import { useAxios } from "Helpers/Hooks";
import _ from "lodash";

const useGetAllSalesSectionsDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/salesSections")
        .then((response) => {
          var mapped = _.map(
            response.data.data,
            _.partialRight(_.pick, ["id", "name", "otherName"])
          );
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllDineInSalesSectionsDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/salesSections/dineIn")
        .then((response) => {
          var mapped = _.map(
            response.data.data,
            _.partialRight(_.pick, ["id", "name", "otherName"])
          );
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllEmployeesDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/employees")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllCitiesDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/cities")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllTaxesDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/taxes")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllSurchargesDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/surcharges")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllDiscountsDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/discounts")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllBranchesDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/branches")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllRolesDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/roles")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllPermissionGroupsDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/permissionGroups")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllPrintersDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropDowns/printers")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllCategoriesAndItemsDropdowns = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/categories")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllOrderCancellationReasons = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/orderCancellationReasons")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllItemCancellationReasons = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/itemCancellationReasons")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllDrivers = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/drivers")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllCashiers = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/cashiers")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllZones = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/zones")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

const useGetAllReportEmails = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/dropdowns/reportEmails")
        .then((response) => {
          var mapped = _.map(response.data.data, _.partialRight(_.pick, ["id", "email"]));
          resolve(mapped);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

export {
  useGetAllDineInSalesSectionsDropdowns,
  useGetAllSalesSectionsDropdowns,
  useGetAllEmployeesDropdowns,
  useGetAllCitiesDropdowns,
  useGetAllTaxesDropdowns,
  useGetAllSurchargesDropdowns,
  useGetAllDiscountsDropdowns,
  useGetAllBranchesDropdowns,
  useGetAllRolesDropdowns,
  useGetAllPermissionGroupsDropdowns,
  useGetAllPrintersDropdowns,
  useGetAllCategoriesAndItemsDropdowns,
  useGetAllOrderCancellationReasons,
  useGetAllItemCancellationReasons,
  useGetAllCashiers,
  useGetAllDrivers,
  useGetAllZones,
  useGetAllReportEmails,
};
