// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Button,
  useTheme,
  Box,
  Paper,
  Typography,
  useMediaQuery,
  FormControl,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import Title from "Components/Title";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import { CheckBox, TextField } from "Components/FormFields";
import ReceiptTemplate from "./ReceiptTemplate";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";

// Services
import {
  useGetAllOrderReceiptData,
  useEditReceiptData,
} from "Services/ReceiptData";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
    paper: {
      display: "flex",
      flexWrap: "wrap",
      margin: 8,
      width: "100%",
    },
    formContainer: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  };
});

// Ad-Hoc Components
const validationSchema = Joi.object({
  orderNumber: Joi.boolean(),
  orderNumberFormatted: Joi.boolean(),
  brandName: Joi.boolean(),
  branchName: Joi.boolean(),
  phoneNumbers: Joi.boolean(),
  headerText: Joi.string().allow("").allow(null),
  itemNotes: Joi.boolean(),
  orderNotes: Joi.boolean(),
  footerText: Joi.string().allow("").allow(null),
});

/**
 * @name Receipt
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Receipt = ({ permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [isFetchingReceiptSettings, setIsFetchingReceiptSettings] =
    useState(true);

  // Effect Hooks
  useEffect(() => {
    getAllReceiptData()
      .then((receiptData) => {
        const data = Object.assign(
          {},
          ...(function _flatten(o) {
            if (o)
              return [].concat(
                ...Object.keys(o).map((k) =>
                  typeof o[k] === "object" ? _flatten(o[k]) : { [k]: o[k] }
                )
              );
          })(receiptData)
        );

        setFormData(data);
        setIsFetchingReceiptSettings(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllReceiptData = useGetAllOrderReceiptData();
  const editReceiptData = useEditReceiptData({
    languageKey: "setup.printersDesign.receipt",
  });
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({
    validationSchema,
    languageKey: "setup.printersDesign.receipt",
  });

  // Event Handlers
  const onEditReceiptHandler = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const {
        orderNumber,
        orderNumberFormatted,
        brandName,
        branchName,
        phoneNumbers,
        headerText,
        itemNotes,
        orderNotes,
        footerText,
      } = formData;

      editReceiptData({
        header: {
          orderNumber,
          orderNumberFormatted,
          brandName,
          branchName,
          phoneNumbers,
          headerText,
        },
        body: {
          itemNotes,
          orderNotes,
        },
        footer: {
          footerText,
        },
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          setIsSubmiting(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  // Other

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));
  const downMd = useMediaQuery(Theme.breakpoints.down("md"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.printersDesign.receipt.pageTitle"
            key="setup.printersDesign.receipt.pageTitle"
          />
        }
      />

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.printersDesign.receipt.pageTitle" />
        </Typography>
      </Box>

      <Box>
        <Paper className={classes.paper} elevation={2} ref={inputRef}>
          <Box className={classes.root}>
            {isFetchingReceiptSettings && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box className={classes.formContainer}>
                <form className={classes.form} noValidate>
                  <FormControl className={classes.formControl}>
                    <Box
                      display="flex"
                      flexDirection={downMd ? "column" : "row"}
                      height={"75vh"}
                      overflow="auto"
                    >
                      <Box
                        flex={downMd ? "unset" : 1}
                        marginRight={3}
                        maxHeight="100%"
                        minHeight="300px"
                        overflow="auto"
                      >
                        <Typography variant="subtitle1" component="span">
                          <LocalizedMessage id="setup.printersDesign.header" />
                        </Typography>

                        <Box marginLeft={2} marginBottom={2}>
                          <CheckBox
                            fullWidth
                            name="orderNumber"
                            tooltip={"Show Order Number?"}
                            helpKey="common.orderNumber"
                            label={<LocalizedMessage id="common.orderNumber" />}
                            onChange={onFormFieldChange}
                            value={formData.orderNumber}
                            errors={formErrors.orderNumber}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.orderNumber}
                          />

                          <CheckBox
                            fullWidth
                            name="orderNumberFormatted"
                            tooltip={"Show Formatted Order Number?"}
                            helpKey="setup.printersDesign.orderNumberFormatted"
                            label={
                              <LocalizedMessage id="setup.printersDesign.orderNumberFormatted" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.orderNumberFormatted}
                            errors={formErrors.orderNumberFormatted}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.orderNumberFormatted}
                          />

                          <CheckBox
                            fullWidth
                            name="brandName"
                            tooltip={"Show Brand Name?"}
                            helpKey="setup.printersDesign.brandName"
                            label={
                              <LocalizedMessage id="setup.printersDesign.brandName" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.brandName}
                            errors={formErrors.brandName}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.brandName}
                          />

                          <CheckBox
                            fullWidth
                            name="branchName"
                            tooltip={"Show Branch Name?"}
                            helpKey="setup.printersDesign.branchName"
                            label={
                              <LocalizedMessage id="setup.printersDesign.branchName" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.branchName}
                            errors={formErrors.branchName}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.branchName}
                          />

                          <CheckBox
                            fullWidth
                            name="phoneNumbers"
                            tooltip={"Show Phone Numbers?"}
                            helpKey="common.phoneNumbers"
                            label={
                              <LocalizedMessage id="common.phoneNumbers" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.phoneNumbers}
                            errors={formErrors.phoneNumbers}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.phoneNumbers}
                          />
                          <Box display="flex">
                            <TextField
                              margin="dense"
                              size="small"
                              variant="outlined"
                              label={
                                <LocalizedMessage id="common.headerText" />
                              }
                              name="headerText"
                              autoComplete="headerText"
                              tooltip={"Header Text"}
                              helpKey="common.headerText"
                              // fullWidth
                              //maxCharacters={50}
                              onChange={onFormFieldChange}
                              value={formData.headerText}
                              errors={formErrors.headerText}
                              isSubmitting={isSubmitting}
                              isLoading={isLoading.headerText}
                            />
                          </Box>
                        </Box>

                        <Typography variant="subtitle1" component="span">
                          <LocalizedMessage id="setup.printersDesign.body" />
                        </Typography>

                        <Box marginLeft={1} marginBottom={2}>
                          <CheckBox
                            fullWidth
                            name="itemNotes"
                            tooltip={"Show Item Notes?"}
                            helpKey="setup.printersDesign.itemNotes"
                            label={
                              <LocalizedMessage id="setup.printersDesign.itemNotes" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.itemNotes}
                            errors={formErrors.itemNotes}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.itemNotes}
                          />

                          <CheckBox
                            fullWidth
                            name="orderNotes"
                            tooltip={"Show Order Notes?"}
                            helpKey="setup.printersDesign.orderNotes"
                            label={
                              <LocalizedMessage id="setup.printersDesign.orderNotes" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.orderNotes}
                            errors={formErrors.orderNotes}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.orderNotes}
                          />
                        </Box>

                        <Typography variant="subtitle1" component="span">
                          <LocalizedMessage id="setup.printersDesign.footer" />
                        </Typography>

                        <Box marginLeft={1} marginBottom={2} display="flex">
                          <TextField
                            margin="dense"
                            size="small"
                            variant="outlined"
                            label={<LocalizedMessage id="common.footerText" />}
                            name="footerText"
                            autoComplete="footerText"
                            tooltip={"Footer Text"}
                            helpKey="common.footerText"
                            // fullWidth
                            //maxCharacters={50}
                            onChange={onFormFieldChange}
                            value={formData.footerText}
                            errors={formErrors.footerText}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.footerText}
                          />
                        </Box>
                      </Box>
                      <Divider
                        orientation={downMd ? "horizontal" : "vertical"}
                        style={{ margin: downMd ? "16px 0px" : "0px 16px" }}
                      />

                      <Box flex={1} height="100%">
                        <ReceiptTemplate {...formData} />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={downMd ? "center" : "end"}
                      marginTop="16px"
                    >
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting || isFetchingReceiptSettings}
                        onClick={onEditReceiptHandler}
                        // style={{ margin: 8 }}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          <LocalizedMessage id="general.save" />
                        )}
                      </Button>
                    </Box>
                  </FormControl>
                </form>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

Receipt.propTypes = {
  /**
   *
   */
};

Receipt.defaultProps = {
  /**
   *
   */
};

export default Receipt;
