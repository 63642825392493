// React
import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"

// Material
import {
  TextField as MUITextField,
  FormControl,
  FormHelperText,
} from "@material-ui/core"
import { Autocomplete as MUIAutoCompleteField } from "@material-ui/lab"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"

// Components
import HelpIcon from "Components/HelpIcon"
import Box from "Components/Box"

// Factories

// Screens

// Assets

// Help

// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  helperTextWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
}))

// Ad-Hoc Components

/**
 * @name AutoCompleteField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const AutoCompleteField = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  label,
  onChange,
  helperText,
  showFirstError: onlyShowFirstError,
  size,
  name,
  defaultValue,
  isLoading,
  isSubmitting,
  disabled,
  disableClearable,
  required,
  options,
  getOptionLabel,
  getOptionSelected,
  hidden,
  value,
  setDefaultValue,
  id,
  multiple,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  const currentValue = value || defaultValue || ""

  // Theme & Style Hooks
  const classes = useStyles()

  // Global State Hooks

  // State Hooks

  // Event Handlers
  const onAutoCompleteFieldChangeHandler = (e, option, reason) => {
    if (typeof onChange === "function")
      onChange(name, option ? option.value : "")
  }

  // Effect Hooks
  useEffect(() => {
    if (typeof onChange === "function") onChange(name, value)
    console.log(
      `%cAutocomplete %cField Mount -> %cName: %c${name}`,
      "font-weight: bold; color: purple",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Other
  console.log(
    `%cAutocomplete %cRerender -> %cName: %c${name}`,
    "font-weight: bold; color: purple",
    "",
    "font-weight: bold;",
    ""
  )

  const val = options.find(
    (option) => getOptionSelected(option) === currentValue
  )
  // Component Render
  return (
    <Box className={className} style={style}>
      <Box className={classes.root}>
        <FormControl margin={otherProps.margin} style={{ flex: 1 }}>
          {isLoading && inputSkeleton ? (
            inputSkeleton
          ) : (
              <MUIAutoCompleteField
                ref={inputRef}
                label={label}
                name={name}
                size={size}
                style={{ flex: 1 }}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    margin={margin}
                    variant={variant}
                    label={label}
                    error={Boolean(errors && errors.length > 0)}
                    disabled={isSubmitting || disabled}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete,
                      disabled: isSubmitting || disabled,
                    }}
                    required={required}
                  />
                )}
                value={val ? val : { value: "", text: "" }}
                onChange={onAutoCompleteFieldChangeHandler}
                disabled={isSubmitting || disabled}
                disableClearable={disableClearable || isSubmitting || disabled}
                options={options}
                getOptionLabel={getOptionLabel}
                getOptionSelected={getOptionSelected}
                {...otherProps}
              />
            )}
        </FormControl>
        {/* <HelpIcon
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          isLoading={isLoading}
          {...helpIconProps}
        /> */}
      </Box>
      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                      <span ref={helperTextRef}>{helperText}</span>
                    ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}
        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  )
}

AutoCompleteField.propTypes = {
  /**
   *
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  className: PropTypes.oneOf([PropTypes.array, PropTypes.string]),
}

AutoCompleteField.defaultProps = {
  variant: "outlined",
  margin: "normal",
  autoComplete: "new-password",
  showFirstError: true,
  helperText: "",
  isLoading: false,
  disableClearable: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
}

export default AutoCompleteField
