// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  Box,
  Paper,
  Table,
  Tooltip,
  TableRow,
  useTheme,
  TableBody,
  TableHead,
  TableCell,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { displayenqueueSnackbar, displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { TextField } from "Components/FormFields";
import { LocalizedMessage } from "Helpers/Localization";
import AlertDialog from "Components/AlertDialog";
import Title from "Components/Title";

// Factories

// Screens
import CancellationReasonForm from "./OrderCancellationReasonForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services
import {
  useGetAllOrderCancellationReasons,
  useDeleteOrderCancellationReason,
} from "Services/OrderCancellationReason/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 200,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name OrderCancellationReasons
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OrderCancellationReasons = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);

  const [page, setPage] = React.useState(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);
  const [editId, setEditId] = React.useState("");

  /* OrderCancellationReason Form */
  const [OrdercancellationReasonFormOpen, setOrderCancellationReasonFormOpen] = useState(false);
  const [OrdercancellationReasonFormMode, setOrderCancellationReasonFormMode] = useState("");
  const [OrdercancellationReasonFormData, setOrderCancellationReasonFormData] = useState({});

  // Effect Hooks
  useEffect(() => {
    getAllOrderCancellationReasons()
      .then((orderCancellationReasons) => {
        const newOrderCancellationReasons = orderCancellationReasons.map(
          (orderCancellationReason) => createData(orderCancellationReason)
        );
        setIsFetchingRows(false);
        setRows(newOrderCancellationReasons);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllOrderCancellationReasons = useGetAllOrderCancellationReasons();
  const deleteOrderCancellationReason = useDeleteOrderCancellationReason();

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddOrderCancellationReasonForm = () => {
    setOrderCancellationReasonFormData({});
    setOrderCancellationReasonFormMode("add");
    setOrderCancellationReasonFormOpen(true);
  };

  const onEditClick = (OrdercancellationReason) => {
    setEditId(OrdercancellationReason.id);
    setOrderCancellationReasonFormData(OrdercancellationReason);
    setOrderCancellationReasonFormMode("edit");
    setOrderCancellationReasonFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteOrderCancellationReason(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setRows(rows.getState().filter((row) => row.id !== id));
      })
      .catch((errors) => {
        errors.forEach((e) => {
          enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
            variant: "error",
            autoHideDuration: 3000,
          });
        });
      });
  };

  const addCallback = (response) => {
    setRows([...rows.getState(), createData(response.data.data)]);
  };

  const editCallback = (response) => {
    const newRows = rows.getState();
    const index = newRows.findIndex((row) => row.id === editId);
    newRows.splice(index, 1, createData(response.data.data));
    setRows(newRows);
  };

  // Other
  const createData = (OrdercancellationReason) => {
    return {
      id: OrdercancellationReason.id,
      name: OrdercancellationReason.name,
      otherName: OrdercancellationReason.otherName,
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.orderOrderCancellationReason.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(OrdercancellationReason)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.orderOrderCancellationReason.includes("delete")) && (
            <AlertDialog id={OrdercancellationReason.id} onAgree={onDeleteClick} />
          )} */}
        </Box>
      ),
    };
  };

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.cancellationReason.orderCancellationReason.pageTitle"
            key="setup.cancellationReason.orderCancellationReason.pageTitle"
          />
        }
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.cancellationReason.orderCancellationReason.pageTitle" />
        </Typography>
        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.orderOrderCancellationReason.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={isFetchingRows}
            component={isFetchingRows ? "div" : undefined}
            onClick={isFetchingRows ? undefined : openAddOrderCancellationReasonForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        <CancellationReasonForm
          open={OrdercancellationReasonFormOpen}
          setOpen={setOrderCancellationReasonFormOpen}
          mode={OrdercancellationReasonFormMode}
          editFormData={OrdercancellationReasonFormData}
          addCallback={addCallback}
          editCallback={editCallback}
          editId={editId}
        />
        {isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

OrderCancellationReasons.propTypes = {
  /**
   *
   */
};

OrderCancellationReasons.defaultProps = {
  /**
   *
   */
};

export default OrderCancellationReasons;
