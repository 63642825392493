// React
import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Material

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Memo
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Memo = ({ children, deps }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return useMemo(children, deps);
};

Memo.propTypes = {
  /**
   *
   */
};

Memo.defaultProps = {
  deps: [],
};

export default Memo;
