// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { Typography, useTheme, Button } from "@material-ui/core";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import Box from "Components/Box";
import Title from "Components/Title";

// Factories

// Utils
import { throttle } from "Utils";

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    height: "100%",
    display: "flex",
    flex: 3,
    flexDirection: "column",
    justifyContent: "center",
  },
  text: {
    marginTop: "50",
    textAlign: "center",
  },
  video: {
    overflow: "hidden",
    paddingBottom: "30%",
    position: "relative",
    height: "0",
    width: "50%",
    margin: "0 auto",
  },
  iFrame: {
    left: "0",
    top: "0",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
}));
// Ad-Hoc Components

/**
 * @name ReadyToGo
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ReadyToGo = ({
  className,
  setStepSubmittingCallback,
  setIsReady,
  ...otherProps
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    setIsReady(true);
    setStepSubmittingCallback(() => onSubmitHandler);
  }, []);
  // Other Hooks

  // Event Handlers
  const onSubmitHandler = throttle(({ onCalled, onSuccess, onCancel }) => {
    window.location.href = window.location.origin + "/portal";
  }, 1000);

  // Other

  // Component Render
  return (
    <>
      <Box className={classes.root} {...otherProps}>
        <Title
          pageTitle={<LocalizedMessage id="wizard.readyToGo.pageTitle" />}
        />
        <div className={classes.text}>
          <div className={classes.video}>
            <iframe
              className={classes.iFrame}
              width="560"
              height="315"
              src="https://www.youtube.com/embed/aUrckgoZ1qs"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <Typography component="span" variant="h6">
            <LocalizedMessage id="wizard.readyToGo.youAreNowAllSet" />
          </Typography>
          <Typography component="span" variant="h6">
            <LocalizedMessage id="wizard.readyToGo.clickFinish" />
          </Typography>
        </div>
      </Box>
    </>
  );
};

ReadyToGo.propTypes = {
  /**
   *
   */
};

ReadyToGo.defaultProps = {
  /**
   *
   */
};

export default ReadyToGo;
