// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  FormHelperText,
  useTheme,
  Box,
} from "@material-ui/core";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { makeStyles } from "Helpers/Styles";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens
import SurchargeForm from "Screens/Portal/Surcharges/SurchargeForm";
import SalesChannelForm from "Screens/Portal/SalesChannels/SalesChannelForm";
import CategoriesAndItemsDialog from "Screens/Portal/Reports/CategoriesAndItemsDialog";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import {
  useGetAllSalesSectionsDropdowns,
  useGetAllSurchargesDropdowns,
  useGetAllCategoriesAndItemsDropdowns,
} from "Services/Dropdowns/";
import { useAddTax, useEditTax } from "Services/Taxes/";
// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(50),
  otherName: Joi.string().allow("").allow(null).min(1).max(50),
  value: Joi.number().required().min(0),
  valueType: Joi.string().valid("FIXED_AMOUNT", "PERCENTAGE").required(),
  taxType: Joi.string().valid("section", "item", "surcharge").required(),
  salesSections: Joi.array()
    .items(Joi.string().uuid())
    .when("taxType", {
      is: "section",
      then: Joi.array().required().min(1),
      // otherwise: Joi.forbidden(),
    }),
  items: Joi.array()
    // .items(Joi.string().uuid())
    .when("taxType", {
      is: "item",
      then: Joi.array().required().min(1),
      // otherwise: Joi.forbidden(),
    }),
  surcharges: Joi.array()
    .items(Joi.string().uuid())
    .when("taxType", {
      is: "surcharge",
      then: Joi.array().required().min(1),
      // otherwise: Joi.forbidden(),
    }),
  categories: Joi.array(),
});

/**
 * @name TaxForm
 * @summary
 * @Tax
 * @component
 * @description
 * >
 */
const TaxForm = ({
  editFormData,
  addCallback,
  editCallback,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
  permissions,
  parent,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState("salesSections.salesSections");
  const [surcharges, setSurcharges] = useGlobalState("surcharges.surcharges");

  // State Hooks
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);
  const [surchargeFormOpen, setSurchargeFormOpen] = useState(false);
  const [categoriesAndItemsModalOpen, setCategoriesAndItemsModalOpen] = useState(false);
  const [categoriesAndItemsData, setCategoriesAndItemsData] = useState([]);
  const [isFetchingCategoriesAndItems, setIsFetchingCategoriesAndItems] = useState(true);

  // Effect Hooks
  useEffect(() => {
    if (mode === "edit") {
      console.log("editformdata", editFormData);
      editFormData = {
        ...editFormData,
        salesSections: editFormData.salesSections.map((salesSection) => salesSection.id),
        surcharges: editFormData.surcharges.map((surcharge) => surcharge.id),
      };

      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "value",
          "valueType",
          "taxType",
          "salesSections",
          "items",
          "surcharges",
        ])
      );
    } else setFormData({ items: [] });
  }, [open]);

  useEffect(() => {
    getData()
      .then((data) => {
        setCategoriesAndItemsData(data);
        setIsFetchingCategoriesAndItems(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingCategoriesAndItems(false);
      });
  }, []);

  useEffect(() => {
    if (!surcharges.isFetchingRows && !surcharges.data.length)
      getAllSurchargesDropdowns()
        .then((res) => {
          setSurcharges({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [surcharges.isFetchingRows]);

  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  // Other Hooks
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const getData = useGetAllCategoriesAndItemsDropdowns();
  const getAllSurchargesDropdowns = useGetAllSurchargesDropdowns();
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();
  const addTax = useAddTax({ languageKey: "common.tax" });
  const editTax = useEditTax({ languageKey: "common.tax" });
  const taxForm = useForm({ validationSchema, languageKey: "common.tax" });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    onFormFieldChange,
    isSubmitting,
    isLoading,
  } = taxForm;

  // Event Handlers
  const openAddTaxForm = (parentTax) => {
    setOpen(true);
  };

  const onCLoseTaxForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddTax = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: taxForm,
      formName: "tax",
      apiHandler: addTax,
      onClose: onCLoseTaxForm,
    });
  };

  const onSaveTax = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: taxForm,
      formName: "tax",
      apiHandler: editTax,
      onClose: onCLoseTaxForm,
    });
  };

  const onOpenSalesChannelForm = () => {
    setSalesChannelFormOpen(true);
  };

  const onOpenSurchargeForm = () => {
    setSurchargeFormOpen(true);
  };

  const onCloseSalesChannelForm = () => {
    setSalesChannelFormOpen(false);
  };

  const surchargeDropDownCallback = (response) => {
    const surcharge = response.data.data;
    const newSurcharge = {
      value: surcharge.id,
      text: surcharge.name,
    };
    setSurcharges({
      ...surcharges,
      data: [...surcharges.data, surcharge],
    });
    const formDataSurcharges = formData.surcharges ? formData.surcharges : [];

    setFormData({
      ...formData,
      surcharges: [...formDataSurcharges, newSurcharge.value],
    });
  };

  const salesChannelDropDownCallback = (response) => {
    const salesSection = response.data.data;
    const newSalesSection = {
      value: salesSection.id,
      text: salesSection.name,
      salesSectionTypeId: salesSection.salesSectionTypeId,
    };
    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, salesSection],
    });
    const formDataSalesSections = formData.salesSections ? formData.salesSections : [];

    setFormData({
      ...formData,
      salesSections: [...formDataSalesSections, newSalesSection.value],
    });
  };

  // Other
  console.log(
    `%cTaxForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseTaxForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>Tax</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Tax Name"}
                helpKey="Menu.tax.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                tooltip={"Tax Other Name"}
                helpKey="Menu.tax.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
              <TextField
                required
                fullWidth
                name="value"
                autoComplete="value"
                tooltip={"Value"}
                helpKey="common.value"
                label={<LocalizedMessage id="common.value" />}
                onChange={onFormFieldChange}
                value={formData.value}
                errors={formErrors.value}
                isSubmitting={isSubmitting}
                isLoading={isLoading.value}
              />
              <SelectField
                neglectNone
                required
                fullWidth
                name="valueType"
                tooltip={"Tax value type"}
                helpKey="common.valueType"
                label={<LocalizedMessage id="common.valueType" />}
                onChange={onFormFieldChange}
                value={formData.valueType}
                errors={formErrors.valueType}
                isSubmitting={isSubmitting}
                isLoading={isLoading.valueType}
                options={[
                  {
                    value: "FIXED_AMOUNT",
                    text: intl.formatMessage({
                      id: "common.fixedAmount",
                    }),
                  },
                  {
                    value: "PERCENTAGE",
                    text: intl.formatMessage({
                      id: "common.percentage",
                    }),
                  },
                ]}
              />
              <SelectField
                neglectNone
                required
                fullWidth
                name="taxType"
                tooltip={"Tax type"}
                helpKey="Menu.tax.taxType"
                label={<LocalizedMessage id="setup.tax.taxType" />}
                onChange={onFormFieldChange}
                value={formData.taxType}
                errors={formErrors.taxType}
                isSubmitting={isSubmitting}
                isLoading={isLoading.taxType}
                options={[
                  {
                    value: "section",
                    text: intl.formatMessage({ id: "common.section" }),
                    disabled:
                      parent === "item" ||
                      parent === "surcharge" ||
                      (mode === "edit" &&
                        (formData.taxType === "item" || formData.taxType === "surcharge")),
                  },
                  {
                    value: "item",
                    text: intl.formatMessage({ id: "common.item" }),
                    disabled:
                      parent === "section" ||
                      parent === "surcharge" ||
                      (mode === "edit" &&
                        (formData.taxType === "section" || formData.taxType === "surcharge")),
                  },
                  {
                    value: "surcharge",
                    text: intl.formatMessage({ id: "common.surcharge" }),
                    disabled:
                      parent === "item" ||
                      parent === "section" ||
                      (mode === "edit" &&
                        (formData.taxType === "item" || formData.taxType === "section")),
                  },
                ]}
              />
              {formData.taxType === "item" && (
                <Box margin={"16px 0px"}>
                  <Button
                    variant="outlined"
                    disabled={isFetchingCategoriesAndItems}
                    onClick={() => setCategoriesAndItemsModalOpen(true)}
                    style={{ width: "100%", padding: "14px" }}
                  >
                    <LocalizedMessage id="export.chooseItems" />
                  </Button>
                  <CategoriesAndItemsDialog
                    open={categoriesAndItemsModalOpen}
                    setOpen={setCategoriesAndItemsModalOpen}
                    formData={formData}
                    setFormData={setFormData}
                    categoriesAndItemsData={categoriesAndItemsData}
                    formDataItems={formData.items}
                  />
                  {formErrors.items && (
                    <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                      <FormHelperText
                        error={Boolean(formErrors.items && formErrors.items.length > 0)}
                        className={classes.helperTextWrapper}
                        component="div"
                        style={{
                          display: "flex",
                          flex: 1,
                          marginLeft: 0,
                        }}
                      >
                        <span className={classes.helperTextWrapper}>
                          {formErrors.items && (
                            <span style={{ display: "flex", flexDirection: "column" }}>
                              {formErrors.items.map((errorMessage, i) => (
                                <span key={i}>{errorMessage}</span>
                              ))}
                            </span>
                          )}
                        </span>
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
              )}
              {formData.taxType === "surcharge" && (
                <SelectField
                  fullWidth
                  required
                  name="surcharges"
                  tooltip={"Surcharges"}
                  helpKey="Menu.Table.surchargeId"
                  label={<LocalizedMessage id="setup.surcharge.pageTitle" />}
                  onChange={onFormFieldChange}
                  value={formData.surcharges}
                  errors={formErrors.surcharges}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.surcharges}
                  options={surcharges.data.map((s) => ({
                    value: s.id,
                    text: s.name,
                  }))}
                  openForm={onOpenSurchargeForm}
                  multiple
                  addPermission={
                    permissions.isAdmin ||
                    (permissions.surcharge && permissions.surcharge.includes("add"))
                  }
                />
              )}
              {formData.taxType === "section" && (
                <SelectField
                  fullWidth
                  required
                  name="salesSections"
                  tooltip={"Sales Channels"}
                  helpKey="Menu.Table.salesSectionId"
                  label={<LocalizedMessage id="common.salesSections" />}
                  onChange={onFormFieldChange}
                  value={formData.salesSections}
                  errors={formErrors.salesSections}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.salesSections}
                  options={salesSections.data.map((s) => ({
                    value: s.id,
                    text: s.name,
                    disabled: s.salesSectionTypeId === "Staff",
                  }))}
                  openForm={onOpenSalesChannelForm}
                  multiple
                  addPermission={
                    permissions.isAdmin ||
                    (permissions.salesSection && permissions.salesSection.includes("add"))
                  }
                />
              )}
            </form>
            <SurchargeForm
              open={surchargeFormOpen}
              mode={"add"}
              setOpen={setSurchargeFormOpen}
              addCallback={surchargeDropDownCallback}
              permissions={permissions}
            />

            <SalesChannelForm
              open={salesChannelFormOpen}
              mode="add"
              addCallback={salesChannelDropDownCallback}
              onCancel={onCloseSalesChannelForm}
              permissions={permissions}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddTax}
          mode={mode}
          onCLoseForm={onCLoseTaxForm}
          isSubmitting={isSubmitting}
          onSave={onSaveTax}
        />
      </DialogActions>
    </Dialog>
  );
};

TaxForm.propTypes = {
  /**
   *
   */
};

TaxForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default TaxForm;
