// React
import React, { useEffect } from "react";

// Material
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useTheme
} from "@material-ui/core";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { makeStyles } from "Helpers/Styles";

// Components
import FormActions from "Components/FormActions";
import { SelectField, TextField } from "Components/FormFields";

// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useIntl } from "react-intl";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  transactionType: Joi.string()
    .valid("adjustment", "increment", "decrement")
    .required(),
  value: Joi.number().min(0),
  quantity: Joi.number().min(0),
});

/**
 * @name StockCapacityForm
 * @summary
 * @StockCapacity
 * @component
 * >
 */
const StockCapacityForm = ({
  editFormData,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      console.log("editFormData", editFormData);
      setFormData(_.pick(editFormData, ["quantity"]));
    } else setFormData({});
  }, [open]);

  // Other Hooks
  const intl = useIntl();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "stockCapacity" });

  // Event Handlers
  const onCLoseStockCapacityForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onSaveStockCapacity = () => {
    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseStockCapacityForm
    );
  };

  // Other
  console.log(
    `%cStockCapacityForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseStockCapacityForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="stockCapacity.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="stockCapacity.currentStockCapacity" /> :{" "}
              <b>{formData.quantity}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <SelectField
                required
                fullWidth
                name="transactionType"
                tooltip={"Discount value type"}
                helpKey="Menu.discount.transactionType"
                label={<LocalizedMessage id="stockCapacity.transactionType" />}
                onChange={onFormFieldChange}
                value={formData.transactionType}
                errors={formErrors.transactionType}
                isSubmitting={isSubmitting}
                isLoading={isLoading.transactionType}
                options={
                  formData.quantity
                    ? [
                        {
                          value: "adjustment",
                          text: intl.formatMessage({id:"stockCapacity.adjustment"}) 
                        },
                        {
                          value: "increment",
                          text: intl.formatMessage({id:"stockCapacity.increment"})
                        },
                        {
                          value: "decrement",
                          text: intl.formatMessage({id:"stockCapacity.decrement"})
                        },
                      ]
                    : [
                        {
                          value: "adjustment",
                          text: intl.formatMessage({id:"stockCapacity.adjustment"})
                        },
                      ]
                }
              />
              <TextField
                required
                label={<LocalizedMessage id="common.value" />}
                name="value"
                tooltip={"value"}
                helpKey="Menu.stockCapacity.nameField"
                onChange={onFormFieldChange}
                value={formData.value}
                errors={formErrors.value}
                isSubmitting={isSubmitting}
                isLoading={isLoading.value}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          mode={mode}
          onCLoseForm={onCLoseStockCapacityForm}
          isSubmitting={isSubmitting}
          onSave={onSaveStockCapacity}
        />
      </DialogActions>
    </Dialog>
  );
};
StockCapacityForm.propTypes = {
  /**
   *
   */
};

StockCapacityForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default StockCapacityForm;
