// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Box,
  useTheme,
  Divider,
  CardContent,
  Card,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // display: "flex",
    // height: "53vh",
    [theme.breakpoints.down("sm")]: { marginTop: 16 },
    [theme.breakpoints.up("sm")]: { marginInlineStart: 16 },
  },
  singleDivider: {
    width: "100%",
    borderTop: "2px dashed #58585f",
    backgroundColor: "unset",
    margin: 7,
    height: 5,
  },
  doubleDivider: {
    width: "100%",
    borderTop: "2px dashed #58585f",
    borderBottom: "2px dashed #58585f",
    backgroundColor: "unset",
    margin: 5,
    height: 5,
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 4,
  },
}));

// Ad-Hoc Components

/**
 * @name ReceiptTemplate
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ReceiptTemplate = ({
  headerText,
  footerText,
  orderNumber,
  orderNumberFormatted,
  brandName,
  branchName,
  phoneNumbers,
  orderNotes,
  itemNotes,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [account] = useGlobalState("userData.account");

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const renderText = (
    text,
    value = true,
    align = "left",
    variant = "body2",
    style
  ) =>
    value && (
      <Typography
        align={
          align === "left" ? "left" : align === "right" ? "right" : "center"
        }
        variant={variant === "h6" ? "h6" : "body2"}
        component="span"
        style={style}
      >
        {text}
      </Typography>
    );

  // Component Render
  return (
    <Box className={classes.root} height="100%">
      <Card elevation={0} style={{ overflow: "auto", height: "100%" }}>
        <CardContent className={classes.cardContent}>
          <img
            src={account.brands[0].logoPath}
            style={{ maxHeight: 80 }}
            alt="Brand Image"
          />
          {renderText("[Order Number]", orderNumber, "center", "h6", {
            margin: "8px 0px",
          })}
          {renderText(
            "[Formatted Order Number]",
            orderNumberFormatted,
            "center"
          )}

          <Box display="flex" flexDirection="column" margin={"16px 0px"}>
            {renderText("Invoice No. [Invoice Number]", true, "center")}

            {renderText("[Brand Name]", brandName, "center")}

            {renderText("[Branch Name]", branchName, "center")}

            {renderText(
              headerText ? headerText : "[Header Text]",
              true,
              "center"
            )}

            {renderText("Phone [Phone Numbers]", phoneNumbers, "center")}
          </Box>

          <Divider classes={{ root: classes.singleDivider }} />

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            {renderText(
              "[Order Number] - [Cashier Name] - [Order Day] - [Order Time]"
            )}
            {renderText(
              "[Sales Channel] - [Sales Channel Type] - Table [Table Number] - [Customers Count] guests"
            )}

            {renderText("[Order Notes]", orderNotes)}
          </Box>

          <Divider classes={{ root: classes.doubleDivider }} />

          <Box display="flex" justifyContent="space-between" width="100%">
            {renderText("ITEM")}
            {renderText("QTY - TOTAL", true, "right")}
          </Box>

          <Divider classes={{ root: classes.doubleDivider }} />

          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            m={0.5}
          >
            {renderText("[Item Name] ([Other Name])")}
            {renderText("[Quantity] × [Price]", true, "right")}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            width="95%"
            m={0.25}
          >
            {renderText("[Modifier Item Name] ([Other Name])")}
            {renderText("[Quantity] × [Price]", true, "right")}
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            width="95%"
            m={0.25}
          >
            {renderText("[Item Discount]")}
            {renderText("-[Quantity] × [Price]", true, "right")}
          </Box>

          {renderText("[Item Notes]", itemNotes, "left", "body2", {
            width: "95%",
            margin: 2,
          })}

          <Divider classes={{ root: classes.doubleDivider }} />

          <>
            <Box display="flex" justifyContent="space-between" width="100%">
              {renderText("Subtotal")}
              {renderText("[Subtotal]", true, "right")}
            </Box>
            <Divider classes={{ root: classes.singleDivider }} />

            <Box display="flex" justifyContent="space-between" width="100%">
              {renderText("[Discount Name]")}
              {renderText("[Value]", true, "right")}
            </Box>

            <Box display="flex" justifyContent="space-between" width="100%">
              {renderText("[Tax Name]")}
              {renderText("[Value]", true, "right")}
            </Box>

            <Box display="flex" justifyContent="space-between" width="100%">
              {renderText("[Surcharge Name]")}
              {renderText("[Value]", true, "right")}
            </Box>
          </>
          <Divider classes={{ root: classes.singleDivider }} />

          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            marginLeft={1}
          >
            {renderText("Grand Total")}
            {renderText("[Grand Total]", true, "right")}
          </Box>

          {renderText("<[Receipt Name]>", true, "center")}
          {renderText(
            footerText ? footerText : "[Footer Text]",
            true,
            "center"
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

ReceiptTemplate.propTypes = {
  /**
   *
   */
};

ReceiptTemplate.defaultProps = {
  /**
   *
   */
};

export default ReceiptTemplate;
