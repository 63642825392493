// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens
import SalesChannelForm from "Screens/Portal/SalesChannels/SalesChannelForm";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import { useGetAllSalesSectionsDropdowns } from "Services/Dropdowns/";
import { useAddTable, useEditTable } from "Services/Tables/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

const validationSchema = Joi.object({
  number: Joi.string().min(1).max(100).required(),
  capacity: Joi.number().min(1).required(),
  salesSectionId: Joi.string().guid().required(),
});

/**
 * @name TableForm
 * @summary
 * @Table
 * @component
 * @description
 * >
 */
const TableForm = ({
  editFormData,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
  permissions,
  editCallback,
  addCallback,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState("salesSections.salesSections");

  // State Hooks
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      editFormData = {
        ...editFormData,
        salesSectionId: editFormData.salesSection.id,
      };
      setFormData(_.pick(editFormData, ["number", "capacity", "salesSectionId"]));
    } else setFormData({});
  }, [open]);

  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  // Other Hooks
  const addTable = useAddTable({ languageKey: "common.table" });
  const editTable = useEditTable({ languageKey: "common.table" });
  const { enqueueSnackbar } = useSnackbar();
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();

  const tableForm = useForm({ validationSchema, languageKey: "common.table" });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = tableForm;

  // Event Handlers
  const openAddTableForm = (parentTable) => {
    setOpen(true);
  };

  const onCLoseTableForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };
  const onOpenSalesChannelForm = () => {
    setSalesChannelFormOpen(true);
  };
  const onCloseSalesChannelForm = () => {
    setSalesChannelFormOpen(false);
  };
  const onAddTable = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: tableForm,
      formName: "table",
      apiHandler: addTable,
      onClose: onCLoseTableForm,
    });
  };

  const onSaveTable = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: tableForm,
      formName: "table",
      apiHandler: editTable,
      onClose: onCLoseTableForm,
    });
  };

  const salesChannelCallback = (response) => {
    const salesSection = response.data.data;
    const newSalesSection = {
      value: salesSection.id,
      text: salesSection.name,
      salesSectionTypeId: salesSection.salesSectionTypeId,
    };
    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, salesSection],
    });
    setFormData({
      ...formData,
      salesSectionId: newSalesSection.value,
    });
  };

  // Other
  console.log(
    `%cTableForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseTableForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>Table</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="setup.table.number" />}
                name="number"
                autoComplete="number"
                tooltip={"Table number"}
                helpKey="Menu.Table.numberField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.number}
                errors={formErrors.number}
                isSubmitting={isSubmitting}
                isLoading={isLoading.number}
              />
              <TextField
                required
                label={<LocalizedMessage id="setup.table.capacity" />}
                name="capacity"
                autoComplete="capacity"
                tooltip={"Table capacity"}
                helpKey="Menu.Table.capacityField"
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.capacity}
                errors={formErrors.capacity}
                isSubmitting={isSubmitting}
                isLoading={isLoading.capacity}
              />
              <SelectField
                required
                fullWidth
                name="salesSectionId"
                tooltip={"Sales Channels"}
                helpKey="Menu.Table.salesSectionId"
                label={<LocalizedMessage id="setup.table.salesSectionId" />}
                onChange={onFormFieldChange}
                value={formData.salesSectionId}
                errors={formErrors.salesSectionId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.salesSectionId}
                openForm={onOpenSalesChannelForm}
                options={salesSections.data
                  .map((s) => {
                    if (s.salesSectionTypeId === "DineIn") return { value: s.id, text: s.name };
                  })
                  .filter((s) => s !== undefined)}
                addPermission={
                  permissions.isAdmin ||
                  (permissions.salesSection && permissions.salesSection.includes("add"))
                }
              />
            </form>
            <SalesChannelForm
              open={salesChannelFormOpen}
              mode="add"
              permissions={permissions}
              addCallback={salesChannelCallback}
              onCancel={onCloseSalesChannelForm}
              parent="table"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddTable}
          mode={mode}
          onCLoseForm={onCLoseTableForm}
          isSubmitting={isSubmitting}
          onSave={onSaveTable}
        />
      </DialogActions>
    </Dialog>
  );
};

TableForm.propTypes = {
  /**
   *
   */
};

TableForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default TableForm;
