const getDateDurations = (intl) => [
  {
    text: intl.formatMessage({ id: "report.dateDuration.today" }),
    value: "today",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.yesterday" }),
    value: "yesterday",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.thisWeek" }),
    value: "thisWeek",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.lastWeek" }),
    value: "lastWeek",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.thisMonth" }),
    value: "thisMonth",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.lastMonth" }),
    value: "lastMonth",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.thisYear" }),
    value: "thisYear",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.lastYear" }),
    value: "lastYear",
  },
  {
    text: intl.formatMessage({ id: "report.dateDuration.custom" }),
    value: "custom",
  },
];

export default getDateDurations;
