// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
  Grid,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import CheckBoxesTree from "Components/CheckBoxesTree";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets
// import categoriesAndItemsData from "Data/Mock/Menu/categoriesAndItemsData";

// Third Parties

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name CategoriesAndItemsDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CategoriesAndItemsDialog = ({
  categoriesAndItemsData,
  open,
  setOpen,
  formData,
  setFormData,
  formDataItems,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [categories, setCategories] = useState([]);
  const [items, setItems] = useState(formDataItems ? formDataItems : []);
  const [itemsData, setItemsData] = useState([]);
  const [itemsDataTemp, setItemsDataTemp] = useState([]);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const handleOnSubmit = () => {
    setFormData((formData) => {
      return { ...formData, categories, items };
    });
    setItemsDataTemp(itemsData);
    setOpen(false);
  };

  const handleCancel = () => {
    if (!formData.items) {
      setCategories([]);
      setItems([]);
      setItemsData([]);
    } else {
      setCategories(formData.categories ? formData.categories : []);
      setItems(formData.items);
      setItemsData(itemsDataTemp);
    }
    setOpen(false);
  };

  // Other

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="export.chooseItems" />
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <CheckBoxesTree
                categoriesAndItemsData={categoriesAndItemsData}
                categories={categories}
                setCategories={setCategories}
                items={items}
                setItems={setItems}
                itemsData={itemsData}
                setItemsData={setItemsData}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onCLoseForm={handleCancel}
          isSubmitting={false}
          action={<LocalizedMessage id="general.apply" />}
          onAction={handleOnSubmit}
        />
        {/* <Button
          style={{
            backgroundColor: "#0582D2",
            color: "#fff",
            margin: "8px",
          }}
          variant="contained"
          onClick={handleCancel}
          //startIcon={<CloseIcon />}
        >
          <LocalizedMessage id="general.cancel" />
        </Button>

        <Button
          type="button"
          color="primary"
          variant="contained"
          // disabled={isSubmitting}
          onClick={handleOnSubmit}
        >
          {
            // isSubmitting ? (
            //   <CircularProgress size={20} />
            // ) :
            <LocalizedMessage id="general.apply" />
          }
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

CategoriesAndItemsDialog.propTypes = {
  /**
   *
   */
};

CategoriesAndItemsDialog.defaultProps = {
  /**
   *
   */
};

export default CategoriesAndItemsDialog;
