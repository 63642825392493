import React, { useState } from "react";
import { Box, Button, Card, Divider ,CardActionArea, CardMedia} from "@material-ui/core";
import ReactPlayer from 'react-player';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "Helpers/Styles";
import HomeIcon from "@material-ui/icons/Home";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { useEffect } from "react";
import { useTheme, useMediaQuery } from '@material-ui/core';

import {
  FcCustomerSupport,
  FcDocument,
  FcFaq,
  FcHome,
  FcInvite,
  FcSmartphoneTablet,
  FcVoicePresentation,
} from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4, 2),
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4, 2),
  },
  // title: {
  //   // margin: theme.spacing(4, 0),
  // },
  paper: {
    height: 140,
    width: 240,
  },
  text: {
    marginTop: "50",
    textAlign: "center",
  },
  video: {
    overflow: "hidden",
    paddingBottom: "30%",
    position: "relative",
    height: "0",
    width: "50%",
    margin: "0 auto",
  },
  iFrame: {
    left: "0",
    top: "0",
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  card: {
    marginBottom: '16px',
    [theme.breakpoints.down("sm")]: {
      margin: '5px',
    }
  },
  media: {
    height: '140px',
  },
  title: {
    padding: '16px',
  },
  selectedCard: {
    marginBottom: '16px',
    backgroundColor: '#FFF9C4', // light gray
    [theme.breakpoints.down("sm")]: {
      margin: '5px',
    }
  },
  videosContainer: {
    [theme.breakpoints.up("sm")]: {
      flexWrap: 'nowrap',
    } 
  },
  videosList: {
    maxHeight: '600px',
    overflow: 'auto',
    [theme.breakpoints.down("sm")]: {
      display: 'flex',
      width: '100%',
      maxWidth: '100%',
      overflowX: 'scroll',
      flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    maxHeight: '300px',
    justifyContent: 'space-between',
    },
    
    
  },
}));

function SectionItem({ Icon, title, disabled = false }) {
  return (
    <Grid xs={12} md={4} item>
      <Button disabled={disabled} style={{ width: "100%" }}>
        <Grid container>
          <Grid item xs={12} justify="center" style={{ display: "flex" }}>
            <Icon style={{ fontSize: 70 }} />
          </Grid>
          <Grid item xs={12} justify="center" style={{ display: "flex" }}>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
}

function Player({ src }) {
  const classes = useStyles();

  return (
    <div className={classes.video}>
      <iframe
        className={classes.iFrame}
        width="560"
        height="315"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

const sources = [
  "https://www.youtube.com/embed/JMyNm3r_baU",
  "https://www.youtube.com/embed/VOfb-z-IaGU",
  "https://www.youtube.com/embed/aUrckgoZ1qs",
  "https://www.youtube.com/embed/7mS0BTbWHCc",
];

const Playlist = () => {

  const [language, ] = useGlobalState("global.locale");
  const classes = useStyles();

  const videos = {
    en: [
      { url: 'https://www.youtube.com/watch?v=JMyNm3r_baU', title: 'Sign Up' },
      { url: 'https://www.youtube.com/watch?v=VOfb-z-IaGU', title: 'Brand Setup' },
      { url: 'https://www.youtube.com/watch?v=aUrckgoZ1qs', title: 'Users' },
      { url: 'https://www.youtube.com/watch?v=7mS0BTbWHCc', title: 'Sales Channels' },
      { url: 'https://www.youtube.com/watch?v=GM6-KuQptXo', title: 'Printers' },
      { url: 'https://www.youtube.com/watch?v=pqkfuMIs5s8', title: 'Email Reports' },
      { url: 'https://www.youtube.com/watch?v=rivASjxY13w', title: 'Payment Settings' },
      { url: 'https://www.youtube.com/watch?v=WY4rRzU-klM', title: 'Menu #1' },
      { url: 'https://www.youtube.com/watch?v=75rtxfjXsLI', title: 'Menu #2' },
      { url: 'https://www.youtube.com/watch?v=Pt1IbY6BI74', title: 'First Order' },
      
    ],
    ar: [
      { url: 'https://www.youtube.com/watch?v=nlwuwddu4bY', title: 'تسجيل الدخول' },
      { url: 'https://www.youtube.com/watch?v=RW9I5InkILc', title: 'العلامة التجارية' },
      { url: 'https://www.youtube.com/watch?v=YSA80L7Sg8c', title: 'أقسام البيع' },
      { url: 'https://www.youtube.com/watch?v=Zr6x09j3vPs', title: 'التقرير اليومي' },
      { url: 'https://www.youtube.com/watch?v=EJbOWFjDi9o', title: 'اعدادات الدفع' },
      { url: 'https://www.youtube.com/watch?v=Rx0piSpBeRk', title: 'القائمة ١' },
      { url: 'https://www.youtube.com/watch?v=8_SOUxwNuwQ', title: 'القائمة ٢' },
      { url: 'https://www.youtube.com/watch?v=D1Z2tTM3fAg', title: 'اول طلب' },
      
    ],
  };

  const [currentVideo, setCurrentVideo] = useState(videos[language][0]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid container spacing={3} className={classes.videosContainer}  direction={isSmallScreen ? 'column-reverse' : 'row'}>
      <Grid item md={3} sm={12} className={classes.videosList}  direction={isSmallScreen ? 'row' : 'column'}>
        {videos[language].map((video, index) => (
          <Card key={index} className={video.url === currentVideo.url ? classes.selectedCard : classes.card}>
            <CardActionArea onClick={() => setCurrentVideo(video)}>
              <CardMedia
              className={classes.media}
                component="img"
                alt={video.title}
                height="120"
                image={`https://img.youtube.com/vi/${video.url.split('v=')[1]}/0.jpg`}
                title={video.title}
              />
              <Typography className={classes.title} variant="subtitle2">{video.title}</Typography>
            </CardActionArea>
          </Card>
        ))}
      </Grid>
      <Grid item md={9} sm={12}>
        <ReactPlayer url={currentVideo.url} controls={true} width="100%" />
        {/* <Typography variant="h6">{currentVideo.title}</Typography> */}
      </Grid>
    </Grid>
  );
};

function GettingStarted() {
  const [heading, setHeading] = useGlobalState("heading.heading");
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <HomeIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage
            id="gettingStarted.pageTitle"
            key="gettingStarted.pageTitle"
          />
        </Box>
      ),
    []
  );
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Playlist />
      <Grid container spacing={4}>
        {/* <Grid item xs={12} md={12}>
          <Typography variant="h5" className={classes.title}>
            Docs
          </Typography>
          <Grid container spacing={4} justify="space-around">
            <SectionItem title={"Getting Started"} Icon={FcHome} />
            <SectionItem title={"Documentation"} Icon={FcDocument} />
            <SectionItem title={"FAQ"} Icon={FcFaq} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" className={classes.title}>
            Support
          </Typography>
          <Grid container spacing={4} justify="space-around">
            <SectionItem title={"Chat"} Icon={FcCustomerSupport} disabled />
            <SectionItem title={"Email Us"} Icon={FcInvite} />
            <SectionItem
              title={"Live Demo"}
              Icon={FcVoicePresentation}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography variant="h5" className={classes.title}>
            Video Tutorial
          </Typography>
          <Grid container spacing={4} justify="space-around">
            {sources.map((src, idx) => (
              <Grid key={idx} xs={12} xl={3} item>
                <Player src={src} />
              </Grid>
            ))}
          </Grid>
        </Grid> */}
        <Grid item xs={12} md={12}>
          <Divider style={{ width: "100%" }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <div style={{ display: "flex", flexDirection: "row", gap: "25px" }}>
            <FcSmartphoneTablet style={{ fontSize: 70 }} />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5" className={classes.title}>
                Call us any time
              </Typography>
              <Typography variant="subtitle1" className={classes.title}>
                UAE: <a href="tel:+971558414263">+971 55 841 4263</a>
              </Typography>
              {/* <Typography variant="subtitle1" className={classes.title}>
                Saudi Arabia: +71654498484
              </Typography> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </Card>
  );
}

export default GettingStarted;
