// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Paper,
  useTheme,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useInterval from "Helpers/useInterval";
import useStateRef from "Helpers/useStateRef";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import OrderDetailsDialog from "../Sales/OrderDetailsDialog";
import Row from "./DeliveryOrdersRow";

// Factories

// Screens

// Assets

// Services
import { useGetOutDrivers } from "Services/Live/";

// Third Parties
import { useSnackbar } from "notistack";
import moment from "moment";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 100,
  },
  {
    id: "ordersCount",
    label: <LocalizedMessage id="report.orderscount" />,
    minWidth: 100,
  },
];

const secondaryColumns = [
  {
    id: "orderNumberFormatted",
    label: <LocalizedMessage id="setup.printersDesign.orderNumberFormatted" />,
    minWidth: 100,
  },
  {
    id: "orderGrandTotalAmount",
    label: <LocalizedMessage id="live.orderGrandTotalAmount" />,
    minWidth: 100,
  },
];

/**
 * @name OutDrivers
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OutDrivers = () => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  const [page, setPage] = useState(0);
  const [outDriversPerPage, setOutDriversPerPage] = useState(5);
  const [outDrivers, setOutDrivers] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = useState(true);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");

  // Effect Hooks
  useEffect(() => {
    if (!openOrderDetailsDialog) setSelectedOrderId("");
  }, [openOrderDetailsDialog]);

  useEffect(() => {
    getDrivers();
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getOutDrivers = useGetOutDrivers();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setOutDriversPerPage(+event.target.value);
    setPage(0);
  };

  const getDrivers = () => {
    setIsFetchingRows(true);
    getOutDrivers()
      .then((drivers) => {
        const newDrivers = drivers.map((driver) => createData(driver));
        setIsFetchingRows(false);
        setOutDrivers(newDrivers);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingRows(false);
      });
  };
  useInterval(getDrivers, 30000, 15 * 60000);

  // Other
  function createData(driver) {
    return {
      id: driver.id,
      name: driver.name,
      ordersCount: driver.orders?.length,
      orders: driver.orders,
    };
  }
  const intl = useIntl();

  // Component Render
  return (
    <>
      <Typography variant="h6" component="h2" style={{ marginBottom: 4 }}>
        <LocalizedMessage id="live.outDrivers" />
      </Typography>
      <Paper>
        {isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {outDrivers
                    .getState()
                    .slice(page * outDriversPerPage, page * outDriversPerPage + outDriversPerPage)
                    .map((driver, index) => {
                      return (
                        <Row
                          key={index}
                          row={driver}
                          columns={columns}
                          secondaryColumns={secondaryColumns}
                          setSelectedOrderId={setSelectedOrderId}
                          setOpenOrderDetailsDialog={setOpenOrderDetailsDialog}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={outDrivers.getState().length}
              rowsPerPage={outDriversPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
        <OrderDetailsDialog
          open={openOrderDetailsDialog}
          setOpen={setOpenOrderDetailsDialog}
          orderId={selectedOrderId}
        />
      </Paper>
    </>
  );
};

OutDrivers.propTypes = {
  /**
   *
   */
};

export default OutDrivers;
