// React
import React, { useEffect, useState } from "react";

// Material
import { Tabs, Tab, AppBar, Paper, useTheme, Box } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import Title from "Components/Title";

// Factories

// Screens

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name Setup
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Setup = ({ permissions, tabs, title }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [value, setValue] = useState(0);
  const [tabsToShow, setTabsToShow] = useState([]);

  // Effect Hooks
  useEffect(() => {
    if (permissions.isAdmin) {
      setTabsToShow(tabs);
    } else {
      const permissionMatrix = Object.keys(permissions);
      const newTabs = tabs.filter((element) =>
        permissionMatrix.includes(element.permissionName)
      );
      setTabsToShow(newTabs);
    }
  }, []);

  // Other Hooks

  // Event Handlers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index, lastIndex) => {
    setValue(index);
  };

  // Other

  // Component Render
  return (
    <Box className={classes.root}>
      <Title pageTitle={<LocalizedMessage id={title} key={title} />} />
      <Box>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="action tabs example"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabsToShow.map((e, i) => {
              return e.node ? (
                <Tab label={e.label} icon={e.icon} key={i} />
              ) : (
                []
              );
            })}
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          {tabsToShow.map(({ node }, i) =>
            node ? (
              <Paper
                key={i}
                dir={theme.direction}
                style={{
                  padding: theme.spacing(1, 2, 2, 2),
                }}
                elevation={2}
                square
              >
                {node}
              </Paper>
            ) : (
              []
            )
          )}
        </SwipeableViews>
      </Box>
    </Box>
  );
};

Setup.propTypes = {
  /**
   *
   */
};

Setup.defaultProps = {
  /**
   *
   */
};

export default Setup;
