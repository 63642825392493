// React
import React, { useState, useEffect, useRef } from "react";

// Material
import {
  Button,
  Grid,
  useTheme,
  CircularProgress,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarFormErrors, displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import useSkeleton from "Helpers/useSkeleton";
import useInterval from "Helpers/useInterval";
import { useForm } from "Helpers/Hooks";

// Components
import Title from "Components/Title";
import Box from "Components/Box";
import Typography from "Components/Typography";
import { SelectField } from "Components/FormFields";
import OrderDetailsDialog from "../Sales/OrderDetailsDialog";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import numeral from "numeral";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";

// Services
import { useGetTables } from "Services/Live/";
import { useGetAllDineInSalesSectionsDropdowns } from "Services/Dropdowns/";
import { useGetAllTableStatuses } from "Services/TableStatuses";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexGrow: 1,
      flexDirection: "column",
      justifyContent: "space-between",
    },
    formContainer: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flex: 3,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
    subColor: {
      color: "#ffffff",
    },
  };
});

// Ad-Hoc Components

/**
 * @name Tables
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Tables = () => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useState([]);
  const [isFetchingSalesSections, setIsFetchingSalesSections] = useState(true);
  const [isFetchingTables, setIsFetchingTables] = useState(true);
  const [tableStatuses, setTableStatuses] = useState([]);
  const [isFetchingTableStatuses, setIsFetchingTableStatuses] = useState(true);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");

  // State Hooks
  const [tables, setTables] = useState([]);

  // Effect Hooks
  useEffect(() => {
    getAllTableStatuses()
      .then((data) => {
        setTableStatuses(data);
        setIsFetchingTableStatuses(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingTableStatuses(false);
      });
  }, []);

  useEffect(() => {
    getAllDineInSalesSections()
      .then((data) => {
        setSalesSections(data);
        setIsFetchingSalesSections(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingSalesSections(false);
      });
  }, []);

  useEffect(() => {
    handleSearch();
  }, []);

  // Other Hooks
  let history = useHistory();
  const getTables = useGetTables();
  const getAllDineInSalesSections = useGetAllDineInSalesSectionsDropdowns();
  const getAllTableStatuses = useGetAllTableStatuses();

  const { enqueueSnackbar } = useSnackbar();
  const [inputSkeleton, inputRef] = useSkeleton("rect");

  const validationSchema = Joi.object({
    tableStatuses: Joi.array().items(Joi.string()),
    salesSections: Joi.array().items(Joi.string().uuid()),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "report" });
  const intl = useIntl();

  // Event Handlers
  const handleSearch = () => {
    const validForm = validateForm();
    console.log(validForm, "validForm");
    if (validForm) {
      setIsFetchingTables(true);
      setTables([]);

      let { tableStatuses, salesSections } = formData;
      getTables(tableStatuses, salesSections)
        .then((response) => {
          setTables((prevState) =>
            response.sort((a, b) =>
              parseInt(a.tableNumber) > parseInt(b.tableNumber)
                ? 1
                : parseInt(b.tableNumber) > parseInt(a.tableNumber)
                ? -1
                : 0
            )
          );
          setIsFetchingTables(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsFetchingTables(false);
        });
    }
  };
  useInterval(handleSearch, 30000, 15 * 60000);

  // Other
  const returnValue = (value) => {
    return value !== null
      ? typeof value === "boolean"
        ? value
          ? "Cancelled"
          : "Applied"
        : value
      : "";
  };

  const returnString = (key, value, xs, md, style = {}) => {
    let val = returnValue(key);
    return (
      (val || val === 0) && (
        <Grid item xs={xs} md={md} style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" style={{ margin: 4 }}>
            {value}
          </Typography>
          <Typography variant="body2" style={style}>
            {isNaN(val) || value.search("Amount") === -1 ? val : numeral(val).format("0.00a")}
          </Typography>
        </Grid>
      )
    );
  };

  const returnButton = (key, value, orderId, xs, md) => {
    return (
      <Grid item xs={xs} md={md} style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ margin: 4 }}>
          {value}
        </Typography>
        <Button
          component="label"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedOrderId(orderId);
            setOpenOrderDetailsDialog(true);
          }}
          style={{
            fontStyle: "italic",
          }}
        >
          {key}
        </Button>
      </Grid>
    );
  };
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <>
      <Box className={classes.root}>
        <Title pageTitle={<LocalizedMessage id="live.pageTitle" key="live.pageTitle" />} />
        <Accordion style={{ marginBottom: 8 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" className={classes.heading} style={{ fontSize: "1rem" }}>
              <LocalizedMessage id="general.filters" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                // display: "flex",
                flex: 1,
                padding: downSm ? 0 : theme.spacing(0, 2),
                minWidth: 0,
                flexDirection: "row",
              }}
            >
              <SelectField
                fullWidth
                name="salesSections"
                tooltip={"Sales Channels"}
                helpKey="common.salesSections"
                label={<LocalizedMessage id="setup.salesChannel.pageTitle" />}
                onChange={onFormFieldChange}
                value={formData.salesSections}
                errors={formErrors.salesSections}
                isSubmitting={isFetchingTables}
                isLoading={isLoading.salesSections}
                options={salesSections.map((s) => ({
                  value: s.id,
                  text: s.name,
                }))}
                multiple
              />
              <SelectField
                fullWidth
                name="tableStatuses"
                tooltip={"Order Status"}
                helpKey="Menu.category.orderStatus"
                label={<LocalizedMessage id="live.tableStatuses" />}
                onChange={onFormFieldChange}
                value={formData.tableStatuses}
                errors={formErrors.tableStatuses}
                isSubmitting={isFetchingTables}
                isLoading={isLoading.tableStatuses}
                options={tableStatuses.map((s) => ({
                  value: s.id,
                  text: intl.formatMessage({
                    id: `live.${s.id?.toLowerCase()}`,
                  }),
                }))}
                multiple
              />
            </Box>

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="button"
                variant="contained"
                color="primary"
                disabled={isFetchingTables}
                onClick={handleSearch}
                style={{ margin: "16px" }}
                startIcon={!isFetchingTables && <DoneIcon />}
              >
                {isFetchingTables ? (
                  <CircularProgress size={20} />
                ) : (
                  <LocalizedMessage id="general.apply" />
                )}
              </Button>
            </Box>
          </AccordionDetails>
        </Accordion>
        {/* <Paper
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
            marginBottom: "20",
          }}
          elevation={2}
          square
        > */}
        {isFetchingTables && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Grid container spacing={2} style={{ justifyContent: "center" }} ref={inputRef}>
            {tables?.length ? (
              tables.map((table, index) => {
                return (
                  <Grid key={index} xs={12} md={4} lg={3} item>
                    <Card
                      style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <CardHeader
                        style={{
                          backgroundColor:
                            table.tableStatus === "Busy"
                              ? "#FF0000"
                              : table.tableStatus === "Available"
                              ? "#808080"
                              : "#008000",
                        }}
                        subheader={
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography className={classes.subColor}>
                              #{table["tableNumber"]}
                            </Typography>
                            <Typography className={classes.subColor}>
                              <LocalizedMessage
                                id={`live.${table["tableStatus"]?.toLowerCase()}`}
                              />
                              {/* {table["tableStatus"]?.toUpperCase()} */}
                            </Typography>
                          </Box>
                        }
                      />
                      <CardContent
                        style={{
                          flex: 1,
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        {table["tableStatus"] === "Busy" ? (
                          <>
                            {table["orderNumberFormatted"]
                              ? returnButton(
                                  table["orderNumberFormatted"],
                                  intl.formatMessage({
                                    id: "setup.printersDesign.orderNumberFormatted",
                                  }) + " :",
                                  table.orderId,
                                  12
                                )
                              : null}
                            {table["orderCreatedAt"]
                              ? returnString(
                                  table["orderCreatedAt"],
                                  intl.formatMessage({
                                    id: "common.createdAt",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["orderCustomersCount"]
                              ? returnString(
                                  table["orderCustomersCount"],
                                  intl.formatMessage({
                                    id: "live.orderCustomersCount",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["orderGrandTotalAmount"]
                              ? returnString(
                                  table["orderGrandTotalAmount"],
                                  intl.formatMessage({
                                    id: "live.orderGrandTotalAmount",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["orderCashier"]
                              ? returnString(
                                  table["orderCashier"],
                                  intl.formatMessage({
                                    id: "sales.cashier",
                                  }) + " :",
                                  12
                                )
                              : null}
                          </>
                        ) : table["tableStatus"] === "Reserved" ? (
                          <>
                            {" "}
                            {table["orderNumberFormatted"]
                              ? returnButton(
                                  table["orderNumberFormatted"],
                                  intl.formatMessage({
                                    id: "live.orderNumberFormatted",
                                  }) + " :",
                                  table.orderId,
                                  12
                                )
                              : null}
                            {table["orderCreatedAt"]
                              ? returnString(
                                  table["orderCreatedAt"],
                                  "Order assigned to table at:",
                                  12
                                )
                              : null}
                            {table["orderGrandTotalAmount"]
                              ? returnString(
                                  table["orderGrandTotalAmount"],
                                  intl.formatMessage({
                                    id: "live.orderGrandTotalAmount",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservedAt"]
                              ? returnString(
                                  table["reservedAt"],
                                  intl.formatMessage({
                                    id: "live.reservedAt",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservedFrom"]
                              ? returnString(
                                  table["reservedFrom"],
                                  intl.formatMessage({
                                    id: "live.reservedFrom",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservedTo"]
                              ? returnString(
                                  table["reservedTo"],
                                  intl.formatMessage({
                                    id: "live.reservedTo",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservationGuestsCount"]
                              ? returnString(
                                  table["reservationGuestsCount"],
                                  intl.formatMessage({
                                    id: "live.reservationGuestsCount",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservationCustomerName"]
                              ? returnString(
                                  table["reservationCustomerName"],
                                  intl.formatMessage({
                                    id: "report.customerName",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservationCustomerPhone"]
                              ? returnString(
                                  table["reservationCustomerPhone"],
                                  intl.formatMessage({
                                    id: "report.customerPhone",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservationNote"]
                              ? returnString(
                                  table["reservationNote"],
                                  intl.formatMessage({
                                    id: "live.reservedFrom",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["reservedBy"]
                              ? returnString(
                                  table["reservedBy"],
                                  intl.formatMessage({
                                    id: "live.reservedBy",
                                  }) + " :",
                                  12
                                )
                              : null}
                            {table["orderCashier"]
                              ? returnString(
                                  table["orderCashier"],
                                  intl.formatMessage({
                                    id: "sales.cashier",
                                  }) + " :",
                                  12
                                )
                              : null}
                          </>
                        ) : (
                          <Typography
                            align="center"
                            variant="h5"
                            style={{
                              marginTop: 10,
                              color: "silver",
                              height: 220,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <LocalizedMessage id="live.noOrders" />
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                );
              })
            ) : (
              <Typography
                alignSelf="center"
                align="center"
                variant="h3"
                style={{
                  padding: 16,
                  color: "silver",
                }}
              >
                <LocalizedMessage id="live.noTables" />
              </Typography>
            )}
          </Grid>
        )}
        <OrderDetailsDialog
          open={openOrderDetailsDialog}
          setOpen={setOpenOrderDetailsDialog}
          orderId={selectedOrderId}
        />
        {/* </Paper> */}
      </Box>
    </>
  );
};

Tables.propTypes = {
  /**
   *
   */
};

Tables.defaultProps = {
  /**
   *
   */
};

export default Tables;
