// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Button,
  CircularProgress,
  Box,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: "100px",
    margin: "4px",
  },
}));

/**
 * @name FormActions
 * @summary
 * @FormActions
 * @component
 * @description
 * >
 */

const FormActions = ({
  onAdd,
  mode,
  onCLoseForm,
  isSubmitting,
  onSave,
  action,
  onAction,
  isClose,
  disabled,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      width="100%"
      style={{
        display: "flex",
        padding: "8px",
        flexDirection: downSm ? "column" : "row",
        alignItems: "end",
        justifyContent: "flex-end",
      }}
    >
      {downSm ? (
        <>
          {mode === "add" ? (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || disabled}
              onClick={onAdd}
              variant="contained"
              fullWidth={true}
              className={classes.button}
              startIcon={!isSubmitting && <DoneIcon />}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.add" />
              )}
            </Button>
          ) : mode === "edit" ? (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || disabled}
              onClick={onSave}
              variant="contained"
              fullWidth={true}
              className={classes.button}
              startIcon={!isSubmitting && <DoneIcon />}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.edit" />
              )}
            </Button>
          ) : action ? (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || disabled}
              onClick={onAction}
              variant="contained"
              fullWidth={true}
              className={classes.button}
              startIcon={!isSubmitting && <DoneIcon />}
            >
              {isSubmitting ? <CircularProgress size={20} /> : action}
            </Button>
          ) : null}
          {onCLoseForm && (
            <Button
              onClick={onCLoseForm}
              variant="outlined"
              fullWidth={true}
              color="primary"
              className={classes.button}
              startIcon={<ClearIcon />}
            >
              {isClose ? (
                <LocalizedMessage id="common.close" />
              ) : (
                <LocalizedMessage id="general.cancel" />
              )}
            </Button>
          )}
        </>
      ) : (
        <>
          {onCLoseForm && (
            <Button
              onClick={onCLoseForm}
              variant="outlined"
              fullWidth={false}
              color="primary"
              className={classes.button}
              startIcon={<ClearIcon />}
            >
              {isClose ? (
                <LocalizedMessage id="common.close" />
              ) : (
                <LocalizedMessage id="general.cancel" />
              )}
            </Button>
          )}
          {mode === "add" ? (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || disabled}
              onClick={onAdd}
              variant="contained"
              fullWidth={false}
              className={classes.button}
              startIcon={!isSubmitting && <DoneIcon />}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.add" />
              )}
            </Button>
          ) : mode === "edit" ? (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || disabled}
              onClick={onSave}
              variant="contained"
              fullWidth={false}
              className={classes.button}
              startIcon={!isSubmitting && <DoneIcon />}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.edit" />
              )}
            </Button>
          ) : action ? (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || disabled}
              onClick={onAction}
              variant="contained"
              fullWidth={false}
              className={classes.button}
              startIcon={!isSubmitting && <DoneIcon />}
            >
              {isSubmitting ? <CircularProgress size={20} /> : action}
            </Button>
          ) : null}
        </>
      )}
    </Box>
  );
};

export default FormActions;
