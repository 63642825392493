// React
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Button,
  Divider,
  Typography,
  Popover,
  useTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MinimizeIcon from "@material-ui/icons/Minimize";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

// Globals

// Helpers
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { useIntl } from "react-intl";
import numeral from "numeral";

// Services
import { useGetOrdersItemsStatistics, useGetOrdersSales } from "Services/Dashboard/";

// Styles

// Ad-Hoc Components

/**
 * @name OrdersSales
 * @summary
 * @category
 * @component
 * @description
 * >
 */

const OrdersSales = ({ style, dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [ordersSalesData, setOrdersSalesData] = useState([]);
  const [chartDimensions, setChartDimensions] = useState({
    height: 0,
    width: 0,
  });

  const chartContainer = useRef({ clientHeight: 0, clientWidth: 0 });
  // const chartContainerWidth = useRef(chartContainer.current.clientWidth)

  // Effect Hooks

  // useEffect(() => {
  //   getOrdersItemsStatistics(
  //     moment(dateFrom).format("YYYY-MM-DD"),
  //     moment(dateTo).format("YYYY-MM-DD")
  //   )
  //     .then((response) => {
  //       setOrdersSalesData(response);
  //     })
  //     .catch((errors) => {
  //       displaySnackbarErrors(errors, enqueueSnackbar);
  //     });
  // }, [dateFrom, dateTo]);

  useEffect(() => {
    // new ResizeSensor(document.querySelector("#chartContainer"), function () {
    //   console.log("Resize", chartContainer.current.clientWidth);
    //   setChartDimensions((cd) => ({
    //     height: cd.height || chartContainer.current.clientHeight,
    //     width: chartContainer.current.clientWidth,
    //   }));
    // });
  }, []);

  useEffect(() => {
    if (chartContainer.current) {
      setChartDimensions((cd) => ({
        height: cd.height || chartContainer.current.clientHeight,
        width: cd.width || chartContainer.current.clientWidth,
      }));
    }
  }, [chartContainer]);

  useEffect(() => {
    getOrdersSales(moment(dateFrom).format("YYYY-MM-DD"), moment(dateTo).format("YYYY-MM-DD"))
      .then((response) => {
        setOrdersSalesData(response);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [dateFrom, dateTo]);

  // Other Hooks
  const getOrdersItemsStatistics = useGetOrdersItemsStatistics();
  const getOrdersSales = useGetOrdersSales();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  // Event Handlers

  // Other
  const data = (theme) => ({
    labels: ordersSalesData.map((el, key) => {
      return moment(el.date).format("MMM DD, YYYY");
    }),
    datasets: [
      {
        label: intl.formatMessage({ id: "report.netsales" }),
        backgroundColor: fade(theme.palette.primary.main, 0.5),
        borderColor: fade(theme.palette.primary.light, 0.7),
        borderWidth: 1,
        data: ordersSalesData.map((v, key) => {
          return v.netsales;
        }),
      },
      {
        label: intl.formatMessage({ id: "report.orderscount" }),
        backgroundColor: fade(theme.palette.secondary.main, 0.5),
        borderColor: fade(theme.palette.secondary.light, 0.7),
        borderWidth: 1,
        data: ordersSalesData.map((v, key) => {
          return v.count;
        }),
      },
    ],
  });
  // Component Render
  return (
    <Card variant="outlined" style={{ ...style, display: "flex" }}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" component="h2">
            <LocalizedMessage id="dashboard.ordersSales" />
          </Typography>
        </Box>

        <Divider />
        {/* <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            margin: Theme.spacing(2, 0),
          }}
        >
          <Box
            style={{
              marginRight: Theme.spacing(5),
            }}
          >
            <Tooltip title={`${ordersVsItemsData.orders}`} placement="top">
              <Typography variant="h6" component="h2">
                {numeral(ordersVsItemsData.orders).format("0.00a")}
              </Typography>
            </Tooltip>
            <Typography color="textSecondary" gutterBottom>
              {intl.formatMessage({ id: "dashboard.orders" })}
            </Typography>
            <Typography variant="h6" component="h2">
              {ordersVsItemsData.ordersTrend < 0 ? (
                <ArrowDownwardIcon
                  style={{
                    color: red[500],
                  }}
                />
              ) : ordersVsItemsData.ordersTrend > 0 ? (
                <ArrowUpwardIcon
                  style={{
                    color: green[500],
                  }}
                />
              ) : (
                <MinimizeIcon
                  style={{
                    color: grey[500],
                  }}
                />
              )}
              {numeral(Math.abs(ordersVsItemsData.ordersTrend)).format("0.0")}%
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {intl.formatMessage({ id: "dashboard.ordersTrend" })}
            </Typography>
          </Box>
          <Box>
            <Tooltip title={`${ordersVsItemsData.itemsSold}`} placement="top">
              <Typography variant="h6" component="h2">
                {numeral(ordersVsItemsData.itemsSold).format("0.00a")}
              </Typography>
            </Tooltip>

            <Typography color="textSecondary" gutterBottom>
              {intl.formatMessage({ id: "dashboard.itemsSold" })}
            </Typography>
            <Typography variant="h6" component="h2">
              {ordersVsItemsData.itemsTrend < 0 ? (
                <ArrowDownwardIcon
                  style={{
                    color: red[500],
                  }}
                />
              ) : ordersVsItemsData.itemsTrend > 0 ? (
                <ArrowUpwardIcon
                  style={{
                    color: green[500],
                  }}
                />
              ) : (
                <MinimizeIcon
                  style={{
                    color: grey[500],
                  }}
                />
              )}
              {numeral(Math.abs(ordersVsItemsData.itemsTrend)).format("0.0")}%
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {intl.formatMessage({ id: "dashboard.itemsTrend" })}
            </Typography>
          </Box>
        </Box>
        */}
        <Box
          style={{ flex: 1, position: "relative", width: "100%" }}
          ref={chartContainer}
          id="chartContainer"
        >
          {chartDimensions.height && (
            <Line
              {...chartDimensions}
              data={data(Theme)}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                // onResize: (ci, ns) => console.log(ci, ns),
                title: {
                  display: false,
                  text: intl.formatMessage({
                    id: "dashboard.ordersVsItemsSold",
                  }),
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: "top",
                },
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

OrdersSales.propTypes = {
  /**
   *
   */
};

export default OrdersSales;
