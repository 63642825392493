// React
import React from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";

// Components
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles

// Ad-Hoc Components

/**
 * @name ServerChangedAlert
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ServerChangedAlert = ({ handleContinueEditing, open, onCloseDialog }) => {
  // Theme & Style Hooks
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={false}
      maxWidth={"sm"}
      style={{ direction: theme.direction }}
    >
      <DialogTitle id="alert-dialog-title">
        {<LocalizedMessage id="ServerChangedAlert.pageTitle" />}
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent
        style={{
          display: "flex",
          flex: 1,
          flexDirection: downSm ? "column" : "row",
          // alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <Box display="flex" flexDirection="column" margin="8px">
          <Typography variant="subtitle2">
            <LocalizedMessage id="POS.serverChanged" />
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <FormActions
          onCLoseForm={onCloseDialog}
          isSubmitting={false}
          action={<LocalizedMessage id="common.continue" />}
          onAction={handleContinueEditing}
          isClose
        />
        {/* <Button
          color="secondary"
          //variant="outlined"
          autoFocus
          onClick={onCloseDialog}
          //startIcon={<CloseIcon />}
        >
          <LocalizedMessage id="common.close" />
        </Button>
        <Button
          onClick={handleContinueEditing}
          // variant="outlined"
          color="primary"
          //startIcon={<PrintIcon />}
        >
          <LocalizedMessage id="common.continue" />
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

ServerChangedAlert.propTypes = {
  /**
   *
   */
};

ServerChangedAlert.defaultProps = {
  /**
   *
   */
};

export default ServerChangedAlert;
