// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { useTheme, Box, useMediaQuery, Grid } from "@material-ui/core";
import AppsIcon from "@material-ui/icons/Apps";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import DownloadPOSCard from "Screens/Portal/Dashboard/DownloadPOSCard";
import DownloadDriverCard from "Screens/Portal/Dashboard/DownloadDriverCard";

// Factories

// Screens

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views";
import QRCode from "qrcode.react";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name DownloadApps
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DownloadApps = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks

  // Effect Hooks
  React.useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <AppsIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage id="portal.downloadApps" key="portal.downloadApps" />
        </Box>
      ),
    []
  );

  // Other Hooks

  // Event Handlers

  // Other
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  // Component Render
  return (
    <>
      <Title pageTitle={<LocalizedMessage id="portal.downloadApps" key="portal.downloadApps" />} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <DownloadPOSCard />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DownloadDriverCard />
        </Grid>
      </Grid>
    </>
    //   <Card>
    //     <CardHeader
    //       title={
    //         <>
    //           <Box
    //             display="flex"
    //             flexDirection="row"
    //             alignItems="center"
    //             justifyContent="space-between"
    //           >
    //             <Typography variant="h6" component="h2">
    //               <LocalizedMessage id="POS.downloadApp" />
    //             </Typography>

    //           </Box>
    //           <Divider style={{ marginTop: 10 }} />
    //         </>
    //       }
    //     />
    //     <CardContent
    //       style={{
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         margin: "30",
    //         position: "relative",
    //       }}
    //     >
    //       <QRCode
    //         value="https://drive.google.com/file/d/1g4pS7WhXSvK8xTNABSspN8CGz7X-KXP5/view"
    //         size={matches ? 200 : 150}
    //       />
    //     </CardContent>
    //   </Card>
    //   <Fab
    //     color="primary"
    //     aria-label="add"
    //     href="https://drive.google.com/file/d/1g4pS7WhXSvK8xTNABSspN8CGz7X-KXP5/view"
    //     target="_blank"
    //     style={{
    //       position: "absolute",
    //       bottom: theme.spacing(-4),
    //       right: theme.spacing(-3),
    //     }}
    //   >
    //     <DownloadAppsIcon />
    //   </Fab>
  );
};

DownloadApps.propTypes = {
  /**
   *
   */
};

DownloadApps.defaultProps = {
  /**
   *
   */
};

export default DownloadApps;
