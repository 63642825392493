// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import "date-fns"
import {
  Box,
  DatePicker as MUIDatePicker,
  FormHelperText,
  FormControl,
} from "@material-ui/core"
import DateFnsUtils from "@date-io/date-fns"
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
  KeyboardDateTimePicker,
} from "@material-ui/pickers"
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"

// Components
import HelpIcon from "Components/HelpIcon"

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  helperTextWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
}))

// Ad-Hoc Components

/**
 * @name DatePicker
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DatePicker = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  maxCharacters,
  defaultValue,
  showFirstError: onlyShowFirstError,
  value,
  type,
  onChange,
  id,
  isLoading,
  isSubmitting,
  hidden,
  name,
  label,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4()

  let newValue = value

  //   if (value && typeof value === "string") {
  //     let hours = value.slice(0, 2);
  //     let minutes = value.slice(3, 5);
  //     let type = value.slice(5, 8);
  //     newValue = new Date();
  //     if (type === "AM" && hours === "12")
  //       newValue.setHours(0, parseInt(minutes));
  //     else newValue.setHours(parseInt(hours) + 12, parseInt(minutes));
  //   }
  value = value === undefined || value === null ? new Date() : newValue

  // Theme & Style Hooks
  const classes = useStyles()

  // Global State Hooks

  // State Hooks

  // const [DatePickerValue, setDatePickerValue] = useState(value);

  // Effect Hooks
  useEffect(() => {
    console.log(
      `%cText Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  useEffect(() => {
    if (typeof onChange === "function") onChange(name, value)
    console.log(
      `%cText Field %cValue Changed -> %cName: %c${name} - %cValue: %c${value}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      "",
      "font-weight: bold;",
      ""
    )
  }, [value])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onChangeHandler = (name, time, v) => {
    // typeof e.persist === "function" && e.persist();

    typeof onChange === "function" && onChange(name, time)
  }

  // Other

  console.log(
    `%cText Field %cRerender -> %cName: %c${name} %cCurrentValue: %c${value}`,
    "font-weight: bold; color: blue",
    "",
    "font-weight: bold;",
    "",
    "font-weight: bold;",
    ""
  )

  // Component Render
  return (
    <Box className={className} style={{ display: hidden ? "none" : "block" }}>
      <FormControl {...{ margin }} className={classes.root} style={style}>
        {isLoading && inputSkeleton ? (
          inputSkeleton
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              {...{
                // format: "hh:mm a",
                // placeholder: "hh:mm a",
                margin,
                onChange: (time, v) => onChangeHandler(name, time, v),
                // KeyboardButtonProps={{
                //   'aria-label': 'change time',
                // }}

                ref: inputRef,
                inputProps: {
                  autoComplete,
                  id: inputFieldID,
                  ...inputProps,
                },
                error: Boolean(errors && errors.length > 0),
                value: value,
                fullWidth,
                size,
                defaultValue,
                variant,
                name,
                label,
                type,
                disabled: isSubmitting || disabled,
                ...otherProps,
              }}
            />
          </MuiPickersUtilsProvider>

          // <MUIDatePicker
          //   {...{
          //     ref: inputRef,
          //     inputProps: {
          //       autoComplete,
          //       id: inputFieldID,
          //       ...inputProps,
          //     },
          //     error: Boolean(errors && errors.length > 0),
          //     value: value,
          //     onChange: onChangeHandler,
          //     fullWidth,
          //     size,
          //     defaultValue,
          //     variant,
          //     name,
          //     label,
          //     type,
          //     disabled: isSubmitting || disabled,
          //     ...otherProps,
          //   }}
          // />
        )}

        {/* <HelpIcon
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          {...helpIconProps}
        /> */}
      </FormControl>

      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                    <span ref={helperTextRef}>{helperText}</span>
                  ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}

        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  )
}

DatePicker.propTypes = {
  /**
   *
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  //   validator: PropTypes.func,
  autoFocus: PropTypes.bool,
  tooltip: PropTypes.string,
  helpKey: PropTypes.string,
  maxCharacters: PropTypes.number,
}

DatePicker.defaultProps = {
  variant: "outlined",
  margin: "normal",
  //   autoComplete: "off",
  showFirstError: true,
  isLoading: false,
  autoFocus: false,
  onChange: () => {},
  fullWidth: true,
}

export default DatePicker
