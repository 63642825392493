// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Tabs,
  useTheme,
  Tab,
  Paper,
  useMediaQuery,
  Box,
  AppBar,
} from "@material-ui/core"
import PaletteIcon from "@material-ui/icons/Palette"
import BusinessIcon from "@material-ui/icons/Business"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"
import Title from "Components/Title"
// Components

// Factories

// Screens
import EditOnlineOrders from "./EditOnlineOrders"
import SetupBranches from "./SetupBranches"

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views"
import { useSnackbar } from "notistack"

// Services
import useGetAllCities from "Services/Cities/useGetAllCities"
// Styles
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}))

// Ad-Hoc Components

/**
 * @name OnlineSetup
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OnlineSetup = ({ setHeading, permissions, branches, setBranches }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [currentComponent, setCurrentComponent] = useState("setupOnline")
  const [value, setValue] = useState(0)
  const [tabsToShow, setTabsToShow] = useState([])

  // Effect Hooks
  useEffect(
    () =>
      setHeading(<LocalizedMessage id="onlineOrders.pageTitle" key="onlineOrders.pageTitle" />),
    []
  )

  // Other Hooks

  // Event Handlers
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleChangeIndex = (index) => {
    setValue(index)
  }
  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("xs"))

  // Component Render
  return (
    <Box
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="onlineOrders.pageTitle"
            key="onlineOrders.pageTitle"
          />
        }
      />

      <Box>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="action tabs example"
            variant="scrollable"
            scrollButtons="on"
            orientation="horizontal"
          >
            <Tab
              label={<LocalizedMessage id={"onlineOrders.setupOnline"} />}
              icon={<PaletteIcon />}
              key={1}
            />
            <Tab
              label={<LocalizedMessage id={"onlineOrders.setupBranches"} />}
              icon={<BusinessIcon />}
              key={2}
            />
          </Tabs>
        </AppBar>

        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        // style={{
        //   flex: 1,
        // }}
        >
          <Paper
            key={0}
            dir={theme.direction}
            style={{
              padding: theme.spacing(1, 2, 2, 2),
            }}
            elevation={2}
            square
          >
            <EditOnlineOrders />
          </Paper>

          <Paper
            key={1}
            dir={theme.direction}
            style={{
              padding: theme.spacing(1, 2, 2, 2),
            }}
            elevation={2}
            square
          >
            <SetupBranches branches={branches} setBranches={setBranches} />
          </Paper>
        </SwipeableViews>
      </Box >
    </Box >
  )
}

OnlineSetup.propTypes = {
  /**
   *
   */
}

OnlineSetup.defaultProps = {
  /**
   *
   */
}

export default OnlineSetup
