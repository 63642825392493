// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Divider,
  Paper,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";
import CircularProgress from "@material-ui/core/CircularProgress";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { useIntl } from "react-intl";

// Components
import ItemTable from "./ItemTable";
import FormActions from "Components/FormActions";
import App from "../Reports/PDFViewer/App";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import numeral from "numeral";
import moment from "moment";

// Services
import { useGetOrderDetails, useGetEInvoice } from "Services/Sales/";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

// Ad-Hoc Components

/**
 * @name OrderDetailsDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OrderDetailsDialog = ({ orderId, createdAt, open, setOpen }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [rows, setRows] = useState({});
  const [isFetchingDetails, setIsFetchingDetails] = useState(true);
  const [invoicePdf, setInvoicePdf] = useState(null);
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);

  // Effect Hooks
  useEffect(() => {
    console.log(
      "🚀 ~ file: OrderDetailsDialog.js ~ line 91 ~ useEffect ~ orderId",
      orderId
    );
    if (orderId)
      getOrderDetails({ orderId })
        .then((response) => {
          setRows(response[0] ? response[0] : {});
          setIsFetchingDetails(false);
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
          setIsFetchingDetails(false);
        });

    return () => {
      setRows({});
      setIsFetchingDetails(true);
    };
  }, [orderId]);

  useEffect(() => {
    console.log(
      "🚀 ~ file: OrderDetailsDialog.js ~ line 110 ~ useEffect ~ orderId",
      orderId
    );
    if (orderId && rows.invoiceNumber) {
      setIsFetchingPdf(false);
      const invoiceDate = moment(createdAt, "DD-MM-YYYY").format("YYYY-MM-DD");
      getEInvoice({
        invoiceNumber: rows.invoiceNumber,
        invoiceDate,
      })
        .then((response) => {
          const blob = new Blob([response], { type: "application/pdf" });
          const objectUrl = window.URL.createObjectURL(blob);
          setInvoicePdf(objectUrl);
          setIsFetchingPdf(false);
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
          setIsFetchingPdf(false);
        });
    } else setIsFetchingPdf(false);
  }, [orderId, rows]);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getOrderDetails = useGetOrderDetails();
  const getEInvoice = useGetEInvoice();
  const intl = useIntl();

  // Event Handlers

  // Other
  const returnNumber = (key, value, xs, md) => (
    <Grid
      item
      xs={xs}
      md={md}
      style={{ display: "flex", alignItems: "center" }}
    >
      <Typography variant="subtitle2" style={{ margin: 4 }}>
        <LocalizedMessage id={value} /> :
      </Typography>
      <Typography variant="body2">{numeral(key).format("0.00a")}</Typography>
    </Grid>
  );
  const returnValue = (value) => {
    return value !== null
      ? typeof value === "boolean"
        ? value
          ? "Cancelled"
          : "Applied"
        : value
      : "";
  };
  const returnString = (key, value, xs, md, style = {}) => {
    let val = returnValue(key);
    return (
      (val || val === 0) && (
        <Grid
          item
          xs={xs}
          md={md}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="subtitle2" style={{ margin: 4 }}>
            <LocalizedMessage id={value} /> :
          </Typography>
          <Typography variant="body2" style={style}>
            {isNaN(val) || value.search("Amount") === -1
              ? val
              : numeral(val).format("0.00a")}
          </Typography>
        </Grid>
      )
    );
  };

  const handleClose = () => {
    setOpen(false);
  };
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle id="alert-dialog-title">
        <LocalizedMessage id="sales.orderDetailsDialog.pageTitle" />
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent
        style={{
          height: "100vh",
        }}
      >
        {isFetchingDetails ? (
          <>
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "4px", height: "5vh", width: "100%" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "4px", height: "5vh", width: "100%" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "4px", height: "5vh", width: "100%" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "4px", height: "5vh", width: "100%" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "4px", height: "5vh", width: "100%" }}
            />
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "4px", height: "5vh", width: "100%" }}
            />
          </>
        ) : (
          rows.id && (
            <>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>
                    <LocalizedMessage id="sales.orderDetailsDialog.pageTitle" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {rows["orderStatusId"] === "Open" ? (
                        <Chip
                          label={
                            <LocalizedMessage
                              id={`setup.printersDesign.${rows["orderStatusId"]}`}
                            />
                          }
                          style={{
                            backgroundColor: "#00c853",
                            color: "white",
                            margin: 8,
                          }}
                        />
                      ) : rows["orderStatusId"] === "Closed" ? (
                        <Chip
                          label={
                            <LocalizedMessage
                              id={`setup.printersDesign.${rows["orderStatusId"]}`}
                            />
                          }
                          style={{
                            backgroundColor: "silver",
                            color: "white",
                            margin: 8,
                          }}
                        />
                      ) : rows["orderStatusId"] === "Merged" ? (
                        <Chip
                          label={
                            <LocalizedMessage
                              id={`setup.printersDesign.${rows["orderStatusId"]}`}
                            />
                          }
                          style={{
                            backgroundColor: "#fbc02d",
                            color: "white",
                            margin: 8,
                          }}
                        />
                      ) : rows["orderStatusId"] === "Void" ? (
                        <Chip
                          label={
                            <LocalizedMessage
                              id={`setup.printersDesign.${rows["orderStatusId"]}`}
                            />
                          }
                          style={{
                            backgroundColor: "#c62828",
                            color: "white",
                            margin: 8,
                          }}
                        />
                      ) : rows["orderStatusId"] === "Returned" ? (
                        <Chip
                          label={
                            <LocalizedMessage
                              id={`setup.printersDesign.${rows["orderStatusId"]}`}
                            />
                          }
                          style={{
                            backgroundColor: "#f361b5",
                            color: "white",
                            margin: 8,
                          }}
                        />
                      ) : null}
                      {rows["isCharged"] ? (
                        <Chip
                          label={
                            <LocalizedMessage id="sales.orderDetailsDialog.charged" />
                          }
                          style={{ backgroundColor: "#00c853", color: "white" }}
                        />
                      ) : (
                        <Chip
                          label={
                            <LocalizedMessage id="sales.orderDetailsDialog.notCharged" />
                          }
                          style={{ backgroundColor: "#c62828", color: "white" }}
                        />
                      )}
                    </Grid>

                    {returnString(
                      rows["orderDay"].value,
                      "report.orderDay",
                      12
                    )}
                    {returnString(
                      rows.salesSection?.name,
                      "common.section",
                      12,
                      6
                    )}
                    {returnString(
                      rows["salesSectionTypeId"],
                      "setup.salesChannel.salesSectionType",
                      12,
                      6
                    )}

                    {rows["isOnline"] &&
                      returnString(
                        rows["onlineStatusId"],
                        "sales.orderDetailsDialog.onlineStatusId",
                        12
                      )}
                    {returnString(
                      rows["invoiceNumber"],
                      "sales.orderDetailsDialog.invoiceNumber",
                      12,
                      6
                    )}
                    {returnString(
                      rows["orderNumberFormatted"],
                      "setup.printersDesign.orderNumberFormatted",
                      12,
                      6
                    )}
                    {returnString(
                      rows.table?.number,
                      "setup.table.number",
                      12,
                      6
                    )}
                    {returnString(
                      rows.tableCustomersCount,
                      "crm.customersCount",
                      12,
                      6
                    )}
                    {returnString(
                      moment(rows["createdAt"]).format(
                        "dddd, MMMM Do YYYY, h:mm:ss a"
                      ),
                      "common.createdAt",
                      12,
                      6
                    )}
                    {returnString(
                      rows["creator"].name,
                      "sales.orderDetailsDialog.creator",
                      12,
                      6
                    )}
                    {rows["createdAt"] !== rows["updatedAt"] &&
                      returnString(
                        moment(rows["updatedAt"]).format(
                          "dddd, MMMM Do YYYY, h:mm:ss a"
                        ),
                        "sales.orderDetailsDialog.updatedAt",
                        12,
                        6
                      )}
                    {rows["createdAt"] !== rows["updatedAt"] &&
                      returnString(
                        rows["modifier"]?.name,
                        "sales.orderDetailsDialog.modifier",
                        12,
                        6
                      )}
                    {returnString(
                      rows["closer"]?.name,
                      "sales.orderDetailsDialog.closer",
                      12,
                      6
                    )}
                    {returnString(
                      rows["orderNotes"],
                      "setup.orderNote.pageTitle",
                      12,
                      6
                    )}
                    {returnString(
                      rows["orderCancellationReason"],
                      "setup.cancellationReason.orderCancellationReason.pageTitle",
                      12,
                      6
                    )}
                    {returnString(
                      rows["onlineOrderCancellationReason"],
                      "setup.cancellationReason.onlineOrderCancellationReason.pageTitle",
                      12,
                      6
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>
                    <LocalizedMessage id="sales.orderDetailsDialog.totals" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {returnNumber(
                      rows["totalDiscountAmount"],
                      "sales.orderDetailsDialog.totalDiscountAmount",
                      12
                    )}
                    {returnNumber(
                      rows["totalTaxAmount"],
                      "sales.orderDetailsDialog.totalTaxAmount",
                      12
                    )}
                    {returnNumber(
                      rows["totalSurchargeAmount"],
                      "sales.orderDetailsDialog.totalSurchargeAmount",
                      12
                    )}
                    {returnNumber(
                      rows["subTotalAmount"],
                      "sales.orderDetailsDialog.subTotalAmount",
                      12
                    )}
                    {returnNumber(
                      rows["grandTotalAmount"],
                      "sales.orderDetailsDialog.grandTotalAmount",
                      12
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>
                    <LocalizedMessage id="sales.orderDetailsDialog.orderHistory" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  {rows["orderHistory"].map((transaction) => {
                    return (
                      <Paper
                        elevation={2}
                        style={{
                          padding: "16px",
                          display: "flex",
                          width: "100%",
                          margin: "4px",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {transaction.statusId === "Open" ? (
                              <Chip
                                label={
                                  <LocalizedMessage
                                    id={`setup.printersDesign.${transaction.statusId}`}
                                  />
                                }
                                style={{
                                  backgroundColor: "#00c853",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : transaction.statusId === "Closed" ? (
                              <Chip
                                label={
                                  <LocalizedMessage
                                    id={`setup.printersDesign.${transaction.statusId}`}
                                  />
                                }
                                style={{
                                  backgroundColor: "silver",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : transaction.statusId === "Merged" ? (
                              <Chip
                                label={
                                  <LocalizedMessage
                                    id={`setup.printersDesign.${transaction.statusId}`}
                                  />
                                }
                                style={{
                                  backgroundColor: "#fbc02d",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : transaction.statusId === "Void" ? (
                              <Chip
                                label={
                                  <LocalizedMessage
                                    id={`setup.printersDesign.${transaction.statusId}`}
                                  />
                                }
                                style={{
                                  backgroundColor: "#c62828",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : transaction.statusId === "Returned" ? (
                              <Chip
                                label={
                                  <LocalizedMessage
                                    id={`setup.printersDesign.${transaction.statusId}`}
                                  />
                                }
                                style={{
                                  backgroundColor: "#f361b5",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : null}
                          </Grid>
                          {returnString(
                            moment(transaction.createdAt).format(
                              "dddd, MMMM Do YYYY, h:mm:ss a"
                            ),
                            "common.createdAt",
                            12,
                            4
                          )}
                          {returnString(
                            transaction.by?.name,
                            "sales.orderDetailsDialog.creator",
                            12,
                            4
                          )}
                        </Grid>
                      </Paper>
                    );
                  })}
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>
                    <LocalizedMessage id="setup.payment.pageTitle" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  {rows["orderPaymentMethods"].map((paymentMethod) => {
                    return (
                      <Paper
                        elevation={2}
                        style={{
                          padding: "16px",
                          display: "flex",
                          width: "100%",
                          margin: "4px",
                        }}
                      >
                        <Grid container spacing={2}>
                          {returnString(
                            paymentMethod.paymentMethod?.name,
                            "rolesNames.paymentMethod",
                            12,
                            6
                          )}
                          {returnNumber(
                            paymentMethod.amount,
                            "sales.orderDetailsDialog.amount",
                            12,
                            6
                          )}
                          {paymentMethod.paymentMethodTypeId === "cash" &&
                            returnNumber(
                              paymentMethod["payedAmount"],
                              "sales.orderDetailsDialog.payedAmount",
                              12,
                              6
                            )}
                          {paymentMethod.paymentMethodTypeId === "cash" &&
                            returnNumber(
                              paymentMethod["remainderAmount"],
                              "sales.orderDetailsDialog.remainderAmount",
                              12,
                              6
                            )}
                          {returnString(
                            moment(paymentMethod.createdAt).format(
                              "dddd, MMMM Do YYYY, h:mm:ss a"
                            ),
                            "common.createdAt",
                            12,
                            6
                          )}
                          {returnString(
                            paymentMethod.cashier?.name,
                            "sales.orderDetailsDialog.creator",
                            12,
                            6
                          )}
                        </Grid>
                      </Paper>
                    );
                  })}
                </AccordionDetails>
              </Accordion>

              {rows["customerId"] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.customersDetails" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {returnString(rows["customerName"], "common.name", 12, 4)}
                      {returnString(
                        rows["customerPhone"],
                        "auth.register.phone",
                        12,
                        4
                      )}
                      {returnString(
                        rows["customerAddress"],
                        "common.address",
                        12,
                        4
                      )}
                      {returnString(
                        rows["customerLongitude"],
                        "sales.orderDetailsDialog.customerLongitude",
                        12,
                        4
                      )}
                      {returnString(
                        rows["customerLatitude"],
                        "sales.orderDetailsDialog.customerLatitude",
                        12,
                        4
                      )}
                      {returnString(
                        rows["deliveryTime"],
                        "setup.zone.deliveryTime",
                        12,
                        4
                      )}
                      {returnString(
                        rows["typeNotes"],
                        "sales.orderDetailsDialog.typeNotes",
                        6,
                        4
                      )}
                      {returnString(
                        rows["driver"]?.name,
                        "report.driver",
                        6,
                        4
                      )}
                      {returnString(rows["zone"]?.name, "report.zone", 6, 4)}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )}

              {rows["deliveryStatusOrderHistory"][0] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.orderDetailsDialog.deliveryStatusOrderHistory" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {rows["deliveryStatusOrderHistory"].map(
                      (deliveryStatusOrder) => {
                        return (
                          <Paper
                            elevation={2}
                            style={{
                              padding: "16px",
                              display: "flex",
                              width: "100%",
                              margin: "4px",
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                {rows["deliveryStatusId"] === "delivered" ? (
                                  <Chip
                                    label={intl.formatMessage({
                                      id: "sales.orderDetailsDialog.delivered",
                                    })}
                                    style={{
                                      backgroundColor: "#00c853",
                                      color: "white",
                                      margin: 8,
                                    }}
                                  />
                                ) : rows["deliveryStatusId"] === "cancelled" ? (
                                  <Chip
                                    label={intl.formatMessage({
                                      id: "sales.orderDetailsDialog.cancelled",
                                    })}
                                    style={{
                                      backgroundColor: "#c62828",
                                      color: "white",
                                      margin: 8,
                                    }}
                                  />
                                ) : rows["deliveryStatusId"] === "pending" ? (
                                  <Chip
                                    label={intl.formatMessage({
                                      id: "sales.orderDetailsDialog.pending",
                                    })}
                                    style={{
                                      backgroundColor: "silver",
                                      color: "inherit",
                                      margin: 8,
                                    }}
                                  />
                                ) : rows["deliveryStatusId"] === "refused" ? (
                                  <Chip
                                    label={intl.formatMessage({
                                      id: "sales.orderDetailsDialog.refused",
                                    })}
                                    style={{
                                      backgroundColor: "#f361b5",
                                      color: "white",
                                      margin: 8,
                                    }}
                                  />
                                ) : rows["deliveryStatusId"] === "accepted" ? (
                                  <Chip
                                    label={intl.formatMessage({
                                      id: "sales.orderDetailsDialog.accepted",
                                    })}
                                    color="primary"
                                    style={{
                                      margin: 8,
                                    }}
                                  />
                                ) : rows["deliveryStatusId"] === "out" ? (
                                  <Chip
                                    label={intl.formatMessage({
                                      id: "sales.orderDetailsDialog.out",
                                    })}
                                    style={{
                                      backgroundColor: "#fbc02d",
                                      color: "white",
                                      margin: 8,
                                    }}
                                  />
                                ) : null}
                              </Grid>
                              {
                                returnString(
                                  deliveryStatusOrder.driver?.name,
                                  "report.driver",
                                  12
                                ) /**@TODO may be wrong */
                              }
                            </Grid>
                          </Paper>
                        );
                      }
                    )}
                  </AccordionDetails>
                </Accordion>
              )}

              {rows["orderSalesSectionDiscountDetails"][0] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.orderDetailsDialog.orderSalesChannelDiscountDetails" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {rows["orderSalesSectionDiscountDetails"].map((el) => {
                      return (
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {el["isCancelled"] && (
                              <Grid item xs={12}>
                                <Chip
                                  label={intl.formatMessage({
                                    id: "sales.orderDetailsDialog.isCancelled",
                                  })}
                                  style={{
                                    backgroundColor: "#c62828",
                                    color: "white",
                                  }}
                                />
                              </Grid>
                            )}
                            {returnString(
                              el.creator.name,
                              "sales.orderDetailsDialog.creator",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el.createdAt).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "common.createdAt",
                              12,
                              6
                            )}
                            {returnString(
                              el["modifier"]?.name,
                              "sales.orderDetailsDialog.modifier",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el["updatedAt"]).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "sales.orderDetailsDialog.updatedAt",
                              12,
                              6
                            )}
                            {returnString(
                              el.discount?.name,
                              "common.name",
                              12,
                              6
                            )}
                            {returnString(
                              el.discount?.otherName,
                              "common.otherName",
                              12,
                              6
                            )}
                            {returnString(
                              el.amount,
                              "sales.orderDetailsDialog.amount",
                              12,
                              6
                            )}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}

              {rows["orderSalesSectionTaxDetails"][0] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.orderDetailsDialog.orderSalesChannelTaxDetails" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {rows["orderSalesSectionTaxDetails"].map((el) => {
                      return (
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {el["isCancelled"] && (
                              <Grid item xs={12}>
                                <Chip
                                  label={intl.formatMessage({
                                    id: "sales.orderDetailsDialog.isCancelled",
                                  })}
                                  style={{
                                    backgroundColor: "#c62828",
                                    color: "white",
                                  }}
                                />
                              </Grid>
                            )}
                            {returnString(
                              el.creator.name,
                              "sales.orderDetailsDialog.creator",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el.createdAt).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "common.createdAt",
                              12,
                              6
                            )}
                            {returnString(
                              el["modifier"]?.name,
                              "sales.orderDetailsDialog.modifier",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el["updatedAt"]).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "sales.orderDetailsDialog.updatedAt",
                              12,
                              6
                            )}
                            {returnString(el.tax?.name, "common.name", 12, 6)}
                            {returnString(
                              el.tax?.otherName,
                              "common.otherName",
                              12,
                              6
                            )}
                            {returnString(
                              el.amount,
                              "sales.orderDetailsDialog.amount",
                              12,
                              6
                            )}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}

              {rows["orderSurchargeSalesSectionDetails"][0] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.orderDetailsDialog.orderSurchargeSalesSectionDetails" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {rows["orderSurchargeSalesSectionDetails"].map((el) => {
                      return (
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {el["isCancelled"] && (
                              <Grid item xs={12}>
                                <Chip
                                  label={intl.formatMessage({
                                    id: "sales.orderDetailsDialog.isCancelled",
                                  })}
                                  style={{
                                    backgroundColor: "#c62828",
                                    color: "white",
                                  }}
                                />
                              </Grid>
                            )}
                            {returnString(
                              el.creator.name,
                              "sales.orderDetailsDialog.creator",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el.createdAt).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "common.createdAt",
                              12,
                              6
                            )}
                            {returnString(
                              el["modifier"]?.name,
                              "sales.orderDetailsDialog.modifier",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el["updatedAt"]).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "sales.orderDetailsDialog.updatedAt",
                              12,
                              6
                            )}
                            {returnString(
                              el.surcharge?.name,
                              "common.name",
                              12,
                              6
                            )}
                            {returnString(
                              el.surcharge?.otherName,
                              "common.otherName",
                              12,
                              6
                            )}
                            {returnString(
                              el.amount,
                              "sales.orderDetailsDialog.amount",
                              12,
                              6
                            )}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}

              {rows["orderSurchargeTaxDetails"][0] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.orderDetailsDialog.orderSurchargeSalesSectionDetails" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {rows["orderSurchargeTaxDetails"].map((el) => {
                      return (
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {el["isCancelled"] && (
                              <Grid item xs={12}>
                                <Chip
                                  label={intl.formatMessage({
                                    id: "sales.orderDetailsDialog.isCancelled",
                                  })}
                                  style={{
                                    backgroundColor: "#c62828",
                                    color: "white",
                                  }}
                                />
                              </Grid>
                            )}
                            {returnString(
                              el.creator.name,
                              "sales.orderDetailsDialog.creator",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el.createdAt).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "common.createdAt",
                              12,
                              6
                            )}
                            {returnString(
                              el["modifier"]?.name,
                              "sales.orderDetailsDialog.modifier",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el["updatedAt"]).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "sales.orderDetailsDialog.updatedAt",
                              12,
                              6
                            )}
                            {returnString(
                              el.surcharge?.name,
                              "surchargeName",
                              12,
                              6
                            )}
                            {returnString(
                              el.surcharge?.otherName,
                              "sales.orderDetailsDialog.surchargeOtherName",
                              12,
                              6
                            )}
                            {returnString(
                              el.tax?.name,
                              "sales.orderDetailsDialog.taxName",
                              12,
                              6
                            )}
                            {returnString(
                              el.tax?.otherName,
                              "sales.orderDetailsDialog.taxOtherName",
                              12,
                              6
                            )}
                            {returnString(el.amount, "report.taxAmount", 12, 6)}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}

              {rows["orderEmployeeDiscountDetails"][0] && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel3a-header"
                  >
                    <Typography className={classes.heading}>
                      <LocalizedMessage id="sales.orderDetailsDialog.orderEmployeeDiscountDetails" />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: "block" }}>
                    {rows["orderEmployeeDiscountDetails"].map((el) => {
                      return (
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid container spacing={2}>
                            {el["isCancelled"] && (
                              <Grid item xs={12}>
                                <Chip
                                  label={intl.formatMessage({
                                    id: "sales.orderDetailsDialog.isCancelled",
                                  })}
                                  style={{
                                    backgroundColor: "#c62828",
                                    color: "white",
                                  }}
                                />
                              </Grid>
                            )}
                            {returnString(
                              el.creator.name,
                              "sales.orderDetailsDialog.creator",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el.createdAt).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "common.createdAt",
                              12,
                              6
                            )}
                            {returnString(
                              el["modifier"]?.name,
                              "sales.orderDetailsDialog.modifier",
                              12,
                              6
                            )}
                            {returnString(
                              moment(el["updatedAt"]).format(
                                "dddd, MMMM Do YYYY, h:mm:ss a"
                              ),
                              "sales.orderDetailsDialog.updatedAt",
                              12,
                              6
                            )}
                            {returnString(
                              el.discount?.name,
                              "common.name",
                              12,
                              6
                            )}
                            {returnString(
                              el.discount?.otherName,
                              "common.otherName",
                              12,
                              6
                            )}
                            {returnString(
                              el.amount,
                              "sales.orderDetailsDialog.amount",
                              12,
                              6
                            )}
                          </Grid>
                        </Paper>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              )}

              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>
                    <LocalizedMessage id="setup.printersDesign.orderItemsDetails" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  <ItemTable orderItems={rows["items"]} />
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel3a-header"
                >
                  <Typography className={classes.heading}>
                    <LocalizedMessage id="sales.orderDetailsDialog.e-invoice" />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ display: "block" }}>
                  {invoicePdf ? (
                    <Box>
                      <App downSm={downSm} url={invoicePdf} />
                    </Box>
                  ) : (
                    <CircularProgress />
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )
        )}
      </DialogContent>
      <DialogActions>
        <FormActions onCLoseForm={handleClose} isSubmitting={false} isClose />

        {/* <Button
          style={{
            backgroundColor: "#0582D2",
            color: "#fff",
            margin: "16px",
          }}
          variant="contained"
          autoFocus
          onClick={handleClose}
          //startIcon={<CloseIcon />}
        >
          <LocalizedMessage id="common.close" />
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

OrderDetailsDialog.propTypes = {
  /**
   *
   */
};

OrderDetailsDialog.defaultProps = {
  /**
   *
   */
};

export default OrderDetailsDialog;
