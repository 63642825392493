// React
import React, { useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  CardHeader,
  Card,
  CardContent,
  Avatar,
  CardActions,
  useTheme,
  Typography,
  Divider,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import Skeleton from "@material-ui/lab/Skeleton";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components
import Memo from "Components/Memo";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import Color from "color";
import { useSnackbar } from "notistack";

// Services
import { useGetSubscriptionsLinks } from "Services/Subscription/";
import SubscriptionPlansTable from "./SubscriptionPlansTable";

// Styles
const useStyles = makeStyles(({ spacing }) => ({
  card: ({ color }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 16,
    transition: "0.2s",
    // maxHeight: 400,
    maxWidth: "100%",
    //minWidth: "100%",
    //  minHeight: "100%",
    boxShadow: "none",
    margin: 16,
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
      transform: "scale(1.1)",
    },
  }),
  avatar: {
    backgroundColor: "#2f57a5",
  },
  actions: {
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#a6a6a629",
  },
  content: ({ color }) => {
    return {
      height: "100%",
      minWidth: "100%",
      minHeight: "100%",
      display: "flex",
      flexDirection: "column",
      // justifyContent: "space-around",
      // backgroundColor: color,
      alignItems: "flex-start",
      backgroundColor: "#a6a6a629",
      padding: "1rem 1.5rem 1.5rem",
    };
  },
  title: {
    fontFamily: "Roboto,Changa",
    fontSize: "2.6rem",
    color: "#0582D2",
    fontWeight: 500,
    //padding: "1.5rem 6rem 1.5rem 6rem",
    alignItems: "cneter",
    //textTransform: "uppercase",
    //alignSelf: "center",
  },
  subtitle: {
    fontFamily: "Montserrat",
    // color: "#fff",
    opacity: 0.87,
    marginLeft: "1rem",
    fontWeight: 500,
    fontSize: "1rem",
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: 20,
    height: 20,
    color: "#2f57a5",
  },
  ol: {
    flexWrap: "noWrap",
  },
  li: {
    display: "flex",
    "& a": {
      display: "flex",
      alignItems: "center",
    },
  },
  mediaClasses: {
    width: "100%",
    height: "0px",
    paddingBottom: "75%",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    filter: "grayscale(40%)",
    // filter: "sepia(100%) hue-rotate(190deg) saturate(500%)"
  },
}));

// Ad-Hoc Components

/**
 * @name SubscriptionDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SubscriptionDialog = ({ open, onCloseDialog, branchOrDevice }) => {
  // Theme & Style Hooks
  const theme = useTheme();
  const classes = useStyles();
  let history = useHistory();

  // Global State Hooks
  const [subscriptionsPlans] = useGlobalState(
    "subscriptionsPlans.subscriptionsPlans"
  );

  // State Hooks
  const [isSubmitting, setIsSubmitting] = useState(false);
  // Effect Hooks

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getSubscriptionsLinks = useGetSubscriptionsLinks({
    languageKey: "subscription",
  });

  // Event Handlers
  const onSubscribeNowClick = (monthlyOrYealry) => {
    setIsSubmitting(true);
    getSubscriptionsLinks({ branchOrDevice, monthlyOrYealry })
      .then((response) => {
        window.open(response, "_self");
        //onCloseDialog();
        //history.go(0);
      })
      .catch((error) => {
        displaySnackbarErrors(error, enqueueSnackbar);
      });
  };

  // Other
  console.log("isSubmitting", isSubmitting);

  // Component Render
  return (
    <Memo deps={[open, subscriptionsPlans]}>
      {() => (
        <Dialog
          open={open}
          onClose={onCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullScreen
          style={{
            direction: theme.direction,
          }}
        >
          <DialogTitle id="alert-dialog-title">
            {<LocalizedMessage id="subscriptionDialog.pageTitle" />}
          </DialogTitle>
          <Divider variant="middle" />

          {subscriptionsPlans.isFetchingRows ? (
            <Skeleton
              animation="wave"
              variant="rect"
              style={{ margin: "8px 24px", height: "90vh", width: "95%" }}
            />
          ) : (
            <DialogContent
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "center",
                flexDirection: "row",
                marginTop: "100",
              }}
            >
              <Grid
                direction="column"
                style={{
                  width: "100%",
                }}
              >
                <Grid
                  container
                  spacing={4}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {branchOrDevice === "branch" && <SubscriptionPlansTable />}

                  {branchOrDevice === "device" && (
                    <>
                      <Grid item xs={12} md={6} lg={3}>
                        <Card className={classes.card} elevation={2}>
                          <CardHeader
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                              >
                                M
                              </Avatar>
                            }
                            title={
                              <Typography variant="h5">
                                Monthly Subscription
                              </Typography>
                            }
                          />
                          <CardContent className={classes.content}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                alignSelf: "center",
                              }}
                            >
                              <Typography
                                className={classes.title}
                              >{`10$`}</Typography>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"One device"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Low price"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Server or Client devices are available"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {
                                  "Exchanging subscriptions among devices is available"
                                }
                              </Typography>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Unlimited orders through one device"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Available for one month"}
                              </Typography>
                            </Box>
                          </CardContent>
                          <CardActions className={classes.actions}>
                            <Button
                              disabled={isSubmitting}
                              onClick={() => onSubscribeNowClick("monthly")}
                              size="large"
                              style={{
                                margin: "20px",
                                color: "white",
                                backgroundColor: "#0582D2",
                              }}
                              variant="contained"
                            >
                              {isSubmitting ? (
                                <CircularProgress
                                  size={20}
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                              )}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>

                      <Grid item xs={12} md={6} lg={3}>
                        <Card className={classes.card}>
                          {/* <CardMedia classes={mediaStyles} image={image} /> */}
                          <CardHeader
                            avatar={
                              <Avatar
                                aria-label="recipe"
                                className={classes.avatar}
                              >
                                Y
                              </Avatar>
                            }
                            title={
                              <Typography variant="h5">
                                Yearly Subscription
                              </Typography>
                            }
                          />
                          <CardContent className={classes.content}>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                                alignSelf: "center",
                              }}
                            >
                              <Typography
                                className={classes.title}
                              >{`100$`}</Typography>
                            </Box>

                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                One device
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Low price"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Server or Client devices are available"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {
                                  "Exchanging subscriptions among devices is available"
                                }
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Unlimited orders through one device"}
                              </Typography>
                            </Box>
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "row",
                              }}
                            >
                              <CheckCircleRoundedIcon
                                className={classes.icon}
                              />
                              <Typography className={classes.subtitle}>
                                {"Available for one year"}
                              </Typography>
                            </Box>
                          </CardContent>
                          <CardActions className={classes.actions}>
                            <Button
                              disabled={isSubmitting}
                              onClick={() => onSubscribeNowClick("yearly")}
                              size="large"
                              style={{
                                margin: "20px",
                                color: "white",
                                backgroundColor: "#0582D2",
                              }}
                              variant="contained"
                            >
                              {isSubmitting ? (
                                <CircularProgress
                                  size={20}
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                              )}
                            </Button>
                          </CardActions>
                        </Card>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
          )}
          <DialogActions>
            <FormActions
              onCLoseForm={onCloseDialog}
              isSubmitting={false}
              isClose
            />

            {/* <Button
              style={{
                backgroundColor: "#0582D2",
                color: "#fff",
                margin: "16px",
              }}
              variant="contained"
              autoFocus
              onClick={onCloseDialog}
              //startIcon={<CloseIcon />}
            >
              <LocalizedMessage id="common.close" />
            </Button> */}
          </DialogActions>
        </Dialog>
      )}
    </Memo>
  );
};

SubscriptionDialog.propTypes = {
  /**
   *
   */
};

SubscriptionDialog.defaultProps = {
  /**
   *
   */
};

export default SubscriptionDialog;
