// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  IconButton,
  Tooltip,
  Fade,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { Info as InfoIcon, Close as CloseIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useGlobalState } from "Helpers/GlobalState";

// Components

// Factories

// Screens

// Assets

// Help
import getHelp from "Help";

// Third Parties
import ReactMarkdown from "react-markdown";
import { useSnackbar } from "notistack";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalTitle: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  modalLoaderContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  skeletonWrapper: {
    padding: "12px",
  },
  skeleton: {
    display: "flex",
    flex: 1,
  },
  largeSkeleton: {
    height: "35px",
    width: "35px",
  },
  mediumSkeleton: {
    height: "24px",
    width: "24px",
  },
  smallSkeleton: {
    height: "20px",
    width: "20px",
  },
}));

/**
 * @name HelpIcon
 * @summary HelpIcon Component
 * @category
 * @component
 * @description
 * >
 */
const HelpIcon = (props) => {
  // Props Destructing
  const {
    className,
    style,
    tooltip,
    helpKey,
    modalTitle,
    size,
    isLoading,
    isSubmitting,
    ...otherProps
  } = props;

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [locale] = useGlobalState("global.locale");

  // State Hooks
  const [helpTextCache, setHelpTextCache] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);

  // Effect Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Other Hooks

  // Event Handlers
  const openHelpHandler = (e) => {
    e.preventDefault();
    if (helpKey) {
      setModalOpen(true);
      if (!helpTextCache) {
        setModalLoading(true);
        getHelp(helpKey, locale).then((response) => {
          const [helpMarkdown, success] = response;
          setModalLoading(false);
          if (success) {
            // Here is the markdown
            setHelpTextCache(helpMarkdown);
          } else {
            setModalOpen(false);
            enqueueSnackbar("Failed to load help. Please try again", {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        });
      }
    }
  };

  const closeModalHandler = () => {
    setModalOpen(false);
  };

  // Other
  const information = (() => {
    if (tooltip) {
      return isLoading ? (
        <Box className={classes.skeletonWrapper}>
          <Skeleton
            animation="wave"
            variant="circle"
            className={[classes.skeleton, classes[size + "Skeleton"]].join(" ")}
          />
        </Box>
      ) : (
        <Tooltip
          title={tooltip}
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 500 }}
          placement="top"
        >
          <IconButton
            aria-label="info"
            onClick={openHelpHandler}
            className={classes.margin}
          >
            <InfoIcon fontSize={size} />
          </IconButton>
        </Tooltip>
      );
    }
  })();

  const modal = (() => (
    <Dialog open={modalOpen} onClose={closeModalHandler}>
      <DialogTitle disableTypography className={classes.modalTitle}>
        <Typography variant="h4">{modalTitle}</Typography>
        <IconButton
          aria-label="close"
          onClick={closeModalHandler}
          className={classes.modalCloseButton}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {modalLoading ? (
          <Box className={classes.modalLoaderContainer}>
            <CircularProgress size={24} />
            <Typography>Loading Help...</Typography>
          </Box>
        ) : (
          <ReactMarkdown source={helpTextCache} />
        )}
      </DialogContent>
    </Dialog>
  ))();

  // Component Render
  return (
    <Box className={className} {...otherProps}>
      <Box className={classes.root} style={style}>
        {information}
      </Box>
      {helpKey && modal}
    </Box>
  );
};

HelpIcon.propTypes = {
  /**
   *
   */
  size: PropTypes.string,
  isLoading: PropTypes.bool,
};

HelpIcon.defaultProps = {
  size: "large",
  isLoading: false,
};

export default HelpIcon;
