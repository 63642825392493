// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { Box, Typography, Button, CircularProgress } from "@material-ui/core";

// Globals
import { useGlobalState } from "Helpers/GlobalState";
import { useHistory } from "react-router-dom";

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import Title from "Components/Title";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components

// Factories

// Screens

// Services
import { useConfirmEmail } from "Services/Auth";

// Assets
import confirmEmailVector from "Assets/Images/confirmEmail.jpg";

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#FAFAFD",
    alignItems: "center",
    justifyContent: "center",
  },
  vector: {
    width: "70%",
    alignSelf: "center",
    marginBottom: theme.spacing(1),
  },
  confirmed: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Ad-Hoc Components

/**
 * @name MailConfirmation
 * @summary MailConfirmation Component
 * @category Screens / Auth / MailConfirmation
 * @component
 * @description
 */
const MailConfirmation = (props) => {
  // Props Destructing
  const { className, goBack, isForgetPasswordAnimationDone, ...otherProps } = props;

  // Theme & Style Hooks
  const classes = useStyles();
  // Global State Hooks
  const [currentComponent, setCurrentComponent] = useGlobalState("components.currentComponent");
  // State Hooks
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [message, setMessage] = useState("Wait for verification...");

  // Effect Hooks
  useEffect(() => {
    const urlParts = history.location.pathname.split("/");
    const token = urlParts[urlParts.length - 1];
    setIsSubmitting(true);
    confirmEmail({ token })
      .then(() => {
        enqueueSnackbar(<LocalizedMessage id="auth.emailConfirmation.success" />, {
          variant: "success",
          autoHideDuration: 5000,
        });
        setMessage(
          intl.formatMessage({
            id: "auth.emailConfirmation.verificationMessage",
          })
        );
        setIsSubmitting(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsSubmitting(false);

        setMessage(
          intl.formatMessage({
            id: "auth.emailConfirmation.error",
          })
        );
      });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const confirmEmail = useConfirmEmail({ languageKey: "auth.confirmEmail" });

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <Title pageTitle={<LocalizedMessage id="auth.register.pageTitle" />} />
      <img src={confirmEmailVector} className={classes.vector} alt="Confirm Email" />
      <Box className={classes.container}>
        <Typography component="h1" variant="h5" style={{ textAlign: "center" }}>
          {message}
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.confirmed}
          onClick={() => {
            setCurrentComponent("SignIn");
            history.replace("/auth");
          }}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            <LocalizedMessage id="auth.register.backToSignIn" />
          )}
        </Button>
      </Box>
    </Box>
  );
};

MailConfirmation.propTypes = {
  /**
   * A function that hides the MailConfirmation and show SignInForm
   */
  backToLoginHandler: PropTypes.func.isRequired,
};

export default MailConfirmation;
