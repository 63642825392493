// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Grid,
  Card,
  Grow,
  Paper,
  Popper,
  Button,
  Divider,
  MenuItem,
  MenuList,
  useTheme,
  CardHeader,
  Typography,
  CardContent,
  useMediaQuery,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Dialog,
  ClickAwayListener,
  CircularProgress,
} from "@material-ui/core";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ListOutlinedIcon from "@material-ui/icons/ListOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import { fade } from "@material-ui/core/styles/colorManipulator";
import DeleteIcon from "@material-ui/icons/Delete";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarErrors,
  displayenqueueSnackbar,
  displaySnackbarFormErrors,
} from "Utils/displaySnackbarErrors";

// Components
import Memo from "Components/Memo";
import LooseMemo from "Components/LooseMemo";
import AlertDialog from "Components/AlertDialog";
import QRDialog from "Components/QRDialog";
import ProgressDialog from "Components/ProgressDialog";
import DownloadTemplateDialog from "Components/DownloadTemplateDialog";
import { DragHandle } from "Components/Sortable";

// Factories

// Screens

// Assets

// Third Parties
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import FileDownload from "js-file-download";
import { useSnackbar } from "notistack";
import arrayMove from "array-move";

// Services
import { useGetMenuItemsTemplate, usePostMenuTemplate } from "Services/Items";

// Styles
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
    // "&:focus > $content, &$selected > $content": {
    //   color: ({ color }) => theme.palette.getContrastText(color),
    // },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {
    // minWidth: "0"
    // minWidth: "100%",
  },
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
  },
  typographyRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0.5, 0, 0.5),
  },
  labelIcon: {
    marginRight: theme.spacing(0, 0.1),
  },
  labelText: {
    fontWeight: "normal",
    flexGrow: 1,
    overflow: "auto",
  },
}));

const useStyles = makeStyles(() => ({
  root: {
    height: "auto",
    flexGrow: 1,
  },
}));

// Ad-Hoc Components
function StyledTreeItem(props, key) {
  const {
    category,
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    onAddItemClick,
    onAddClick,
    onEditClick,
    onDeleteClick,
    permissions,
    ...other
  } = props;

  const classes = useTreeItemStyles({ color });
  const Theme = useTheme();
  const [locale, setLocale] = useGlobalState("global.locale");
  const [open, setOpen] = useState(false);

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));
  const downMd = useMediaQuery(Theme.breakpoints.down("md"));

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  }

  return (
    <Box
      key={key}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      <TreeItem
        label={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <LooseMemo deps={[labelText]}>
              {() => (
                <Box className={classes.labelRoot}>
                  <Typography variant="body2" className={classes.labelText}>
                    {labelText}
                  </Typography>
                </Box>
              )}
            </LooseMemo>
            <Box display="flex" alignItems="center">
              <Memo deps={[category, locale, downSm]}>
                {() =>
                  downSm ? (
                    <>
                      <PopupState
                        variant="popover"
                        popupId="demo-popup-popover"
                      >
                        {(popupState) => (
                          <div>
                            {/* <MenuItem
                            {...bindToggle(popupState)}
                            aria-controls={
                              popupState.isOpen ? "menu-list-grow" : undefined
                            }
                            aria-haspopup="true"
                          >
                            <ListItemText primary={branch.name} />
                          </MenuItem> */}
                            <ListOutlinedIcon
                              {...bindToggle(popupState)}
                              aria-controls={
                                popupState.isOpen ? "menu-list-grow" : undefined
                              }
                              aria-haspopup="true"
                              fontSize="small"
                              style={{ margin: Theme.spacing(1, 1, 0, 0) }}
                            />
                            <Popper
                              style={{ zIndex: 10000 }}
                              {...bindPopper(popupState)}
                              transition
                            >
                              {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={popupState.close}
                                    >
                                      <MenuList
                                        autoFocusItem={popupState.isOpen}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                        style={{
                                          maxHeight: "90vh",
                                          overflow: "auto",
                                        }}
                                      >
                                        <Box
                                          display="flex"
                                          flexDirection="column"
                                        >
                                          {(permissions.isAdmin ||
                                            permissions.category.includes(
                                              "add"
                                            )) && (
                                            <>
                                              {(permissions.isAdmin ||
                                                (permissions.item &&
                                                  permissions.item.includes(
                                                    "add"
                                                  ))) && (
                                                <MenuItem
                                                  disabled={!category.id}
                                                  onClick={() => {
                                                    popupState.close();
                                                    onAddItemClick();
                                                  }}
                                                  style={{ color: "#0000008A" }}
                                                >
                                                  <FastfoodIcon
                                                    className={
                                                      classes.labelIcon
                                                    }
                                                  />
                                                  <Box
                                                    className={
                                                      classes.typographyRoot
                                                    }
                                                  >
                                                    <Typography
                                                      className={
                                                        classes.labelText
                                                      }
                                                      variant="body2"
                                                      component="h2"
                                                    >
                                                      <LocalizedMessage id="category.addItem" />
                                                    </Typography>
                                                  </Box>
                                                </MenuItem>
                                              )}
                                              <MenuItem
                                                onClick={() => {
                                                  popupState.close();
                                                  onAddClick();
                                                }}
                                                size="small"
                                                className={classes.labelIcon}
                                                style={{ color: "#0000008A" }}
                                              >
                                                <AddIcon />
                                                <Box
                                                  className={
                                                    classes.typographyRoot
                                                  }
                                                >
                                                  <Typography
                                                    className={
                                                      classes.labelText
                                                    }
                                                    variant="body2"
                                                    component="h2"
                                                  >
                                                    <LocalizedMessage id="category.newSubCategory" />
                                                  </Typography>
                                                </Box>
                                              </MenuItem>
                                            </>
                                          )}
                                          {(permissions.isAdmin ||
                                            permissions.category.includes(
                                              "edit"
                                            )) && (
                                            <MenuItem
                                              onClick={() => {
                                                popupState.close();
                                                onEditClick();
                                              }}
                                              className={classes.labelIcon}
                                              style={{ color: "#0000008A" }}
                                            >
                                              <EditIcon />
                                              <Box
                                                className={
                                                  classes.typographyRoot
                                                }
                                              >
                                                <Typography
                                                  className={classes.labelText}
                                                  variant="body2"
                                                  component="h2"
                                                >
                                                  <LocalizedMessage id="category.editCategory" />
                                                </Typography>
                                              </Box>
                                            </MenuItem>
                                          )}
                                          {/* {(permissions.isAdmin ||
                                          permissions.category.includes(
                                            "delete"
                                          )) && (
                                            <MenuItem
                                              onClick={() => {
                                                popupState.close()
                                                setOpen(true)
                                              }}
                                              style={{ color: "#0000008A" }}
                                              className={classes.labelIcon}
                                            >
                                              <DeleteIcon />
                                              <Box
                                                className={classes.typographyRoot}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  component="h2"
                                                  className={classes.labelText}
                                                >
                                                  <LocalizedMessage id="general.alertDialog.deleteText" />
                                                </Typography>
                                              </Box>
                                            </MenuItem>
                                          )} */}
                                        </Box>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </div>
                        )}
                      </PopupState>
                    </>
                  ) : (
                    <Box display="flex" minHeight={30}>
                      {(permissions.isAdmin ||
                        permissions.category.includes("add")) && (
                        <>
                          {(permissions.isAdmin ||
                            (permissions.item &&
                              permissions.item.includes("add"))) && (
                            <Button
                              size="small"
                              className={classes.labelIcon}
                              disabled={!category.id}
                              onClick={() => onAddItemClick()}
                              color="inherit"
                              startIcon={<FastfoodIcon />}
                              variant="contained"
                              style={{
                                margin: "0px 2px",
                                backgroundColor: "inherit",
                              }}
                              // style={{ backgroundColor: "inherit" }}
                            >
                              <Box className={classes.typographyRoot}>
                                <Typography
                                  className={classes.labelText}
                                  variant="body2"
                                  component="h2"
                                >
                                  <LocalizedMessage id="category.addItem" />
                                </Typography>
                              </Box>
                            </Button>
                          )}
                          <Button
                            onClick={onAddClick}
                            size="small"
                            className={classes.labelIcon}
                            color="inherit"
                            startIcon={<AddIcon />}
                            variant="contained"
                            style={{
                              margin: "0px 2px",
                              backgroundColor: "inherit",
                            }}
                            // style={{ backgroundColor: "inherit" }}
                          >
                            <Box className={classes.typographyRoot}>
                              <Typography
                                className={classes.labelText}
                                variant="body2"
                                component="h2"
                              >
                                <LocalizedMessage id="category.newSubCategory" />
                              </Typography>
                            </Box>
                          </Button>
                        </>
                      )}
                      {(permissions.isAdmin ||
                        permissions.category.includes("edit")) && (
                        // <Tooltip title="Edit">
                        <Button
                          onClick={onEditClick}
                          size="small"
                          className={classes.labelIcon}
                          color="inherit"
                          startIcon={<EditIcon />}
                          variant="contained"
                          style={{
                            margin: "0px 0px 0px 2px ",
                            backgroundColor: "inherit",
                          }}
                          // style={{ backgroundColor: "inherit" }}
                        >
                          <Box className={classes.typographyRoot}>
                            <Typography
                              className={classes.labelText}
                              variant="body2"
                              component="h2"
                            >
                              <LocalizedMessage id="category.editCategory" />
                            </Typography>
                          </Box>
                        </Button>
                        // </Tooltip>
                      )}
                      {/* {(permissions.isAdmin ||
                        permissions.category.includes("delete")) && (
                        // <Tooltip title="Delete">
                        <AlertDialog
                          onAgree={onDeleteClick}
                          size="small"
                          className={classes.labelIcon}
                          typographyClassName={classes.labelText}
                          typographyRootClassName={classes.typographyRoot}
                          deleteText
                        />

                        // </Tooltip>
                      )} */}
                    </Box>
                  )
                }
              </Memo>
              {downMd && <DragHandle />}
            </Box>
          </Box>
        }
        style={{
          "--tree-view-color": Theme.palette.getContrastText(color),
          "--tree-view-bg-color": bgColor,
        }}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      />
      {/* <AlertDialog id={account.id} onAgree={onDeleteClick} /> */}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <LocalizedMessage id="general.alertDialog.confirmMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            <LocalizedMessage id="general.alertDialog.disagree" />
          </Button>
          <Button
            onClick={() => {
              onDeleteClick();
              setOpen(false);
            }}
            color="primary"
            autoFocus
          >
            <LocalizedMessage id="general.alertDialog.agree" />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

/**
 * @name categoryList
 * @summary
 * @item
 * @component
 * @description
 * >
 */
const CategoryList = ({
  open,
  onCloseCategories,
  onOpenCategories,
  setCategoryFormOpen,
  setCategoryFormMode,
  setCategoryFormData,
  selectedCategory,
  setSelectedCategory,
  setOpenItemCategoryCalled,
  categories,
  getAllCategories,
  setCategories,
  onDelete,
  setEditId,
  // deletedIds,
  isFetchingCategories,
  permissions,
}) => {
  // Theme & Style Hooks
  const Theme = useTheme();
  const classes = useStyles();

  // Global State Hooks
  const [branch, setBranch] = useGlobalState("userData.branch");

  // State Hooks
  const [openDialog, setOpenDialog] = useState(false);
  const [openDownloadTemplateDialog, setOpenDownloadTemplateDialog] =
    useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = React.useState(false);
  const [menuItemsFile, setMenuItemsFile] = useState(null);
  const [expanded, setExpanded] = useState([]);

  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [imported, setImported] = useState(false);
  const [isUploadFailed, setIsUploadFailed] = useState(false);

  // Effect Hooks

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getMenuItemsTemplate = useGetMenuItemsTemplate();
  const postMenuTemplate = usePostMenuTemplate();

  const { enqueueSnackbar } = useSnackbar();

  const openAddCategoryForm = (parentCategory) => {
    setCategoryFormData({
      parentCategory:
        parentCategory.id != null
          ? {
              id: parentCategory.id,
              name: parentCategory.name,
            }
          : null,
    });
    setCategoryFormMode("add");
    setCategoryFormOpen(true);
  };

  const openEditCategoryForm = (category, parentCategory) => {
    setEditId(category.id);
    setCategoryFormData({
      ...category,
      parentCategory:
        parentCategory.id != null
          ? {
              id: parentCategory.id,
              name: parentCategory.name,
            }
          : null,
    });
    setCategoryFormMode("edit");
    setCategoryFormOpen(true);
  };

  const handleCategorySelection = (e, category) => {
    if (e.target.tagName !== "path" && e.target.tagName !== "svg")
      setExpanded((prev) => {
        if (prev.includes(category.id)) {
          return prev.filter((el) => el !== category.id);
        } else return [...prev, category.id];
      });
    setSelectedCategory(category);
  };

  // Event Handlers
  useEffect(() => {
    if (menuItemsFile) {
      handleImportMenu();
    }
  }, [menuItemsFile]);
  // Other

  const SortableStyledTreeItem = SortableElement(
    ({ index, category, parentLabel, parentID, level }) => (
      <StyledTreeItem
        key={index}
        nodeId={category.id}
        labelText={category.name}
        color={category.color}
        bgColor={fade(category.color, 0.7)}
        category={category}
        onAddItemClick={() => {
          setSelectedCategory(category);
          setOpenItemCategoryCalled(true);
        }}
        onAddClick={() =>
          openAddCategoryForm({
            name: category.name,
            id: category.id,
          })
        }
        onEditClick={() =>
          openEditCategoryForm(category, {
            name: parentLabel,
            id: parentID,
          })
        }
        onDeleteClick={() => onDelete(category)}
        onClick={(e) => handleCategorySelection(e, category)}
        permissions={permissions}
      >
        {renderChildren(
          category.children,
          level + 1,
          category.name,
          category.id
        )}
      </StyledTreeItem>
    )
  );

  const renderChildren = (cats, level, parentLabel, parentID) =>
    cats &&
    // cats.length &&
    cats.map((category, index) => {
      // if (!deletedIds.includes(category.id))
      return level === 1 ? (
        <SortableStyledTreeItem
          index={index}
          category={category}
          parentLabel={parentLabel}
          parentID={parentID}
          level={level}
        />
      ) : (
        <StyledTreeItem
          key={index}
          nodeId={category.id}
          labelText={category.name}
          color={category.color}
          bgColor={fade(category.color, 0.7)}
          category={category}
          onAddItemClick={() => {
            setSelectedCategory(category);
            setOpenItemCategoryCalled(true);
          }}
          onAddClick={() =>
            openAddCategoryForm({
              name: category.name,
              id: category.id,
            })
          }
          onEditClick={() =>
            openEditCategoryForm(category, {
              name: parentLabel,
              id: parentID,
            })
          }
          onDeleteClick={() => onDelete(category)}
          onClick={(e) => handleCategorySelection(e, category)}
          permissions={permissions}
        >
          {renderChildren(
            category.children,
            level + 1,
            category.name,
            category.id
          )}
        </StyledTreeItem>
      );
    });

  const SortableTreeView = SortableContainer(
    ({ Theme, classes, categories, selectedCategory, expanded }) => (
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={
          Theme.direction === "rtl" ? <ArrowLeftIcon /> : <ArrowRightIcon />
        }
        defaultEndIcon={<div style={{ width: 15 }} />}
        selected={selectedCategory.id}
        expanded={expanded}
        onNodeSelect={(e) => console.log("e.target.tagName", e.target.tagName)}
      >
        {renderChildren(categories, 1, null, null)}
      </TreeView>
    )
  );

  const { REACT_APP_QRLINK } = process.env;

  const qrUrl = REACT_APP_QRLINK + "/" + branch.id;

  const handleListKeyDown = (event) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };
  const handleExportMenuItem = () => {
    getMenuItemsTemplate() /** @TODO here we must convert the categoires array of items into just ids */
      .then((response) => {
        FileDownload(response, "Menu Items Template.xls");
        setIsFetchingPdf(false);
      })
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar);
        setIsFetchingPdf(false);
      });
  };

  const handleImportMenu = (event) => {
    setImported(false);
    setIsUploadFailed(false);

    postMenuTemplate(menuItemsFile)
      .then((response) => {
        displayenqueueSnackbar(enqueueSnackbar, "imported"); // To be deleted
        setImported(true);
        getAllCategories()
          .then((categories) => {
            setCategories(categories);
            setSelectedCategory(categories[0] ? categories[0] : {});
          })
          .catch((errors) => {
            displaySnackbarErrors(errors, enqueueSnackbar);
          });
      })
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar);
        setIsUploadFailed(true);
      });
  };
  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));
  const downMd = useMediaQuery(Theme.breakpoints.down("md"));

  // Component Render
  return (
    <>
      {!open && (
        <>
          <Button
            color="primary"
            variant="outlined"
            onClick={onOpenCategories}
            style={{
              marginBottom: Theme.spacing(1),
            }}
          >
            <LocalizedMessage id="category.openList" />
          </Button>
        </>
      )}
      {/* Start Categories List */}
      {isFetchingCategories && inputSkeleton ? (
        inputSkeleton
      ) : (
        <Memo
          deps={[
            open,
            categories,
            // deletedIds,
            selectedCategory?.id,
            openDialog,
            Theme.direction,
            progressDialogOpen,
            openDownloadTemplateDialog,
            imported,
            isUploadFailed,
            expanded,
          ]}
        >
          {() =>
            open && (
              <Grid item xs={12}>
                <Card ref={inputRef} style={{ overflow: "auto" }}>
                  <CardHeader
                    title={
                      <>
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Box>
                            <Typography variant="h6" component="h2">
                              <LocalizedMessage id="common.category" />
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Box>
                              <PopupState
                                variant="popover"
                                popupId="demo-popup-popover"
                              >
                                {(popupState) => (
                                  <>
                                    <ListOutlinedIcon
                                      {...bindToggle(popupState)}
                                      aria-controls={
                                        popupState.isOpen
                                          ? "menu-list-grow"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      style={{
                                        margin: Theme.spacing(1, 1, 0, 0),
                                      }}
                                    />
                                    <Popper
                                      style={{ zIndex: 10000 }}
                                      {...bindPopper(popupState)}
                                      transition
                                    >
                                      {({ TransitionProps }) => (
                                        <Grow {...TransitionProps}>
                                          <Paper>
                                            <ClickAwayListener
                                              onClickAway={popupState.close}
                                            >
                                              <MenuList
                                                autoFocusItem={
                                                  popupState.isOpen
                                                }
                                                id="menu-list-grow"
                                                onKeyDown={handleListKeyDown}
                                                style={{
                                                  maxHeight: "90vh",
                                                  overflow: "auto",
                                                }}
                                              >
                                                <Box
                                                  display="flex"
                                                  flexDirection="column"
                                                >
                                                  {(permissions.isAdmin ||
                                                    permissions.category) && (
                                                    <>
                                                      {(permissions.isAdmin ||
                                                        (permissions.item &&
                                                          permissions.item.includes(
                                                            "importItems"
                                                          ))) && (
                                                        <MenuItem
                                                          aria-label="Import Menu"
                                                          component="label"
                                                          size="small"
                                                          style={{
                                                            color: "#0000008A",
                                                          }}
                                                        >
                                                          <PublishIcon
                                                            style={{
                                                              margin: 5,
                                                            }}
                                                          />
                                                          <LocalizedMessage id="category.importMenu" />
                                                          <input
                                                            type="file"
                                                            onChange={(
                                                              event
                                                            ) => {
                                                              popupState.close();
                                                              setProgressDialogOpen(
                                                                true
                                                              );
                                                              setMenuItemsFile(
                                                                event.target
                                                                  .files[0]
                                                              );
                                                            }}
                                                            hidden
                                                          />
                                                        </MenuItem>
                                                      )}
                                                      {(permissions.isAdmin ||
                                                        (permissions.item &&
                                                          permissions.item.includes(
                                                            "downloadTemplate"
                                                          ))) && (
                                                        <MenuItem
                                                          disabled={
                                                            isFetchingPdf
                                                          }
                                                          onClick={() => {
                                                            popupState.close();
                                                            setOpenDownloadTemplateDialog(
                                                              true
                                                            );
                                                          }}
                                                          style={{
                                                            color: "#0000008A",
                                                          }}
                                                        >
                                                          <GetAppIcon
                                                            style={{
                                                              margin: 5,
                                                            }}
                                                          />

                                                          {isFetchingPdf ? (
                                                            <CircularProgress
                                                              size={20}
                                                            />
                                                          ) : (
                                                            <LocalizedMessage id="category.downloadDialog.title" />
                                                          )}
                                                        </MenuItem>
                                                      )}
                                                    </>
                                                  )}
                                                </Box>
                                              </MenuList>
                                            </ClickAwayListener>
                                          </Paper>
                                        </Grow>
                                      )}
                                    </Popper>
                                  </>
                                )}
                              </PopupState>
                            </Box>
                          </Box>
                        </Box>
                        <Divider style={{ marginTop: 10 }} />
                      </>
                    }
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        flex: 20,
                        overflowX: "auto",
                        position: "relative",
                        overflow: "visible",
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          flex: 20,
                          overflowX: "auto",
                          position: "relative",
                          overflow: "visible",
                          flexDirection: "column",
                        }}
                      >
                        {selectedCategory?.id && (
                          <SortableTreeView
                            selectedCategory={selectedCategory}
                            Theme={Theme}
                            classes={classes}
                            categories={categories}
                            axis="xy"
                            onSortEnd={({ oldIndex, newIndex }) => {
                              setCategories((categories) => [
                                ...arrayMove(categories, oldIndex, newIndex),
                              ]);
                            }}
                            pressDelay={downSm ? 50 : undefined}
                            distance={downSm ? undefined : 1}
                            expanded={expanded}
                            // shouldCancelStart={(e) =>
                            //   console.log(111, e.target.tagName)
                            // }
                            useDragHandle={downMd}
                          ></SortableTreeView>
                        )}
                        <Memo deps={[selectedCategory]}>
                          {() => (
                            <Box>
                              {(permissions.isAdmin ||
                                permissions.category.includes("add")) && (
                                <Button
                                  size="small"
                                  onClick={() =>
                                    openAddCategoryForm({
                                      name: null,
                                      id: null,
                                    })
                                  }
                                  startIcon={<AddIcon />}
                                  style={{
                                    marginTop: 10,
                                  }}
                                  color="primary"
                                  variant="contained"
                                  // style={{ backgroundColor: "inherit" }}
                                >
                                  <LocalizedMessage id="category.newCategory" />
                                </Button>
                              )}
                            </Box>
                          )}
                        </Memo>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
                <QRDialog
                  qrData={qrUrl}
                  open={openDialog}
                  onCloseDialog={() => setOpenDialog(false)}
                />
                <ProgressDialog
                  file={menuItemsFile}
                  imported={imported}
                  failed={isUploadFailed}
                  open={progressDialogOpen}
                  onCloseDialog={() => setProgressDialogOpen(false)}
                />
                <DownloadTemplateDialog
                  open={openDownloadTemplateDialog}
                  onCloseDialog={() => setOpenDownloadTemplateDialog(false)}
                  onDownloadClick={handleExportMenuItem}
                />
              </Grid>
            )
          }
        </Memo>
      )}
      {/* End Categories List */}
    </>
  );
};

CategoryList.propTypes = {
  /**
   *
   */
};

export default CategoryList;
