// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { useTheme, Box } from "@material-ui/core";
import BallotIcon from "@material-ui/icons/Ballot";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import EventNoteIcon from "@material-ui/icons/EventNote";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import FaceIcon from "@material-ui/icons/Face";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import Tabbed from "./Tabbed";

// Factories

// Screens
import { SalesByChannel } from "Screens/Portal/Reports/SalesByChannel";
import { SalesByZone } from "Screens/Portal/Reports/SalesByZone";
import { SalesByItem } from "Screens/Portal/Reports/SalesByItem";
import { SalesByHour } from "Screens/Portal/Reports/SalesByHour";

import { SalesByCashier } from "Screens/Portal/Reports/SalesByCashier";
import { SalesByDriver } from "Screens/Portal/Reports/SalesByDriver";
// import { SalesByOrder } from "Screens/Portal/Reports/SalesByOrder"
import { VoidOrders } from "Screens/Portal/Reports/VoidOrders";
import { VoidItems } from "Screens/Portal/Reports/VoidItems";
import { ZReport } from "Screens/Portal/Reports/ZReport";

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name Reports
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Reports = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [tabsToShow, setTabsToShow] = useState([]);

  // Effect Hooks
  useEffect(() => {
    if (permissions.isAdmin) setTabsToShow(tabs);
    else {
      const permissionMatrix = Object.keys(permissions);
      const newTabs = tabs.filter((element) => permissionMatrix.includes(element.permissionName));
      setTabsToShow(newTabs);
    }
  }, []);

  useEffect(
    () => setHeading(<LocalizedMessage id="report.pageTitle" key="report.pageTitle" />),
    []
  );

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers

  // Other
  // const nestedTabs = [
  //   {
  //     label: <LocalizedMessage id="report.salesByCashier.pageTitle" />,
  //     permissionName: "reportSalesByCashier",
  //     icon: <FaceIcon />,
  //     node: <SalesByCashier permissions={permissions} />,
  //   },
  //   {
  //     label: <LocalizedMessage id="report.salesByDriver.pageTitle" />,
  //     permissionName: "reportSalesByDriver",
  //     icon: <LocationSearchingIcon />,
  //     node: <SalesByDriver permissions={permissions} />,
  //   },
  // ];

  const tabs = [
    {
      label: <LocalizedMessage id="report.salesByChannel.pageTitle" />,
      permissionName: "reportSalesByChannel",
      icon: <EventNoteIcon />,
      node: <SalesByChannel permissions={permissions} />,
    },
    {
      label: <LocalizedMessage id="report.salesByItem.pageTitle" />,
      permissionName: "reportSalesByItem",
      icon: <BallotIcon />,
      node: <SalesByItem permissions={permissions} />,
    },
    {
      label: <LocalizedMessage id="report.salesByHour.pageTitle" />,
      permissionName: "reportSalesByHour",
      icon: <QueryBuilderIcon />,
      node: <SalesByHour permissions={permissions} />,
    },
    {
      label: <LocalizedMessage id="report.salesByZone.pageTitle" />,
      permissionName: "reportSalesByZone",
      icon: <LocationSearchingIcon />,
      node: <SalesByZone permissions={permissions} />,
    },

    {
      label: <LocalizedMessage id="report.salesByCashier.pageTitle" />,
      permissionName: "reportSalesByCashier",
      icon: <FaceIcon />,
      node: <SalesByCashier permissions={permissions} />,
    },
    {
      label: <LocalizedMessage id="report.salesByDriver.pageTitle" />,
      permissionName: "reportSalesByDriver",
      icon: <DriveEtaIcon />,
      node: <SalesByDriver permissions={permissions} />,
    },

    // {
    //   label: <LocalizedMessage id="report.salesByOrder.pageTitle" />,
    //   permissionName: "reportSalesByOrder",
    //   icon: <LocationSearchingIcon />,
    //   node: <SalesByOrder permissions={permissions} />,
    // },

    {
      label: <LocalizedMessage id="report.voidOrders.pageTitle" />,
      permissionName: "reportVoidOrders",
      icon: <SpeakerNotesOffIcon />,
      node: <VoidOrders permissions={permissions} />,
    },
    {
      label: <LocalizedMessage id="report.voidItems.pageTitle" />,
      permissionName: "reportVoidItems",
      icon: <CancelPresentationIcon />,
      node: <VoidItems permissions={permissions} />,
    },
    {
      label: <LocalizedMessage id="common.zReport" />,
      permissionName: "zReport",
      icon: <AssignmentTurnedInIcon />,
      node: <ZReport permissions={permissions} />,
    },

    // {
    //   label: "Sales by person",
    //   icon: <FaceIcon />,
    //   isTabbed: true,
    //   node: <Tabbed tabs={nestedTabs} isTabbed />
    // },
  ];

  // Component Render
  return (
    <Box className={classes.root}>
      <Title pageTitle={<LocalizedMessage id="report.pageTitle" key="report.pageTitle" />} />
      <Tabbed
        key={"tabbed"}
        style={{
          padding: theme.spacing(2),
        }}
        tabs={tabsToShow}
      />
    </Box>
  );
};

Reports.propTypes = {
  /**
   *
   */
};

Reports.defaultProps = {
  /**
   *
   */
};

export default Reports;
