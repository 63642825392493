// React
import React, { useState, useEffect } from "react";

// Material
import { Box, Grid } from "@material-ui/core";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";
import useStateRef from "Helpers/useStateRef";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";

// Factories

// Screens
import { Items } from "./Items/";
import { Categories } from "./Categories/";

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services
import { useGetAllItems } from "Services/Items/";

// Styles

// Ad-Hoc Components

/**
 * @name Menu
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Menu = ({}) => {
  // Theme & Style Hooks

  // Global State Hooks
  const [heading, setHeading] = useGlobalState("heading.heading");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [categories, setCategories] = useState([]);
  const [isFetchingCategories, setIsFetchingCategories] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [openItemCategoryCalled, setOpenItemCategoryCalled] = useState(false);
  const [items, setItems] = useStateRef([]);
  const [isFetchingItems, setIsFetchingItems] = useState(true);

  // Other Hooks

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <RestaurantMenuIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage id="menu.pageTitle" key="menu.pageTitle" />
        </Box>
      ),
    []
  );

  useEffect(() => {
    setIsFetchingItems(true);
    if (!isFetchingCategories) {
      if (selectedCategory?.id)
        getAllItems(selectedCategory.id)
          .then((itms) => {
            setItems(itms);
            setIsFetchingItems(false);
          })
          .catch((errors) => {
            displaySnackbarErrors(errors, enqueueSnackbar);
          });
      else setIsFetchingItems(false);
    }
  }, [selectedCategory]);

  // Other Hooks
  const getAllItems = useGetAllItems();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers

  // Other

  // Component Render
  return (
    <>
      <Title pageTitle={<LocalizedMessage id="menu.pageTitle" key="menu.pageTitle" />} />
      <Grid container justify="center" spacing={2}>
        {(permissions.isAdmin ||
          (permissions.category && permissions.category.includes("get"))) && (
          <Categories
            categories={categories}
            setCategories={setCategories}
            isFetchingCategories={isFetchingCategories}
            setIsFetchingCategories={setIsFetchingCategories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            setOpenItemCategoryCalled={setOpenItemCategoryCalled}
            permissions={permissions}
            setItems={setItems}
          />
        )}
        {(permissions.isAdmin ||
          (permissions.menuItem && permissions.menuItem.includes("get"))) && (
          <Items
            isFetchingCategories={isFetchingCategories}
            selectedCategory={selectedCategory}
            openItemCategoryCalled={openItemCategoryCalled}
            setOpenItemCategoryCalled={setOpenItemCategoryCalled}
            permissions={permissions}
            items={items}
            setItems={setItems}
            isFetchingItems={isFetchingItems}
          />
        )}
      </Grid>
    </>
  );
};

export default Menu;
