import React, { useState, useEffect } from "react";
import {
  TableCell,
  TableRow,
  Collapse,
  Typography,
  TableHead,
  Table,
  Box,
  IconButton,
  TableBody,
  Chip,
} from "@material-ui/core";
import { useGetDeviceSessions } from "Services/POS";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { useSnackbar } from "notistack";
import { makeStyles } from "Helpers/Styles";
import LocalizedMessage from "./../../../Helpers/Localization/LocalizedMessage";

const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}));

const DeviceRow = ({ row, columns, sessionsColumns }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [sessions, setSessions] = useState([]);
  const getDeviceSessions = useGetDeviceSessions();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getDeviceSessions(row.id)
      .then((sessions) => {
        setSessions(sessions);
      })
      .catch((errors) => {
        console.log(errors);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [row.id]);

  return (
    <>
      <TableRow hover role="checkbox" tabIndex={-1} className={classes.root}>
        <TableCell key={row.id + "-expand"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              {column.format ? column.format(value) : value}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow hover>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                <LocalizedMessage id="POS.sessions" />
              </Typography>
              <Table size="small" aria-label="sessions">
                <TableHead>
                  <TableRow>
                    {sessionsColumns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sessions.map((session) => (
                    <TableRow key={session.serverCreatedAt}>
                      {sessionsColumns.map((column, idx) => (
                        <TableCell key={column.id} align={column.align}>
                          {session[column.id]}
                          {idx === 0 && row.activeSessionId === session.id && (
                            <Chip
                              size="small"
                              color="primary"
                              label={"active"}
                              style={{
                                marginInlineStart: 8,
                              }}
                            />
                          )}
                        </TableCell>
                      ))}
                      {/* <TableCell>
                        {session.macAddress}
                        {row.activeSessionId === session.id && (
                          <Chip
                            size="small"
                            color="primary"
                            label={"active"}
                            style={{
                              marginInlineStart: 8,
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="right">{session.ipAddress}</TableCell>
                      <TableCell align="right">{session.deviceModel}</TableCell>
                      <TableCell align="right">
                        {session.operatingSystem}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default DeviceRow;
