// React
import React, { useState } from "react"
import PropTypes from "prop-types"

// Material
import { Box, Slide, Fade } from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"

// Components

// Factories

// Screens
import ForgetPasswordStep1 from "./ForgetPasswordStep1"
import ForgetPasswordStep2 from "./ForgetPasswordStep2"

// Assets

// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
}))

// Ad-Hoc Components

/**
 * @name ForgetPasswordForm
 * @summary ForgetPasswordForm Component
 * @category Screens / Auth
 * @component
 * @description
 * > A wrapper for forget password form steps
 */
const ForgetPasswordForm = (props) => {
  // Props Destructing
  const {
    className,
    backToLoginHandler,
    isForgetPasswordAnimationDone,
    ...otherProps
  } = props

  // Theme & Style Hooks
  const classes = useStyles()

  // Global State Hooks

  // State Hooks
  const [currentComponent, setCurrentComponent] = useState("Step1")
  const [isAnimationDone, setIsAnimationDone] = useState(false)
  const [mail, setMail] = useState("")

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const goToStep2Handler = (email) => {
    setCurrentComponent("Step2")
    setMail(email)
  }
  // Other

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <Slide
        direction="right"
        in={currentComponent === "Step1" && isForgetPasswordAnimationDone}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 200 }}
        onEntered={() => {
          setIsAnimationDone(false)
        }}
        onExited={() => {
          setIsAnimationDone(true)
        }}
      >
        <Box>
          <ForgetPasswordStep1
            backToLoginHandler={backToLoginHandler}
            goToStep2Handler={goToStep2Handler}
          />
        </Box>
      </Slide>
      <Fade
        in={currentComponent === "Step2" && isAnimationDone}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 500, exit: 200 }}
      >
        <Box>
          <ForgetPasswordStep2
            backToLoginHandler={backToLoginHandler}
            email={mail}
            goToStep2Handler={goToStep2Handler}
          />
        </Box>
      </Fade>
    </Box>
  )
}

ForgetPasswordForm.propTypes = {
  /**
   * A function that hides the ForgetPasswordForm and show SignInForm
   */
  backToLoginHandler: PropTypes.func.isRequired,
}

export default ForgetPasswordForm
