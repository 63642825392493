// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Card,
  Button,
  Divider,
  useTheme,
  CardHeader,
  Typography,
  CardContent,
} from "@material-ui/core";

import AndroidIcon from "@material-ui/icons/Android";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
// import GetAppIcon from "@material-ui/icons/GetApp";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";

// Components
import QRDialog from "Components/QRDialog";

// Factories

// Screens

// Assets

// Third Parties
import QRCode from "qrcode.react";

// Styles

// Ad-Hoc Components
const { REACT_APP_DOWNLOAD_POS_IOS, REACT_APP_DOWNLOAD_POS_ANDROID } =
  process.env;

/**
 * @name DownloadPOSCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DownloadPOSCard = ({}) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks
  const [openAndroidQrDialog, setOpenAndroidQrDialog] = useState(false);
  const [openIOSQrDialog, setOpenIOSQrDialog] = useState(false);
  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <>
      <Card style={{ overflow: "auto" }}>
        <CardHeader
          title={
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" component="h2">
                  <LocalizedMessage id="POS.downloadApp" />
                </Typography>
              </Box>
              <Divider style={{ marginTop: 10 }} />
            </>
          }
        />
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            minHeight: 160,
            paddingBottom: 40,
          }}
        >
          <Button
            style={{ flex: 1 }}
            aria-label="QR"
            size="large"
            onClick={() => setOpenIOSQrDialog(true)}
            // target="_blank"
            // href="#"
            startIcon={<StayCurrentPortraitIcon color="primary" />}
            color="primary"
          >
            <LocalizedMessage id="POS.downloadIOSApp" />
          </Button>

          <Button
            style={{ flex: 1 }}
            aria-label="QR"
            size="large"
            onClick={() => setOpenAndroidQrDialog(true)}
            // target="_blank"
            // href="https://drive.google.com/file/d/1g4pS7WhXSvK8xTNABSspN8CGz7X-KXP5/view"
            startIcon={<AndroidIcon color="primary" />}
            color="primary"
          >
            <LocalizedMessage id="POS.downloadAndroidApp" />
          </Button>
        </CardContent>
      </Card>

      <QRDialog
        qrData={REACT_APP_DOWNLOAD_POS_ANDROID}
        open={openAndroidQrDialog}
        onCloseDialog={() => setOpenAndroidQrDialog(false)}
        downLoadLink={REACT_APP_DOWNLOAD_POS_ANDROID}
      />

      <QRDialog
        qrData={"IOS Link will be available soon."}
        open={openIOSQrDialog}
        onCloseDialog={() => setOpenIOSQrDialog(false)}
        downLoadLink={REACT_APP_DOWNLOAD_POS_IOS}
      />
    </>
  );
};

DownloadPOSCard.propTypes = {
  /**
   *
   */
};

export default DownloadPOSCard;
