// React
import React from "react";

// Material
import {
  TableBody,
  TableCell,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Third Parties
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";

// Styles
const useStyles = makeStyles(() => ({
  sortableHelper: {
    boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  drag: {
    "&:hover": {
      cursor: "grab",
    },
  },
}));

export function DragHandle({}) {
  const classes = useStyles();
  const Handle = SortableHandle(() => (
    <Tooltip
      title={<LocalizedMessage id="general.drag" />}
      className={classes.drag}
    >
      <IconButton aria-label="drag-handle">
        <DragIndicatorIcon />
      </IconButton>
    </Tooltip>
  ));
  return <Handle />;
}

export default function SortableBody({ data, columns, onSortEnd }) {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Other
  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  const SortableRow = SortableElement(({ row }) => {
    return (
      <>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id}
          style={{ width: "100%" }}
        >
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id} align={column.align}>
                {column.id === "drag" ? (
                  <DragHandle />
                ) : column.format ? (
                  column.format(value)
                ) : (
                  value
                )}
              </TableCell>
            );
          })}
        </TableRow>
      </>
    );
  });

  const SortableComponent = SortableContainer(() => {
    return (
      <TableBody>
        {data.map((row, index) => {
          return <SortableRow row={row} index={index} />;
        })}
      </TableBody>
    );
  });

  return (
    <SortableComponent
      //   pressDelay={downSm ? 50 : undefined}
      distance={downSm ? undefined : 1}
      helperClass={classes.sortableHelper}
      axis="y"
      useDragHandle={true}
      data={data}
      columns={columns}
      onSortEnd={onSortEnd}
    />
  );
}
