// React
import React, { useEffect, useState } from "react";

// Material
import { Box, Typography, Grid, Card, CardContent } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

// Globals

// Helpers
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { LocalizedMessage } from "Helpers/Localization";
import { makeStyles } from "Helpers/Styles";

// Components

// Factories

// Screens

// Services
import { useGetCustomersCount } from "Services/CRM/";

// Assets

// Third Parties
import moment from "moment";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
  iconBox: {
    display: "flex",
    backgroundColor: "white",
    margin: 8,
    alignItems: "center",
  },
  numberBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

// Ad-Hoc Components

/**
 * @name CountCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CountCard = ({ dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [customersCount, setCustomersCount] = useState([
    {
      total: 0,
      onlinecustomers: 0,
      newcustomers: 0,
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  // Effect Hooks
  useEffect(() => {
    setIsLoading(true);
    getCustomersCount(moment(dateFrom).format("YYYY-MM-DD"), moment(dateTo).format("YYYY-MM-DD"))
      .then((response) => {
        setCustomersCount(response);
        setIsLoading(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsLoading(false);
      });
  }, [dateFrom, dateTo]);

  // Other Hooks

  // Event Handlers

  // Other
  const getCustomersCount = useGetCustomersCount();
  const { enqueueSnackbar } = useSnackbar();

  // Component Render
  return (
    <>
      <Typography variant="h6" component="h2" style={{ margin: 8 }}>
        <LocalizedMessage id="crm.customersCount" />
      </Typography>
      <Grid
        container
        spacing={2}
        justify="center"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {isLoading ? (
                <Skeleton variant="rect" width={70} height={70} style={{ margin: 6 }} />
              ) : (
                <Box className={classes.iconBox}>
                  <TrendingUpOutlinedIcon color="primary" style={{ fontSize: "xxx-large" }} />
                </Box>
              )}
              <Box className={classes.numberBox}>
                {isLoading ? (
                  <Skeleton width={100} height={35} />
                ) : (
                  <Typography variant="subtitle1" align="center">
                    <LocalizedMessage id="report.total" />
                  </Typography>
                )}
                {isLoading ? (
                  <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                ) : (
                  <Box style={{ marginTop: "auto" }}>
                    <Typography variant="h4">{customersCount[0].total}</Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              {isLoading ? (
                <Skeleton variant="rect" width={70} height={70} style={{ margin: 6 }} />
              ) : (
                <Box className={classes.iconBox}>
                  <PersonAddIcon style={{ color: "green", fontSize: "xxx-large" }} />
                </Box>
              )}
              <Box className={classes.numberBox}>
                {isLoading ? (
                  <Skeleton width={100} height={35} />
                ) : (
                  <Typography variant="subtitle1" align="center">
                    <LocalizedMessage id="crm.newcustomers" />
                  </Typography>
                )}
                {isLoading ? (
                  <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                ) : (
                  <Box style={{ marginTop: "auto" }}>
                    <Typography variant="h4"> {customersCount[0].newcustomers}</Typography>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
        {/* <Grid item xs={6}>
          <Card style={{ padding: 8 }}>
            <Typography variant="h6" component="h2" style={{ margin: 8, color: "green" }}>
              <LocalizedMessage id="crm.onlineCustomers" />
            </Typography>
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              {customersCount[0].onlinecustomers}
            </Typography>
          </Card>
        </Grid> */}
      </Grid>
    </>
  );
};

CountCard.propTypes = {
  /**
   *
   */
};

export default CountCard;
