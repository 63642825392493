// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Paper,
  useTheme,
  Box,
  CircularProgress,
  Button,
  Typography,
  Divider,
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import PublishIcon from "@material-ui/icons/Publish";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import { useForm } from "Helpers/Hooks";
import { getReportDate, getDateDurations } from "Utils";
import {
  displaySnackbarErrors,
  displayenqueueSnackbar,
  displaySnackbarFormErrors,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { DatePicker, SelectField } from "Components/FormFields";
import CategoriesAndItemsDialog from "Screens/Portal/Reports/CategoriesAndItemsDialog";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import FileDownload from "js-file-download";

// Services
import { useGetAllCategoriesAndItemsDropdowns } from "Services/Dropdowns";
import { useGetMenuItemsPDF, useGetOrdersPDF } from "Services/Export";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: "flex",
    margin: theme.spacing(0, 2),
    flexDirection: "column",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const menuItemValidationSchema = Joi.object({
  dateDuration: Joi.string()
    .valid(
      "today",
      "yesterday",
      "thisWeek",
      "lastWeek",
      "thisMonth",
      "lastMonth",
      "thisYear",
      "lastYear",
      "custom"
    )
    .required(),
  dateFrom: Joi.date(),
  dateTo: Joi.date().min(Joi.ref("dateFrom")),
  categories: Joi.array().items(Joi.object({ id: Joi.string().uuid(), name: Joi.string() })),
  items: Joi.array().items(Joi.object({ id: Joi.string().uuid(), name: Joi.string() })),
});

const orderValidationSchema = Joi.object({
  dateDuration: Joi.string()
    .valid(
      "today",
      "yesterday",
      "thisWeek",
      "lastWeek",
      "thisMonth",
      "lastMonth",
      "thisYear",
      "lastYear",
      "custom"
    )
    .required(),
  dateFrom: Joi.date(),
  dateTo: Joi.date().min(Joi.ref("dateFrom")),
  categories: Joi.array().items(Joi.object({ id: Joi.string().uuid(), name: Joi.string() })),
  items: Joi.array().items(Joi.object({ id: Joi.string().uuid(), name: Joi.string() })),
});

/**
 * @name Exports
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Exports = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [categoriesAndItemsModalOpen, setCategoriesAndItemsModalOpen] = useState(false);
  const [categoriesAndItemsData, setCategoriesAndItemsData] = useState([]);
  const [isFetchingCategoriesAndItems, setIsFetchingCategoriesAndItems] = useState(true);
  const [isFetchingPdf, setIsFetchingPdf] = React.useState(false);
  const [pdf, setPdf] = useState("");
  const [isFetchingOrderPdf, setIsFetchingOrderPdf] = React.useState(false);
  const [orderPdf, setOrderPdf] = useState();

  // Effect Hooks
  React.useEffect(
    () => setHeading(<LocalizedMessage id="export.pageTitle" key="export.pageTitle" />),
    []
  );

  useEffect(() => {
    getData()
      .then((data) => {
        setCategoriesAndItemsData(data);
        setIsFetchingCategoriesAndItems(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingCategoriesAndItems(false);
      });
  }, []);

  const {
    formData: menuItemFormData,
    setFormData: menuItemSetFormData,
    formErrors: menuItemFormErrors,
    setFormErrors: menuItemSetFormErrors,
    validateForm: menuItemValidateForm,
    onFormFieldChange: menuItemOnFormFieldChange,
    isSubmitting: menuItemIsLoading,
  } = useForm({
    validationSchema: menuItemValidationSchema,
    languageKey: "export",
  });

  const {
    formData: orderFormData,
    setFormData: orderSetFormData,
    formErrors: orderFormErrors,
    setFormErrors: orderSetFormErrors,
    validateForm: orderValidateForm,
    onFormFieldChange: orderOnFormFieldChange,
    isSubmitting: orderIsLoading,
  } = useForm({
    validationSchema: orderValidationSchema,
    languageKey: "export",
  });

  useEffect(() => {
    const { from, to } = getReportDate("thisWeek", "", "");
    menuItemSetFormData({
      dateDuration: "thisWeek",
      dateFrom: from,
      dateTo: to,
    });
    orderSetFormData({
      dateDuration: "thisWeek",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getData = useGetAllCategoriesAndItemsDropdowns();
  const { enqueueSnackbar } = useSnackbar();
  const getMenuItemsPDF = useGetMenuItemsPDF();
  const getOrdersPDF = useGetOrdersPDF();

  // Other Hooks

  // Event Handlers
  const handleExportMenuItem = () => {
    menuItemFormData.categories = menuItemFormData.categories?.map((category) => {
      return {
        id: category.id,
      };
    });
    menuItemFormData.items = menuItemFormData.items?.map((item) => {
      return {
        id: item.id,
      };
    });

    const validForm = menuItemValidateForm();

    if (validForm) {
      setIsFetchingPdf(true);

      let { dateDuration, dateFrom, dateTo, categories, items } = menuItemFormData;

      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      menuItemSetFormData({ ...menuItemFormData, dateFrom: from, dateTo: to });

      getMenuItemsPDF() /** @TODO here we must convert the categoires array of items into just ids */
        .then((response) => {
          FileDownload(response, "Menu Items Data.xls");
          setIsFetchingPdf(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, menuItemSetFormErrors);
          setIsFetchingPdf(false);
        });
    }
  };

  const handleExportOrders = () => {
    const validForm = orderValidateForm();

    if (validForm) {
      setIsFetchingOrderPdf(true);

      let { dateDuration, dateFrom, dateTo } = orderFormData;

      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      orderSetFormData({ ...orderFormData, dateFrom: from, dateTo: to });
      getOrdersPDF()
        .then((response) => {
          FileDownload(response, "Orders Data.xls");
          setIsFetchingOrderPdf(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, menuItemSetFormErrors);
          setIsFetchingOrderPdf(false);
        });
    }
  };
  // Other
  const dateDurations = getDateDurations(intl);

  // Component Render
  return (
    <Box className={classes.root}>
      <Title pageTitle={<LocalizedMessage id="export.pageTitle" key="export.pageTitle" />} />
      <Paper
        dir={Theme.direction}
        style={{
          padding: Theme.spacing(2),
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            <Typography variant="h6" component="h2">
              <LocalizedMessage id="export.export" />
            </Typography>
          </Box>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: "12px 16px" }}
            >
              <Typography variant="subtitle1" className={classes.heading}>
                <LocalizedMessage id="rolesNames.menuItem" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.root}>
              <Box display="flex">
                <Box
                  style={{
                    flex: "1 1",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <SelectField
                    fullWidth
                    name="dateDuration"
                    tooltip={"Date Duration"}
                    helpKey="Menu.category.dateDuration"
                    label={<LocalizedMessage id="report.dateDuration.title" />}
                    onChange={menuItemOnFormFieldChange}
                    value={menuItemFormData.dateDuration}
                    errors={menuItemFormErrors.dateDuration}
                    isSubmitting={isFetchingPdf}
                    isLoading={menuItemIsLoading.dateDuration}
                    options={dateDurations}
                    defaultValue="thisWeek"
                  />
                  {menuItemFormData.dateDuration === "custom" && (
                    <>
                      <DatePicker
                        name="dateFrom"
                        tooltip={"Date from"}
                        helpKey="common.dateFrom"
                        label={intl.formatMessage({ id: "common.dateFrom" })}
                        onChange={menuItemOnFormFieldChange}
                        value={menuItemFormData.dateFrom}
                        errors={menuItemFormErrors.dateFrom}
                        isSubmitting={isFetchingPdf}
                        isLoading={menuItemIsLoading.dateFrom}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                      <DatePicker
                        name="dateTo"
                        tooltip={"Date from"}
                        helpKey="common.dateTo"
                        label={intl.formatMessage({ id: "common.dateTo" })}
                        onChange={menuItemOnFormFieldChange}
                        value={menuItemFormData.dateTo}
                        errors={menuItemFormErrors.dateTo}
                        isSubmitting={isFetchingPdf}
                        isLoading={menuItemIsLoading.dateTo}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                    </>
                  )}
                </Box>

                <Box
                  style={{
                    flex: 1,
                    padding: 16,
                  }}
                >
                  <CategoriesAndItemsDialog
                    open={categoriesAndItemsModalOpen}
                    setOpen={setCategoriesAndItemsModalOpen}
                    formData={menuItemFormData}
                    setFormData={menuItemSetFormData}
                    categoriesAndItemsData={categoriesAndItemsData}
                  />
                  <Box>
                    <Button
                      variant="outlined"
                      disabled={isFetchingCategoriesAndItems || isFetchingPdf}
                      onClick={() => setCategoriesAndItemsModalOpen(true)}
                      style={{ width: "100%", padding: "14px" }}
                    >
                      <LocalizedMessage id="export.chooseItems" />
                    </Button>
                  </Box>
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="button"
                  color="primary"
                  disabled={isFetchingPdf}
                  onClick={handleExportMenuItem}
                  style={{ flex: 1, padding: 20 }}
                  startIcon={<GetAppIcon style={{ margin: 5 }} />}
                >
                  {isFetchingPdf ? (
                    <CircularProgress size={20} />
                  ) : (
                    <LocalizedMessage id="general.export" />
                  )}
                </Button>
                {/* <Button
                  component="label"
                  color="primary"
                  style={{ flex: 1, padding: 20 }}
                  startIcon={<PublishIcon style={{ margin: 5 }} />}
                >
                  <LocalizedMessage id="general.import" />
                  <input
                    type="file"
                    onChange={(event) => console.log(event.target.files[0])}
                    hidden
                  />
                </Button> */}
              </Box>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ padding: "12px 16px" }}
            >
              <Typography variant="subtitle1" className={classes.heading}>
                <LocalizedMessage id="common.order" />
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.root}>
              <Box display="flex">
                <Box
                  style={{
                    flex: "1 1",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <SelectField
                    fullWidth
                    name="dateDuration"
                    tooltip={"Date Duration"}
                    helpKey="Menu.category.dateDuration"
                    label={<LocalizedMessage id="report.dateDuration.title" />}
                    onChange={orderOnFormFieldChange}
                    value={orderFormData.dateDuration}
                    errors={orderFormErrors.dateDuration}
                    // isSubmitting={isFetchingRows}
                    isLoading={orderIsLoading.dateDuration}
                    options={dateDurations}
                    defaultValue="thisWeek"
                  />
                  {orderFormData.dateDuration === "custom" && (
                    <>
                      <DatePicker
                        name="dateFrom"
                        tooltip={"Date from"}
                        helpKey="common.dateFrom"
                        label={intl.formatMessage({ id: "common.dateFrom" })}
                        onChange={orderOnFormFieldChange}
                        value={orderFormData.dateFrom}
                        errors={orderFormErrors.dateFrom}
                        // isSubmitting={isFetchingRows}
                        isLoading={orderIsLoading.dateFrom}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                      <DatePicker
                        name="dateTo"
                        tooltip={"Date from"}
                        helpKey="common.dateTo"
                        label={intl.formatMessage({ id: "common.dateTo" })}
                        onChange={orderOnFormFieldChange}
                        value={orderFormData.dateTo}
                        errors={orderFormErrors.dateTo}
                        // isSubmitting={isFetchingRows}
                        isLoading={orderIsLoading.dateTo}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        margin="dense"
                      />
                    </>
                  )}
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="button"
                  color="primary"
                  // disabled={isFetchingRows}
                  onClick={handleExportOrders}
                  style={{ flex: 1, padding: 20 }}
                  startIcon={<GetAppIcon style={{ margin: 5 }} />}
                >
                  {/* {isFetchingRows ? (
                  <CircularProgress size={20} />
                ) : ( */}
                  <LocalizedMessage id="general.export" />
                  {/* )} */}
                </Button>
                {/* <Button
                  component="label"
                  color="primary"
                  style={{ flex: 1, padding: 20 }}
                  startIcon={<PublishIcon style={{ margin: 5 }} />}
                >
                  <LocalizedMessage id="general.import" />
                  <input
                    type="file"
                    onChange={(event) => console.log(event.target.files[0])}
                    hidden
                  />
                </Button> */}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>

      <Paper
        dir={Theme.direction}
        style={{
          padding: Theme.spacing(2),
          marginTop: 16,
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <Typography variant="h6" component="h2">
            <LocalizedMessage id="export.import" />
          </Typography>
        </Box>

        <Divider />

        <Box display="flex" justifyContent="flex-end">
          <Button
            component="label"
            color="primary"
            style={{ flex: 1, padding: 20 }}
            startIcon={<PublishIcon style={{ margin: 5 }} />}
          >
            <LocalizedMessage id="general.import" />
            <input type="file" onChange={(event) => console.log(event.target.files[0])} hidden />
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

Exports.propTypes = {
  /**
   *
   */
};

Exports.defaultProps = {
  /**
   *
   */
};

export default Exports;
