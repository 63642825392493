// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Box,
  useTheme,
  Divider,
  CardContent,
  Card,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    // flexGrow: 1,
    // display: "flex",
    // height: "53vh",
    [theme.breakpoints.down("sm")]: { marginTop: 16 },
    [theme.breakpoints.up("sm")]: { marginInlineStart: "16px" },
  },
  singleDivider: {
    width: "100%",
    borderTop: "2px dashed #58585f",
    backgroundColor: "unset",
    margin: "7px 0px",
    height: 5,
  },
  doubleDivider: {
    width: "100%",
    borderTop: "2px dashed #58585f",
    borderBottom: "2px dashed #58585f",
    backgroundColor: "unset",
    margin: "5px 0px",
    height: 5,
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    margin: 4,
  },
}));

// Ad-Hoc Components
const xReportData = {
  header: {
    brandName: true,
    branchName: true,
    city: true,
    phoneNumbers: true,
  },
  ordersCountSummary: {
    firstOrderInvoiceNumber: true,
    firstOrderTime: true,
    lastOrderInvoiceNumber: true,
    lastOrderTime: true,
    totalCustomersCount: true,
    totalCashiersCount: true,
    totalDriversCount: true,
    total: true,
    orderStatusesCountSummary: {
      Open: true,
      Closed: true,
      Void: true,
      Merged: true,
      Returned: true,
    },
    orderSalesSectionTypesCountSummary: {
      DineIN: true,
      Delivery: true,
      Takeaway: true,
      Pickup: true,
      Staff: true,
      DriveThru: true,
    },
    orderSalesSectionsCountSummary: true,
    orderPaymentMethodTypesCountSummary: {
      complementary: true,
      cash: true,
      customAccount: true,
      electronicCard: true,
      customerCredit: true,
    },
    OrderPaymentMethodsCountSummary: true,
  },
  ordersByStatusDetails: {
    Open: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Closed: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Void: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Merged: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Returned: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
  },
  ordersBySalesSectionTypeDetails: {
    DineIN: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Delivery: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Takeaway: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Pickup: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    Staff: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
    DriveThru: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
  },
  orderBySalesSectionDetails: {
    salesSectionName: {
      ordersCount: true,
      customersCount: true,
      totalDiscounts: true,
      totalSurcharges: true,
      totalTaxes: true,
      totalSales: true,
      netSales: true,
    },
  },
  orderByPaymentMethodTypesDetails: {
    complementary: {
      ordersCount: true,
      customersCount: true,
      netSales: true,
    },
    cash: {
      ordersCount: true,
      customersCount: true,
      netSales: true,
    },
    customAccount: {
      ordersCount: true,
      customersCount: true,
      netSales: true,
    },
    electronicCard: {
      ordersCount: true,
      customersCount: true,
      netSales: true,
    },
    customerCredit: {
      ordersCount: true,
      customersCount: true,
      netSales: true,
    },
  },
  orderByPaymentMethodDetails: {
    paymentMethodName: {
      ordersCount: true,
      customersCount: true,
      netSales: true,
    },
  },
  cashierCashes: {
    startCash: true,
    endCash: true,
    totalExpenses: true,
    totalSales: true,
    netCash: true,
    chargedBy: true,
  },
  driversCashes: {
    startCash: true,
    endCash: true,
    totalExpenses: true,
    totalSales: true,
    netCash: true,
    chargedBy: true,
  },
  additionalOrders: {
    mergedOrders: false,
  },
  categoriesSales: {
    displayItem: true,
  },
  salesItems: {
    displayItem: true,
  },
  staffSalesItems: {
    displayItem: true,
  },
  complementarySalesItems: {
    displayItem: true,
  },
  voidedSalesItems: {
    displayItem: true,
  },
  voidedOrderItems: {
    displayItem: true,
  },
  footer: {
    totalCustomerCount: true,
    customerAveragePay: true,
    totalExpensesAmount: true,
    complementaryDiscountAmount: true,
    complementarySurchargeAmount: true,
    complementaryTaxAmount: true,
    complementaryTotalSales: true,
    complementaryNetSales: true,
  },
};

/**
 * @name XReportTemplate
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const XReportTemplate = ({
  // xReportData
  customizeXZReport,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [account] = useGlobalState("userData.account");

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const renderText = (
    text,
    value = true,
    align = "left",
    variant = "body2",
    style
  ) =>
    value && (
      <Typography
        align={
          align === "left" ? "left" : align === "right" ? "right" : "center"
        }
        variant={
          variant === "h6"
            ? "h6"
            : variant === "subtitle2"
            ? "subtitle2"
            : "body2"
        }
        // component="span"
        style={style}
      >
        {text}
      </Typography>
    );

  const mapCamelToNormal = (text) =>
    text.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });

  const returnRepeated = (text) => {
    const normalText = mapCamelToNormal(text);
    return `${normalText}: [${normalText}]`;
  };

  const returnUnrepeated = (text) => {
    const normalText = mapCamelToNormal(text);
    return `${normalText}`;
  };

  const checkRecursive = (obj) => {
    return Object.values(obj).every((el) =>
      typeof el === "object" ? checkRecursive(el) : el
    );
  };

  const isVisible = (firstKey, secondKey) => {
    if (secondKey)
      return customizeXZReport[firstKey][secondKey]
        ? checkRecursive(customizeXZReport[firstKey][secondKey])
        : false;
    else
      return customizeXZReport[firstKey]
        ? checkRecursive(customizeXZReport[firstKey])
        : false;
  };
  // Component Render
  return (
    <Box className={classes.root} height="100%">
      <Card elevation={0} style={{ overflow: "auto", height: "100%" }}>
        <CardContent className={classes.cardContent}>
          <img
            src={account.brands[0].logoPath}
            style={{ maxHeight: 80 }}
            alt="Brand Image"
          />

          {renderText("X-Report", true, "center", "h6", { marginTop: 8 })}

          <Box>
            {Object.entries(xReportData).map(
              ([firstKey, firstValue], firstIndex) => {
                if (typeof firstValue === "boolean") {
                  return renderText(
                    returnRepeated(firstKey),
                    customizeXZReport[firstKey]
                  );
                } else {
                  return (
                    <div>
                      {isVisible(firstKey) && (
                        <>
                          {firstIndex ? (
                            <Divider
                              classes={{ root: classes.singleDivider }}
                            />
                          ) : (
                            <Box marginTop={2} />
                          )}
                        </>
                      )}

                      {renderText(
                        returnUnrepeated(firstKey),
                        isVisible(firstKey),
                        "center",
                        "subtitle2",
                        { textDecoration: "underline" }
                      )}

                      {firstKey === "header" && (
                        <>
                          {renderText(returnRepeated("employeeName"))}
                          {renderText(returnRepeated("reportTime"))}
                          {renderText(returnRepeated("salesDay"))}
                        </>
                      )}

                      {Object.entries(firstValue).map(
                        ([secondKey, secondValue], secondIndex) => {
                          if (typeof secondValue === "boolean") {
                            if (secondKey === "displayItem") {
                              return (
                                customizeXZReport[firstKey] && (
                                  <>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      width="100%"
                                      m={0.5}
                                    >
                                      {renderText("QTY   Name")}
                                      {renderText("Price", true, "right")}
                                    </Box>
                                    <Box
                                      display="flex"
                                      justifyContent="space-between"
                                      width="100%"
                                      m={0.5}
                                    >
                                      {renderText("[Quantity]   [Name]")}
                                      {renderText("[Price]", true, "right")}
                                    </Box>
                                    <Divider
                                      classes={{ root: classes.singleDivider }}
                                    />
                                    {renderText(
                                      "Total: [Total]",
                                      true,
                                      "right"
                                    )}
                                  </>
                                )
                              );
                            } else
                              return renderText(
                                returnRepeated(secondKey),
                                isVisible(firstKey, secondKey)
                              );
                          } else {
                            return (
                              <div>
                                {isVisible(firstKey, secondKey) && (
                                  <Divider
                                    classes={{ root: classes.singleDivider }}
                                  />
                                )}
                                {renderText(
                                  returnUnrepeated(secondKey),
                                  isVisible(firstKey, secondKey),
                                  "left",
                                  "subtitle2",
                                  { borderBottom: "2px solid white" }
                                )}

                                {Object.entries(secondValue).map(
                                  ([thirdKey, thirdValue], thirdIndex) => {
                                    return (
                                      <Box>
                                        {renderText(
                                          returnRepeated(thirdKey),
                                          secondKey === "salesSectionName" ||
                                            secondKey === "paymentMethodName"
                                            ? customizeXZReport[firstKey][
                                                thirdKey
                                              ]
                                            : customizeXZReport[firstKey][
                                                secondKey
                                              ][thirdKey],
                                          "left",
                                          "body2",
                                          { margin: 8 }
                                        )}
                                      </Box>
                                    );
                                  }
                                )}
                              </div>
                            );
                          }
                        }
                      )}
                    </div>
                  );
                }
              }
            )}
            <>
              {renderText(returnRepeated("totalDiscountAmount"))}
              {renderText(returnRepeated("totalSurchargeAmount"))}
              {renderText(returnRepeated("totalTaxAmount"))}
              {renderText(
                returnRepeated("totalSales"),
                true,
                "left",
                "subtitle2"
              )}
            </>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

XReportTemplate.propTypes = {
  /**
   *
   */
};

XReportTemplate.defaultProps = {
  /**
   *
   */
};

export default XReportTemplate;
