// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Material
import {
  Toolbar,
  useTheme,
  Button,
  CircularProgress,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Divider,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Tooltip,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import StorageIcon from "@material-ui/icons/Storage";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { useForm } from "Helpers/Hooks";
import { getReportDate, getDateDurations } from "Utils";
import { displaySnackbarFormErrors } from "Utils/displaySnackbarErrors";

// Components
import { DatePicker, SelectField } from "Components/FormFields";
import App from "../PDFViewer/App";
import Row from "../Row";
import Memo from "Components/Memo";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import _, { constant, set } from "lodash";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import moment from "moment";

// Services
import { useGetSummary, useGetSummaryPdf } from "Services/Reports/";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 660,
    scroll: "auto",
  },
}));

// Ad-Hoc Components
const preColumns = [
  {
    id: "count",
    label: <LocalizedMessage id="report.count" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "discount",
    label: <LocalizedMessage id="report.discount" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "surcharge",
    label: <LocalizedMessage id="report.surcharge" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "tax",
    label: <LocalizedMessage id="report.tax" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "staff",
    label: <LocalizedMessage id="report.staff" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "complementaryTotalSales",
    label: <LocalizedMessage id="report.complementaryTotal" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "complementaryNetSales",
    label: <LocalizedMessage id="report.complementaryNetSales" />,
    minWidth: 100,
    format: false,
  },
];

const postColumns = [
  {
    id: "totalSales",
    label: <LocalizedMessage id="report.totalsales" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "netSales",
    label: <LocalizedMessage id="report.netsales" />,
    minWidth: 100,
    format: false,
  },
];

/**
 * @name Summary
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Summary = ({ permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [footerRow, setFooterRow] = useStateRef([]);

  const [isFetchingRows, setIsFetchingRows] = useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = React.useState(false);
  const [pdf, setPdf] = useState("");
  const [PDFViewerOpen, setPDFViewerOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [hasPdfAccess, setHasPdfAccess] = useState(false);
  const [hasTableAccess, setHasTableAccess] = useState(false);
  const [spaningData, setSpaningData] = useState([]);
  // Effect Hooks

  // Other Hooks
  const intl = useIntl();
  const getSummary = useGetSummary();
  const getSummaryPdf = useGetSummaryPdf();
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Joi.object({
    dateDuration: Joi.string()
      .valid(
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "thisYear",
        "lastYear",
        "custom"
      )
      .required(),
    dateFrom: Joi.date(),
    dateTo: Joi.date().min(Joi.ref("dateFrom")),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "report" });

  useEffect(() => {
    const { from, to } = getReportDate("today", "", "");
    setFormData({
      dateDuration: "today",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  useEffect(() => {
    if (formData.dateDuration === "today") handleSearch();
  }, [formData.dateDuration]);

  useEffect(() => {
    if (formData.dateDuration) handleSearch();
  }, [PDFViewerOpen]);

  useEffect(() => {
    if (permissions.isAdmin) {
      setHasPdfAccess(true);
      setHasTableAccess(true);
    } else {
      if (permissions.reportSummary.includes("pdf")) setHasPdfAccess(true);
      if (permissions.reportSummary.includes("get")) setHasTableAccess(true);
    }
  }, []);

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    const validForm = validateForm();

    if (validForm) {
      setIsFetchingRows(true);
      setIsFetchingPdf(true);
      setPdf("");
      setRows([]);

      let { dateDuration, dateFrom, dateTo } = formData;
      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      setFormData({ ...formData, dateFrom: from, dateTo: to });

      if (hasTableAccess && !PDFViewerOpen)
        getSummary({
          dateFrom: from,
          dateTo: to,
        })
          .then((response) => {
            setColumns([
              ...preColumns,
              // ...response.paymentMethods.map((paymentMethod) =>
              //   createColumn(paymentMethod)
              // ),
              ...postColumns,
            ]);
            if (response.data.length > 0) {
              const cols = Object.keys(response.data[0]);
              let newSpaningData = {};
              cols.map((column) => (newSpaningData[column] = 0));

              response.data.map((key) =>
                Object.entries(key).map((k) => {
                  newSpaningData[k[0]] += k[1];
                })
              );
              delete newSpaningData.data;
              delete newSpaningData.hour;
              setSpaningData(newSpaningData);
            }
            setRows(response.data);
            setFooterRow(response.footer);
            setIsFetchingRows(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else {
        setPDFViewerOpen(true);
        setIsFetchingRows(false);
      }

      if (hasPdfAccess && PDFViewerOpen)
        getSummaryPdf({
          dateFrom: from,
          dateTo: to,
        })
          .then((response) => {
            console.log(response, 14);
            const blob = new Blob([response], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            setPdf(objectUrl);
            setIsFetchingPdf(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else setIsFetchingPdf(false);
    }
  };

  // Other
  const dateDurations = getDateDurations(intl);

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h6"
            className={classes.heading}
            style={{ fontSize: "1rem" }}
          >
            <LocalizedMessage id="report.reportFilters" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex">
            <Box
              style={{
                flex: "1 1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <SelectField
                neglectNone
                fullWidth
                name="dateDuration"
                tooltip={"Date Duration"}
                helpKey="Menu.category.dateDuration"
                label={<LocalizedMessage id="report.dateDuration.title" />}
                onChange={onFormFieldChange}
                value={formData.dateDuration}
                errors={formErrors.dateDuration}
                isSubmitting={isFetchingRows || isFetchingPdf}
                isLoading={isLoading.dateDuration}
                options={dateDurations}
                defaultValue="today"
              />
              {formData.dateDuration === "custom" && (
                <>
                  <DatePicker
                    name="dateFrom"
                    tooltip={"Date from"}
                    helpKey="common.dateFrom"
                    label={intl.formatMessage({ id: "common.dateFrom" })}
                    onChange={onFormFieldChange}
                    value={formData.dateFrom}
                    errors={formErrors.dateFrom}
                    isSubmitting={isFetchingRows || isFetchingPdf}
                    isLoading={isLoading.dateFrom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                  />
                  <DatePicker
                    name="dateTo"
                    tooltip={"Date from"}
                    helpKey="common.dateTo"
                    label={intl.formatMessage({ id: "common.dateTo" })}
                    onChange={onFormFieldChange}
                    value={formData.dateTo}
                    errors={formErrors.dateTo}
                    isSubmitting={isFetchingRows || isFetchingPdf}
                    isLoading={isLoading.dateTo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                  />
                </>
              )}
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={isFetchingRows || isFetchingPdf}
              onClick={handleSearch}
              style={{ margin: "16px" }}
              startIcon={!isFetchingRows && !isFetchingPdf && <DoneIcon />}
            >
              {isFetchingRows || isFetchingPdf ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.apply" />
              )}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Memo deps={[pdf, rows.getState()]}>
        {() => (
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: 16,
              overflow: "auto",
            }}
          >
            {formData.dateDuration && (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="report.dateDuration.title" />
                    {": " +
                      dateDurations.filter(
                        (dd) => formData.dateDuration === dd.value
                      )[0]?.text}
                  </Typography>
                }
              />
            )}
          </Toolbar>
        )}
      </Memo>

      {hasPdfAccess && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title={
              PDFViewerOpen ? (
                <LocalizedMessage id="report.showTable" />
              ) : (
                <LocalizedMessage id="report.showPDFViewer" />
              )
            }
          >
            {downSm ? (
              <Button
                style={{ flex: 1 }}
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => setPDFViewerOpen((prevState) => !prevState)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {PDFViewerOpen ? (
                  <>
                    <StorageIcon style={{ marginRight: 8 }} />
                    <LocalizedMessage id="report.showTable" />
                  </>
                ) : (
                  <>
                    <PictureAsPdfIcon style={{ marginRight: 8 }} />
                    <LocalizedMessage id="report.showPDFViewer" />
                  </>
                )}
              </Button>
            ) : (
              <IconButton
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => setPDFViewerOpen((prevState) => !prevState)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {PDFViewerOpen ? <StorageIcon /> : <PictureAsPdfIcon />}
                <Typography variant="body2" component="h2"></Typography>
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title={<LocalizedMessage id="report.downloadPDF" />}>
            {downSm ? (
              <Button
                style={{ flex: 1 }}
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => window.open(pdf)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                <GetAppIcon style={{ marginRight: 8 }} />
                <LocalizedMessage id="report.downloadPDF" />
              </Button>
            ) : (
              <IconButton
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => window.open(pdf)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {<GetAppIcon />}
                <Typography variant="body2" component="h2"></Typography>
              </IconButton>
            )}
          </Tooltip>
        </Box>
      )}

      {(isFetchingRows || isFetchingPdf) && inputSkeleton ? (
        inputSkeleton
      ) : PDFViewerOpen && pdf ? (
        <Box>
          <App downSm={downSm} url={pdf} />
        </Box>
      ) : (
        <Paper
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {/* {rows.getState().length ? (
                  <TableBody>
                    {rows.getState()
                      ? rows
                          .getState()
                          .map((row, index) => (
                            <Row
                              key={index}
                              row={row}
                              columns={columns}
                              secondaryColumns={[]}
                            />
                          ))
                      : null} */}
                {rows.getState().length ? (
                  <TableBody>
                    {rows.getState()
                      ? rows.getState().map((row, index) => (
                          <TableRow key={index}>
                            <TableCell />
                            {columns.map((column, index) => {
                              return (
                                <TableCell
                                  key={column.id + "-second"}
                                  align={column.align}
                                >
                                  {row[column.id]}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        ))
                      : null}
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rows.getState().length + 1}
                    >
                      <TableCell />
                      {footerRow.getState()
                        ? footerRow.getState().map((row, index) => (
                            <TableCell
                              key={index + "-footer"}
                              style={{ fontWeight: "bold" }}
                            >
                              {row}
                            </TableCell>
                          ))
                        : null}
                    </TableRow>
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

Summary.propTypes = {
  /**
   *
   */
};

Summary.defaultProps = {
  /**
   *
   */
};

export default Summary;
