import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import payloads from "./payloads";

const handler = ({
  formName,
  form,
  apiHandler,
  callback,
  enqueueSnackbar,
  onClose,
  editId = undefined,
  selectedCategory = undefined,
}) => {
  const { formData, setFormErrors, validateForm, setIsSubmiting } = form;
  const payload = payloads[formName](formData, selectedCategory);

  const validForm = validateForm();
  console.log(validForm ? "validForm" : "Not valid form");
  console.log("payload: ", payload);
  if (validForm) {
    setIsSubmiting(true);
    apiHandler({ ...payload, id: editId })
      .then((response) => {
        displayenqueueSnackbar(enqueueSnackbar, editId ? "edited" : "added");
        setIsSubmiting(false);
        callback(response);
        onClose();
      })
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
        setIsSubmiting(false);
      });
  }
};

export default handler;
