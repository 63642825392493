// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  FormControl,
  useMediaQuery,
  useTheme,
  Divider,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarFormErrors,
  displaySnackbarErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import payloads from "Helpers/Handlers/payloads";

// Components
import Title from "Components/Title";
import Box from "Components/Box";
import {
  TextField,
  ChipField,
  MapField,
  AutoCompleteField,
} from "Components/FormFields";

// Factories

// Utils
import { throttle } from "Utils";

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";

// Services
import { useAddBranch, useGetBranch } from "Services/Wizard/Branches/";
import { useGetAllCurrencies } from "Services/Currencies/";
import { useGetAllCountries } from "Services/Countries/";
import { useGetAllTimezones } from "Services/Timezones/";
import { useAddStorage } from "Services/Storage";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: { flexDirection: "column" },
    [theme.breakpoints.up("sm")]: { flexDirection: "row" },
  },
  vectorContainer: {
    display: "flex",
    flex: 1,
  },
  vectorWrapper: {
    flex: 1,
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(2) },
    [theme.breakpoints.up("sm")]: { padding: theme.spacing(7) },
  },
  vector: {
    maxHeight: "200px",
    maxWidth: "100%",
  },
  formContainer: {
    flexGrow: 1,
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(3) },
    [theme.breakpoints.up("sm")]: { padding: theme.spacing(7) },
    height: "100%",
    display: "flex",
    flex: 3,
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    display: "flex",
  },
  formControl: {
    display: "flex",
    flex: 1,
  },
}));

// Ad-Hoc Components

// Other
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().min(1).max(100).allow(null, ""),
  currencyId: Joi.string().required(),
  countryId: Joi.string().required(),
  city: Joi.string().required().min(1).max(100),
  cityOtherName: Joi.string().allow("").allow(null),
  address: Joi.string().required(),
  addressOtherName: Joi.string().allow("").allow(null),
  commercialRegistrationNumber: Joi.when("countryId", {
    is: "SA",
    then: Joi.string().required(),
    otherwise: Joi.string().allow("").allow(null),
  }),
  vatRegistrationNumber: Joi.when("countryId", {
    is: "SA",
    then: Joi.string()
      .length(15)
      .regex(/^[0-9]+$/)
      .required(),
    otherwise: Joi.string().allow("").allow(null),
  }),
  timezone: Joi.string().required(),
  phoneNumbers: Joi.array().required().items(Joi.number()),
  location: Joi.object({
    lng: Joi.number().min(-180).max(180).allow(null),
    lat: Joi.number().min(-90).max(90).allow(null),
  }),
});

/**
 * @name BranchSetup
 * @summary BranchSetup Component
 * @category
 * @component
 * @description
 * >
 */
const BranchSetup = ({
  className,
  setDirty,
  setIsReady,
  setStepSubmittingCallback,
  ...otherProps
}) => {
  // Local Storage Fetch

  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [branch, setBranch] = useGlobalState("userData.branch");

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false);

  const [currencies, setCurrencies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [isFetchingBranch, setIsFetchingBranch] = useState(true);
  const [isFetchingCurrencies, setIsFetchingCurrencies] = useState(true);
  const [isFetchingCountries, setIsFetchingCountries] = useState(true);
  const [isFetchingTimezones, setIsFetchingTimezones] = useState(true);

  // Other Hooks
  const addStorage = useAddStorage();
  const getAllCurrencies = useGetAllCurrencies();
  const getAllCountries = useGetAllCountries();
  const getAllTimezones = useGetAllTimezones();
  const addBranch = useAddBranch({ languageKey: "wizard.branchSetup" });
  const getBranch = useGetBranch();

  const { enqueueSnackbar } = useSnackbar();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
  } = useForm({ validationSchema, languageKey: "wizard.branchSetup" });

  // Event Handlers
  const addStorageHandler = (value) => {
    addStorage({ key: "wizard", value: value })
      .then((response) => console.log(response))
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
      });
  };

  const onSubmitHandler = throttle(({ onCalled, onSuccess, onCancel }) => {
    const validForm = validateForm();

    if (validForm) {
      setIsSubmiting(true);
      onCalled();
      const payload = payloads["branch"](formData);
      addBranch(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          setBranch(response.data.data);
          addStorageHandler("portal");
          onSuccess();
          setIsSubmiting(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
          onCancel();
        });
    }
  }, 1000);

  // Effect Hooks
  useEffect(() => {
    setStepSubmittingCallback(() => onSubmitHandler);
  }, [formData]);

  useEffect(() => {
    if (
      !isFetchingCountries &&
      !isFetchingCurrencies &&
      !isFetchingTimezones &&
      !isFetchingBranch
    ) {
      setIsScreenLoaded(true);
      setIsReady(true);
    }
  }, [
    isFetchingCurrencies,
    isFetchingCountries,
    isFetchingTimezones,
    isFetchingBranch,
  ]);

  useEffect(() => {
    getBranch()
      .then((response) => {
        setFormData(response);
        setIsFetchingBranch(false);
      })
      .catch((errors) => {
        setIsFetchingBranch(false);
      });
  }, []);

  useEffect(() => {
    getAllCurrencies()
      .then((type) => {
        setCurrencies(() =>
          type.map((sc) => ({ value: sc.id, text: sc.name }))
        );
        setIsFetchingCurrencies(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  useEffect(() => {
    getAllCountries()
      .then((countries) => {
        setCountries(() =>
          countries.map((sc) => ({ value: sc.id, text: sc.name }))
        );
        setIsFetchingCountries(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  useEffect(() => {
    getAllTimezones()
      .then((zones) => {
        setTimezones(() =>
          zones.map((sc) => ({ value: sc.name, text: sc.name }))
        );
        setIsFetchingTimezones(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <>
      <Box className={[classes.root, className].join(" ")} {...otherProps}>
        <Title
          pageTitle={<LocalizedMessage id="wizard.branchSetup.pageTitle" />}
        />

        {!downSm && (
          <>
            <Box className={classes.vectorContainer}>
              <Box className={classes.vectorWrapper}>
                <MapField
                  fullWidth
                  label={<LocalizedMessage id="Branch Location" />}
                  name="location"
                  tooltip={"Branch Location"}
                  helpKey="branch.NameField"
                  //  maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.location}
                  errors={formErrors.location}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {downSm && (
          <>
            <Box className={classes.formContainer}>
              <Typography component="h1" variant="h6">
                <LocalizedMessage id="wizard.branchSetup.fillInOneOfYourBranchesDetails" />
              </Typography>
            </Box>
            <Divider variant="middle" />
            <Box className={classes.vectorContainer}>
              <Box className={classes.vectorWrapper}>
                <MapField
                  fullWidth
                  label={<LocalizedMessage id="Branch Location" />}
                  name="location"
                  tooltip={"Branch Location"}
                  helpKey="branch.NameField"
                  //  maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.location}
                  errors={formErrors.location}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />
              </Box>
            </Box>
          </>
        )}

        <Box className={classes.formContainer}>
          {!downSm && (
            <Typography component="span" variant="h6">
              <LocalizedMessage id="wizard.branchSetup.fillInOneOfYourBranchesDetails" />
            </Typography>
          )}
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Branch Name"}
                helpKey="Menu.Branch.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <TextField
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                autoComplete="otherName"
                tooltip={"Branch otherName"}
                helpKey="Menu.Branch.otherNameField"
                // maxCharacters={50}
                fullWidth
                onChange={onFormFieldChange}
                value={formData.otherName ? formData.otherName : ""}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <AutoCompleteField
                fullWidth
                required
                label={<LocalizedMessage id="common.countryId" />}
                name="countryId"
                tooltip={"Branch Country"}
                helpKey="BranchSetup.country"
                //  maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.countryId}
                errors={formErrors.countryId}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                options={countries}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="common.city" />}
                name="city"
                tooltip={"Branch City"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.city}
                errors={formErrors.city}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.cityOtherName" />}
                name="cityOtherName"
                tooltip={"Branch cityOtherName"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.cityOtherName}
                errors={formErrors.cityOtherName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <AutoCompleteField
                fullWidth
                required
                helpKey="common.timezone"
                label={<LocalizedMessage id="common.timezone" />}
                name="timezone"
                tooltip={"Branch Time Zone"}
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.timezone}
                errors={formErrors.timezone}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                options={timezones}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
              <AutoCompleteField
                fullWidth
                required
                label={<LocalizedMessage id="common.currencyId" />}
                name="currencyId"
                tooltip={"Currency"}
                helpKey="BranchSetup.country"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.currencyId}
                errors={formErrors.currencyId}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                options={currencies}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
              <ChipField
                required
                fullWidth
                label={<LocalizedMessage id="common.phoneNumbers" />}
                name="phoneNumbers"
                tooltip={"Branch Phone Numbers"}
                helpKey="common.phoneNumbers"
                onChange={onFormFieldChange}
                value={formData.phoneNumbers}
                errors={formErrors.phoneNumbers}
                helperText="Enter one phone number and press Enter then add more"
                isLoading={!isScreenLoaded}
                isSubmitting={isSubmitting}
              />
              <TextField
                fullWidth
                label={
                  <LocalizedMessage id="common.commercialRegistrationNumber" />
                }
                name="commercialRegistrationNumber"
                tooltip={"Branch commercialRegistrationNumber"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.commercialRegistrationNumber}
                errors={formErrors.commercialRegistrationNumber}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.vatRegistrationNumber" />}
                name="vatRegistrationNumber"
                tooltip={"Branch vatRegistrationNumber"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.vatRegistrationNumber}
                errors={formErrors.vatRegistrationNumber}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />

              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="common.address" />}
                name="address"
                tooltip={"Branch address"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.address}
                errors={formErrors.address}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.addressOtherName" />}
                name="addressOtherName"
                tooltip={"Branch addressOtherName"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.addressOtherName}
                errors={formErrors.addressOtherName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
            </FormControl>
          </form>
        </Box>
      </Box>
    </>
  );
};

BranchSetup.propTypes = {
  /**
   *
   */
};

export default BranchSetup;
