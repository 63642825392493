// Material

// Components

import React, { useState, useEffect } from "react";

// Material
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  Chip,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import PhonelinkIcon from "@material-ui/icons/Phonelink";
import DevicesIcon from "@material-ui/icons/Devices";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import TabletIcon from "@material-ui/icons/Tablet";
import DriveEtaIcon from "@material-ui/icons/DriveEta";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import useSkeleton from "Helpers/useSkeleton";

// Components
import QRDialog from "./POSQRDialog";
import Title from "Components/Title";
import SubscriptionAlert from "Components/SubscriptionAlert";

// Factories

// Screens
import POSForm from "./POSForm";
// import DownloadPOSCard from "Screens/Portal/Dashboard/DownloadPOSCard";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import moment from "moment";

// Services
import {
  useGetAllDevices,
  useGetTempCode,
  useEditDevice,
  useAddDevice,
} from "Services/POS/";
import DeviceRow from "./DeviceRow";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
    container: {
      maxHeight: 440,
    },
  };
});

// Ad-Hoc Components
const columns = [
  {
    id: "deviceName",
    label: <LocalizedMessage id="POS.deviceName" />,
    minWidth: 100,
  },
  {
    id: "deviceCode",
    label: <LocalizedMessage id="POS.deviceCode" />,
    minWidth: 100,
  },
  {
    id: "deviceType",
    label: <LocalizedMessage id="POS.type" />,
    minWidth: 100,
  },
  {
    id: "printerName",
    label: <LocalizedMessage id="POS.printerName" />,
    minWidth: 100,
  },
  {
    id: "subscription",
    label: <LocalizedMessage id="subscriptionDialog.pageTitle" />,
    minWidth: 100,
  },
  {
    id: "expirationDate",
    label: <LocalizedMessage id="POS.expirationDate" />,
    minWidth: 100,
  },
  { id: "actions", label: "", minWidth: 50 },
];
const sessionsColumns = [
  {
    id: "macAddress",
    label: <LocalizedMessage id="POS.macAddress" />,
    minWidth: 100,
    align: "left",
  },
  {
    id: "ipAddress",
    label: <LocalizedMessage id="POS.ipAddress" />,
    minWidth: 100,
    align: "right",
  },
  {
    id: "deviceModel",
    label: <LocalizedMessage id="POS.deviceModel" />,
    minWidth: 100,
    align: "right",
  },
  {
    id: "operatingSystem",
    label: <LocalizedMessage id="POS.operatingSystem" />,
    minWidth: 100,
    align: "right",
  },
];

/**
 * @name POS
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const POS = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [branch] = useGlobalState("userData.branch");
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useState([]);
  const [isFetchingRows, setIsFetchingRows] = useState(true);
  const [QRData, setQRData] = useState(undefined);
  const [editId, setEditId] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openBranchDialog, setOpenBranchDialog] = useState(false);
  const [isLoadingQr, setIsLoadingQr] = useState(false);
  const [subscriptionLink, setSubscriptionLink] = useState("");
  const [status, setStatus] = useState(0);
  const [openSubscriptionAlert, setOpenSubscriptionAlert] = useState(false);
  const [openSubscribeNowDialog, setOpenSubscribeNowDialog] = useState(false);

  /* Printer Form */
  const [printerFormOpen, setPrinterFormOpen] = useState(false);
  const [printerFormMode, setPrinterFormMode] = useState("");
  const [printerFormData, setPrinterFormData] = useState({});

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <TabletIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage id="POS.pageTitle" key="POS.pageTitle" />
        </Box>
      ),
    []
  );

  useEffect(() => {
    getRows();
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const editDevice = useEditDevice({ languageKey: "POS" });
  const getAllDevices = useGetAllDevices();
  const addDevice = useAddDevice({ languageKey: "POS" });
  const getTempCode = useGetTempCode({ languageKey: "account" });
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onEditClick = (device) => {
    setEditId(device.id);
    setPrinterFormData(device);
    setPrinterFormMode("edit");
    setPrinterFormOpen(true);
  };

  const onEditPrinterHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLosePrinterForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const { printerId, subscriptionId, type, name } = formData;
      editDevice({
        id: editId,
        printerId,
        subscriptionId,
        type,
        name,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          const index = rows.findIndex((row) => row.id === editId);
          rows.splice(index, 1, createData(response.data.data));
          setRows(rows);
          setIsSubmiting(false);
          onCLosePrinterForm();
        })
        .catch((errors) => {
          console.log(errors);
          errors.forEach((e) => {
            if (e.field) {
              setFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }));
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
          setIsSubmiting(false);
        });
    }
  };

  const onAddDeviceHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLosePrinterForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const { printerId, subscriptionId, type, name } = formData;
      addDevice({
        printerId,
        subscriptionId,
        type,
        name,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "Added");
          const newDevice = createData(response.data.data);
          setRows([newDevice, ...rows]);
          setIsSubmiting(false);
          onCLosePrinterForm();
        })
        .catch((errors) => {
          console.log(errors);
          errors.forEach((e) => {
            if (e.field) {
              setFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }));
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
          setIsSubmiting(false);
        });
    }
  };
  const handleOpenQrDialog = (deviceId) => {
    setIsLoadingQr(true);

    getTempCode(deviceId)
      .then((res) => {
        setOpenDialog(true);
        setQRData({
          tempCode: res,
        });
        setIsLoadingQr(false);
      })
      .catch((errors, status, link) => {
        setSubscriptionLink(link);
        setStatus(status);
        setIsLoadingQr(false);
        setOpenSubscriptionAlert(true);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const handleAddNewDevice = () => {
    setPrinterFormMode("add");
    setPrinterFormOpen(true);
  };

  const handleCloseQrDialog = () => {
    setOpenDialog(false);
    setOpenBranchDialog(false);
    setQRData(undefined);
    getRows();
  };

  const getRows = () => {
    setIsFetchingRows(true);
    getAllDevices()
      .then((deviceTable) => {
        const newPOSTable = deviceTable
          .sort((a, b) => (b.type.toLocaleLowerCase() === "server" ? 1 : -1))
          .map((device) => createData(device));
        setRows(newPOSTable);
        setIsFetchingRows(false);
      })
      .catch((errors) => {
        console.log(errors);
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingRows(false);
      });
  };

  // Other
  const createData = (device) => {
    return {
      id: device.id,
      deviceName: device.name,
      deviceType: (
        <LocalizedMessage
          id={`common.${device.type.toLocaleLowerCase()}`}
          key={`common.${device.type.toLocaleLowerCase()}`}
        />
      ),
      deviceCode: device.code,
      activeSessionId: device.activeSessionId,
      printerName: device.printer ? (
        device.printer.name
      ) : (
        <LocalizedMessage id="POS.noPrinter" key="POS.noPrinter" />
      ),
      subscription: (
        <Box>
          {device.subscription?.subscriptionStatus === "Active" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={device.subscription?.subscriptionStatus}
                style={{
                  backgroundColor: "#00c853",
                  color: "white",
                }}
              />
              <Typography variant="caption">
                {`${device.subscription?.productName} - ${device.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : device.subscription?.subscriptionStatus === "InGrace" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={device.subscription?.subscriptionStatus
                  .match(/[A-Z][a-z]+|[0-9]+/g)
                  .join(" ")}
                style={{
                  backgroundColor: "#e48f2a",
                  color: "white",
                }}
              />
              <Typography variant="caption">
                {`${device.subscription?.productName} - ${device.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : device.subscription?.subscriptionStatus === "Expired" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={device.subscription?.subscriptionStatus}
                style={{
                  backgroundColor: "#c62828",
                  color: "white",
                  marginRight: 8,
                }}
              />
              <Typography variant="caption">
                {`${device.subscription?.productName} - ${device.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : device.subscription?.subscriptionStatus === "InCountingDown" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={device.subscription?.subscriptionStatus
                  .match(/[A-Z][a-z]+|[0-9]+/g)
                  .join(" ")}
                style={{
                  backgroundColor: "#ff9800",
                  color: "white",
                  marginRight: 8,
                }}
              />
              <Typography variant="caption">
                {`${device.subscription?.productName} - ${device.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ),
      expirationDate: (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {device.subscription?.id ? (
            device.subscription?.subscriptionAutoRenew ? (
              <Tooltip title={<LocalizedMessage id="POS.autoRecurring" />}>
                <AutorenewIcon
                  style={{
                    margin: 4,
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={<LocalizedMessage id="POS.nonRecurring" />}>
                <RadioButtonUncheckedIcon
                  style={{
                    margin: 4,
                  }}
                />
              </Tooltip>
            )
          ) : null}
          <Typography>{device.subscription?.subscriptionExpiryDate}</Typography>
        </Box>
      ),
      actions: (
        <Box>
          {(permissions.isAdmin ||
            (permissions.pos && permissions.pos.includes("generateToken"))) && (
            <Tooltip title={<LocalizedMessage id="POS.generateTempCode" />}>
              <IconButton
                aria-label="generateCode"
                onClick={async () => handleOpenQrDialog(device.id)}
              >
                <PhonelinkIcon />
              </IconButton>
            </Tooltip>
          )}
          {(permissions.isAdmin || permissions.pos.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(device)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    };
  };

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={<LocalizedMessage id="POS.pageTitle" key="POS.pageTitle" />}
      />

      <POSForm
        open={printerFormOpen}
        setOpen={setPrinterFormOpen}
        mode={printerFormMode}
        editFormData={printerFormData}
        onSave={onEditPrinterHandler}
        onAdd={onAddDeviceHandler}
        permissions={permissions}
      />

      <Card
        // ref={inputRef}
        style={{ overflow: "auto" }}
      >
        <CardHeader
          title={
            <>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="h6" component="h2">
                  <LocalizedMessage id="POS.pageTitle" />
                </Typography>
                <Box display="flex" flexDirection="row" alignItems="center">
                  {(permissions.isAdmin || permissions.pos) && (
                    <Button
                      aria-label="QR"
                      size="small"
                      onClick={() => setOpenBranchDialog(true)}
                      startIcon={<DriveEtaIcon />}
                    >
                      <LocalizedMessage id="POS.addDriverDevice" />
                    </Button>
                  )}
                  {(permissions.isAdmin ||
                    (permissions.pos &&
                      permissions.pos.includes("generateToken"))) && (
                    <>
                      <Button
                        aria-label="Add"
                        size="small"
                        onClick={handleAddNewDevice}
                        startIcon={<DevicesIcon />}
                      >
                        <LocalizedMessage id="POS.addPOSDevice" />
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
              <Divider style={{ marginTop: 10 }} />
            </>
          }
        />
        <CardContent
          style={{
            // display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 20,
              overflowX: "auto",
              position: "relative",
              overflow: "visible",
            }}
          >
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        ? rows
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <DeviceRow
                                  key={row.id}
                                  row={row}
                                  columns={columns}
                                  sessionsColumns={sessionsColumns}
                                />
                              );
                            })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      {(QRData || openBranchDialog) && (
        <QRDialog
          qrData={openBranchDialog ? {} : JSON.stringify(QRData)}
          open={openDialog || openBranchDialog}
          onCloseDialog={handleCloseQrDialog}
          driverOrPOS={openBranchDialog ? "driver" : "POS"}
        />
      )}
      <SubscriptionAlert
        open={openSubscriptionAlert}
        onCloseDialog={() => setOpenSubscriptionAlert(false)}
        status={status}
        onSubscribeNowClick={() => setOpenSubscribeNowDialog(true)}
        branchOrDevice="device"
      />
    </Box>
  );
};

POS.propTypes = {
  /**
   *
   */
};

POS.defaultProps = {
  /**
   *
   */
};

export default POS;
