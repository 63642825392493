// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { Box, Button, Typography, useTheme } from "@material-ui/core";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";

// Components
import HelpIcon from "Components/HelpIcon";

// Factories

// Screens

// Assets

// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components

/**
 * @name ModifierCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ModifierCard = ({ style, modifier, editModifierGroup, deleteModifierGroup }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingLeft: Theme.spacing(1),
      }}
    >
      <Typography variant="body2" component="h2">
        {modifier.name}
      </Typography>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={editModifierGroup}>
          <LocalizedMessage id={"item.editModifier"} />
        </Button>
        <Button color="secondary" onClick={deleteModifierGroup}>
          <LocalizedMessage id={"item.deleteModifier"} />
        </Button>
      </Box>
    </Box>
  );
};

ModifierCard.propTypes = {
  /**
   *
   */
};

export default ModifierCard;
