// React
import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

// Material

// Globals

// Helpers
import { useGlobalState } from "Helpers/GlobalState";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @summary Manipulate DOM Title
 * @category Components
 * @component
 * @requires React
 * @requires ReactDOM
 * @requires PropTypes
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @description
 * > Clears DOM Title tag<br />
 * > Appends the page title and site title texts to the Title tag
 * @example
 * return (
 *   <>
 *      <Title pageTitle="Title Component Effect" appTitle="Docs" />
 *      <code class="prettyprint prettyprinted">
 *          <pre>
 *              {'<Title pageTitle="Title Component Effect" appTitle="Docs" />'}
 *          </pre>
 *      </code>
 *      <p>See the page title changed to "Title Component Effect - Docs"!</p>
 *   </>
 * )
 */
const Title = (props) => {
  // Props Destructing
  let { pageTitle, appTitle } = props;

  // Theme & Style Hooks

  // Global State Hooks
  const [locale] = useGlobalState("global.locale");

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const titleEl = document.getElementsByTagName("title")[0];
  if (!appTitle) {
    appTitle = process.env["REACT_APP_TITLE_" + locale.toUpperCase()];
  }

  // Component Render
  return (
    <>
      {ReactDOM.createPortal(
        (pageTitle ? [pageTitle, " - ", appTitle] : [appTitle]) || [""],
        titleEl
      )}
    </>
  );
};

Title.propTypes = {
  /**
   * Current Page Title
   */
  pageTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  /**
   * Main App Title
   */
  appTitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

Title.defaultProps = {};

export default Title;
