// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import { Divider, Paper, AppBar, Tabs, Tab, useTheme } from "@material-ui/core/"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"

// Components

// Factories

// Screens

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views"

// Services

// Styles
const useStyles = makeStyles(() => ({}))

// Ad-Hoc Components
const TabPanel = (props) => {
  const { children, current, self } = props

  return (
    <div style={{ display: current !== self ? "none" : null }}>{children}</div>
  )
}

/**
 * @name Tabbed
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Tabbed = ({ style, tabs, isTabbed, children }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [value, setValue] = useState(0)

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const handleChange = (ev, newValue) => {
    setValue(newValue)
  }

  const handleChangeIndex = (index) => {
    setValue(index)
  }

  // Other

  // Component Render
  return (
    <div>
      {isTabbed && <Divider />}
      <Paper style={{ position: "static" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="action tabs example"
            variant="scrollable"
            scrollButtons="on"
          >
            {tabs.map((e, i) => (
              <Tab key={`${i} ${e.label}`} label={e.label} icon={e.icon} />
            ))}
          </Tabs>
        </AppBar>
      </Paper>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabs.map((tab, index) => (
          <Paper
            key={tab.permissionName}
            dir={theme.direction}
            style={{ padding: tab.isTabbed ? 0 : theme.spacing(2) }}
            elevation={2}
            square
          >
            {tab.node}
          </Paper>
        ))}
      </SwipeableViews>
    </div>
  )
}

Tabbed.propTypes = {
  /**
   *
   */
}

Tabbed.defaultProps = {
  /**
   *
   */
}

export default Tabbed
