const items = [
  {
    "id": "599c64fb-0b87-43ed-b466-e8fa0f395496",
    "name": "Pizzas",
    "otherName": "صحة",
    "items": [
      {
        "id": "c7b070ba-c44b-4444-9da8-c54f283db62e",
        "name": "Table - 52873",
        "otherName": "دراجة هوائية",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 1186.2,
        "averagePreparationTime": null,
        "color": "#fed8cf",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 3989,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "bb6e21d0-7679-4d35-bd3b-2cb1d50d2dfe",
        "name": "Shirt - 96427",
        "otherName": "تونة",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 8425.4,
        "averagePreparationTime": null,
        "color": "#bce437",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 16952,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "daccfc58-195a-4737-be88-92cc37c21ab6",
        "name": "Towels - 26356",
        "otherName": "سجق",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 8526.3,
        "averagePreparationTime": null,
        "color": "#d364ea",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 76275,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "a7528704-8e5b-4239-985a-06c1bb4cb90c",
        "name": "Chair - 1477",
        "otherName": "حاسوب",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 3579,
        "averagePreparationTime": null,
        "color": "#12ef75",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 29258,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "17de1f76-cbf1-48ee-8bd9-9ec8dd5f7f3b",
        "name": "Pants - 74622",
        "otherName": "حاسوب",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 3683.3,
        "averagePreparationTime": null,
        "color": "#1dedfc",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 80127,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "98d5d000-f63c-4b8c-9600-2f5bf23d658c",
        "name": "Mouse - 59497",
        "otherName": "رقائق بطاطس",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 5606.4,
        "averagePreparationTime": null,
        "color": "#d6e0d5",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 92421,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "ecb6f5cd-d959-485b-8d77-a82cf7a9f2cb",
        "name": "Soap - 9286",
        "otherName": "طاولة",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 1460.1,
        "averagePreparationTime": null,
        "color": "#a387f5",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 88441,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "d177157b-7ac9-4f59-884c-686c61cec3b4",
        "name": "Chicken - 53367",
        "otherName": "قفازات",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 9822.8,
        "averagePreparationTime": null,
        "color": "#7caf59",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 51128,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "a097c324-84a5-42bd-b253-7cfb5d6a1343",
        "name": "Keyboard - 36797",
        "otherName": "كرسي",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 9753.9,
        "averagePreparationTime": null,
        "color": "#5ac648",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 90834,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "a9fc2094-164f-436a-b3ac-7e644c53497f",
        "name": "Pants - 20462",
        "otherName": "لوحة المفاتيح",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 7605.6,
        "averagePreparationTime": null,
        "color": "#d2ecfa",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 17084,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "a98d8c52-4368-4a74-88d8-8e429922a211",
        "name": "Shoes - 8929",
        "otherName": "حاسوب",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 6821.5,
        "averagePreparationTime": null,
        "color": "#48562b",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 55805,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "d193b7cf-bdb9-4efb-860c-729d1fd2eb7c",
        "name": "Pizza - 41181",
        "otherName": "لحم خنزير مقدد",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 8930.1,
        "averagePreparationTime": null,
        "color": "#a20c19",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 57844,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "6ffa021c-fb51-4de6-a634-d27be0f3d9b0",
        "name": "Shirt - 69613",
        "otherName": "سجق",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 1213.5,
        "averagePreparationTime": null,
        "color": "#1764b",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 38281,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "f9f4f9a4-a06c-4337-9a18-bcea0b37d048",
        "name": "Bike - 24643",
        "otherName": "فأر",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 7524.1,
        "averagePreparationTime": null,
        "color": "#8169df",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 78502,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "f4adf3d3-0b07-4a04-8d46-c0197b6fdae6",
        "name": "Chair - 70212",
        "otherName": "قميص",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 2422.8,
        "averagePreparationTime": null,
        "color": "#625e89",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 79625,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "0e9ffd2e-3355-48b6-916f-0eae84fae71e",
        "name": "Shirt - 57757",
        "otherName": "قميص",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 2532.4,
        "averagePreparationTime": null,
        "color": "#2181d5",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 73298,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "c32c661f-7467-4b61-a02e-c3c0f6e2fb90",
        "name": "Mouse - 67494",
        "otherName": "بنطال",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 132.6,
        "averagePreparationTime": null,
        "color": "#aa3e98",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 51152,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "2c1a33ee-eaec-445d-88de-4e0fae90988f",
        "name": "Mouse - 53118",
        "otherName": "مناشف",
        "barcode": null,
        "categoryId": "599c64fb-0b87-43ed-b466-e8fa0f395496",
        "printerId": null,
        "price": 9112,
        "averagePreparationTime": null,
        "color": "#1fdd0c",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 626,
        "isByKilo": false,
        "modifierGroups": []
      }
    ],
    "children": [
      {
        "id": "b847f4da-cdf9-4717-b002-475e219870f6",
        "name": "Margarita",
        "otherName": "أدوات",
        "items": [
          {
            "id": "cb4ac9dc-0eca-48c3-a40c-1503d46f2e43",
            "name": "Fish - 66167",
            "otherName": "قبعة",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 9856.8,
            "averagePreparationTime": null,
            "color": "#f7eba0",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 39410,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "ac98ac39-4f1a-4e59-8db2-c445c9558166",
            "name": "Ball - 44929",
            "otherName": "مناشف",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 5794.7,
            "averagePreparationTime": null,
            "color": "#f228fc",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 88704,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "d905b83f-3a14-41f7-9435-b2683d848488",
            "name": "Chair - 8035",
            "otherName": "حاسوب",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 5740.7,
            "averagePreparationTime": null,
            "color": "#29bc92",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 42172,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "362de513-7e26-4575-a171-32ab921e868c",
            "name": "Towels - 91505",
            "otherName": "بيتزا",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 9797.5,
            "averagePreparationTime": null,
            "color": "#4ccf8c",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 32994,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "ee91663c-3492-41e5-9932-095c5d41661d",
            "name": "Cheese - 37794",
            "otherName": "لوحة المفاتيح",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 1566.7,
            "averagePreparationTime": null,
            "color": "#b89486",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 92089,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "b150f473-505b-4e03-afa8-a7c5e7043517",
            "name": "Chips - 45244",
            "otherName": "بنطال",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 3883.6,
            "averagePreparationTime": null,
            "color": "#42ec21",
            "image": null,
            "hasModifiers": null,
            "stockType": "incrementalStock",
            "defaultStock": 80250,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "80edd43c-c7e2-45b5-ba43-b2305d2d6558",
            "name": "Gloves - 73228",
            "otherName": "سجق",
            "barcode": null,
            "categoryId": "b847f4da-cdf9-4717-b002-475e219870f6",
            "printerId": null,
            "price": 5416.2,
            "averagePreparationTime": null,
            "color": "#c8afc2",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 17609,
            "isByKilo": false,
            "modifierGroups": []
          }
        ],
        "children": []
      },
    ]
  },
  {
    "id": "c9827450-b739-4710-be6c-909d91137846",
    "name": "Soups",
    "otherName": "صناعة",
    "items": [
      {
        "id": "6175e394-be1d-4b88-aa8e-a45e0095df58",
        "name": "Pants - 70283",
        "otherName": "سيارة",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 3169.8,
        "averagePreparationTime": null,
        "color": "#1dfefa",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 45306,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "8e9b0809-1d76-43ea-b947-92426a48a8c9",
        "name": "Car - 73491",
        "otherName": "دراجة هوائية",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 8007.7,
        "averagePreparationTime": null,
        "color": "#e69588",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 82727,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "d0142a9f-6b6d-4c16-97cc-139495d2c9ff",
        "name": "Fish - 64101",
        "otherName": "أحذية",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 7237.9,
        "averagePreparationTime": null,
        "color": "#952701",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 94492,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "64d5098d-73b9-43ab-9842-e22081f3a166",
        "name": "Chips - 97355",
        "otherName": "كرة",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 6199.3,
        "averagePreparationTime": null,
        "color": "#b3e345",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 14686,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "696e3295-4bc0-46f9-9a2b-747c6a77fd16",
        "name": "Pizza - 232",
        "otherName": "فأر",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 8354.7,
        "averagePreparationTime": null,
        "color": "#b35b98",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 15291,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "a9abb5e5-f0b7-4aa6-88f3-cbf6abd55713",
        "name": "Pizza - 98365",
        "otherName": "طاولة",
        "barcode": "",
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 6902,
        "averagePreparationTime": null,
        "color": "#9f270c",
        "image": null,
        "hasModifiers": false,
        "stockType": "incrementalStock",
        "defaultStock": 60912,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "f1c89c9d-3db3-496a-b784-a09aaeb3edfe",
        "name": "Table - 5203",
        "otherName": "لوحة المفاتيح",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 595.9,
        "averagePreparationTime": null,
        "color": "#4088f7",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 8185,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "dada6c7d-9957-4fad-994a-d2753cacf568",
        "name": "Chips - 6863",
        "otherName": "سلطة",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 5683,
        "averagePreparationTime": null,
        "color": "#835199",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 8412,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "92b0bb8f-82de-4c66-a886-083e868de204",
        "name": "Car - 69464",
        "otherName": "دراجة هوائية",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 8401.3,
        "averagePreparationTime": null,
        "color": "#274705",
        "image": null,
        "hasModifiers": null,
        "stockType": "incrementalStock",
        "defaultStock": 77146,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "1c2b58b5-cd0f-453a-b481-44c065fba240",
        "name": "Computer - 91220",
        "otherName": "سجق",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 642.6,
        "averagePreparationTime": null,
        "color": "#83fa01",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 10857,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "d1423afd-090e-4d9b-a89a-c300d485f14b",
        "name": "Bacon - 55084",
        "otherName": "قميص",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 7098.6,
        "averagePreparationTime": null,
        "color": "#981748",
        "image": null,
        "hasModifiers": null,
        "stockType": "dailyStock",
        "defaultStock": 94674,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "51ac321f-83df-42ee-88e9-a415734e9cf9",
        "name": "Towels - 90221",
        "otherName": "كرة",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 4630,
        "averagePreparationTime": null,
        "color": "#288685",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 64729,
        "isByKilo": false,
        "modifierGroups": []
      },
      {
        "id": "a356dd4f-93b8-4910-be38-03284522b6cb",
        "name": "Hat - 6413",
        "otherName": "صابون",
        "barcode": null,
        "categoryId": "c9827450-b739-4710-be6c-909d91137846",
        "printerId": null,
        "price": 3844.2,
        "averagePreparationTime": null,
        "color": "#a89d63",
        "image": null,
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": 10984,
        "isByKilo": false,
        "modifierGroups": []
      }
    ],
    "children": [
      {
        "id": "5c3eb11c-84ca-47ff-8519-40f55830654b",
        "name": "Mushroom",
        "otherName": "في الهواء الطلق",
        "items": [
          {
            "id": "4d87a607-87e2-4173-8f30-c924375521e1",
            "name": "Car - 14733",
            "otherName": "تونة",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 8223.7,
            "averagePreparationTime": null,
            "color": "#7a02b",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 86126,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "2f69cccb-e01e-4e10-b83a-c415c0796e5e",
            "name": "Keyboard - 37547",
            "otherName": "سمك",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 3938.8,
            "averagePreparationTime": null,
            "color": "#2945c0",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 75782,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "2694537f-7b5f-4a1d-908d-5e0cd9ccc981",
            "name": "Pizza - 2231",
            "otherName": "دجاج",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 9448.8,
            "averagePreparationTime": null,
            "color": "#d344ab",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 27912,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "e14d9248-efad-481f-b790-7e5d2faaa71e",
            "name": "Bacon - 79639",
            "otherName": "قفازات",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 9295.4,
            "averagePreparationTime": null,
            "color": "#b8f3e7",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 88933,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "c5b7abf2-7cfb-4cad-a68d-6878ff87805d",
            "name": "Mouse - 98794",
            "otherName": "حاسوب",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 2644.9,
            "averagePreparationTime": null,
            "color": "#1f6a7b",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 10351,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "a563c6a1-8aa4-4245-a679-eb2280de49ed",
            "name": "Car - 9062",
            "otherName": "بيتزا",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 1106.7,
            "averagePreparationTime": null,
            "color": "#2806a1",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 11023,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "0a3f91d9-77ff-4028-8ccc-f8b10ed012b6",
            "name": "Shirt - 51897",
            "otherName": "طاولة",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 5464.8,
            "averagePreparationTime": null,
            "color": "#106549",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 89458,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "871094f7-a4cf-4c46-8791-bd100454beee",
            "name": "Mouse - 58783",
            "otherName": "تونة",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 8244.5,
            "averagePreparationTime": null,
            "color": "#95fdaa",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": 43404,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "83908d00-7daf-4525-bff8-46769bb6b5c8",
            "name": "Keyboard - 59404",
            "otherName": "دراجة هوائية",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 368.8,
            "averagePreparationTime": null,
            "color": "#955971",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 84256,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "cd3305af-d91e-4243-a738-b20bcdcf733f",
            "name": "Keyboard - 17204",
            "otherName": "كرسي",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 6494.4,
            "averagePreparationTime": null,
            "color": "#d10223",
            "image": null,
            "hasModifiers": null,
            "stockType": "dailyStock",
            "defaultStock": 94477,
            "isByKilo": false,
            "modifierGroups": []
          },
          {
            "id": "02decd8d-938f-427a-8f24-f3e378aeced1",
            "name": "Shirt - 40221",
            "otherName": "أحذية",
            "barcode": null,
            "categoryId": "5c3eb11c-84ca-47ff-8519-40f55830654b",
            "printerId": null,
            "price": 4609.2,
            "averagePreparationTime": null,
            "color": "#da7390",
            "image": null,
            "hasModifiers": null,
            "stockType": "incrementalStock",
            "defaultStock": 57310,
            "isByKilo": false,
            "modifierGroups": []
          }
        ],
        "children": []
      },
    ]
  },
  {
    "id": "ec276309-b5aa-4456-ab83-8c95dd0adac3",
    "name": "Pastas",
    "otherName": "",
    "items": [
      {
        "id": "92d10d2d-6eb7-4a04-bd49-da6718e04440",
        "name": "Abdulrahman Salim",
        "otherName": "",
        "barcode": "",
        "categoryId": "ec276309-b5aa-4456-ab83-8c95dd0adac3",
        "printerId": null,
        "price": 0,
        "averagePreparationTime": null,
        "color": "#99f3bd",
        "image": "",
        "hasModifiers": null,
        "stockType": "noStock",
        "defaultStock": null,
        "isByKilo": false,
        "modifierGroups": []
      }
    ],
    "children": [
      {
        "id": "2670db1e-9114-4b27-9f60-a47a575c05b5",
        "name": "Negresko",
        "otherName": "",
        "items": [
          {
            "id": "3d3d38e1-385e-49f2-a101-cf337a35e87e",
            "name": "Abdulrahman Salim1",
            "otherName": "",
            "barcode": "",
            "categoryId": "2670db1e-9114-4b27-9f60-a47a575c05b5",
            "printerId": null,
            "price": 0,
            "averagePreparationTime": null,
            "color": "#99f3bd",
            "image": null,
            "hasModifiers": null,
            "stockType": "noStock",
            "defaultStock": null,
            "isByKilo": false,
            "modifierGroups": []
          }
        ],
        "children": [
          {
            "id": "5c6beb4a-7e5f-443d-9503-3b4e6210563b",
            "name": "White Sauce",
            "otherName": "",
            "items": [
              {
                "id": "419803e3-e980-4734-abcf-b36ff8df4f86",
                "name": "Abdulrahman Salim12",
                "otherName": "",
                "barcode": "",
                "categoryId": "5c6beb4a-7e5f-443d-9503-3b4e6210563b",
                "printerId": null,
                "price": 0,
                "averagePreparationTime": null,
                "color": "#99f3bd",
                "image": null,
                "hasModifiers": null,
                "stockType": "noStock",
                "defaultStock": null,
                "isByKilo": false,
                "modifierGroups": []
              }
            ],
            "children": []
          }
        ]
      }
    ]
  }
]
export default items;
