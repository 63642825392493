// React
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { unstable_batchedUpdates } from "react-dom";

// Material
import { Box, Typography, CircularProgress, useTheme } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories
import { structure } from "Data/DesignStructure";

// Screens

// Assets
import { OnionLogoWithText } from "Assets/Images";

// Third Part5es
import { useSnackbar } from "notistack";

// Services
import { useGetUserMe } from "Services/User";
import { useStorageGetAll } from "Services/Storage";
import { useGetAllRoles } from "Services/Roles/";
import useGetAllTaxes from "Services/Taxes/useGetAllTaxes";
import useGetAllSurcharges from "Services/SurCharges/useGetAllSurCharges";
import useGetAllZones from "Services/Zones/useGetAllZones";
import useGetAllCities from "Services/Cities/useGetAllCities";
import useGetAllPrinters from "Services/Printers/useGetAllPrinters";
import useGetAllEmployees from "Services/Employees/useGetAllEmployees";
import useGetAllCountries from "Services/Countries/useGetAllCountries";
import useGetAllTimezones from "Services/Timezones/useGetAllTimezones";
import useGetAllDiscounts from "Services/Discounts/useGetAllDiscounts";
import useGetAllCurrencies from "Services/Currencies/useGetAllCurrencies";
import { useGetAllPermissions } from "Services/Permissions/";
import useGetAllSalesSectionTypes from "Services/Type/useGetAllSalesSectionTypes";
import useGetAllSalesSections from "Services/SalesChannels/useGetAllSalesSections";
import { useGetSubscriptionsPlans } from "Services/Subscription/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name Initializer
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Initializer = ({ children }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [brand, setBrand] = useGlobalState("userData.brand");
  const [currentComponent, setCurrentComponent] = useGlobalState("components.currentComponent");

  const [branch, setBranch] = useGlobalState("userData.branch");
  const [userAuth, setUserAuth] = useGlobalState("userData.auth");
  const [appState, setAppState] = useGlobalState("global.app");
  const [serverStorageState, setServerStorageState] = useGlobalState("userData.serverStorage");
  const [accountState, setAccountState] = useGlobalState("userData.account");
  const [locale, setLocale] = useGlobalState("global.locale");

  const [permissions, setPermissions] = useGlobalState("userData.permissions");
  const [dataToShowInPortal, setDataToShowInPortal] = useGlobalState("userData.dataToShowInPortal");

  /** Shared Data States */
  const [taxes, setTaxes] = useGlobalState("taxes.taxes");
  const [surcharges, setSurcharges] = useGlobalState("surcharges.surcharges");
  const [zones, setZones] = useGlobalState("zones.zones");
  const [cities, setCities] = useGlobalState("cities.cities");
  const [printers, setPrinters] = useGlobalState("printers.printers");
  const [employees, setEmployees] = useGlobalState("employees.employees");
  const [roles, setRoles] = useGlobalState("roles.roles");
  const [countries, setCountries] = useGlobalState("countries.countries");
  const [timezones, setTimezones] = useGlobalState("timezones.timezones");
  const [discounts, setDiscounts] = useGlobalState("discounts.discounts");
  const [currencies, setCurrencies] = useGlobalState("currencies.currencies");
  const [permissionsGroups, setPermissionsGroups] = useGlobalState(
    "permissionsGroups.permissionsGroups"
  );
  const [salesSections, setSalesSections] = useGlobalState("salesSections.salesSections");
  const [salesSectionsTypes, setSalesSectionsTypes] = useGlobalState(
    "salesSectionsTypes.salesSectionsTypes"
  );
  const [subscriptionsPlans, setSubscriptionsPlans] = useGlobalState(
    "subscriptionsPlans.subscriptionsPlans"
  );

  // State Hooks
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [disconnected, setDisconnected] = useState(false);
  const [loadedPermissions, setLoadedPermissions] = useState(false);

  //   const [initializerMounted, setInitializerMounted] = useState(false);
  const urlParts = history.location.pathname.split("/");
  const emailConfirmation = urlParts[urlParts.length - 2];
  const requestedUrl = "/" + urlParts.slice(1, urlParts.length - 1).join("/");

  console.log("22222rerendered");

  // Effect Hooks
  useEffect(() => {
    /** Load User Auth Data From Local Storage */
    let userStoredAuth = {};
    let storedBranch = {};
    let storedBrand = {};
    let storedLocale = "";
    try {
      userStoredAuth = JSON.parse(localStorage.getItem("userAuth")) || {};
      storedBranch = JSON.parse(localStorage.getItem("branch")) || {};
      storedBrand = JSON.parse(localStorage.getItem("brand")) || {};
      storedLocale = JSON.parse(localStorage.getItem("locale")) || "en";
    } catch (e) {}
    Object.keys(userAuth).forEach((key, i) => {
      userStoredAuth[key] = userStoredAuth[key] || userAuth[key];
    });

    storedBranch = storedBranch.id ? storedBranch : branch;
    storedBrand = storedBrand.id ? storedBrand : brand;

    setBranch((br) => ({
      ...br,
      ...storedBranch,
    }));

    setBrand((br) => ({
      ...br,
      ...storedBrand,
    }));

    setLocale(storedLocale);

    setUserAuth((ua) => ({
      ...ua,
      ...userStoredAuth,
      loaded: true,
    }));
  }, []);

  const loadUserData = () => {
    userMe()
      .then(({ serverStorage, account }) => {
        let newServerStorage = {};
        Object.keys(serverStorageState).forEach((key, i) => {
          newServerStorage[key] =
            (serverStorage.find((serverStorageObj) => serverStorageObj.key === key) || {})[
              "value"
            ] || serverStorageState[key];
        });

        let newAccount = {};
        Object.keys(accountState).forEach((key, i) => {
          newAccount[key] = account[key] || accountState[key];
        });

        const foundBranch = account.brands[0]
          ? account.brands[0].branches.find((br) => br.id === branch.id)
          : undefined;

        let newBranch;
        if (branch.id && foundBranch) newBranch = foundBranch;
        else if (!newAccount.brands.length) newBranch = {};
        else if (newAccount.isAdmin) newBranch = newAccount.brands[0].branches[0];
        else newBranch = newAccount.branchRoles[0].branch;

        setBranch({ ...newBranch });

        loadPermissions(newAccount, newBranch);

        setServerStorageState((sss) => ({
          ...sss,
          ...newServerStorage,
        }));
        setAccountState((as) => ({
          ...as,
          ...newAccount,
        }));

        const wizard = newServerStorage.wizard;

        if (account.isOwner && wizard !== "portal") history.replace("/wizard");
        else {
          const currentWindow = window.location.pathname;

          if (currentWindow === "/auth" || currentWindow === "/" || currentWindow === "/wizard")
            history.replace("/portal");
          else history.replace(currentWindow);
        }

        setAppState((as) => ({
          ...as,
          loaded: true,
        }));
        setDisconnected(false);
      })
      .catch((errors) => {
        /** @TODO Redirect To Auth */
        console.log("errors", errors);
        errors.forEach((e) => {
          enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
            variant: "error",
            autoHideDuration: 3000,
          });
          if (e.languageKey === "errors.authentication") {
            handleLogout();
            history.go(0);
          }
        });
        //history.replace("/auth")

        setAppState((as) => ({
          ...as,
          loaded: false,
        }));
        setDisconnected(true);
      });
  };

  const loadPermissions = (newAccount, newBranch) => {
    let keys, currentRole, dataToShow;
    if (newAccount.isAdmin) {
      setPermissions({ isOwner: true, isAdmin: true });
      setDataToShowInPortal(Object.keys(structure));
    } else {
      currentRole = newAccount.branchRoles.find((br) => br.branchId === newBranch.id).role;

      if (currentRole.isAdmin) {
        setPermissions({ isOwner: false, isAdmin: true });
        const tabsForOnlyOwners = ["branch", "account", "role", "onlineOrdersSetup"];
        setDataToShowInPortal(
          Object.keys(structure).filter((el) => !tabsForOnlyOwners.includes(el))
        );
      } else {
        const jsonPermissions = JSON.parse(currentRole.permissionsMatrix);
        keys = Object.keys(jsonPermissions);
        setPermissions({ ...jsonPermissions });

        if (keys)
          dataToShow = Object.entries(structure)
            .map(([parent, children]) => {
              for (let index = 0; index < keys.length; index++)
                if (keys[index] in children) return parent;
            })
            .filter((el) => el !== undefined);

        setDataToShowInPortal(dataToShow);
      }
    }
    setLoadedPermissions(true);
  };

  const handleLogout = () => {
    setBranch({});
    setUserAuth({
      loaded: false,
      token: null,
    });
    setAccountState({});
    localStorage.clear();
  };

  useEffect(() => {
    if (userAuth.loaded) {
      if (emailConfirmation === "emailConfirmation") {
        handleLogout();
        setCurrentComponent("MailConfirmation");
        setAppState((as) => ({
          ...as,
          loaded: true,
        }));
      } else {
        if (userAuth.token) {
          loadUserData();
        } else {
          history.replace("/auth");
          setAppState((as) => ({
            ...as,
            loaded: true,
          }));
        }
      }
    }
  }, [userAuth]);

  useEffect(() => {
    if (loadedPermissions) {
      const d = {
        tax: [getAllTaxes, setTaxes],
        surcharge: [getAllSurcharges, setSurcharges],
        zone: [getAllZones, setZones],
        role: [getAllRoles, setRoles],
        city: [getAllCities, setCities],
        printer: [getAllPrinters, setPrinters],
        employee: [getAllEmployees, setEmployees],
        orderDiscount: [getAllDiscounts, setDiscounts],
        permissionGroup: [getAllPermissions, setPermissionsGroups],
        salesSection: [getAllSalesSections, setSalesSections],
      };
      const isFetchingRows = false;
      unstable_batchedUpdates(() => {
        Object.entries(d).forEach(async ([key, value]) => {
          if (permissions.isOwner || permissions.isAdmin || permissions[key]) {
            value[1]({
              data: await value[0](),
              isFetchingRows,
            });
          }
        });
      });

      const fetchCommonData = async () => {
        const countries = await getAllCountries();
        const timezones = await getAllTimezones();
        const currencies = await getAllCurrencies();
        const salesSectionsTypes = await getAllSalesSectionsTypes();
        const subscriptionsPlans = await getSubscriptionsPlans();
        unstable_batchedUpdates(() => {
          setCountries({ data: countries, isFetchingRows });
          setTimezones({ data: timezones, isFetchingRows });
          setCurrencies({ data: currencies, isFetchingRows });
          setSalesSectionsTypes({ data: salesSectionsTypes, isFetchingRows });
          setSubscriptionsPlans({ data: subscriptionsPlans, isFetchingRows });
        });
      };
      fetchCommonData();
    }
  }, [loadedPermissions, permissions]);

  // Other Hooks
  const userMe = useGetUserMe({
    languageKey: "user",
  });

  const storageGetAll = useStorageGetAll({
    languageKey: "storage",
  });

  /** Gets */
  const getAllRoles = useGetAllRoles();
  const getAllZones = useGetAllZones();
  const getAllTaxes = useGetAllTaxes();
  const getAllSurcharges = useGetAllSurcharges();
  const getAllCities = useGetAllCities();
  const getAllPrinters = useGetAllPrinters();
  const getAllEmployees = useGetAllEmployees();
  const getAllCountries = useGetAllCountries();
  const getAllTimezones = useGetAllTimezones();
  const getAllDiscounts = useGetAllDiscounts();
  const getAllCurrencies = useGetAllCurrencies();
  const getAllPermissions = useGetAllPermissions();
  const getAllSalesSections = useGetAllSalesSections();
  const getAllSalesSectionsTypes = useGetAllSalesSectionTypes();
  const getSubscriptionsPlans = useGetSubscriptionsPlans({
    languageKey: "subscription",
  });
  /** */
  // Event Handlers

  // Other

  // Component Render
  return !appState.loaded ? (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          flex: 1,
          padding: theme.spacing(2),
        }}
      >
        <img
          src={OnionLogoWithText}
          style={{
            width: "50%",
          }}
          alt="Onion Logo"
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          flex: 1,
          padding: theme.spacing(2),
        }}
      >
        {disconnected ? (
          <Typography>
            <LocalizedMessage id="errors.connection.error" />
          </Typography>
        ) : (
          <Typography component={"span"}>
            <CircularProgress size={16} /> Loading App...{" "}
          </Typography>
        )}
      </Box>
    </Box>
  ) : (
    children
  );
};

Initializer.propTypes = {
  /**
   *
   */
};

Initializer.defaultProps = {
  /**
   *
   */
};

export default Initializer;
