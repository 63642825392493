// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Toolbar,
  useTheme,
  Button,
  CircularProgress,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Tooltip,
  IconButton,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import StorageIcon from "@material-ui/icons/Storage";
import SearchIcon from "@material-ui/icons/Search";
import DescriptionIcon from "@material-ui/icons/Description";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import useInterval from "Helpers/useInterval";
import { useForm } from "Helpers/Hooks";
import { getReportDate, getDateDurations } from "Utils";
import {
  displaySnackbarFormErrors,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { DatePicker, SelectField, TextField } from "Components/FormFields";
import App from "../Reports/PDFViewer/App";
import Memo from "Components/Memo";
import Title from "Components/Title";
import OrderDetailsDialog from "./OrderDetailsDialog";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import moment from "moment";

// Services
import {
  useGetSales,
  useGetSalesPdf,
  useGetOrderDaySales,
} from "Services/Sales/";
import { useGetAllSalesSectionsDropdowns } from "Services/Dropdowns";
import { useGetAllOrderStatuses } from "Services/OrderStatuses";
import { useGetOrderDays } from "Services/Dashboard/";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 660,
    scroll: "auto",
  },
}));

// Ad-Hoc Components

const columns = [
  {
    id: "orderNumber",
    label: <LocalizedMessage id="common.orderNumber" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "orderStatus",
    label: <LocalizedMessage id="sales.orderStatus" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "salesSection",
    label: <LocalizedMessage id="sales.salesSection" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "netSales",
    label: <LocalizedMessage id="sales.netSales" />,
    minWidth: 100,
    format: false,
  },

  {
    id: "orderDay",
    label: <LocalizedMessage id="sales.orderDay" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "createdAt",
    label: <LocalizedMessage id="common.createdAt" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "cashier",
    label: <LocalizedMessage id="sales.cashier" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "closedAt",
    label: <LocalizedMessage id="sales.closedAt" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "closedBy",
    label: <LocalizedMessage id="sales.closedBy" />,
    minWidth: 100,
    format: false,
  },
  { id: "actions", label: "", minWidth: 200 },
];

/**
 * @name Sales
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Sales = ({ live }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");
  // State Hooks
  const [salesSections, setSalesSections] = useState([]);
  const [isFetchingSalesSections, setIsFetchingSalesSections] = useState(true);
  const [orderStatuses, setOrderStatuses] = useState([]);
  const [isFetchingOrderStatuses, setIsFetchingOrderStatuses] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useStateRef([]);
  const [footerRow, setFooterRow] = useStateRef([]);
  const [openSearchField, setOpenSearchField] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [orderDay, setOrderDay] = useState("");

  const [isFetchingRows, setIsFetchingRows] = useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);
  const [pdf, setPdf] = useState("");
  const [PDFViewerOpen, setPDFViewerOpen] = useState(false);
  const [hasPdfAccess, setHasPdfAccess] = useState(
    permissions.isAdmin ||
      permissions.live.includes("get") ||
      permissions.sales.includes("get")
  );
  const [hasTableAccess, setHasTableAccess] = useState(
    permissions.isAdmin ||
      permissions.live.includes("pdf") ||
      permissions.sales.includes("pdf")
  );

  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  // Effect Hooks
  useEffect(() => {
    if (!live)
      setHeading(
        <Box display="flex" alignItems="center">
          <ShowChartIcon
            style={{ margin: "0px 16px", width: 20, height: 20 }}
          />
          <LocalizedMessage id="sales.pageTitle" key="sales.pageTitle" />
        </Box>
      );
  }, []);

  useEffect(() => {
    if (!openOrderDetailsDialog) setSelectedOrder({});
  }, [openOrderDetailsDialog]);

  useEffect(() => {
    getAllSalesSections()
      .then((data) => {
        setSalesSections(data);
        setIsFetchingSalesSections(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingSalesSections(false);
      });
  }, []);

  useEffect(() => {
    getAllOrderStatuses()
      .then((data) => {
        setOrderStatuses(data);
        setIsFetchingOrderStatuses(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingOrderStatuses(false);
      });
  }, []);

  useEffect(() => {
    getOrderDay()
      .then((response) => {
        if (!response) setOrderDay(null);
        else if (response.value) setOrderDay(response.value);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setOrderDay(null);
      });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const getCustomSales = useGetSales();
  const getOrderDay = useGetOrderDays();
  const getCustomSalesPdf = useGetSalesPdf();
  const getOrderDaySales = useGetOrderDaySales();
  let getSales = live ? getOrderDaySales : getCustomSales;
  let getSalesPdf = getCustomSalesPdf;
  const getAllSalesSections = useGetAllSalesSectionsDropdowns();
  const getAllOrderStatuses = useGetAllOrderStatuses();
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Joi.object({
    dateDuration: Joi.string().valid(
      "orderDay",
      "today",
      "yesterday",
      "thisWeek",
      "lastWeek",
      "thisMonth",
      "lastMonth",
      "thisYear",
      "lastYear",
      "custom"
    ),
    dateFrom: Joi.date(),
    dateTo: Joi.date().min(Joi.ref("dateFrom")),
    salesSections: Joi.array().items(Joi.string().uuid()),
    orderStatuses: Joi.array().items(Joi.string()),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "report" });

  useEffect(() => {
    const { from, to } = getReportDate("today", "", "");

    setFormData({
      dateDuration: "today",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  useEffect(() => {
    if (formData.dateDuration === "today" || live) handleSearch();
  }, [formData.dateDuration]);

  useEffect(() => {
    if (formData.dateDuration) handleSearch();
  }, [PDFViewerOpen]);

  useEffect(() => {
    if (permissions.isAdmin) {
      setHasPdfAccess(true);
      setHasTableAccess(true);
    } else {
      if (live) {
        if (permissions.live.includes("pdf")) setHasPdfAccess(true);
        if (permissions.live.includes("get")) setHasTableAccess(true);
      } else {
        if (permissions.sales.includes("pdf")) setHasPdfAccess(true);
        if (permissions.sales.includes("get")) setHasTableAccess(true);
      }
    }
  }, []);

  useInterval(
    () => {
      if (live) handleSearch();
    },
    30000,
    15 * 60000
  );

  // Event Handlers
  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    const validForm = validateForm();
    if (validForm) {
      setIsFetchingRows(true);
      setIsFetchingPdf(true);
      setPdf("");
      setRows([]);

      let { dateDuration, dateFrom, dateTo, salesSections, orderStatuses } =
        formData;

      const { from, to } =
        dateDuration === "orderDay"
          ? {
              from: moment(orderDay).format("YYYY-MM-DD HH:mm:ss"),
              to: moment(orderDay).format("YYYY-MM-DD HH:mm:ss"),
            }
          : getReportDate(dateDuration, dateFrom, dateTo);
      setFormData({ ...formData, dateFrom: from, dateTo: to });

      if (hasTableAccess && !PDFViewerOpen) {
        getSales({
          dateFrom: from,
          dateTo: to,
          salesSections,
          orderStatuses,
        })
          .then((response) => {
            live
              ? setRows(response.map((order) => createData(order)))
              : setRows(response.data.map((order) => createData(order)));
            setFooterRow(response.footer);
            setIsFetchingRows(false);
            setPage(0);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      } else {
        if (!live) setPDFViewerOpen(true);
        setIsFetchingRows(false);
      }

      if (hasPdfAccess && PDFViewerOpen && !live)
        getSalesPdf({
          dateFrom: from,
          dateTo: to,
          salesSections,
          orderStatuses,
        })
          .then((response) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            setPdf(objectUrl);
            setIsFetchingPdf(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else setIsFetchingPdf(false);
    }
  };

  /** @TODO add orderDetails to permissions */
  const onViewOrderDetailsClick = (order) => {
    setSelectedOrder(order);
    setOpenOrderDetailsDialog(true);
  };

  // Other
  function createData(order) {
    return {
      orderId: order.orderId,
      orderNumber: order.orderNumber,
      orderStatus:
        order.orderStatus === "Open" ? (
          <Chip
            label={order.orderStatus}
            style={{ backgroundColor: "#00c853", color: "white" }}
          />
        ) : order.orderStatus === "Closed" ? (
          <Chip
            label={order.orderStatus}
            style={{ backgroundColor: "silver", color: "white" }}
          />
        ) : order.orderStatus === "Merged" ? (
          <Chip
            label={order.orderStatus}
            style={{ backgroundColor: "#fbc02d", color: "white" }}
          />
        ) : order.orderStatus === "Void" ? (
          <Chip
            label={order.orderStatus}
            style={{ backgroundColor: "#c62828", color: "white" }}
          />
        ) : order.orderStatus === "Returned" ? (
          <Chip
            label={order.orderStatus}
            style={{ backgroundColor: "#f361b5", color: "white" }}
          />
        ) : (
          <Chip label={order.orderStatus} color="primary" />
        ),
      salesSection: order.salesSection,
      netSales: order.netSales,
      orderDay: order.orderDay,
      createdAt: order.createdAt,
      cashier: order.cashier,
      closedAt: order.closedAt,
      closedBy: order.closedBy,
      actions: (
        <Box>
          <Tooltip title={<LocalizedMessage id="sales.showOrderDetails" />}>
            <Button
              style={{ flex: 1 }}
              onClick={() => onViewOrderDetailsClick(order)}
              variant="outlined"
              color="secondary"
            >
              <DescriptionIcon style={{ margin: 8 }} />
              <LocalizedMessage id="sales.showOrderDetails" />
            </Button>
          </Tooltip>
        </Box>
      ),
    };
  }

  let dateDurations = getDateDurations(intl);
  dateDurations.splice(1, 0, {
    text: intl.formatMessage({ id: "report.dateDuration.orderDay" }),
    value: "orderDay",
  });

  const salesSectionsFiltersTags = [];
  salesSections.forEach((ss) => {
    if (formData.salesSections)
      formData.salesSections.forEach((salesSectionId) => {
        if (ss.id === salesSectionId) salesSectionsFiltersTags.push(ss.name);
      });
  });

  const orderStatusesFiltersTags = [];
  orderStatuses.forEach((ss) => {
    if (formData.orderStatuses)
      formData.orderStatuses.forEach((orderStatusId) => {
        if (ss.id === orderStatusId) orderStatusesFiltersTags.push(ss.id);
      });
  });

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="sales.pageTitle" key="sales.pageTitle" />
        }
      />
      <Paper
        dir={theme.direction}
        style={{
          padding: "4px 24px 26px 24px",
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            // paddingBottom: Theme.spacing(10),
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              <LocalizedMessage id="sales.pageTitle" />
            </Typography>
          </Box>
          <Divider style={{ margin: "4px 0px 28px" }} />

          <Box
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="h6"
                  className={classes.heading}
                  style={{ fontSize: "1rem" }}
                >
                  <LocalizedMessage id="general.filters" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box display="flex" flexDirection={downSm ? "column" : "row"}>
                  {!live && (
                    <Box
                      style={{
                        flex: "1 1",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <SelectField
                        fullWidth
                        neglectNone
                        name="dateDuration"
                        tooltip={"Date Duration"}
                        helpKey="Menu.category.dateDuration"
                        label={
                          <LocalizedMessage id="report.dateDuration.title" />
                        }
                        onChange={onFormFieldChange}
                        value={formData.dateDuration}
                        errors={formErrors.dateDuration}
                        isSubmitting={isFetchingRows || isFetchingPdf}
                        isLoading={isLoading.dateDuration}
                        options={dateDurations}
                        defaultValue={"today"}
                      />
                      {formData.dateDuration === "custom" && (
                        <>
                          <DatePicker
                            name="dateFrom"
                            tooltip={"Date from"}
                            helpKey="common.dateFrom"
                            label={intl.formatMessage({
                              id: "common.dateFrom",
                            })}
                            onChange={onFormFieldChange}
                            value={formData.dateFrom}
                            errors={formErrors.dateFrom}
                            isSubmitting={isFetchingRows || isFetchingPdf}
                            isLoading={isLoading.dateFrom}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="dense"
                          />
                          <DatePicker
                            name="dateTo"
                            tooltip={"Date from"}
                            helpKey="common.dateTo"
                            label={intl.formatMessage({ id: "common.dateTo" })}
                            onChange={onFormFieldChange}
                            value={formData.dateTo}
                            errors={formErrors.dateTo}
                            isSubmitting={isFetchingRows || isFetchingPdf}
                            isLoading={isLoading.dateTo}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            margin="dense"
                          />
                        </>
                      )}
                    </Box>
                  )}
                  <Box
                    style={{
                      flex: 1,
                      padding: downSm ? 0 : theme.spacing(0, 2),
                      minWidth: 0,
                    }}
                  >
                    <SelectField
                      fullWidth
                      name="salesSections"
                      tooltip={"Sales Channels"}
                      helpKey="common.salesSections"
                      label={
                        <LocalizedMessage id="setup.salesChannel.pageTitle" />
                      }
                      onChange={onFormFieldChange}
                      value={formData.salesSections}
                      errors={formErrors.salesSections}
                      isSubmitting={isFetchingRows || isFetchingPdf}
                      isLoading={isLoading.salesSections}
                      options={salesSections.map((s) => ({
                        value: s.id,
                        text: s.name,
                      }))}
                      multiple
                    />

                    <SelectField
                      fullWidth
                      name="orderStatuses"
                      tooltip={"Order Status"}
                      helpKey="Menu.category.orderStatus"
                      label={<LocalizedMessage id="sales.orderStatus" />}
                      onChange={onFormFieldChange}
                      value={formData.orderStatuses}
                      errors={formErrors.orderStatuses}
                      isSubmitting={isFetchingRows || isFetchingPdf}
                      isLoading={isLoading.orderStatuses}
                      options={orderStatuses.map((s) => ({
                        value: s.id,
                        text: s.id,
                      }))}
                      multiple
                    />
                  </Box>
                </Box>

                <Box display="flex" justifyContent="flex-end">
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    disabled={isFetchingRows || isFetchingPdf}
                    onClick={handleSearch}
                    style={{ margin: "16px" }}
                    startIcon={
                      !isFetchingRows && !isFetchingPdf && <DoneIcon />
                    }
                  >
                    {isFetchingRows || isFetchingPdf ? (
                      <CircularProgress size={20} />
                    ) : (
                      <LocalizedMessage id="general.apply" />
                    )}
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Memo deps={[pdf, rows.getState()]}>
              {() => (
                <Toolbar
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexWrap: "wrap",
                    marginTop: 16,
                    overflow: "auto",
                  }}
                >
                  {(formData.dateDuration || live) && (
                    <Chip
                      style={{
                        margin: 8,
                        padding: 8,
                        height: "100%",
                        color: "white",
                        backgroundColor: "#0582D2",
                      }}
                      label={
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "normal" }}
                        >
                          <LocalizedMessage id="report.dateDuration.title" />{" "}
                          {": "}
                          {live
                            ? intl.formatMessage({
                                id: "report.dateDuration.orderDay",
                              })
                            : dateDurations.filter(
                                (dd) => formData.dateDuration === dd.value
                              )[0]?.text}
                        </Typography>
                      }
                    />
                  )}
                  {formData.dateTo && (
                    <Chip
                      style={{
                        margin: 8,
                        padding: 8,
                        height: "100%",
                        color: "white",
                        backgroundColor: "#0582D2",
                      }}
                      label={
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "normal" }}
                        >
                          {formData.dateTo === formData.dateFrom ? (
                            <>
                              <LocalizedMessage id="report.day" />
                              {": " +
                                moment(formData.dateFrom).format(
                                  "MMM DD, YYYY"
                                )}
                            </>
                          ) : (
                            <>
                              <LocalizedMessage id="common.dateFrom" />
                              {": " +
                                moment(formData.dateFrom).format(
                                  "MMM DD, YYYY"
                                )}
                              <LocalizedMessage id="common.dateTo" />
                              {": " +
                                moment(formData.dateTo).format("MMM DD, YYYY")}
                            </>
                          )}
                        </Typography>
                      }
                    />
                  )}

                  {formData.orderDay ? (
                    <Chip
                      style={{
                        margin: 8,
                        padding: 8,
                        height: "100%",
                        color: "white",
                        backgroundColor: "#0582D2",
                      }}
                      label={
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "normal" }}
                        >
                          <LocalizedMessage id="setup.salesChannel.pageTitle" />
                          {": " + formData.orderDay}
                        </Typography>
                      }
                    />
                  ) : null}

                  {salesSectionsFiltersTags.length ? (
                    <Chip
                      style={{
                        margin: 8,
                        padding: 8,
                        height: "100%",
                        color: "white",
                        backgroundColor: "#0582D2",
                      }}
                      label={
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "normal" }}
                        >
                          <LocalizedMessage id="setup.salesChannel.pageTitle" />
                          {": " + salesSectionsFiltersTags.join(", ")}
                        </Typography>
                      }
                    />
                  ) : null}

                  {orderStatusesFiltersTags.length ? (
                    <Chip
                      style={{
                        margin: 8,
                        padding: 8,
                        height: "100%",
                        color: "white",
                        backgroundColor: "#0582D2",
                      }}
                      label={
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "normal" }}
                        >
                          <LocalizedMessage id="sales.orderstatus" />
                          {": " + orderStatusesFiltersTags.join(", ")}
                        </Typography>
                      }
                    />
                  ) : null}
                </Toolbar>
              )}
            </Memo>
            {downSm && (
              <>
                {!openSearchField && downSm && (
                  <IconButton onClick={() => setOpenSearchField(true)}>
                    <SearchIcon color={"primary"} />
                  </IconButton>
                )}
                {(openSearchField || !downSm) && (
                  <TextField
                    label={intl.formatMessage({ id: "general.search" })}
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    variant="outlined"
                  />
                )}
              </>
            )}
            <Box
              display="flex"
              justifyContent={downSm ? "flex-end" : "space-between"}
            >
              {!downSm && (
                <>
                  {!openSearchField && downSm && (
                    <IconButton onClick={() => setOpenSearchField(true)}>
                      <SearchIcon color={"primary"} />
                    </IconButton>
                  )}
                  {(openSearchField || !downSm) && (
                    <TextField
                      label={intl.formatMessage({ id: "general.search" })}
                      size="small"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      variant="outlined"
                    />
                  )}
                </>
              )}
              {!live && hasPdfAccess && (
                <Box display="flex">
                  <Tooltip
                    title={
                      PDFViewerOpen ? (
                        <LocalizedMessage id="report.showTable" />
                      ) : (
                        <LocalizedMessage id="report.showPDFViewer" />
                      )
                    }
                  >
                    {downSm ? (
                      <Button
                        style={{ flex: 1 }}
                        component={
                          isFetchingRows || isFetchingPdf ? "div" : undefined
                        }
                        onClick={
                          isFetchingRows || isFetchingPdf
                            ? undefined
                            : () => setPDFViewerOpen((prevState) => !prevState)
                        }
                        disabled={isFetchingRows || isFetchingPdf}
                      >
                        {PDFViewerOpen ? (
                          <>
                            <StorageIcon style={{ marginRight: 8 }} />
                            <LocalizedMessage id="report.showTable" />
                          </>
                        ) : (
                          <>
                            <PictureAsPdfIcon style={{ marginRight: 8 }} />
                            <LocalizedMessage id="report.showPDFViewer" />
                          </>
                        )}
                      </Button>
                    ) : (
                      <IconButton
                        component={
                          isFetchingRows || isFetchingPdf ? "div" : undefined
                        }
                        onClick={
                          isFetchingRows || isFetchingPdf
                            ? undefined
                            : () => setPDFViewerOpen((prevState) => !prevState)
                        }
                        disabled={isFetchingRows || isFetchingPdf}
                      >
                        {PDFViewerOpen ? <StorageIcon /> : <PictureAsPdfIcon />}
                        <Typography variant="body2" component="h2"></Typography>
                      </IconButton>
                    )}
                  </Tooltip>
                  <Tooltip title={<LocalizedMessage id="report.downloadPDF" />}>
                    {downSm ? (
                      <Button
                        style={{ flex: 1 }}
                        component={
                          isFetchingRows || isFetchingPdf || !PDFViewerOpen
                            ? "div"
                            : undefined
                        }
                        onClick={
                          isFetchingRows || isFetchingPdf || !PDFViewerOpen
                            ? undefined
                            : () => window.open(pdf)
                        }
                        disabled={
                          isFetchingRows || isFetchingPdf || !PDFViewerOpen
                        }
                      >
                        <GetAppIcon style={{ marginRight: 8 }} />
                        <LocalizedMessage id="report.downloadPDF" />
                      </Button>
                    ) : (
                      <IconButton
                        component={
                          isFetchingRows || isFetchingPdf || !PDFViewerOpen
                            ? "div"
                            : undefined
                        }
                        onClick={
                          isFetchingRows || isFetchingPdf || !PDFViewerOpen
                            ? undefined
                            : () => window.open(pdf)
                        }
                        disabled={
                          isFetchingRows || isFetchingPdf || !PDFViewerOpen
                        }
                      >
                        {<GetAppIcon />}
                        <Typography variant="body2" component="h2"></Typography>
                      </IconButton>
                    )}
                  </Tooltip>
                </Box>
              )}
            </Box>
            {(isFetchingRows || isFetchingPdf) && inputSkeleton ? (
              inputSkeleton
            ) : PDFViewerOpen && pdf ? (
              <Box>
                <App downSm={downSm} url={pdf} />
              </Box>
            ) : (
              <Paper
                style={{
                  marginTop: theme.spacing(2),
                }}
              >
                <Box ref={inputRef}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      {rows.getState().length ? (
                        <TableBody>
                          {(searchQuery
                            ? rows
                                .getState()
                                .filter(
                                  (row) =>
                                    row.ordernumber
                                      .toLowerCase()
                                      .indexOf(searchQuery.toLowerCase()) !=
                                      -1 || searchQuery.toLowerCase() === ""
                                )
                            : rows.getState()
                          )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row, index) => (
                              <TableRow
                                hover
                                role="checkbox"
                                tabIndex={-1}
                                key={row.id}
                              >
                                <TableCell />
                                {columns.map((column, index) => (
                                  <TableCell
                                    key={column.id + "-second"}
                                    align={column.align}
                                  >
                                    {row[column.id] ? row[column.id] : ""}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={rows.getState().length + 1}
                          >
                            <TableCell />
                            {footerRow.getState()
                              ? footerRow.getState().map((row, index) => (
                                  <TableCell
                                    key={index + "-footer"}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    {row}
                                  </TableCell>
                                ))
                              : null}
                          </TableRow>
                        </TableBody>
                      ) : null}
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.getState().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Box>
              </Paper>
            )}
          </Box>
        </Box>
      </Paper>
      <OrderDetailsDialog
        open={openOrderDetailsDialog}
        setOpen={setOpenOrderDetailsDialog}
        orderId={selectedOrder.orderId}
        createdAt={selectedOrder.createdAt}
      />
    </Box>
  );
};

Sales.propTypes = {
  /**
   *
   */
};

Sales.defaultProps = {
  /**
   *
   */
};

export default Sales;
