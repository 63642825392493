// React
import React, { useState, useEffect } from "react";

// Material
import {
  Fab,
  useTheme,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { TextField } from "Components/FormFields";
import SelectField from "Components/SelectField";
import AlertDialog from "Components/AlertDialog";
import SortableBody from "Components/Sortable";

// Factories

// Screens
import ZoneForm from "./ZoneForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import arrayMove from "array-move";

// Services
import { useDeleteZone } from "Services/Zones/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "drag",
    label: <LocalizedMessage id="general.drag" />,
    minWidth: 20,
    align: "center",
  },
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 200,
  },
  {
    id: "minimumOrderValue",
    label: <LocalizedMessage id="setup.zone.minimumOrderValue" />,
    minWidth: 200,
  },
  {
    id: "deliveryTime",
    label: <LocalizedMessage id="setup.zone.deliveryTime" />,
    minWidth: 200,
  },
  {
    id: "cityName",
    label: <LocalizedMessage id="setup.zone.cityName" />,
    minWidth: 200,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name Zones
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Zones = ({ setHeading, permissions, refresh }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();
  // Global State Hooks
  const [zones, setZones] = useGlobalState("zones.zones");
  const [cities, setCities] = useGlobalState("cities.cities");
  const [branch, setBranch] = useGlobalState("userData.branch");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [currentCities, setCurrentCities] = React.useState([]);
  const [editId, setEditId] = React.useState("");

  /* Zone Form */
  const [zoneFormOpen, setZoneFormOpen] = useState(false);
  const [zoneFormMode, setZoneFormMode] = useState("");
  const [zoneFormData, setZoneFormData] = useState({});
  const [selectedCity, setSelectedCity] = useState({});

  // Effect Hooks
  useEffect(() => {
    setRows(zones.data.map((el) => createData(el)));
  }, [zones]);

  useEffect(() => {
    const newCities = cities.data.map((city) => {
      return { value: city.id, text: city.name };
    });
    setCurrentCities(newCities);
  }, [cities.data]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const deleteZone = useDeleteZone();

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddZoneForm = () => {
    setZoneFormData({});
    setZoneFormMode("add");
    setZoneFormOpen(true);
  };

  const onEditClick = (zone) => {
    setEditId(zone.id);
    setZoneFormData(zone);
    setZoneFormMode("edit");
    setZoneFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteZone(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setRows(rows.getState().filter((row) => row.id !== id));
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const addCallback = (response) => {
    setRows([...rows.getState(), createData(response.data.data)]);
  };

  const editCallback = (response) => {
    const newRows = rows.getState();
    const index = newRows.findIndex((row) => row.id === editId);
    newRows.splice(index, 1, createData(response.data.data));
    setRows(newRows);
  };

  // Other
  const createData = (zone) => {
    return {
      id: zone.id,
      name: zone.name,
      otherName: zone.otherName,
      minimumOrderValue: zone.minimumOrderValue + " " + branch.currencyId,
      deliveryTime: zone.deliveryTime + " min",
      city: zone.city,
      cityName: zone.city.name,

      actions: (
        <Box>
          {(permissions.isAdmin || permissions.zone.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(zone)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.zone.includes("delete")) && (
            <AlertDialog id={zone.id} onAgree={onDeleteClick} />
          )} */}
        </Box>
      ),
    };
  };

  const filterResult = selectedCity
    ? rows.getState().filter((row) => row.city.id === selectedCity)
    : rows.getState();

  const searchResult = searchQuery
    ? filterResult.filter(
        (row) =>
          row.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
          searchQuery.toLowerCase() === ""
      )
    : filterResult;

  const data = searchResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ flex: 1, margin: 8 }}>
          <LocalizedMessage id="setup.zone.pageTitle" />
        </Typography>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            flex: 3,
            minWidth: 0,
          }}
        >
          {cities.data.length ? (
            <Box flex={"1 0 50%"} minWidth={0}>
              <SelectField
                label={"City"}
                size={"small"}
                validator={() => []}
                style={{ margin: Theme.spacing(1) }}
                options={currentCities}
                value={selectedCity ? selectedCity : cities.data[0].value}
                onChange={(e) => setSelectedCity(e.target.value)}
              />
            </Box>
          ) : null}
          <Box minWidth={0}>
            {!openSearchField && downSm && (
              <IconButton onClick={() => setOpenSearchField(true)}>
                <SearchIcon color={"primary"} />
              </IconButton>
            )}
            {(openSearchField || !downSm) && (
              <TextField
                style={{
                  marginRight: Theme.spacing(1),
                  marginLeft: Theme.spacing(1),
                }}
                label={<LocalizedMessage id="general.search" />}
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </Box>
      {(permissions.isAdmin || permissions.zone.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={zones.isFetchingRows}
            component={zones.isFetchingRows ? "div" : undefined}
            onClick={zones.isFetchingRows ? undefined : openAddZoneForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        <ZoneForm
          open={zoneFormOpen}
          setOpen={setZoneFormOpen}
          mode={zoneFormMode}
          editFormData={zoneFormData}
          addCallback={addCallback}
          editCallback={editCallback}
          editId={editId}
          currentCities={currentCities}
          permissions={permissions}
        />
        {zones.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <SortableBody
                  data={data}
                  columns={columns}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    setRows((elements) => [...arrayMove(elements, oldIndex, newIndex)]);
                  }}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

Zones.propTypes = {
  /**
   *
   */
};

Zones.defaultProps = {
  /**
   *
   */
};

export default Zones;
