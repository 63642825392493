// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { handler } from "Helpers/Handlers";

// Components
import { TextField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import { useAddItemNote, useEditItemNote } from "Services/ItemNotes/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
});

/**
 * @name NoteForm
 * @summary
 * @Note
 * @component
 * @description
 * >
 */
const NoteForm = ({
  editFormData,
  editCallback,
  addCallback,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit")
      setFormData(_.pick(editFormData, ["name", "otherName"]));
    else setFormData({});
  }, [open]);

  // Other Hooks
  const addItemNote = useAddItemNote({ languageKey: "setup.itemNote" });
  const editItemNote = useEditItemNote({ languageKey: "setup.itemNote" });
  const { enqueueSnackbar } = useSnackbar();
  const itemNoteForm = useForm({ validationSchema, languageKey: "setup.note" });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = itemNoteForm;

  // Event Handlers
  const onCLoseNoteForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddNote = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: itemNoteForm,
      formName: "itemNote",
      apiHandler: addItemNote,
      onClose: onCLoseNoteForm,
    });
  };

  const onSaveNote = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: itemNoteForm,
      formName: "itemNote",
      apiHandler: editItemNote,
      onClose: onCLoseNoteForm,
    });
  };

  // Other
  console.log(
    `%cNoteForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseNoteForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="setup.itemNote.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Note Name"}
                helpKey="Menu.note.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                tooltip={"Note Other Name"}
                helpKey="Menu.note.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddNote}
          mode={mode}
          onCLoseForm={onCLoseNoteForm}
          isSubmitting={isSubmitting}
          onSave={onSaveNote}
        />
      </DialogActions>
    </Dialog>
  );
};

NoteForm.propTypes = {
  /**
   *
   */
};

NoteForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default NoteForm;
