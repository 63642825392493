// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { FormHelperText, Grid, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";
import payloads from "Helpers/Handlers/payloads";

// Components
import HelpIcon from "Components/HelpIcon";
import SelectField from "./SelectField";
import Box from "Components/Box";

// Factories

// Screens
import BranchForm from "Screens/Portal/Branches/BranchForm";
import RoleForm from "Screens/Portal/Roles/RoleForm";

// Assets

// Servicies
import { useAddRole } from "Services/Roles/";
import { useAddBranch } from "Services/Branches/";
import { useGetAllRolesDropdowns } from "Services/Dropdowns/";

// Help

// Third Parties
import { useSnackbar } from "notistack";

// Styles
const useStyles = makeStyles((theme) => ({}));

// Ad-Hoc Components

/**
 * @name BranchRoles
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const BranchRoles = ({
  className,
  style,
  tooltip,
  helpKey,
  variant,
  margin,
  autoComplete,
  helpIconProps,
  options,
  getOptionLabel,
  getOptionSelected,
  defaultValue,
  onChange,
  helperText,
  size,
  inputProps,
  isLoading,
  hidden,
  isSubmitting,
  disabled,
  setDefaultValue,
  label,
  id,
  required,
  name,
  value,
  multiple,
  validator,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  branches,
  mode,
  isAdmin,
  setBranches,
  refresh,
  formData,
  setFormData,
  permissions,
  ...otherProps
}) => {
  value =
    value === undefined || value === null || !value.length ? [{ branchId: "", roleId: "" }] : value;
  const [currentValue, setCurrentValue] = useState(value);

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [roles, setRoles] = useGlobalState("roles.roles");
  const [permissionsMatrix, setPermissionsMatrix] = useGlobalState(
    "rolesPermissionsMatrix.rolesPermissionsMatrix"
  );

  // State Hooks
  const [openRoleForm, setOpenRoleForm] = useState(false);
  const [BranchFormOpen, setBranchFormOpen] = useState(false);
  const [mappedBranches, setMappedBranches] = useState([]);

  // Effect Hooks

  useEffect(() => {
    if (!roles.isFetchingRows && !roles.data.length)
      getAllRolesDropdowns()
        .then((res) => {
          setRoles({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [roles.isFetchingRows]);

  useEffect(() => {
    if (branches.length) setMappedBranches(branches.map((br) => ({ value: br.id, text: br.name })));
  }, [branches]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect");
  const addRole = useAddRole({ languageKey: "role" });
  const addBranch = useAddBranch({ languageKey: "branch" });
  const getAllRolesDropdowns = useGetAllRolesDropdowns();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const onChangeRoleHandler = (nam, val) => {
    let newValue = currentValue;

    const index = nam.split(".")[1];
    newValue.splice(index, 1, {
      branchId: currentValue[index].branchId,
      roleId: val,
    });
    typeof onChange === "function" && onChange(name, newValue);
    setCurrentValue(newValue);
  };

  const onChangeBranchHandler = (nam, val) => {
    let newValue = currentValue;
    const index = nam.split(".")[1];
    newValue.splice(index, 1, {
      branchId: val,
      roleId: currentValue[index].roleId,
    });
    typeof onChange === "function" && onChange(name, newValue);
    setCurrentValue(newValue);
  };

  const onOpenRoleForm = () => {
    setOpenRoleForm(true);
  };

  const onCloseRoleForm = () => {
    setOpenRoleForm(false);
  };

  const onAddRoleHandler = (
    keyy,
    roleFormData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseRoleForm
  ) => {
    const validForm = validateForm();
    if (validForm) {
      setIsSubmiting(true);
      const payload = payloads["role"](roleFormData);
      addRole(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          const role = response.data.data;
          const newRole = {
            value: role.id,
            text: role.name,
            permissionsMatrix: role.permissionsMatrix,
          };

          let formDataBranchRoles;
          if (formData.branchRoles) {
            formDataBranchRoles = formData.branchRoles;
            formDataBranchRoles.splice(keyy, 1, {
              roleId: newRole.value,
              branchId: formData.branchRoles[keyy].branchId,
            });
          } else
            formDataBranchRoles = [
              {
                roleId: newRole.value,
                branchId: "",
              },
            ];

          setCurrentValue(formDataBranchRoles);
          setFormData({
            ...formData,
            branchRoles: formDataBranchRoles,
          });
          setRoles({ ...roles, data: [...roles.data, role] });
          setIsSubmiting(false);
          onCLoseRoleForm();
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  const onOpenBranchForm = () => {
    setBranchFormOpen(true);
  };

  const onCloseBranchForm = () => {
    setBranchFormOpen(false);
  };

  const onAddBranchHandler = (
    keyy,
    branchFormData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseBranchForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);

    if (validForm) {
      setIsSubmiting(true);
      const payload = payloads["branch"](branchFormData);
      addBranch(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          const branch = response.data.data;

          let formDataBranchRoles;
          if (formData.branchRoles) {
            formDataBranchRoles = formData.branchRoles;
            formDataBranchRoles.splice(keyy, 1, {
              roleId: formData.branchRoles[keyy].roleId,
              branchId: branch.id,
            });
          } else
            formDataBranchRoles = [
              {
                roleId: "",
                branchId: branch.id,
              },
            ];

          setCurrentValue(formDataBranchRoles);
          setBranches([...branches, branch]);
          setFormData({
            ...formData,
            branchRoles: formDataBranchRoles,
          });

          setIsSubmiting(false);
          onCLoseBranchForm();
        })
        .catch((errors) => {
          console.log(errors);
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  // Other
  const renderGrid = (branchId, roleId, key) => {
    return (
      <>
        <Grid xs={6} md={5} item key={key + "role"}>
          <SelectField
            required
            key={"roleId." + key}
            name={"roleId." + key}
            tooltip={"Account role ID"}
            helpKey="common.roleId"
            label={<LocalizedMessage id="common.roleId" />}
            onChange={onChangeRoleHandler}
            value={roleId}
            // errors={errors}
            isSubmitting={isSubmitting}
            isLoading={roles.isFetchingRows}
            openForm={onOpenRoleForm}
            options={roles.data.map((el) => ({ value: el.id, text: el.name }))}
            addPermission={
              permissions.isAdmin || (permissions.role && permissions.role.includes("add"))
            }
          />
        </Grid>

        <Grid xs={6} md={5} item key={key + "branch"}>
          <SelectField
            required
            fullWidth
            key={"branchId." + key}
            name={"branchId." + key}
            tooltip={"Account role ID"}
            helpKey="common.branchId"
            label={<LocalizedMessage id="common.branchId" />}
            onChange={onChangeBranchHandler}
            value={branchId}
            // errors={errors}
            isSubmitting={isSubmitting}
            isLoading={roles.isFetchingRows}
            openForm={onOpenBranchForm}
            options={mappedBranches}
            addPermission={
              permissions.isAdmin || (permissions.branch && permissions.branch.includes("add"))
            }
          />
        </Grid>

        <RoleForm
          open={openRoleForm}
          mode={"add"}
          onAdd={onAddRoleHandler}
          onCancel={onCloseRoleForm}
          full={true}
          keyy={key}
          permissions={permissions}
        />
        <BranchForm
          open={BranchFormOpen}
          mode={"add"}
          onAdd={onAddBranchHandler}
          onCancel={onCloseBranchForm}
          full={true}
          keyy={key}
          permissions={permissions}
        />
      </>
    );
  };

  // Component Render
  const handleDelete = (key) => {
    currentValue.splice(key, 1);
    setCurrentValue([...currentValue]);
    onChange(name, currentValue);
  };

  return (
    <>
      {!isAdmin && (
        <Box className={classes.fieldWrapper}>
          {roles.isFetchingRows && inputSkeleton
            ? inputSkeleton
            : currentValue.map(({ branchId, roleId }, key) => {
                return (
                  <Grid
                    key={key}
                    container
                    wrap="wrap"
                    spacing={2}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                    }}
                  >
                    {renderGrid(branchId, roleId, key)}

                    <Grid item xs={2}>
                      <Box
                        isLoading={roles.isFetchingRows}
                        skeleton={
                          <Box className={classes.vectorWrapper}>
                            <Skeleton
                              animation="wave"
                              variant="rect"
                              style={{ height: "30px", width: "80%" }}
                            />
                          </Box>
                        }
                      >
                        <Button
                          fullWidth
                          disabled={currentValue.length === 1}
                          variant="outlined"
                          onClick={() => handleDelete(key)}
                          color="secondary"
                        >
                          <LocalizedMessage id="general.dropdown.remove" />
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                );
              })}

          <Box display="flex" flexDirection="row-reverse">
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                setFormData({
                  ...formData,
                  branchRoles: [...currentValue, { branchId: "", roleId: "" }],
                });
                setCurrentValue([...currentValue, { branchId: "", roleId: "" }]);
              }}
              style={{ marginTop: 20 }}
              color="primary"
            >
              <LocalizedMessage id="general.add" />
            </Button>
          </Box>

          {/* <HelpIcon
              {...{
                tooltip,
                helpKey,
                size,
                isLoading,
                modalTitle: label,
                ...helpIconProps,
              }}
            /> */}
        </Box>
      )}
      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                    <span ref={helperTextRef}>{helperText}</span>
                  ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}
        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </>
  );
};

BranchRoles.propTypes = {
  /**
   *
   */
};

BranchRoles.defaultProps = {
  options: Array(),
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
};

export default BranchRoles;
