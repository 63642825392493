import { useAxios } from 'Helpers/Hooks'
import _ from 'lodash'

const useEditDiscount = ({ languageKey }) => {
  const axios = useAxios({ api: true })

  return ({
    id,
    name,
    otherName,
    salesSections,
    employees,
    value,
    valueType,
    discountType,
    dateFrom,
    dateTo,
  }) =>
    new Promise(function (resolve, reject) {
      axios
        .put('/orderDiscounts/' + id, {
          name,
          otherName,
          salesSections,
          employees,
          value,
          valueType,
          discountType,
          dateFrom,
          dateTo,
        })
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          let endErrors = []
          if (error.response) {
            const { status, data } = error.response
            if (status === 403) {
              endErrors.push({
                message: 'Authentication error',
                languageKey: 'errors.authentication',
              })
            } else if (status === 404) {
              endErrors.push({
                message: 'Data not found',
                languageKey: 'errors.data.not.found',
              })
            } else if (status === 404) {
              endErrors.push({
                message: 'Data not found',
                languageKey: 'errors.data.not.found',
              })
            } else if (status === 401) {
              endErrors.push({
                message: 'Authorization error',
                languageKey: 'errors.authorization',
              })
            } else if (status === 400) {
              const joi = Array.from(data.errors.joi)
              endErrors = joi.map(e => ({
                field: e.context.key,
                message: e.message,
                languageKey: `validation.${e.context.key}.${e.type}`,
              }))
            } else if (status === 409) {
              endErrors.push({
                field: 'name',
                message: 'Name must be unique',
                languageKey: `validation.name.unique`,
              })
            } else if (status === 500) {
              endErrors.push({
                message: 'Server error',
                languageKey: 'errors.server.error',
              })
            }
          } else if (error.request) {
            endErrors.push({
              message: 'Server did not respond',
              languageKey: 'errors.server.no.response',
            })
          } else {
            endErrors.push({
              message: 'Network or Browser error',
              languageKey: 'errors.network.error',
            })
          }

          reject(endErrors)
        })
    })
}

export default useEditDiscount
