// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Typography,
  Avatar,
  Button,
  // TextField,
  CircularProgress,
} from "@material-ui/core";
import { Assignment as AssignmentIcon } from "@material-ui/icons";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { displaySnackbarFormErrors } from "Utils/displaySnackbarErrors";

// Components
import Title from "Components/Title";
import { TextField, PasswordField, PhoneField } from "Components/FormFields";

// Factories

// Screens

// Assets
import { RegisterVector } from "Assets/Vectors";

// Third Parties
import zxcvbn from "zxcvbn";
import Joi from "@hapi/joi";
import { useSnackbar } from "notistack";

// Services
import { useRegister } from "Services/Auth";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  vector: {
    width: "55%",
    alignSelf: "center",
    // marginBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",

    "& $avatar": {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Ad-Hoc Components
const JoiCustom = Joi.extend((joi) => {
  return {
    type: "strongPassword",
    base: joi.string(),
    messages: {
      "strongPassword.base": '"{{#label}}" must be a strong password',
    },
    coerce(value, helpers) {},
    validate(value, helpers) {
      if (zxcvbn(value).score < 3) {
        return { value, errors: helpers.error("strongPassword.base") };
      }
    },
  };
});

const validationSchema = JoiCustom.object({
  email: JoiCustom.string().email({ tlds: false }).required(),
  password: JoiCustom.strongPassword().required(),
  confirmPassword: JoiCustom.ref("password"),
  phoneNumber: JoiCustom.string(),
}).with("password", "confirmPassword");

/**
 * @name RegisterForm
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const RegisterForm = ({ children, className, goToSignInHandler, ...otherProps }) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [serverStorage, setServerStorage] = useGlobalState("userData.serverStorage");

  // State Hooks
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "auth.register" });

  // Effect Hooks

  // Other Hooks
  const register = useRegister({ languageKey: "auth.register" });
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const onSubmit = (e) => {
    const validForm = validateForm();
    if (validForm) {
      const { email, password, phoneNumber } = formData;
      setIsSubmiting(true);
      register({ email, password, phoneNumber })
        .then(() => {
          enqueueSnackbar(<LocalizedMessage id="auth.register.success" />, {
            variant: "success",
            autoHideDuration: 5000,
          });
          goToSignInHandler();
          setServerStorage({ ...serverStorage, justRegistered: true });
          setFormData({});
          setIsSubmiting(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  // Other

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <Title pageTitle={<LocalizedMessage id="auth.register.pageTitle" />} />
      <img src={RegisterVector} className={classes.vector} alt="Register" />
      <Box className={classes.title}>
        <Avatar className={classes.avatar}>
          <AssignmentIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <LocalizedMessage id="auth.register.title" />
        </Typography>
      </Box>
      <form className={classes.form} noValidate>
        <TextField
          required
          label={<LocalizedMessage id="common.emailAddress" />}
          name="email"
          autoComplete="email"
          autoFocus
          onChange={onFormFieldChange}
          value={formData.email}
          errors={formErrors.email}
          isSubmitting={isSubmitting}
          isLoading={isLoading.email}
        />
        <PasswordField
          required
          name="password"
          label={<LocalizedMessage id="common.password" />}
          InputProps={{
            autoComplete: "new-password",
          }}
          autoComplete="new-password"
          onChange={onFormFieldChange}
          value={formData.password}
          errors={formErrors.password}
          isSubmitting={isSubmitting}
          isLoading={isLoading.password}
          showStrength={true}
        />
        <PasswordField
          required
          name="confirmPassword"
          label={<LocalizedMessage id="auth.register.confirmPassword" />}
          InputProps={{
            autoComplete: "new-password",
          }}
          autoComplete="new-password"
          onChange={onFormFieldChange}
          value={formData.confirmPassword}
          errors={formErrors.confirmPassword}
          isSubmitting={isSubmitting}
          isLoading={isLoading.confirmPassword}
        />
        <PhoneField
          label={<LocalizedMessage id="auth.register.phone" />}
          name="phoneNumber"
          autoComplete="phoneNumber"
          preferredCountries={["ae"]}
          defaultCountry={"ae"}
          autoFormat={false}
          onChange={onFormFieldChange}
          value={formData.phoneNumber}
          errors={formErrors.phoneNumber}
          isSubmitting={isSubmitting}
          isLoading={isLoading.phoneNumber}
        />
        <Button
          type="button"
          variant="contained"
          color="primary"
          fullWidth
          className={classes.submit}
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            <LocalizedMessage id="auth.register.submit" />
          )}
        </Button>
        {children}
      </form>
    </Box>
  );
};

RegisterForm.propTypes = {
  /**
   *
   */
};

RegisterForm.defaultProps = {
  /**
   *
   */
};

export default RegisterForm;
