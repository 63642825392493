// React
import React, { useState, useEffect } from "react";

// Material
// Globals

// Helpers
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { searchTree } from "Utils/";

// Components
import CategoryList from "./CategoryList";

// Factories
import CategoryForm from "./CategoryForm";

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services
import {
  useGetAllCategories,
  useAddCategory,
  useGetCategory,
  useDeleteCategory,
  useEditCategory,
} from "Services/Category/";

// Styles

// Ad-Hoc Components

/**
 * @name Categories
 * @summary
 * @item
 * @component
 * @description
 * >
 */
const Categories = ({
  categories,
  setCategories,
  setItems,
  isFetchingCategories,
  setIsFetchingCategories,
  selectedCategory,
  setSelectedCategory,
  setOpenItemCategoryCalled,
  permissions,
}) => {
  // Theme & Style Hooks

  // Global State Hooks
  const [open, setOpen] = useState(true);

  // State Hooks
  const [categoryFormOpen, setCategoryFormOpen] = useState(false);
  const [categoryFormMode, setCategoryFormMode] = useState("");
  const [categoryFormData, setCategoryFormData] = useState({});

  // const [deletedId, setDeletedId] = useState()
  //const [deletedIds, setDeletedIds] = useState([])
  const [editId, setEditId] = React.useState("");

  // Effect Hooks
  // useEffect(() => {
  //   if (deletedId) setDeletedIds([...deletedIds, deletedId])
  // }, [deletedId])

  useEffect(() => {
    getAllCategories()
      .then((categories) => {
        setCategories(categories);
        setIsFetchingCategories(false);
        setSelectedCategory(categories[0] ? categories[0] : {});
      })
      .catch((errors) => {
        console.log(errors);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const getAllCategories = useGetAllCategories({ languageKey: "category" });
  const addCategory = useAddCategory({ languageKey: "category" });
  const editCategory = useEditCategory({ languageKey: "category" });
  const getCategory = useGetCategory({ languageKey: "category" });
  const deleteCategory = useDeleteCategory();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const onOpenCategoriesHandler = () => {
    setOpen(true);
  };

  const onCloseCategoriesHandler = () => {
    setOpen(false);
  };

  const onDeleteCategoryHandler = (deletedCategory) => {
    deleteCategory(deletedCategory.id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");
        if (deletedCategory.parentId) {
          const firstParent = categories.filter((category) => {
            const result = searchTree(category, deletedCategory.parentId);
            if (result) return result;
          })[0];

          getCategory(firstParent.id)
            .then((newCategory) => {
              const index = categories.findIndex(
                (category) => category.id === newCategory.id
              );
              const childIndex = categories[index].children.findIndex(
                (child) => child.id === deletedCategory.id
              );
              categories[index].children.splice(childIndex, 1);

              setCategories([...categories]);
            })
            .catch((errors) => {
              displaySnackbarErrors(errors, enqueueSnackbar);
            });
        } else {
          const index = categories.findIndex(
            (category) => category.id === deletedCategory.id
          );
          categories.splice(index, 1);

          setSelectedCategory(categories[0] ? categories[0] : {});
          if (!categories.length) setItems([]);
          setCategories([...categories]);
        }
        // setSelectedCategory((selectedCategory) => {
        //   console.log("[...deletedIds, id]", [
        //     ...deletedIds,
        //     deletedCategory.id,
        //   ])
        //   console.log("categories", categories)

        //   const newSelectedId = categories.filter(
        //     (category) =>
        //       ![...deletedIds, deletedCategory.id].includes(category.id)
        //   )[0]
        //   if (categories.length === 1) {
        //     setItems([])
        //   }
        //   return newSelectedId
        // })
        //setDeletedId(deletedCategory.id)
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  // Other

  // Component Render
  return (
    <>
      <CategoryList
        open={open}
        onCloseCategories={onCloseCategoriesHandler}
        onOpenCategories={onOpenCategoriesHandler}
        setCategoryFormOpen={setCategoryFormOpen}
        setCategoryFormMode={setCategoryFormMode}
        setCategoryFormData={setCategoryFormData}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        getAllCategories={getAllCategories}
        categories={categories}
        setCategories={setCategories}
        onDelete={onDeleteCategoryHandler}
        //deletedIds={deletedIds}
        setEditId={setEditId}
        isFetchingCategories={isFetchingCategories}
        setOpenItemCategoryCalled={setOpenItemCategoryCalled}
        permissions={permissions}
      />
      <CategoryForm
        open={categoryFormOpen}
        setOpen={setCategoryFormOpen}
        mode={categoryFormMode}
        editFormData={categoryFormData}
        permissions={permissions}
        categories={categories}
        setCategories={setCategories}
        setSelectedCategory={setSelectedCategory}
        editId={editId}
      />

      {/* <CategoryInfo open={open} /> */}
    </>
  );
};

export default Categories;
