// React
import React, { useEffect } from "react";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  CircularProgress,
  useTheme,
  Divider,
} from "@material-ui/core";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import { TextField, CheckBox } from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens
import ModifierItemField from "./ModifierItemField";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const modifierItemsSchema = Joi.object({
  id: Joi.string().guid(),
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().min(1).max(100).allow(null, ""),
  price: Joi.number().min(0).allow(null, ""),
});

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  minOptions: Joi.number().integer().required().min(0),
  maxOptions: Joi.number().integer().required().min(Joi.ref("minOptions")),
  allowMultipleQuantities: Joi.boolean(),
  modifierItems: Joi.array().when("allowMultipleQuantities", {
    is: false,
    then: Joi.array().items(modifierItemsSchema).min(Joi.ref("minOptions")),
    otherwise: Joi.array().items(modifierItemsSchema).min(1),
  }),
});

/**
 * @name ModifierForm
 * @summary
 * @item
 * @component
 * @description
 * >
 */
const ModifierForm = ({
  name,
  onChange,
  mode,
  open,
  setOpen,
  editFormData,
  editIndex,
  modifiers,
  setModifiers,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData2", editFormData);
    if (mode === "edit") {
      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "minOptions",
          "maxOptions",
          "allowMultipleQuantities",
          "modifierItems",
        ])
      );
    } else setFormData({});
  }, [open]);

  // Other Hooks
  const {
    formData,
    setFormData,
    formErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "item.modifier" });

  // Event Handlers
  const onAddModifier = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      onChange(name, [...modifiers, formData]);
      setModifiers([...modifiers, formData]);
      setOpen(false);
      setFormData({});
    }
  };

  const onSaveModifier = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      const newModifier = { id: editFormData.id, ...formData };
      modifiers.splice(editIndex, 1, newModifier);
      onChange(name, [...modifiers]);
      setModifiers([...modifiers]);
      setOpen(false);
      setFormData({});
    }
  };
  console.log("Updating modifier data");

  // Other
  console.log(
    `%cModifierForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll={"paper"}
        fullScreen={true}
        style={{ direction: theme.direction }}
      >
        <DialogTitle>
          <LocalizedMessage id="item.modifier.formTitle" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mode === "add" ? (
              <Typography component="span">
                <LocalizedMessage id="general.add" />
              </Typography>
            ) : (
              <Typography component="span">
                <LocalizedMessage id="general.edit" />
                <b> {formData.name}</b>
              </Typography>
            )}
          </DialogContentText>
          <form className={classes.form} noValidate>
            <TextField
              required
              label={<LocalizedMessage id="common.name" />}
              name="name"
              autoComplete="name"
              tooltip={"Item Full Name"}
              helpKey="Menu.items.NameField"
              autoFocus
              fullWidth
              // maxCharacters={50}
              onChange={onFormFieldChange}
              value={formData.name}
              errors={formErrors.name}
              isSubmitting={isSubmitting}
              isLoading={isLoading.name}
            />
            <TextField
              label={<LocalizedMessage id="common.otherName" />}
              name="otherName"
              autoComplete="otherName"
              tooltip={"Items Other Name"}
              helpKey="Menu.items.otherNameField"
              fullWidth
              // maxCharacters={50}
              onChange={onFormFieldChange}
              value={formData.otherName}
              errors={formErrors.otherName}
              isSubmitting={isSubmitting}
              isLoading={isLoading.otherName}
            />
            <TextField
              required
              label={<LocalizedMessage id="item.modifier.minOptions" />}
              name="minOptions"
              autoComplete="minOptions"
              tooltip={"Items minOptions"}
              helpKey="Menu.items.minOptionsField"
              fullWidth
              // maxCharacters={50}
              onChange={onFormFieldChange}
              value={formData.minOptions}
              errors={formErrors.minOptions}
              isSubmitting={isSubmitting}
              isLoading={isLoading.minOptions}
            />
            <TextField
              required
              label={<LocalizedMessage id="item.modifier.maxOptions" />}
              name="maxOptions"
              autoComplete="maxOptions"
              tooltip={"Items maxOptions"}
              helpKey="Menu.items.maxOptionsField"
              fullWidth
              // maxCharacters={50}
              onChange={onFormFieldChange}
              value={formData.maxOptions}
              errors={formErrors.maxOptions}
              isSubmitting={isSubmitting}
              isLoading={isLoading.maxOptions}
            />
            <CheckBox
              fullWidth
              name="allowMultipleQuantities"
              tooltip={"Allow Multiple Quantity?"}
              helpKey="Menu.Items.allowMultipleQuantity"
              label={<LocalizedMessage id="item.modifier.allowMultipleQuantity" />}
              onChange={onFormFieldChange}
              value={formData.allowMultipleQuantities}
              errors={formErrors.allowMultipleQuantities}
              isSubmitting={isSubmitting}
              isLoading={isLoading.allowMultipleQuantities}
            />
            <ModifierItemField
              // fullWidth
              name="modifierItems"
              tooltip={"Modifier Items"}
              helpKey="Menu.Items.modifierItems"
              label={<LocalizedMessage id="item.modifier.modifierItems" />}
              onChange={onFormFieldChange}
              value={formData.modifierItems}
              errors={formErrors.modifierItems}
              isSubmitting={isSubmitting}
              isLoading={isLoading.modifierItems}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <FormActions
            onAdd={onAddModifier}
            mode={mode}
            onCLoseForm={() => setOpen(false)}
            isSubmitting={isSubmitting}
            onSave={onSaveModifier}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

ModifierForm.propTypes = {
  /**
   *
   */
};

ModifierForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default ModifierForm;
