// React
import React, { useState, useEffect } from "react";

// Material
import {
  Box,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core/";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import {
  displaySnackbarErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import Memo from "Components/Memo";
import LooseMemo from "Components/LooseMemo";

// Factories
import ItemForm from "./ItemForm";
import ItemCard from "./ItemCard";

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import LazyLoad from "react-lazyload";

// Services
import { useDeleteItem } from "Services/Items/";

// Styles

// Ad-Hoc Components
const SortableItem = SortableElement(
  ({ item, onEditItem, updated, permissions, onDeleteItem }) => {
    return (
      <Grid item xs={12} sm={6} lg={4}>
        <LazyLoad
          offset={100}
          placeholder={
            <Memo>
              {() => (
                <Card>
                  <CardContent>
                    <Skeleton
                      animation="wave"
                      variant={"rect"}
                      style={{
                        height: 200,
                        width: "100%",
                      }}
                    />
                  </CardContent>
                </Card>
              )}
            </Memo>
          }
        >
          <LooseMemo deps={[item, updated]}>
            {() => {
              return (
                <ItemCard
                  style={{
                    height: "100%",
                  }}
                  item={item}
                  onViewItem={() => onEditItem(item)}
                  onDeleteItem={() => onDeleteItem(item)}
                  permissions={permissions}
                />
              );
            }}
          </LooseMemo>
        </LazyLoad>
      </Grid>
    );
  }
);

const SortableList = SortableContainer(
  ({ items, onEditItem, updated, permissions, onDeleteItem }) => {
    let u = updated + 1;
    return (
      <Grid container spacing={2}>
        <Memo deps={[items, u]}>
          {() =>
            items.map((item, index) => (
              <SortableItem
                key={item.id}
                index={index}
                item={item}
                onEditItem={onEditItem}
                onDeleteItem={onDeleteItem}
                updated={u}
                permissions={permissions}
              />
            ))
          }
        </Memo>
      </Grid>
    );
  }
);

/**
 * @name Items
 * @summary
 * @item
 * @component
 * @description
 * >
 */
const Items = ({
  isFetchingCategories,
  selectedCategory,
  openItemCategoryCalled,
  setOpenItemCategoryCalled,
  permissions,
  items,
  setItems,
  isFetchingItems,
}) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [itemFormOpen, setItemFormOpen] = useState(false);
  const [itemFormMode, setItemFormMode] = useState("");
  const [itemFormData, setItemFormData] = useState({});
  const [updated, setUpdated] = React.useState(0);
  const [editId, setEditId] = useState("");

  // Effect Hooks

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const deleteItem = useDeleteItem();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleEditItem = (item) => {
    setItemFormData(item);
    setItemFormMode("edit");
    setItemFormOpen(true);
  };

  const handleDeleteItem = (item) => {
    deleteItem(item.id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");
        setItems((items) => items.filter((itm) => itm.id !== item.id));
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const addCallback = (response) => setItems((items) => [...items, response]);

  const editCallback = (response) => {
    setItems((items) => {
      const index = items.findIndex((item) => item.id === editId);
      items.splice(index, 1, response);
      return items;
    });

    setUpdated((updated) => updated + 1);
  };
  // Other
  const downMd = useMediaQuery(Theme.breakpoints.down("md"));

  // Component Render
  return (
    <>
      <Grid item xs={12}>
        <>
          {isFetchingItems && inputSkeleton ? (
            inputSkeleton
          ) : (
            <Memo deps={[items.getState(), updated, selectedCategory, downMd]}>
              {() => (
                <Grid item xs={12}>
                  <Card
                    ref={inputRef}
                    style={{
                      overflow: "visible",
                    }}
                  >
                    <CardHeader
                      title={
                        <>
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography variant="h6" component="h2">
                              {console.log(
                                "aaselectedCategory",
                                selectedCategory
                              )}
                              {selectedCategory?.name
                                ? selectedCategory.name + ", "
                                : null}
                              <LocalizedMessage id="item.pageTitle" />
                            </Typography>

                            {(permissions.isAdmin ||
                              (permissions.menuItem &&
                                permissions.menuItem.includes("add"))) && (
                              <Button
                                size="small"
                                disabled={selectedCategory?.id ? false : true}
                                onClick={() => setOpenItemCategoryCalled(true)}
                                startIcon={
                                  <FastfoodIcon style={{ margin: 5 }} />
                                }
                                color="primary"
                                variant="contained"
                              >
                                <LocalizedMessage id="item.newItem" />
                              </Button>
                            )}
                          </Box>
                          <Divider style={{ marginTop: 10 }} />
                        </>
                      }
                    />
                    <CardContent
                      style={{
                        display: "flex",
                        overflowX: "auto",
                        position: "relative",
                        overflow: "visible",
                      }}
                    >
                      {items.getState().length ? (
                        <SortableList
                          ref={inputRef}
                          items={items.getState()}
                          axis="xy"
                          onSortEnd={({ oldIndex, newIndex }) => {
                            setItems((items) => [
                              ...arrayMove(items, oldIndex, newIndex),
                            ]);
                          }}
                          onEditItem={handleEditItem}
                          onDeleteItem={handleDeleteItem}
                          updated={updated}
                          permissions={permissions}
                          shouldCancelStart={(e) =>
                            e.target.tagName === "SPAN" ||
                            e.target.tagName === "BUTTON"
                              ? true
                              : false
                          }
                          useDragHandle={downMd}
                        />
                      ) : (
                        <Typography variant="body1" component="h2">
                          {<LocalizedMessage id="menu.noItems" />}
                        </Typography>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              )}
            </Memo>
          )}
        </>

        <ItemForm
          open={itemFormOpen}
          setOpen={setItemFormOpen}
          mode={itemFormMode}
          setMode={setItemFormMode}
          editFormData={itemFormData}
          selectedCategory={selectedCategory}
          editCallback={editCallback}
          addCallback={addCallback}
          openItemCategoryCalled={openItemCategoryCalled}
          setOpenItemCategoryCalled={setOpenItemCategoryCalled}
          permissions={permissions}
          editId={editId}
          setEditId={setEditId}
        />
      </Grid>
    </>
  );
};

export default Items;
