// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { makeStyles } from "Helpers/Styles";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField, RadioBox } from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens
import PermissionForm from "Screens/Portal/Permissions/PermissionForm";
import SalesChannelForm from "Screens/Portal/SalesChannels/SalesChannelForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import _ from "lodash";
import { useIntl } from "react-intl";

// Services
import {
  useGetAllSalesSectionsDropdowns,
  useGetAllPermissionGroupsDropdowns,
} from "Services/Dropdowns/";
import { useAddEmployee, useEditEmployee } from "Services/Employees/";

// Styles
const useStyles = makeStyles((theme) => ({
  radioBox: {
    width: "50%",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

/**
 * @name EmployeeForm
 * @summary
 * @employee
 * @component
 * @description
 * >
 */
const EmployeeForm = ({
  editFormData,
  editId,
  addCallback,
  editCallback,
  onCancel,
  mode,
  open,
  permissions,
  setOpen,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState(
    "salesSections.salesSections"
  );
  const [permissionsGroups, setPermissionsGroups] = useGlobalState(
    "permissionsGroups.permissionsGroups"
  );

  // State Hooks
  const [openPermissionForm, setOpenPermissionForm] = useState(false);
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);

  const validationSchema = Joi.object({
    name: Joi.string().required().min(1).max(100),
    accessId: Joi.string()
      .required()
      .length(4)
      .regex(/^[0-9]+$/),
    accessPin:
      mode === "edit"
        ? Joi.string()
            .allow("")
            .allow(null)
            .optional()
            .length(8)
            .regex(/^[0-9]+$/)
        : Joi.string()
            .optional()
            .length(8)
            .regex(/^[0-9]+$/)
            .required(),
    driverOrCashier: Joi.string().valid("isDriver", "hasFrontOfficeAccess"),
    maxDiscountAmount: Joi.when("driverOrCashier", {
      is: "hasFrontOfficeAccess",
      then: Joi.number().min(0).allow("").allow(null),
      otherwise: Joi.allow("").allow(null),
    }),
    maxDiscountPercentage: Joi.when("driverOrCashier", {
      is: "hasFrontOfficeAccess",
      then: Joi.number().min(0).allow("").allow(null),
      otherwise: Joi.allow("").allow(null),
    }),
    // isDriver: Joi.boolean(),
    // hasFrontOfficeAccess: Joi.boolean(),
    permissionGroupId: Joi.string().uuid().when("driverOrCashier", {
      is: "hasFrontOfficeAccess",
      then: Joi.required(),
    }),
    isActive: Joi.boolean(),
    salesSections: Joi.array().items(Joi.string().uuid()),
  });

  const employeeForm = useForm({
    validationSchema,
    languageKey: "setup.employee",
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = employeeForm;

  // Effect Hooks
  useEffect(() => {
    // setOpen(true)
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      editFormData = {
        ...editFormData,
        permissionGroupId: editFormData.permissionGroup
          ? editFormData.permissionGroup.id
          : undefined,
        salesSections: editFormData.salesSections.map(
          (salesSection) => salesSection.id
        ),
        driverOrCashier: editFormData.isDriver
          ? "isDriver"
          : "hasFrontOfficeAccess",
      };
      setFormData(
        _.pick(editFormData, [
          "name",
          "accessId",
          "permissionGroupId",
          "salesSections",
          "driverOrCashier",
          "maxDiscountAmount",
          "maxDiscountPercentage",
        ])
      );
    } else {
      setFormData({
        salesSections: salesSections.data.map(
          (salesSection) => salesSection.id
        ),
      });
    }
  }, [open]);

  useEffect(() => {
    if (!permissionsGroups.isFetchingRows && !permissionsGroups.data.length)
      getAllPermissionGroupsDropdowns()
        .then((res) => {
          setPermissionsGroups({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [permissionsGroups.isFetchingRows]);

  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
          if (mode === "add") {
            setFormData({
              salesSections: res.map((salesSection) => salesSection.id),
            });
          }
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  // Other Hooks
  const addEmployee = useAddEmployee({ languageKey: "setup.employee" });
  const editEmployee = useEditEmployee({ languageKey: "setup.employee" });
  const { enqueueSnackbar } = useSnackbar();
  const getAllPermissionGroupsDropdowns = useGetAllPermissionGroupsDropdowns();
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();

  // Event Handlers
  const onCLoseEmployeeForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddEmployee = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: employeeForm,
      formName: "employee",
      apiHandler: addEmployee,
      onClose: onCLoseEmployeeForm,
    });
  };

  const onSaveEmployee = () => {
    if (formData.accessPin === "") formData.accessPin = undefined;

    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: employeeForm,
      formName: "employee",
      apiHandler: editEmployee,
      onClose: onCLoseEmployeeForm,
    });
  };

  const onOpenPermissionForm = () => {
    setOpenPermissionForm(true);
  };

  const onClosePermissionForm = () => {
    setOpenPermissionForm(false);
  };

  const onOpenSalesChannelForm = () => {
    setSalesChannelFormOpen(true);
  };

  const onCloseSalesChannelForm = () => {
    setSalesChannelFormOpen(false);
  };

  const permissionCallback = (response) => {
    const permission = response.data.data;
    const newPermission = {
      value: permission.id,
      text: permission.name,
      permissionsMatrix: permission.permissionsMatrix,
      isAdmin: permission.isAdmin,
    };
    setPermissionsGroups({
      ...permissionsGroups,
      data: [...permissionsGroups.data, permission],
    });

    setFormData({
      ...formData,
      permissionGroupId: newPermission.value,
    });
  };

  const salesChannelCallback = (response) => {
    const salesSection = response.data.data;
    const newSalesSection = {
      value: salesSection.id,
      text: salesSection.name,
      salesSectionTypeId: salesSection.salesSectionTypeId,
    };

    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, salesSection],
    });
    const formDataSalesSections = formData.salesSections
      ? formData.salesSections
      : [];

    setFormData({
      ...formData,
      salesSections: [...formDataSalesSections, newSalesSection.value],
    });
  };

  // Other
  console.log(
    `%cEmployeeForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  const driverOrCashierOptions = [
    {
      text: intl.formatMessage({ id: "setup.employee.hasFrontOfficeAccess" }),
      value: "hasFrontOfficeAccess",
    },
    {
      text: intl.formatMessage({ id: "setup.employee.isDriver" }),
      value: "isDriver",
    },
  ];

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseEmployeeForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        {" "}
        <LocalizedMessage id="setup.employee.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Employee Full Name"}
                helpKey="Menu.Employee.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                required
                fullWidth
                name="accessId"
                autoComplete="new-password"
                tooltip={"Employee Access Id"}
                helpKey="Menu.Employee.accessId"
                disabled={mode === "edit"}
                label={<LocalizedMessage id="setup.employee.accessId" />}
                // maxCharacters={4}
                onChange={onFormFieldChange}
                value={formData.accessId}
                errors={formErrors.accessId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.accessId}
              />
              <TextField
                required
                fullWidth
                name="accessPin"
                autoComplete="new-password"
                tooltip={"Employee Access PIN"}
                helpKey="Menu.Employee.accessPin"
                label={<LocalizedMessage id="setup.employee.accessPin" />}
                // maxCharacters={8}
                onChange={onFormFieldChange}
                value={formData.accessPin}
                errors={formErrors.accessPin}
                isSubmitting={isSubmitting}
                isLoading={isLoading.accessPin}
                type="password"
              />
              <RadioBox
                // fullWidth
                name="driverOrCashier"
                tooltip={"Driver or Cashier?"}
                helpKey="Menu.Items.driverOrCashier"
                label={<LocalizedMessage id="setup.employee.driverOrCashier" />}
                onChange={onFormFieldChange}
                value={formData.driverOrCashier}
                errors={formErrors.driverOrCashier}
                isSubmitting={isSubmitting}
                isLoading={isLoading.driverOrCashier}
                options={driverOrCashierOptions}
                defaultValue={
                  formData.driverOrCashier || driverOrCashierOptions[0].value
                }
                className={classes.radioBox}
              />

              {/* <CheckBox
                fullWidth
                name="isDriver"
                tooltip={"Can you drive?"}
                helpKey="Menu.Employee.isDriver"
                label={<LocalizedMessage id="setup.employee.isDriver" />}
                onChange={onFormFieldChange}
                value={formData.isDriver}
                errors={formErrors.isDriver}
                isSubmitting={isSubmitting}
                isLoading={isLoading.isDriver}
              />
              <CheckBox
                fullWidth
                name="hasFrontOfficeAccess"
                tooltip={"Has front office access?"}
                helpKey="Menu.Employee.hasFrontOfficeAccess"
                label={
                  <LocalizedMessage id="setup.employee.hasFrontOfficeAccess" />
                }
                onChange={onFormFieldChange}
                value={formData.hasFrontOfficeAccess}
                errors={formErrors.hasFrontOfficeAccess}
                isSubmitting={isSubmitting}
                isLoading={isLoading.hasFrontOfficeAccess}
              /> */}
              {formData.driverOrCashier === "hasFrontOfficeAccess" && (
                <>
                  <SelectField
                    required
                    fullWidth
                    name="permissionGroupId"
                    tooltip={"Employee Permissions"}
                    helpKey="Menu.Employee.permissionGroupId"
                    label={
                      <LocalizedMessage id="setup.employee.permissionGroupId" />
                    }
                    onChange={onFormFieldChange}
                    value={formData.permissionGroupId}
                    errors={formErrors.permissionGroupId}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.permissionGroupId}
                    openForm={onOpenPermissionForm}
                    options={permissionsGroups.data.map((pr) => ({
                      value: pr.id,
                      text: pr.name,
                    }))}
                    addPermission={
                      permissions.isAdmin ||
                      (permissions.permissionGroup &&
                        permissions.permissionGroup.includes("add"))
                    }
                  />
                  <SelectField
                    fullWidth
                    name="salesSections"
                    tooltip={"Sales Sections"}
                    helpKey="common.salesSections"
                    label={<LocalizedMessage id="common.salesSections" />}
                    onChange={onFormFieldChange}
                    value={formData.salesSections}
                    errors={formErrors.salesSections}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.salesSections}
                    options={salesSections.data.map((s) => ({
                      value: s.id,
                      text: s.name,
                    }))}
                    openForm={onOpenSalesChannelForm}
                    addPermission={
                      permissions.isAdmin ||
                      (permissions.salesSection &&
                        permissions.salesSection.includes("add"))
                    }
                    multiple
                  />
                  <TextField
                    fullWidth
                    name="maxDiscountAmount"
                    autoComplete="maxDiscountAmount"
                    tooltip={"Max Discount Amount"}
                    helpKey="Menu.discount.maxDiscountAmount"
                    label={
                      <LocalizedMessage id="setup.employee.maxDiscountAmount" />
                    }
                    onChange={onFormFieldChange}
                    value={formData.maxDiscountAmount}
                    errors={formErrors.maxDiscountAmount}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.maxDiscountAmount}
                  />
                  <TextField
                    fullWidth
                    name="maxDiscountPercentage"
                    autoComplete="maxDiscountPercentage"
                    tooltip={"Max Discount Percentage"}
                    helpKey="Menu.discount.maxDiscountPercentage"
                    label={
                      <LocalizedMessage id="setup.employee.maxDiscountPercentage" />
                    }
                    onChange={onFormFieldChange}
                    value={formData.maxDiscountPercentage}
                    errors={formErrors.maxDiscountPercentage}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.maxDiscountPercentage}
                  />
                </>
              )}
            </form>
            {(permissions.isAdmin ||
              (permissions.permissionGroup &&
                permissions.permissionGroup.includes("add"))) && (
              <PermissionForm
                open={openPermissionForm}
                mode="add"
                onCancel={onClosePermissionForm}
                addCallback={permissionCallback}
              />
            )}
            <SalesChannelForm
              open={salesChannelFormOpen}
              mode="add"
              permissions={permissions}
              addCallback={salesChannelCallback}
              onCancel={onCloseSalesChannelForm}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddEmployee}
          mode={mode}
          onCLoseForm={onCLoseEmployeeForm}
          isSubmitting={isSubmitting}
          onSave={onSaveEmployee}
        />
      </DialogActions>
    </Dialog>
  );
};

EmployeeForm.propTypes = {
  /**
   *
   */
};

EmployeeForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default EmployeeForm;
