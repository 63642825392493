// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useStateRef from "Helpers/useStateRef";

// Components

// Factories
import ModifierCard from "./ModifierCard";

// Screens
import ModifierForm from "./ModifierForm";

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name ModifierGroup
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ModifierGroup = ({ name, onChange, value }) => {
  value = value === undefined || value === null ? [] : value;
  const [modifiers, setModifiers] = useState(value);
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();
  // Global State Hooks
  // State Hooks
  const [modifierFormOpen, setModifierFormOpen] = useState(false);
  const [modifierFormMode, setModifierFormMode] = useState("");
  const [modifierFormData, setModifierFormData] = useState({});
  const [editIndex, setEditIndex] = useState(undefined);
  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const handleEditModifier = (index) => {
    setEditIndex(index);
    setModifierFormData({
      ...modifiers[index],
    });
    setModifierFormMode("edit");
    setModifierFormOpen(true);
  };
  const handleDeleteModifier = (index) => {
    modifiers.splice(index, 1);
    setModifiers([...modifiers]);
    onChange(name, modifiers);
  };
  // Other

  // Component Render
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="body1" component="h2">
          <LocalizedMessage id="item.modifiers" />
        </Typography>

        <Button
          size="small"
          onClick={() => {
            setModifierFormMode("add");
            setModifierFormOpen(true);
          }}
          startIcon={<AddIcon style={{ margin: 5 }} />}
        >
          <LocalizedMessage id="item.addModifier" />
        </Button>
      </Box>
      <Divider
        style={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
      />

      <Grid container spacing={2}>
        {modifiers.length ? (
          modifiers.map((modifier, index) => {
            return (
              <Grid item xs={12}>
                <ModifierCard
                  style={{
                    height: "100%",
                  }}
                  modifier={modifier}
                  editModifierGroup={() => handleEditModifier(index)}
                  deleteModifierGroup={() => handleDeleteModifier(index)}
                  // onEditItem={() => onEditItem(index)}
                />
              </Grid>
            );
          })
        ) : (
          <Typography
            variant="body1"
            component="h2"
            style={{ margin: theme.spacing(1) }}
          >
            <LocalizedMessage id="item.modifier.noModifiers" />
          </Typography>
        )}
      </Grid>
      <ModifierForm
        open={modifierFormOpen}
        setOpen={setModifierFormOpen}
        mode={modifierFormMode}
        setMode={setModifierFormMode}
        editFormData={modifierFormData}
        editIndex={editIndex}
        modifiers={modifiers}
        setModifiers={setModifiers}
        name={name}
        onChange={onChange}
      />
    </>
  );
};

ModifierGroup.propTypes = {
  /**
   *
   */
};

ModifierGroup.defaultProps = {
  /**
   *
   */
};

export default ModifierGroup;
