// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { FormHelperText, Grid, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";
import payloads from "Helpers/Handlers/payloads";

// Components
import HelpIcon from "Components/HelpIcon";
import SelectField from "./SelectField";
import Box from "Components/Box";

// Factories

// Screens
import BranchForm from "Screens/Portal/Branches/BranchForm";
import RoleForm from "Screens/Portal/Roles/RoleForm";

// Assets

// Servicies
import { useGetAllSalesSectionsDropdowns } from "Services/Dropdowns/";

// Help

// Third Parties
import { useSnackbar } from "notistack";
import TextField from "./TextField";

// Styles
const useStyles = makeStyles((theme) => ({}));

// Ad-Hoc Components

/**
 * @name SalesSectionsPrices
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesSectionsPrices = ({
  className,
  style,
  tooltip,
  helpKey,
  variant,
  margin,
  autoComplete,
  helpIconProps,
  options,
  getOptionLabel,
  getOptionSelected,
  defaultValue,
  onChange,
  helperText,
  size,
  inputProps,
  isLoading,
  hidden,
  isSubmitting,
  disabled,
  setDefaultValue,
  label,
  id,
  required,
  name,
  value,
  multiple,
  validator,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  branches,
  mode,
  isAdmin,
  setBranches,
  refresh,
  formData,
  setFormData,
  permissions,
  ...otherProps
}) => {
  value = value === undefined || value === null || !value.length ? [] : value;
  const [currentValue, setCurrentValue] = useState(value);

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState("salesSections.salesSections");

  // State Hooks;

  // Effect Hooks
  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect");
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const onChangeSalesSectionHandler = (nam, val) => {
    let newValue = currentValue;

    const index = nam.split(".")[1];
    newValue.splice(index, 1, {
      salesSectionId: val,
      price: currentValue[index].price,
    });
    typeof onChange === "function" && onChange(name, newValue);
    setCurrentValue(newValue);
  };

  const onChangePriceHandler = (nam, val) => {
    let newValue = currentValue;
    const index = nam.split(".")[1];
    newValue.splice(index, 1, {
      salesSectionId: currentValue[index].salesSectionId,
      price: val,
    });
    typeof onChange === "function" && onChange(name, newValue);
    setCurrentValue(newValue);
  };

  // Other
  const renderGrid = (salesSectionId, price, key) => {
    return (
      <>
        <Grid xs={6} md={5} item key={key + "salesSection"}>
          <SelectField
            key={"salesSectionId." + key}
            name={"salesSectionId." + key}
            tooltip={"Sales Channel"}
            helpKey="common.salesSectionId"
            label={<LocalizedMessage id="setup.salesChannel.pageTitle" />}
            onChange={onChangeSalesSectionHandler}
            value={salesSectionId}
            //errors={errors}
            isSubmitting={isSubmitting}
            isLoading={salesSections.isFetchingRows}
            options={salesSections.data.map((el) => ({
              value: el.id,
              text: el.name,
            }))}
            defaultValue={
              salesSections.data.map((el) => ({
                value: el.id,
                text: el.name,
              }))[key].value
            }
          />
        </Grid>

        <Grid xs={6} md={5} item key={key + "price"}>
          <TextField
            fullWidth
            key={"price." + key}
            name={"price." + key}
            tooltip={"Price"}
            helpKey="common.price"
            label={<LocalizedMessage id="common.price" />}
            onChange={onChangePriceHandler}
            value={price}
            // errors={errors}
            isSubmitting={isSubmitting}
            isLoading={salesSections.isFetchingRows}
          />
        </Grid>
      </>
    );
  };

  // Component Render
  const handleDelete = (key) => {
    currentValue.splice(key, 1);
    setCurrentValue([...currentValue]);
    onChange(name, currentValue);
  };

  return (
    <>
      <Box className={classes.fieldWrapper}>
        {salesSections.isFetchingRows && inputSkeleton
          ? inputSkeleton
          : currentValue.map(({ salesSectionId, price }, key) => {
              return (
                <Grid
                  key={key}
                  container
                  wrap="wrap"
                  spacing={2}
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                  }}
                >
                  {renderGrid(salesSectionId, price, key)}

                  <Grid item xs={2}>
                    <Box
                      isLoading={salesSections.isFetchingRows}
                      skeleton={
                        <Box className={classes.vectorWrapper}>
                          <Skeleton
                            animation="wave"
                            variant="rect"
                            style={{ height: "30px", width: "80%" }}
                          />
                        </Box>
                      }
                    >
                      <Button
                        fullWidth
                        //disabled={currentValue.length === 1}
                        variant="outlined"
                        onClick={() => handleDelete(key)}
                        color="secondary"
                      >
                        <LocalizedMessage id="general.dropdown.remove" />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}

        <Box display="flex" flexDirection="row-reverse">
          <Button
            fullWidth
            variant="outlined"
            disabled={currentValue.length === salesSections.data.length}
            onClick={() => {
              setFormData({
                ...formData,
                salesSections: [...currentValue, { salesSectionId: "", price: 0 }],
              });
              setCurrentValue([...currentValue, { salesSectionId: "", price: 0 }]);
            }}
            style={{ marginTop: 20 }}
            color="primary"
          >
            <LocalizedMessage id="item.addSalesSection" />
          </Button>
        </Box>
      </Box>

      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                    <span ref={helperTextRef}>{helperText}</span>
                  ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}
        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </>
  );
};

SalesSectionsPrices.propTypes = {
  /**
   *
   */
};

SalesSectionsPrices.defaultProps = {
  options: Array(),
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
};

export default SalesSectionsPrices;
