// React
import React from "react"
import PropTypes from "prop-types"

// Material
import { Select, MenuItem, FormControl } from "@material-ui/core"
import { Language as LanguageIcon } from "@material-ui/icons"

// Globals

// Helpers
import { useGlobalState } from "Helpers/GlobalState"

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name LanguageDropdown
 * @summary LanguageDropdown Component
 * @category Components
 * @component
 * @description
 * > Renders a dropdown contains app languages and change app language on changed
 * @requires React
 * @requires Select
 * @requires MenuItem
 * @requires FormControl
 * @requires LanguageIcon
 * @requires useGlobalState
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @see {@link https://material-ui.com/components/selects/|Material UI Select}
 * @see {@link https://material-ui.com/api/menu-item/|Material UI MenuItem API}
 * @see {@link https://material-ui.com/api/form-control/|Material UI FormControl API}
 * @see {@link https://material-ui.com/components/icons/|Material UI Icons}
 * @see {@link module:GlobalState.useGlobalState|Helpers / GlobalState}
 */
const LanguageDropdown = (props) => {
  // Props Destructing

  // Theme & Style Hooks

  // Global State Hooks
  const [locale, setLocale] = useGlobalState("global.locale")

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const HandleLanguageChange = async (e) => {
    setLocale(e.target.value)
  }

  // Other

  // Component Render
  return (
    <FormControl {...props}>
      <Select
        value={locale}
        onChange={HandleLanguageChange}
        IconComponent={LanguageIcon}
        defaultValue={"en"}
      >
        <MenuItem value={"en"}>English</MenuItem>
        <MenuItem value={"ar"}>Arabic</MenuItem>
        <MenuItem value={"fr"}>French</MenuItem>
        <MenuItem value={"gr"}>German</MenuItem>
        <MenuItem value={"ru"}>Russian</MenuItem>
        <MenuItem value={"es"}>Espagnol</MenuItem>
        <MenuItem value={"jap"}>Japonais</MenuItem>
        <MenuItem value={"ch"}>Chinese</MenuItem>
      </Select>
      {/* <FormHelperText>Choose Language</FormHelperText> */}
    </FormControl>
  )
}

LanguageDropdown.propTypes = {
  className: PropTypes.string,
}

export default LanguageDropdown
