// React
import React, { useState, useEffect } from "react";

// Material
import { useTheme, Box, Button, Card, CardContent, Grid, Popover } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { getReportDate, getDateDurations } from "Utils";

// Components
import { DatePicker, SelectField } from "Components/FormFields";
import CountCard from "./CountCard";
import CustomerByZoneCard from "./CustomerByZoneCard";
import CustomerByCityCard from "./CustomerByCityCard";
import TopPurchasingCustomersList from "./TopPurchasingCustomersList";
import TopOrdersCountCustomersList from "./TopOrdersCountCustomersList";

// Factories

// Screens

// Assets

// Third Parties
import { useIntl } from "react-intl";
import moment from "moment";

// Services

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 497,
  },
}));

const CRMDashboard = () => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const { from, to } = getReportDate("today", "", "");
  const [dateFrom, setDateFrom] = useState(from);
  const [dateTo, setDateTo] = useState(to);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateDuration, setDateDuration] = useState("today");

  // Event Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnSelectDateDuration = (name, value) => {
    if (value) {
      setDateDuration(value);
      const { from, to } = getReportDate(value, dateFrom, dateTo);
      setDateFrom(from);
      setDateTo(to);
      if (value !== dateDuration && value !== "custom") handleClose();
    }
  };

  // Other
  const intl = useIntl();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const dateDurations = getDateDurations(intl);

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <Button aria-describedby={id} onClick={handleClick} color="primary">
          {`${moment(dateFrom).format("MMM DD, YYYY")} - ${moment(dateTo).format("MMM DD, YYYY")}`}
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Card variant="outlined">
          <CardContent>
            <Box width="240.5px">
              <SelectField
                fullWidth
                neglectNone
                name="dateDuration"
                label={<LocalizedMessage id="report.dateDuration.title" />}
                onChange={handleOnSelectDateDuration}
                value={dateDuration}
                options={dateDurations}
                variant="standard"
                margin="none"
                defaultValue={"today"}
              />
            </Box>
            {dateDuration === "custom" && (
              <Box marginTop={2}>
                <Box style={{ marginBottom: Theme.spacing(2) }}>
                  <DatePicker
                    name="dateFrom"
                    tooltip={"Date from"}
                    helpKey="common.dateFrom"
                    label={intl.formatMessage({
                      id: "common.dateFrom",
                    })}
                    onChange={(name, value) => {
                      setDateFrom(value);
                      if (dateFrom !== value) handleClose();
                    }}
                    value={dateFrom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    disabled={dateDuration !== "custom"}
                  />
                </Box>
                <Box>
                  <DatePicker
                    name="dateTo"
                    tooltip={"Date from"}
                    helpKey="common.dateTo"
                    label={intl.formatMessage({ id: "common.dateTo" })}
                    onChange={(name, value) => {
                      setDateTo(value);
                      if (dateTo !== value) handleClose();
                    }}
                    value={dateTo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                    disabled={dateDuration !== "custom"}
                  />
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </Popover>
      <CountCard dateFrom={dateFrom} dateTo={dateTo} />
      <Grid container justify="center" spacing={2} style={{ marginTop: 2 }}>
        <Grid xs={12} md={6} item>
          <CustomerByZoneCard dateFrom={dateFrom} dateTo={dateTo} />
        </Grid>
        <Grid xs={12} md={6} item>
          <CustomerByCityCard dateFrom={dateFrom} dateTo={dateTo} />
        </Grid>
        <Grid xs={12} item>
          <TopPurchasingCustomersList dateFrom={dateFrom} dateTo={dateTo} />
        </Grid>
        <Grid xs={12} item>
          <TopOrdersCountCustomersList dateFrom={dateFrom} dateTo={dateTo} />
        </Grid>
      </Grid>
    </>
  );
};
export default CRMDashboard;
