/**
 * @module State
 * @description
 * > Provides the global state initial values
 * @category Globals
 */

/**
 * @name InitialGlobalState
 * @summary Global State Initial Values
 * @constant
 * @type {object}
 * @property {object} global Global State Object for all components
 * @property {string} global.locale App current locale
 */
const InitialGlobalState = {
  heading: { heading: "" },
  global: {
    locale: "en",
    app: {
      loaded: false,
    },
  },
  userData: {
    auth: {
      loaded: false,
      token: null,
      rememberMe: false,
    },
    account: {
      firstName: null,
      lastName: null,
      email: null,
      phoneNumber: null,
      timezone: null,
      isOwner: null,
      isAdmin: null,
      brands: {
        id: "",
        name: "",
        otherName: "",
        logoPath: "",
      },
      branches: [],
      branchRoles: [],
      permissions: { isOwner: false, isAdmin: false },
      dataToShowInPortal: [],
    },
    serverStorage: {
      wizard: false,
    },
    branch: {
      id: "",
      name: "",
    },
  },
  components: {
    currentComponent: "SignIn",
    setupCurrentComponent: "cards",
    reportsCurrentComponent: "reportCards",
  },
  taxes: {
    taxes: {
      isFetchingRows: true,
      data: [],
    },
  },
  surcharges: {
    surcharges: {
      isFetchingRows: true,
      data: [],
    },
  },
  zones: {
    zones: {
      isFetchingRows: true,
      data: [],
    },
  },
  roles: {
    roles: {
      isFetchingRows: true,
      data: [],
    },
  },
  cities: {
    cities: {
      isFetchingRows: true,
      data: [],
    },
  },
  printers: {
    printers: {
      isFetchingRows: true,
      data: [],
    },
  },
  employees: {
    employees: {
      isFetchingRows: true,
      data: [],
    },
  },
  countries: {
    countries: {
      isFetchingRows: true,
      data: [],
    },
  },
  timezones: {
    timezones: {
      isFetchingRows: true,
      data: [],
    },
  },
  discounts: {
    discounts: {
      isFetchingRows: true,
      data: [],
    },
  },
  currencies: {
    currencies: {
      isFetchingRows: true,
      data: [],
    },
  },
  permissionsGroups: {
    permissionsGroups: {
      isFetchingRows: true,
      data: [],
    },
  },
  salesSections: {
    salesSections: {
      isFetchingRows: true,
      data: [],
    },
  },
  permissionsMatrix: {
    permissionsMatrix: {
      isFetchingRows: true,
      data: [],
    },
  },
  rolesPermissionsMatrix: {
    rolesPermissionsMatrix: {
      isFetchingRows: true,
      data: [],
    },
  },
  salesSectionsTypes: {
    salesSectionsTypes: {
      isFetchingRows: true,
      data: [],
    },
  },
  subscriptionsPlans: {
    subscriptionsPlans: {
      isFetchingRows: true,
      data: [],
    },
  },
};

export default InitialGlobalState;
