// React
import React from "react";
import PropTypes from "prop-types";

// Material

// Globals

// Helpers
import useLooseMemo from "Helpers/useLooseMemo";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name LooseMemo
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const LooseMemo = ({ children, deps }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return useLooseMemo(children, deps);
};

LooseMemo.propTypes = {
  /**
   *
   */
};

LooseMemo.defaultProps = {
  deps: [],
};

export default LooseMemo;
