// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";
import SortableBody from "Components/Sortable";

// Factories

// Screens
import CityForm from "./CityForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import arrayMove from "array-move";

// Services
import { useDeleteCity } from "Services/Cities/";

// Styles
const useStyles = makeStyles(() => ({
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "drag",
    label: <LocalizedMessage id="general.drag" />,
    minWidth: 20,
    align: "center",
  },
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 200,
  },
  { id: "actions", label: "", minWidth: 200 },
];

/**
 * @name Cities
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Cities = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [cities, setCities] = useGlobalState("cities.cities");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  // const [isFetchingRows, setIsFetchingRows] = React.useState(true);
  const [editId, setEditId] = React.useState("");

  /* City Form */
  const [cityFormOpen, setCityFormOpen] = useState(false);
  const [cityFormMode, setCityFormMode] = useState("");
  const [cityFormData, setCityFormData] = useState({});

  useEffect(() => {
    setRows(cities.data.map((city) => createData(city)));
  }, [cities.data]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const deleteCity = useDeleteCity();

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddCityForm = () => {
    setCityFormData({});
    setCityFormMode("add");
    setCityFormOpen(true);
  };

  const onEditClick = (city) => {
    setEditId(city.id);
    setCityFormData(city);
    setCityFormMode("edit");
    setCityFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteCity(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setCities({
          ...cities,
          data: rows.getState().filter((row) => row.id !== id),
        });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const addCallback = (response) => {
    const newData = [...rows.getState(), createData(response.data.data)];
    setCities({ ...cities, data: newData });
  };

  const editCallback = (response) => {
    const newRows = rows.getState();
    const index = newRows.findIndex((row) => row.id === editId);
    newRows.splice(index, 1, createData(response.data.data));
    setCities({ ...cities, data: newRows });
  };

  // Other
  const createData = (city) => {
    return {
      id: city.id,
      name: city.name,
      otherName: city.otherName,
      drag: "",
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.city.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(city)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.city.includes("delete")) && (
            <AlertDialog id={city.id} onAgree={onDeleteClick} />
          )} */}
        </Box>
      ),
    };
  };

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between ",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.city.pageTitle" />
        </Typography>
        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.city.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={cities.isFetchingRows}
            component={cities.isFetchingRows ? "div" : undefined}
            onClick={cities.isFetchingRows ? undefined : openAddCityForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        <CityForm
          open={cityFormOpen}
          setOpen={setCityFormOpen}
          mode={cityFormMode}
          editFormData={cityFormData}
          editCallback={editCallback}
          addCallback={addCallback}
          editId={editId}
        />
        {cities.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <SortableBody
                  data={(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  columns={columns}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    setRows((elements) => [...arrayMove(elements, oldIndex, newIndex)]);
                  }}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

Cities.propTypes = {
  /**
   *
   */
};

Cities.defaultProps = {
  /**
   *
   */
};

export default Cities;
