// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box, Typography, useTheme } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens
import ItemCard from "./ItemCard";

// Assets
import noItems from "Assets/Images/noItems.png";
// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components

/**
 * @name ItemList
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ItemList = ({ items, title, perRow }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: Theme.spacing(1),
        }}
      >
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
      </Box>
      <Grid container justify="center" spacing={2}>
        {items.length ? (
          items.map((item) => (
            <Grid key={item.id} xs={12} md={6} lg={4} item>
              <ItemCard key={item.id} item={item} style={{ height: "100%" }} />
            </Grid>
          ))
        ) : (
          <Box display="flex" alignItems="center" flexDirection="column">
            <img src={noItems} alt="No Items" />
            <Typography variant="body2" component="h2">
              <LocalizedMessage id="general.noItems" />
            </Typography>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

ItemList.propTypes = {
  /**
   *
   */
};

export default ItemList;
