// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { useTheme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import blueGrey from "@material-ui/core/colors/blueGrey";

// Globals

// Helpers
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components

// Factories

// Screens
import PieChartCard from "./PieChartCard";

// Servicies
import { useGetSalesByEmployee } from "Services/Dashboard";

// Assets

// Third Parties
import moment from "moment";
import { useSnackbar } from "notistack";
import randomcolor from "randomcolor";
import { useIntl } from "react-intl";

// Styles

// Ad-Hoc Components

/**
 * @name SalesByEmployeeCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesByEmployeeCard = ({ style, dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [salesByEmployeeData, setSalesByEmployeeData] = useState([]);
  const [empty, setEmpty] = useState(false);

  // Effect Hooks
  useEffect(() => {
    setEmpty(false);
    getSalesByEmployee(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    )
      .then((response) => {
        setSalesByEmployeeData(response);
        var empty = true;
        response.forEach((emp) => {
          if (emp.income) empty = false;
        });
        if (empty) setEmpty(true);
      })
      .catch((errors) => {
        setEmpty(true);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [dateFrom, dateTo]);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getSalesByEmployee = useGetSalesByEmployee();
  const intl = useIntl();

  // Event Handlers

  // Other
  const chartData = (theme) => ({
    labels: salesByEmployeeData.reduce((acc, currentValue) => {
      if (currentValue.income){
        return [...acc, `${currentValue.employee}: ${currentValue.income}`]
      }
      else return acc
    },[]),
    datasets: [
      {
        label: intl.formatMessage({ id: "common.income" }),
        backgroundColor: Array(salesByEmployeeData.length)
          .fill(1)
          .map((v, key) => {
            return randomcolor({ hue: "#C84493" });
          }),
        data: salesByEmployeeData.reduce((acc, currentValue) => {
          if (currentValue.income){
            return [...acc, currentValue.income]
          }
          else return acc
        },[]),
      },
    ],
  });

  const dummyData = (theme) => ({
    labels: [
      "Mohamed Ibrahim",
      "Reghu Imaa",
      "Anika Anushka",
      "Kashvi",
      "Keya",
      "Zara Aadiv",
    ],
    datasets: [
      {
        label: "Income",
        backgroundColor: Array(6)
          .fill(1)
          .map((v) => {
            return randomcolor({
              hue: "#C84493",
            });
          }),
        data: Array(6)
          .fill(1)
          .map((v, key) => {
            return Math.ceil(Math.random() * (key + 1) * 1000);
          }),
      },
    ],
  });

  // Component Render
  return (
    <>
      {empty ? (
        <PieChartCard
          blurred={true}
          title={intl.formatMessage({ id: "dashboard.salesByEmployee" })}
          pieChartData={dummyData(Theme)}
        />
      ) : (
        <PieChartCard
          title={intl.formatMessage({ id: "dashboard.salesByEmployee" })}
          pieChartData={chartData(Theme)}
        />
      )}
    </>
  );
};

SalesByEmployeeCard.propTypes = {
  /**
   *
   */
};

export default SalesByEmployeeCard;
