// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Chip,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  wrapperInner: { width: "min-content" },
}));

// Ad-Hoc Components

/**
 * @name Row
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Row = ({
  row,
  columns,
  orderItemsModifiersColumns,
  orderItemTaxDetailsColumns,
  orderItemDiscountDetailsColumns,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [open, setOpen] = useState(false);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  const returnValue = (value) => {
    return value !== null
      ? typeof value === "boolean"
        ? value
          ? "Cancelled"
          : "Not Cancelled"
        : value.name
        ? value.otherName
          ? value.name + " (" + value.otherName + ")"
          : value.name
        : value
      : "";
  };
  // Component Render
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        //  className={classes.root}
      >
        <TableCell key={row.id + "-expand"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((column) => {
          let value = row[column.id];
          if (
            column.id === "updatedAt" &&
            row["updatedAt"] === row["createdAt"]
          )
            value = "";
          else value = column.format ? column.format(value) : value;
          return (
            <TableCell key={row.id + column.id} align={column.align}>
              {column.id === "isCancelled" ? (
                value ? (
                  <Chip
                    label={
                      <LocalizedMessage id="sales.orderDetailsDialog.isCancelled" />
                    }
                    style={{ backgroundColor: "#c62828", color: "white" }}
                  />
                ) : (
                  <Chip
                    label={
                      <LocalizedMessage id="sales.orderDetailsDialog.ordered" />
                    }
                    style={{ backgroundColor: "#00c853", color: "white" }}
                  />
                )
              ) : (
                returnValue(value)
              )}
            </TableCell>
          );
        })}
      </TableRow>

      {row.orderItemTaxDetails[0] && (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id + "2"}>
          <TableCell
            key={row.id + "-orderItemTaxDetailsColumns"}
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 1}
          >
            {orderItemTaxDetailsColumns.length ? (
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                classes={{ wrapperInner: classes.wrapperInner }}
              >
                <Paper elevation={2} style={{ margin: 24, padding: 24 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    <LocalizedMessage id="sales.orderDetailsDialog.orderItemTaxDetailsColumns" />
                  </Typography>
                  <Table size="small" aria-label="orderItemTaxDetailsColumns">
                    <TableHead>
                      <TableRow>
                        {orderItemTaxDetailsColumns.map((secondaryColumn) => (
                          <TableCell key={secondaryColumn.id + "-first"}>
                            {secondaryColumn.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.orderItemTaxDetails.map((secondaryRow) => (
                        <TableRow key={secondaryRow.id}>
                          {orderItemTaxDetailsColumns.map((column, index) => {
                            let value = secondaryRow[column.id];
                            if (
                              column.id === "updatedAt" &&
                              secondaryRow["updatedAt"] ===
                                secondaryRow["createdAt"]
                            )
                              value = "";
                            else
                              value = column.format
                                ? column.format(value)
                                : value;

                            return (
                              <TableCell
                                key={column.id + "-second"}
                                align={column.align}
                              >
                                {column.id === "isCancelled" ? (
                                  value ? (
                                    <Chip
                                      label={
                                        <LocalizedMessage id="sales.orderDetailsDialog.isCancelled" />
                                      }
                                      style={{
                                        backgroundColor: "#c62828",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label={
                                        <LocalizedMessage id="sales.orderDetailsDialog.applied" />
                                      }
                                      style={{
                                        backgroundColor: "#00c853",
                                        color: "white",
                                      }}
                                    />
                                  )
                                ) : (
                                  returnValue(value)
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Collapse>
            ) : null}
          </TableCell>
        </TableRow>
      )}

      {row.orderItemDiscountDetails[0] && (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id + "2"}>
          <TableCell
            key={row.id + "-orderItemDiscountDetailsColumns"}
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 1}
          >
            {orderItemDiscountDetailsColumns.length ? (
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                classes={{ wrapperInner: classes.wrapperInner }}
              >
                <Paper elevation={2} style={{ margin: 24, padding: 24 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    <LocalizedMessage id="sales.orderDetailsDialog.orderItemDiscountDetailsColumns" />
                  </Typography>
                  <Table
                    size="small"
                    aria-label="orderItemDiscountDetailsColumns"
                  >
                    <TableHead>
                      <TableRow>
                        {orderItemDiscountDetailsColumns.map(
                          (secondaryColumn) => (
                            <TableCell key={secondaryColumn.id + "-first"}>
                              {secondaryColumn.label}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.orderItemDiscountDetails.map((secondaryRow) => (
                        <TableRow key={secondaryRow.id}>
                          {orderItemDiscountDetailsColumns.map(
                            (column, index) => {
                              let value = secondaryRow[column.id];

                              if (
                                column.id === "updatedAt" &&
                                secondaryRow["updatedAt"] ===
                                  secondaryRow["createdAt"]
                              )
                                value = "";
                              else
                                value = column.format
                                  ? column.format(value)
                                  : value;

                              return (
                                <TableCell
                                  key={column.id + "-second"}
                                  align={column.align}
                                >
                                  {column.id === "isCancelled" ? (
                                    value ? (
                                      <Chip
                                        label={
                                          <LocalizedMessage id="sales.orderDetailsDialog.isCancelled" />
                                        }
                                        style={{
                                          backgroundColor: "#c62828",
                                          color: "white",
                                        }}
                                      />
                                    ) : (
                                      <Chip
                                        label={
                                          <LocalizedMessage id="sales.orderDetailsDialog.applied" />
                                        }
                                        style={{
                                          backgroundColor: "#00c853",
                                          color: "white",
                                        }}
                                      />
                                    )
                                  ) : (
                                    returnValue(value)
                                  )}
                                </TableCell>
                              );
                            }
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Collapse>
            ) : null}
          </TableCell>
        </TableRow>
      )}

      {row.orderItemsModifiers[0] && (
        <TableRow hover role="checkbox" tabIndex={-1} key={row.id + "2"}>
          <TableCell
            key={row.id + "-orderItemsModifiersColumns"}
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={columns.length + 1}
          >
            {orderItemsModifiersColumns.length ? (
              <Collapse
                in={open}
                timeout="auto"
                unmountOnExit
                classes={{ wrapperInner: classes.wrapperInner }}
              >
                <Paper elevation={2} style={{ margin: 24, padding: 24 }}>
                  <Typography variant="h6" gutterBottom component="div">
                    <LocalizedMessage id="sales.orderDetailsDialog.orderItemsModifiers" />
                  </Typography>
                  <Table size="small" aria-label="orderItemsModifiers">
                    <TableHead>
                      <TableRow>
                        {orderItemsModifiersColumns.map((secondaryColumn) => (
                          <TableCell key={secondaryColumn.id + "-first"}>
                            {secondaryColumn.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.orderItemsModifiers.map((secondaryRow) => (
                        <TableRow key={secondaryRow.id}>
                          {orderItemsModifiersColumns.map((column, index) => {
                            let value = secondaryRow[column.id];

                            if (
                              column.id === "updatedAt" &&
                              secondaryRow["updatedAt"] ===
                                secondaryRow["createdAt"]
                            )
                              value = "";
                            else
                              value = column.format
                                ? column.format(value)
                                : value;

                            return (
                              <TableCell
                                key={column.id + "-second"}
                                align={column.align}
                              >
                                {column.id === "isCancelled" ? (
                                  value ? (
                                    <Chip
                                      label={
                                        <LocalizedMessage id="sales.orderDetailsDialog.isCancelled" />
                                      }
                                      style={{
                                        backgroundColor: "#c62828",
                                        color: "white",
                                      }}
                                    />
                                  ) : (
                                    <Chip
                                      label={
                                        <LocalizedMessage id="sales.orderDetailsDialog.applied" />
                                      }
                                      style={{
                                        backgroundColor: "#00c853",
                                        color: "white",
                                      }}
                                    />
                                  )
                                ) : (
                                  returnValue(value)
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Collapse>
            ) : null}
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

Row.propTypes = {
  /**
   *
   */
};

Row.defaultProps = {
  /**
   *
   */
};

export default Row;
