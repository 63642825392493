// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { handler } from "Helpers/Handlers";

// Components
import { TextField } from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import {
  useAddItemCancellationReason,
  useEditItemCancellationReason,
} from "Services/ItemCancellationReason/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
});

/**
 * @name CancellationReasonForm
 * @summary
 * @CancellationReason
 * @component
 * @description
 * >
 */
const CancellationReasonForm = ({
  editFormData,
  editCallback,
  addCallback,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") setFormData(_.pick(editFormData, ["name", "otherName"]));
    else setFormData({});
  }, [open]);

  // Other Hooks
  const addItemCancellationReason = useAddItemCancellationReason({
    languageKey: "setup.cancellationReason",
  });
  const editItemCancellationReason = useEditItemCancellationReason({
    languageKey: "setup.cancellationReason",
  });
  const { enqueueSnackbar } = useSnackbar();
  const itemCancellationReasonForm = useForm({
    validationSchema,
    languageKey: "setup.cancellationReason",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = itemCancellationReasonForm;

  // Event Handlers
  const onCLoseCancellationReasonForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddCancellationReason = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: itemCancellationReasonForm,
      formName: "itemCancellationReason",
      apiHandler: addItemCancellationReason,
      onClose: onCLoseCancellationReasonForm,
    });
  };

  const onSaveCancellationReason = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: itemCancellationReasonForm,
      formName: "itemCancellationReason",
      apiHandler: editItemCancellationReason,
      onClose: onCLoseCancellationReasonForm,
    });
  };

  // Other
  console.log(
    `%cCancellationReasonForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseCancellationReasonForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="setup.cancellationReason.name" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Order Cancellation Reason"}
                helpKey="Menu.cancellationReason.NameField"
                autoFocus
                fullWidth
                //maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                tooltip={"Order Cancellation Reason"}
                helpKey="Menu.cancellationReason.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddCancellationReason}
          mode={mode}
          onCLoseForm={onCLoseCancellationReasonForm}
          isSubmitting={isSubmitting}
          onSave={onSaveCancellationReason}
        />
      </DialogActions>
    </Dialog>
  );
};

CancellationReasonForm.propTypes = {
  /**
   *
   */
};

CancellationReasonForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default CancellationReasonForm;
