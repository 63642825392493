// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { useTheme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import blueGrey from "@material-ui/core/colors/blueGrey";

// Globals

// Helpers
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components

// Factories

// Screens
import PieChartCard from "./PieChartCard";

// Assets

// Third Parties
import moment from "moment";
import { useSnackbar } from "notistack";
import randomcolor from "randomcolor";
import { useIntl } from "react-intl";

// Styles

// Servicies
import { useGetSalesByCategory } from "Services/Dashboard";

// Ad-Hoc Components

/**
 * @name SalesByCategoryCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesByCategoryCard = ({ style, dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [salesByCategoryData, setSalesByCategoryData] = useState([]);
  const [empty, setEmpty] = useState(false);

  // Effect Hooks
  useEffect(() => {
    setEmpty(false);
    getSalesByCategory(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    )
      .then((response) => {
        setSalesByCategoryData(response);
        var empty = true;
        response.forEach((emp) => {
          if (emp.income) empty = false;
        });
        if (empty) setEmpty(true);
      })
      .catch((errors) => {
        setEmpty(true);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [dateFrom, dateTo]);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getSalesByCategory = useGetSalesByCategory();
  const intl = useIntl();

  // Event Handlers

  // Other
  const chartData = (theme) => ({
    labels: salesByCategoryData.reduce((acc, currentValue) => {
      if (currentValue.income){
        return [...acc, `${currentValue.category}: ${currentValue.income}`]
      }
      else return acc
    }, []),
    datasets: [
      {
        label: intl.formatMessage({ id: "common.income" }),
        backgroundColor: salesByCategoryData.map((v, key) => {
          return randomcolor({
            hue: v.color,
          });
        }),
        data: 
        salesByCategoryData.reduce((acc, currentValue) => {
          if (currentValue.income){
            return [...acc, currentValue.income]
          }
          else return acc
        }, []),
      },
    ],
  });

  const dummyData = (theme) => ({
    labels: [
      "Burger",
      "Pasta",
      "Tortilla",
      "Fries",
      "Desserts",
      "Drinks",
      "Pizza",
      "Sandwiches",
      "Rice",
    ],
    datasets: [
      {
        label: "Income",
        backgroundColor: [
          fade(pink[500], 0.7),
          fade(purple[500], 0.7),
          fade(blue[500], 0.7),
          fade(teal[500], 0.7),
          fade(lightGreen[500], 0.7),
          fade(orange[500], 0.7),
          fade(blueGrey[500], 0.7),
          fade(pink[300], 0.7),
          fade(purple[300], 0.7),
          fade(blue[300], 0.7),
          fade(teal[300], 0.7),
          fade(lightGreen[300], 0.7),
          fade(orange[300], 0.7),
          fade(blueGrey[300], 0.7),
        ],
        // borderColor: fade(theme.palette.primary.light, 0.7),
        //   borderWidth: 1,
        data: Array(9)
          .fill(1)
          .map((v, key) => {
            return Math.ceil(Math.random() * (key + 1) * 1000);
          }),
      },
    ],
  });

  // Component Render
  return (
    <>
      {empty ? (
        <PieChartCard
          blurred={true}
          title={intl.formatMessage({ id: "dashboard.salesByCategory" })}
          pieChartData={dummyData(Theme)}
        />
      ) : (
        <PieChartCard
          title={intl.formatMessage({ id: "dashboard.salesByCategory" })}
          pieChartData={chartData(Theme)}
        />
      )}
    </>
  );
};

SalesByCategoryCard.propTypes = {
  /**
   *
   */
};

export default SalesByCategoryCard;
