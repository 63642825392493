// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { VpnKey as VpnKeyIcon } from "@material-ui/icons";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import SupportText from "Components/SupportText";
import Title from "Components/Title";
import { TextField, PasswordField } from "Components/FormFields";

// Factories

// Screens

// Assets
import { ForgetPasswordVector } from "Assets/Vectors";

// Servicies
import { useForgetPassword } from "Services/Auth";

// Third Parties
import Joi from "@hapi/joi";
import { useSnackbar } from "notistack";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  vector: {
    width: "55%",
    alignSelf: "center",
    marginBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",

    "& $avatar": {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  backToLogin: {
    margin: theme.spacing(0, 0, 2),
  },
}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  email: Joi.string().email({ tlds: false }).required(),
});

/**
 * @name ForgetPasswordStep1
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ForgetPasswordStep1 = (props) => {
  // Props Destructing
  const { className, backToLoginHandler, goToStep2Handler, ...otherProps } =
    props;

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "auth.forgetPassword.step1" });
  // Effect Hooks

  // Other Hooks
  const postForgetPassword = useForgetPassword({
    languageKey: "auth.forgetPassword.step1",
  });
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const onSubmit = (e) => {
    const validForm = validateForm();
    if (validForm) {
      const { email } = formData;
      setIsSubmiting(true);
      postForgetPassword({ email })
        .then(() => {
          enqueueSnackbar(
            <LocalizedMessage id="auth.forgetPassword.step1.sendCodeSuccess" />,
            {
              variant: "success",
              autoHideDuration: 5000,
            }
          );
          goToStep2Handler(email);
          setFormData({});
          setIsSubmiting(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };
  // Other

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <Title
        pageTitle={
          <LocalizedMessage id="auth.forgetPassword.step1.pageTitle" />
        }
      />
      <img
        src={ForgetPasswordVector}
        className={classes.vector}
        alt="Forgot Password"
      />
      <Box className={classes.title}>
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <LocalizedMessage id="auth.forgetPassword.step1.title" />
        </Typography>
      </Box>
      <form className={classes.form} noValidate>
        <Typography variant="body2">
          <LocalizedMessage id="auth.forgetPassword.step1.subTitle" />
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={<LocalizedMessage id="common.emailAddress" />}
          name="email"
          autoComplete="email"
          autoFocus
          onChange={onFormFieldChange}
          value={formData.email}
          errors={formErrors.email}
          isSubmitting={isSubmitting}
          isLoading={isLoading.email}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={20} style={{ color: "white" }} />
          ) : (
            <LocalizedMessage id="auth.forgetPassword.step1.submit" />
          )}
        </Button>
        <Button
          type="button"
          fullWidth
          variant="contained"
          style={{
            color: "white",
            backgroundColor: "#0582D2",
          }}
          className={classes.backToLogin}
          onClick={backToLoginHandler}
        >
          <LocalizedMessage id="common.backToLogin" />
        </Button>
        {props.children}
        <Typography variant="caption">
          <SupportText
            issueText={
              <LocalizedMessage id="auth.forgetPassword.step1.issueText"></LocalizedMessage>
            }
          />
        </Typography>
      </form>
    </Box>
  );
};

ForgetPasswordStep1.propTypes = {
  /**
   * A function that hides the ForgetPasswordForm and show SignInForm
   */
  backToLoginHandler: PropTypes.func,
};

export default ForgetPasswordStep1;
