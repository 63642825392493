// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Material
import {
  useTheme,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";

// Components
import Row from "./ItemRow";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import { useIntl } from "react-intl";
import moment from "moment";

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 660,
    scroll: "auto",
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "menuItem",
    label: <LocalizedMessage id="sales.orderDetailsDialog.menuItem" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "isCancelled",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 150,
    format: false,
  },
  {
    id: "unitPrice",
    label: <LocalizedMessage id="common.unitPrice" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "quantity",
    label: <LocalizedMessage id="common.quantity" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "totalDiscountAmount",
    label: (
      <LocalizedMessage id="sales.orderDetailsDialog.totalDiscountAmount" />
    ),
    minWidth: 100,
    format: false,
  },
  {
    id: "totalTaxAmount",
    label: <LocalizedMessage id="sales.orderDetailsDialog.totalTaxAmount" />,
    minWidth: 100,
    format: (value) => numeral(value).format("0.00a"),
  },
  {
    id: "subTotalAmount",
    label: <LocalizedMessage id="sales.orderDetailsDialog.subTotalAmount" />,
    minWidth: 100,
    format: (value) => numeral(value).format("0.00a"),
  },
  {
    id: "grandTotalAmount",
    label: <LocalizedMessage id="sales.orderDetailsDialog.grandTotalAmount" />,
    minWidth: 100,
    format: (value) => numeral(value).format("0.00a"),
  },
  {
    id: "notes",
    label: <LocalizedMessage id="sales.orderDetailsDialog.notes" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "itemCancellationReason",
    label: (
      <LocalizedMessage id="sales.orderDetailsDialog.itemCancellationReason" />
    ),
    minWidth: 100,
    format: false,
  },
  {
    id: "creator",
    label: <LocalizedMessage id="sales.orderDetailsDialog.creator" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "createdAt",
    label: <LocalizedMessage id="common.createdAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
  {
    id: "modifier",
    label: <LocalizedMessage id="sales.orderDetailsDialog.modifier" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "updatedAt",
    label: <LocalizedMessage id="sales.orderDetailsDialog.updatedAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
];

const orderItemsModifiersColumns = [
  {
    id: "modifierGroup",
    label: <LocalizedMessage id="sales.orderDetailsDialog.modifierGroup" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "isCancelled",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 150,
    format: false,
  },
  {
    id: "modifierItem",
    label: <LocalizedMessage id="sales.orderDetailsDialog.modifierItem" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "price",
    label: <LocalizedMessage id="common.price" />,
    minWidth: 100,
    format: (value) => numeral(value).format("0.00a"),
  },
  {
    id: "quantity",
    label: <LocalizedMessage id="common.quantity" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "creator",
    label: <LocalizedMessage id="sales.orderDetailsDialog.creator" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "createdAt",
    label: <LocalizedMessage id="common.createdAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
  {
    id: "modifier",
    label: <LocalizedMessage id="sales.orderDetailsDialog.modifier" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "updatedAt",
    label: <LocalizedMessage id="sales.orderDetailsDialog.updatedAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
];

const orderItemDiscountDetailsColumns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "isCancelled",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 150,
    format: false,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "amount",
    label: <LocalizedMessage id="sales.orderDetailsDialog.amount" />,
    minWidth: 100,
    format: (value) => numeral(value).format("0.00a"),
  },
  {
    id: "creator",
    label: <LocalizedMessage id="sales.orderDetailsDialog.creator" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "createdAt",
    label: <LocalizedMessage id="common.createdAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
  {
    id: "modifier",
    label: <LocalizedMessage id="sales.orderDetailsDialog.modifier" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "updatedAt",
    label: <LocalizedMessage id="sales.orderDetailsDialog.updatedAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
];

const orderItemTaxDetailsColumns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "isCancelled",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 150,
    format: false,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "amount",
    label: <LocalizedMessage id="sales.orderDetailsDialog.amount" />,
    minWidth: 100,
    format: (value) => numeral(value).format("0.00a"),
  },
  {
    id: "creator",
    label: <LocalizedMessage id="sales.orderDetailsDialog.creator" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "createdAt",
    label: <LocalizedMessage id="common.createdAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
  {
    id: "modifier",
    label: <LocalizedMessage id="sales.orderDetailsDialog.modifier" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "updatedAt",
    label: <LocalizedMessage id="sales.orderDetailsDialog.updatedAt" />,
    minWidth: 100,
    format: (value) => moment(value).format("dddd, MMMM Do YYYY, h:mm:ss a"),
  },
];

/**
 * @name ItemTable
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ItemTable = ({ orderItems }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  // Effect Hooks

  // Other Hooks
  const intl = useIntl();
  const [inputSkeleton, inputRef] = useSkeleton("rect");

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Paper
        style={{
          marginTop: theme.spacing(2),
        }}
      >
        <Box ref={inputRef}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              {orderItems.length ? (
                <TableBody>
                  {orderItems.map((row, index) => (
                    <Row
                      key={index}
                      row={row}
                      columns={columns}
                      orderItemsModifiersColumns={orderItemsModifiersColumns}
                      orderItemTaxDetailsColumns={orderItemTaxDetailsColumns}
                      orderItemDiscountDetailsColumns={
                        orderItemDiscountDetailsColumns
                      }
                    />
                  ))}
                </TableBody>
              ) : null}
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Box>
  );
};

ItemTable.propTypes = {
  /**
   *
   */
};

ItemTable.defaultProps = {
  /**
   *
   */
};

export default ItemTable;
