import { useAxios } from "Helpers/Hooks";
import _ from "lodash";

const useGetAllItems = () => {
  const axios = useAxios({ api: true });

  return (id) =>
    new Promise(function (resolve, reject) {
      axios
        .get("/menuItems/", {
          headers: {
            categoryId: id,
          },
        })
        .then((response) => {
          const result = response.data.data.map((item) => {
            const modifierGroups = item.modifierGroups.map((modifierGroup) => {
              const modifierItems = modifierGroup.modifierItems.map(
                (modifierItem) => {
                  return {
                    id: modifierItem.id,
                    name: modifierItem.name,
                    otherName: modifierItem.otherName,
                    price: modifierItem.price,
                  };
                }
              );
              return {
                id: modifierGroup.id,
                name: modifierGroup.name,
                otherName: modifierGroup.otherName,
                maxOptions: modifierGroup.maxOptions,
                minOptions: modifierGroup.minOptions,
                allowMultipleQuantities: modifierGroup.allowMultipleQuantities,
                modifierItems,
              };
            });

            return {
              id: item.id,
              name: item.name,
              otherName: item.otherName,
              description: item.description,
              barcode: item.barcode,
              categoryId: item.categoryId,
              color: item.color,
              image: item.image,
              defaultPrice: item.defaultPrice,
              salesSections: item.salesSections,
              orderedMenuItem: item.orderedMenuItem,
              taxes: item.taxes,
              discounts: item.discounts,
              printerId: item.printerId,
              stockType: item.stockType,
              isActive: item.isActive,
              isByKilo: item.isByKilo,
              defaultStock: item.defaultStock,
              modifierGroups,
            };
          });

          resolve(result);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

export default useGetAllItems;
