// React
import React from "react";
import PropTypes from "prop-types";

// Third Party
import { FormattedMessage } from "react-intl";

/**
 * @name LocalizedMessage
 * @description Returns Localized Text
 * @component
 * @category Helpers
 */
const LocalizedMessage = (props) => (
  <FormattedMessage id={props.id} values={props.values} key={props.id}>
    {props.children}
  </FormattedMessage>
);

LocalizedMessage.propTypes = {
  /**
   * Message Translation ID Written In Dot Notation
   */
  id: PropTypes.string.isRequired,
  /**
   * Variables' Replacements of The Localized Message
   */
  values: PropTypes.object,
  /**
   * Children Component To Be Wrapped
   */
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
  ]),
};

LocalizedMessage.defaultProps = {};

export default LocalizedMessage;
