// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  useTheme,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import {
  TextField,
  AutoCompleteField,
  CheckBox,
  BranchRoles,
  PasswordField,
  PhoneField,
} from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import zxcvbn from "zxcvbn";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name AccountForm
 * @summary
 * @Account
 * @component
 * @description
 * >
 */
const AccountForm = ({
  editFormData,
  onAdd,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
  branches,
  setBranches,
  refresh,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [timezones] = useGlobalState("timezones.timezones");

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      setFormData(
        _.pick(editFormData, [
          "firstName",
          "lastName",
          "email",
          "phoneNumber",
          "password",
          // "timezone",
          "branchRoles",
          "isAdmin",
        ])
      );
    } else setFormData({});
  }, [open]);

  // Other Hooks
  const passwordValidation = (value, helpers) => {
    if (zxcvbn(value).score < 3) {
      throw new Error("password must be a strong");
    }
    return value;
  };

  const branchRoleSchema = Joi.object({
    branchId: Joi.string().uuid().required(),
    roleId: Joi.string().uuid().required(),
  });

  const validationSchema = Joi.object({
    firstName: Joi.string().required().min(1).max(100),
    lastName: Joi.string().required().min(1).max(100),
    email: Joi.string().email({ tlds: false }).required(),
    phoneNumber: Joi.number().integer().allow(null).allow(""),
    password:
      mode === "edit"
        ? Joi.string()
            .custom(passwordValidation, "password validation")
            .allow("", null)
        : Joi.string().custom(passwordValidation, "password validation"),
    // timezone: Joi.string().required().min(1).max(100),
    isAdmin: Joi.boolean(),
    branchRoles: Joi.array().when("isAdmin", {
      is: false,
      then: Joi.array().items(branchRoleSchema).required().min(1),
    }),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "account" });

  // Event Handlers
  const onCLoseAccountForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddAccount = () => {
    onAdd(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseAccountForm
    );
  };

  const onSaveAccount = () => {
    if (formData.password === "") formData.password = undefined;

    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseAccountForm
    );
  };

  // Other
  console.log(
    `%cAccountForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseAccountForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="account.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              {" "}
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />
              <b>: {formData.firstName}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.firstName" />}
                name="firstName"
                autoComplete="firstName"
                tooltip={"Account first name"}
                helpKey="account.firstNameField"
                autoFocus
                fullWidth
                //maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.firstName}
                errors={formErrors.firstName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.firstName}
              />

              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="common.lastName" />}
                name="lastName"
                autoComplete="lastName"
                tooltip={"Account last name"}
                helpKey="account.lastNameField"
                //  maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.lastName}
                errors={formErrors.lastName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.lastName}
              />
              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="common.email" />}
                name="email"
                autoComplete="new-password"
                tooltip={"Account email"}
                helpKey="account.emailField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.email}
                errors={formErrors.email}
                isSubmitting={isSubmitting}
                isLoading={isLoading.email}
              />

              <PhoneField
                required
                label={<LocalizedMessage id="auth.register.phone" />}
                name="phoneNumber"
                autoComplete="phoneNumber"
                preferredCountries={["ae"]}
                defaultCountry={"ae"}
                autoFormat={false}
                onChange={onFormFieldChange}
                value={formData.phoneNumber}
                errors={formErrors.phoneNumber}
                isSubmitting={isSubmitting}
                isLoading={isLoading.phoneNumber}
              />

              <PasswordField
                required
                fullWidth
                label={<LocalizedMessage id="common.password" />}
                name="password"
                type="password"
                tooltip={"Account password"}
                helpKey="account.passwordField"
                autoComplete="new-password"
                onChange={onFormFieldChange}
                value={formData.password}
                errors={formErrors.password}
                isSubmitting={isSubmitting}
                isLoading={isLoading.password}
                showStrength={true}
              />

              {/* <AutoCompleteField
                fullWidth
                required
                label={<LocalizedMessage id="common.timezone" />}
                name="timezone"
                tooltip={"Account Time Zone"}
                helpKey="Menu.account.NameField"
                onChange={onFormFieldChange}
                value={formData.timezone}
                errors={formErrors.timezone}
                isSubmitting={isSubmitting}
                isLoading={isLoading.timezone}
                options={timezones.data.map((timezone) => {
                  return { value: timezone.name, text: timezone.name }
                })}
              /> */}

              <CheckBox
                fullWidth
                name="isAdmin"
                tooltip={"Admin access?"}
                helpKey="common.isAdmin"
                label={<LocalizedMessage id="common.isAdmin" />}
                onChange={onFormFieldChange}
                value={formData.isAdmin}
                errors={formErrors.isAdmin}
                isSubmitting={isSubmitting}
                isLoading={isLoading.isAdmin}
              />

              <BranchRoles
                required
                name="branchRoles"
                tooltip={"Branch Roles"}
                helpKey="accounts.branchRoles"
                label={<LocalizedMessage id="account.branchRoles" />}
                onChange={onFormFieldChange}
                value={formData.branchRoles}
                // value={branchRoles}
                errors={formErrors.branchRoles}
                isSubmitting={isSubmitting}
                isLoading={isLoading.branchRoles}
                branches={branches}
                mode={mode}
                isAdmin={formData.isAdmin}
                formData={formData}
                setFormData={setFormData}
                setBranches={setBranches}
                refresh={refresh}
                permissions={permissions}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddAccount}
          mode={mode}
          onCLoseForm={onCLoseAccountForm}
          isSubmitting={isSubmitting}
          onSave={onSaveAccount}
        />
      </DialogActions>
    </Dialog>
  );
};

AccountForm.propTypes = {
  /**
   *
   */
};

AccountForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default AccountForm;
