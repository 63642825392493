// React
import React, { useEffect, useState } from "react"

// Material
import {
  Checkbox,
  Typography,
  Box,
  DialogContentText,
  useTheme,
} from "@material-ui/core/"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"

// Components

// Factories

// Screens

// Assets

// Third Parties
import uuid from "uuid"
import _ from "lodash"

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
}))

// Ad-Hoc Components

/**
 * @name PermissionsCheckBoxesTree
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const PermissionsCheckBoxesTree = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  maxCharacters,
  defaultValue,
  value,
  type,
  onChange,
  id,
  isLoading,
  showFirstError: onlyShowFirstError,
  isSubmitting,
  hidden,
  name,
  title,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  permissionsMatrix,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4()
  const newValue = value ? JSON.parse(JSON.stringify(value)) : undefined
  const newPermissionMatrix = permissionsMatrix.data
    ? JSON.parse(JSON.stringify(permissionsMatrix.data))
    : undefined
  value = value === undefined || value === null ? newPermissionMatrix : newValue

  // Theme & Style Hooks
  const classes = useStyles()
  const Theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [currentValue, setCurrentValue] = useState(value)

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  const handleCheckBox = (e, checked, node) => {
    typeof e.persist === "function" && e.persist()
    _.set(currentValue, e.target.name, checked)
    setCurrentValue({ ...currentValue })

    typeof onChange === "function" && onChange(name, currentValue)
  }

  // Other
  const label = (node, index, parent) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {node === "order" ||
        node === "employeesCashes" ||
        node === "tables" ||
        node === "branchActions" ||
        node === "payments" ||
        node === "create" ||
        node === "edit" ||
        node === "discounts" ? (
          <Typography variant="body2" style={{ padding: 11 }}>
            {<LocalizedMessage id={`permissionsNames.${node}`} />}
          </Typography>
        ) : (
          <>
            <Checkbox
              id={`checkbox-${index}`}
              name={`${parent}.${node}`}
              checked={_.get(currentValue, `${parent}.${node}`)}
              onChange={(e, checked) => handleCheckBox(e, checked, node)}
              onClick={(e) => e.stopPropagation()}
            />
            <Typography variant="body2">
              {<LocalizedMessage id={`permissionsNames.${node}`} />}
            </Typography>
          </>
        )}
    </div>
  )

  const renderTree = (node, index, parent) => (
    <TreeItem key={index} nodeId={index} label={label(node[0], index, parent)}>
      {typeof node[1] === "object" && node[1] !== null
        ? Object.entries(node[1]).map((role, i) =>
          renderTree(
            role,
            `${role[0]}-${i}`,
            parent ? `${parent}.${node[0]}` : node[0]
          )
        )
        : null}
    </TreeItem>
  )

  // Component Render
  return (
    <Box display="flex">
      <Box flex={1}>
        <DialogContentText>
          <Typography component="span">
            <LocalizedMessage id="permissionsNames.pageTitle" />
          </Typography>
        </DialogContentText>
        <TreeView
          className={classes.root}
          defaultExpanded={Object.keys(newPermissionMatrix).map(
            (el, index) => `${el}-${index}`
          )}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {Object.entries(newPermissionMatrix).map((role, index) =>
            renderTree(role, `${role[0]}-${index}`, "")
          )}
        </TreeView>
      </Box>
    </Box>
  )
}

PermissionsCheckBoxesTree.propTypes = {
  /**
   *
   */
}

PermissionsCheckBoxesTree.defaultProps = {
  /**
   *
   */
  variant: "outlined",
  margin: "normal",
  //   autoComplete: "off",
  showFirstError: true,
  isLoading: false,
  autoFocus: false,
  onChange: () => { },
  fullWidth: true,
}

export default PermissionsCheckBoxesTree
