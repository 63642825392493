// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { FormControl, Divider, useTheme } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import Title from "Components/Title";
import ImagePreloader from "Components/ImagePreloader";
import Box from "Components/Box";
import Typography from "Components/Typography";
import { TextField, PasswordField, PhoneField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useHistory } from "react-router-dom";
import zxcvbn from "zxcvbn";

// Services
import { useEditUserMe } from "Services/User/";
import { useGetAllTimezones } from "Services/Timezones/";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexGrow: 1,
    },
    formContainer: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flex: 3,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  };
});

// Ad-Hoc Components
const passwordValidation = (value, helpers) => {
  if (zxcvbn(value).score < 3) {
    throw new Error("password must be a strong");
  }
  return value;
};
const validationSchema = Joi.object({
  firstName: Joi.string().required().min(1).max(100),
  lastName: Joi.string().required().min(1).max(100),
  password: Joi.string().custom(passwordValidation, "password validation"),
  phoneNumber: Joi.number().integer().allow(null).allow(""),
  email: Joi.allow(null).allow(""),
  // timezone: Joi.string().required().min(1).max(100),
});

/**
 * @name EditProfile
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const EditProfile = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [accountState, setAccountState] = useGlobalState("userData.account");
  const [heading, setHeading] = useGlobalState("heading.heading");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false);
  const [timezones, setTimezones] = useState([]);

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <AccountCircleIcon
            style={{ margin: "0px 16px", width: 20, height: 20 }}
          />
          <LocalizedMessage
            id="editProfile.pageTitle"
            key="editProfile.pageTitle"
          />
        </Box>
      ),
    []
  );

  useEffect(() => {
    setFormData({
      firstName: accountState.firstName,
      lastName: accountState.lastName,
      password: accountState.password,
      phoneNumber: accountState.phoneNumber,
      email: accountState.email,
      // timezone: accountState.timezone,
    });
  }, [timezones]);

  useEffect(() => {
    getAllTimezones()
      .then((zones) => {
        setTimezones(() =>
          zones.map((sc) => ({ value: sc.name, text: sc.name }))
        );
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  // const [inputSkeleton, inputRef] = useSkeleton("rect");
  let history = useHistory();
  const editProfile = useEditUserMe({ languageKey: "editProfile" });
  const getAllTimezones = useGetAllTimezones();
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "editProfile" });

  // Event Handlers
  const onCloseProfileForm = () => {
    history.replace("/portal/dashboard");
  };

  const onEditProfileHandler = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const { firstName, lastName, phoneNumber, password, timezone } = formData;
      editProfile({
        firstName,
        lastName,
        phoneNumber,
        password,
        timezone,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          setAccountState(response.data.data);
          setIsSubmiting(false);
          onCloseProfileForm();
          setFormData({});
        })
        .catch((errors) => {
          console.log(errors);
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);

          setIsSubmiting(false);
        });
    }
  };

  // Other

  // Component Render
  return (
    <>
      <Box className={classes.root}>
        <Title
          pageTitle={
            <LocalizedMessage
              id="editProfile.pageTitle"
              key="editProfile.pageTitle"
            />
          }
        />

        <Box className={classes.formContainer}>
          <Paper
            dir={theme.direction}
            style={{
              padding: theme.spacing(2),
              marginBottom: "20",
            }}
            elevation={2}
            square
          >
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              <LocalizedMessage id="editProfile.pageTitle" />
            </Typography>

            <Divider style={{ marginTop: 10 }} />

            <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  label={<LocalizedMessage id="common.firstName" />}
                  name="firstName"
                  autoComplete="firstName"
                  tooltip={"Account first name"}
                  helpKey="Menu.Account.firstNameField"
                  autoFocus
                  fullWidth
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.firstName}
                  errors={formErrors.firstName}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.firstName}
                />
                <TextField
                  required
                  fullWidth
                  label={<LocalizedMessage id="common.lastName" />}
                  name="lastName"
                  tooltip={"Account last name"}
                  helpKey="Menu.Account.lastNameField"
                  //maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.lastName}
                  errors={formErrors.lastName}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.lastName}
                />
                <TextField
                  required
                  label={<LocalizedMessage id="common.email" />}
                  name="email"
                  autoComplete="email"
                  tooltip={"Account email address"}
                  helpKey="Menu.Account.emailField"
                  fullWidth
                  disabled
                  // maxCharacters={50}
                  // onChange={onFormFieldChange}
                  value={formData.email}
                  // errors={formErrors.email}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.email}
                />
                <PhoneField
                  label={<LocalizedMessage id="auth.register.phone" />}
                  name="phoneNumber"
                  autoComplete="phoneNumber"
                  preferredCountries={["ae"]}
                  defaultCountry={"ae"}
                  autoFormat={false}
                  onChange={onFormFieldChange}
                  value={formData.phoneNumber}
                  errors={formErrors.phoneNumber}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.phoneNumber}
                />
                <PasswordField
                  required
                  fullWidth
                  label={<LocalizedMessage id="common.password" />}
                  name="password"
                  type="password"
                  tooltip={"Account password"}
                  helpKey="account.passwordField"
                  InputProps={{
                    autoComplete: "newPassword",
                  }}
                  autoComplete="newPassword"
                  onChange={onFormFieldChange}
                  value={formData.password}
                  errors={formErrors.password}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.password}
                  showStrength={true}
                />

                {/* <SelectField
                  fullWidth
                  label={<LocalizedMessage id="common.timezone" />}
                  name="timezone"
                  tooltip={"Account Time Zone"}
                  helpKey="Menu.Account.TimezoneField"
                  //maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.timezone}
                  errors={formErrors.timezone}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.timezone}
                  options={timezones}
                /> */}
              </FormControl>
            </form>
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <FormActions
                onCLoseForm={onCloseProfileForm}
                isSubmitting={isSubmitting}
                mode="edit"
                onSave={onEditProfileHandler}
              />

              {/* <Button onClick={onCloseProfileForm} color="secondary">
                <LocalizedMessage id="general.cancel" />
              </Button>

              <Button
                type="button"
                color="primary"
                disabled={isSubmitting}
                onClick={onEditProfileHandler}
              >
                {isSubmitting ? (
                  <CircularProgress size={20} />
                ) : (
                  <LocalizedMessage id="general.edit" />
                )}
              </Button> */}
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

EditProfile.propTypes = {
  /**
   *
   */
};

EditProfile.defaultProps = {
  /**
   *
   */
};

export default EditProfile;
