// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  FormControl,
  Button,
  CircularProgress,
  Box,
  Paper,
  Typography,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import useSkeleton from "Helpers/useSkeleton";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { ColorPickerField } from "Components/FormFields";
import Title from "Components/Title";
import QRDialog from "Components/QRDialog";

// Factories

// Screens
import { BuilderTemplate } from "Screens/BuilderTemplate";

// Assets

// Third Parties
import _ from "lodash";
import Joi from "@hapi/joi";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";

// Services
import { useGetOnlineOrders, useEditOnlineOrders } from "Services/OnlineOrders";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
    paper: {
      display: "flex",
      flexWrap: "wrap",
      margin: 8,
      width: "100%",
    },
    formContainer: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  };
});

// Ad-Hoc Components
const validationSchema = Joi.object({
  primaryBackgroundColor: Joi.string().required(),
  primaryTextColor: Joi.string().required(),
  backgroundColor: Joi.string().required(),
  secondaryBackgroundColor: Joi.string().required(),
  secondaryTextColor: Joi.string().required(),
});

const { REACT_APP_QRLINK } = process.env;

/**
 * @name EditOnlineOrders
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const EditOnlineOrders = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [branch] = useGlobalState("userData.branch");
  const [accountState] = useGlobalState("userData.account");

  // State Hooks

  const [QRData, setQRData] = useState(
    `${REACT_APP_QRLINK}${accountState.brands[0].name.replace(
      /\s/g,
      ""
    )}/getstarted/${jwt.sign({ brandId: branch.brandId }, "1234")}`
  );
  const [openQRDialog, setOpenQRDialog] = useState(false);
  const [isFetchingOnlineOrders, setIsFetchingOnlineOrders] = useState(true);

  // Effect Hooks
  // useEffect(
  //   () =>
  //     setHeading(
  //       <LocalizedMessage
  //         id="onlineOrders.pageTitle"
  //         key="onlineOrders.pageTitle"
  //       />
  //     ),
  //   []
  // )

  useEffect(() => {
    getOnlineOrders()
      .then((onlineOrders) => {
        console.log("onlineOrders", onlineOrders);
        if (!onlineOrders)
          setFormData({
            primaryBackgroundColor: "#1AA6B7",
            primaryTextColor: "#FFFFFF",
            backgroundColor: "#D9ECF2",
            secondaryBackgroundColor: "#F56A79",
            secondaryTextColor: "#200E39",
          });
        else
          setFormData(
            _.pick(onlineOrders, [
              "primaryBackgroundColor",
              "primaryTextColor",
              "backgroundColor",
              "secondaryBackgroundColor",
              "secondaryTextColor",
            ])
          );
        setIsFetchingOnlineOrders(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getOnlineOrders = useGetOnlineOrders();
  const editEditOnlineOrders = useEditOnlineOrders({
    languageKey: "onlineOrders",
  });
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "onlineOrders" });

  // Event Handlers
  const onCloseEditOnlineOrdersForm = () => {
    history.replace("/portal/dashboard");
  };

  const onEditOnlineOrdersHandler = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const {
        primaryBackgroundColor,
        primaryTextColor,
        backgroundColor,
        secondaryBackgroundColor,
        secondaryTextColor,
      } = formData;
      editEditOnlineOrders({
        primaryBackgroundColor,
        primaryTextColor,
        backgroundColor,
        secondaryBackgroundColor,
        secondaryTextColor,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");
          setIsSubmiting(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("accountState", accountState);

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage
            id="onlineOrders.pageTitle"
            key="onlineOrders.pageTitle"
          />
        }
      />

      <Box
        dir={theme.direction}
        style={{
          position: "relative",
        }}
      >
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "16px 8px",
          }}
          variant="h6"
          component="h2"
        >
          <LocalizedMessage id="onlineOrders.pageTitle" />
        </Typography>
        <Box>
          <Paper className={classes.paper} elevation={2} ref={inputRef}>
            <Box className={classes.root}>
              {isFetchingOnlineOrders && inputSkeleton ? (
                inputSkeleton
              ) : (
                <Box className={classes.formContainer}>
                  <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                      <Box
                        display="flex"
                        flexDirection={downSm ? "column" : "row"}
                      >
                        <Box flex={1} marginRight={3}>
                          <ColorPickerField
                            required
                            fullWidth
                            label={
                              <LocalizedMessage id="onlineOrders.primaryBackgroundColor" />
                            }
                            name="primaryBackgroundColor"
                            tooltip={
                              "Color of the onlineOrders when displayed at the POS and Reports"
                            }
                            helpKey="Menu.onlineOrders.ColorField"
                            value={formData.primaryBackgroundColor}
                            onChange={onFormFieldChange}
                            errors={formErrors.primaryBackgroundColor}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.primaryBackgroundColor}
                          />
                          <ColorPickerField
                            required
                            fullWidth
                            label={
                              <LocalizedMessage id="onlineOrders.primaryTextColor" />
                            }
                            name="primaryTextColor"
                            tooltip={
                              "Color of the onlineOrders when displayed at the POS and Reports"
                            }
                            helpKey="Menu.onlineOrders.ColorField"
                            value={formData.primaryTextColor}
                            onChange={onFormFieldChange}
                            errors={formErrors.primaryTextColor}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.primaryTextColor}
                          />
                          <ColorPickerField
                            required
                            fullWidth
                            label={
                              <LocalizedMessage id="onlineOrders.secondaryBackgroundColor" />
                            }
                            name="secondaryBackgroundColor"
                            tooltip={
                              "Color of the onlineOrders when displayed at the POS and Reports"
                            }
                            helpKey="Menu.onlineOrders.ColorField"
                            value={formData.secondaryBackgroundColor}
                            onChange={onFormFieldChange}
                            errors={formErrors.secondaryBackgroundColor}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.secondaryBackgroundColor}
                          />
                          <ColorPickerField
                            required
                            fullWidth
                            label={
                              <LocalizedMessage id="onlineOrders.secondaryTextColor" />
                            }
                            name="secondaryTextColor"
                            tooltip={
                              "Color of the onlineOrders when displayed at the POS and Reports"
                            }
                            helpKey="Menu.onlineOrders.ColorField"
                            value={formData.secondaryTextColor}
                            onChange={onFormFieldChange}
                            errors={formErrors.secondaryTextColor}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.secondaryTextColor}
                          />
                          <ColorPickerField
                            required
                            fullWidth
                            label={
                              <LocalizedMessage id="onlineOrders.backgroundColor" />
                            }
                            name="backgroundColor"
                            tooltip={
                              "Color of the onlineOrders when displayed at the POS and Reports"
                            }
                            helpKey="Menu.onlineOrders.ColorField"
                            value={formData.backgroundColor}
                            onChange={onFormFieldChange}
                            errors={formErrors.backgroundColor}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.backgroundColor}
                          />
                        </Box>
                        <Box
                          flex={1}
                          marginRight={3}
                          overflow="auto"
                          maxHeight={"50vh"}
                        >
                          <BuilderTemplate {...formData} />
                        </Box>
                      </Box>
                    </FormControl>
                  </form>
                </Box>
              )}
            </Box>
          </Paper>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || isFetchingOnlineOrders}
              onClick={onEditOnlineOrdersHandler}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.edit" />
              )}
            </Button>

            <Button
              type="button"
              color="primary"
              disabled={isSubmitting || isFetchingOnlineOrders}
              onClick={() => {
                setOpenQRDialog(true);
              }}
            >
              <LocalizedMessage id="general.preview" />
            </Button>
          </Box>
        </Box>
      </Box>
      {
        <QRDialog
          qrData={QRData}
          open={openQRDialog}
          onCloseDialog={() => setOpenQRDialog(false)}
          openLink={QRData}
        />
      }
    </Box>
  );
};

EditOnlineOrders.propTypes = {
  /**
   *
   */
};

EditOnlineOrders.defaultProps = {
  /**
   *
   */
};

export default EditOnlineOrders;
