// React
import React, { useRef } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import QRCode from "qrcode.react";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import { useReactToPrint } from "react-to-print";
import { Link } from "react-router-dom";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name QRDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const QRDialog = ({ qrData, open, onCloseDialog, driverOrPOS }) => {
  // Theme & Style Hooks
  const theme = useTheme();
  console.log("driverOrPOS", driverOrPOS);

  // Global State Hooks
  const [branch] = useGlobalState("userData.branch");
  // State Hooks

  // Effect Hooks

  // Other Hooks
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Event Handlers

  // Other
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle id="alert-dialog-title">
        {<LocalizedMessage id="QrDialog.pageTitle" />}
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent
        style={{
          display: "flex",
          flex: 1,
          flexDirection: downSm ? "column" : "row",
          // alignItems: "center",
          justifyContent: "space-around",
          marginTop: "72px",
        }}
      >
        <Box display="flex" flexDirection="column" margin="8px">
          <Typography variant="subtitle2" style={{ margin: 16 }}>
            <LocalizedMessage id="POS.toActivate" />
          </Typography>
          <Typography variant="body1" style={{ margin: 16 }}>
            <LocalizedMessage id="POS.step1" />
          </Typography>
          <Typography variant="body1" style={{ margin: 16 }}>
            <LocalizedMessage id="POS.step2" />
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column">
          {qrData && driverOrPOS === "POS" && (
            <>
              <QRCode
                style={{ alignSelf: "center" }}
                value={qrData}
                size={downSm ? 300 : 400}
              />
              <Typography
                variant="body1"
                style={{ margin: 16, alignSelf: "center" }}
              >
                <LocalizedMessage id="POS.tokenMessage" />
              </Typography>
              <Typography
                variant="h6"
                style={{ margin: 16, alignSelf: "center" }}
              >
                <LocalizedMessage id="POS.token" />:{" "}
                {JSON.parse(qrData).tempCode}
              </Typography>
            </>
          )}
          {driverOrPOS === "driver" && (
            <QRCode
              style={{ alignSelf: "center" }}
              value={branch.id}
              size={downSm ? 300 : 400}
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <FormActions
          onCLoseForm={onCloseDialog}
          isSubmitting={false}
          action={<LocalizedMessage id="common.print" />}
          onAction={handlePrint}
          isClose
        />
        {/* <Button
          style={{
            backgroundColor: "#0582D2",
            color: "#fff",
            marginRight: "8px",
          }}
          variant="contained"
          autoFocus
          onClick={onCloseDialog}
        >
          <LocalizedMessage id="common.close" />
        </Button>
        <Button
          onClick={handlePrint}
          variant="contained"
          color="primary"
          //startIcon={<PrintIcon />}
        >
          <LocalizedMessage id="common.print" />
        </Button> */}

        <Box
          displayPrint="block"
          style={{
            // display: 'flex',
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "none",
          }}
        >
          <ComponentToPrint
            branch={branch}
            qrData={qrData}
            downSm={downSm}
            ref={componentRef}
            driverOrPOS={driverOrPOS}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

QRDialog.propTypes = {
  /**
   *
   */
};

QRDialog.defaultProps = {
  /**
   *
   */
};

export default QRDialog;
class ComponentToPrint extends React.Component {
  render() {
    const { branch, qrData, downSm, driverOrPOS } = this.props;
    return (
      <Box display="flex" flexDirection="column" m={3}>
        <Typography variant="h3" component="h3" style={{ margin: 20 }}>
          <LocalizedMessage id="QrDialog.pageTitle" />
        </Typography>
        <Divider />
        {qrData && driverOrPOS === "POS" && (
          <Box
            alignSelf="center"
            m={15}
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <QRCode style={{}} value={qrData} size={downSm ? 300 : 500} />
            <Typography variant="h4" style={{ margin: 16 }}>
              <LocalizedMessage id="POS.token" />: {JSON.parse(qrData).token}
            </Typography>
          </Box>
        )}
        {driverOrPOS === "driver" && (
          <Box
            alignSelf="center"
            m={15}
            alignItems="center"
            display="flex"
            flexDirection="column"
          >
            <QRCode
              style={{ alignSelf: "center" }}
              value={branch.id}
              size={downSm ? 300 : 400}
            />
          </Box>
        )}
      </Box>
    );
  }
}
