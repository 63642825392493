// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  FormControl,
  useMediaQuery,
  useTheme,
  Divider,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import {
  displaySnackbarErrors,
  displayenqueueSnackbar,
  displaySnackbarFormErrors,
} from "Utils/displaySnackbarErrors";
import payloads from "Helpers/Handlers/payloads";

// Components
import Title from "Components/Title";
import ImagePreloader from "Components/ImagePreloader";
import {
  TextField,
  AutoCompleteField,
  PhoneField,
} from "Components/FormFields";
import Box from "Components/Box";
import Typography from "Components/Typography";
import Image from "Components/Image";

// Factories

// Utils
import { throttle } from "Utils";

// Screens

// Assets
import { AccountVector } from "Assets/Vectors";

// Third Parties
import Joi from "@hapi/joi";
import { useSnackbar } from "notistack";
import moment from "moment-timezone";

// Services
import { useGetAllTimezones } from "Services/Timezones/";
import { useAddAccount, useGetAccount } from "Services/Wizard/Accounts";
import { useAddStorage } from "Services/Storage";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  },
  vectorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  vectorWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(3) },
    [theme.breakpoints.up("sm")]: { padding: theme.spacing(7) },
  },
  vector: {
    maxHeight: "200px",
    maxWidth: "100%",
  },
  formContainer: {
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
      flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(7),
      flexDirection: "column",
    },
    height: "100%",
    display: "flex",
    // flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    display: "flex",
  },
  formControl: {
    display: "flex",
    flex: 1,
    width: "100%",
  },
}));

// Ad-Hoc Components

// Other
const validationSchema = Joi.object({
  firstName: Joi.string().required().min(1).max(100),
  lastName: Joi.string().required().min(1).max(100),
  phoneNumber: Joi.number().integer().allow(null).allow(""),
  // timezone: Joi.string().required().min(1).max(100),
});

const timeZoneList = moment.tz
  .names()
  .map((v, k) => {
    const offset = String(moment().tz(v).format("ZZ"));
    const offsetFormatted = offset.slice(0, 3) + ":" + offset.slice(3);
    return {
      value: v,
      offset: offset,
      text: `${v.replace("_", " ")} (UTC${offsetFormatted})`,
    };
  })
  .sort((a, b) => {
    const getOffsetValue = (offset) => {
      return parseFloat(offset.slice(0, 3) + "." + offset.slice(3));
    };

    return getOffsetValue(a.offset) - getOffsetValue(b.offset);
  });

const userTimeZone = moment.tz.guess();

/**
 * @name AccountSetup
 * @summary AccountSetup Component
 * @category
 * @component
 * @description
 * >
 */
const AccountSetup = (props) => {
  // Props Destructing
  const {
    className,
    setDirty,
    setIsReady,
    setStepSubmittingCallback,
    ...otherProps
  } = props;

  // Local Storage Fetch

  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);
  const [timezones, setTimezones] = useState([]);
  const [isFetchingAccount, setIsFetchingAccount] = useState(true);
  const [isFetchingTimezones, setIsFetchingTimezones] = useState(true);

  // Other Hooks
  const addStorage = useAddStorage();
  const getAllTimezones = useGetAllTimezones();
  const addAccount = useAddAccount({ languageKey: "wizard.accountSetup" });
  const getAccount = useGetAccount();

  const { enqueueSnackbar } = useSnackbar();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "wizard.accountSetup" });

  // Effect Hooks
  useEffect(() => {
    setStepSubmittingCallback(() => onSubmitHandler);
  }, [formData]);

  useEffect(() => {
    if (!isFetchingTimezones && !isImageLoading && !isFetchingAccount) {
      setIsScreenLoaded(true);
      setIsReady(true);
    }
  }, [isImageLoading, isFetchingAccount, isFetchingTimezones]);

  useEffect(() => {
    setIsFetchingAccount(true);

    getAccount()
      .then((response) => {
        setFormData(response);
        setIsFetchingAccount(false);
      })
      .catch((errors) => {
        setIsFetchingAccount(false);
      });
  }, []);

  useEffect(() => {
    getAllTimezones()
      .then((zones) => {
        setTimezones(() =>
          zones.map((sc) => ({ value: sc.name, text: sc.name }))
        );
        setIsFetchingTimezones(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Event Handlers
  const addStorageHandler = (value) => {
    addStorage({ key: "wizard", value: value })
      .then((response) => console.log(response))
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
      });
  };

  const onSubmitHandler = throttle(({ onCalled, onSuccess, onCancel }) => {
    const validForm = validateForm();

    if (validForm) {
      setIsSubmiting(true);
      onCalled();
      const payload = payloads["accountSetup"](formData);
      addAccount(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          addStorageHandler("branch");
          setIsSubmiting(false);
          onSuccess();
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);

          setIsSubmiting(false);
          onCancel();
        });
    }
  }, 1000);

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("xs"));

  // Component Render
  return (
    <>
      <Box
        className={[classes.root, className].join(" ")}
        style={{ flexDirection: downSm ? "column" : "row" }}
        {...otherProps}
      >
        <Title
          pageTitle={<LocalizedMessage id="wizard.accountSetup.pageTitle" />}
        />

        {!downSm && (
          <>
            <Box className={classes.vectorContainer}>
              <Box className={classes.vectorWrapper}>
                <Image
                  src={AccountVector}
                  className={classes.vector}
                  estimatedHeight={200}
                  estimatedWidth={200}
                  isLoading={!isScreenLoaded}
                />
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem />
          </>
        )}

        {downSm && (
          <>
            <Box className={classes.formContainer}>
              <Typography
                component="h1"
                variant="h6"
                // position="left"
                isLoading={!isScreenLoaded}
              >
                <LocalizedMessage id="wizard.accountSetup.fillInYourAccountDetails" />
              </Typography>
            </Box>
            <Divider variant="middle" />
            <Box className={classes.vectorContainer}>
              <Box className={classes.vectorWrapper}>
                <Image
                  src={AccountVector}
                  className={classes.vector}
                  estimatedHeight={200}
                  estimatedWidth={200}
                  isLoading={!isScreenLoaded}
                />
              </Box>
            </Box>
          </>
        )}

        <Box className={classes.formContainer}>
          {!downSm && (
            <Typography
              component="h1"
              variant="h6"
              position="left"
              isLoading={!isScreenLoaded}
            >
              <LocalizedMessage id="wizard.accountSetup.fillInYourAccountDetails" />
            </Typography>
          )}
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <TextField
                required
                label={<LocalizedMessage id="common.firstName" />}
                name="firstName"
                autoFocus
                onChange={onFormFieldChange}
                value={formData.firstName}
                errors={formErrors.firstName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                tooltip={"Your restaurant, cafe or bar firstName"}
                helpKey="common.firstName"
                //maxCharacters={50}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
              <TextField
                required
                label={<LocalizedMessage id="common.lastName" />}
                name="lastName"
                autoComplete="lastName"
                onChange={onFormFieldChange}
                value={formData.lastName}
                errors={formErrors.lastName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                tooltip={"Your restaurant, cafe or bar lastName"}
                helpKey="common.lastName"
                // maxCharacters={50}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
              <PhoneField
                label={<LocalizedMessage id="auth.register.phone" />}
                name="phoneNumber"
                autoComplete="phoneNumber"
                preferredCountries={["ae"]}
                defaultCountry={"ae"}
                autoFormat={false}
                onChange={onFormFieldChange}
                value={formData.phoneNumber}
                errors={formErrors.phoneNumber}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              {/* <AutoCompleteField
                required
                label={"Timezones"}
                name="timezone"
                onChange={onFormFieldChange}
                value={formData.timezone}
                errors={formErrors.timezone}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                tooltip={"Your restaurant, cafe or bar timezone"}
                helpKey="common.timezone"
                // maxCharacters={50}
                options={timezones}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              /> */}
            </FormControl>
          </form>
        </Box>
      </Box>

      <ImagePreloader
        onLoaded={() => setIsImageLoading(false)}
        onTimeout={() => setIsImageLoading(false)}
        images={[AccountVector]}
      />
    </>
  );
};

AccountSetup.propTypes = {
  /**
   *
   */
};

export default AccountSetup;
