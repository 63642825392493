// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  useMediaQuery,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
  CircularProgress,
  useTheme,
  Divider,
} from "@material-ui/core";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import {
  TextField,
  SelectField,
  ColorPickerField,
  ImageField,
  CheckBox,
  RadioBox,
  SalesSectionsPrices,
} from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens
import PrinterForm from "Screens/Portal/Printers/PrinterForm";
import DiscountForm from "Screens/Portal/Discounts/DiscountForm";
import TaxForm from "Screens/Portal/Taxes/TaxForm";
import ModifierGroup from "./Modifiers/ModifierGroup";

// Assets
import { uploadItem } from "Assets/Vectors";

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import { useAddItem, useEditItem } from "Services/Items/";
import useGetAllPrinterTypes from "Services/PrinterTypes/useGetAllPrinterTypes";
import {
  useGetAllTaxesDropdowns,
  useGetAllDiscountsDropdowns,
  useGetAllPrintersDropdowns,
} from "Services/Dropdowns/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name ItemForm
 * @summary
 * @item
 * @component
 * @description
 * >
 */
const ItemForm = ({
  onCancel,
  mode,
  setMode,
  open,
  setOpen,
  selectedCategory,
  editFormData,
  editCallback,
  addCallback,
  openItemCategoryCalled,
  setOpenItemCategoryCalled,
  permissions,
  editId,
  setEditId,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [printers, setPrinters] = useGlobalState("printers.printers");
  const [discounts, setDiscounts] = useGlobalState("discounts.discounts");
  const [taxes, setTaxes] = useGlobalState("taxes.taxes");

  // State Hooks
  const [openPrinterForm, setOpenPrinterForm] = useState(false);
  const [openDiscountForm, setOpenDiscountForm] = useState(false);
  const [openTaxForm, setOpenTaxForm] = useState(false);
  const [printerTypes, setPrinterTypes] = useState([]);
  const [isFetchingPrinterTypes, setIsFetchingPrinterTypes] = useState(true);

  // Effect Hooks
  useEffect(() => {
    // setOpen(true)
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      editFormData = {
        ...editFormData,
        taxes: editFormData.taxes.map((tax) => tax.id),
        discounts: editFormData.discounts.map((discount) => discount.id),
      };
      setEditId(editFormData.id);
      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "description",
          "barcode",
          "categoryId",
          "color",
          "defaultPrice",
          "salesSections",
          "taxes",
          "discounts",
          "modifierGroups",
          "printerId",
          "stockType",
          "isActive",
          "isByKilo",
          "image",
          "defaultStock",
        ])
      );
    } else
      setFormData((formData) => {
        return { ...formData, isActive: true };
      });
  }, [open]);

  useEffect(() => {
    getAllPrinterTypes()
      .then((type) => {
        setPrinterTypes(() => type.map((sc) => ({ value: sc.id, text: sc.id })));
        setIsFetchingPrinterTypes(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  useEffect(() => {
    if (!printers.isFetchingRows && !printers.data.length)
      getAllPrintersDropdowns()
        .then((res) => {
          setPrinters({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [printers.isFetchingRows]);

  useEffect(() => {
    if (!discounts.isFetchingRows && !discounts.data.length)
      getAllDiscountsDropdowns()
        .then((res) => {
          setDiscounts({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [discounts.isFetchingRows]);

  useEffect(() => {
    if (!taxes.isFetchingRows && !taxes.data.length)
      getAllTaxesDropdowns()
        .then((res) => {
          setTaxes({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [taxes.isFetchingRows]);

  useEffect(() => {
    if (openItemCategoryCalled) openAddItemForm(selectedCategory);
  }, [openItemCategoryCalled]);

  // Other Hooks
  const editItem = useEditItem({ languageKey: "item" });
  const addItem = useAddItem({ languageKey: "item" });
  const { enqueueSnackbar } = useSnackbar();
  const getAllPrinterTypes = useGetAllPrinterTypes();
  const getAllTaxesDropdowns = useGetAllTaxesDropdowns();
  const getAllPrintersDropdowns = useGetAllPrintersDropdowns();
  const getAllDiscountsDropdowns = useGetAllDiscountsDropdowns();

  // const modifierItemsSchema = Joi.object({
  //   name: Joi.string().required().min(1).max(100),
  //   defaultPrice: Joi.number().min(0).allow(null, ""),
  // })

  // const modifierGroupsSchema = Joi.object({
  //   name: Joi.string().required().min(1).max(100),
  //   otherName: Joi.string().allow(null, "").min(1).max(100),
  //   minOptions: Joi.number().min(1),
  //   maxOptions: Joi.number().min(1),
  //   allowMultipleQuantities: Joi.boolean(),
  //   modifierItems: Joi.array().items(modifierItemsSchema).min(1),
  // })
  const SalesSectionsPricesSchema = Joi.object({
    salesSectionId: Joi.string().uuid().allow(null, ""),
    price: Joi.number().min(0).allow(null, ""),
  });

  const validationSchema = Joi.object({
    name: Joi.string().required().min(1).max(100),
    otherName: Joi.string().allow(null, "").min(1).max(100),
    description: Joi.string().allow(null, "").min(1),
    barcode: Joi.string().allow(null, ""),
    categoryId: Joi.string().guid().required(),
    color: Joi.string().required(),
    image: Joi.alternatives(Joi.string().allow(null, ""), Joi.object()),
    defaultPrice: Joi.number().min(0).allow(null, ""),
    salesSections: Joi.array().items(SalesSectionsPricesSchema).unique(),
    isByKilo: Joi.boolean(),
    isActive: Joi.boolean(),
    taxes: Joi.array().items(Joi.string().uuid()),
    discounts: Joi.array().items(Joi.string().uuid()),
    printerId: Joi.string().allow(null, ""),
    modifierGroups: Joi.array(),
    stockType: Joi.string().valid("dailyStock", "noStock", "incrementalStock"),
    defaultStock: Joi.when("isByKilo", {
      is: true,
      then: Joi.number().greater(0).allow(null, ""),
      otherwise: Joi.number().integer().min(1).allow(null, ""),
    }),
  });

  const itemForm = useForm({ validationSchema, languageKey: "item" });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = itemForm;

  // Event Handlers
  const onOpenPrinterForm = () => {
    setOpenPrinterForm(true);
  };
  const onClosePrinterForm = () => {
    setOpenPrinterForm(false);
  };

  const onOpenDiscountForm = () => {
    setOpenDiscountForm(true);
  };
  const onCloseDiscountForm = () => {
    setOpenDiscountForm(false);
  };

  const onOpenTaxForm = () => {
    setOpenTaxForm(true);
  };
  const onCloseTaxForm = () => {
    setOpenTaxForm(false);
  };

  const openAddItemForm = (category) => {
    setFormData({
      categoryId: category.id != null ? category.id : null,
    });
    setMode("add");
    setOpen(true);
  };

  const onCLoseItemForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
    setOpenItemCategoryCalled(false);
  };

  const onAddItem = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: itemForm,
      formName: "item",
      apiHandler: addItem,
      onClose: onCLoseItemForm,
      selectedCategory,
    });
  };

  const onSaveItem = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: itemForm,
      formName: "item",
      apiHandler: editItem,
      onClose: onCLoseItemForm,
      selectedCategory,
    });
  };

  const taxCallback = (response) => {
    const tax = response.data.data;
    const newTax = {
      value: tax.id,
      text: tax.name,
    };
    const formDataTaxes = formData.taxes ? formData.taxes : [];

    setTaxes({ ...taxes, data: [...taxes.data, tax] });
    setFormData({
      ...formData,
      taxes: [...formDataTaxes, newTax.value],
    });
  };

  const discountCallback = (response) => {
    const discount = response.data.data;
    const newDiscount = {
      value: discount.id,
      text: discount.name,
    };
    const formDataDiscounts = formData.discounts ? formData.discounts : [];

    setDiscounts({
      ...discounts,
      data: [...discounts.data, discount],
    });

    setFormData({
      ...formData,
      discounts: [...formDataDiscounts, newDiscount.value],
    });
  };

  const printerCallback = (response) => {
    const printer = response.data.data;
    const newPrinter = {
      value: printer.id,
      text: printer.name,
    };
    setPrinters({
      ...printers,
      data: [...printers.data, printer],
    });
    setIsSubmiting(false);
    setFormData({
      ...formData,
      printerId: newPrinter.value,
    });
  };

  // Other
  console.log(
    `%cItemForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  const stockOptions = [
    { text: "No Stock", value: "noStock" },
    { text: "Daily Stock", value: "dailyStock" },
    { text: "Incremental Stock", value: "incrementalStock" },
  ];

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <>
      <Dialog
        open={open}
        onClose={onCLoseItemForm}
        scroll={"paper"}
        fullScreen={true}
        style={{ direction: theme.direction }}
      >
        <DialogTitle>
          <LocalizedMessage id="item.pageTitle" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {mode === "add" ? (
              <Typography component="span">
                <LocalizedMessage id="general.add" />
              </Typography>
            ) : (
              <Typography component="span">
                <LocalizedMessage id="general.edit" />
                <b> {formData.name}</b>
              </Typography>
            )}
            {formData.parentCategory && (
              <Typography component="span">
                <br />
                <LocalizedMessage id="item.category" />
                <b>{formData.parentCategory.name}</b>
              </Typography>
            )}
          </DialogContentText>
          <form className={classes.form} noValidate>
            <Box
              display="flex"
              flexDirection={downSm ? "column" : "row"}
              justifyContent="space-around"
            >
              {downSm ? (
                <>
                  <Box
                    style={{
                      flex: 0.5,
                      marginTop: 10,
                      marginLeft: downSm ? 0 : 20,
                    }}
                  >
                    <ImageField
                      fullWidth
                      placeholderImage={uploadItem}
                      name="image"
                      // label="Click on the image to select your item image"
                      label={<LocalizedMessage id="item.image" />}
                      tooltip="Accepted file types: jpeg, png, gif and svg"
                      helpKey="Menu.items.ImageField"
                      onChange={onFormFieldChange}
                      className={classes.imageField}
                      value={formData.image}
                      errors={formErrors.image}
                      isLoading={isLoading.image}
                      isSubmitting={isSubmitting}
                    />
                  </Box>
                  <Divider orientation="vertical" flexItem style={{ margin: 20 }} />

                  <Box style={{ flex: 1, marginRight: 20 }}>
                    <TextField
                      required
                      label={<LocalizedMessage id="common.name" />}
                      name="name"
                      autoComplete="name"
                      tooltip={"Item Full Name"}
                      helpKey="Menu.items.NameField"
                      autoFocus
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.name}
                      errors={formErrors.name}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.name}
                    />
                    <TextField
                      label={<LocalizedMessage id="common.otherName" />}
                      name="otherName"
                      autoComplete="otherName"
                      tooltip={"Items Other Name"}
                      helpKey="Menu.items.otherNameField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.otherName}
                      errors={formErrors.otherName}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.otherName}
                    />
                    <TextField
                      label={<LocalizedMessage id="item.description" />}
                      name="description"
                      autoComplete="description"
                      tooltip={"Items Description"}
                      helpKey="Menu.items.descriptionField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.description}
                      errors={formErrors.description}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.description}
                    />
                    <TextField
                      label={<LocalizedMessage id="item.barcode" />}
                      name="barcode"
                      autoComplete="barcode"
                      tooltip={"Barcode"}
                      helpKey="Menu.items.barcodeField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.barcode}
                      errors={formErrors.barcode}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.barcode}
                    />
                    <TextField
                      label={<LocalizedMessage id="common.defaultPrice" />}
                      name="defaultPrice"
                      autoComplete="defaultPrice"
                      tooltip={"Items defaultPrice"}
                      helpKey="Menu.items.defaultPriceField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.defaultPrice}
                      errors={formErrors.defaultPrice}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.defaultPrice}
                    />

                    <SalesSectionsPrices
                      required
                      name="salesSections"
                      tooltip={"Sales Sections Prices"}
                      helpKey="SalesSectionsPrices"
                      label={<LocalizedMessage id="item.salesSectionsPrices" />}
                      onChange={onFormFieldChange}
                      value={formData.salesSections}
                      errors={formErrors.salesSections}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.salesSections}
                      mode={mode}
                      formData={formData}
                      setFormData={setFormData}
                      permissions={permissions}
                    />
                    <SelectField
                      fullWidth
                      name="taxes"
                      tooltip={"Taxes"}
                      helpKey="common.taxes"
                      label={<LocalizedMessage id="common.taxes" />}
                      onChange={onFormFieldChange}
                      value={formData.taxes}
                      errors={formErrors.taxes}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.taxes}
                      options={taxes.data
                        .map((tax) => {
                          if (tax.taxType === "item") return { value: tax.id, text: tax.name };
                        })
                        .filter((tax) => tax !== undefined)}
                      multiple
                      openForm={onOpenTaxForm}
                      addPermission={
                        permissions.isAdmin || (permissions.tax && permissions.tax.includes("add"))
                      }
                    />
                    <SelectField
                      fullWidth
                      name="discounts"
                      tooltip={"Discounts"}
                      helpKey="common.discounts"
                      label={<LocalizedMessage id="common.discounts" />}
                      onChange={onFormFieldChange}
                      value={formData.discounts}
                      errors={formErrors.discounts}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.discounts}
                      options={discounts.data
                        .map((d) => {
                          if (d.discountType === "item") return { value: d.id, text: d.name };
                        })
                        .filter((d) => d !== undefined)}
                      multiple
                      openForm={onOpenDiscountForm}
                      addPermission={
                        permissions.isAdmin ||
                        (permissions.orderDiscount && permissions.orderDiscount.includes("add"))
                      }
                    />
                    <SelectField
                      fullWidth
                      name="printerId"
                      tooltip={"printerId"}
                      helpKey="Menu.items.printerId"
                      label={<LocalizedMessage id="item.printerId" />}
                      onChange={onFormFieldChange}
                      value={formData.printerId}
                      errors={formErrors.printerId}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.printerId}
                      options={printers.data
                        .map((p) => {
                          if (p.printerTypeId === "itemSpecific")
                            return { value: p.id, text: p.name };
                        })
                        .filter((p) => p !== undefined)}
                      openForm={onOpenPrinterForm}
                      addPermission={
                        permissions.isAdmin ||
                        (permissions.printer && permissions.printer.includes("add"))
                      }
                    />
                    <ColorPickerField
                      required
                      fullWidth
                      label={<LocalizedMessage id="common.color" />}
                      name="color"
                      tooltip={"Color of the items when displayed at the POS and Reports"}
                      helpKey="Menu.items.ColorField"
                      value={formData.color ? formData.color : "#99f3bd"}
                      onChange={onFormFieldChange}
                      errors={formErrors.color}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.color}
                    />
                    <CheckBox
                      fullWidth
                      name="isActive"
                      tooltip={"Active?"}
                      helpKey="common.isActive"
                      label={<LocalizedMessage id="common.isActive" />}
                      onChange={onFormFieldChange}
                      value={formData.isActive}
                      errors={formErrors.isActive}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.isActive}
                    />
                    <CheckBox
                      fullWidth
                      name="isByKilo"
                      tooltip={"By kilo?"}
                      helpKey="Menu.Items.isByKilo"
                      label={<LocalizedMessage id="item.isByKilo" />}
                      onChange={onFormFieldChange}
                      value={formData.isByKilo}
                      errors={formErrors.isByKilo}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.isByKilo}
                    />
                    <RadioBox
                      fullWidth
                      name="stockType"
                      tooltip={"Stock Type"}
                      helpKey="Menu.Items.stockType"
                      label={<LocalizedMessage id="item.stockType" />}
                      onChange={onFormFieldChange}
                      value={formData.stockType}
                      errors={formErrors.stockType}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.stockType}
                      options={stockOptions}
                      defaultValue={formData.stockType || stockOptions[0].value}
                    />
                    {formData.stockType === "dailyStock" && (
                      <TextField
                        label={<LocalizedMessage id="item.defaultStock" />}
                        name="defaultStock"
                        autoComplete="defaultStock"
                        tooltip={"Items defaultStock"}
                        helpKey="Menu.items.defaultStockField"
                        fullWidth
                        // maxCharacters={50}
                        onChange={onFormFieldChange}
                        value={formData.defaultStock}
                        errors={formErrors.defaultStock}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading.defaultStock}
                      />
                    )}
                    <ModifierGroup
                      name="modifierGroups"
                      tooltip={"Modifier Group"}
                      helpKey="item.modifier.modifierGroups"
                      label={<LocalizedMessage id="item.modifier.modifierGroups" />}
                      onChange={onFormFieldChange}
                      value={formData.modifierGroups}
                      errors={formErrors.modifierGroups}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.modifierGroups}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box style={{ flex: 1, marginRight: 20 }}>
                    <TextField
                      required
                      label={<LocalizedMessage id="common.name" />}
                      name="name"
                      autoComplete="name"
                      tooltip={"Item Full Name"}
                      helpKey="Menu.items.NameField"
                      autoFocus
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.name}
                      errors={formErrors.name}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.name}
                    />
                    <TextField
                      label={<LocalizedMessage id="common.otherName" />}
                      name="otherName"
                      autoComplete="otherName"
                      tooltip={"Items Other Name"}
                      helpKey="Menu.items.otherNameField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.otherName}
                      errors={formErrors.otherName}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.otherName}
                    />
                    <TextField
                      label={<LocalizedMessage id="item.description" />}
                      name="description"
                      autoComplete="description"
                      tooltip={"Items Description"}
                      helpKey="Menu.items.descriptionField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.description}
                      errors={formErrors.description}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.description}
                    />
                    <TextField
                      label={<LocalizedMessage id="item.barcode" />}
                      name="barcode"
                      autoComplete="barcode"
                      tooltip={"Barcode"}
                      helpKey="Menu.items.barcodeField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.barcode}
                      errors={formErrors.barcode}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.barcode}
                    />
                    <TextField
                      label={<LocalizedMessage id="common.defaultPrice" />}
                      name="defaultPrice"
                      autoComplete="defaultPrice"
                      tooltip={"Items defaultPrice"}
                      helpKey="Menu.items.defaultPriceField"
                      fullWidth
                      // maxCharacters={50}
                      onChange={onFormFieldChange}
                      value={formData.defaultPrice}
                      errors={formErrors.defaultPrice}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.defaultPrice}
                    />
                    <SalesSectionsPrices
                      required
                      name="salesSections"
                      tooltip={"Sales Sections Prices"}
                      helpKey="SalesSectionsPrices"
                      label={<LocalizedMessage id="item.salesSectionsPrices" />}
                      onChange={onFormFieldChange}
                      value={formData.salesSections}
                      errors={formErrors.salesSections}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.salesSections}
                      mode={mode}
                      formData={formData}
                      setFormData={setFormData}
                      permissions={permissions}
                    />
                    <SelectField
                      fullWidth
                      name="taxes"
                      tooltip={"Taxes"}
                      helpKey="common.taxes"
                      label={<LocalizedMessage id="common.taxes" />}
                      onChange={onFormFieldChange}
                      value={formData.taxes}
                      errors={formErrors.taxes}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.taxes}
                      options={taxes.data
                        .map((tax) => {
                          if (tax.taxType === "item") return { value: tax.id, text: tax.name };
                        })
                        .filter((tax) => tax !== undefined)}
                      multiple
                      openForm={onOpenTaxForm}
                      addPermission={
                        permissions.isAdmin || (permissions.tax && permissions.tax.includes("add"))
                      }
                    />
                    <SelectField
                      fullWidth
                      name="discounts"
                      tooltip={"Discounts"}
                      helpKey="common.discounts"
                      label={<LocalizedMessage id="common.discounts" />}
                      onChange={onFormFieldChange}
                      value={formData.discounts}
                      errors={formErrors.discounts}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.discounts}
                      options={discounts.data
                        .map((d) => {
                          if (d.discountType === "item") return { value: d.id, text: d.name };
                        })
                        .filter((d) => d !== undefined)}
                      multiple
                      openForm={onOpenDiscountForm}
                      addPermission={
                        permissions.isAdmin ||
                        (permissions.orderDiscount && permissions.orderDiscount.includes("add"))
                      }
                    />
                    <SelectField
                      fullWidth
                      name="printerId"
                      tooltip={"printerId"}
                      helpKey="Menu.items.printerId"
                      label={<LocalizedMessage id="item.printerId" />}
                      onChange={onFormFieldChange}
                      value={formData.printerId}
                      errors={formErrors.printerId}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.printerId}
                      options={printers.data
                        .map((p) => {
                          if (p.printerTypeId === "itemSpecific")
                            return { value: p.id, text: p.name };
                        })
                        .filter((p) => p !== undefined)}
                      openForm={onOpenPrinterForm}
                      addPermission={
                        permissions.isAdmin ||
                        (permissions.printer && permissions.printer.includes("add"))
                      }
                    />
                    <ColorPickerField
                      required
                      fullWidth
                      label={<LocalizedMessage id="common.color" />}
                      name="color"
                      tooltip={"Color of the items when displayed at the POS and Reports"}
                      helpKey="Menu.items.ColorField"
                      value={formData.color ? formData.color : "#99f3bd"}
                      onChange={onFormFieldChange}
                      errors={formErrors.color}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.color}
                    />
                    <CheckBox
                      fullWidth
                      name="isActive"
                      tooltip={"Active?"}
                      helpKey="common.isActive"
                      label={<LocalizedMessage id="common.isActive" />}
                      onChange={onFormFieldChange}
                      value={formData.isActive}
                      errors={formErrors.isActive}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.isActive}
                    />
                    <CheckBox
                      fullWidth
                      name="isByKilo"
                      tooltip={"By kilo?"}
                      helpKey="Menu.Items.isByKilo"
                      label={<LocalizedMessage id="item.isByKilo" />}
                      onChange={onFormFieldChange}
                      value={formData.isByKilo}
                      errors={formErrors.isByKilo}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.isByKilo}
                    />
                    <RadioBox
                      fullWidth
                      name="stockType"
                      tooltip={"Stock Type"}
                      helpKey="Menu.Items.stockType"
                      label={<LocalizedMessage id="item.stockType" />}
                      onChange={onFormFieldChange}
                      value={formData.stockType}
                      errors={formErrors.stockType}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.stockType}
                      options={stockOptions}
                      defaultValue={formData.stockType || stockOptions[0].value}
                    />
                    {formData.stockType === "dailyStock" && (
                      <TextField
                        label={<LocalizedMessage id="item.defaultStock" />}
                        name="defaultStock"
                        autoComplete="defaultStock"
                        tooltip={"Items defaultStock"}
                        helpKey="Menu.items.defaultStockField"
                        fullWidth
                        // maxCharacters={50}
                        onChange={onFormFieldChange}
                        value={formData.defaultStock}
                        errors={formErrors.defaultStock}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading.defaultStock}
                      />
                    )}
                    <ModifierGroup
                      name="modifierGroups"
                      tooltip={"Modifier Group"}
                      helpKey="item.modifier.modifierGroups"
                      label={<LocalizedMessage id="item.modifier.modifierGroups" />}
                      onChange={onFormFieldChange}
                      value={formData.modifierGroups}
                      errors={formErrors.modifierGroups}
                      isSubmitting={isSubmitting}
                      isLoading={isLoading.modifierGroups}
                    />
                  </Box>
                  <Divider orientation="vertical" flexItem style={{ margin: 20 }} />
                  <Box style={{ flex: 0.5, marginTop: 150, marginLeft: 20 }}>
                    <ImageField
                      fullWidth
                      placeholderImage={uploadItem}
                      name="image"
                      // label="Click on the image to select your item image"
                      label={<LocalizedMessage id="item.image" />}
                      tooltip="Accepted file types: jpeg, png, gif and svg"
                      helpKey="Menu.items.ImageField"
                      onChange={onFormFieldChange}
                      className={classes.imageField}
                      value={formData.image}
                      errors={formErrors.image}
                      isLoading={isLoading.image}
                      isSubmitting={isSubmitting}
                    />
                  </Box>
                </>
              )}
            </Box>
          </form>
          <DiscountForm
            open={openDiscountForm}
            mode={"add"}
            addCallback={discountCallback}
            onCancel={onCloseDiscountForm}
            permissions={permissions}
            parent="item"
          />
          <TaxForm
            open={openTaxForm}
            mode={"add"}
            addCallback={taxCallback}
            onCancel={onCloseTaxForm}
            permissions={permissions}
            parent="item"
          />
          <PrinterForm
            open={openPrinterForm}
            mode={"add"}
            addCallback={printerCallback}
            printerTypes={printerTypes}
            onCancel={onClosePrinterForm}
            parent="item"
          />
        </DialogContent>
        <DialogActions>
          <FormActions
            onAdd={onAddItem}
            mode={mode}
            onCLoseForm={onCLoseItemForm}
            isSubmitting={isSubmitting}
            onSave={onSaveItem}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

ItemForm.propTypes = {
  /**
   *
   */
};

ItemForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default ItemForm;
