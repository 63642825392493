// React
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

// Material
import {
  Fab,
  Chip,
  useTheme,
  Box,
  Paper,
  TableRow,
  IconButton,
  Tooltip,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Typography,
  Table,
  useMediaQuery,
  CircularProgress,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import CropFreeIcon from "@material-ui/icons/CropFree";
import SearchIcon from "@material-ui/icons/Search";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LocationCityIcon from "@material-ui/icons/LocationCity";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";
// Components
import Title from "Components/Title";
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";
import QRDialog from "Components/QRDialog";
import payloads from "Helpers/Handlers/payloads";
import SubscriptionAlert from "Components/SubscriptionAlert";
import SubscriptionDialog from "Components/SubscriptionDialog";

// Factories

// Screens
import BranchForm from "./BranchForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import {
  useDeleteBranch,
  useAddBranch,
  useEditBranch,
} from "Services/Branches/";
import { useGetAvailableSubscriptions } from "Services/Subscription/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
    format: false,
  },
  // {
  //   id: "otherName",
  //   label: <LocalizedMessage id="common.otherName" />,
  //   minWidth: 200,
  //   format: false,
  // },
  {
    id: "currencyId",
    label: <LocalizedMessage id="common.currencyId" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "countryId",
    label: <LocalizedMessage id="common.countryId" />,
    minWidth: 100,
    format: false,
  },
  // {
  //   id: "city",
  //   label: <LocalizedMessage id="common.city" />,
  //   minWidth: 100,
  //   format: false,
  // },
  // {
  //   id: "timezone",
  //   label: <LocalizedMessage id="common.timezone" />,
  //   minWidth: 100,
  //   format: false,
  // },
  {
    id: "phoneNumbers",
    label: <LocalizedMessage id="common.phoneNumbers" />,
    minWidth: 100,
    format: true,
  },
  {
    id: "subscription",
    label: <LocalizedMessage id="subscriptionDialog.pageTitle" />,
    minWidth: 100,
  },
  {
    id: "expirationDate",
    label: <LocalizedMessage id="POS.expirationDate" />,
    minWidth: 100,
  },

  { id: "actions", label: "", minWidth: 200, format: false },
];

const { CHECKOUT_BRANCH_MONTHLY } = process.env;

/**
 * @name Branches
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Branches = ({ branches, setBranches }) => {
  console.log(
    "🚀 ~ file: Branches.js ~ line 149 ~ Branches ~ branches",
    branches
  );
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();
  let history = useHistory();

  // Global State Hooks
  const [branch, setBranch] = useGlobalState("userData.branch");
  const [subscriptionsPlans] = useGlobalState(
    "subscriptionsPlans.subscriptionsPlans"
  );
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);
  const [editId, setEditId] = React.useState("");
  const [status, setStatus] = useState(0);
  const [openQrDialog, setOpenQRDialog] = useState(false);
  const [openSubscriptionAlert, setOpenSubscriptionAlert] = useState(false);
  const [isFetchingSubscriptions, setIsFetchingSubscriptions] = useState(false);
  const [qrData, setQRData] = useState("");

  /* Branch Form */
  const [BranchFormOpen, setBranchFormOpen] = useState(false);
  const [BranchFormMode, setBranchFormMode] = useState("");
  const [BranchFormData, setBranchFormData] = useState({});

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <LocationCityIcon
            style={{ margin: "0px 16px", width: 20, height: 20 }}
          />
          <LocalizedMessage id="branch.pageTitle" key="branch.pageTitle" />
        </Box>
      ),
    []
  );

  useEffect(() => {
    if (branches.length) {
      setRows(branches.map((branch) => createData(branch)));
      setIsFetchingRows(false);
    }
  }, [branches]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const deleteBranch = useDeleteBranch();
  const addBranch = useAddBranch({ languageKey: "branch" });
  const editBranch = useEditBranch({ languageKey: "branch" });
  const getAvailableSubscriptions = useGetAvailableSubscriptions({
    languageKey: "subscription",
  });

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  // Event Handlers

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddBranchForm = () => {
    setBranchFormData({});
    setBranchFormMode("add");
    setBranchFormOpen(true);
  };

  const onEditClick = (Branch) => {
    setEditId(Branch.id);
    setBranchFormData(Branch);
    setBranchFormMode("edit");
    setBranchFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteBranch(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setRows(rows.getState().filter((row) => row.id !== id));
        setBranches(rows.getState().filter((row) => row.id !== id));
        if (branch.id === id) {
          const newBranch = branches.find((el) => el.id !== id);
          setBranch(newBranch);
          history.go(0);
        }
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const onAddBranchHandler = (
    keyy,
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseBranchForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);

    if (validForm) {
      setIsSubmiting(true);
      const payload = payloads["branch"](formData);
      addBranch(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          setRows([...rows.getState(), createData(response.data.data)]);
          setBranches([...branches, response.data.data]);
          setIsSubmiting(false);
          onCLoseBranchForm();
        })
        .catch((errors) => {
          console.log(errors);
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          displaySnackbarErrors(errors, enqueueSnackbar);
          setIsSubmiting(false);
        });
    }
  };

  const onEditBranchHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseBranchForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const payload = payloads["branch"](formData);
      editBranch({ ...payload, id: editId })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          const newRows = rows.getState();
          const newBranches = branches;

          const index = newRows.findIndex((row) => row.id === editId);
          newRows.splice(index, 1, createData(response.data.data));
          newBranches.splice(index, 1, response.data.data);
          setRows(newRows);
          setBranches(newBranches);

          if (branch.id === response.data.data.id)
            setBranch(response.data.data);
          setIsSubmiting(false);
          onCLoseBranchForm();
        })
        .catch((errors) => {
          console.log(errors);
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  const handleOpenQrDialog = (br) => {
    setOpenQRDialog(true);
    setQRData(br.id);
  };

  const onAddClick = () => {
    setIsFetchingSubscriptions(true);
    getAvailableSubscriptions({ branchOrDevice: "branch", branchId: branch.id })
      .then((response) => {
        setIsFetchingSubscriptions(false);
        openAddBranchForm();
      })
      .catch((errorResponse) => {
        setStatus(errorResponse?.status);
        setOpenSubscriptionAlert(true);
        setIsFetchingSubscriptions(false);
      });
  };

  // Other

  function createData(branch) {
    return {
      id: branch.id,
      name: branch.name,
      //otherName: branch.otherName,
      currencyId: branch.currency.name,
      currency: branch.currency,
      country: branch.country,
      countryId: branch.country.name,
      // city: branch.city,
      // timezone: branch.timezone,
      phoneNumbers: branch.phoneNumbers,
      subscription: (
        <Box>
          {branch.subscription?.subscriptionStatus === "Active" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={intl.formatMessage({ id: "branch.active" })}
                style={{
                  backgroundColor: "#00c853",
                  color: "white",
                }}
              />
              <Typography variant="caption" style={{ marginTop: 4 }}>
                {`${branch.subscription?.productName} - ${branch.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : branch.subscription?.subscriptionStatus === "InGrace" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={intl.formatMessage({ id: "branch.inGrace" })}
                style={{
                  backgroundColor: "#e48f2a",
                  color: "white",
                }}
              />
              <Typography variant="caption" style={{ marginTop: 4 }}>
                {`${branch.subscription?.productName} - ${branch.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : branch.subscription?.subscriptionStatus === "InCountingDown" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={intl.formatMessage({ id: "branch.inCountingDown" })}
                style={{
                  backgroundColor: "#ff9800",
                  color: "white",
                }}
              />
              <Typography variant="caption" style={{ marginTop: 4 }}>
                {`${branch.subscription?.productName} - ${branch.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : branch.subscription?.subscriptionStatus === "Expired" ? (
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Chip
                label={intl.formatMessage({ id: "branch.expired" })}
                style={{
                  backgroundColor: "#c62828",
                  color: "white",
                  marginRight: 8,
                }}
              />
              <Typography variant="caption" style={{ marginTop: 4 }}>
                {`${branch.subscription?.productName} - ${branch.subscription?.subscriptionPlan}`}
              </Typography>
            </Box>
          ) : null}
        </Box>
      ),
      expirationDate: (
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {branch.subscription?.id ? (
            branch.subscription?.subscriptionAutoRenew ? (
              <Tooltip title={<LocalizedMessage id="POS.autoRecurring" />}>
                <AutorenewIcon
                  style={{
                    margin: 4,
                  }}
                />
              </Tooltip>
            ) : (
              <Tooltip title={<LocalizedMessage id="POS.nonRecurring" />}>
                <RadioButtonUncheckedIcon
                  style={{
                    margin: 4,
                  }}
                />
              </Tooltip>
            )
          ) : null}
          <Typography>{branch.subscription?.subscriptionExpiryDate}</Typography>
        </Box>
      ),
      // location: branch.location,
      onlineDineInCode: branch.onlineDineInCode,
      isActiveInOnline: branch.isActiveInOnline,
      actions: (
        <Box>
          <Tooltip title={<LocalizedMessage id="general.edit" />}>
            <IconButton aria-label="edit" onClick={() => onEditClick(branch)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* <AlertDialog id={branch.id} onAgree={onDeleteClick} deleteText="" /> */}
          <Tooltip title={<LocalizedMessage id="general.scanQr" />}>
            <IconButton
              aria-label="scan"
              onClick={() => handleOpenQrDialog(branch)}
            >
              <CropFreeIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="branch.pageTitle" key="branch.pageTitle" />
        }
      />

      <Paper
        dir={Theme.direction}
        style={{
          padding: "4px 24px 0px 24px",
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            paddingBottom: Theme.spacing(10),
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              <LocalizedMessage id="branch.pageTitle" />
            </Typography>

            {!openSearchField && downSm && (
              <IconButton onClick={() => setOpenSearchField(true)}>
                <SearchIcon color={"primary"} />
              </IconButton>
            )}
            {(openSearchField || !downSm) && (
              <TextField
                label={intl.formatMessage({ id: "general.search" })}
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
              />
            )}
          </Box>

          <Tooltip title={<LocalizedMessage id="general.add" />}>
            <Fab
              disabled={
                isFetchingRows ||
                isFetchingSubscriptions ||
                subscriptionsPlans.isFetchingRows
              }
              color="primary"
              aria-label="add"
              style={{
                position: "absolute",
                bottom: Theme.spacing(2),
                right: Theme.spacing(2),
                pointerEvents: BranchFormOpen ? "none" : "auto",
              }}
              component={
                isFetchingRows ||
                isFetchingSubscriptions ||
                subscriptionsPlans.isFetchingRows
                  ? "div"
                  : undefined
              }
              onClick={
                isFetchingRows ||
                isFetchingSubscriptions ||
                subscriptionsPlans.isFetchingRows
                  ? undefined
                  : onAddClick
              }
            >
              {isFetchingSubscriptions || subscriptionsPlans.isFetchingRows ? (
                <CircularProgress size={20} />
              ) : (
                <AddIcon />
              )}
            </Fab>
          </Tooltip>

          <Paper className={classes.root}>
            {/* Start Branch Form */}
            <BranchForm
              open={BranchFormOpen}
              setOpen={setBranchFormOpen}
              mode={BranchFormMode}
              editFormData={BranchFormData}
              onAdd={onAddBranchHandler}
              onSave={onEditBranchHandler}
              full={true}
            />
            {/* End Branch Form */}
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.getState().length
                        ? rows
                            .getState()
                            .filter(
                              (row) =>
                                row.name
                                  .toLowerCase()
                                  .indexOf(searchQuery.toLowerCase()) != -1 ||
                                searchQuery.toLowerCase() === ""
                            )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  {columns.map((column) => {
                                    const value = row[column.id];
                                    let phones = "";
                                    let splitted;
                                    if (column.format) {
                                      if (typeof value === "string")
                                        splitted = value.split(",");
                                      else splitted = value;
                                      if (splitted.length === 1) {
                                        phones = value;
                                      } else {
                                        splitted.map(
                                          (v) => (phones += `${v} - `)
                                        );
                                        phones = phones.slice(0, -2);
                                      }
                                    }
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {column.format ? phones : value}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
          <QRDialog
            qrData={qrData}
            open={openQrDialog}
            onCloseDialog={() => setOpenQRDialog(false)}
          />
          <SubscriptionAlert
            open={openSubscriptionAlert && !subscriptionsPlans.isFetchingRows}
            onCloseDialog={() => setOpenSubscriptionAlert(false)}
            status={status}
            branchOrDevice="branch"
          />
        </Box>
      </Paper>
    </Box>
  );
};

Branches.propTypes = {
  /**
   *
   */
};

Branches.defaultProps = {
  /**
   *
   */
};

export default Branches;
