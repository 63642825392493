const payloads = {
  salesChannel: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    salesSectionTypeId: formData.salesSectionTypeId,
    printerId: formData.printerId,
  }),
  table: (formData) => ({
    capacity: formData.capacity,
    number: formData.number,
    salesSectionId: formData.salesSectionId,
  }),
  permission: (formData) => ({
    name: formData.name,
    isAdmin: formData.isAdmin,
    permissionsMatrix: formData.isAdmin
      ? undefined
      : formData.permissionsMatrix,
  }),
  employee: (formData) => ({
    name: formData.name,
    accessId: formData.accessId,
    accessPin: formData.accessPin,
    permissionGroupId:
      formData.driverOrCashier === "hasFrontOfficeAccess"
        ? formData.permissionGroupId
        : undefined,
    salesSections:
      formData.driverOrCashier === "hasFrontOfficeAccess"
        ? formData.salesSections
        : undefined,
    maxDiscountAmount:
      formData.driverOrCashier === "hasFrontOfficeAccess"
        ? formData.maxDiscountAmount
        : undefined,
    maxDiscountPercentage:
      formData.driverOrCashier === "hasFrontOfficeAccess"
        ? formData.maxDiscountPercentage
        : undefined,
    isDriver: formData.driverOrCashier === "isDriver" ? true : false,
    hasFrontOfficeAccess:
      formData.driverOrCashier === "hasFrontOfficeAccess" ? true : false,
  }),
  category: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    color: formData.color,
    parentId: formData.parentId,
    salesSections: formData.salesSections,
    isAlwaysAvailable: formData.isAlwaysAvailable,
    weekDays: formData.weekDays,
    isActive: formData.isActive,
    isAllDay: formData.isAllDay,
    timeFrom: formData.timeFrom,
    timeTo: formData.timeTo,
  }),
  item: (formData, selectedCategory) => {
    return {
      name: formData.name,
      otherName: formData.otherName,
      description: formData.description,
      barcode: formData.barcode,
      categoryId: selectedCategory.id,
      color: formData.color,
      image: formData.image,
      defaultPrice: formData.defaultPrice,
      salesSections: formData.salesSections,
      taxes: formData.taxes,
      discounts: formData.discounts,
      printerId: formData.printerId ? formData.printerId : "",
      stockType: formData.stockType,
      isActive: formData.isActive,
      isByKilo: formData.isByKilo,
      modifierGroups: formData.modifierGroups ? formData.modifierGroups : [],
      defaultStock:
        formData.stockType === "dailyStock" ? formData.defaultStock : undefined,
    };
  },
  city: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  zone: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    cityId: formData.cityId,
    deliveryTime: formData.deliveryTime,
    minimumOrderValue: formData.minimumOrderValue,
  }),
  printer: (formData) => ({
    name: formData.name,
    ipAddress: formData.ipAddress,
    printerTypeId: formData.printerTypeId,
    codePage: formData.codePage,
    cutType: formData.cutType,
    isPrinterBeep: formData.isPrinterBeep,
    textDensity: formData.textDensity,
    cashDrawerPin: formData.cashDrawerPin,
    printingSize: formData.printingSize,
    receiptName:
      formData.printerTypeId !== "itemSpecific"
        ? formData.receiptName
        : undefined,
    copyName:
      formData.printerTypeId !== "receipt" ? undefined : formData.copyName,
    isPrintOnSave:
      formData.printerTypeId !== "receipt" ? undefined : formData.isPrintOnSave,
  }),
  discount: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    employees:
      formData.discountType === "employee" ? formData.employees : undefined,
    salesSections:
      formData.discountType === "section" ? formData.salesSections : undefined,
    value: formData.value,
    valueType: formData.valueType,
    discountType: formData.discountType,
    dateFrom: formData.dateFrom,
    dateTo: formData.dateTo,
  }),
  surcharge: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    value: formData.value,
    valueType: formData.valueType,
    salesSections: formData.salesSections,
    taxes: formData.taxes,
  }),
  tax: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    value: formData.value,
    valueType: formData.valueType,
    taxType: formData.taxType,
    salesSections:
      formData.taxType === "section" ? formData.salesSections : undefined,
    items:
      formData.taxType === "item"
        ? formData.items?.map((item) => item.id)
        : undefined,
    surcharges:
      formData.taxType === "surcharge" ? formData.surcharges : undefined,
  }),
  payment: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    typeId: formData.typeId,
  }),
  itemNote: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  orderNote: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  orderCancellationReason: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  onlineOrderCancellationReason: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  itemCancellationReason: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  tableReservationCancellationReason: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
  }),
  role: (formData) => ({
    name: formData.name,
    isAdmin: formData.isAdmin,
    permissionsMatrix: formData.isAdmin
      ? undefined
      : formData.permissionsMatrix,
  }),
  account: (formData) => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    email: formData.email,
    phoneNumber: formData.phoneNumber,
    password: formData.password,
    timezone: formData.timezone,
    isAdmin: formData.isAdmin,
    branchRoles: formData.isAdmin ? undefined : formData.branchRoles,
  }),
  accountSetup: (formData) => ({
    firstName: formData.firstName,
    lastName: formData.lastName,
    phoneNumber: formData.phoneNumber,
    timezone: formData.timezone,
  }),
  brandSetup: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    logo: formData.logo,
  }),
  branch: (formData) => ({
    name: formData.name,
    otherName: formData.otherName,
    currencyId: formData.currencyId,
    countryId: formData.countryId,
    city: formData.city,
    cityOtherName: formData.cityOtherName,
    address: formData.address,
    addressOtherName: formData.addressOtherName,
    commercialRegistrationNumber: formData.commercialRegistrationNumber,
    vatRegistrationNumber: formData.vatRegistrationNumber,
    timezone: formData.timezone,
    phoneNumbers: formData.phoneNumbers,
    location: formData.location,
  }),
};

export default payloads;
