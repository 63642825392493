// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  Paper,
  useTheme,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  Typography,
  Divider,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckIcon from "@material-ui/icons/Check";
import AccountBoxIcon from "@material-ui/icons/AccountBox";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import payloads from "Helpers/Handlers/payloads";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";
// Factories

// Screens
import AccountForm from "./AccountForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import {
  useGetAllAccounts,
  useDeleteAccount,
  useAddAccount,
  useEditAccount,
  useDeactivateAccount,
  useActivateAccount,
} from "Services/Accounts/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  { id: "name", label: <LocalizedMessage id="common.name" />, minWidth: 200 },
  {
    id: "email",
    label: <LocalizedMessage id="common.email" />,
    minWidth: 200,
  },
  {
    id: "phoneNumber",
    label: <LocalizedMessage id="auth.register.phone" />,
    minWidth: 200,
  },
  // {
  //   id: "timezone",
  //   label: <LocalizedMessage id="common.timezone" />,
  //   minWidth: 100,
  // },
  {
    id: "status",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 50,
  },
  { id: "actions", label: "", minWidth: 200 },
];

/**
 * @name Accounts
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Accounts = ({ branches, setBranches }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [searchQuery, setSearchQuery] = useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = useState(true);
  const [editId, setEditId] = useState("");
  const [isActivating, setIsActivating] = useStateRef(false);

  /* Account Form */
  const [AccountFormOpen, setAccountFormOpen] = useState(false);
  const [AccountFormMode, setAccountFormMode] = useState("");
  const [AccountFormData, setAccountFormData] = useState({});

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <AccountBoxIcon
            style={{ margin: "0px 16px", width: 20, height: 20 }}
          />
          <LocalizedMessage id="account.pageTitle" key="account.pageTitle" />
        </Box>
      ),
    []
  );

  useEffect(() => {
    getAllAccounts()
      .then((Accounts) => {
        const newAccounts = Accounts.map((Account) => createData(Account));
        setIsFetchingRows(false);
        setRows(newAccounts);
      })
      .catch((errors) => {
        console.log(errors);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllAccounts = useGetAllAccounts();
  const deleteAccount = useDeleteAccount();
  const addAccount = useAddAccount({ languageKey: "account" });
  const editAccount = useEditAccount({ languageKey: "account" });
  const activateAccount = useActivateAccount({
    languageKey: "account",
  });
  const deactivateAccount = useDeactivateAccount({
    languageKey: "account",
  });
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddAccountForm = () => {
    setAccountFormData({});
    setAccountFormMode("add");
    setAccountFormOpen(true);
  };

  const onEditClick = (account) => {
    setEditId(account.id);
    setAccountFormData(account);
    setAccountFormMode("edit");
    setAccountFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteAccount(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");
        setRows(rows.getState().filter((row) => row.id !== id));
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const onAddAccountHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseAccountForm
  ) => {
    const validForm = validateForm();

    if (validForm) {
      setIsSubmiting(true);
      const payload = payloads["account"](formData);
      addAccount(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          setRows([...rows.getState(), createData(response.data.data)]);
          setIsSubmiting(false);
          onCLoseAccountForm();
        })
        .catch((errors) => {
          if (errors[0].field === "branchId") errors[0].field = "branchRoles";
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  const onEditAccountHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseAccountForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const payload = payloads["account"](formData);
      editAccount({
        ...payload,
        id: editId,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          const newRows = rows.getState();
          const index = newRows.findIndex((row) => row.id === editId);
          newRows.splice(index, 1, createData(response.data.data));
          setRows(newRows);
          setIsSubmiting(false);
          onCLoseAccountForm();
        })
        .catch((errors) => {
          if (errors[0].field === "branchId") errors[0].field = "branchRoles";
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  const onActivateClick = (account) => {
    setIsActivating(true);
    const index = rows.getState().findIndex((row) => row.id === account.id);
    rows
      .getState()
      .splice(index, 1, createData({ ...account, isActive: false }));
    setRows([...rows.getState()]);

    activateAccount(account.id)
      .then((response) => {
        enqueueSnackbar(<LocalizedMessage id="account.active.success" />, {
          variant: "success",
          autoHideDuration: 5000,
        });
        const index = rows.getState().findIndex((row) => row.id === account.id);
        setIsActivating(false);
        rows
          .getState()
          .splice(index, 1, createData({ ...account, isActive: true }));
        setRows([...rows.getState()]);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const onDeactivateClick = (account) => {
    setIsActivating(true);
    const index = rows.getState().findIndex((row) => row.id === account.id);
    rows
      .getState()
      .splice(index, 1, createData({ ...account, isActive: true }));

    setRows([...rows.getState()]);

    deactivateAccount(account.id)
      .then((response) => {
        enqueueSnackbar(<LocalizedMessage id="account.deactive.success" />, {
          variant: "success",
          autoHideDuration: 5000,
        });
        const index = rows.getState().findIndex((row) => row.id === account.id);
        setIsActivating(false);
        rows
          .getState()
          .splice(index, 1, createData({ ...account, isActive: false }));
        setRows([...rows.getState()]);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  // Other
  function createData(account) {
    return {
      id: account.id,
      name: account.firstName + " " + account.lastName,
      email: account.email,
      phoneNumber: account.phoneNumber,
      password: account.password,
      timezone: account.timezone,
      branchRoles: account.branchRoles,
      status: account.isActive ? (
        <Chip
          label={<LocalizedMessage id="general.active" />}
          color="primary"
        />
      ) : (
        <Chip
          label={<LocalizedMessage id="general.inActive" />}
          style={{ backgroundColor: "#0582D2", color: "white" }}
        />
      ),
      actions: (
        <Box>
          {account.isActive ? (
            <Tooltip title={<LocalizedMessage id="common.deactivate" />}>
              <IconButton
                component={isActivating.getState() ? "div" : undefined}
                onClick={
                  isActivating.getState()
                    ? undefined
                    : () => onDeactivateClick(account)
                }
                disabled={isActivating.getState()}
                aria-label="deactivate"
                //onClick={() => onDeactivateClick(account)}
              >
                <NotInterestedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title={<LocalizedMessage id="common.activate" />}>
              <IconButton
                component={isActivating.getState() ? "div" : undefined}
                onClick={
                  isActivating.getState()
                    ? undefined
                    : () => onActivateClick(account)
                }
                disabled={isActivating.getState()}
                aria-label="Activate"
                // onClick={() => onActivateClick(account)}
              >
                <CheckIcon />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title={<LocalizedMessage id="general.edit" />}>
            <IconButton aria-label="edit" onClick={() => onEditClick(account)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {/* <AlertDialog id={account.id} onAgree={onDeleteClick} /> */}
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="account.pageTitle" key="account.pageTitle" />
        }
      />
      <Paper
        dir={Theme.direction}
        style={{
          padding: "4px 24px 0px 24px",
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            paddingBottom: Theme.spacing(10),
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              <LocalizedMessage id="account.pageTitle" />
            </Typography>

            {!openSearchField && downSm && (
              <IconButton onClick={() => setOpenSearchField(true)}>
                <SearchIcon color={"primary"} />
              </IconButton>
            )}
            {(openSearchField || !downSm) && (
              <TextField
                label={intl.formatMessage({ id: "general.search" })}
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
              />
            )}
          </Box>

          <Tooltip title={<LocalizedMessage id="general.add" />}>
            <Fab
              disabled={isFetchingRows}
              color="primary"
              aria-label="add"
              style={{
                position: "absolute",
                bottom: Theme.spacing(2),
                right: Theme.spacing(2),
              }}
              component={isFetchingRows ? "div" : undefined}
              onClick={isFetchingRows ? undefined : openAddAccountForm}
            >
              <AddIcon />
            </Fab>
          </Tooltip>

          <Paper className={classes.root}>
            {/* Start Account Form */}

            <AccountForm
              open={AccountFormOpen}
              setOpen={setAccountFormOpen}
              mode={AccountFormMode}
              editFormData={AccountFormData}
              onAdd={onAddAccountHandler}
              onSave={onEditAccountHandler}
              branches={branches}
              setBranches={setBranches}
              permissions={permissions}
            />
            {/* End Account Form */}
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(searchQuery
                        ? rows
                            .getState()
                            .filter(
                              (row) =>
                                row.name
                                  .toLowerCase()
                                  .indexOf(searchQuery.toLowerCase()) != -1 ||
                                searchQuery.toLowerCase() === ""
                            )
                        : rows.getState()
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.id}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};

Accounts.propTypes = {
  /**
   *
   */
};

Accounts.defaultProps = {
  /**
   *
   */
};

export default Accounts;
