// React
import React from "react";

// Material
import { useTheme, Grid } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";

// Components
import InDrivers from "./InDrivers.js";
import OutDrivers from "./OutDrivers.js";

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles

const Delivery = () => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Event Handlers

  // Other

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6} lg={6} item>
        <OutDrivers />
      </Grid>
      <Grid xs={12} md={6} lg={6} item>
        <InDrivers />
      </Grid>
    </Grid>
  );
};
export default Delivery;
