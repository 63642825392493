// React
import React, { useEffect } from "react";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, AutoCompleteField } from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import _ from "lodash";

// Services
import { useAddPayment, useEditPayment } from "Services/Payments/";
// Styles
const useStyles = makeStyles((theme) => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  typeId: Joi.string().required(),
});

/**
 * @name PaymentForm
 * @summary
 * @Payment
 * @component
 * @description
 * >
 */
const PaymentForm = ({
  editFormData,
  addCallback,
  editCallback,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
  paymentTypes,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const paymentForm = useForm({
    validationSchema,
    languageKey: "setup.payment",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = paymentForm;
  // Effect Hooks

  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      setFormData(_.pick(editFormData, ["name", "otherName", "typeId"]));
    } else setFormData({});
  }, [open]);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const addPayment = useAddPayment({ languageKey: "setup.Payment" });
  const editPayment = useEditPayment({ languageKey: "setup.Payment" });
  // Event Handlers

  const onCLosePaymentForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddPayment = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: paymentForm,
      formName: "payment",
      apiHandler: addPayment,
      onClose: onCLosePaymentForm,
    });
  };

  const onSavePayment = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: paymentForm,
      formName: "payment",
      apiHandler: editPayment,
      onClose: onCLosePaymentForm,
    });
  };

  // Other
  console.log(
    `%cPaymentForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLosePaymentForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>Payment</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Payment Name"}
                helpKey="setup.payment.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />

              <TextField
                fullWidth
                name="otherName"
                autoComplete="otherName"
                tooltip={"Payment Other Name"}
                helpKey="common.otherName"
                label={<LocalizedMessage id="common.otherName" />}
                // maxCharacters={8}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />

              <AutoCompleteField
                required
                fullWidth
                name="typeId"
                tooltip={"Payment Type"}
                helpKey="setup.payment.typeId"
                label={<LocalizedMessage id="setup.payment.typeId" />}
                onChange={onFormFieldChange}
                value={formData.typeId}
                errors={formErrors.typeId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.typeId}
                options={paymentTypes}
              />
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddPayment}
          mode={mode}
          onCLoseForm={onCLosePaymentForm}
          isSubmitting={isSubmitting}
          onSave={onSavePayment}
        />
      </DialogActions>
    </Dialog>
  );
};

PaymentForm.propTypes = {
  /**
   *
   */
};

PaymentForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default PaymentForm;
