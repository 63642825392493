import "./wdyr"; // <--- first import
// Fonts
import "typeface-roboto";
import "typeface-changa";
// React
import React from "react";
import ReactDOM from "react-dom";
// Third Parties

// CSS

// Components
import App from "./App";
// Other
import * as serviceWorker from "./serviceWorker";

if (process.env.NODE_ENV !== "development") console.log = () => {};

// Clear Page Title (To Be Set By Children Components)
document.getElementsByTagName("title")[0].innerHTML = "";

// Render
ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
