// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import { TextField } from "Components/FormFields";

// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  id: Joi.string().guid(),
  onlineDineInCode: Joi.string().required().length(4),
});

/**
 * @name BranchForm
 * @summary
 * @Branch
 * @component
 * @description
 * >
 */
const BranchForm = ({
  editFormData,
  onAdd,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      setFormData(_.pick(editFormData, ["id", "onlineDineInCode"]));
    }
  }, [open]);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "branch" });

  // Event Handlers

  const onCLoseBranchForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const checkKeyDown = (e) => {
    if (e.code === "Enter") onSaveBranch();
  };

  const onSaveBranch = () => {
    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseBranchForm
    );
  };

  // Other
  console.log(
    `%cBranchForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseBranchForm}
      scroll={"paper"}
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="branch.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component="span">
            <LocalizedMessage id="branch.enterCode" />
          </Typography>
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            {/* <form className={classes.form} onKeyDown={(e) => checkKeyDown(e)} noValidate> */}
            <TextField
              required
              label={<LocalizedMessage id="branch.code" />}
              name="onlineDineInCode"
              autoComplete="onlineDineInCode"
              tooltip={"Branch Code"}
              helpKey="Menu.Branch.CodeField"
              autoFocus
              fullWidth
              // maxCharacters={50}
              onChange={onFormFieldChange}
              value={formData.onlineDineInCode}
              errors={formErrors.onlineDineInCode}
              isSubmitting={isSubmitting}
              isLoading={!isLoading}
            />
            {/* </form> */}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          style={{
            backgroundColor: "#0582D2",
            color: "#fff",
            marginRight: "8px",
          }}
          variant="contained"
          onClick={onCLoseBranchForm}
          //startIcon={<CloseIcon />}
        >
          <LocalizedMessage id="general.cancel" />
        </Button>
        <Button
          type="button"
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          onClick={onSaveBranch}
          onKeyDown={(e) => checkKeyDown(e)}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            <LocalizedMessage id="general.edit" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BranchForm.propTypes = {
  /**
   *
   */
};

BranchForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default BranchForm;
