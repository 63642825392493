// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { OnionColoredLogo } from "Assets/Images";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name CRMRow
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CRMRow = ({ row, columns, secondaryColumns }) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [open, setOpen] = useState(false);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  let phoneNumbers = "";
  row.customerPhones.map((phoneNumber) => {
    const value = phoneNumber.phoneNumber;
    phoneNumbers += `${value} - `;
  });
  phoneNumbers = phoneNumbers.slice(0, -2);

  // Component Render
  return (
    <React.Fragment>
      <TableRow hover role="checkbox" tabIndex={-1} key={row.id} className={classes.root}>
        <TableCell />
        {columns.map((column) => (
          <TableCell key={column.id} align={column.align}>
            {column.id !== "customerPhones" ? row[column.id] : phoneNumbers}
          </TableCell>
        ))}
      </TableRow>
    </React.Fragment>
  );
};

CRMRow.propTypes = {
  /**
   *
   */
};

CRMRow.defaultProps = {
  /**
   *
   */
};

export default CRMRow;
