// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  FormHelperText,
  FormControl,
  Box,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";

// Components
import HelpIcon from "Components/HelpIcon";
import { CheckBox } from "Components/FormFields";

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid";
import _ from "lodash";

// Styles
const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    color: theme.palette.text.secondary,
    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
    // "&:focus > $content, &$selected > $content": {
    //   color: ({ color }) => theme.palette.getContrastText(color),
    // },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  checkbox: {
    display: "flex",
    alignItems: "flex-start",
    height: 20,
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "inherit",
    flexGrow: 1,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  fieldWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
    flexShrink: 1,
  },
  field: {
    flex: 2,
    minWidth: 0,
    flexShrink: 1,
    justifyContent: "normal",
    width: "100%",
  },
  formControl: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
}));

// Ad-Hoc Components
function StyledTreeItem(props) {
  const {
    labelText,
    labelIcon: LabelIcon,
    // currentValue,
    // setCurrentValue,
    onEditClick,
    onDeleteClick,
    // selectedRoleIds,
    // setSelectedRoleIds,
    onChange,
    checked,
    ...other
  } = props;

  const classes = useTreeItemStyles();

  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        position: "relative",
      }}
    >
      <CheckBox
        name={props.nodeId}
        // tooltip={'Check the role'}
        // helpKey="Roles.checkbox"
        onChange={onChange}
        // label={labelText}
        value={checked}
        size="small"
      />
      <TreeItem
        label={
          <Box className={classes.labelRoot}>
            <Typography className={classes.labelRoot}>{labelText}</Typography>
          </Box>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        {...other}
      ></TreeItem>
    </Box>
  );
}

StyledTreeItem.propTypes = {
  labelIcon: PropTypes.elementType.isRequired,
  labelText: PropTypes.string.isRequired,
  // currentValue: PropTypes.string,
};

/**
 * @name Tree
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Tree = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  maxCharacters,
  defaultValue,
  value,
  type,
  onChange,
  id,
  isLoading,
  showFirstError: onlyShowFirstError,
  isSubmitting,
  hidden,
  name,
  label,
  disabled,
  required,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4();
  const [currentValue, setCurrentValue] = useState(
    value === undefined || value === null ? [] : value
  );
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    if (typeof onChange === "function") onChange(name, currentValue);
    setCurrentValue(currentValue);

    console.log(
      `%cText Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      ""
    );
  }, []);

  // useEffect(() => {
  //   // console.log(currentValue)
  //   console.log(
  //     `%Tree Field %cValue Changed -> %cName: %c${name} - %cValue: %c${currentValue}`,
  //     "font-weight: bold; color: blue",
  //     "",
  //     "font-weight: bold;",
  //     "",
  //     "font-weight: bold;",
  //     ""
  //   );
  // }, [currentValue]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect");

  const onChangeHandler = (e) => {
    typeof e.persist === "function" && e.persist();
    typeof onChange === "function" && onChange(e.target.name, e.target.value);
  };

  // Other

  const renderChildren = (rolesTables, level, parentLabel, parentID) => {
    const handleOnChange = (name, value) => {
      const setRecursive = (name, obj) => {
        for (var key in obj) {
          name = name + "." + key;
          if (typeof obj[key] == "object" && obj[key] !== null) {
            setRecursive(name, obj[key]);
          } else {
            _.set(currentValue, name, value);
            name = "";
          }
        }
      };

      let target = _.get(currentValue, name);

      if (name) {
        if (typeof target === "boolean") _.set(currentValue, name, value);
        else setRecursive(name, target);
        setCurrentValue({ ...currentValue });
      }
    };

    return (
      typeof rolesTables === "object" &&
      rolesTables !== null &&
      rolesTables &&
      // rolesTables.length &&
      Object.entries(rolesTables).map(([key, value]) => {
        const nodeId = parentLabel ? parentLabel + "." + key : key;

        const eachRecursive = (obj) => {
          let a = true;
          for (var k in obj) {
            if (typeof obj[k] == "object" && obj[k] !== null)
              return a && eachRecursive(obj[k]);
            else a = a && obj[k];
          }
          return a;
        };

        let checked;
        if (typeof value === "boolean") checked = value;
        else checked = eachRecursive(value);

        return (
          <StyledTreeItem
            nodeId={nodeId}
            labelText={key}
            onChange={handleOnChange}
            checked={checked}
            // currentValue={currentValue}
            // setCurrentValue={setCurrentValue}
            // expandAllSiblings
            // onAddClick={() => onAddRole()}
            // onEditClick={() => onSaveRole()}
            // onDeleteClick={() => onDelete(table.id)}
            // onClick={() => setSelectedRoleId(id)}
            // selectedRoleIds={selectedRoleIds}
            // setSelectedRoleIds={setSelectedRoleIds}
          >
            {renderChildren(value, level + 1, nodeId, nodeId)}
          </StyledTreeItem>
        );
      })
    );
  };

  // Component Render
  return (
    <Box className={className} style={{ display: hidden ? "none" : "block" }}>
      <FormControl {...{ margin }} className={classes.root} style={style}>
        <Box className={classes.field}>
          {isLoading && inputSkeleton ? (
            inputSkeleton
          ) : (
            <TreeView
              style={{ display: "inline-block" }}
              ref={inputRef}
              labelId={`${inputFieldID}-label`}
              // defaultValue={defaultValue && getOptionLabel(defaultValue)}
              onChange={onChangeHandler}
              error={Boolean(errors && errors.length > 0)}
              inputProps={{
                autoComplete,
                disabled: isSubmitting || disabled,
                id: inputFieldID,
                ...inputProps,
              }}
              disabled={isSubmitting || disabled}
              variant={variant}
              className={classes.field}
              id={inputFieldID}
              required={required}
              label={label}
              name={name}
              defaultExpanded={["menu", "setup", "POS"]}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 15 }} />}
              onNodeSelect={(obj, val) => {
                // setSelectedRoleIds([...selectedRoleIds.getState(), val])
              }}
              {...otherProps}
            >
              {renderChildren(currentValue, 1, null, null)}
            </TreeView>
          )}
        </Box>
        {/* <HelpIcon
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          {...helpIconProps}
        /> */}
      </FormControl>

      <Box className={classes.root}>
        {(helperText || errors || maxCharacters || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                    <span ref={helperTextRef}>{helperText}</span>
                  ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}

        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  );
};

Tree.propTypes = {
  /**
   *
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  //   validator: PropTypes.func,
  autoFocus: PropTypes.bool,
  tooltip: PropTypes.string,
  helpKey: PropTypes.string,
  maxCharacters: PropTypes.number,
};

Tree.defaultProps = {
  variant: "outlined",
  margin: "normal",
  //   autoComplete: "off",
  showFirstError: true,
  isLoading: false,
  autoFocus: false,
  onChange: () => {},
  fullWidth: true,
};

export default Tree;
