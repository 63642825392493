// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  useTheme,
  InputAdornment,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens
import CityForm from "Screens/Portal/Cities/CityForm";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import { useAddCity } from "Services/Cities/";
import { useGetAllCitiesDropdowns } from "Services/Dropdowns/";
import { useAddZone, useEditZone } from "Services/Zones/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  cityId: Joi.string().uuid().required(),
  minimumOrderValue: Joi.number().min(0),
  deliveryTime: Joi.number().integer().min(0),
});

/**
 * @name ZoneForm
 * @summary
 * @Zone
 * @component
 * @description
 * >
 */
const ZoneForm = ({
  editFormData,
  addCallback,
  editCallback,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
  currentCities,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [cities, setCities] = useGlobalState("cities.cities");
  const [branch, setBranch] = useGlobalState("userData.branch");

  // State Hooks
  const [openCityForm, setOpenCityForm] = React.useState(false);

  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      editFormData = {
        ...editFormData,
        cityId: editFormData.city.id,
      };
      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "cityId",
          "minimumOrderValue",
          "deliveryTime",
        ])
      );
    } else setFormData({});
  }, [open]);

  useEffect(() => {
    if (!cities.isFetchingRows && !cities.data.length)
      getAllCitiesDropdowns()
        .then((res) => {
          setCities({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [cities.isFetchingRows]);

  // Other Hooks
  const addZone = useAddZone({ languageKey: "setup.zone" });
  const editZone = useEditZone({ languageKey: "setup.zone" });
  const addCity = useAddCity({ languageKey: "common.city" });
  const getAllCitiesDropdowns = useGetAllCitiesDropdowns();
  const zoneForm = useForm({ validationSchema, languageKey: "setup.zone" });
  const { enqueueSnackbar } = useSnackbar();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = zoneForm;

  // Event Handlers
  const onOpenCityForm = () => {
    setOpenCityForm(true);
  };

  const onCLoseCityForm = () => {
    setOpenCityForm(false);
  };

  const onCLoseZoneForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddZone = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: zoneForm,
      formName: "zone",
      apiHandler: addZone,
      onClose: onCLoseZoneForm,
    });
  };

  const onSaveZone = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: zoneForm,
      formName: "zone",
      apiHandler: editZone,
      onClose: onCLoseZoneForm,
    });
  };

  const cityDropDownCallback = (response) => {
    setCities({
      ...cities,
      data: [...cities.data, response.data.data],
    });
    setFormData({
      ...formData,
      cityId: response.data.data.id,
    });
  };

  // Other
  console.log(
    `%cZoneForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseZoneForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>Zone</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Zone Name"}
                helpKey="setup.zone.NameField"
                autoFocus
                fullWidth
                //maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                tooltip={"Zone Other Name"}
                helpKey="setup.zone.NameField"
                //maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
              <SelectField
                required
                fullWidth
                name="cityId"
                tooltip={"City"}
                helpKey="setup.zone.cityId"
                label={<LocalizedMessage id="setup.zone.cityId" />}
                onChange={onFormFieldChange}
                value={formData.cityId}
                errors={formErrors.cityId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.cityId}
                openForm={onOpenCityForm}
                options={currentCities}
                addPermission={
                  permissions.isAdmin ||
                  (permissions.city && permissions.city.includes("add"))
                }
              />
              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="setup.zone.minimumOrderValue" />}
                name="minimumOrderValue"
                tooltip={"Zone Other Name"}
                helpKey="setup.zone.NameField"
                //maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.minimumOrderValue}
                errors={formErrors.minimumOrderValue}
                isSubmitting={isSubmitting}
                isLoading={isLoading.minimumOrderValue}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {branch.currencyId}
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="setup.zone.deliveryTime" />}
                name="deliveryTime"
                tooltip={"Zone Other Name"}
                helpKey="setup.zone.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.deliveryTime}
                errors={formErrors.deliveryTime}
                isSubmitting={isSubmitting}
                isLoading={isLoading.deliveryTime}
              />
            </form>
            <CityForm
              open={openCityForm}
              mode={"add"}
              addCallback={cityDropDownCallback}
              onCancel={onCLoseCityForm}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddZone}
          mode={mode}
          onCLoseForm={onCLoseZoneForm}
          isSubmitting={isSubmitting}
          onSave={onSaveZone}
        />
      </DialogActions>
    </Dialog>
  );
};

ZoneForm.propTypes = {
  /**
   *
   */
};

ZoneForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default ZoneForm;
