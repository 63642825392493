// React
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { Typography as MUITypography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({
  left: {
    marginRight: "auto",
  },
  right: {
    marginLeft: "auto",
  },
  center: {
    marginRight: "auto",
    marginLeft: "auto",
  },
}));
// Ad-Hoc Components

/**
 * @name Typography
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Typography = (props) => {
  // Props Destructing
  const { position, className, children, isLoading, ...otherProps } = props;

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [skeletonDimensions, setSkeletonDimensions] = useState({});
  const textRef = useRef();

  // Effect Hooks
  useEffect(() => {
    const { offsetWidth, offsetHeight } = textRef.current;

    setSkeletonDimensions((d) => ({
      ...d,
      height: offsetHeight,
      width: offsetWidth,
    }));
  }, [textRef]);

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return isLoading && skeletonDimensions.height ? (
    <Skeleton
      animation="wave"
      variant="text"
      style={{
        width: skeletonDimensions.width,
        height: skeletonDimensions.height,
      }}
    />
  ) : (
    <MUITypography
      className={[classes[position], className].join(" ")}
      ref={textRef}
      {...otherProps}
    >
      {children}
    </MUITypography>
  );
};

Typography.propTypes = {
  /**
   *
   */
};

Typography.defaultProps = {
  isLoading: false,
  position: undefined,
};

export default Typography;
