// React
import React, { useState, useEffect, useMemo } from "react";

// UI KIT

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties
import _ from "lodash";

// Styles

// Ad-Hoc Components

/**
 * @name useLooseMemo
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const useLooseMemo = (func, deps) => {
  deps = _.isArray(deps) ? deps : [];
  const [stateData, setStateData] = useState([]);

  useEffect(() => {
    setStateData((d) => [...deps]);
  }, []);

  const memoed = useMemo(func, [stateData]);

  if (!_.isEqual(deps, stateData)) {
    setStateData((d) => [...deps]);
    return func();
  }

  // useEffect(() => {
  //   if (!_.isEqual(deps, stateData)) {
  //     setStateData((d) => [...deps]);
  //   }
  // }, [...deps]);

  // console.log(stateData, deps, _.isEqual(deps, stateData));
  return memoed;
};

export default useLooseMemo;
