const structure = {
  dashboard: {
    dashboard: "get",
  },
  live: {
    live: ["summary", "tables", "delivery", "get", "pdf", "orderDetails"],
  },
  setup: {
    employee: ["get", "add", "edit", "active", "deactivate"],
    permissionGroup: ["get", "add", "edit", "delete"],
    salesSection: ["get", "add", "edit", "active", "deactivate"],
    table: ["get", "add", "edit", "delete"],
    orderDiscount: ["get", "add", "edit", "delete"],
    tax: ["get", "add", "edit", "delete"],
    surcharge: ["get", "add", "edit", "delete"],
    paymentMethod: ["get", "add", "edit", "delete"],
    orderNote: ["get", "add", "edit", "delete"],
    itemNote: ["get", "add", "edit", "delete"],
    city: ["get", "add", "edit", "delete"],
    zone: ["get", "add", "edit", "delete"],
    printer: ["get", "add", "edit", "delete"],
    printersDesign: ["receiptDesign", "xReportDesign", "zReportDesign"],
    orderCancellationReason: ["get", "add", "edit", "delete"],
    itemCancellationReason: ["get", "add", "edit", "delete"],
    onlineOrderCancellationReason: ["get", "add", "edit", "delete"],
    tableReservationCancellationReason: ["get", "add", "edit", "delete"],
    generalSettings: ["edit", "get"],
    reportEmail: ["get", "add", "edit", "delete"],
    stockCapacity: ["edit", "get"],
  },
  menu: {
    category: ["get", "add", "edit", "delete"],
    menuItem: ["get", "add", "edit", "downloadTemplate", "importItems"],
  },
  menuItemStock: {
    menuItemStock: ["get", "edit"],
  },
  pos: {
    pos: ["edit", "generateToken", "get"],
  },
  driverLogin: {},
  customer: { customer: ["get"] },
  sales: { sales: ["get", "pdf", "orderDetails"] },

  report: {
    reportSummaryReport: ["get", "pdf"],
    reportSalesByHour: ["get", "pdf"],
    reportSalesByItem: ["get", "pdf"],
    reportSalesByChannel: ["get", "pdf"],
    reportSalesByZone: ["get", "pdf"],
    reportSalesByCashier: ["get", "pdf"],
    reportSalesByDriver: ["get", "pdf"],
    reportVoidOrders: ["get", "pdf"],
    reportVoidItems: ["get", "pdf"],
    reportMergedOrders: ["get", "pdf"],
    reportSalesByMethod: ["get", "pdf"],
    reportSalesByDay: ["get", "pdf"],
    zReport: ["get", "pdf"],
  },
  branch: {},
  brand: {},
  account: {},
  role: {},
  subscriptions: {},
  onlineOrdersSetup: {
    onlineOrdersSetup: ["edit", "get"],
  },
  export: {},
};

export default structure;
