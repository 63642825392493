// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Tabs,
  useTheme,
  Tab,
  Paper,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import ListAltIcon from "@material-ui/icons/ListAlt";
import EventSeatIcon from "@material-ui/icons/EventSeat";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import Title from "Components/Title";
import { useGlobalState } from "Helpers/GlobalState";

// Components

// Factories

// Screens
// import { OnlineOrderCancellationReasons } from "Screens/Portal/Cancellation/OnlineOrderCancellationReasons";
import { OrderCancellationReasons } from "Screens/Portal/Cancellation/OrderCancellationReasons";
import { ItemCancellationReasons } from "Screens/Portal/Cancellation/ItemCancellationReasons";
import { TableReservationCancellationReasons } from "Screens/Portal/Cancellation/TableReservationCancellationReasons";

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views";
import { useSnackbar } from "notistack";

// Services
import useGetAllCities from "Services/Cities/useGetAllCities";
// Styles
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

// Ad-Hoc Components

/**
 * @name Cancellation
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Cancellation = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [value, setValue] = useState(
    permissions.isAdmin ? 0 : permissions.city ? 0 : 1
  );
  // Effect Hooks

  // Other Hooks
  const getAllCities = useGetAllCities();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChangeCities = (cities) => {
    // setCities(cities);
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("xs"));

  // Component Render
  return (
    <div
      style={{
        display: "flex",
        flexDirection: downSm ? "column" : "row",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.cancellationReason.pageTitle"
            key="setup.cancellationReason.pageTitle"
          />
        }
      />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="action tabs example"
        variant="scrollable"
        scrollButtons="on"
        orientation={downSm ? "horizontal" : "vertical"}
      >
        <Tab
          label={
            <LocalizedMessage
              id={"setup.cancellationReason.orderCancellationReason.label"}
            />
          }
          disabled={
            !(permissions.isAdmin || permissions.orderCancellationReason)
          }
          icon={<ListAltIcon />}
        />
        {/* <Tab
          label={
            <LocalizedMessage
              id={
                "setup.cancellationReason.onlineOrderCancellationReason.label"
              }
            />
          }
          disabled={!(permissions.isAdmin || permissions.onlineOrderCancellationReason)}
          icon={<ListAltIcon />}
        /> */}
        <Tab
          label={
            <LocalizedMessage
              id={"setup.cancellationReason.itemCancellationReason.label"}
            />
          }
          disabled={
            !(permissions.isAdmin || permissions.itemCancellationReason)
          }
          icon={<FastfoodIcon />}
        />
        <Tab
          label={
            <LocalizedMessage
              id={
                "setup.cancellationReason.tableReservationCancellationReason.label"
              }
            />
          }
          disabled={
            !(
              permissions.isAdmin ||
              permissions.tableReservationCancellationReasons
            )
          }
          icon={<EventSeatIcon />}
        />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{
          flex: 1,
        }}
      >
        <Paper
          key={0}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.orderCancellationReason) && (
            <OrderCancellationReasons permissions={permissions} />
          )}
        </Paper>

        {/* <Paper
          key={0}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.orderCancellationReason) && (
            <OnlineOrderCancellationReasons permissions={permissions} />
          )}
        </Paper> */}

        <Paper
          key={1}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.itemCancellationReason) && (
            <ItemCancellationReasons permissions={permissions} />
          )}
        </Paper>

        <Paper
          key={2}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin ||
            permissions.tableReservationCancellationReasons) && (
            <TableReservationCancellationReasons permissions={permissions} />
          )}
        </Paper>
      </SwipeableViews>
    </div>
  );
};

Cancellation.propTypes = {
  /**
   *
   */
};

Cancellation.defaultProps = {
  /**
   *
   */
};

export default Cancellation;
