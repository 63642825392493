// React
import React, { useState, useEffect } from "react";

// Material
import { Divider, useTheme } from "@material-ui/core";
import {
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  IconButton,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import ListAltIcon from "@material-ui/icons/ListAlt";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { TextField } from "Components/FormFields";

// Factories

// Screens
import StockCapacityForm from "./StockCapacityForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import { useGetAllStockCapacities, useEditStockCapacity } from "Services/StockCapacities";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 200,
  },
  {
    id: "quantity",
    label: <LocalizedMessage id="common.quantity" />,
    minWidth: 200,
  },
  { id: "actions", label: "", minWidth: 200 },
];

/**
 * @name StockCapacity
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const StockCapacity = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [openSearchField, setOpenSearchField] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = useState(true);
  const [editId, setEditId] = useState("");
  const [isEdited, setIsEdited] = useState(false);

  /* StockCapacity Form */
  const [StockCapacityFormOpen, setStockCapacityFormOpen] = useState(false);
  const [StockCapacityFormMode, setStockCapacityFormMode] = useState("");
  const [StockCapacityFormData, setStockCapacityFormData] = useState({});

  // Effect hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <ListAltIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage id="stockCapacity.pageTitle" key="stockCapacity.pageTitle" />
        </Box>
      ),
    []
  );

  useEffect(() => {
    getAllStockCapacity()
      .then((response) => {
        setRows(response.map((stockCapacity) => createData(stockCapacity)));
        setIsFetchingRows(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [isEdited]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllStockCapacity = useGetAllStockCapacities();
  const editStockCapacity = useEditStockCapacity({
    languageKey: "stockCapacity",
  });
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const onEditClick = (stockCapacity) => {
    setEditId(stockCapacity.id);
    setStockCapacityFormData(stockCapacity);
    setStockCapacityFormMode("edit");
    setStockCapacityFormOpen(true);
  };

  const onEditStockCapacityHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseStockCapacityForm
  ) => {
    const validForm = validateForm();

    if (validForm) {
      const { transactionType, value } = formData;
      let errorsExist = false;
      let quantity = formData.quantity;
      let val = Number(value);
      if (transactionType === "adjustment") quantity = val;
      else if (transactionType === "increment") quantity += val;
      else if (transactionType === "decrement") {
        if (quantity - val < 0) {
          errorsExist = true;
          setFormErrors((fe) => ({
            ...fe,
            value: [<LocalizedMessage id={"validation.value.invalidDecreament"} />],
          }));
        } else {
          quantity -= val;
        }
      }
      if (!errorsExist) {
        setIsSubmiting(true);
        editStockCapacity({
          menuItemId: editId,
          transactionType,
          value: val,
          quantity,
        })
          .then((response) => {
            displayenqueueSnackbar(enqueueSnackbar, "edited");
            setIsEdited(!isEdited);
            setIsSubmiting(false);
            onCLoseStockCapacityForm();
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsSubmiting(false);
          });
      }
    }
  };

  // Other
  function createData(stockCapacity) {
    return {
      id: stockCapacity.menuItemId,
      name: stockCapacity.name,
      otherName: stockCapacity.otherName,
      quantity: stockCapacity.quantity,
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.menuItemStock.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(stockCapacity)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={<LocalizedMessage id="stockCapacity.pageTitle" key="stockCapacity.pageTitle" />}
      />

      <Paper
        dir={Theme.direction}
        style={{
          padding: "4px 24px 24px 24px",
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2">
              <LocalizedMessage id="stockCapacity.pageTitle" />
            </Typography>
            {!openSearchField && downSm && (
              <IconButton onClick={() => setOpenSearchField(true)}>
                <SearchIcon color={"primary"} />
              </IconButton>
            )}
            {(openSearchField || !downSm) && (
              <TextField
                label={intl.formatMessage({ id: "general.search" })}
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
              />
            )}
          </Box>
          <Divider style={{ margin: 10 }} />

          <Paper className={classes.root}>
            {/* Start StockCapacity Form */}

            <StockCapacityForm
              open={StockCapacityFormOpen}
              setOpen={setStockCapacityFormOpen}
              mode={StockCapacityFormMode}
              editFormData={StockCapacityFormData}
              onSave={onEditStockCapacityHandler}
            />
            {/* End StockCapacity Form */}
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(searchQuery
                        ? rows
                            .getState()
                            .filter(
                              (row) =>
                                row.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
                                searchQuery.toLowerCase() === ""
                            )
                        : rows.getState()
                      )
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.format ? column.format(value) : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};

StockCapacity.propTypes = {
  /**
   *
   */
};

StockCapacity.defaultProps = {
  /**
   *
   */
};

export default StockCapacity;
