// React
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    flexDirection: "column",
  },
}));

const BorderLinearProgress = withStyles(
  (theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  }),
  { index: 1 }
)(LinearProgress);
// Ad-Hoc Components

/**
 * @name DownloadTemplateDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const DownloadTemplateDialog = ({ open, onCloseDialog, onDownloadClick }) => {
  // Theme & Style Hooks
  const theme = useTheme();
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  // Effect Hooks

  // Other Hooks
  const componentRef = useRef();

  // Event Handlers

  // Other
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={false}
      fullWidth
      maxWidth="md"
      style={{
        direction: theme.direction,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {<LocalizedMessage id="category.downloadDialog.title" />}
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent className={classes.root}>
        <Typography variant="h6" component="span">
          {<LocalizedMessage id="setup.note.pageTitle" />} :
        </Typography>
        <Typography variant="subtitle2" component="span">
          {<LocalizedMessage id="category.downloadDialog.note1" />}
        </Typography>
        <Typography variant="subtitle2" component="span">
          {<LocalizedMessage id="category.downloadDialog.note2" />}
        </Typography>
        <Typography variant="subtitle2" component="span">
          {<LocalizedMessage id="category.downloadDialog.note3" />}
        </Typography>
        <Typography variant="subtitle2" component="span">
          {<LocalizedMessage id="category.downloadDialog.note4" />}
        </Typography>
        <Typography variant="h6" component="span">
          {<LocalizedMessage id="category.downloadDialog.downloadPhrase" />}
          <Button
            onClick={onDownloadClick}
            component="label"
            color="primary"
            size="small"
          >
            {<LocalizedMessage id="category.downloadDialog.title" />}
          </Button>
        </Typography>
        {/* 
      
        <Typography variant="body1" component="span">
          {<LocalizedMessage id="general.downloadTemplate" />}
        </Typography> */}
      </DialogContent>
      <DialogActions>
        <FormActions onCLoseForm={onCloseDialog} isSubmitting={false} isClose />

        {/* <Button
          onClick={onCloseDialog}
          style={{ marginTop: 20, color: "#0582D2" }}
        >
          <LocalizedMessage id="common.close" />
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

DownloadTemplateDialog.propTypes = {
  /**
   *
   */
};

DownloadTemplateDialog.defaultProps = {
  /**
   *
   */
};

export default DownloadTemplateDialog;
