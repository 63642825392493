/**
 * @module Translations
 * @description
 * > Exports Language Object For All Supported Languages
 * > Reduced To Be Accessible Via Dot Notation
 * @example
 * {
 *  "en": {
 *    "auth.signIn.Title": "Sign In"
 *  },
 *  "ar": {
 *    "auth.signIn.Title": "تسجيل الدخول"
 *  }
 * }
 * @category Translations
 */

// Translations
import * as translations from "./SupportedTranslations";

export default Object.keys(translations).reduce(
  (processedTranslations, currentLocale) => {
    processedTranslations[currentLocale] = (function recursive(
      obj,
      current,
      res = Object()
    ) {
      for (let key in obj) {
        let value = obj[key];
        let newKey = current ? current + "." + key : key; // Do Joining
        if (value && typeof value === "object") {
          res = { ...res, ...recursive(value, newKey, res) }; // It's a nested object
        } else {
          res[newKey] = value; // Translation Text
        }
      }
      return res;
    })(translations[currentLocale]);
    return processedTranslations;
  },
  Object()
);
