// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Paper,
  useTheme,
  Box,
  Button,
  Toolbar,
  Chip,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
} from "Utils/displaySnackbarErrors";
import { getReportDate, getDateDurations } from "Utils";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { TextField } from "Components/FormFields";
import CRMRow from "./CRMRow";
import { DatePicker, SelectField } from "Components/FormFields";

// Factories

// Screens
import OrderDetailsDialog from "./OrderDetailsDialog";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import moment from "moment";
import Joi from "@hapi/joi";
import Memo from "Components/Memo";

// Services
import { useGetAllCRMs } from "Services/CRM/";
import { useGetAllZones } from "Services/Dropdowns/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 660,
    scroll: "auto",
  },
}));

// Ad-Hoc Components
const columns = [
  { id: "name", label: <LocalizedMessage id="common.name" />, minWidth: 100 },
  {
    id: "customerType",
    label: <LocalizedMessage id="crm.customerType" />,
    minWidth: 100,
  },

  { id: "email", label: <LocalizedMessage id="common.email" />, minWidth: 100 },
  {
    id: "customerPhones",
    label: <LocalizedMessage id="common.phoneNumbers" />,
    minWidth: 200,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name FilteredCustomers
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const FilteredCustomers = () => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [branch, setBranch] = useGlobalState("userData.branch");
  const [zones, setZones] = useState([]);
  const [isFetchingZones, setIsFetchingZones] = useState(true);
  const [rows, setRows] = useStateRef([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);
  const { from, to } = getReportDate("today", "", "");
  const [dateFrom, setDateFrom] = useState(from);
  const [dateTo, setDateTo] = useState(to);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateDuration, setDateDuration] = useState("today");
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [count, setCount] = useState(10);

  const validationSchema = Joi.object({
    dateDuration: Joi.string()
      .valid(
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "thisYear",
        "lastYear",
        "custom"
      )
      .required(),
    dateFrom: Joi.date(),
    dateTo: Joi.date().min(Joi.ref("dateFrom")),
    zoneId: Joi.string().uuid().allow(null, ""),
    customerType: Joi.string().allow(null, ""),
    name: Joi.string().allow(null, ""),
    email: Joi.string().allow(null, ""),
    phoneNumber: Joi.string().allow(null, ""),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "report" });

  useEffect(() => {
    getAllZones()
      .then((data) => {
        setZones(data);
        setIsFetchingZones(false);
      })
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
        setIsFetchingZones(false);
      });
  }, []);

  useEffect(() => {
    const { from, to } = getReportDate("today", "", "");
    setFormData({
      dateDuration: "today",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  useEffect(() => {
    handleSearch();
  }, [page]);

  // useEffect(() => {
  //   if (formData.dateDuration === "today") handleSearch();
  // }, [formData.dateDuration]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllCRMs = useGetAllCRMs();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const getAllZones = useGetAllZones();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelectDateDuration = (name, value) => {
    if (value) {
      setDateDuration(value);
      const { from, to } = getReportDate(value, dateFrom, dateTo);
      setDateFrom(from);
      setDateTo(to);
      if (value !== dateDuration && value !== "custom") handleClose();
    }
  };

  const handleSearch = () => {
    const validForm = validateForm();

    if (validForm) {
      setIsFetchingRows(true);

      let {
        dateDuration,
        dateFrom,
        dateTo,
        name,
        email,
        phoneNumber,
        zoneId,
        customerType,
      } = formData;
      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      setFormData({ ...formData, dateFrom: from, dateTo: to });
      getAllCRMs({
        dateFrom: moment(from).format("YYYY-MM-DD"),
        dateTo: moment(to).format("YYYY-MM-DD"),
        pageRows: rowsPerPage,
        pageNumber: page,
        name,
        email,
        phoneNumber,
        zoneId,
        // online: customerType === "online" ? true : false,
      })
        .then((CRMs) => {
          const newCRM = CRMs.rows.map((crm) => {
            return createData(crm);
          });
          setIsFetchingRows(false);
          setRows(newCRM);
          setCount(CRMs.count);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsFetchingRows(false);
        });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onViewOrderDetailsClick = (customer) => {
    setSelectedCustomer(customer);
    setOpenOrderDetailsDialog(true);
  };

  // Other
  function createData(crm) {
    return {
      id: crm.id,
      name: crm.name,
      email: branch.isActiveInOnline
        ? crm.onlineCustomerId
          ? crm.email
            ? crm.email
            : "No value"
          : "No value"
        : null,
      customerPhones: crm.customerPhones,
      customerType: branch.isActiveInOnline ? (
        crm.onlineCustomerId ? (
          <Chip
            label={<LocalizedMessage id="general.online" />}
            style={{ backgroundColor: "#00c853", color: "white" }}
          />
        ) : (
          <Chip label={<LocalizedMessage id="crm.fromPOS" />} color="primary" />
        )
      ) : null,
      actions: (
        <Box>
          <Tooltip title={<LocalizedMessage id="sales.showOrderDetails" />}>
            <Button
              style={{ flex: 1 }}
              onClick={() => onViewOrderDetailsClick(crm)}
              variant="outlined"
              color="secondary"
            >
              <DescriptionIcon style={{ marginRight: 8 }} />
              <LocalizedMessage id="sales.showOrderDetails" />
            </Button>
          </Tooltip>
        </Box>
      ),
    };
  }

  const dateDurations = getDateDurations(intl);
  const customerType = [
    {
      value: "Online",
      text: intl.formatMessage({
        id: "general.online",
      }),
    },
    {
      value: "Offline",
      text: intl.formatMessage({
        id: "general.offline",
      }),
    },
  ];

  const zonesFiltersTags = [];
  zones.forEach((z) => {
    if (formData.zoneId)
      if (z.id === formData.zoneId) zonesFiltersTags.push(z.name);
  });

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));
  console.log("rows.getState()", rows.getState());

  return (
    <Box className={classes.root}>
      <Title
        pageTitle={<LocalizedMessage id="crm.pageTitle" key="crm.pageTitle" />}
      />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h6"
            className={classes.heading}
            style={{ fontSize: "1rem" }}
          >
            <LocalizedMessage id="general.filters" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex">
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "1 1",
              }}
            >
              <Box
                style={{
                  flex: "1 1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <SelectField
                  neglectNone
                  fullWidth
                  name="dateDuration"
                  tooltip={"Date Duration"}
                  helpKey="Menu.category.dateDuration"
                  label={<LocalizedMessage id="report.dateDuration.title" />}
                  onChange={onFormFieldChange}
                  value={formData.dateDuration}
                  errors={formErrors.dateDuration}
                  isSubmitting={isFetchingRows}
                  isLoading={isLoading.dateDuration}
                  options={dateDurations}
                  defaultValue="today"
                />
                {formData.dateDuration === "custom" && (
                  <>
                    <DatePicker
                      name="dateFrom"
                      tooltip={"Date from"}
                      helpKey="common.dateFrom"
                      label={intl.formatMessage({ id: "common.dateFrom" })}
                      onChange={onFormFieldChange}
                      value={formData.dateFrom}
                      errors={formErrors.dateFrom}
                      isSubmitting={isFetchingRows}
                      isLoading={isLoading.dateFrom}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                    />
                    <DatePicker
                      name="dateTo"
                      tooltip={"Date from"}
                      helpKey="common.dateTo"
                      label={intl.formatMessage({ id: "common.dateTo" })}
                      onChange={onFormFieldChange}
                      value={formData.dateTo}
                      errors={formErrors.dateTo}
                      isSubmitting={isFetchingRows}
                      isLoading={isLoading.dateTo}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                    />
                  </>
                )}
              </Box>
              <SelectField
                fullWidth
                name="zoneId"
                tooltip={"zoneId"}
                helpKey="Menu.category.zoneId"
                label={<LocalizedMessage id="setup.zone.pageTitle" />}
                onChange={onFormFieldChange}
                value={formData.zoneId}
                errors={formErrors.zoneId}
                isSubmitting={isFetchingRows}
                isLoading={isLoading.zoneId}
                options={zones.map((z) => ({
                  value: z.id,
                  text: z.name,
                }))}
              />
              {branch.isActiveInOnline && (
                <SelectField
                  fullWidth
                  name="customerType"
                  tooltip={"customerType"}
                  helpKey="Menu.category.orderStatus"
                  label={<LocalizedMessage id="crm.customerType" />}
                  onChange={onFormFieldChange}
                  value={formData.customerType}
                  errors={formErrors.customerType}
                  isSubmitting={isFetchingRows}
                  isLoading={isLoading.customerType}
                  options={customerType}
                />
              )}
            </Box>
            <Box
              style={{
                flex: 1,
                padding: downSm ? 0 : Theme.spacing(0, 2),
                minWidth: 0,
              }}
            >
              <TextField
                fullWidth
                name="name"
                label={<LocalizedMessage id="common.name" />}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isFetchingRows}
                isLoading={isLoading.name}
                variant="outlined"
              />
              {branch.isActiveInOnline && (
                <TextField
                  fullWidth
                  name="email"
                  label={<LocalizedMessage id="common.email" />}
                  onChange={onFormFieldChange}
                  value={formData.email}
                  errors={formErrors.email}
                  isSubmitting={isFetchingRows}
                  isLoading={isLoading.email}
                  variant="outlined"
                />
              )}
              <TextField
                fullWidth
                name="phoneNumber"
                label={<LocalizedMessage id="common.phoneNumbers" />}
                onChange={onFormFieldChange}
                value={formData.phoneNumber}
                errors={formErrors.phoneNumber}
                isSubmitting={isFetchingRows}
                isLoading={isLoading.phoneNumber}
                variant="outlined"
              />
            </Box>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button
              type="button"
              disabled={isFetchingRows}
              onClick={handleSearch}
              variant="contained"
              color="primary"
              style={{ margin: "16px" }}
              startIcon={!isFetchingRows && <DoneIcon />}
            >
              {isFetchingRows ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.apply" />
              )}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Memo deps={[rows.getState()]}>
        {() => (
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: 16,
              overflow: "auto",
            }}
          >
            <Chip
              style={{
                margin: 8,
                padding: 8,
                height: "100%",
                color: "white",
              }}
              color="secondary"
              label={
                <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                  <LocalizedMessage id="report.dateDuration.title" />
                  {": " +
                    dateDurations.filter(
                      (dd) => formData.dateDuration === dd.value
                    )[0]?.text}
                </Typography>
              }
            />
            {formData.dateTo && (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                }}
                color="secondary"
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    {formData.dateTo === formData.dateFrom ? (
                      <>
                        <LocalizedMessage id="report.day" />
                        {": " +
                          moment(formData.dateFrom).format("MMM DD, YYYY")}
                      </>
                    ) : (
                      <>
                        <LocalizedMessage id="common.dateFrom" />
                        {": " +
                          moment(formData.dateFrom).format("MMM DD, YYYY")}
                        <LocalizedMessage id="common.dateTo" />
                        {": " + moment(formData.dateTo).format("MMM DD, YYYY")}
                      </>
                    )}
                  </Typography>
                }
              />
            )}
            {formData.customerType ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                }}
                color="secondary"
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="crm.customerType" />
                    {": " + formData.customerType}
                  </Typography>
                }
              />
            ) : null}
            {zonesFiltersTags.length ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                }}
                color="secondary"
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="setup.zone.pageTitle" />
                    {": " + zonesFiltersTags.join(", ")}
                  </Typography>
                }
              />
            ) : null}
            {formData.name ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                }}
                color="secondary"
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="common.name" />
                    {": " + formData.name}
                  </Typography>
                }
              />
            ) : null}
            {formData.email ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                }}
                color="secondary"
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="common.email" />
                    {": " + formData.email}
                  </Typography>
                }
              />
            ) : null}
            {formData.phoneNumber ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                }}
                color="secondary"
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="common.phoneNumbers" />
                    {": " + formData.phoneNumber}
                  </Typography>
                }
              />
            ) : null}
          </Toolbar>
        )}
      </Memo>
      <Box
        style={{
          // paddingBottom: Theme.spacing(10),
          position: "relative",
        }}
      >
        <Paper className={classes.root}>
          {isFetchingRows && inputSkeleton ? (
            inputSkeleton
          ) : (
            <Box ref={inputRef}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell />

                      {columns.map((column) => (
                        <TableCell
                          key={column.id + "-title"}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          {column.id !== "customerType" && column.id !== "email"
                            ? column.label
                            : branch.isActiveInOnline
                            ? column.label
                            : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.getState().map((row, index) => (
                      <CRMRow
                        key={row.id}
                        row={row}
                        columns={columns}
                        secondaryColumns={[]}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </Paper>
      </Box>
      <OrderDetailsDialog
        open={openOrderDetailsDialog}
        setOpen={setOpenOrderDetailsDialog}
        customerDetails={selectedCustomer}
      />
    </Box>
  );
};
export default FilteredCustomers;
