// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Button,
  useTheme,
  Box,
  Paper,
  Typography,
  useMediaQuery,
  FormControl,
  CircularProgress,
  Divider,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import Title from "Components/Title";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import { CheckBox, TextField } from "Components/FormFields";
import XReportTemplate from "./XReportTemplate";
import XZReportTree from "Components/XZReportTree";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";

// Services
import {
  useGetAllXReportDataJson,
  useGetAllXReportData,
  useEditXReportData,
} from "Services/XReport";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
    paper: {
      display: "flex",
      flexWrap: "wrap",
      margin: 8,
      width: "100%",
    },
    formContainer: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  };
});

// Ad-Hoc Components
const validationSchema = Joi.object({});

/**
 * @name XReport
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const XReport = ({ permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [key, setKey] = useState(0);
  const [prevFormData, setPrevFormData] = useStateRef({});
  const [isFetchingXReportSettings, setIsFetchingXReportSettings] =
    useState(true);
  const [openCustomizeXReport, setOpenCustomizeXReport] = useState(false);
  const [customizeXZReport, setCustomizeXZReport] = useState({});
  const [isFetchingXReportJson, setIsFetchingXReportJson] = useState(true);
  // Effect Hooks
  // React.useEffect(() => setHeading("XReport"), []);
  useEffect(() => {
    getAllXReportData()
      .then((reportData) => {
        setFormData({ customizeXZReport: reportData });
        setPrevFormData({ customizeXZReport: reportData });
        setIsFetchingXReportSettings(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  useEffect(() => {
    getAllXReportDataJson()
      .then((reportData) => {
        setCustomizeXZReport(reportData);
        setIsFetchingXReportJson(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllXReportData = useGetAllXReportData();
  const getAllXReportDataJson = useGetAllXReportDataJson();
  const editXReportData = useEditXReportData({
    languageKey: "common.xReport",
  });
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({
    validationSchema,
    languageKey: "common.xReport",
  });

  // Event Handlers
  const onEditXReportHandler = () => {
    const validForm = validateForm();
    console.log(validForm);
    setIsSubmiting(true);
    const { customizeXZReport } = formData;

    editXReportData({
      ...customizeXZReport,
    })
      .then((response) => {
        displayenqueueSnackbar(enqueueSnackbar, "edited");

        setIsSubmiting(false);
      })
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
        setIsSubmiting(false);
      });
  };

  // Other
  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));
  const downMd = useMediaQuery(Theme.breakpoints.down("md"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.printersDesign.xReport.pageTitle"
            key="setup.printersDesign.xReport.pageTitle"
          />
        }
      />

      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.printersDesign.xReport.pageTitle" />
        </Typography>
      </Box>

      <Box>
        <Paper className={classes.paper} elevation={2} ref={inputRef}>
          <Box className={classes.root}>
            {isFetchingXReportSettings && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box className={classes.formContainer}>
                <form className={classes.form} noValidate>
                  <FormControl className={classes.formControl}>
                    <Box
                      display="flex"
                      flexDirection={downMd ? "column" : "row"}
                      height={"75vh"}
                      overflow="auto"
                    >
                      {/* <Button
                        fullWidth
                        disabled={isFetchingXReportSettings}
                        variant="outlined"
                        onClick={() => {
                          setOpenCustomizeXReport(true);
                        }}
                        color="secondary"
                      >
                        <LocalizedMessage id="setup.printersDesign.xReport.customize" />
                      </Button>
                      <Button
                        fullWidth
                        type="button"
                        color="primary"
                        variant="outlined"
                        disabled={isSubmitting || isFetchingXReportSettings}
                        onClick={onEditXReportHandler}
                        style={{ marginTop: 8 }}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          <LocalizedMessage id="general.save" />
                        )}
                      </Button> */}

                      {!isFetchingXReportJson && !isFetchingXReportSettings && (
                        <Box
                          overflow="auto"
                          maxHeight="100%"
                          minHeight="300px"
                          flex={1}
                        >
                          <XZReportTree
                            key={key}
                            open={openCustomizeXReport}
                            onCancel={() => {
                              console.log(
                                "prevFormData",
                                prevFormData.getState()
                              );

                              setFormData((prev) => ({
                                ...prevFormData.getState(),
                              }));
                              setKey((key) => key + 1);
                              setOpenCustomizeXReport(false);
                            }}
                            onSave={() => {
                              setPrevFormData({ ...formData });
                              setKey((key) => key + 1);
                              setOpenCustomizeXReport(false);
                            }}
                            // required
                            label={
                              <LocalizedMessage id="setup.printersDesign.xReport.customize" />
                            }
                            name="customizeXZReport"
                            autoComplete="customizeXZReport"
                            tooltip={"permission customizeXZReport"}
                            helpKey="permission.CheckboxField"
                            autoFocus
                            fullWidth
                            // maxCharacters={50}
                            onChange={onFormFieldChange}
                            value={formData.customizeXZReport}
                            errors={formErrors.customizeXZReport}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.customizeXZReport}
                            customizeXZReport={customizeXZReport}
                          />
                        </Box>
                      )}
                      <Divider
                        orientation={downMd ? "horizontal" : "vertical"}
                        style={{ margin: downMd ? "16px 0px" : "0px 16px" }}
                      />

                      <Box flex={1} height="100%">
                        {formData.customizeXZReport && (
                          <XReportTemplate
                            customizeXZReport={formData.customizeXZReport}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      justifyContent={downMd ? "center" : "end"}
                      marginTop="16px"
                    >
                      <Button
                        // fullWidth
                        type="button"
                        color="primary"
                        variant="contained"
                        disabled={isSubmitting}
                        onClick={() => {
                          setPrevFormData({ ...formData });
                          setKey((key) => key + 1);
                          setOpenCustomizeXReport(false);
                          onEditXReportHandler();
                        }}
                        // style={{ marginTop: 8 }}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={20} />
                        ) : (
                          <LocalizedMessage id="general.save" />
                        )}
                      </Button>
                    </Box>
                  </FormControl>
                </form>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

XReport.propTypes = {
  /**
   *
   */
};

XReport.defaultProps = {
  /**
   *
   */
};

export default XReport;
