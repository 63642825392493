// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Tabs,
  useTheme,
  Tab,
  Paper,
  useMediaQuery,
  Box,
} from "@material-ui/core";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import Title from "Components/Title";
import { useGlobalState } from "Helpers/GlobalState";

// Components

// Factories

// Screens
import { Cities } from "Screens/Portal/Cities";
import { Zones } from "Screens/Portal/Zones";

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views";
import { useSnackbar } from "notistack";

// Services
import useGetAllCities from "Services/Cities/useGetAllCities";
// Styles
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));

// Ad-Hoc Components

/**
 * @name Delivery
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Delivery = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [value, setValue] = useState(
    permissions.isAdmin ? 0 : permissions.city ? 0 : 1
  );

  // Effect Hooks

  // Other Hooks
  const getAllCities = useGetAllCities();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleChangeCities = (cities) => {
    // setCities(cities);
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("xs"));

  // Component Render
  return (
    <div
      style={{
        display: "flex",
        flexDirection: downSm ? "column" : "row",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.delivery.pageTitle"
            key="setup.delivery.pageTitle"
          />
        }
      />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="action tabs example"
        variant="scrollable"
        scrollButtons="on"
        orientation={downSm ? "horizontal" : "vertical"}
      >
        <Tab
          label={<LocalizedMessage id={"setup.city.pageTitle"} />}
          disabled={!(permissions.isAdmin || permissions.city)}
          icon={<LocationCityIcon />}
        />
        <Tab
          label={<LocalizedMessage id={"setup.zone.pageTitle"} />}
          disabled={!(permissions.isAdmin || permissions.zone)}
          icon={<LocationSearchingIcon />}
        />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{
          flex: 1,
        }}
      >
        <Paper
          key={0}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.city) && (
            <Cities permissions={permissions} />
          )}
        </Paper>

        <Paper
          key={1}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.zone) && (
            <Zones permissions={permissions} />
          )}
        </Paper>
      </SwipeableViews>
    </div>
  );
};

Delivery.propTypes = {
  /**
   *
   */
};

Delivery.defaultProps = {
  /**
   *
   */
};

export default Delivery;
