import axios from 'axios'
import { useGlobalState } from 'Helpers/GlobalState'

const { REACT_APP_API_ENDPOINT, REACT_APP_WEB_ENDPOINT } = process.env

const useAxios = ({ api }) => {
  const [userAuth] = useGlobalState('userData.auth')
  const [branch] = useGlobalState("userData.branch");

  return axios.create({
    baseURL: (api ? REACT_APP_API_ENDPOINT : REACT_APP_WEB_ENDPOINT) + '/',
    timeout: 30000,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userAuth.token}`,
      branchId: branch.id,
    },
  })
}

export default useAxios
