// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";
import Title from "Components/Title";

// Factories

// Screens
import TaxForm from "./TaxForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services
import { useDeleteTax } from "Services/Taxes/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 200,
  },
  {
    id: "value",
    label: <LocalizedMessage id="common.value" />,
    minWidth: 100,
  },
  {
    id: "taxType",
    label: <LocalizedMessage id="setup.tax.taxType" />,
    minWidth: 100,
    format: (col) => <LocalizedMessage id={`common.${col}`} />,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name Taxes
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Taxes = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [taxes, setTaxes] = useGlobalState("taxes.taxes");
  const [branch, setBranch] = useGlobalState("userData.branch");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [editId, setEditId] = React.useState("");

  /* Tax Form */
  const [taxFormOpen, setTaxFormOpen] = useState(false);
  const [taxFormMode, setTaxFormMode] = useState("");
  const [taxFormData, setTaxFormData] = useState({});

  // Effect Hooks
  useEffect(() => {
    if (taxes.data) setRows(taxes.data.map((tax) => createData(tax)));
    else setRows([]);
  }, [taxes]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const deleteTax = useDeleteTax();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddTaxForm = () => {
    setTaxFormData({});
    setTaxFormMode("add");
    setTaxFormOpen(true);
  };

  const onEditClick = (tax) => {
    setEditId(tax.id);
    setTaxFormData(tax);
    setTaxFormMode("edit");
    setTaxFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteTax(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setTaxes({ ...taxes, data: taxes.data.filter((row) => row.id !== id) });
      })
      .catch((errors) => {
        errors.forEach((e) => {
          enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
            variant: "error",
            autoHideDuration: 3000,
          });
        });
      });
  };
  const addCallback = (response) => {
    setTaxes({ ...taxes, data: [...taxes.data, response.data.data] });
  };

  const editCallback = (response) => {
    const index = taxes.data.findIndex((row) => row.id === editId);
    taxes.data.splice(index, 1, response.data.data);
    setTaxes({ ...taxes });
  };

  // Other
  function createData(tax) {
    return {
      id: tax.id,
      name: tax.name,
      otherName: tax.otherName,
      value:
        tax.valueType === "PERCENTAGE"
          ? tax.value + "%"
          : tax.value + " " + branch.currencyId,
      taxType: tax.taxType,
      salesSections: tax.salesSections,
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.tax.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(tax)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.tax.includes("delete")) && (
            <AlertDialog id={tax.id} onAgree={onDeleteClick} />
          )} */}
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.tax.pageTitle"
            key="setup.tax.pageTitle"
          />
        }
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.tax.pageTitle" />
        </Typography>
        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            style={{
              marginRight: Theme.spacing(1),
              marginLeft: Theme.spacing(1),
            }}
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.tax.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={taxes.isFetchingRows}
            component={taxes.isFetchingRows ? "div" : undefined}
            onClick={taxes.isFetchingRows ? undefined : openAddTaxForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        {/* Start Tax Form */}
        <TaxForm
          open={taxFormOpen}
          setOpen={setTaxFormOpen}
          mode={taxFormMode}
          editId={editId}
          editFormData={taxFormData}
          addCallback={addCallback}
          editCallback={editCallback}
          permissions={permissions}
          parent="tax"
        />
        {/* End Tax Form */}
        {taxes.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.name
                              .toLowerCase()
                              .indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

Taxes.propTypes = {
  /**
   *
   */
};

Taxes.defaultProps = {
  /**
   *
   */
};

export default Taxes;
