// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box as MUIBox } from "@material-ui/core";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Box
 * @summary Material UI Extended Box Component
 * @category
 * @component
 * @description
 * >
 */
const Box = (props) => {
  // Props Destructing
  const { skeleton, isLoading, children, ...otherProps } = props;

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <MUIBox {...otherProps}>
      {skeleton && isLoading ? skeleton : children}
    </MUIBox>
  );
};

Box.propTypes = {
  /**
   *
   */
  className: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  style: PropTypes.object,
};

Box.defaultProps = {
  isLoading: false,
};

export default Box;
