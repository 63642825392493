// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useLooseMemo from "Helpers/useLooseMemo";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import AlertDialog from "Components/AlertDialog";
import { DragHandle } from "Components/Sortable";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import LazyLoad from "react-lazyload";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    display: "unset",
  },
  media: {
    minHeight: "223px",
    height: 0,
    // paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components

/**
 * @name ItemCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ItemCard = ({ style, item, onViewItem, permissions, onDeleteItem }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [branch, setBranch] = useGlobalState("userData.branch");

  // State Hooks
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const downMd = useMediaQuery(Theme.breakpoints.down("md"));

  // Component Render
  return useLooseMemo(
    () => (
      <Card style={{ ...style, display: "flex", flexDirection: "column" }}>
        <LazyLoad once>
          <CardMedia
            component="img"
            image={item.image}
            className={classes.media}
            title={item.title}
            style={{
              borderBottom: item.image ? `5px solid ${item.color}` : 0,
              backgroundColor: item.image ? undefined : `${item.color}`,
            }}
          />
        </LazyLoad>
        <CardContent
          style={{ display: "flex", flexDirection: "column", flex: 0.5 }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              {item.name}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <Typography
              variant="subtitle2"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Typography variant="inherit">
                <LocalizedMessage id="item.ordered" />:
              </Typography>
              &nbsp;
              <Typography variant="inherit" color="secondary">
                {numeral(item.orderedMenuItem).format("0,0a")}
              </Typography>
            </Typography>
            <Typography variant="h6" color="primary" component="h2">
              {`${numeral(item.defaultPrice).format("0,0.0")} ${
                branch.currencyId
              }`}
            </Typography>
          </Box>
        </CardContent>
        {(permissions.isAdmin ||
          (permissions.menuItem && permissions.menuItem.includes("edit"))) && (
          <CardActions className={classes.root}>
            <Box justifyContent="space-between" display="flex">
              <Box>
                {downMd && <DragHandle />}
                <Button
                  variant="outlined"
                  onClick={onViewItem}
                  color="secondary"
                >
                  <LocalizedMessage id={"item.viewItem"} />
                </Button>
              </Box>

              {/* <AlertDialog
                onAgree={onDeleteItem}
                //deleteText={<LocalizedMessage id={"general.delete"} />}
                outlined={true}
              /> */}
            </Box>
          </CardActions>
        )}
      </Card>
    ),
    [style, item, downMd]
  );
};

ItemCard.propTypes = {
  /**
   *
   */
};

export default ItemCard;
