// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import "date-fns";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  useTheme,
  CircularProgress,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { searchTree } from "Utils/";
import { handler } from "Helpers/Handlers";

// Components
import {
  TextField,
  SelectField,
  ColorPickerField,
  CheckBox,
  TimePicker,
} from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens
import SalesChannelForm from "Screens/Portal/SalesChannels/SalesChannelForm";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import { useGetAllSalesSectionsDropdowns } from "Services/Dropdowns/";
import {
  useAddCategory,
  useGetCategory,
  useDeleteCategory,
  useEditCategory,
} from "Services/Category/";

// Styles
const useStyles = makeStyles(() => ({}));

// const salesSectionTypeSchema = {
//   name: Joi.string().required(),
//   excludedSalesSectionTypes: Joi.array().items(Joi.string().uuid()),
// }
const weekSchema = Joi.string().valid(
  "saturday",
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday"
);

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  parentId: Joi.string().guid(),
  color: Joi.string().required(),
  salesSections: Joi.array().items(Joi.string().uuid()),
  isAlwaysAvailable: Joi.boolean(),
  weekDays: Joi.array().items(weekSchema),
  isAllDay: Joi.boolean(),
  isActive: Joi.boolean(),
  timeFrom: Joi.when("isAllDay", { is: "false", then: Joi.date().required() }),
  timeTo: Joi.when("isAllDay", {
    is: "false",
    then: Joi.date().min(Joi.ref("timeFrom")).required(),
  }),
});

/**
 * @name CategoryForm
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CategoryForm = ({
  editFormData,
  onCancel,
  mode,
  open,
  setOpen,
  permissions,
  categories,
  setCategories,
  setSelectedCategory,
  editId,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState("salesSections.salesSections");
  // State Hooks
  // const [options, setOptions] = React.useState([])
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      if (!editFormData.parentCategory)
        //i.e enter if parent
        // eslint-disable-next-line react-hooks/exhaustive-deps
        editFormData = {
          ...editFormData,
          salesSections: editFormData.salesSections.map((salesSection) => salesSection.id),
        };
      editFormData = {
        ...editFormData,
        weekDays: editFormData.weekDays ? editFormData.weekDays : [],
      };
      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "color",
          "salesSections",
          "isAlwaysAvailable",
          "weekDays",
          "isAllDay",
          "isActive",
          "timeFrom",
          "timeTo",
        ])
      );
    } else setFormData({ isAlwaysAvailable: true, isAllDay: true, isActive: true });
  }, [open]);

  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  // Other Hooks
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();
  const addCategory = useAddCategory({ languageKey: "category" });
  const editCategory = useEditCategory({ languageKey: "category" });
  const getCategory = useGetCategory({ languageKey: "category" });

  const categoryForm = useForm({ validationSchema, languageKey: "category" });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = categoryForm;

  // Event Handlers
  const openAddCategoryForm = (parentCategory) => {
    setOpen(true);
  };

  const onCLoseCategoryForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddCategory = () => {
    formData.parentId = editFormData.parentCategory ? editFormData.parentCategory.id : undefined;

    const callback = (response) => {
      let newCategory, firstParent;
      if (formData.parentId) {
        firstParent = categories.filter((category) => {
          const result = searchTree(category, formData.parentId);
          if (result) return result;
        })[0];
        getCategory(firstParent.id)
          .then((newCategory) => {
            const index = categories.findIndex((category) => category.id === newCategory.id);

            categories.splice(index, 1, newCategory);

            setCategories([...categories]);
            onCLoseCategoryForm();
            setSelectedCategory(newCategory);
          })
          .catch((errors) => {
            displaySnackbarErrors(errors, enqueueSnackbar);
            setIsSubmiting(false);
          });
      } else {
        newCategory = response.data.data;

        if (!newCategory.children.length) newCategory.children = undefined;
        setSelectedCategory(newCategory);
        setCategories([...categories, newCategory]);
      }
    };

    handler({
      callback,
      enqueueSnackbar,
      form: categoryForm,
      formName: "category",
      apiHandler: addCategory,
      onClose: onCLoseCategoryForm,
    });
  };

  const onSaveCategory = () => {
    formData.parentId = editFormData.parentCategory ? editFormData.parentCategory.id : undefined;

    const callback = (response) => {
      let newCategory, firstParent;
      if (formData.parentId) {
        firstParent = categories.filter((category) => {
          const result = searchTree(category, formData.parentId);
          if (result) return result;
        })[0];
        getCategory(firstParent.id).then((newCategory) => {
          const index = categories.findIndex((category) => category.id === newCategory.id);
          categories.splice(index, 1, newCategory);

          setCategories([...categories]);
          onCLoseCategoryForm();
        });
      } else {
        newCategory = response.data.data;
        if (!newCategory.children.length) newCategory.children = undefined;
        const index = categories.findIndex((category) => category.id === newCategory.id);
        categories.splice(index, 1, newCategory);

        setCategories([...categories]);
      }
    };

    handler({
      editId,
      callback,
      enqueueSnackbar,
      form: categoryForm,
      formName: "category",
      apiHandler: editCategory,
      onClose: onCLoseCategoryForm,
    });
  };
  const onOpenSalesChannelForm = () => {
    setSalesChannelFormOpen(true);
  };

  const onCloseSalesChannelForm = () => {
    setSalesChannelFormOpen(false);
  };

  const salesChannelCallback = (response) => {
    const salesSection = response.data.data;
    const newSalesSection = {
      value: salesSection.id,
      text: salesSection.name,
      salesSectionTypeId: salesSection.salesSectionTypeId,
    };
    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, salesSection],
    });
    const formDataSalesSections = formData.salesSections ? formData.salesSections : [];

    setFormData({
      ...formData,
      salesSections: [...formDataSalesSections, newSalesSection.value],
    });
  };

  // Other
  const weekDays = [
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.saturday" }),
      value: "saturday",
    },
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.sunday" }),
      value: "sunday",
    },
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.monday" }),
      value: "monday",
    },
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.tuesday" }),
      value: "tuesday",
    },
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.wednesday" }),
      value: "wednesday",
    },
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.thursday" }),
      value: "thursday",
    },
    {
      text: intl.formatMessage({ id: "category.weekDaysNames.friday" }),
      value: "friday",
    },
  ];

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseCategoryForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="common.category" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />
              <b> {formData.name}</b>
            </Typography>
          )}

          {editFormData.parentCategory && (
            <Typography component="span">
              <br />
              <LocalizedMessage id="category.subCategory" />
              <b>{editFormData.parentCategory.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Category Full Name"}
                helpKey="Menu.category.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                name="otherName"
                autoComplete="otherName"
                label={intl.formatMessage({ id: "common.otherName" })}
                tooltip={"Category Other Name"}
                helpKey="Menu.category.otherNameField"
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
              <ColorPickerField
                required
                fullWidth
                label={<LocalizedMessage id="common.color" />}
                name="color"
                tooltip={"Color of the category when displayed at the POS and Reports"}
                helpKey="Menu.Category.ColorField"
                value={formData.color ? formData.color : "#e89f71"}
                onChange={onFormFieldChange}
                errors={formErrors.color}
                isSubmitting={isSubmitting}
                isLoading={isLoading.color}
              />

              {!editFormData.parentCategory && (
                <>
                  <SelectField
                    fullWidth
                    name="salesSections"
                    tooltip={"Sales Sections"}
                    helpKey="common.salesSections"
                    label={<LocalizedMessage id="common.salesSections" />}
                    onChange={onFormFieldChange}
                    value={formData.salesSections}
                    errors={formErrors.salesSections}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.salesSections}
                    options={salesSections.data.map((s) => ({
                      value: s.id,
                      text: s.name,
                    }))}
                    openForm={onOpenSalesChannelForm}
                    addPermission={
                      permissions.isAdmin ||
                      (permissions.salesSection && permissions.salesSection.includes("add"))
                    }
                    multiple
                  />
                  <CheckBox
                    fullWidth
                    name="isActive"
                    tooltip={"Active?"}
                    helpKey="common.isActive"
                    label={<LocalizedMessage id="common.isActive" />}
                    onChange={onFormFieldChange}
                    value={formData.isActive}
                    errors={formErrors.isActive}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.isActive}
                  />
                  <CheckBox
                    fullWidth
                    name="isAlwaysAvailable"
                    tooltip={"Always available?"}
                    helpKey="common.isAlwaysAvailable"
                    label={<LocalizedMessage id="common.isAlwaysAvailable" />}
                    onChange={onFormFieldChange}
                    value={formData.isAlwaysAvailable}
                    defaultValue={true}
                    errors={formErrors.isAlwaysAvailable}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.isAlwaysAvailable}
                  />
                  {!formData.isAlwaysAvailable && (
                    <>
                      <SelectField
                        fullWidth
                        name="weekDays"
                        tooltip={"Week days"}
                        helpKey="common.weekDays"
                        label={<LocalizedMessage id="common.weekDays" />}
                        onChange={onFormFieldChange}
                        value={formData.weekDays}
                        errors={formErrors.weekDays}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading.weekDays}
                        options={weekDays}
                        multiple
                      />

                      <CheckBox
                        fullWidth
                        name="isAllDay"
                        tooltip={"Available all day"}
                        helpKey="common.isAllDay"
                        label={<LocalizedMessage id="common.isAllDay" />}
                        onChange={onFormFieldChange}
                        value={formData.isAllDay}
                        errors={formErrors.isAllDay}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading.isAllDay}
                      />
                      {!formData.isAllDay && (
                        <>
                          <TimePicker
                            fullWidth
                            name="timeFrom"
                            tooltip={"Time from"}
                            helpKey="common.timeFrom"
                            label={<LocalizedMessage id="common.timeFrom" />}
                            onChange={onFormFieldChange}
                            value={formData.timeFrom}
                            errors={formErrors.timeFrom}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.timeFrom}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <TimePicker
                            fullWidth
                            name="timeTo"
                            tooltip={"Time to"}
                            helpKey="common.timeTo"
                            label={<LocalizedMessage id="common.timeTo" />}
                            onChange={onFormFieldChange}
                            value={formData.timeTo}
                            errors={formErrors.timeTo}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.timeTo}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </form>
            <SalesChannelForm
              open={salesChannelFormOpen}
              mode="add"
              permissions={permissions}
              addCallback={salesChannelCallback}
              onCancel={onCloseSalesChannelForm}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddCategory}
          mode={mode}
          onCLoseForm={onCLoseCategoryForm}
          isSubmitting={isSubmitting}
          onSave={onSaveCategory}
        />
      </DialogActions>
    </Dialog>
  );
};

CategoryForm.propTypes = {
  /**
   *
   */
};

CategoryForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default CategoryForm;
