// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import FilterListIcon from "@material-ui/icons/FilterList";
import CropFreeIcon from "@material-ui/icons/CropFree";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import { TextField } from "Components/FormFields";
import SelectField from "Components/SelectField";
import AlertDialog from "Components/AlertDialog";
import Title from "Components/Title";
import QRDialog from "Components/QRDialog";
import SortableBody from "Components/Sortable";

// Factories

// Screens
import TableForm from "./TableForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import arrayMove from "array-move";

// Services
import { useGetAllTables, useDeleteTable } from "Services/Tables/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "drag",
    label: <LocalizedMessage id="general.drag" />,
    minWidth: 20,
    align: "center",
  },
  {
    id: "number",
    label: <LocalizedMessage id="setup.table.number" />,
    minWidth: 200,
  },
  {
    id: "salesSectionName",
    label: <LocalizedMessage id="setup.table.salesSectionName" />,
    minWidth: 100,
  },
  {
    id: "capacity",
    label: <LocalizedMessage id="setup.table.capacity" />,
    minWidth: 100,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name Tables
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Tables = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState(
    "salesSections.salesSections"
  );
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [openSelectField, setOpenSelectField] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);
  const [editId, setEditId] = React.useState("");
  const [openQrDialog, setOpenQRDialog] = useState(false);
  const [qrData, setQRData] = useState("");
  /* Table Form */
  const [tableFormOpen, setTableFormOpen] = useState(false);
  const [tableFormMode, setTableFormMode] = useState("");
  const [tableFormData, setTableFormData] = useState({});
  const [selectedSalesSection, setSelectedSalesSection] = React.useState({});

  // Effect Hooks
  useEffect(() => {
    getAllTables()
      .then((tables) => {
        const newTables = tables.map((table) => createData(table));
        setIsFetchingRows(false);
        setRows(newTables);
      })
      .catch((errors) => {
        console.log(errors);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllTables = useGetAllTables();
  const deleteTable = useDeleteTable();

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddTableForm = () => {
    setTableFormData({});
    setTableFormMode("add");
    setTableFormOpen(true);
  };

  const onEditClick = (table) => {
    setEditId(table.id);
    setTableFormData(table);
    setTableFormMode("edit");
    setTableFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteTable(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setRows(rows.getState().filter((row) => row.id !== id));
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };
  const editCallback = (response) => {
    const newRows = rows.getState();
    const index = newRows.findIndex((row) => row.id === editId);
    newRows.splice(index, 1, createData(response.data.data));
    setRows(newRows);
  };

  const addCallback = (response) => {
    setRows([...rows.getState(), createData(response.data.data)]);
  };

  const handleOpenQrDialog = (tableLink) => {
    setOpenQRDialog(true);
    setQRData(tableLink);
  };
  // Other

  const createData = (table) => {
    return {
      id: table.id,
      number: table.number,
      capacity: table.capacity,
      salesSection: table.salesSection,
      salesSectionName: table.salesSection.name,
      tableLink: table.tableLink,
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.table.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(table)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.table.includes("delete")) && (
            <AlertDialog id={table.id} onAgree={onDeleteClick} />
          )} */}
          {(permissions.isAdmin || permissions.table) && (
            <Tooltip title={<LocalizedMessage id="general.scanQr" />}>
              <IconButton
                aria-label="scan"
                onClick={() => handleOpenQrDialog(table.tableLink)}
              >
                <CropFreeIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    };
  };

  const filterResult = selectedSalesSection
    ? rows
        .getState()
        .filter((row) => row.salesSection.id === selectedSalesSection)
    : rows.getState();

  const searchResult = searchQuery
    ? filterResult.filter(
        (row) =>
          row.number.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
          searchQuery.toLowerCase() === ""
      )
    : filterResult;

  const data = searchResult.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.table.pageTitle"
            key="setup.table.pageTitle"
          />
        }
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between ",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.table.pageTitle" />
        </Typography>
        <Box
          style={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <Box flex={2} minWidth={0}>
            {salesSections.data.length && downSm && !openSelectField ? (
              <IconButton onClick={() => setOpenSelectField(true)}>
                <FilterListIcon color={"primary"} />
              </IconButton>
            ) : salesSections.data.length ? (
              openSelectField || !downSm ? (
                <SelectField
                  label={<LocalizedMessage id="setup.salesChannel.pageTitle" />}
                  size={"small"}
                  validator={() => []}
                  style={{ margin: Theme.spacing(1) }}
                  options={salesSections.data
                    .map((s) => {
                      if (s.salesSectionTypeId === "DineIn")
                        return { value: s.id, text: s.name };
                    })
                    .filter((s) => s !== undefined)}
                  value={
                    selectedSalesSection
                      ? selectedSalesSection
                      : { value: "", text: "None" }
                  }
                  onChange={(e) => setSelectedSalesSection(e.target.value)}
                />
              ) : null
            ) : null}
          </Box>
          <Box flex={1} minWidth={0}>
            {!openSearchField && downSm && (
              <IconButton onClick={() => setOpenSearchField(true)}>
                <SearchIcon color={"primary"} />
              </IconButton>
            )}
            {(openSearchField || !downSm) && (
              <TextField
                style={{
                  marginRight: Theme.spacing(1),
                  marginLeft: Theme.spacing(1),
                }}
                label={<LocalizedMessage id="general.search" />}
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
              />
            )}
          </Box>
        </Box>
      </Box>
      {(permissions.isAdmin || permissions.table.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={isFetchingRows}
            component={isFetchingRows ? "div" : undefined}
            onClick={isFetchingRows ? undefined : openAddTableForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        {/* Start Table Form */}
        <TableForm
          open={tableFormOpen}
          setOpen={setTableFormOpen}
          mode={tableFormMode}
          editFormData={tableFormData}
          editId={editId}
          permissions={permissions}
          editCallback={editCallback}
          addCallback={addCallback}
        />
        {/* End Table Form */}
        {isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <SortableBody
                  data={data}
                  columns={columns}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    setRows((elements) => [
                      ...arrayMove(elements, oldIndex, newIndex),
                    ]);
                  }}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
        <QRDialog
          qrData={qrData}
          open={openQrDialog}
          onCloseDialog={() => setOpenQRDialog(false)}
        />
      </Paper>
    </Box>
  );
};

Tables.propTypes = {
  /**
   *
   */
};

Tables.defaultProps = {
  /**
   *
   */
};

export default Tables;
