// React
import React, { useState, useEffect } from "react";

// Material
import {
  useTheme,
  Button,
  CircularProgress,
  useMediaQuery,
  Toolbar,
  Chip,
  Box,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DoneIcon from "@material-ui/icons/Done";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import { useForm } from "Helpers/Hooks";
import { getReportDate, getDateDurations } from "Utils";
import { displaySnackbarFormErrors } from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components
import { DatePicker, SelectField } from "Components/FormFields";
import App from "../PDFViewer/App";
import Memo from "Components/Memo";
import SendEmailsDialog from "./SendEmailsDialog";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import moment from "moment";

// Services
import { useGetAllReportEmails } from "Services/Dropdowns/";
import { useGetZReportPdf } from "Services/Reports/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components

/**
 * @email ZReport
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ZReport = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);
  const [pdf, setPdf] = useState("");
  const [hasPdfAccess, setHasPdfAccess] = useState(
    permissions.isAdmin || permissions.zReport.includes("pdf")
  );
  const [reportEmails, setReportEmails] = useState([]);
  const [isFetchingEmails, setIsFetchingEmails] = useState(true);
  const [openEmailsDialog, setOpenEmailsDialog] = useState(false);

  // Effect Hooks
  useEffect(() => {
    getAllReportEmails()
      .then((emails) => {
        setReportEmails(emails.map((email) => createData(email)));
        setIsFetchingEmails(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingEmails(false);
      });
  }, []);

  useEffect(() => {
    if (permissions.isAdmin) {
      setHasPdfAccess(true);
    } else {
      if (permissions.zRepot.includes("pdf")) setHasPdfAccess(true);
    }
  }, []);

  useEffect(() => {
    const { from } = getReportDate("today", "", "");
    setFormData({
      orderDay: from,
    });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const getAllReportEmails = useGetAllReportEmails();
  const getZReportPdf = useGetZReportPdf();
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Joi.object({
    dateDuration: Joi.string()
      .valid(
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "thisYear",
        "lastYear",
        "custom"
      )
      .required(),
    dateFrom: Joi.date(),
    dateTo: Joi.date().min(Joi.ref("dateFrom")),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "reports" });
  useEffect(() => {
    const { from, to } = getReportDate("today", "", "");
    setFormData({
      dateDuration: "today",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  useEffect(() => {
    handleGetZReport();
  }, []);

  // Event Handlers
  const handleGetZReport = () => {
    const validForm = validateForm();

    if (validForm) {
      setIsFetchingPdf(true);
      setPdf("");
      let { dateDuration, dateFrom, dateTo } = formData;
      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      if (hasPdfAccess)
        getZReportPdf({
          dateFrom: from,
          dateTo: to,
        })
          .then((response) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            setPdf(objectUrl);
            setIsFetchingPdf(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingPdf(false);
          });
      else setIsFetchingPdf(false);
    }
  };

  // Other
  function createData(reportEmail) {
    return {
      id: reportEmail.id,
      email: reportEmail.email,
    };
  }
  const dateDurations = getDateDurations(intl);

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Paper
        style={{
          marginTop: theme.spacing(2),
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="h6"
              className={classes.heading}
              style={{ fontSize: "1rem" }}
            >
              <LocalizedMessage id="report.reportFilters" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box display="flex">
              <Box
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <SelectField
                  fullWidth
                  neglectNone
                  name="dateDuration"
                  tooltip={"Date Duration"}
                  helpKey="Menu.category.dateDuration"
                  label={<LocalizedMessage id="report.dateDuration.title" />}
                  onChange={onFormFieldChange}
                  value={formData.dateDuration}
                  errors={formErrors.dateDuration}
                  isSubmitting={isFetchingPdf}
                  isLoading={isLoading.orderDay}
                  options={dateDurations}
                  defaultValue="today"
                />
                {formData.dateDuration === "custom" ? (
                  <>
                    <DatePicker
                      name="dateFrom"
                      tooltip={"Date from"}
                      helpKey="common.dateFrom"
                      label={intl.formatMessage({ id: "common.dateFrom" })}
                      onChange={onFormFieldChange}
                      value={formData.dateFrom}
                      errors={formErrors.dateFrom}
                      isSubmitting={isFetchingPdf}
                      isLoading={isLoading.orderDay}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                    />
                    <DatePicker
                      name="dateTo"
                      tooltip={"Date from"}
                      helpKey="common.dateTo"
                      label={intl.formatMessage({ id: "common.dateTo" })}
                      onChange={onFormFieldChange}
                      value={formData.dateTo}
                      errors={formErrors.dateTo}
                      isSubmitting={isFetchingPdf}
                      isLoading={isLoading.orderDay}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      margin="dense"
                    />
                  </>
                ) : null}
              </Box>
            </Box>

            {hasPdfAccess && (
              <Box display="flex" justifyContent="flex-end">
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  disabled={isFetchingPdf}
                  onClick={handleGetZReport}
                  style={{ margin: "16px" }}
                  startIcon={!isFetchingPdf && <DoneIcon />}
                >
                  {isFetchingPdf ? (
                    <CircularProgress size={20} />
                  ) : (
                    <LocalizedMessage id="general.apply" />
                  )}
                </Button>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>

        <Memo
          deps={[
            isFetchingEmails,
            isFetchingPdf,
            openEmailsDialog,
            pdf,
            formData.orderDay,
          ]}
        >
          {() => (
            <Toolbar
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: 16,
                overflow: "auto",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "flex-start",
                }}
              >
                <Chip
                  style={{
                    margin: 8,
                    padding: 8,
                    height: "100%",
                    color: "white",
                    backgroundColor: "#0582D2",
                  }}
                  label={
                    <Typography
                      variant="body2"
                      style={{ whiteSpace: "normal" }}
                    >
                      <LocalizedMessage id="report.dateDuration.title" />
                      {": " +
                        dateDurations.filter(
                          (dd) => formData.dateDuration === dd.value
                        )[0]?.text}
                    </Typography>
                  }
                />
                {formData.dateDuration === "custom" && (
                  <Chip
                    style={{
                      margin: 8,
                      padding: 8,
                      height: "100%",
                      color: "white",
                      backgroundColor: "#0582D2",
                    }}
                    label={
                      <Typography
                        variant="body2"
                        style={{ whiteSpace: "normal" }}
                      >
                        {formData.dateTo === formData.dateFrom ? (
                          <>
                            <LocalizedMessage id="report.day" />
                            {": " +
                              moment(formData.dateFrom).format("MMM DD, YYYY")}
                          </>
                        ) : (
                          <>
                            <LocalizedMessage id="common.dateFrom" />
                            {": " +
                              moment(formData.dateFrom).format("MMM DD, YYYY")}
                            <LocalizedMessage id="common.dateTo" />
                            {": " +
                              moment(formData.dateTo).format("MMM DD, YYYY")}
                          </>
                        )}
                      </Typography>
                    }
                  />
                )}
              </Box>
              <Tooltip title={<LocalizedMessage id="report.sendEmails" />}>
                <Button
                  component={isFetchingPdf ? "div" : undefined}
                  onClick={
                    isFetchingPdf ? undefined : () => setOpenEmailsDialog(true)
                  }
                  disabled={isFetchingPdf}
                  color="primary"
                >
                  {isFetchingPdf || isFetchingEmails ? (
                    <CircularProgress size={20} />
                  ) : (
                    <LocalizedMessage id="report.sendEmails" />
                  )}
                </Button>
              </Tooltip>
            </Toolbar>
          )}
        </Memo>
        <SendEmailsDialog
          dateDuration={formData.dateDuration}
          dateFrom={formData.dateFrom}
          dateTo={formData.dateTo}
          reportEmails={reportEmails}
          open={openEmailsDialog}
          setOpen={setOpenEmailsDialog}
        />
      </Paper>
      {isFetchingPdf ? (
        <Skeleton
          animation="wave"
          variant="rect"
          style={{
            display: "flex",
            flex: 1,
          }}
          height={480}
        />
      ) : (
        <Box>
          <App url={pdf} />
        </Box>
      )}
    </Box>
  );
};

ZReport.propTypes = {
  /**
   *
   */
};

ZReport.defaultProps = {
  /**
   *
   */
};

export default ZReport;
