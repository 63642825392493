// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Material
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Box,
  Paper,
  CircularProgress,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";

// Factories

// Screens
import { BrandSetup } from "Screens/Wizard/BrandSetup";
import { AccountSetup } from "Screens/Wizard/AccountSetup";
import { BranchSetup } from "Screens/Wizard/BranchSetup";
import ReadyToGo from "./ReadyToGo";
import Footer from "./Footer";

// Assets
import { OnionColoredLogo } from "Assets/Images";

// Third Parties
import { useHistory } from "react-router-dom";
// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    padding: theme.spacing(4, 4, 1, 4),
  },
  wizardPaper: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "flex-start",
    marginBottom: theme.spacing(3),
  },

  logoBox: {
    ...theme.palette.dark,
    padding: theme.spacing(2),
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#bdbdbd",

    "& $logo": {
      maxHeight: "100px",
    },
  },
  wizardButtons: {
    marginTop: "auto",
    display: "flex",
    padding: theme.spacing(2),
  },
  actionButtons: {
    marginLeft: "auto",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  footer: {
    marginTop: "auto",
    width: "100%",
  },
}));

// Ad-Hoc Components

// Other
function getSteps() {
  return [
    {
      title: <LocalizedMessage id="wizard.setupYourBrand" />,
      component: (props) => <BrandSetup {...props} />,
    },
    {
      title: <LocalizedMessage id="wizard.setupYourAccount" />,
      component: (props) => <AccountSetup {...props} />,
    },
    {
      title: <LocalizedMessage id="wizard.setupABranch" />,
      component: (props) => <BranchSetup {...props} />,
    },
    // {
    //   title: "Configure Sales Channels",
    //   component: (props) => <ConfigureSalesChannels {...props} />,
    // },
    {
      title: <LocalizedMessage id="wizard.setupReadyToGo" />,
      component: (props) => <ReadyToGo {...props} />,
    },
  ];
}

/**
 * @name Wizard
 * @summary Wizard Component
 * @category
 * @component
 * @description
 * >
 */
const Wizard = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [serverStorage] = useGlobalState("userData.serverStorage");
  const [accountState, setAccountState] = useGlobalState("userData.account");
  const [branch, setBranch] = useGlobalState("userData.branch");
  const [userAuth, setUserAuth] = useGlobalState("userData.auth");

  // State Hooks
  const [activeStep, setActiveStep] = React.useState(0);
  const [dirtyComponents, setDirtyComponents] = useState(false);
  const dirtyRef = useRef(dirtyComponents);
  const [isReady, setIsReady] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepSubmittingCallback, setStepSubmittingCallback] = useState(
    () => (arg) => {}
  );

  const setDirty = (data) => {
    dirtyRef.current = data;
    setDirtyComponents(data);
  };

  // Effect Hooks
  useEffect(() => {
    const { wizard } = serverStorage;
    if (wizard) {
      const index =
        wizard === "brand"
          ? 0
          : wizard === "account"
          ? 1
          : wizard === "branch"
          ? 2
          : 3;
      setActiveStep(index);
    } else {
      console.log("No wizard in local serverStorage");
    }

    const onBeforeUnloadHandler = (e) => {
      if (dirtyRef.current) {
        const msg = "Changes you made may not be saved.";
        (e || window.event).returnValue = msg;
        return msg;
      }
    };

    window.addEventListener("beforeunload", onBeforeUnloadHandler);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnloadHandler);
    };
  }, []);
  // Other Hooks
  let history = useHistory();

  // Event Handlers
  const onSubmitHandler = () => {
    stepSubmittingCallback({
      onCalled: () => {
        setIsSubmitting(true);
      },
      onSuccess: () => {
        setDirtyComponents(false);

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep !== steps.length - 2) {
          setIsReady(false);
        }
        setIsSubmitting(false);
      },
      onCancel: () => {
        setIsSubmitting(false);
      },
    });
  };

  const handleLogout = () => {
    setBranch({});
    setUserAuth({
      loaded: false,
      token: null,
    });
    setAccountState({});
    localStorage.clear();
    history.go(0);
  };
  // Other
  const steps = getSteps();

  // Component Render
  return (
    <Box className={classes.root}>
      <Paper className={classes.wizardPaper}>
        <Box className={classes.logoBox}>
          <img
            src={OnionColoredLogo}
            className={classes.logo}
            alt="Onion Logo"
          />
        </Box>

        <Stepper activeStep={activeStep} style={{ overflow: "auto" }}>
          {steps.map((step) => (
            <Step key={step.title}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[activeStep].component({
          setDirty,
          setIsReady,
          setStepSubmittingCallback,
        })}

        <Box className={classes.wizardButtons}>
          <Button
            type="button"
            variant="contained"
            style={{
              color: "white",
              backgroundColor: "#0582D2",
            }}
            onClick={handleLogout}
            disabled={!isReady || isSubmitting}
          >
            <LocalizedMessage id="common.signOut" />
          </Button>
          <Box className={classes.actionButtons}>
            <Button
              type="button"
              variant="contained"
              style={{
                color: "white",
                backgroundColor: "#0582D2",
              }}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
              }}
              disabled={activeStep === 0 || !isReady || isSubmitting}
              className={classes.backButton}
            >
              <LocalizedMessage id="wizard.back" />
            </Button>
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={!isReady || isSubmitting}
              onClick={onSubmitHandler}
            >
              {isSubmitting ? (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              ) : activeStep === steps.length - 1 ? (
                <LocalizedMessage id="wizard.finish" />
              ) : (
                <LocalizedMessage id="wizard.next" />
              )}
            </Button>
          </Box>
        </Box>
      </Paper>
      <Footer className={classes.footer} />
    </Box>
  );
};

Wizard.propTypes = {
  /**
   *
   */
};

export default Wizard;
