// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  FormControl,
  Button,
  CircularProgress,
  Divider,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Paper,
  Box,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import BusinessIcon from "@material-ui/icons/Business";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displayenqueueSnackbar,
  displaySnackbarFormErrors,
} from "Utils/displaySnackbarErrors";

// Components
import Title from "Components/Title";
import ImagePreloader from "Components/ImagePreloader";
import { TextField, ImageField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets
import { BrandingVector } from "Assets/Vectors";

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useHistory } from "react-router-dom";
import _ from "lodash";
// Services
import { useEditBrand } from "Services/Brands/";

// Styles

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    cardRoot: {
      flex: 1,
    },
    imageField: {
      flex: 1,
      margin: 20,
      alignSelf: "center",
    },
    textField: {
      width: "60%",
      alignSelf: "center",
      // margin: 20,
    },
    formContainer: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  };
});

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().min(1).max(100).required(),
  otherName: Joi.string().min(1).max(100).allow(null, ""),
  logo: Joi.alternatives(
    Joi.string().allow(null, ""),
    Joi.object().keys({
      type: Joi.any().valid(
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/svg+xml"
      ),
      name: Joi.string(),
      size: Joi.number().max(1024 * 1024),
    })
  ).required(),
});
/**
 * @name EditBrand
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const EditBrand = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [accountState, setAccountState] = useGlobalState("userData.account");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false);

  // Effect Hooks
  React.useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <BusinessIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage
            id="editBrand.pageTitle"
            key="editBrand.pageTitle"
          />
        </Box>
      ),
    []
  );

  // Other Hooks
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const editBrand = useEditBrand({ languageKey: "editBrand" });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "editBrand" });

  useEffect(() => {
    setFormData(() => {
      return {
        name: accountState.brands[0].name,
        otherName: accountState.brands[0].otherName,
        logo: accountState.brands[0].logoPath,
      };
    });
  }, []);

  // Event Handlers
  const onCloseBrandForm = () => {
    history.replace("/portal/dashboard");
  };

  const onImagesLoadedHandler = () => {
    setIsScreenLoaded(true);
  };

  const onEditBrandHandler = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const { name, otherName, logo } = formData;
      editBrand({
        name,
        otherName,
        logo,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          setAccountState({ ...accountState, brands: [response.data.data] });
          setIsSubmiting(false);
          // onCloseBrandForm();
          // setFormData({});
        })
        .catch((errors) => {
          console.log(errors);
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("xs"));

  // Component Render
  return (
    <>
      <Box className={classes.root}>
        <Title
          pageTitle={
            <LocalizedMessage
              id="editBrand.pageTitle"
              key="editBrand.pageTitle"
            />
          }
        />

        <Card style={{ flex: 1 }}>
          <CardHeader
            title={
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" component="h2">
                    <LocalizedMessage id="editBrand.pageTitle" />
                  </Typography>
                </Box>
                <Divider style={{ marginTop: 10 }} />
              </>
            }
          />

          <CardContent
            style={{
              display: "flex",
              flexDirection: downSm ? "column" : "row",
            }}
          >
            <Box className={classes.formContainer}>
              <form className={classes.form} noValidate>
                <FormControl className={classes.formControl}>
                  {downSm && (
                    <>
                      <ImageField
                        margin="dense"
                        placeholderImage={BrandingVector}
                        name="logo"
                        label={<LocalizedMessage id="editBrand.logo" />}
                        tooltip="Accepted file types: jpeg, png, gif and svg"
                        onChange={onFormFieldChange}
                        value={formData.logo}
                        errors={formErrors.logo}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading.logo}
                        className={classes.imageField}
                      />
                    </>
                  )}
                  <TextField
                    required
                    label={<LocalizedMessage id="common.name" />}
                    name="name"
                    autoFocus
                    onChange={onFormFieldChange}
                    value={formData.name}
                    errors={formErrors.name}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.name}
                    tooltip={"Your restaurant, cafe or bar name"}
                    helpKey="common.name"
                    //maxCharacters={50}
                    helpIconProps={{
                      "data-tour": "helpIcon",
                    }}
                    className={classes.textField}
                  />
                  <TextField
                    label={<LocalizedMessage id="common.otherName" />}
                    name="otherName"
                    onChange={onFormFieldChange}
                    value={formData.otherName}
                    errors={formErrors.otherName}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.otherName}
                    tooltip={"Your restaurant, cafe or bar otherName"}
                    helpKey="common.otherName"
                    //maxCharacters={50}
                    helpIconProps={{
                      "data-tour": "helpIcon",
                    }}
                    className={classes.textField}
                  />
                </FormControl>
              </form>
            </Box>

            {!downSm && (
              <Box display="flex" flex={"0 1 60%"}>
                <Divider orientation="vertical" flexItem />
                <ImageField
                  placeholderImage={BrandingVector}
                  name="logo"
                  label={<LocalizedMessage id="wizard.brandSetup.logo" />}
                  tooltip="Accepted file types: jpeg, png, gif and svg"
                  onChange={onFormFieldChange}
                  value={formData.logo}
                  errors={formErrors.logo}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                  className={classes.imageField}
                />
              </Box>
            )}
          </CardContent>

          <CardActions
            style={{
              paddingTop: "20",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <FormActions
              // onCLoseForm={onCloseBrandForm}
              isSubmitting={isSubmitting}
              action="save"
              onAction={onEditBrandHandler}
            />

            {/* <Button onClick={onCloseBrandForm} color="secondary">
              <LocalizedMessage id="general.cancel" />
            </Button>

            <Button
              type="button"
              color="primary"
              disabled={isSubmitting}
              onClick={onEditBrandHandler}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.edit" />
              )}
            </Button> */}
          </CardActions>
        </Card>
      </Box>

      <ImagePreloader
        onLoaded={onImagesLoadedHandler}
        onTimeout={onImagesLoadedHandler}
        images={[BrandingVector]}
      />
    </>
  );
};

EditBrand.propTypes = {
  /**
   *
   */
};

EditBrand.defaultProps = {
  /**
   *
   */
};

export default EditBrand;
