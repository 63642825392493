// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import {
  displaySnackbarFormErrors,
  displaySnackbarErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField, DateTimePicker } from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens
import SalesChannelForm from "Screens/Portal/SalesChannels/SalesChannelForm";
import EmployeeForm from "Screens/Portal/Employees/EmployeeForm";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import { useAddSalesSection } from "Services/SalesChannels";
import { useAddEmployee } from "Services/Employees";
import {
  useGetAllSalesSectionsDropdowns,
  useGetAllEmployeesDropdowns,
} from "Services/Dropdowns/";
import { useGetAllItems } from "Services/Items/";
import { useAddDiscount, useEditDiscount } from "Services/Discounts/";
// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  salesSections: Joi.array().items(Joi.string().uuid()).min(1),
  employees: Joi.array().items(Joi.string().uuid()),
  items: Joi.array().items(Joi.string().uuid()),
  value: Joi.number().required().greater(0),
  valueType: Joi.string().valid("FIXED_AMOUNT", "PERCENTAGE").required(),
  discountType: Joi.string().valid("section", "employee", "item").required(),
  dateFrom: Joi.date().required(),
  dateTo: Joi.date().min(Joi.ref("dateFrom")).required(),
});

/**
 * @name DiscountForm
 * @summary
 * @Discount
 * @component
 * @description
 * >
 */
const DiscountForm = ({
  editFormData,
  editCallback,
  addCallback,
  editId,
  onCancel,
  mode,
  open,
  permissions,
  setOpen,
  parent,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState(
    "salesSections.salesSections"
  );

  const [employees, setEmployees] = useGlobalState("employees.employees");

  // State Hooks
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);
  const [employeeFormOpen, setEmployeeFormOpen] = useState(false);
  const [items, setItems] = useState([]);

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      editFormData = {
        ...editFormData,
        salesSections: editFormData.salesSections.map(
          (salesSection) => salesSection.id
        ),
        employees: editFormData.employees.map((employee) => employee.id),
      };
      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "salesSections",
          "employees",
          "value",
          "dateFrom",
          "dateTo",
          "valueType",
          "discountType",
        ])
      );
    } else setFormData({});
  }, [open]);

  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  useEffect(() => {
    if (!employees.isFetchingRows && !employees.data.length)
      getAllEmployeesDropdowns()
        .then((res) => {
          setEmployees({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [employees.isFetchingRows]);

  // useEffect(() => {
  //   getAllItems()
  //     .then((itms) => {
  //       setItems(itms);
  //     })
  //     .catch((errors) => {
  //       errors.forEach((e) => {
  //         enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
  //           variant: "error",
  //           autoHideDuration: 3000,
  //         });
  //       });
  //     });
  // }, []);

  // Other Hooks
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const addSalesChannel = useAddSalesSection({
    languageKey: "setup.salesChannel",
  });
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();
  const getAllItems = useGetAllItems();
  const getAllEmployeesDropdowns = useGetAllEmployeesDropdowns();
  const addDiscount = useAddDiscount({ languageKey: "setup.discount" });
  const editDiscount = useEditDiscount({ languageKey: "setup.discount" });

  const discountForm = useForm({
    validationSchema,
    languageKey: "setup.discount",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    onFormFieldChange,
    isSubmitting,
    isLoading,
  } = discountForm;
  // Event Handlers
  const onOpenSalesChannelForm = () => {
    setSalesChannelFormOpen(true);
  };
  const onCloseSalesChannelForm = () => {
    setSalesChannelFormOpen(false);
  };

  const onOpenEmployeeForm = () => {
    setEmployeeFormOpen(true);
  };
  const onCloseEmployeeForm = () => {
    setEmployeeFormOpen(false);
  };

  const openAddDiscountForm = (parentDiscount) => {
    setOpen(true);
  };

  const onCLoseDiscountForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddDiscount = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: discountForm,
      formName: "discount",
      apiHandler: addDiscount,
      onClose: onCLoseDiscountForm,
    });
  };

  const onSaveDiscount = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: discountForm,
      formName: "discount",
      apiHandler: editDiscount,
      onClose: onCLoseDiscountForm,
    });
  };

  const salesChannelDropDownCallback = (response) => {
    const salesSection = response.data.data;
    const newSalesSection = {
      value: salesSection.id,
      text: salesSection.name,
      salesSectionTypeId: salesSection.salesSectionTypeId,
    };
    const formDataSalesSections = formData.salesSections
      ? formData.salesSections
      : [];

    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, salesSection],
    });
    setFormData({
      ...formData,
      salesSections: [...formDataSalesSections, newSalesSection.value],
    });
  };

  const employeeDropDownCallback = (response) => {
    const employee = response.data.data;
    const newEmployee = {
      value: employee.id,
      text: employee.name,
    };
    const formDataEmployees = formData.employees ? formData.employees : [];

    setEmployees({
      ...employees,
      data: [...employees.data, response.data.data],
    });

    setFormData({
      ...formData,
      employees: [...formDataEmployees, newEmployee.value],
    });
  };

  // Other
  console.log(
    `%cDiscountForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );
  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseDiscountForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>Discounts</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <form className={classes.form} noValidate>
          <TextField
            required
            label={<LocalizedMessage id="common.name" />}
            name="name"
            autoComplete="name"
            tooltip={"Discount Name"}
            helpKey="Menu.discount.NameField"
            autoFocus
            fullWidth
            // maxCharacters={50}
            onChange={onFormFieldChange}
            value={formData.name}
            errors={formErrors.name}
            isSubmitting={isSubmitting}
            isLoading={isLoading.name}
          />
          <TextField
            fullWidth
            label={<LocalizedMessage id="common.otherName" />}
            name="otherName"
            tooltip={"Discount Other Name"}
            helpKey="Menu.discount.NameField"
            // maxCharacters={50}
            onChange={onFormFieldChange}
            value={formData.otherName}
            errors={formErrors.otherName}
            isSubmitting={isSubmitting}
            isLoading={isLoading.otherName}
          />
          <SelectField
            required
            fullWidth
            name="discountType"
            tooltip={"Discount value type"}
            helpKey="Menu.discount.discountType"
            label={<LocalizedMessage id="setup.discount.discountType" />}
            onChange={onFormFieldChange}
            value={formData.discountType}
            errors={formErrors.discountType}
            isSubmitting={isSubmitting}
            isLoading={isLoading.discountType}
            options={[
              {
                value: "section",
                text: intl.formatMessage({ id: "common.section" }),
                disabled: parent === "item" || parent === "employee",
              },
              {
                value: "employee",
                text: intl.formatMessage({ id: "common.employee" }),
                disabled: parent === "item" || parent === "section",
              },
              {
                value: "item",
                text: intl.formatMessage({ id: "common.item" }),
                disabled: parent === "section" || parent === "employee",
              },
            ]}
          />

          {formData.discountType === "section" && (
            <SelectField
              required
              fullWidth
              name="salesSections"
              tooltip={"Sales Sections"}
              helpKey="common.salesSections"
              label={<LocalizedMessage id="common.salesSections" />}
              onChange={onFormFieldChange}
              value={formData.salesSections}
              errors={formErrors.salesSections}
              isSubmitting={isSubmitting}
              isLoading={isLoading.salesSections}
              options={salesSections.data.map((s) => ({
                value: s.id,
                text: s.name,
                disabled: s.salesSectionTypeId === "Staff",
              }))}
              multiple
              openForm={onOpenSalesChannelForm}
              addPermission={
                permissions.isAdmin ||
                (permissions.salesSection &&
                  permissions.salesSection.includes("add"))
              }
            />
          )}
          {formData.discountType === "employee" && (
            <SelectField
              fullWidth
              name="employees"
              tooltip={"Employees"}
              helpKey="setup.discount.employee"
              label={<LocalizedMessage id="setup.discount.employees" />}
              onChange={onFormFieldChange}
              value={formData.employees}
              errors={formErrors.employees}
              isSubmitting={isSubmitting}
              isLoading={isLoading.employees}
              options={employees.data.map((el) => ({
                value: el.id,
                text: el.name,
              }))}
              multiple
              openForm={onOpenEmployeeForm}
              addPermission={
                permissions.isAdmin ||
                (permissions.employee && permissions.employee.includes("add"))
              }
            />
          )}
          {/* {formData.discountType === "item" && (
            <SelectField
              fullWidth
              name="items"
              tooltip={"Items"}
              helpKey="setup.discount.items"
              label={<LocalizedMessage id="setup.discount.items" />}
              onChange={onFormFieldChange}
              value={formData.items}
              errors={formErrors.items}
              isSubmitting={isSubmitting}
              isLoading={isLoading.items}
              options={items.map((el) => ({
                value: el.id,
                text: el.name,
              }))}
              multiple
              // openForm={onOpenEmployeeForm}
              addPermission={
                permissions.isAdmin || permissions.item.includes("add")
              }
            />
          )} */}
          <TextField
            required
            fullWidth
            name="value"
            autoComplete="value"
            tooltip={"Value"}
            helpKey="common.value"
            label={<LocalizedMessage id="common.value" />}
            onChange={onFormFieldChange}
            value={formData.value}
            errors={formErrors.value}
            isSubmitting={isSubmitting}
            isLoading={isLoading.value}
          />
          <SelectField
            required
            fullWidth
            name="valueType"
            tooltip={"Discount value type"}
            helpKey="common.valueType"
            label={<LocalizedMessage id="common.valueType" />}
            onChange={onFormFieldChange}
            value={formData.valueType}
            errors={formErrors.valueType}
            isSubmitting={isSubmitting}
            isLoading={isLoading.valueType}
            options={[
              {
                value: "FIXED_AMOUNT",
                text: intl.formatMessage({ id: "common.fixedAmount" }),
              },
              {
                value: "PERCENTAGE",
                text: intl.formatMessage({ id: "common.percentage" }),
              },
            ]}
          />
          <DateTimePicker
            fullWidth
            name="dateFrom"
            tooltip={"Date from"}
            helpKey="common.dateFrom"
            label={<LocalizedMessage id="common.dateFrom" />}
            onChange={onFormFieldChange}
            value={formData.dateFrom}
            errors={formErrors.dateFrom}
            isSubmitting={isSubmitting}
            isLoading={isLoading.dateFrom}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <DateTimePicker
            fullWidth
            name="dateTo"
            tooltip={"Date from"}
            helpKey="common.dateTo"
            label={<LocalizedMessage id="common.dateTo" />}
            onChange={onFormFieldChange}
            value={formData.dateTo}
            errors={formErrors.dateTo}
            isSubmitting={isSubmitting}
            isLoading={isLoading.dateTo}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
        <EmployeeForm
          open={employeeFormOpen}
          mode={"add"}
          addCallback={employeeDropDownCallback}
          onCancel={onCloseEmployeeForm}
          permissions={permissions}
        />
        <SalesChannelForm
          open={salesChannelFormOpen}
          mode="add"
          permissions={permissions}
          addCallback={salesChannelDropDownCallback}
          onCancel={onCloseSalesChannelForm}
        />
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddDiscount}
          mode={mode}
          onCLoseForm={onCLoseDiscountForm}
          isSubmitting={isSubmitting}
          onSave={onSaveDiscount}
        />
      </DialogActions>
    </Dialog>
  );
};

DiscountForm.propTypes = {
  /**
   *
   */
};

DiscountForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default DiscountForm;
