// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useGlobalState } from "Helpers/GlobalState";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name Subscribers
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Subscribers = ({ children }) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [userAuth] = useGlobalState("userData.auth");
  const [branch] = useGlobalState("userData.branch");
  const [brand] = useGlobalState("userData.brand");
  const [accountState] = useGlobalState("userData.account");
  const [serverStorage] = useGlobalState("userData.serverStorage");
  const [locale] = useGlobalState("global.locale");

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    localStorage.setItem("userAuth", JSON.stringify(userAuth));
    localStorage.setItem("branch", JSON.stringify(branch));
    localStorage.setItem("brand", JSON.stringify(brand));
    localStorage.setItem("accountState", JSON.stringify(accountState));
    localStorage.setItem("serverStorage", JSON.stringify(serverStorage));
    localStorage.setItem("locale", JSON.stringify(locale));
  }, [userAuth, branch, brand, serverStorage, accountState, locale]);

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return children;
};

Subscribers.propTypes = {
  /**
   *
   */
};

Subscribers.defaultProps = {
  /**
   *
   */
};

export default Subscribers;
