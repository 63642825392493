// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  FormHelperText,
  FormControl,
  TextField as MUITextField,
  Popper,
  useTheme,
  ClickAwayListener,
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"

// Components
import HelpIcon from "Components/HelpIcon"

// Factories

// Screens

// Assets

// Help

// Third Parties
import { ChromePicker } from "react-color"
import uuid from "uuid"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  helperTextWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
}))

// Ad-Hoc Components
function isColor(strColor) {
  if (!strColor) return false
  var s = new Option().style
  s.color = strColor
  var test1 = s.color == strColor
  var test2 = /^#[0-9A-F]{6}$/i.test(strColor)
  if (test1 == true || test2 == true) {
    return true
  } else {
    return false
  }
}

/**
 * @name ColorPickerField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ColorPickerField = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  defaultValue,
  value,
  onChange,
  id,
  // validator,
  showFirstError: onlyShowFirstError,
  isLoading,
  isSubmitting,
  hidden,
  name,
  label,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  const currentValue = value || defaultValue || ""
  const inputFieldID = id || uuid.v4()

  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [ColorPickerFieldValue, setColorPickerFieldValue] = useState(
    currentValue
  )
  const [pickerOpen, setPickerOpen] = useState(false)
  const [pickerAnchorEl, setPickerAnchorEl] = useState(null)

  // Effect Hooks
  useEffect(() => {
    // typeof setDefaultValue === "function" && setDefaultValue(currentValue);
    console.log(
      `%cColor Picker Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  useEffect(() => {
    changeInputValue(currentValue)
    if (typeof onChange === "function") onChange(name, currentValue)
    console.log(
      `%cColor Picker Field %cValue Changed -> %cName: %c${name} - %cValue: %c${currentValue}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      "",
      "font-weight: bold;",
      ""
    )
  }, [value])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onColorPickerFieldChangeHandler = (e) => {
    // const e = {
    //   target: {
    //     name,
    //     value: color,
    //   },
    //   persist: () => {},
    // };
    let newValue = changeInputValue(e.target.value)
    if (typeof onChange === "function" && newValue === e.target.value)
      onChange(e)
  }

  const onChromePickerFieldChangeHandler = (color) => {
    if (typeof onChange === "function") onChange(name, color.hex)
    changeInputValue(color.hex)
    // inputRef.current.change();
  }

  const onColorPickerFieldClickHandler = (e) => {
    setPickerAnchorEl(e.currentTarget)
    setPickerOpen(true)
  }

  const onChromePickerFieldClickAway = () => {
    setPickerOpen(false)
  }

  // Other
  const changeInputValue = (v) => {
    let newValue = v
    setColorPickerFieldValue(newValue)
    return newValue
  }

  console.log(
    `%cColor Picker Field %cRerender -> %cName: %c${name}`,
    "font-weight: bold; color: blue",
    "",
    "font-weight: bold;",
    ""
  )

  // Component Render
  return (
    <Box className={className} style={{ display: hidden ? "none" : "block" }}>
      <FormControl {...{ margin }} className={classes.root} style={style}>
        {isLoading && inputSkeleton ? (
          inputSkeleton
        ) : (
          <ClickAwayListener onClickAway={onChromePickerFieldClickAway}>
            <Box style={{ flex: 1 }}>
              <MUITextField
                {...{
                  ref: inputRef,
                  inputProps: {
                    autoComplete,
                    id: inputFieldID,
                    ...inputProps,
                    style: {
                      backgroundColor: ColorPickerFieldValue,
                      color: isColor(ColorPickerFieldValue)
                        ? theme.palette.getContrastText(ColorPickerFieldValue)
                        : "inherit",
                      margin: "8px 7px",
                      padding: "10.5px 7px",
                    },
                    disabled: true,
                  },
                  error: Boolean(errors && errors.length > 0),
                  value: ColorPickerFieldValue,
                  onChange: onColorPickerFieldChangeHandler,
                  fullWidth,
                  size,
                  defaultValue,
                  variant,
                  name,
                  label,
                  disabled: isSubmitting || disabled,
                  onClick: onColorPickerFieldClickHandler,
                  ...otherProps,
                }}
              />
              <Popper
                id={inputFieldID + "-ChromePicker"}
                open={pickerOpen}
                anchorEl={pickerAnchorEl}
                placement="bottom"
                disablePortal={true}
                style={{ zIndex: 1 }}
              >
                <ChromePicker
                  color={ColorPickerFieldValue}
                  onChange={onChromePickerFieldChangeHandler}
                  disableAlpha={true}
                />
              </Popper>
            </Box>
          </ClickAwayListener>
        )}

        {/* <HelpIcon
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          {...helpIconProps}
        /> */}
      </FormControl>

      <Box className={classes.root}>
        {(helperText || errors) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <FormHelperText
              error={errors.length > 0}
              className={classes.helperTextWrapper}
            >
              {
                <span className={classes.helperTextWrapper}>
                  {helperText &&
                    !errors &&
                    (isLoading && helperTextSkeleton !== undefined ? (
                      helperTextSkeleton
                    ) : (
                      <span ref={helperTextRef}>{helperText}</span>
                    ))}
                  {errors && (
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      {errors.map((errorMessage, i) => (
                        <span key={i}>{errorMessage}</span>
                      ))}
                    </span>
                  )}
                </span>
              }
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}
        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  )
}

ColorPickerField.propTypes = {
  /**
   *
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  validator: PropTypes.func,
  autoFocus: PropTypes.bool,
  tooltip: PropTypes.string,
  helpKey: PropTypes.string,
}

ColorPickerField.defaultProps = {
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  showFirstError: true,
  isLoading: false,
}

export default ColorPickerField
