// React
import React, { useEffect, useState } from "react";

// Material
import {
  Box,
  Fade,
  Slide,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  CardMedia,
  Link,
  Breadcrumbs,
  withStyles,
} from "@material-ui/core";

import {
  Face as FaceIcon,
  ShoppingCart as ShoppingCartIcon,
  Gavel as GavelIcon,
  Redeem as RedeemIcon,
  RoomService as RoomServiceIcon,
  EventSeat as EventSeatIcon,
  Note as NoteIcon,
  Motorcycle as MotorcycleIcon,
  CancelPresentation as CancelPresentationIcon,
  Payment as PaymentIcon,
  Settings as SettingsIcon,
  Email as EmailIcon,
} from "@material-ui/icons";
import PrintIcon from "@material-ui/icons/Print";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BuildIcon from "@material-ui/icons/Build";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";

import { Employees } from "Screens/Portal/Employees";
import { SalesChannels } from "Screens/Portal/SalesChannels";
import { Discounts } from "Screens/Portal/Discounts";
import { Taxes } from "Screens/Portal/Taxes";
import { Surcharges } from "Screens/Portal/Surcharges";
import { Tables } from "Screens/Portal/Tables";
import { Notes } from "Screens/Portal/Notes";
import { Delivery } from "Screens/Portal/Delivery";
import { Cancellation } from "Screens/Portal/Cancellation";
import { Printers } from "Screens/Portal/Printers";
import { PrintersDesign } from "Screens/Portal/PrintersDesign";
import { Permissions } from "Screens/Portal/Permissions";
import { Payments } from "Screens/Portal/Payments";
import { EditGeneralSettings } from "Screens/Portal/GeneralSettings";
import { ReportEmails } from "Screens/Portal/ReportEmails";

// Factories

// Screens

// Assets
import employee from "Assets/Images/employee.png";
import printer from "Assets/Images/printer.png";
import payment from "Assets/Images/payment.png";
import mail from "Assets/Images/mail.png";
import generalSettings from "Assets/Images/generalSettings.jpg";
import salesChannels from "Assets/Images/salesChannels.png";

// Third Parties
import { useSnackbar } from "notistack";
import { Setup } from "../Setup";
import Color from "color";
// import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree"

// Services

// Styles
const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    justifyContent: "center",
  },
}));

const useStyles = makeStyles(({ spacing }) => ({
  actionArea: {
    height: "100%",
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: ({ color }) => ({
    // maxHeight: 400,
    maxWidth: "100%",
    // minWidth: 256,
    // minHeight: 250,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      height: "180px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      // backgroundColor: color,
      backgroundColor: "rgb(216 216 216);",
      padding: "1rem 1.5rem 1.5rem",
    };
  },
  title: {
    fontFamily: "Roboto,Changa",
    fontSize: "1.6rem",
    // color: "#fff",
    textTransform: "uppercase",
  },
  subtitle: {
    fontFamily: "Montserrat",
    // color: "#fff",
    opacity: 0.87,
    marginTop: "1rem",
    fontWeight: 500,
    fontSize: 14,
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
  icon: { marginRight: spacing(0.5), width: 20, height: 20 },
  mediaClasses: {
    width: "100%",
    height: "0px",
    paddingBottom: "75%",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    filter: "grayscale(40%)",
    // filter: "sepia(100%) hue-rotate(190deg) saturate(500%)"
  },
}));

const StyledBreadCrumbs = withStyles(({ palette, spacing }) => ({
  root: {
    display: "flex",
    marginLeft: 16,
    marginRight: 16,
    color: palette.primary.main,
  },
  ol: {
    flexWrap: "nowrap",
  },
  li: {
    display: "flex",
    "& a": {
      display: "flex",
      alignItems: "center",
    },
  },
}))(Breadcrumbs);

// Ad-Hoc Components
const CustomCard = ({ classes, image, title, subtitle, onClick }) => {
  // const mediaStyles = useFourThreeCardMediaStyles()
  return (
    <CardActionArea className={classes.actionArea} onClick={onClick}>
      <Card className={classes.card}>
        {/* <CardMedia classes={mediaStyles} image={image} /> */}
        <CardMedia classes={{ root: classes.mediaClasses }} image={image} />
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant={"h2"}>
            {title}
          </Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

/**
 * @name CardsSetup
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CardsSetup = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [currentComponent, setCurrentComponent] = useGlobalState(
    "components.setupCurrentComponent"
  );
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  // const [currentComponent, setCurrentComponent] = useState("cards")
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [isSetupAnimationDone, setIsSetupAnimationDone] = useState(true);
  const [currentTabs, setCurrentTabs] = useState({});

  // Effect Hooks
  const downXs = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(
    () =>
      setHeading(
        <StyledBreadCrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            color={currentComponent === "cards" ? "textPrimary" : "inherit"}
            href="/"
            onClick={(event) => handleClick(event, "cards")}
            className={classes.link}
          >
            <SettingsIcon
              style={{ marginRight: theme.spacing(0.5), width: 20, height: 20 }}
              color="primary"
            />
            <Typography
              variant="h6"
              component="h2"
              style={{ margin: "16px 8px" }}
              color="primary"
            >
              <LocalizedMessage id="setup.pageTitle" />
            </Typography>
          </Link>
          {!downXs && isAnimationDone && currentTabs.title ? (
            <Link color="textPrimary" className={classes.link}>
              {currentTabs.icon}
              <Typography
                variant="h6"
                component="h2"
                style={{ margin: "16px 8px" }}
              >
                {currentTabs.title}
              </Typography>

              {/* <WhatshotIcon className={classes.icon} /> */}
            </Link>
          ) : null}
        </StyledBreadCrumbs>
      ),
    [currentComponent, currentTabs, isAnimationDone, downXs]
  );

  // Other Hooks
  const gridStyles = useGridStyles();
  // const styles = useStyles({ color: "#7C499D" })
  // const styles2 = useStyles({ color: "#2E56A5" })
  // const styles3 = useStyles({ color: "#C64092" })
  // const styles4 = useStyles({ color: "#939496" })
  // const styles5 = useStyles({ color: "#A34599" })

  const styles = useStyles({ color: "#2E56A5" });
  const styles2 = useStyles({ color: "#2E56A5" });
  const styles3 = useStyles({ color: "#2E56A5" });
  const styles4 = useStyles({ color: "#2E56A5" });
  const styles5 = useStyles({ color: "#2E56A5" });

  // Event Handlers
  const handleCardClick = (component, tabs) => {
    setCurrentComponent(component);
    setCurrentTabs(tabs);
  };

  function handleClick(event, component) {
    event.preventDefault();
    setCurrentComponent(component);
    setIsAnimationDone(false);
    setCurrentTabs([]);
  }

  // Other
  const tabs = {
    employeesTabs: {
      title: <LocalizedMessage id={"setup.employee.pageTitle"} />,
      icon: <FaceIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id={"setup.employee.pageTitle"} />,
          permissionName: "employee",
          icon: <FaceIcon />,
          node: <Employees />,
        },
        {
          label: <LocalizedMessage id={"setup.permission.pageTitle"} />,
          permissionName: "permissionGroup",
          icon: <AccountTreeIcon />,
          node: <Permissions />,
        },
      ],
    },
    salesChannelsTabs: {
      title: <LocalizedMessage id={"setup.salesChannel.pageTitle"} />,
      icon: <ShoppingCartIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id={"setup.salesChannel.pageTitle"} />,
          permissionName: "salesSection",
          icon: <ShoppingCartIcon />,
          node: <SalesChannels />,
        },
        {
          label: <LocalizedMessage id={"setup.table.pageTitle"} />,
          permissionName: "table",
          icon: <EventSeatIcon />,
          node: <Tables />,
        },
        permissions.isAdmin || permissions.zone || permissions.city
          ? {
              label: <LocalizedMessage id={"setup.delivery.pageTitle"} />,
              permissionName:
                permissions.isAdmin || permissions.zone ? "zone" : "city",
              icon: <MotorcycleIcon />,
              node: <Delivery />,
            }
          : { permissionName: null },
      ],
    },
    paymentSettingsTabs: {
      title: <LocalizedMessage id={"setup.paymentSettings.pageTitle"} />,
      icon: <PaymentIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id={"setup.discount.pageTitle"} />,
          permissionName: "orderDiscount",
          icon: <RedeemIcon />,
          node: <Discounts />,
        },
        {
          label: <LocalizedMessage id={"setup.surcharge.pageTitle"} />,
          permissionName: "surcharge",
          icon: <RoomServiceIcon />,
          node: <Surcharges />,
        },
        {
          label: <LocalizedMessage id={"setup.tax.pageTitle"} />,
          permissionName: "tax",
          icon: <GavelIcon />,
          node: <Taxes />,
        },
        {
          label: <LocalizedMessage id={"setup.payment.pageTitle"} />,
          permissionName: "paymentMethod",
          icon: <PaymentIcon />,
          node: <Payments />,
        },
        {
          label: <LocalizedMessage id={"setup.taxSettings.pageTitle"} />,
          permissionName: "generalSettings",
          icon: <SettingsIcon />,
          node: <EditGeneralSettings />,
        },
      ],
    },
    printersTabs: {
      title: <LocalizedMessage id={"setup.printer.pageTitle"} />,
      icon: <PrintIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id={"setup.printer.pageTitle"} />,
          permissionName: "printer",
          icon: <PrintIcon />,
          node: <Printers />,
        },
        {
          label: <LocalizedMessage id={"setup.printersDesign.pageTitle"} />,
          permissionName: "printersDesign",
          icon: <PrintIcon />,
          node: <PrintersDesign />,
        },
      ],
    },
    reportEmailsTabs: {
      title: <LocalizedMessage id={"setup.reportEmail.pageTitle"} />,
      icon: <EmailIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id={"setup.reportEmail.pageTitle"} />,
          permissionName: "reportEmail",
          icon: <EmailIcon />,
          node: <ReportEmails />,
        },
      ],
    },
    generalSettingsTabs: {
      title: <LocalizedMessage id={"setup.generalSettings.pageTitle"} />,
      icon: <BuildIcon className={classes.icon} />,
      data: [
        permissions.isAdmin || permissions.itemNote || permissions.orderNote
          ? {
              label: <LocalizedMessage id={"setup.note.pageTitle"} />,
              permissionName:
                permissions.isAdmin || permissions.itemNote
                  ? "itemNote"
                  : "orderNote",
              icon: <NoteIcon />,
              node: <Notes />,
            }
          : { permissionName: null },
        permissions.isAdmin ||
        permissions.orderCancellationReason ||
        permissions.itemCancellationReason ||
        permissions.tableReservationCancellationReasons
          ? {
              label: (
                <LocalizedMessage id={"setup.cancellationReason.pageTitle"} />
              ),
              permissionName:
                permissions.isAdmin || permissions.orderCancellationReason
                  ? "orderCancellationReason"
                  : permissions.itemCancellationReason
                  ? "itemCancellationReason"
                  : "tableReservationCancellationReasons",
              icon: <CancelPresentationIcon />,

              node: <Cancellation />,
            }
          : { permissionName: null },
      ],
    },
  };

  // Component Render
  return (
    <>
      <Paper
        className={classes.root}
        dir={theme.direction}
        elevation={2}
        square
      >
        <Title
          pageTitle={
            <LocalizedMessage id="setup.pageTitle" key="setup.pageTitle" />
          }
        />
        <Fade
          in={currentComponent === "cards" && isSetupAnimationDone}
          mountOnEnter
          unmountOnExit
          // timeout={{ enter: 300, exit: 50 }}
          onEntered={() => {
            setIsAnimationDone(false);
          }}
          onExited={() => {
            setIsAnimationDone(true);
          }}
          style={{ padding: 36 }}
        >
          <Grid
            // classes={gridStyles}
            container
            spacing={6}
          >
            {(permissions.isAdmin ||
              permissions.employee ||
              permissions.permissionGroup) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomCard
                  classes={styles}
                  title={<LocalizedMessage id={"setup.employee.pageTitle"} />}
                  subtitle={
                    <>
                      <LocalizedMessage id={"setup.employee.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.permission.pageTitle"} />.
                    </>
                  }
                  image={employee}
                  onClick={() =>
                    handleCardClick("employees", tabs.employeesTabs)
                  }
                />
              </Grid>
            )}
            {(permissions.isAdmin ||
              permissions.salesSection ||
              permissions.table ||
              permissions.city ||
              permissions.zone) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomCard
                  classes={styles2}
                  title={
                    <LocalizedMessage id={"setup.salesChannel.pageTitle"} />
                  }
                  subtitle={
                    <>
                      <LocalizedMessage id={"setup.salesChannel.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.delivery.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.table.pageTitle"} />.
                    </>
                  }
                  image={salesChannels}
                  onClick={() =>
                    handleCardClick("salesChannels", tabs.salesChannelsTabs)
                  }
                />
              </Grid>
            )}
            {(permissions.isAdmin ||
              permissions.orderDiscount ||
              permissions.tax ||
              permissions.surcharge ||
              permissions.paymentMethod ||
              permissions.generalSettings) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomCard
                  classes={styles3}
                  title={
                    <LocalizedMessage id={"setup.paymentSettings.pageTitle"} />
                  }
                  subtitle={
                    <>
                      <LocalizedMessage id={"setup.discount.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.tax.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.surcharge.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.payment.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.tax.taxSetting"} />.
                    </>
                  }
                  image={payment}
                  onClick={() =>
                    handleCardClick("paymentSettings", tabs.paymentSettingsTabs)
                  }
                />
              </Grid>
            )}
            {(permissions.isAdmin || permissions.reportEmail) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomCard
                  classes={styles2}
                  title={
                    <LocalizedMessage id={"setup.reportEmail.pageTitle"} />
                  }
                  subtitle={
                    <LocalizedMessage id={"setup.reportEmail.pageTitle"} />
                  }
                  image={mail}
                  onClick={() =>
                    handleCardClick("reportEmails", tabs.reportEmailsTabs)
                  }
                />
              </Grid>
            )}
            {(permissions.isAdmin ||
              permissions.printer ||
              permissions.printersDesign) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomCard
                  classes={styles5}
                  title={<LocalizedMessage id={"setup.printer.pageTitle"} />}
                  subtitle={
                    <>
                      <LocalizedMessage id={"setup.printer.pageTitle"} />
                      {", "}
                      <LocalizedMessage id={"setup.printersDesign.pageTitle"} />
                      .
                    </>
                  }
                  image={printer}
                  onClick={() => handleCardClick("printers", tabs.printersTabs)}
                />
              </Grid>
            )}
            {(permissions.isAdmin ||
              permissions.orderNote ||
              permissions.itemNote ||
              permissions.orderCancellationReason ||
              permissions.itemCancellationReason) && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <CustomCard
                  classes={styles4}
                  title={
                    <LocalizedMessage id={"setup.generalSettings.pageTitle"} />
                  }
                  subtitle={
                    <>
                      <LocalizedMessage id={"setup.note.pageTitle"} />
                      {", "}
                      <LocalizedMessage
                        id={"setup.cancellationReason.pageTitle"}
                      />
                      .
                    </>
                  }
                  image={generalSettings}
                  onClick={() =>
                    handleCardClick("generalSettings", tabs.generalSettingsTabs)
                  }
                />
              </Grid>
            )}
          </Grid>
        </Fade>
      </Paper>
      <Fade
        in={currentComponent !== "cards" && isAnimationDone}
        mountOnEnter
        unmountOnExit
        // timeout={{ enter: 300, exit: 200 }}
      >
        <Box>
          <Slide
            in={currentComponent !== "cards" && isAnimationDone}
            direction={theme.direction === "ltr" ? "left" : "right"}
            mountOnEnter
            unmountOnExit
            // timeout={{ enter: 300, exit: 100 }}
            onEntered={() => {
              setIsSetupAnimationDone(false);
            }}
            onExited={() => {
              setIsSetupAnimationDone(true);
            }}
          >
            <Box>
              <Setup
                permissions={permissions}
                tabs={currentTabs.data}
                title="setup.pageTitle"
              />
            </Box>
          </Slide>
        </Box>
      </Fade>
    </>
  );
};

CardsSetup.propTypes = {
  /**
   *
   */
};

CardsSetup.defaultProps = {
  /**
   *
   */
};

export default CardsSetup;
