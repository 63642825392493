// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckIcon from "@material-ui/icons/Check";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { TextField } from "Components/FormFields";
import Title from "Components/Title";
import SortableBody from "Components/Sortable";

// Factories

// Screens
import SalesChannelForm from "./SalesChannelForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import arrayMove from "array-move";

// Services
import {
  useActivateSalesSection,
  useDeactivateSalesSection,
} from "Services/SalesChannels/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "drag",
    label: <LocalizedMessage id="general.drag" />,
    minWidth: 5,
    align: "center",
  },
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 50,
  },
  {
    id: "salesSectionTypeId",
    label: <LocalizedMessage id="setup.salesChannel.salesSectionTypeId" />,
    minWidth: 50,
  },
  {
    id: "status",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 50,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name SalesChannels
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesChannels = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState(
    "salesSections.salesSections"
  );
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isActivating, setIsActivating] = useStateRef(false);
  const [editId, setEditId] = React.useState("");

  /* SalesChannel Form */
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);
  const [salesChannelFormMode, setSalesChannelFormMode] = useState("");
  const [salesChannelFormData, setSalesChannelFormData] = useState({});

  // Effect Hooks
  useEffect(() => {
    setRows(salesSections.data.map((el) => createData(el)));
  }, [salesSections]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const activateSalesSection = useActivateSalesSection({
    languageKey: "setup.salesChannel",
  });
  const deactivateSalesSection = useDeactivateSalesSection({
    languageKey: "setup.salesChannel",
  });

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddSalesChannelForm = () => {
    setSalesChannelFormData({});
    setSalesChannelFormMode("add");
    setSalesChannelFormOpen(true);
  };

  const onEditClick = (salesSection) => {
    setEditId(salesSection.id);
    setSalesChannelFormData(salesSection);
    setSalesChannelFormMode("edit");
    setSalesChannelFormOpen(true);
  };

  const onActivateClick = (salesSection) => {
    setIsActivating(true);
    const index = salesSections.data.findIndex(
      (row) => row.id === salesSection.id
    );
    salesSections.data.splice(index, 1, { ...salesSection, isActive: false });
    setSalesSections({ ...salesSections });

    activateSalesSection(salesSection.id)
      .then((response) => {
        enqueueSnackbar(
          <LocalizedMessage id="setup.salesChannel.active.success" />,
          {
            variant: "success",
            autoHideDuration: 5000,
          }
        );
        const index = salesSections.data.findIndex(
          (row) => row.id === salesSection.id
        );
        setIsActivating(false);
        salesSections.data.splice(index, 1, {
          ...salesSection,
          isActive: true,
        });
        setSalesSections({ ...salesSections });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const onDeactivateClick = (salesSection) => {
    setIsActivating(true);
    const index = salesSections.data.findIndex(
      (row) => row.id === salesSection.id
    );
    salesSections.data.splice(index, 1, { ...salesSection, isActive: true });

    setSalesSections({ ...salesSections });

    deactivateSalesSection(salesSection.id)
      .then((response) => {
        enqueueSnackbar(
          <LocalizedMessage id="setup.salesChannel.deactive.success" />,
          {
            variant: "success",
            autoHideDuration: 5000,
          }
        );
        const index = salesSections.data.findIndex(
          (row) => row.id === salesSection.id
        );
        setIsActivating(false);
        salesSections.data.splice(index, 1, {
          ...salesSection,
          isActive: false,
        });
        setSalesSections({ ...salesSections });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const addCallback = (response) => {
    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, response.data.data],
    });
  };

  const editCallback = (response) => {
    const index = salesSections.data.findIndex((row) => row.id === editId);
    salesSections.data.splice(index, 1, response.data.data);
    setSalesSections({ ...salesSections });
  };

  // Other
  const createData = (salesChannel) => {
    return {
      id: salesChannel.id,
      name: salesChannel.name,
      salesSectionTypeId: (
        <LocalizedMessage
          id={`lookUpTables.salesSectionTypes.${salesChannel.salesSectionTypeId}`}
        />
      ),
      status: salesChannel.isActive ? (
        <Chip
          label={<LocalizedMessage id="general.active" />}
          color="primary"
        />
      ) : (
        <Chip
          label={<LocalizedMessage id="general.inActive" />}
          style={{ color: "white", backgroundColor: "#0582D2" }}
        />
      ),
      actions: (
        <Box>
          {salesChannel.isActive
            ? (permissions.isAdmin ||
                permissions.salesSection.includes("deactivate")) && (
                <Tooltip title={<LocalizedMessage id="common.deactivate" />}>
                  <IconButton
                    component={
                      isActivating.getState() ||
                      salesChannel.salesSectionTypeId === "Online"
                        ? "div"
                        : undefined
                    }
                    onClick={
                      isActivating.getState() ||
                      salesChannel.salesSectionTypeId === "Online"
                        ? undefined
                        : () => onDeactivateClick(salesChannel)
                    }
                    disabled={
                      isActivating.getState() ||
                      salesChannel.salesSectionTypeId === "Online"
                    }
                    aria-label="deactivate"
                    //onClick={() => onDeactivateClick(salesChannel)}
                  >
                    <NotInterestedIcon />
                  </IconButton>
                </Tooltip>
              )
            : (permissions.isAdmin ||
                permissions.salesSection.includes("activate")) && (
                <Tooltip title={<LocalizedMessage id="common.activate" />}>
                  <IconButton
                    component={
                      isActivating.getState() ||
                      salesChannel.salesSectionTypeId === "Online"
                        ? "div"
                        : undefined
                    }
                    onClick={
                      isActivating.getState() ||
                      salesChannel.salesSectionTypeId === "Online"
                        ? undefined
                        : () => onActivateClick(salesChannel)
                    }
                    disabled={
                      isActivating.getState() ||
                      salesChannel.salesSectionTypeId === "Online"
                    }
                    aria-label="Activate"
                    // onClick={() => onActivateClick(salesChannel)}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              )}
          {(permissions.isAdmin ||
            permissions.salesSection.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton
                component={
                  salesChannel.salesSectionTypeId === "Online"
                    ? "div"
                    : undefined
                }
                onClick={
                  salesChannel.salesSectionTypeId === "Online"
                    ? undefined
                    : () => onEditClick(salesChannel)
                }
                disabled={salesChannel.salesSectionTypeId === "Online"}
                aria-label="edit"
                //onClick={() => onEditClick(salesChannel)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    };
  };

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.salesChannel.pageTitle"
            key="setup.salesChannel.pageTitle"
          />
        }
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between ",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.salesChannel.pageTitle" />
        </Typography>
        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.salesSection.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={salesSections.isFetchingRows}
            component={salesSections.isFetchingRows ? "div" : undefined}
            onClick={
              salesSections.isFetchingRows ? undefined : openAddSalesChannelForm
            }
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        <SalesChannelForm
          open={salesChannelFormOpen}
          setOpen={setSalesChannelFormOpen}
          mode={salesChannelFormMode}
          permissions={permissions}
          editFormData={salesChannelFormData}
          addCallback={addCallback}
          editCallback={editCallback}
          editId={editId}
          parent="salesChannel"
        />
        {salesSections.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <SortableBody
                  data={(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.name
                              .toLowerCase()
                              .indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  columns={columns}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    setRows((elements) => [
                      ...arrayMove(elements, oldIndex, newIndex),
                    ]);
                  }}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

SalesChannels.propTypes = {
  /**
   *
   */
};

SalesChannels.defaultProps = {
  /**
   *
   */
};

export default SalesChannels;
