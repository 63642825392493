// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Paper,
  useTheme,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import useInterval from "Helpers/useInterval";

// Components

// Factories

// Screens

// Assets

// Services
import { useGetInDrivers } from "Services/Live/";

// Third Parties
import { useSnackbar } from "notistack";
import moment from "moment";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingLeast: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 100,
  },
];

/**
 * @name InDrivers
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const InDrivers = () => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  const [page, setPage] = React.useState(0);
  const [inDriversPerPage, setInDriversPerPage] = React.useState(5);
  const [inDrivers, setInDrivers] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);

  // Effect Hooks
  useEffect(() => {
    getDrivers();
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getInDrivers = useGetInDrivers();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setInDriversPerPage(+event.target.value);
    setPage(0);
  };

  const getDrivers = () => {
    setIsFetchingRows(true);
    getInDrivers()
      .then((drivers) => {
        const newDrivers = drivers.map((driver) => createData(driver));
        setIsFetchingRows(false);
        setInDrivers(newDrivers);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingRows(false);
      });
  };
  useInterval(getDrivers, 30000, 15 * 60000);

  // Other
  function createData(leastSellingItem) {
    return {
      id: leastSellingItem.id,
      name: leastSellingItem.name,
      ordered: leastSellingItem.ordered,
    };
  }
  const intl = useIntl();

  // Component Render
  return (
    <>
      <Typography variant="h6" component="h2" style={{ marginBottom: 4 }}>
        <LocalizedMessage id="live.inDrivers" />
      </Typography>
      <Paper>
        {isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inDrivers
                    .getState()
                    .slice(page * inDriversPerPage, page * inDriversPerPage + inDriversPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={inDrivers.getState().length}
              rowsPerPage={inDriversPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </>
  );
};

InDrivers.propTypes = {
  /**
   *
   */
};

export default InDrivers;
