// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  FormControl,
  Button,
  CircularProgress,
  Box,
  Paper,
  Typography,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import useSkeleton from "Helpers/useSkeleton";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { SwitchBox, RadioBox } from "Components/FormFields";
import Title from "Components/Title";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

// Services
import {
  useGetGeneralSettings,
  useEditGeneralSettings,
} from "Services/GeneralSettings";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexGrow: 1,
    },
    paper: {
      display: "flex",
      flexWrap: "wrap",
      margin: 8,
      "& > *": {
        width: theme.spacing(6),
        height: theme.spacing(12),
      },
    },
    formContainer: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flex: 3,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
    radioBox: {
      width: "50%",
    },
  };
});

// Ad-Hoc Components
const validationSchema = Joi.object({
  taxType: Joi.string().valid("exclusive", "inclusive"),
});
/**
 * @name EditGeneralSettings
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const EditGeneralSettings = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [isFetchingGeneralSettings, setIsFetchingGeneralSettings] = useState(
    {}
  );

  // Effect Hooks
  useEffect(() => {
    getGeneralSettings()
      .then((generalSettings) => {
        setFormData({
          taxType: generalSettings.tax,
        });
        setIsFetchingGeneralSettings(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getGeneralSettings = useGetGeneralSettings();
  const editEditGeneralSettings = useEditGeneralSettings({
    languageKey: "setup.taxSettings",
  });
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "setup.taxSettings" });

  // Event Handlers
  const onCloseEditGeneralSettingsForm = () => {
    history.replace("/portal/dashboard");
  };

  const onEditGeneralSettingsHandler = () => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const { taxType } = formData;
      editEditGeneralSettings({
        tax: taxType,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");
          /** @TODO when eman responds */
          // setFormData({
          //   taxInclusive: response.data.tax === "inclusive" ? true : false,
          // })
          setIsSubmiting(false);
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  // Other
  const taxTypes = [
    {
      text: intl.formatMessage({ id: "setup.taxSettings.taxExclusive" }),
      value: "exclusive",
    },
    {
      text: intl.formatMessage({ id: "setup.taxSettings.taxInclusive" }),
      value: "inclusive",
    },
  ];
  // Component Render
  return (
    <>
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.taxSettings.pageTitle"
            key="setup.taxSettings.pageTitle"
          />
        }
      />

      <Typography
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingTop: 16,
        }}
        variant="h6"
        component="h2"
      >
        <LocalizedMessage id="setup.taxSettings.pageTitle" />
      </Typography>
      <Paper className={classes.paper} elevation={2}>
        <Box className={classes.root}>
          {isFetchingGeneralSettings && inputSkeleton ? (
            inputSkeleton
          ) : (
            <Box ref={inputRef} className={classes.formContainer}>
              <form className={classes.form} noValidate>
                <FormControl className={classes.formControl}>
                  {/* <CheckBox
                    fullWidth
                    name="taxInclusive"
                    tooltip={'Tax is inclusive?'}
                    helpKey="setup.taxSettings.taxInclusive"
                    label={<LocalizedMessage id="setup.taxSettings.taxInclusive" />}
                    onChange={onFormFieldChange}
                    value={formData.taxInclusive}
                    errors={formErrors.taxInclusive}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.taxInclusive}
                  /> */}
                  {(permissions.isAdmin ||
                    permissions.taxSettings.includes("edit")) && (
                    <>
                      {/* <SwitchBox
                            fullWidth
                            name="taxInclusive"
                            tooltip={"Tax is inclusive?"}
                            helpKey="setup.taxSettings.taxInclusive"
                            label1={
                              <LocalizedMessage id="setup.taxSettings.taxExclusive" />
                            }
                            label2={
                              <LocalizedMessage id="setup.taxSettings.taxInclusive" />
                            }
                            onChange={onFormFieldChange}
                            value={formData.taxInclusive}
                            errors={formErrors.taxInclusive}
                            isSubmitting={isSubmitting}
                            isLoading={isLoading.taxInclusive}
                          /> */}
                      <RadioBox
                        fullWidth
                        name="taxType"
                        tooltip={"Tax is inclusive?"}
                        helpKey="setup.taxSettings.taxesType"
                        onChange={onFormFieldChange}
                        value={formData.taxType}
                        errors={formErrors.taxType}
                        isSubmitting={isSubmitting}
                        isLoading={isLoading.taxType}
                        label={
                          <LocalizedMessage id="setup.taxSettings.taxesType" />
                        }
                        options={taxTypes}
                        defaultValue={formData.taxType || taxTypes[0].value}
                        className={classes.radioBox}
                      />
                    </>
                  )}
                </FormControl>
              </form>
            </Box>
          )}
        </Box>
      </Paper>

      <Box
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          type="button"
          color="primary"
          disabled={isSubmitting}
          onClick={onEditGeneralSettingsHandler}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
            <LocalizedMessage id="general.save" />
          )}
        </Button>
      </Box>
    </>
  );
};

EditGeneralSettings.propTypes = {
  /**
   *
   */
};

EditGeneralSettings.defaultProps = {
  /**
   *
   */
};

export default EditGeneralSettings;
