import { useAxios } from "Helpers/Hooks";
import _ from "lodash";

const useStorageGetAll = ({ languageKey }) => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/storages")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const { status, data } = error.response;
            endErrors = [
              {
                message: "Failed to load server storage",
                languageKey: `${languageKey}.failed`,
              },
            ];
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

export default useStorageGetAll;
