// React
import React, { useRef } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import WebIcon from "@material-ui/icons/Web";
import AppsIcon from "@material-ui/icons/Apps";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import QRCode from "qrcode.react";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import { useReactToPrint } from "react-to-print";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name QRDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const QRDialog = ({ qrData, open, onCloseDialog, downLoadLink, openLink }) => {
  // Theme & Style Hooks
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Event Handlers

  // Other
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle id="alert-dialog-title">
        {<LocalizedMessage id="QrDialog.pageTitle" />}
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {qrData && (
          <>
            <QRCode
              style={{ alignSelf: "center" }}
              value={qrData}
              size={downSm ? 300 : 400}
            />
            {openLink && (
              <Button
                style={{ width: "50%", marginTop: 16 }}
                aria-label="QR"
                size="large"
                target="_blank"
                disabled={!openLink}
                href={openLink}
                startIcon={<WebIcon />}
              >
                <LocalizedMessage id="onlineOrders.openOnlineWebSite" />
              </Button>
            )}

            {downLoadLink && (
              <Button
                style={{ width: "50%", marginTop: 16 }}
                aria-label="QR"
                size="large"
                target="_blank"
                disabled={downLoadLink === "#"}
                href={downLoadLink}
                startIcon={<AppsIcon />}
              >
                {downLoadLink === "#" ? (
                  <LocalizedMessage id="IOS Link will be available soon." />
                ) : (
                  <LocalizedMessage id="general.downloadApp" />
                )}
              </Button>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <FormActions
          onCLoseForm={onCloseDialog}
          isSubmitting={false}
          action={<LocalizedMessage id="common.print" />}
          onAction={handlePrint}
          isClose
        />
        {/* <Button
          style={{
            backgroundColor: "#0582D2",
            color: "#fff",
            marginRight: "8px",
          }}
          variant="contained"
          autoFocus
          onClick={onCloseDialog}
        >
          <LocalizedMessage id="common.close" />
        </Button>
        <Button
          onClick={handlePrint}
          variant="contained"
          color="primary"
          //startIcon={<PrintIcon />}
        >
          <LocalizedMessage id="common.print" />
        </Button> */}

        <Box
          displayPrint="block"
          style={{
            // display: 'flex',
            flexDirection: "row",
            justifyContent: "flex-end",
            display: "none",
          }}
        >
          <ComponentToPrint
            qrData={qrData}
            downSm={downSm}
            ref={componentRef}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

QRDialog.propTypes = {
  /**
   *
   */
};

QRDialog.defaultProps = {
  /**
   *
   */
};

export default QRDialog;
class ComponentToPrint extends React.Component {
  render() {
    const { classes, qrData, downSm } = this.props;
    return (
      <Box display="flex" flexDirection="column" m={3}>
        <Typography variant="h3" component="h2" style={{ margin: 20 }}>
          <LocalizedMessage id="QrDialog.pageTitle" />
        </Typography>
        <Divider />

        {qrData && (
          <Box alignSelf="center" m={15}>
            <QRCode style={{}} value={qrData} size={downSm ? 300 : 500} />
          </Box>
        )}
      </Box>
    );
  }
}
