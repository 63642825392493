// React
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  FormControl,
  FormHelperText,
  Typography,
  Switch,
  Grid,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"

// Components
import HelpIcon from "Components/HelpIcon"

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  fieldWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
    flexShrink: 1,
  },
  field: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
    justifyContent: "normal",
  },
  formControl: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
}))

// Ad-Hoc Components
/**
 * @name SwitchBox
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SwitchBox = ({
  className,
  style,
  tooltip,
  helpKey,
  variant,
  margin,
  autoComplete,
  helpIconProps,
  options,
  getOptionLabel,
  getOptionSelected,
  defaultValue,
  onChange,
  helperText,
  size,
  inputProps,
  isLoading,
  hidden,
  isSubmitting,
  disabled,
  setDefaultValue,
  label1,
  label2,
  id,
  required,
  name,
  value,
  multiple,
  validator,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  // Theme & Style Hooks
  const classes = useStyles()
  if (typeof value !== "boolean") value = false

  // Global State Hooks

  // State Hooks
  const [inputFieldID] = useState(id || uuid.v4())

  // Effect Hooks
  useEffect(() => {
    onChangeHandler({
      persist: () => {},
      target: {
        name,
        checked: value,
      },
    })
    // console.log(
    //   `%cSelect %cField Mount -> %cName: %c${name}`,
    //   "font-weight: bold; color: burlywood",
    //   "",
    //   "font-weight: bold;",
    //   ""
    // );
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onChangeHandler = (e) => {
    if (typeof onChange == "function") {
      onChange(e.target.name, e.target.checked)
    }
  }

  // Other
  // console.log(
  //   `%SwitchBox %cRerender -> %cName: %c${name}`,
  //   "font-weight: bold; color: burlywood",
  //   "",
  //   "font-weight: bold;",
  //   ""
  // );

  // Component Render
  return (
    <Box className={className}>
      <Box className={classes.root} style={style}>
        <FormControl
          variant={variant}
          className={classes.formControl}
          size={size}
        >
          <Box className={classes.field}>
            {isLoading && inputSkeleton ? (
              inputSkeleton
            ) : (
              <FormGroup>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography variant="body2">{label1}</Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        style={style}
                        ref={inputRef}
                        checked={value}
                        //labelId={`${inputFieldID}-label`}
                        // defaultValue={defaultValue && getOptionLabel(defaultValue)}
                        onChange={onChangeHandler}
                        error={Boolean(errors && errors.length > 0).toString()}
                        inputProps={{
                          autoComplete,
                          disabled: isSubmitting || disabled,
                          id: inputFieldID,
                          ...inputProps,
                        }}
                        disabled={isSubmitting || disabled}
                        variant={variant}
                        className={classes.field}
                        id={inputFieldID}
                        required={required}
                        name={name}
                        size={size}
                        {...otherProps}
                      />
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">{label2}</Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </FormGroup>
            )}
          </Box>
          <Box className={classes.root}>
            {(helperText || errors || preHelperComponents) && (
              <Box
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {preHelperComponents && <Box>{preHelperComponents}</Box>}
                <FormHelperText
                  error={Boolean(errors && errors.length > 0)}
                  className={classes.helperTextWrapper}
                  component="div"
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <span className={classes.helperTextWrapper}>
                    {helperText &&
                      !errors &&
                      (isLoading && helperTextSkeleton !== undefined ? (
                        helperTextSkeleton
                      ) : (
                        <span ref={helperTextRef}>{helperText}</span>
                      ))}
                    {errors && (
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {errors.map((errorMessage, i) => (
                          <span key={i}>{errorMessage}</span>
                        ))}
                      </span>
                    )}
                  </span>
                </FormHelperText>
                {postHelperComponents && <Box>{postHelperComponents}</Box>}
              </Box>
            )}
            <HelpIcon
              style={{ visibility: "hidden", height: 0 }}
              tooltip={tooltip}
              helpKey={helpKey}
              modalTitle={label1}
              size={size}
              {...helpIconProps}
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  )
}

SwitchBox.propTypes = {
  /**
   *
   */
}

SwitchBox.defaultProps = {
  options: Array(),
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
}

export default SwitchBox
