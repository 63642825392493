import { useAxios } from "Helpers/Hooks";
import _ from "lodash";

const usePostMenuTemplate = () => {
  const axios = useAxios({ api: true });
  return (menuItemsFile) =>
    new Promise(function (resolve, reject) {
      const data = new FormData();
      data.append("menuItemsFile", menuItemsFile);
      axios
        .post("/menuItems/excel/import", data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.log(error.response.data.errors, 123);
          let endErrors = [];
          let validationError = error.response?.data.errors.mesage
            ? error.response.data.errors.mesage
            : "There is an error in the schema of the uploaded file";
          if (error.response) {
            const { status, data } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            } else if (status === 415) {
              endErrors.push({
                message: "Unsuppored media type",
                languageKey: "errors.unsupportedMedia",
              });
            } else if (status === 422) {
              endErrors.push({
                message: "Server error",
                languageKey:
                  "Server return an error: " + error.response.data.errors,
              });
            } else if (status === 400) {
              endErrors.push({
                message: "Server return error",
                languageKey:
                  "Server return an error: " +
                  data.errors.joi[0].message +
                  " in record " +
                  (data.errors.joi[0].path[0] + 2),
              });
            } else {
              endErrors.push({
                message: "Server return error",
                languageKey: "Server return an error: " + validationError,
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

export default usePostMenuTemplate;
