// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { Box, Button, Typography, useTheme, Tooltip } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import red from "@material-ui/core/colors/red";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
// import { ModifyItem } from "./ModifyItem"

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import LazyLoad from "react-lazyload";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    minHeight: "223px",
    height: 0,
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components

/**
 * @name ItemCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ItemCard = ({ style, item, onAddToCart, onMinusFromCart }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [branch] = useGlobalState("userData.branch");

  // State Hooks
  const [openDialog, setOpenDialog] = useState(false);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const { name, image, price, color } = item;

  // Component Render
  return (
    <>
      {/* <ModifyItem
        open={openDialog}
        setOpen={setOpenDialog}
        item={item}
        onAddToCart={onAddToCart}
        onMinusFromCart={onMinusFromCart}
      /> */}
      <Card style={{ ...style, display: "flex", flexDirection: "column" }}>
        <CardMedia
          component="img"
          image={image}
          className={classes.media}
          title={name}
          style={{
            borderBottom: item.image ? `5px solid ${item.color}` : 0,
            backgroundColor: `${item.color}`,
          }}
          onClick={() => setOpenDialog(true)}
        />
        {/* <CardContent
          style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" component="h2">
              {name}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "auto",
            }}
          >
            <Tooltip title={`${price} ${branch.currencyId ? branch.currencyId : "AED"}`} placement="top">
              <Typography variant="h6" color="primary" component="h2">
                {`${numeral(price).format("0.00a")} ${branch.currencyId ? branch.currencyId : "AED"}`}
              </Typography>
            </Tooltip>
          </Box>
        </CardContent> */}
        {/* <CardActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddToCart}
            startIcon={<ShoppingCartIcon />}
          >
            Add To Cart
        </Button>
        </CardActions> */}
      </Card>
    </>
  );
};

ItemCard.propTypes = {
  /**
   *
   */
};

export default ItemCard;
