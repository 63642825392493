// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  IconButton,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles
const useStyles = makeStyles(() => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
}));

// Ad-Hoc Components

/**
 * @name Row
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Row = ({ row, columns, secondaryColumns }) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [open, setOpen] = useState(false);

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <React.Fragment>
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.id}
        className={classes.root}
      >
        <TableCell key={row.id + "-expand"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {columns.map((column) => (
          <TableCell key={row.id + column.id} align={column.align}>
            {row[column.id] ? row[column.id] : 0}
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover role="checkbox" tabIndex={-1} key={row.id + "2"}>
        <TableCell
          key={row.id + "-secondaryColumns"}
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          {secondaryColumns.length ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  <LocalizedMessage id="setup.payment.pageTitle" />
                </Typography>
                <Table size="small" aria-label="Sales By Channel">
                  <TableHead>
                    <TableRow>
                      {secondaryColumns.map((secondaryColumn) => (
                        <TableCell key={secondaryColumn.id + "-first"}>
                          {secondaryColumn.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.data.map((secondaryRow) => (
                      <TableRow key={secondaryRow.paymentmethod}>
                        {secondaryColumns.map((column, index) => (
                          <TableCell
                            key={column.id + "-second"}
                            align={column.align}
                          >
                            {secondaryRow[column.id]
                              ? secondaryRow[column.id]
                              : 0}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          ) : null}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

Row.propTypes = {
  /**
   *
   */
};

Row.defaultProps = {
  /**
   *
   */
};

export default Row;
