// React
import React, { useEffect, useState } from "react";

// Material
import {
  Box,
  Fade,
  Slide,
  Paper,
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  CardActionArea,
  Card,
  CardContent,
  CardMedia,
  Link,
  Breadcrumbs,
  withStyles,
} from "@material-ui/core";

import {
  Face as FaceIcon,
  ShoppingCart as ShoppingCartIcon,
  CancelPresentation as CancelPresentationIcon,
  Payment as PaymentIcon,
} from "@material-ui/icons";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BallotIcon from "@material-ui/icons/Ballot";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import EventNoteIcon from "@material-ui/icons/EventNote";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import SpeakerNotesOffIcon from "@material-ui/icons/SpeakerNotesOff";
import MergeTypeIcon from "@material-ui/icons/MergeType";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import AssessmentIcon from "@material-ui/icons/Assessment";
import TodayIcon from "@material-ui/icons/Today";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { Summary } from "Screens/Portal/Reports/Summary";
import { SalesByDay } from "Screens/Portal/Reports/SalesByDay";
import { SalesByChannel } from "Screens/Portal/Reports/SalesByChannel";
import { SalesByZone } from "Screens/Portal/Reports/SalesByZone";
import { SalesByItem } from "Screens/Portal/Reports/SalesByItem";
import { SalesByHour } from "Screens/Portal/Reports/SalesByHour";
import { SalesByCashier } from "Screens/Portal/Reports/SalesByCashier";
import { SalesByDriver } from "Screens/Portal/Reports/SalesByDriver";
import { SalesByPaymentMethod } from "Screens/Portal/Reports/SalesByPaymentMethod";
import { VoidOrders } from "Screens/Portal/Reports/VoidOrders";
import { VoidItems } from "Screens/Portal/Reports/VoidItems";
import { MergedOrders } from "Screens/Portal/Reports/MergedOrders";
import { ZReport } from "Screens/Portal/Reports/ZReport";

// Factories

// Screens

// Assets
import voided from "Assets/Images/voided.png";
import sales from "Assets/Images/sales.png";
import zReport from "Assets/Images/zReport.png";

// Third Parties
import { useSnackbar } from "notistack";
import { Setup } from "../Setup";
import Color from "color";
// import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree"

// Services

// Styles
const useGridStyles = makeStyles(({ breakpoints }) => ({
  root: {
    justifyContent: "center",
  },
}));

const useStyles = makeStyles(({ spacing }) => ({
  actionArea: {
    height: "100%",
    borderRadius: 16,
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  card: ({ color }) => ({
    // maxHeight: 400,
    maxWidth: "100%",
    // minWidth: 256,
    // minHeight: 250,
    borderRadius: 16,
    boxShadow: "none",
    "&:hover": {
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
  content: ({ color }) => {
    return {
      height: "180px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      // backgroundColor: color,
      backgroundColor: "rgb(216 216 216);",
      padding: "1rem 1.5rem 1.5rem",
    };
  },
  title: {
    fontFamily: "Roboto,Changa",
    fontSize: "1.6rem",
    // color: "#fff",
    textTransform: "uppercase",
  },
  subtitle: {
    fontFamily: "Montserrat",
    // color: "#fff",
    opacity: 0.87,
    marginTop: "1rem",
    fontWeight: 500,
    fontSize: 14,
  },
  link: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: spacing(0.5),
    width: 20,
    height: 20,
  },
  mediaClasses: {
    width: "100%",
    height: "0px",
    paddingBottom: "75%",
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    filter: "grayscale(40%)",
    // filter: "sepia(100%) hue-rotate(190deg) saturate(500%)"
  },
}));

const StyledBreadCrumbs = withStyles({
  root: {
    display: "flex",
    marginLeft: 16,
    marginRight: 16,
  },
  ol: {
    flexWrap: "nowrap",
  },
  li: {
    display: "flex",
    "& a": {
      display: "flex",
      alignItems: "center",
    },
  },
})(Breadcrumbs);

// Ad-Hoc Components
const CustomCard = ({ classes, image, title, subtitle, onClick }) => {
  // const mediaStyles = useFourThreeCardMediaStyles()
  return (
    <CardActionArea className={classes.actionArea} onClick={onClick}>
      <Card className={classes.card}>
        {/* <CardMedia classes={mediaStyles} image={image} /> */}
        <CardMedia classes={{ root: classes.mediaClasses }} image={image} />
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant={"h2"}>
            {title}
          </Typography>
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

/**
 * @name CardsReports
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CardsReports = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [currentComponent, setCurrentComponent] = useGlobalState(
    "components.reportsCurrentComponent"
  );
  const [heading, setHeading] = useGlobalState("heading.heading");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [isAnimationDone, setIsAnimationDone] = useState(false);
  const [isSetupAnimationDone, setIsSetupAnimationDone] = useState(true);
  const [currentTabs, setCurrentTabs] = useState({});

  // Effect Hooks
  const downXs = useMediaQuery(theme.breakpoints.down("xs"));
  useEffect(
    () =>
      setHeading(
        <StyledBreadCrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" />}
        >
          <Link
            color={
              currentComponent === "reportCards" ? "textPrimary" : "inherit"
            }
            href="/"
            onClick={(event) => handleClick(event, "reportCards")}
            className={classes.link}
          >
            <AssessmentIcon
              style={{ marginRight: theme.spacing(0.5), width: 20, height: 20 }}
              color="primary"
            />
            <Typography
              variant="h6"
              component="h2"
              style={{ margin: "16px 8px" }}
              color="primary"
            >
              <LocalizedMessage id="report.pageTitle" />
            </Typography>
          </Link>
          {!downXs && isAnimationDone && currentTabs.title ? (
            <Link color="textPrimary" className={classes.link}>
              {currentTabs.icon}
              <Typography
                variant="h6"
                component="h2"
                style={{ margin: "16px 8px" }}
              >
                {currentTabs.title}
              </Typography>

              {/* <WhatshotIcon className={classes.icon} /> */}
            </Link>
          ) : null}
        </StyledBreadCrumbs>
      ),
    [currentComponent, currentTabs, isAnimationDone, downXs]
  );

  // Other Hooks
  const gridStyles = useGridStyles();
  // const styles = useStyles({ color: "#7C499D" })
  // const styles2 = useStyles({ color: "#2E56A5" })
  // const styles3 = useStyles({ color: "#C64092" })
  // const styles4 = useStyles({ color: "#939496" })
  // const styles5 = useStyles({ color: "#A34599" })

  const styles = useStyles({ color: "#2E56A5" });
  const styles2 = useStyles({ color: "#2E56A5" });
  const styles3 = useStyles({ color: "#2E56A5" });
  const styles4 = useStyles({ color: "#2E56A5" });
  const styles5 = useStyles({ color: "#2E56A5" });

  // Event Handlers
  const handleCardClick = (component, tabs) => {
    setCurrentComponent(component);
    setCurrentTabs(tabs);
  };

  function handleClick(event, component) {
    event.preventDefault();
    setCurrentComponent(component);
    setIsAnimationDone(false);
    setCurrentTabs([]);
  }

  // Other
  const tabs = {
    salesTabs: {
      title: <LocalizedMessage id={"sales.pageTitle"} />,
      icon: <FaceIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id="report.reportSummary.pageTitle" />,
          permissionName: "reportSummaryReport",
          icon: <AssessmentIcon />,
          node: <Summary permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.reportSalesByDay.pageTitle" />,
          permissionName: "reportSalesByDay",
          icon: <TodayIcon />,
          node: <SalesByDay permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.salesByChannel.pageTitle" />,
          permissionName: "reportSalesByChannel",
          icon: <EventNoteIcon />,
          node: <SalesByChannel permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.salesByItem.pageTitle" />,
          permissionName: "reportSalesByItem",
          icon: <BallotIcon />,
          node: <SalesByItem permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.salesByHour.pageTitle" />,
          permissionName: "reportSalesByHour",
          icon: <QueryBuilderIcon />,
          node: <SalesByHour permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.salesByZone.pageTitle" />,
          permissionName: "reportSalesByZone",
          icon: <LocationSearchingIcon />,
          node: <SalesByZone permissions={permissions} />,
        },

        {
          label: <LocalizedMessage id="report.salesByCashier.pageTitle" />,
          permissionName: "reportSalesByCashier",
          icon: <FaceIcon />,
          node: <SalesByCashier permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.salesByDriver.pageTitle" />,
          permissionName: "reportSalesByDriver",
          icon: <DriveEtaIcon />,
          node: <SalesByDriver permissions={permissions} />,
        },
        {
          label: (
            <LocalizedMessage id="report.salesByPaymentMethod.pageTitle" />
          ),
          permissionName: "reportSalesByMethod",
          icon: <PaymentIcon />,
          node: <SalesByPaymentMethod permissions={permissions} />,
        },
      ],
    },
    voidedTabs: {
      title: <LocalizedMessage id={"report.voided"} />,
      icon: <ShoppingCartIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id="report.voidOrders.pageTitle" />,
          permissionName: "reportVoidOrders",
          icon: <SpeakerNotesOffIcon />,
          node: <VoidOrders permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.voidItems.pageTitle" />,
          permissionName: "reportVoidItems",
          icon: <CancelPresentationIcon />,
          node: <VoidItems permissions={permissions} />,
        },
        {
          label: <LocalizedMessage id="report.mergedOrders.pageTitle" />,
          permissionName: "reportMergedOrders",
          icon: <MergeTypeIcon />,
          node: <MergedOrders permissions={permissions} />,
        },
      ],
    },
    zReportTabs: {
      title: <LocalizedMessage id={"common.zReport"} />,
      icon: <AssignmentTurnedInIcon className={classes.icon} />,
      data: [
        {
          label: <LocalizedMessage id="common.zReport" />,
          permissionName: "zReport",
          icon: <AssignmentTurnedInIcon />,
          node: <ZReport permissions={permissions} setHeading={{}} />,
        },
      ],
    },
  };

  // Component Render
  return (
    <>
      <Title
        pageTitle={
          <LocalizedMessage id="report.pageTitle" key="report.pageTitle" />
        }
      />
      <Fade
        in={currentComponent === "reportCards" && isSetupAnimationDone}
        mountOnEnter
        unmountOnExit
        // timeout={{ enter: 100, exit: 50 }}
        onEntered={() => {
          setIsAnimationDone(false);
        }}
        onExited={() => {
          setIsAnimationDone(true);
        }}
        style={{ padding: 36 }}
      >
        <Paper
          className={classes.root}
          dir={theme.direction}
          elevation={2}
          square
        >
          <Grid
            // classes={gridStyles}
            container
            spacing={6}
          >
            {(permissions.isAdmin ||
              permissions.reportSalesByHour ||
              permissions.reportSalesByChannel ||
              permissions.reportSalesByCashier ||
              permissions.reportSalesByDriver ||
              permissions.reportSalesByOrder ||
              permissions.reportSalesByItem ||
              permissions.salesByPaymentMethod) && (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <CustomCard
                  classes={styles}
                  title={<LocalizedMessage id={"sales.pageTitle"} />}
                  subtitle={
                    <>
                      <LocalizedMessage id="report.reportSummary.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.reportSalesByDay.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByChannel.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByItem.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByHour.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByZone.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByCashier.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByDriver.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.salesByPaymentMethod.pageTitle" />
                    </>
                  }
                  image={sales}
                  onClick={() => handleCardClick("sales", tabs.salesTabs)}
                />
              </Grid>
            )}
            {(permissions.isAdmin ||
              permissions.reportVoidItems ||
              permissions.reportVoidOrders) && (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <CustomCard
                  classes={styles2}
                  title={<LocalizedMessage id={"report.voidedAndMerged"} />}
                  subtitle={
                    <>
                      <LocalizedMessage id="report.voidItems.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.voidOrders.pageTitle" />
                      {", "}
                      <LocalizedMessage id="report.mergedOrders.pageTitle" />
                    </>
                  }
                  image={voided}
                  onClick={() => handleCardClick("voided", tabs.voidedTabs)}
                />
              </Grid>
            )}
            {(permissions.isAdmin || permissions.zReport) && (
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <CustomCard
                  classes={styles3}
                  title={<LocalizedMessage id={"common.zReport"} />}
                  subtitle={<LocalizedMessage id="common.zReport" />}
                  image={zReport}
                  onClick={() => handleCardClick("zReport", tabs.zReportTabs)}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Fade>
      <Fade
        in={currentComponent !== "reportCards" && isAnimationDone}
        mountOnEnter
        unmountOnExit
        // timeout={{ enter: 200, exit: 50 }}
      >
        <Box>
          <Slide
            in={currentComponent !== "reportCards" && isAnimationDone}
            direction={theme.direction === "ltr" ? "left" : "right"}
            mountOnEnter
            unmountOnExit
            // timeout={{ enter: 100, exit: 50 }}
            onEntered={() => {
              setIsSetupAnimationDone(false);
            }}
            onExited={() => {
              setIsSetupAnimationDone(true);
            }}
          >
            <Box>
              <Setup
                permissions={permissions}
                tabs={currentTabs.data}
                title="report.pageTitle"
              />
            </Box>
          </Slide>
        </Box>
      </Fade>
    </>
  );
};

CardsReports.propTypes = {
  /**
   *
   */
};

CardsReports.defaultProps = {
  /**
   *
   */
};

export default CardsReports;
