// @ts-nocheck
// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Button,
  Divider,
  Typography,
  Popover,
  useTheme,
  Tooltip,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MinimizeIcon from "@material-ui/icons/Minimize";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import blueGrey from "@material-ui/core/colors/blueGrey";

import grey from "@material-ui/core/colors/grey";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties
import { Doughnut } from "react-chartjs-2";

// Styles

// Ad-Hoc Components

/**
 * @name PieChartCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const PieChartCard = ({ blurred, title, pieChartData, style }) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Card style={style}>
      <CardContent>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Typography color="textSecondary" gutterBottom>
            {title}
          </Typography>
        </Box>
        <Box
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {blurred && (
            <Typography
              variant="h5"
              style={{
                color: "#0000008A",
                zIndex: 1000,
                position: "absolute",
              }}
            >
              <LocalizedMessage id="common.noData" />
            </Typography>
          )}
          <Box
            style={{
              filter: blurred ? "blur(8px)" : null,
              WebkitFilter: blurred ? "blur(8px)" : null,
            }}
          >
            <Doughnut
              data={pieChartData}
              height={300}
              options={{
                elements: {
                  line: {
                    tension: 0,
                  },
                },
                title: {
                  display: false,
                },
                legend: {
                  display: true,
                  position: "bottom",
                },
                layout: {
                  padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                  },
                },
                scales: {
                  xAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        display: false, //this will remove only the label
                      },
                    },
                  ],
                  yAxes: [
                    {
                      gridLines: {
                        display: false,
                      },
                      ticks: {
                        display: false, //this will remove only the label
                      },
                    },
                  ],
                },
                tooltips: {
                  callbacks: {
                    title: (tooltipItem, data) => {
                      return data["labels"][tooltipItem[0]["index"]];
                    },
                    label: (tooltipItem, data) => {},
                  },
                },
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

PieChartCard.propTypes = {
  /**
   *
   */
};

export default PieChartCard;
