// React
import React, { useEffect, useState } from "react";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { makeStyles } from "Helpers/Styles";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField } from "Components/FormFields";
import FormActions from "Components/FormActions";

// Factories

// Screens
import SalesChannelForm from "Screens/Portal/SalesChannels/SalesChannelForm";
import TaxForm from "Screens/Portal/Taxes/TaxForm";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import { useAddSalesSection } from "Services/SalesChannels";
import { useGetAllSalesSectionsDropdowns, useGetAllTaxesDropdowns } from "Services/Dropdowns/";
import { useAddTax } from "Services/Taxes/";
import { useAddSurCharge, useEditSurCharge } from "Services/SurCharges/";
// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  value: Joi.number().required().greater(0),
  valueType: Joi.string().valid("FIXED_AMOUNT", "PERCENTAGE").required(),
  taxes: Joi.array().items(Joi.string().uuid()),
  salesSections: Joi.array().items(Joi.string().uuid()),
});

/**
 * @name SurChargeForm
 * @summary
 * @SurCharge
 * @component
 * @description
 * >
 */

const SurChargeForm = ({
  editFormData,
  editId,
  addCallback,
  editCallback,
  onCancel,
  mode,
  open,
  setOpen,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [salesSections, setSalesSections] = useGlobalState("salesSections.salesSections");

  const [taxes, setTaxes] = useGlobalState("taxes.taxes");

  // State Hooks
  const [salesChannelFormOpen, setSalesChannelFormOpen] = useState(false);
  const [openTaxForm, setOpenTaxForm] = useState(false);

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      editFormData = {
        ...editFormData,
        taxes: editFormData.taxSurcharges ? editFormData.taxSurcharges.map((tax) => tax.taxId) : [],

        salesSections: editFormData.salesSections
          ? editFormData.salesSections.map((salesSection) => salesSection.id)
          : [],
      };
      setFormData(
        _.pick(editFormData, ["name", "otherName", "value", "valueType", "salesSections", "taxes"])
      );
    } else setFormData({});
  }, [open]);

  useEffect(() => {
    if (!salesSections.isFetchingRows && !salesSections.data.length)
      getAllSalesSectionsDropdowns()
        .then((res) => {
          setSalesSections({
            data: res,
            isFetchingRows: false,
          });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [salesSections.isFetchingRows]);

  useEffect(() => {
    if (!taxes.isFetchingRows && !taxes.data.length)
      getAllTaxesDropdowns()
        .then((res) => {
          setTaxes({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [taxes.isFetchingRows]);

  // Other Hooks
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const addSalesChannel = useAddSalesSection({
    languageKey: "setup.salesChannel",
  });
  const getAllSalesSectionsDropdowns = useGetAllSalesSectionsDropdowns();
  const getAllTaxesDropdowns = useGetAllTaxesDropdowns();
  const addSurCharge = useAddSurCharge({ languageKey: "common.surcharge" });
  const editSurCharge = useEditSurCharge({ languageKey: "common.surcharge" });
  const addTax = useAddTax({ languageKey: "common.tax" });

  const surchargeForm = useForm({
    validationSchema,
    languageKey: "common.surcharge",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = surchargeForm;
  // Event Handlers
  const openAddSurChargeForm = (parentSurCharge) => {
    setOpen(true);
  };

  const onCLoseSurChargeForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onOpenTaxForm = () => {
    setOpenTaxForm(true);
  };

  const onAddSurCharge = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: surchargeForm,
      formName: "surcharge",
      apiHandler: addSurCharge,
      onClose: onCLoseSurChargeForm,
    });
  };

  const onSaveSurCharge = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: surchargeForm,
      formName: "surcharge",
      apiHandler: editSurCharge,
      onClose: onCLoseSurChargeForm,
    });
  };

  const onOpenSalesChannelForm = () => {
    setSalesChannelFormOpen(true);
  };

  const onCloseSalesChannelForm = () => {
    setSalesChannelFormOpen(false);
  };

  const salesChannelDropDownCallback = (response) => {
    const salesSection = response.data.data;
    const newSalesSection = {
      value: salesSection.id,
      text: salesSection.name,
      salesSectionTypeId: salesSection.salesSectionTypeId,
    };
    setSalesSections({
      ...salesSections,
      data: [...salesSections.data, salesSection],
    });
    const formDataSalesSections = formData.salesSections ? formData.salesSections : [];

    setFormData({
      ...formData,
      salesSections: [...formDataSalesSections, newSalesSection.value],
    });
  };
  const taxDropDownCallback = (response) => {
    const tax = response.data.data;
    const newTax = {
      value: tax.id,
      text: tax.name,
    };
    const formDataTaxes = formData.taxes ? formData.taxes : [];

    setTaxes({ ...taxes, data: [...taxes.data, tax] });
    setFormData({
      ...formData,
      taxes: [...formDataTaxes, newTax.value],
    });
  };

  // Other
  console.log(
    `%cSurChargeForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseSurChargeForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="common.surcharge" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Surcharge Name"}
                helpKey="setup.surcharge.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                tooltip={"Surcharge Other Name"}
                helpKey="setup.surcharge.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
              <TextField
                required
                fullWidth
                name="value"
                autoComplete="value"
                tooltip={"Value"}
                helpKey="common.value"
                label={<LocalizedMessage id="common.value" />}
                onChange={onFormFieldChange}
                value={formData.value}
                errors={formErrors.value}
                isSubmitting={isSubmitting}
                isLoading={isLoading.value}
              />
              <SelectField
                required
                fullWidth
                name="valueType"
                tooltip={"Surcharge value type"}
                helpKey="common.valueType"
                label={<LocalizedMessage id="common.valueType" />}
                onChange={onFormFieldChange}
                value={formData.valueType}
                errors={formErrors.valueType}
                isSubmitting={isSubmitting}
                isLoading={isLoading.valueType}
                options={[
                  {
                    value: "FIXED_AMOUNT",
                    text: intl.formatMessage({
                      id: "common.fixedAmount",
                    }),
                  },
                  {
                    value: "PERCENTAGE",
                    text: intl.formatMessage({
                      id: "common.percentage",
                    }),
                  },
                ]}
              />
              <SelectField
                fullWidth
                name="salesSections"
                tooltip={"Sales Channels"}
                helpKey="setup.surcharge.salesSectionId"
                label={<LocalizedMessage id="common.salesSections" />}
                onChange={onFormFieldChange}
                value={formData.salesSections}
                errors={formErrors.salesSections}
                isSubmitting={isSubmitting}
                isLoading={isLoading.salesSections}
                options={salesSections.data.map((s) => ({
                  value: s.id,
                  text: s.name,
                  disabled: s.salesSectionTypeId === "Staff",
                }))}
                openForm={onOpenSalesChannelForm}
                multiple
                addPermission={
                  permissions.isAdmin ||
                  (permissions.salesSection && permissions.salesSection.includes("add"))
                }
              />
              <SelectField
                fullWidth
                name="taxes"
                tooltip={"Taxes"}
                helpKey="setup.surcharge.taxId"
                label={<LocalizedMessage id="common.taxes" />}
                onChange={onFormFieldChange}
                value={formData.taxes}
                errors={formErrors.taxes}
                isSubmitting={isSubmitting}
                isLoading={isLoading.taxes}
                options={taxes.data
                  .map((t) => {
                    if (t.taxType === "surcharge") return { value: t.id, text: t.name };
                  })
                  .filter((t) => t !== undefined)}
                openForm={onOpenTaxForm}
                multiple
                addPermission={
                  permissions.isAdmin || (permissions.tax && permissions.tax.includes("add"))
                }
              />
            </form>
            <SalesChannelForm
              open={salesChannelFormOpen}
              mode="add"
              addCallback={salesChannelDropDownCallback}
              onCancel={onCloseSalesChannelForm}
              permissions={permissions}
            />
            <TaxForm
              open={openTaxForm}
              mode={"add"}
              addCallback={taxDropDownCallback}
              onCancel={() => setOpenTaxForm(false)}
              permissions={permissions}
              parent="surcharge"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddSurCharge}
          mode={mode}
          onCLoseForm={onCLoseSurChargeForm}
          isSubmitting={isSubmitting}
          onSave={onSaveSurCharge}
        />
      </DialogActions>
    </Dialog>
  );
};

SurChargeForm.propTypes = {
  /**
   *
   */
};

SurChargeForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default SurChargeForm;
