// React
import React, { useEffect, useState } from "react";

// Material
import {
  Checkbox,
  Typography,
  Box,
  DialogContentText,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@material-ui/core/";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import TreeItem from "@material-ui/lab/TreeItem";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import uuid from "uuid";
import _ from "lodash";

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
}));

// Ad-Hoc Components

/**
 * @name XZReportTree
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const XZReportTree = ({
  key,
  open,
  onSave,
  onCancel,
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  maxCharacters,
  defaultValue,
  value,
  type,
  onChange,
  id,
  isLoading,
  showFirstError: onlyShowFirstError,
  isSubmitting,
  hidden,
  name,
  title,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  customizeXZReport,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4();
  const newValue = value ? JSON.parse(JSON.stringify(value)) : undefined;
  const newCustomizeXZReport = customizeXZReport
    ? JSON.parse(JSON.stringify(customizeXZReport))
    : undefined;
  value =
    value === undefined || value === null ? newCustomizeXZReport : newValue;

  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [currentValue, setCurrentValue] = useState(value);

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks
  Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    var a = s.split(".");
    for (var i = 0, n = a.length; i < n; ++i) {
      var k = a[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  };

  // Event Handlers
  const recursivelySet = (prevKey, obj, checked) => {
    Object.entries(obj).map(([key, value]) => {
      let path = prevKey + "." + key;

      if (typeof value === "boolean") {
        _.set(currentValue, path, checked);
      } else recursivelySet(path, value, checked);
    });
  };

  const handleCheckBox = (e, checked, node) => {
    typeof e.persist === "function" && e.persist();

    const targetName =
      e.target.name[0] === "." ? e.target.name.substring(1) : e.target.name;
    const val = Object.byString(currentValue, targetName);

    if (typeof val === "object" && val !== null) {
      recursivelySet(targetName, val, checked);
    } else _.set(currentValue, targetName, checked);

    setCurrentValue({ ...currentValue });

    typeof onChange === "function" && onChange(name, currentValue);
  };

  const checkRecursive = (obj) => {
    return Object.values(obj).every((el) =>
      typeof el === "object" ? checkRecursive(el) : el
    );
  };

  const checkRecursiveFalse = (obj) => {
    return Object.values(obj).every((el) =>
      typeof el === "object" ? checkRecursiveFalse(el) : !el
    );
  };

  const isThisChecked = (parent, node) => {
    const name = `${parent}.${node}`;

    const val = Object.byString(currentValue, name);
    if (typeof val === "boolean") return val;
    else {
      return checkRecursive(val);
    }
  };

  const isThisIntermediate = (parent, node) => {
    const name = `${parent}.${node}`;

    const val = Object.byString(currentValue, name);
    if (typeof val === "boolean") return false;
    else {
      return !(checkRecursiveFalse(val) ^ checkRecursive(val));
    }
  };

  // Other
  const label = (node, index, parent) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <>
          <Checkbox
            id={`checkbox-${index}`}
            name={`${parent}.${node}`}
            checked={isThisChecked(parent, node)}
            onChange={(e, checked) => handleCheckBox(e, checked, node)}
            onClick={(e) => e.stopPropagation()}
            indeterminate={isThisIntermediate(parent, node)}
            indeterminateIcon={<IndeterminateCheckBoxIcon color="secondary" />}
          />
          <Typography variant="body2">
            {<LocalizedMessage id={`setup.printersDesign.${node}`} />}
          </Typography>
        </>
        {/* )} */}
      </div>
    );
  };

  const renderTree = (node, index, parent) => (
    <TreeItem key={index} nodeId={index} label={label(node[0], index, parent)}>
      {typeof node[1] === "object" && node[1] !== null //check if the value is an object
        ? Object.entries(node[1]).map((role, i) =>
            renderTree(
              role,
              `${role[0]}-${i}`, // node
              parent ? `${parent}.${node[0]}` : node[0] // parent name
            )
          )
        : null}
    </TreeItem>
  );

  // Component Render
  return (
    <Box display="flex">
      <Box flex={1}>
        <DialogContentText>
          <Typography component="span">
            <LocalizedMessage id="setup.printersDesign.reportTreePageTitle" />
          </Typography>
        </DialogContentText>
        <TreeView
          className={classes.root}
          // defaultExpanded={Object.keys(newCustomizeXZReport).map(
          //   (el, index) => `${el}-${index}`
          // )}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={
            theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )
          }
        >
          {Object.entries(newCustomizeXZReport).map((role, index) =>
            renderTree(role, `${role[0]}-${index}`, "")
          )}
        </TreeView>
      </Box>
    </Box>
  );
};

XZReportTree.propTypes = {
  /**
   *
   */
};

XZReportTree.defaultProps = {
  /**
   *
   */
  variant: "outlined",
  margin: "normal",
  //   autoComplete: "off",
  showFirstError: true,
  isLoading: false,
  autoFocus: false,
  onChange: () => {},
  fullWidth: true,
};

export default XZReportTree;
