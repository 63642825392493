import React, { Component } from "react";
import PropTypes from "prop-types";
import "./App.css";
import Viewer from "./Viewer.js";
import pdfjsLib from "pdfjs-dist/webpack";
import { Box, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { withTheme } from "@material-ui/core/styles";
import isNull from "lodash/isNull";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scale: 0,
      url: isNull,
    };
  }

  componentDidMount() {
    this.setState({ url: this.props.url });
    let loadingTask = pdfjsLib.getDocument(this.props.url);
    loadingTask.promise.then(
      (doc) => {
        console.log(
          `Document ${this.props.url} loaded ${doc.numPages} page(s)`
        );
        this.viewer.setState({
          doc,
        });
      },
      (reason) => {
        console.error(`Error during ${this.props.url} loading: ${reason}`);
      }
    );
  }

  zoomIn(e) {
    if (this.state.scale < 3.0)
      this.viewer.setState({
        scale: this.viewer.state.scale * 1.1,
      });
  }

  zoomOut(e) {
    if (this.state.scale > 0.5)
      this.viewer.setState({
        scale: this.viewer.state.scale / 1.1,
      });
  }

  displayScaleChanged(e) {
    this.setState({
      scale: e.scale,
    });
  }

  render() {
    return (
      <div className="App">
        <Box
          style={{
            backgroundColor: "#404040",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            marginRight="auto"
            marginLeft="auto"
            overflow="auto"
          >
            <IconButton onClick={(e) => this.zoomOut(e)}>
              <RemoveIcon
                style={{ color: "#fafafa", margin: this.props.downSm ? 0 : 8 }}
              />
            </IconButton>
            <Typography
              style={{
                backgroundColor: "#000",
                color: "#fafafa",
                margin: this.props.downSm ? 0 : 8,
              }}
            >
              {(this.state.scale * 100).toFixed(1)}%
            </Typography>
            <IconButton onClick={(e) => this.zoomIn(e)}>
              <AddIcon
                style={{ color: "#fafafa", margin: this.props.downSm ? 0 : 8 }}
              />
            </IconButton>
            <IconButton
              onClick={() => this.viewer._pdfViewer.currentPageNumber--}
            >
              {this.props.theme.direction === "rtl" ? (
                <NavigateNextIcon
                  style={{
                    color: "#fafafa",
                    margin: this.props.downSm ? 0 : 8,
                  }}
                />
              ) : (
                <NavigateBeforeIcon
                  style={{
                    color: "#fafafa",
                    margin: this.props.downSm ? 0 : 8,
                  }}
                />
              )}
            </IconButton>
            <IconButton
              onClick={() => this.viewer._pdfViewer.currentPageNumber++}
            >
              {this.props.theme.direction === "rtl" ? (
                <NavigateBeforeIcon
                  style={{
                    color: "#fafafa",
                    margin: this.props.downSm ? 0 : 8,
                  }}
                />
              ) : (
                <NavigateNextIcon
                  style={{
                    color: "#fafafa",
                    margin: this.props.downSm ? 0 : 8,
                  }}
                />
              )}
            </IconButton>
          </Box>
          <Box alignSelf="flex-end">
            <IconButton onClick={() => this.viewer.print(this.state.url)}>
              <PrintIcon
                style={{ color: "#fafafa", margin: this.props.downSm ? 0 : 8 }}
              />
            </IconButton>
          </Box>
          <Box alignSelf="flex-end">
            <IconButton onClick={() => window.open(this.state.url)}>
              <GetAppIcon
                style={{ color: "#fafafa", margin: this.props.downSm ? 0 : 8 }}
              />
            </IconButton>
          </Box>
        </Box>
        <div className="App-body">
          <Viewer
            ref={(ref) => (this.viewer = ref)}
            onScaleChanged={(e) => this.displayScaleChanged(e)}
          ></Viewer>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  url: PropTypes.string,
};

export default withTheme(App);
