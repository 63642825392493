// React
import React, { useState, useEffect } from "react";

// Material
import { useTheme, Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import RedeemIcon from "@material-ui/icons/RedeemOutlined";
import GavelIcon from "@material-ui/icons/GavelOutlined";
import RoomServiceIcon from "@material-ui/icons/RoomServiceOutlined";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import useInterval from "Helpers/useInterval";

// Components

// Factories

// Screens
import SummaryCard from "./SummaryCard";

// Assets

// Third Parties
import { useIntl } from "react-intl";
import moment from "moment";
import { useSnackbar } from "notistack";

// Services
import { useGetSummaryData } from "Services/Live/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 497,
  },
}));

const Summary = () => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [isLoading, setIsLoading] = useState(true);
  const [summaryData, setSummaryData] = useState({});
  const [ordersStatuses, setOrdersStatuses] = useState({
    openOrdersCount: 0,
    closedOrdersCount: 0,
    voidOrdersCount: 0,
    mergedOrdersCount: 0,
    splittedOrdersCount: 0,
    returnedOrdersCount: 0,
  });

  // Event Handlers
  useEffect(() => {
    getSummary();
  }, []);

  // Other
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const getSummaryData = useGetSummaryData();
  const createOrderStatusesData = (allSummaryData) => {
    return {
      openOrdersCount: allSummaryData.openOrdersCount,
      closedOrdersCount: allSummaryData.closedOrdersCount,
      voidOrdersCount: allSummaryData.voidOrdersCount,
      mergedOrdersCount: allSummaryData.mergedOrdersCount,
      splittedOrdersCount: allSummaryData.splittedOrdersCount,
      returnedOrdersCount: allSummaryData.returnedOrdersCount,
    };
  };

  const getSummary = () => {
    setIsLoading(true);
    getSummaryData()
      .then((response) => {
        setSummaryData(response[0]);
        setOrdersStatuses(createOrderStatusesData(response[0]));
        setIsLoading(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsLoading(false);
      });
  };
  useInterval(getSummary, 30000, 15 * 60000);

  return (
    <>
      <Typography variant="h6" component="h2" style={{ marginBottom: 4 }}>
        <LocalizedMessage id="rolesNames.actions.summary" />
      </Typography>
      <Grid container justify="center" spacing={4}>
        {Object.entries(ordersStatuses).map((key, value) => {
          return (
            <Grid xs={12} sm={6} md={4} lg={2} item>
              <SummaryCard
                keyy={key}
                isLoading={isLoading}
                CardIcon={TrendingUpOutlinedIcon}
                headerStyle={{
                  backgroundColor:
                    key[0] === "openOrdersCount"
                      ? "#008000"
                      : key[0] === "closedOrdersCount"
                      ? "#808080"
                      : key[0] === "mergedOrdersCount"
                      ? "#E3BB57"
                      : key[0] === "voidOrdersCount"
                      ? "#FF0000"
                      : key[0] === "returnedOrdersCount"
                      ? "#E91E63"
                      : "#191970",
                  color: "white",
                }}
              />
            </Grid>
          );
        })}
        <Grid xs={12} sm={6} md={4} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <TrendingUpOutlinedIcon fontSize="large" style={{ color: "green" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.totalsales" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.totalSales ? summaryData.totalSales : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <BarChartOutlinedIcon fontSize="large" style={{ color: "MidnightBlue" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.netsales" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.netSales ? summaryData.netSales : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <LocalMallOutlinedIcon fontSize="large" style={{ color: "GoldenRod" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.orderscount" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.ordersCount ? summaryData.ordersCount : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <RoomServiceIcon fontSize="large" style={{ color: "grey" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.surcharge" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.surcharge ? summaryData.surcharge : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <GavelIcon fontSize="large" style={{ color: "green" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.tax" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">{summaryData.tax ? summaryData.tax : 0}</Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} md={4} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <RedeemIcon fontSize="large" style={{ color: "red" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.discount" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.discount ? summaryData.discount : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <LocalOfferOutlinedIcon fontSize="large" style={{ color: "red" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.complementaryTotal" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.complementaryTotalSales
                          ? summaryData.complementaryTotalSales
                          : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12} sm={6} lg={3} item>
          <Card style={{ display: "flex", alignItems: "center", height: "100%" }}>
            <CardContent>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={60} style={{ margin: 6 }} />
                ) : (
                  <Box
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      padding: 16,
                      marginRight: 8,
                    }}
                  >
                    <MonetizationOnOutlinedIcon fontSize="large" style={{ color: "#e91e63" }} />
                  </Box>
                )}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {isLoading ? (
                    <Skeleton width={100} height={35} />
                  ) : (
                    <Typography variant="subtitle1">
                      <LocalizedMessage id="report.complementaryNetSales" />
                    </Typography>
                  )}
                  {isLoading ? (
                    <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                  ) : (
                    <Box style={{ marginTop: "auto" }}>
                      <Typography variant="h4">
                        {summaryData.complementaryNetSales ? summaryData.complementaryNetSales : 0}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
export default Summary;
