// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { Checkbox, Typography, Box, DialogContentText, useTheme } from "@material-ui/core/";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import TreeItem from "@material-ui/lab/TreeItem";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets
import mockCategories from "Data/Mock/Menu/c";

// Third Parties

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
}));

// Ad-Hoc Components

/**
 * @name CheckBoxesTree
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const CheckBoxesTree = ({
  categoriesAndItemsData,
  categories,
  setCategories,
  items,
  setItems,
  itemsData,
  setItemsData,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  // useEffect(() => {

  // }, []);

  // Other Hooks

  // Event Handlers
  const recursivelySet = (obj) => {
    if (obj.items.length)
      setItems((items) => {
        var ids = new Set(items.map((d) => d.id));
        var newItems = [...items, ...obj.items.filter((item) => !ids.has(item.id))];
        return newItems;
      });
    if (obj.children.length) setCategories((categories) => [...categories, ...obj.children]);

    if (obj.children.length) {
      obj.children.map((objectChild) => recursivelySet(objectChild));
    }
  };

  const recursivelyUnset = (obj) => {
    setItems((items) => {
      // removed children items
      return items.filter((item) => item.categoryId !== obj.id);
    });

    if (obj.children.length) {
      obj.children.map((child) => recursivelyUnset(child));
    }

    setCategories((categories) => {
      // removed children categories and the parent category (@TODO remove all parents)
      let newCategories = categories.filter(
        (category) => category.id !== obj.id && category.id !== obj.parentId
      );

      return [...newCategories];
    });
  };

  const handleCheckBox = (e, checked, node, parent) => {
    if (checked) {
      setCategories([...categories, node]);
      recursivelySet(node);
    } else {
      recursivelyUnset(node);
    }
  };

  const handleItemsCheckBox = (e, checked, node, parent) => {
    const currentItemIndex = items.findIndex((item) => item.id === node.id);
    if (checked) {
      if (currentItemIndex === -1) {
        setItems([...items, node]);
        isThisChecked(parent, [...items, node]) &&
          setCategories((categories) => [...categories, parent]);
      }
    } else {
      if (currentItemIndex !== -1) {
        const newItems = Array.from(items);
        newItems.splice(currentItemIndex, 1);
        setItems([...newItems]);
        setCategories((categories) => {
          const newCategories = categories.filter((category) => category.id !== node.categoryId);

          return [...newCategories];
        });
      }
    }
  };

  const isThisNotChecked = (node) =>
    node.items?.every((el) => !items.find((item) => item.id === el.id)) &&
    node.children.every(
      (el) => !categories.find((category) => category.id === el.id) && isThisNotChecked(el)
    );

  const isThisChecked = (node, searchInTheseItems) => {
    if (node.items.length)
      return (
        node.items?.every((el) =>
          searchInTheseItems?.length
            ? searchInTheseItems.find((item) => item.id === el.id)
            : items.find((item) => item.id === el.id)
        ) && node.children.every((el) => isThisChecked(el))
      );
    else if (node.children.length) return node.children.every((el) => isThisChecked(el));
    else {
      return categories.find((category) => category.id === node.id);
    }
  };

  const isThisIntermediate = (node) => !(isThisNotChecked(node) ^ isThisChecked(node));

  // Other
  const label = (node, parent) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        id={`checkbox-${node.id}`}
        checked={isThisChecked(node)}
        onChange={(e, checked) => handleCheckBox(e, checked, node, parent)}
        onClick={(e) => e.stopPropagation()}
        indeterminate={isThisIntermediate(node)}
        indeterminateIcon={<IndeterminateCheckBoxIcon color="secondary" />}
      />
      <Typography variant="caption">{node.name}</Typography>
    </div>
  );

  const itemsLabel = (node, parent) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Checkbox
        id={`checkbox-${node.id}`}
        checked={Boolean(items.filter((item) => item.id === node.id).length)}
        onChange={(e, checked) => handleItemsCheckBox(e, checked, node, parent)}
        onClick={(e) => e.stopPropagation()}
      />
      <Typography variant="caption">{node.name}</Typography>
    </div>
  );

  const renderTree = (node, parent) => (
    <>
      <TreeItem key={node.id} nodeId={node.id} label={label(node, parent)}>
        {Array.isArray(node.children) ? node.children.map((n) => renderTree(n, parent)) : null}
        {node?.items.map((item) => (
          <TreeItem key={item.id} nodeId={item.id} label={itemsLabel(item, node)} />
        ))}
      </TreeItem>
    </>
  );

  console.log("items", items);
  console.log("categories", categories);

  // Component Render
  return (
    <Box display="flex">
      <Box flex={1}>
        <DialogContentText>
          <Typography component="span">
            <LocalizedMessage id="export.chooseItems" />
          </Typography>
        </DialogContentText>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={theme.direction === "rtl" ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        >
          {categoriesAndItemsData.map((node) => renderTree(node, {}))}
        </TreeView>
      </Box>
    </Box>
  );
};

CheckBoxesTree.propTypes = {
  /**
   *
   */
};

CheckBoxesTree.defaultProps = {
  /**
   *
   */
};

export default CheckBoxesTree;
