// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Toolbar,
  Chip,
  useTheme,
  useMediaQuery,
  Button,
  CircularProgress,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import StorageIcon from "@material-ui/icons/Storage";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { getReportDate, getDateDurations } from "Utils";
import {
  displaySnackbarFormErrors,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";

// Components
import { DatePicker, SelectField } from "Components/FormFields";
import Row from "../Row";
import App from "../PDFViewer/App";
import Memo from "Components/Memo";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import _ from "lodash";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import moment from "moment";

// Services
import { useGetSalesByZone, useGetSalesByZonePdf } from "Services/Reports/";
import { useGetAllZones } from "Services/Dropdowns/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 660,
    scroll: "auto",
  },
}));

// Ad-Hoc Components

const preColumns = [
  {
    id: "zone",
    label: <LocalizedMessage id="report.zone" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "netSales",
    label: <LocalizedMessage id="report.netsales" />,
    minWidth: 100,
    format: false,
  },
];

const postColumns = [
  {
    id: "count",
    label: <LocalizedMessage id="report.count" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "avgerage",
    label: <LocalizedMessage id="report.average" />,
    minWidth: 100,
    format: false,
  },

  {
    id: "percentage",
    label: <LocalizedMessage id="common.percentage" />,
    minWidth: 200,
    format: false,
  },
];

const secondaryColumns = [
  {
    id: "paymentmethod",
    label: <LocalizedMessage id="common.paymentMethod" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "trx",
    label: <LocalizedMessage id="report.count" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "surcharges",
    label: <LocalizedMessage id="common.surcharge" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "income",
    label: <LocalizedMessage id="common.income" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "discount",
    label: <LocalizedMessage id="report.discount" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "tax",
    label: <LocalizedMessage id="common.tax" />,
    minWidth: 100,
    format: false,
  },
  {
    id: "total",
    label: <LocalizedMessage id="report.total" />,
    minWidth: 100,
    format: false,
  },
];

/**
 * @name SalesByZone
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesByZone = ({ permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [zones, setZones] = useState([]);
  const [isFetchingZones, setIsFetchingZones] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [footerRow, setFooterRow] = useStateRef([]);

  const [isFetchingRows, setIsFetchingRows] = useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);
  const [pdf, setPdf] = useState("");
  const [PDFViewerOpen, setPDFViewerOpen] = useState(false);
  const [columns, setColumns] = useState([...preColumns, ...postColumns]);
  const [hasPdfAccess, setHasPdfAccess] = useState(false);
  const [hasTableAccess, setHasTableAccess] = useState(false);
  const [spaningData, setSpaningData] = useState([]);

  // Effect Hooks
  useEffect(() => {
    getAllZones()
      .then((data) => {
        setZones(data);
        setIsFetchingZones(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingZones(false);
      });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const getSalesByZone = useGetSalesByZone();
  const getSalesByZonePdf = useGetSalesByZonePdf();
  const getAllZones = useGetAllZones();
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Joi.object({
    dateDuration: Joi.string()
      .valid(
        "today",
        "yesterday",
        "thisWeek",
        "lastWeek",
        "thisMonth",
        "lastMonth",
        "thisYear",
        "lastYear",
        "custom"
      )
      .required(),
    dateFrom: Joi.date(),
    dateTo: Joi.date().min(Joi.ref("dateFrom")),
    zones: Joi.array().items(Joi.string().uuid()),
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "report" });

  useEffect(() => {
    const { from, to } = getReportDate("today", "", "");
    setFormData({
      dateDuration: "today",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  useEffect(() => {
    if (formData.dateDuration === "today") handleSearch();
  }, [formData.dateDuration]);

  useEffect(() => {
    if (formData.dateDuration) handleSearch();
  }, [PDFViewerOpen]);
  useEffect(() => {
    if (permissions.isAdmin) {
      setHasPdfAccess(true);
      setHasTableAccess(true);
    } else {
      if (permissions.reportSalesByZone.includes("pdf")) setHasPdfAccess(true);
      if (permissions.reportSalesByZone.includes("get"))
        setHasTableAccess(true);
    }
  }, []);

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    const validForm = validateForm();

    if (validForm) {
      setIsFetchingRows(true);
      setIsFetchingPdf(true);
      setPdf("");
      setRows([]);
      setFooterRow([]);
      let { dateDuration, dateFrom, dateTo, zones } = formData;

      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      setFormData({ ...formData, dateFrom: from, dateTo: to });

      if (hasTableAccess && !PDFViewerOpen)
        getSalesByZone({
          dateFrom: from,
          dateTo: to,
          zones,
        })
          .then((response) => {
            // setColumns([
            //   response.data.map((c) => {
            //     createColumn(c)
            //   }),
            // ])

            let newSpaningData = {};
            if (response.data[0]) {
              const cols = Object.keys(response.data[0]);
              cols.map((column) => (newSpaningData[column] = 0));
            }

            response.data.map((key) =>
              Object.entries(key).map((k) => {
                newSpaningData[k[0]] += k[1];
              })
            );
            delete newSpaningData.zone;
            setSpaningData(newSpaningData);
            setRows(response.data);
            setFooterRow(response.footer);
            setIsFetchingRows(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else {
        setPDFViewerOpen(true);
        setIsFetchingRows(false);
      }

      if (hasPdfAccess && PDFViewerOpen)
        getSalesByZonePdf({
          dateFrom: from,
          dateTo: to,
          zones,
        })
          .then((response) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            setPdf(objectUrl);
            setIsFetchingPdf(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else setIsFetchingPdf(false);
    }
  };

  // Other
  const dateDurations = getDateDurations(intl);

  const zonesFiltersTags = [];
  zones.forEach((z) => {
    if (formData.zones)
      formData.zones.forEach((zoneId) => {
        if (z.id === zoneId) zonesFiltersTags.push(z.name);
      });
  });

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            component="span"
            variant="h6"
            className={classes.heading}
            style={{ fontSize: "1rem" }}
          >
            <LocalizedMessage id="report.reportFilters" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex" flexDirection={downSm ? "column" : "row"}>
            <Box
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <SelectField
                fullWidth
                neglectNone
                name="dateDuration"
                tooltip={"Date Duration"}
                helpKey="Menu.category.dateDuration"
                label={<LocalizedMessage id="report.dateDuration.title" />}
                onChange={onFormFieldChange}
                value={formData.dateDuration}
                errors={formErrors.dateDuration}
                isSubmitting={isFetchingRows || isFetchingPdf}
                isLoading={isLoading.dateDuration}
                options={dateDurations}
                defaultValue="today"
              />
              {formData.dateDuration === "custom" && (
                <>
                  <DatePicker
                    name="dateFrom"
                    tooltip={"Date from"}
                    helpKey="common.dateFrom"
                    label={intl.formatMessage({ id: "common.dateFrom" })}
                    onChange={onFormFieldChange}
                    value={formData.dateFrom}
                    errors={formErrors.dateFrom}
                    isSubmitting={isFetchingRows || isFetchingPdf}
                    isLoading={isLoading.dateFrom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                  />
                  <DatePicker
                    name="dateTo"
                    tooltip={"Date from"}
                    helpKey="common.dateTo"
                    label={intl.formatMessage({ id: "common.dateTo" })}
                    onChange={onFormFieldChange}
                    value={formData.dateTo}
                    errors={formErrors.dateTo}
                    isSubmitting={isFetchingRows || isFetchingPdf}
                    isLoading={isLoading.dateTo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                  />
                </>
              )}
            </Box>

            <Box
              style={{
                flex: 1,
                padding: downSm ? 0 : theme.spacing(0, 2),
                minWidth: 0,
              }}
            >
              <SelectField
                fullWidth
                name="zones"
                tooltip={"Zones"}
                helpKey="Menu.category.zones"
                label={<LocalizedMessage id="setup.zone.pageTitle" />}
                onChange={onFormFieldChange}
                value={formData.zones}
                errors={formErrors.zones}
                isSubmitting={isFetchingRows || isFetchingPdf}
                isLoading={isLoading.zones}
                options={zones.map((z) => ({
                  value: z.id,
                  text: z.name,
                }))}
                multiple
              />
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={isFetchingRows || isFetchingPdf}
              onClick={handleSearch}
              style={{ margin: "16px" }}
              startIcon={!isFetchingRows && !isFetchingPdf && <DoneIcon />}
            >
              {isFetchingRows || isFetchingPdf ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.apply" />
              )}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Memo deps={[pdf, rows.getState()]}>
        {() => (
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: 16,
              overflow: "auto",
            }}
          >
            <Chip
              style={{
                margin: 8,
                padding: 8,
                height: "100%",
                color: "white",
                backgroundColor: "#0582D2",
              }}
              label={
                <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                  <LocalizedMessage id="report.dateDuration.title" />
                  {": " +
                    dateDurations.filter(
                      (dd) => formData.dateDuration === dd.value
                    )[0]?.text}
                </Typography>
              }
            />
            {formData.dateTo && (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    {formData.dateTo === formData.dateFrom ? (
                      <>
                        <LocalizedMessage id="report.day" />
                        {": " +
                          moment(formData.dateFrom).format("MMM DD, YYYY")}
                      </>
                    ) : (
                      <>
                        <LocalizedMessage id="common.dateFrom" />
                        {": " +
                          moment(formData.dateFrom).format("MMM DD, YYYY")}
                        <LocalizedMessage id="common.dateTo" />
                        {": " + moment(formData.dateTo).format("MMM DD, YYYY")}
                      </>
                    )}
                  </Typography>
                }
              />
            )}
            {zonesFiltersTags.length ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="setup.zone.pageTitle" />
                    {": " + zonesFiltersTags.join(", ")}
                  </Typography>
                }
              />
            ) : null}
          </Toolbar>
        )}
      </Memo>
      {hasPdfAccess && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title={
              PDFViewerOpen ? (
                <LocalizedMessage id="report.showTable" />
              ) : (
                <LocalizedMessage id="report.showPDFViewer" />
              )
            }
          >
            {downSm ? (
              <Button
                style={{ flex: 1 }}
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => setPDFViewerOpen((prevState) => !prevState)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {PDFViewerOpen ? (
                  <>
                    <StorageIcon style={{ marginRight: 8 }} />
                    <LocalizedMessage id="report.showTable" />
                  </>
                ) : (
                  <>
                    <PictureAsPdfIcon style={{ marginRight: 8 }} />
                    <LocalizedMessage id="report.showPDFViewer" />
                  </>
                )}
              </Button>
            ) : (
              <IconButton
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => setPDFViewerOpen((prevState) => !prevState)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {PDFViewerOpen ? <StorageIcon /> : <PictureAsPdfIcon />}
                <Typography variant="body2" component="h2"></Typography>
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title={<LocalizedMessage id="report.downloadPDF" />}>
            {downSm ? (
              <Button
                style={{ flex: 1 }}
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => window.open(pdf)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                <GetAppIcon style={{ marginRight: 8 }} />
                <LocalizedMessage id="report.downloadPDF" />
              </Button>
            ) : (
              <IconButton
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => window.open(pdf)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {<GetAppIcon />}
                <Typography variant="body2" component="h2"></Typography>
              </IconButton>
            )}
          </Tooltip>
        </Box>
      )}

      {(isFetchingRows || isFetchingPdf) && inputSkeleton ? (
        inputSkeleton
      ) : PDFViewerOpen && pdf ? (
        <Box>
          <App url={pdf} />
        </Box>
      ) : (
        <Paper
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="dense simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {rows.getState().length ? (
                  <TableBody>
                    {rows.getState()
                      ? rows.getState().map((row, index) => (
                          <TableRow key={index}>
                            <TableCell />
                            {columns.map((column, index) => (
                              <TableCell
                                key={column.id + "-second"}
                                align={column.align}
                              >
                                {row[column.id]}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))
                      : null}
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={rows.getState().length + 1}
                    >
                      <TableCell />
                      {footerRow.getState()
                        ? footerRow.getState().map((row, index) => (
                            <TableCell
                              key={index + "-footer"}
                              style={{ fontWeight: "bold" }}
                            >
                              {row}
                            </TableCell>
                          ))
                        : null}
                    </TableRow>
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

SalesByZone.propTypes = {
  /**
   *
   */
};

SalesByZone.defaultProps = {
  /**
   *
   */
};

export default SalesByZone;
