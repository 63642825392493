// React
import React, { useRef } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import CloseIcon from "@material-ui/icons/Close";
import PrintIcon from "@material-ui/icons/Print";

// Globals

// Helpers
//import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import useSkeleton from "Helpers/useSkeleton";

// Components
import QRCode from "qrcode.react";

// Factories

// Screens

// Assets

// Third Parties
import ReactToPrint from "react-to-print";

// Services

// Styles

// Ad-Hoc Components

/**
 * @name QRDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
class QRDialog extends React.Component {
  render() {
    const { qrData, open, onCloseDialog, isGettingPdf } = this.props;

    // Theme & Style Hooks

    // Global State Hooks

    // State Hooks

    // Effect Hooks

    // Other Hooks

    // Event Handlers

    // Other
    // const aboveMd = useMediaQuery(theme.breakpoints.up("md"))
    //const downSm = useMediaQuery(theme.breakpoints.down("sm"))

    // Component Render

    return (
      <Dialog
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen
        //  style={{ direction: theme.direction }}
      >
        <DialogTitle id="alert-dialog-title">
          {<LocalizedMessage id="QrDialog.pageTitle" />}
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {qrData.tablesData && (
            <ComponentToPrint
              isGettingPdf={isGettingPdf}
              propsRef={(el) => (this.componentRef = el)}
              qrData={qrData}
            />
          )}
        </DialogContent>
        <DialogActions
          style={{
            padding: "22px",
          }}
        >
          <Button
            style={{
              backgroundColor: "#0582D2",
              color: "#fff",
              marginRight: "8px",
            }}
            variant="contained"
            autoFocus
            onClick={onCloseDialog}
            startIcon={<CloseIcon />}
          >
            <LocalizedMessage id="common.close" />
          </Button>
          {qrData.tablesData && (
            <ReactToPrint
              trigger={() => (
                <Button
                  color={"primary"}
                  disabled={!qrData.tablesData.length}
                  variant="contained"
                >
                  <LocalizedMessage id="common.xReport" />
                </Button>
              )}
              content={() => this.componentRef}
              bodyClass={"printElement1"}
            />
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

QRDialog.propTypes = {
  /**
   *
   */
};

QRDialog.defaultProps = {
  /**
   *
   */
};

export default QRDialog;

const useStyles = makeStyles((theme) => ({
  tabStyle: {
    displayPrint: "block",

    height: "100%",
    maxHeight: 1000,
  },
  qrCode: {
    [theme.breakpoints.down("sm")]: {
      size: 300,
      alignSelf: "center",
    },
    [theme.breakpoints.up("md")]: {
      size: 400,
    },
    margin: 5,
    alignSelf: "center",
  },
}));
const defaultProps = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  style: { width: "5rem", height: "5rem" },
};

const ComponentToPrint = ({ qrData, isGettingPdf, propsRef }) => {
  const theme = useTheme();
  const classes = useStyles();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [inputSkeleton, inputRef] = useSkeleton("rect");

  return (
    <Box className={classes.tabStyle} ref={inputRef}>
      {isGettingPdf && inputSkeleton ? (
        inputSkeleton
      ) : qrData.tablesData.length ? (
        <Box ref={propsRef}>
          {qrData.tablesData?.map((qr) => (
            <Box
              {...defaultProps}
              style={{
                height: "98%",
                borderColor: "primary",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pageBreakAfter: "always",
                margin: 10,
                //display: "none",
              }}
            >
              <Box
                style={{
                  margin: 10,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    margin: 20,
                  }}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant={downSm ? "h4" : "h3"}
                    component="h2"
                    style={{ margin: "inherit", textAlign: "center" }}
                  >
                    <LocalizedMessage id="onlineOrders.welcomeTo" />{" "}
                    {qrData.branch.name}
                  </Typography>

                  <QRCode
                    className={classes.qrCode}
                    value={qr.tableLink}
                    size={downSm ? 250 : 400}
                  />
                  <Divider variant="middle" />
                </Box>
                <Typography
                  variant="h5"
                  component="h2"
                  style={{ marginTop: "5" }}
                >
                  Table Number: {qr.number}
                </Typography>
                <Typography
                  variant={downSm ? "h6" : "h4"}
                  component="h2"
                  style={{ textAlign: "center" }}
                >
                  <LocalizedMessage id="onlineOrders.scan" />
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography variant={"subtitle2"} component="h6">
          <LocalizedMessage id="onlineOrders.noTables" />
        </Typography>
      )}
    </Box>
  );
};
