// React
import React, { useState, useEffect } from "react";

// Material
import { Box, Paper, AppBar, Tabs, Tab, useTheme } from "@material-ui/core";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import EventSeatIcon from "@material-ui/icons/EventSeat";
import AssessmentIcon from "@material-ui/icons/Assessment";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import ShowChartIcon from "@material-ui/icons/ShowChart";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
// Components
import Summary from "./Summary";
import Tables from "./Tables";
import Delivery from "./Delivery";
import { Sales } from "Screens/Portal/Sales";

// Factories

// Screens

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views";

// Services

// Styles

// Ad-Hoc Components

/**
 * @name Live
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Live = () => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [value, setValue] = useState(0);

  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <LiveTvIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage id="live.pageTitle" key="live.pageTitle" />
        </Box>
      ),
    []
  );

  // Other Hooks

  // Other Hooks

  // Event Handlers
  const handleChange = (ev, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // Other
  const tabs = [
    {
      label: <LocalizedMessage id="rolesNames.actions.summary" />,
      permissionName: "live",
      icon: <AssessmentIcon />,
      node: <Summary />,
    },
    {
      label: <LocalizedMessage id="setup.table.pageTitle" />,
      permissionName: "live",
      icon: <EventSeatIcon />,
      node: <Tables />,
    },
    {
      label: <LocalizedMessage id="rolesNames.actions.delivery" />,
      permissionName: "live",
      icon: <MotorcycleIcon />,
      node: <Delivery />,
    },
    {
      label: <LocalizedMessage id="rolesNames.order" />,
      permissionName: "live",
      icon: <ShowChartIcon />,
      node: <Sales live={true} />,
    },
  ];

  // Component Render
  return (
    <div>
      <Paper style={{ position: "static" }}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            aria-label="action tabs example"
            variant="scrollable"
            scrollButtons="on"
          >
            {tabs.map((e, i) => (
              <Tab key={`${i} ${e.label}`} label={e.label} icon={e.icon} />
            ))}
          </Tabs>
        </AppBar>
      </Paper>

      <SwipeableViews
        axis={Theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        {tabs.map((tab, index) => (
          <Paper
            key={tab.permissionName}
            dir={Theme.direction}
            style={{ padding: tab.isTabbed ? 0 : Theme.spacing(2) }}
            elevation={2}
            square
          >
            {tab.node}
          </Paper>
        ))}
      </SwipeableViews>
    </div>
  );
};

Live.propTypes = {
  /**
   *
   */
};

Live.defaultProps = {
  /**
   *
   */
};

export default Live;
