// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  useMediaQuery,
  useTheme,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Grid,
  CircularProgress,
  Box,
  Divider,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import {
  TextField,
  ChipField,
  MapField,
  AutoCompleteField,
} from "Components/FormFields";
import FormActions from "Components/FormActions";
// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useIntl } from "react-intl";

// Services

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  currencyId: Joi.string().required(),
  countryId: Joi.string().required(),
  city: Joi.string().required().min(1).max(100),
  cityOtherName: Joi.string().allow("").allow(null),
  address: Joi.string().required(),
  addressOtherName: Joi.string().allow("").allow(null),
  commercialRegistrationNumber: Joi.when("countryId", {
    is: "SA",
    then: Joi.string().required(),
    otherwise: Joi.string().allow("").allow(null),
  }),
  vatRegistrationNumber: Joi.when("countryId", {
    is: "SA",
    then: Joi.string()
      .length(15)
      .regex(/^[0-9]+$/)
      .required(),
    otherwise: Joi.string().allow("").allow(null),
  }),
  timezone: Joi.string().required(),
  phoneNumbers: Joi.array().min(1).required().items(Joi.number()),
  location: Joi.object({
    lng: Joi.number().min(-180).max(180),
    lat: Joi.number().min(-90).max(90),
  }),
});

/**
 * @name BranchForm
 * @summary
 * @Branch
 * @component
 * @description
 * >
 */
const BranchForm = ({
  editFormData,
  onAdd,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
  full,
  keyy,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [currencies] = useGlobalState("currencies.currencies");
  const [countries] = useGlobalState("countries.countries");
  const [timezones] = useGlobalState("timezones.timezones");

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false);

  // Other Hooks
  const intl = useIntl();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
  } = useForm({ validationSchema, languageKey: "branch" });

  // Effect Hooks
  useEffect(() => {
    if (
      !currencies.isFetchingRows &&
      !countries.isFetchingRows &&
      !timezones.isFetchingRows
    )
      setIsScreenLoaded(true);
  }, [
    currencies.isFetchingRows,
    countries.isFetchingRows,
    timezones.isFetchingRows,
  ]);

  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const phoneNumbers =
        typeof editFormData.phoneNumbers === "string"
          ? editFormData.phoneNumbers.split(",")
          : editFormData.phoneNumbers;

      const location = editFormData.location
        ? editFormData.location
        : { lat: editFormData.latitude, lng: editFormData.longitude };

      editFormData = {
        ...editFormData,
        currencyId: editFormData.currency.id,
        countryId: editFormData.country.id,
        phoneNumbers,
        location,
      };

      setFormData(
        _.pick(editFormData, [
          "name",
          "otherName",
          "currencyId",
          "countryId",
          "city",
          "cityOtherName",
          "address",
          "addressOtherName",
          "commercialRegistrationNumber",
          "vatRegistrationNumber",
          "timezone",
          "phoneNumbers",
          "location",
        ])
      );
    } else setFormData({});
  }, [open]);

  // Event Handlers
  const onCLoseBranchForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddBranch = () => {
    onAdd(
      keyy,
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseBranchForm
    );
  };

  const onSaveBranch = () => {
    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseBranchForm
    );
  };

  // Other
  console.log(
    `%cBranchForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseBranchForm}
      scroll={"paper"}
      fullScreen={full}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="branch.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />
              <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>

        <form className={classes.form} noValidate>
          <Box
            display="flex"
            flexDirection={downSm ? "column" : "row"}
            justifyContent="space-around"
          >
            {downSm && (
              <Box style={{ flex: 1, marginRight: 20, marginTop: 15 }}>
                <MapField
                  fullWidth
                  label={<LocalizedMessage id="branch.location" />}
                  name="location"
                  tooltip={"Branch Location"}
                  helpKey="Branch.NameField"
                  onChange={onFormFieldChange}
                  value={formData.location}
                  errors={formErrors.location}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />
              </Box>
            )}
            <Box style={{ flex: 1, marginInlineEnd: 20, marginTop: 15 }}>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Branch Name"}
                helpKey="Menu.Branch.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <TextField
                label={intl.formatMessage({ id: "common.otherName" })}
                name="otherName"
                autoComplete="otherName"
                tooltip={"Branch otherName"}
                helpKey="Menu.Branch.otherNameField"
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <AutoCompleteField
                fullWidth
                required
                label={<LocalizedMessage id="common.countryId" />}
                name="countryId"
                tooltip={"Branch Country"}
                helpKey="Menu.Branch.NameField"
                onChange={onFormFieldChange}
                value={formData.countryId}
                errors={formErrors.countryId}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                options={countries.data.map((country) => {
                  return { value: country.id, text: country.name };
                })}
              />

              <TextField
                fullWidth
                label={
                  <LocalizedMessage id="common.commercialRegistrationNumber" />
                }
                name="commercialRegistrationNumber"
                tooltip={"Branch commercialRegistrationNumber"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.commercialRegistrationNumber}
                errors={formErrors.commercialRegistrationNumber}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="common.vatRegistrationNumber" />}
                name="vatRegistrationNumber"
                tooltip={"Branch vatRegistrationNumber"}
                helpKey="Menu.Branch.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.vatRegistrationNumber}
                errors={formErrors.vatRegistrationNumber}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
              />
              <AutoCompleteField
                fullWidth
                required
                label={<LocalizedMessage id="common.timezone" />}
                name="timezone"
                tooltip={"Branch Time Zone"}
                helpKey="Menu.Branch.NameField"
                onChange={onFormFieldChange}
                value={formData.timezone}
                errors={formErrors.timezone}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                options={timezones.data.map((timezone) => {
                  return { value: timezone.name, text: timezone.name };
                })}
              />

              <AutoCompleteField
                required
                fullWidth
                name="currencyId"
                tooltip={"Currency"}
                helpKey="common.currencyId"
                label={<LocalizedMessage id="common.currencyId" />}
                onChange={onFormFieldChange}
                value={formData.currencyId}
                errors={formErrors.currencyId}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                options={currencies.data.map((currency) => {
                  return { value: currency.id, text: currency.name };
                })}
              />
              <ChipField
                required
                fullWidth
                label={<LocalizedMessage id="common.phoneNumbers" />}
                name="phoneNumbers"
                tooltip={"Branch Phone Numbers"}
                helpKey="common.phoneNumbers"
                onChange={onFormFieldChange}
                value={formData.phoneNumbers}
                errors={formErrors.phoneNumbers}
                helperText={<LocalizedMessage id="branch.phoneNumbersHelp" />}
                isLoading={!isScreenLoaded}
                isSubmitting={isSubmitting}
              />
            </Box>
            <>
              <Divider orientation="vertical" flexItem />
              <Box style={{ flex: 1, marginInlineStart: 20, marginTop: 15 }}>
                <TextField
                  required
                  fullWidth
                  label={<LocalizedMessage id="common.city" />}
                  name="city"
                  tooltip={"Branch City"}
                  helpKey="Menu.Branch.NameField"
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.city}
                  errors={formErrors.city}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />
                <TextField
                  fullWidth
                  label={<LocalizedMessage id="common.cityOtherName" />}
                  name="cityOtherName"
                  tooltip={"Branch cityOtherName"}
                  helpKey="Menu.Branch.NameField"
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.cityOtherName}
                  errors={formErrors.cityOtherName}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />
                <TextField
                  required
                  fullWidth
                  label={<LocalizedMessage id="common.address" />}
                  name="address"
                  tooltip={"Branch address"}
                  helpKey="Menu.Branch.NameField"
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.address}
                  errors={formErrors.address}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />
                <TextField
                  fullWidth
                  label={<LocalizedMessage id="common.addressOtherName" />}
                  name="addressOtherName"
                  tooltip={"Branch addressOtherName"}
                  helpKey="Menu.Branch.NameField"
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.addressOtherName}
                  errors={formErrors.addressOtherName}
                  isSubmitting={isSubmitting}
                  isLoading={!isScreenLoaded}
                />

                {!downSm && (
                  <MapField
                    fullWidth
                    label={<LocalizedMessage id="branch.location" />}
                    name="location"
                    tooltip={"Branch Location"}
                    helpKey="Branch.NameField"
                    onChange={onFormFieldChange}
                    value={formData.location}
                    errors={formErrors.location}
                    isSubmitting={isSubmitting}
                    isLoading={!isScreenLoaded}
                  />
                )}
              </Box>
            </>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddBranch}
          mode={mode}
          onCLoseForm={onCLoseBranchForm}
          isSubmitting={isSubmitting}
          onSave={onSaveBranch}
        />
      </DialogActions>
    </Dialog>
  );
};

BranchForm.propTypes = {
  /**
   *
   */
};

BranchForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default BranchForm;
