// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { useTheme } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import blueGrey from "@material-ui/core/colors/blueGrey";

// Globals

// Helpers
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components

// Factories

// Screens
import PieChartCard from "./PieChartCard";

// Servicies
import { useGetSalesByChannel } from "Services/Dashboard";
// Assets

// Third Parties
import moment from "moment";
import { useSnackbar } from "notistack";
import randomcolor from "randomcolor";
import { useIntl } from "react-intl";

// Styles

// Ad-Hoc Components

/**
 * @name SalesBySectionCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesBySectionCard = ({ style, dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [salesByChannelData, setSalesByChannelData] = useState([]);
  const [empty, setEmpty] = useState(false);

  // Effect Hooks
  useEffect(() => {
    setEmpty(false);
    getSalesByChannel(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    )
      .then((response) => {
        setSalesByChannelData(response);

        var empty = true;
        response.forEach((emp) => {
          if (emp.income) empty = false;
        });
        if (empty) setEmpty(true);
      })
      .catch((errors) => {
        setEmpty(true);
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [dateFrom, dateTo]);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getSalesByChannel = useGetSalesByChannel();
  const intl = useIntl();

  // Event Handlers

  // Other
  const chartData = (theme) => ({
    labels: salesByChannelData.reduce((acc, currentValue) => {
      if (currentValue.income !== 0){
        return [...acc, `${currentValue.salessection}: ${currentValue.income}`]
      }
      else return acc
    }, []),
    datasets: [
      {
        label: intl.formatMessage({ id: "common.income" }),
        backgroundColor: Array(salesByChannelData.length)
          .fill(1)
          .map((v, key) => {
            return randomcolor({ hue: "#305BA9" });
          }),
        data: salesByChannelData.reduce((acc, currentValue) => {
          if (currentValue.income !== 0){
            return [...acc, currentValue.income]
          }
          else return acc
        }, []),
      },
    ],
  });

  const dummyData = (theme) => ({
    labels: ["Takeaway", "Dine In", "Delivery", "Carryout"],
    datasets: [
      {
        label: "Income",
        backgroundColor: Array(4)
          .fill(1)
          .map((v, key) => {
            return randomcolor({
              hue: "#305BA9",
            });
          }),
        data: Array(4)
          .fill(4)
          .map((v, key) => {
            return Math.ceil(Math.random() * (key + 1) * 1000);
          }),
      },
    ],
  });

  
  // Component Render
  return (
    <>
      {empty ? (
        <PieChartCard
          blurred={true}
          title={intl.formatMessage({ id: "dashboard.salesByChannel" })}
          pieChartData={dummyData(Theme)}
        />
      ) : (
        <PieChartCard
          title={intl.formatMessage({ id: "dashboard.salesByChannel" })}
          pieChartData={chartData(Theme)}
        />
      )}
    </>
  );
};

SalesBySectionCard.propTypes = {
  /**
   *
   */
};

export default SalesBySectionCard;
