// React
import React from "react";
import PropTypes from "prop-types";

// Third Party
import { IntlProvider } from "react-intl";

// Translations
import Translations from "Translations";

// Helpers
import { useGlobalState } from "Helpers/GlobalState";

/**
 * @name LocalizationProvider
 * @component
 * @description
 * > Returns The Localization Provider Component To Be Used For Wrapping The App
 * @category Helpers
 */
const LocalizationProvider = props => {
  const [locale] = useGlobalState("global.locale");

  return (
    <IntlProvider locale={locale} messages={Translations[locale]}>
      {props.children}
    </IntlProvider>
  );
};

LocalizationProvider.propTypes = {
  /**
   * Children Component To Be Wrapped
   */
  children: PropTypes.node
};

export default LocalizationProvider;
