// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  InputAdornment,
  IconButton,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import {
  Assignment as AssignmentIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";

// Components
import { TextField } from "Components/FormFields";

// Factories

// Screens

// Assets

// Help

// Third Parties
import zxcvbn from "zxcvbn";

// Styles
const useStyles = makeStyles((theme) => ({}));

// Ad-Hoc Components

/**
 * @name PasswordField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const PasswordField = ({
  showStrength,
  preHelperComponents,
  InputProps,
  value,
  name,
  ...otherProps
}) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [showPassword, setShowPassword] = useState(false);

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log(
      `%cPassword Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      ""
    );
  }, []);

  // Other Hooks

  // Event Handlers
  const onClickShowPassword = () => {
    setShowPassword((sp) => !sp);
  };

  const onMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // Other
  const getPasswordStrengthProgress = () => {
    const password = value || "";
    const score = zxcvbn(password).score;
    const min = 0;
    const max = 4;
    return ((score - min) * 100) / (max - min);
  };

  const getPasswordStrengthText = () => {
    const password = value || "";
    const score = zxcvbn(password).score;
    const cases = {
      0: "Very Weak",
      1: "Weak",
      2: "Normal",
      3: "Strong",
      4: "Very Strong",
    };
    return password ? cases[score] : "";
  };

  console.log(
    `%cPassword Field %cRerender -> %cName: %c${name} %cCurrentValue: %c${value}`,
    "font-weight: bold; color: blue",
    "",
    "font-weight: bold;",
    "",
    "font-weight: bold;",
    ""
  );

  // Component Render
  return (
    <TextField
      {...otherProps}
      name={name}
      value={value}
      type={showPassword ? "text" : "password"}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={onClickShowPassword}
              onMouseDown={onMouseDownPassword}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      preHelperComponents={[
        showStrength && (
          <Box
            key={0}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <LinearProgress
              variant="determinate"
              value={getPasswordStrengthProgress()}
              style={{ width: "60%" }}
            />
            <Typography style={{ marginLeft: "auto" }}>
              {getPasswordStrengthText()}
            </Typography>
          </Box>
        ),
        preHelperComponents,
      ]}
    />
  );
};

PasswordField.propTypes = {
  /**
   *
   */
};

PasswordField.defaultProps = {
  showStrength: false,
};

export default PasswordField;
