// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box } from "@material-ui/core";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties
import OnImagesLoaded from "react-on-images-loaded";

// Styles

// Ad-Hoc Components

/**
 * @name ImagePreloader
 * @summary ImagePreloader Component
 * @category
 * @component
 * @description
 * >
 */
const ImagePreloader = (props) => {
  // Props Destructing
  const { onLoaded, onTimeout, timeout, images, ...otherProps } = props;

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <OnImagesLoaded
      onLoaded={onLoaded}
      onTimeout={onTimeout}
      timeout={timeout}
      {...otherProps}
    >
      <Box style={{ height: "0px", width: "0px", overflow: "hidden" }}>
        {images.map((image, key) => (
          <img src={image} key={key} />
        ))}
      </Box>
    </OnImagesLoaded>
  );
};

ImagePreloader.propTypes = {
  /**
   *
   */
};

ImagePreloader.defaultProps = {
  onLoaded: () => {},
  onTimeout: () => {},
  timeout: 7000,
};

export default ImagePreloader;
