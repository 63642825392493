// React
import React, { useState } from "react";

// Material
import { Box, Grid, Slide, Fade } from "@material-ui/core";

// Local
import { makeStyles } from "Helpers/Styles";
import { OnionLogoWithText } from "Assets/Images";
import { SignInForm, SignInNav } from "Screens/Auth/SignIn";
import { RegisterForm, RegisterFormNav } from "Screens/Auth/Register";
import { ForgetPasswordForm } from "Screens/Auth/ForgetPassword";
import { MailConfirmation } from "Screens/Auth/MailConfirmation";
import { useHistory } from "react-router-dom";
import { useGlobalState } from "Helpers/GlobalState";

import Footer from "./Footer";
import Slider from "./Slider";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    direction: "ltr",
    flip: false,
  },

  sliderGridItem: {
    position: "relative",
    overflow: "hidden",

    "& $sliderDiagonalOverlay": {
      position: "absolute",
      height: "100%",
      width: "100%",
      background: theme.palette.background.paper,
      clipPath: "polygon(5% 0, 100% 0, 100% 100%, 0 100%)",
      top: 0,
      left: "95%",
      zIndex: 1,
      padding: 0,
      margin: 0,
      boxShadow: theme.shadows[20],
      direction: "ltr",
      flip: false,
    },
  },

  authGridItem: {
    zIndex: 2,

    "& $authWrapper": {
      padding: theme.spacing(2, 4, 1, 2),
      display: "flex",
      flexDirection: "column",
      direction: "ltr",
      background: theme.palette.background.paper,
      alignItems: "flex-start",
      minHeight: "100%",
      overflow: "hidden",
    },
  },

  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "50%",
    maxHeight: "100px",
    marginBottom: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(4, 4, 1, 4),
    display: "flex",
    flexDirection: "column",
    direction: "ltr",
    background: theme.palette.background.paper,
  },

  footer: {
    flexShrink: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: "100%",
  },
}));

/**
 * @name Auth
 * @summary Auth Wrapper Component
 * @category Screens / Auth
 * @component
 * @see SignIn
 * @see Slider
 * @see ForgetPassword
 * @see Footer
 * @description
 * > Wrap auth Slider, SignIn, ForgetPassword and Footer components<br />
 * > Provide the transition animation between SignIn and ForgetPassword components
 */
const Auth = () => {
  const classes = useStyles();
  const history = useHistory();
  const [currentComponent, setCurrentComponent] = useGlobalState("components.currentComponent");

  const [isSignInAnimationDone, setisSignInAnimationDone] = useState(true);
  const [isForgetPasswordAnimationDone, setisForgetPasswordAnimationDone] = useState(true);
  const [isRegisterAnimationDone, setisRegisterAnimationDone] = useState(true);
  const [isMailConfirmationAnimationnDone, setIsMailConfirmationAnimationnDone] = useState(true);
  console.log("currentComponent", currentComponent);
  return (
    <Grid container component="main" className={classes.root}>
      <Grid className={classes.sliderGridItem} item xs={true} sm={4} md={7}>
        <Box className={classes.sliderDiagonalOverlay} display={{ xs: "none", sm: "block" }}></Box>
        <Slider />
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Box} className={classes.authGridItem}>
        <Box className={classes.authWrapper}>
          <Box className={classes.logoContainer} alignSelf="center" flexShrink={0}>
            <img src={OnionLogoWithText} alt="Onion Logo" className={classes.logo} />
          </Box>
          <Box width={"100%"} flexGrow={1}>
            <Box display="block" width="100%" position="relative" left={0} top={0}>
              <Slide
                direction="right"
                in={
                  currentComponent === "SignIn" &&
                  isForgetPasswordAnimationDone &&
                  isRegisterAnimationDone
                }
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 200 }}
                onEntered={() => {
                  setisSignInAnimationDone(true);
                }}
                onExited={() => {
                  setisSignInAnimationDone(true);
                }}
              >
                <Box>
                  <SignInForm
                    goToRegisterHandler={() => {
                      setisSignInAnimationDone(false);
                      setCurrentComponent("Register");
                    }}
                  >
                    <SignInNav
                      goToForgetPasswordHandler={() => {
                        setisSignInAnimationDone(false);
                        setCurrentComponent("ForgetPassword");
                      }}
                    />
                  </SignInForm>
                </Box>
              </Slide>
              <Slide
                direction="right"
                in={currentComponent === "Register" && isSignInAnimationDone}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 200 }}
                onEntered={() => {
                  setisRegisterAnimationDone(true);
                }}
                onExited={() => {
                  setisRegisterAnimationDone(true);
                }}
              >
                <Box>
                  <RegisterForm
                    isRegisterAnimationDone={isRegisterAnimationDone}
                    goToSignInHandler={() => {
                      setisRegisterAnimationDone(false);
                      setCurrentComponent("SignIn");
                    }}
                  >
                    <RegisterFormNav
                      goToSignInHandler={() => {
                        setisRegisterAnimationDone(false);
                        setCurrentComponent("SignIn");
                      }}
                    />
                  </RegisterForm>
                </Box>
              </Slide>
              <Fade
                in={currentComponent === "ForgetPassword" && isSignInAnimationDone}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 200 }}
                onEntered={() => {
                  setisForgetPasswordAnimationDone(true);
                }}
                onExited={() => {
                  setisForgetPasswordAnimationDone(true);
                }}
              >
                <Box>
                  <ForgetPasswordForm
                    isForgetPasswordAnimationDone={isForgetPasswordAnimationDone}
                    backToLoginHandler={() => {
                      setisForgetPasswordAnimationDone(false);
                      setCurrentComponent("SignIn");
                    }}
                  ></ForgetPasswordForm>
                </Box>
              </Fade>
              <Fade
                in={currentComponent === "MailConfirmation"}
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 300 }}
                onEntered={() => {
                  setIsMailConfirmationAnimationnDone(false);
                }}
                onExited={() => {
                  setIsMailConfirmationAnimationnDone(true);
                }}
              >
                <Box>
                  <MailConfirmation
                    goBack={() => {
                      // setisForgetPasswordAnimationDone(false);
                      setCurrentComponent("SignIn");
                    }}
                  />
                </Box>
              </Fade>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Auth;
