import React from "react"
import { LocalizedMessage } from "Helpers/Localization"

/**
 * @name displaySnackbarErrors
 * @summary
 * @category Utils
 * @description
 * >
 */

const displaySnackbarErrors = (errors, enqueueSnackbar) => {
  console.log("errors", errors)
  errors.forEach((e) => {
    enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
      variant: "error",
      autoHideDuration: 3000,
    })
  })
}

const displaySnackbarFormErrors = (errors, enqueueSnackbar, setFormErrors) => {
  console.log("errors", errors)
  errors.forEach((e) => {
    if (e.field) {
      setFormErrors((fe) => ({
        ...fe,
        [e.field]: [
          ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
          <LocalizedMessage id={e.languageKey} />,
        ],
      }))
    } else {
      enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
        variant: "error",
        autoHideDuration: 3000,
      })
    }
  })
}

const displayenqueueSnackbar = (enqueueSnackbar, action) => {
  enqueueSnackbar(<LocalizedMessage id={`general.${action}.success`} />, {
    variant: "success",
    autoHideDuration: 5000,
  })
}
export {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
}
export default {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
}
