// React
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import { useReactToPrint } from "react-to-print";

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    flexDirection: "column",
  },
}));

const BorderLinearProgress = withStyles(
  (theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: "#1a90ff",
    },
  }),
  { index: 1 }
)(LinearProgress);
// Ad-Hoc Components

/**
 * @name ProgressDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ProgressDialog = ({ file, imported, open, onCloseDialog, failed }) => {
  // Theme & Style Hooks
  const theme = useTheme();
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [progress, setProgress] = useState(0);
  // Effect Hooks

  React.useEffect(() => {
    if (!imported && file) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 70
            ? prevProgress < 95
              ? prevProgress + 2
              : 96
            : prevProgress + 5
        );
      }, 800);

      return () => {
        clearInterval(timer);
        setProgress(0);
      };
    }
  }, [imported, file]);

  // Other Hooks
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Event Handlers

  // Other
  const aboveMd = useMediaQuery(theme.breakpoints.up("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={false}
      fullWidth
      maxWidth="md"
      style={{
        direction: theme.direction,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {<LocalizedMessage id="category.importMenu" />}
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent className={classes.root}>
        <Typography variant="h5" component="span">
          {file?.name}
        </Typography>
        {!imported && !failed ? (
          <BorderLinearProgress variant="determinate" value={progress} />
        ) : // <Pace color="#27ae60" style={{ margin: 40, position: "relative" }} />
        failed ? (
          <Typography
            variant="h5"
            align="center"
            component="span"
            style={{ margin: 20, color: "red" }}
          >
            {" "}
            <LocalizedMessage id="general.imported.failed" />
          </Typography>
        ) : (
          <Typography
            variant="h5"
            align="center"
            component="span"
            style={{ margin: 20, color: "green" }}
          >
            {" "}
            <LocalizedMessage id="general.imported.success" />
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <FormActions onCLoseForm={onCloseDialog} isSubmitting={false} isClose />

        {/* <Button
          onClick={onCloseDialog}
          style={{ marginTop: 20, color: "#0582D2" }}
        >
          <LocalizedMessage id="common.close" />
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

ProgressDialog.propTypes = {
  /**
   *
   */
};

ProgressDialog.defaultProps = {
  /**
   *
   */
};

export default ProgressDialog;
