// React
import React, { useEffect, useRef, useState } from "react";

// Material

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name useInterval
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const useInterval = (callback, delay, stopAfter) => {
  const savedCallback = useRef();
  const [startTime, setStartTime] = useState(new Date().getTime());
  const [intervalId, setIntervalId] = useState(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (stopAfter && new Date().getTime() - startTime > stopAfter) clearInterval(intervalId);
      else savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      setIntervalId(id);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export default useInterval;
