// React
import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components
import { TextField, CheckBox } from "Components/FormFields";
import PermissionsCheckBoxesTree from "Components/PermissionsCheckBoxesTree";
import FormActions from "Components/FormActions";
// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";

// Services
import { useAddPermission, useEditPermission } from "Services/Permissions/";
import { useGetPermissionsMatrix } from "Services/Permissions/";
import { handler } from "Helpers/Handlers";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    height: "auto",
    flexGrow: 1,
  },
}));
// Ad-Hoc Components
let action = Joi.object().pattern(Joi.string(), Joi.alternatives(Joi.boolean(), Joi.object()));

let permissionsMatrix = Joi.object().pattern(Joi.string(), action);

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  isAdmin: Joi.boolean(),
  permissionsMatrix: Joi.object().when("isAdmin", {
    is: false,
    then: permissionsMatrix.required().min(1),
  }),
});
// Ad-Hoc Components

/**
 * @name PermissionForm
 * @summary
 * @Permission
 * @component
 * @description
 * >
 */
const PermissionForm = ({
  editFormData,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
  addCallback,
  editCallback,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissionsGroups, setPermissionsGroups] = useGlobalState(
    "permissionsGroups.permissionsGroups"
  );

  // State Hooks
  const [permissionsMatrix, setPermissionsMatrix] = useGlobalState(
    "permissionsMatrix.permissionsMatrix"
  );

  // Effect Hooks
  useEffect(() => {
    getAllPermissionsMatrix()
      .then((res) => {
        setPermissionsMatrix({ data: res, isFetchingRows: false });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      setFormData({
        name: editFormData.name,
        isAdmin: editFormData.isAdmin,
        permissionsMatrix: editFormData.isAdmin
          ? permissionsMatrix.data
          : editFormData.permissionsMatrix,
      });
    } else {
      setFormData({ permissionsMatrix: permissionsMatrix.data });
    }
  }, [open, permissionsMatrix.data]);

  // Other Hooks
  const addPermission = useAddPermission({ languageKey: "setup.permission" });
  const editPermission = useEditPermission({ languageKey: "setup.permission" });
  const getAllPermissionsMatrix = useGetPermissionsMatrix();
  const { enqueueSnackbar } = useSnackbar();

  const permissionForm = useForm({
    validationSchema,
    languageKey: "setup.permission",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = permissionForm;

  // Event Handlers
  const onCLosePermissionForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddPermission = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: permissionForm,
      formName: "permission",
      apiHandler: addPermission,
      onClose: onCLosePermissionForm,
    });
  };

  const onSavePermission = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: permissionForm,
      formName: "permission",
      apiHandler: editPermission,
      onClose: onCLosePermissionForm,
    });
  };

  // Other
  console.log(
    `%cPermissionForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLosePermissionForm}
      scroll={"paper"}
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="setup.permission.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Permission name"}
                helpKey="permission.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <CheckBox
                fullWidth
                name="isAdmin"
                tooltip={"Admin access?"}
                helpKey="common.isAdmin"
                label={<LocalizedMessage id="common.isAdmin" />}
                onChange={onFormFieldChange}
                value={formData.isAdmin}
                errors={formErrors.isAdmin}
                isSubmitting={isSubmitting}
                isLoading={isLoading.isAdmin}
              />
              {!formData.isAdmin && (
                // <RolesCheckBoxes
                //   required
                //   label={
                //     <LocalizedMessage id="setup.permission.permissionsMatrix" />
                //   }
                //   name="permissionsMatrix"
                //   autoComplete="permissionsMatrix"
                //   tooltip={"permission permissionsMatrix"}
                //   helpKey="permission.CheckboxField"
                //   autoFocus
                //   fullWidth
                //   // maxCharacters={50}
                //   onChange={onFormFieldChange}
                //   value={formData.permissionsMatrix}
                //   errors={formErrors.permissionsMatrix}
                //   isSubmitting={isSubmitting}
                //   isLoading={isLoading.permissionsMatrix}
                //   permissionsMatrix={permissionsMatrix}
                // />
                <PermissionsCheckBoxesTree
                  required
                  label={<LocalizedMessage id="setup.permission.permissionsMatrix" />}
                  name="permissionsMatrix"
                  autoComplete="permissionsMatrix"
                  tooltip={"permission permissionsMatrix"}
                  helpKey="permission.CheckboxField"
                  autoFocus
                  fullWidth
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.permissionsMatrix}
                  errors={formErrors.permissionsMatrix}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.permissionsMatrix}
                  permissionsMatrix={permissionsMatrix}
                />
              )}
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddPermission}
          mode={mode}
          onCLoseForm={onCLosePermissionForm}
          isSubmitting={isSubmitting}
          onSave={onSavePermission}
        />
      </DialogActions>
    </Dialog>
  );
};

PermissionForm.propTypes = {
  /**
   *
   */
};

PermissionForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default PermissionForm;
