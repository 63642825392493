// React
import React, { useState } from "react"

// Material

// Globals

// Helpers
import useStateRef from "Helpers/useStateRef"
import { LocalizedMessage } from "Helpers/Localization"

// Components

// Factories

// Screens

// Assets

// Third Parties

// Services

// Styles

// Ad-Hoc Components

/**
 * @name useForm
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const useForm = ({ validationSchema, languageKey }) => {
  const [formData, setFormData] = useStateRef({})
  const [formErrors, setFormErrors] = useState({})
  const [isSubmitting, setIsSubmiting] = useState(false)
  const [isFieldLoading, setIsFieldLoading] = useState({})

  const setIsLoading = (field, loading) => {
    setIsFieldLoading((l) => ({ ...l, [field]: loading }))
  }

  const onFormFieldChange = (name, value) => {
    console.log("[useForm] Field Change", name, value)
    setFormData((d) => {
      const fd = { ...d, [name]: value }
      setFormErrors((fde) => {
        return { ...fde, [name]: undefined }
      })
      return fd
    })
  }

  const validateForm = () => {
    let valuesToValidate = {}
    let formDataState = formData.getState()

    Object.keys(formDataState).forEach((key, i) => {
      valuesToValidate[key] =
        formDataState[key] instanceof File
          ? {
              name: formDataState[key].name,
              type: formDataState[key].type,
              size: formDataState[key].size,
            }
          : formDataState[key]
    })
    const validationResult = validationSchema.validate(valuesToValidate, {
      abortEarly: false,
    })
    if (validationResult.error) {
      setFormErrors((fde) => {
        let errors = {}
        console.log("validationResult", validationResult)
        validationResult.error.details.forEach((d) => {
          const { context, type, path, message } = d
          if (type === "object.with") {
            errors = {
              ...errors,
              [context.peer]: [
                path.length > 1 ? (
                  <LocalizedMessage
                    id={`validation.${path[0]}.${type}`}
                  />
                ) : (
                  <LocalizedMessage
                    id={`validation.${context.peer}.${type}`}
                  />
                ),
              ],
            }
          } else {
            errors = {
              ...errors,
              [path[0]]: [
                path.length > 1 ? (
                  <LocalizedMessage
                    id={`validation.${path[0]}.${type}`}
                  />
                ) : (
                  <LocalizedMessage
                    id={`validation.${context.label}.${type}`}
                  />
                ),
              ],
            }
          }
        })
        return errors
      })
      return false
    }

    return true
  }

  return {
    formData: formData.getState(),
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading: isFieldLoading,
    setIsLoading,
  }
}

export default useForm
