// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box, Typography, Link } from "@material-ui/core";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Copyrights
 * @summary Copyrights Component
 * @category Components
 * @component
 * @description
 * > Renders app copyrights
 * @requires React
 * @requires Box
 * @requires Typography
 * @requires Link
 * @requires LocalizedMessage
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @see {@link https://material-ui.com/components/box/|Material UI Box}
 * @see {@link https://material-ui.com/components/typography/|Material UI Typography}
 * @see {@link https://material-ui.com/components/links/|Material UI Link}
 * @see {@link LocalizedMessage|Helpers / Localization / LocalizedMessage}
 */
const Copyrights = (props) => {
  // Props Destructing

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box {...props}>
      <Typography variant="body2" color="textSecondary">
        <LocalizedMessage
          id="auth.signIn.copyrights"
          values={{
            Link: (...chunks) => <Link href="https://www.posonion.com/">{chunks}</Link>,
            year: new Date().getFullYear(),
          }}
        >
          {(...chunks) =>
            chunks.map((chunk, i) => <React.Fragment key={i}>{chunk}</React.Fragment>)
          }
        </LocalizedMessage>
      </Typography>
    </Box>
  );
};

Copyrights.propTypes = {
  className: PropTypes.string,
};

export default Copyrights;
