// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  useTheme,
} from "@material-ui/core";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useForm from "Helpers/Hooks/useForm";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import { TextField, SelectField, AutoCompleteField } from "Components/FormFields/";
import FormActions from "Components/FormActions";

// Factories

// Screens
import PrinterForm from "Screens/Portal/Printers/PrinterForm";

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import useGetAllPrinterTypes from "Services/PrinterTypes/useGetAllPrinterTypes";
import { useGetAllPrintersDropdowns } from "Services/Dropdowns/";
import { useAddSalesSection, useEditSalesSection } from "Services/SalesChannels/";
import { AddIcCall } from "@material-ui/icons";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  otherName: Joi.string().allow(null, "").min(1).max(100),
  salesSectionTypeId: Joi.string().required(),
  printerId: Joi.string().allow(null, ""),
});

/**
 * @name SalesChannelForm
 * @summary
 * @salesChannel
 * @component
 * @description
 * >
 */
const SalesChannelForm = ({
  editFormData,
  editId,
  addCallback,
  editCallback,
  onCancel,
  mode,
  open,
  permissions,
  setOpen,
  parent,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  // Global State Hooks
  const [printers, setPrinters] = useGlobalState("printers.printers");
  const [salesSectionsTypes] = useGlobalState("salesSectionsTypes.salesSectionsTypes");

  // State Hooks
  const [openPrinterForm, setOpenPrinterForm] = useState(false);
  const [printerTypes, setPrinterTypes] = React.useState([]);
  const [isFetchingPrinterTypes, setIsFetchingPrinterTypes] = React.useState(true);
  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit")
      setFormData(_.pick(editFormData, ["name", "otherName", "salesSectionTypeId", "printerId"]));
    else setFormData({});
  }, [open]);

  useEffect(() => {
    if (!printers.isFetchingRows && !printers.data.length)
      getAllPrintersDropdowns()
        .then((res) => {
          setPrinters({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [printers.isFetchingRows]);

  useEffect(() => {
    getAllPrinterTypes()
      .then((type) => {
        setPrinterTypes(() => type.map((sc) => ({ value: sc.id, text: sc.id })));
        setIsFetchingPrinterTypes(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getAllPrinterTypes = useGetAllPrinterTypes();
  const getAllPrintersDropdowns = useGetAllPrintersDropdowns();
  const addSalesChannel = useAddSalesSection({
    languageKey: "setup.salesChannel",
  });
  const editSalesChannel = useEditSalesSection({
    languageKey: "setup.salesChannel",
  });

  const salesChannelForm = useForm({
    validationSchema,
    languageKey: "setup.salesChannel",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = salesChannelForm;

  // Event Handlers
  const onOpenPrinterForm = () => {
    setOpenPrinterForm(true);
  };
  const onClosePrinterForm = () => {
    setOpenPrinterForm(false);
  };

  const onCLoseSalesChannelForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddSalesChannel = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: salesChannelForm,
      formName: "salesChannel",
      apiHandler: addSalesChannel,
      onClose: onCLoseSalesChannelForm,
    });
  };

  const onSaveSalesChannel = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: salesChannelForm,
      formName: "salesChannel",
      apiHandler: editSalesChannel,
      onClose: onCLoseSalesChannelForm,
    });
  };

  const printerCallback = (response) => {
    const printer = response.data.data;
    const newPrinter = {
      value: printer.id,
      text: printer.name,
    };
    setPrinters({
      ...printers,
      data: [...printers.data, printer],
    });
    setIsSubmiting(false);
    setFormData({
      ...formData,
      printerId: newPrinter.value,
    });
  };

  // Other
  console.log(
    `%cSalesChannelForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseSalesChannelForm}
      scroll={"paper"}
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle>Sales Channel</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Sales Channel Name"}
                helpKey="Menu.salesChannel.NameField"
                autoFocus
                fullWidth
                //  maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <TextField
                fullWidth
                name="otherName"
                autoComplete="otherName"
                tooltip={"Sales Channel Other Name"}
                helpKey="common.otherName"
                label={<LocalizedMessage id="common.otherName" />}
                // maxCharacters={4}
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.otherName}
              />
              <AutoCompleteField
                required
                fullWidth
                name="salesSectionTypeId"
                tooltip={"Sales Channels"}
                helpKey="Menu.salesChannel.salesSectionTypeId"
                label={<LocalizedMessage id="setup.salesChannel.salesSectionTypeId" />}
                onChange={onFormFieldChange}
                value={formData.salesSectionTypeId}
                errors={formErrors.salesSectionTypeId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.salesSectionTypeId}
                disabled={mode === "edit"}
                options={salesSectionsTypes.data.map((ss) => {
                  return {
                    value: ss.id,
                    text: intl.formatMessage({
                      id: `lookUpTables.salesSectionTypes.${ss.id}`,
                    }),
                  };
                })}
                getOptionDisabled={(option) =>
                  (option.value !== "DineIn" && parent === "table") ||
                  (option.value === "Online" && parent === "salesChannel")
                }
              />

              <SelectField
                fullWidth
                name="printerId"
                tooltip={"printerId"}
                helpKey="Menu.items.printerId"
                label={<LocalizedMessage id="common.printer" />}
                onChange={onFormFieldChange}
                value={formData.printerId}
                errors={formErrors.printerId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.printerId}
                options={printers.data
                  .map((p) => {
                    if (p.printerTypeId === "salesSection")
                      return {
                        value: p.id,
                        text: p.name,
                      };
                  })
                  .filter((p) => p !== undefined)}
                openForm={onOpenPrinterForm}
                addPermission={
                  permissions.isAdmin ||
                  (permissions.printer && permissions.printer.includes("add"))
                }
              />
            </form>
            <PrinterForm
              open={openPrinterForm}
              mode={"add"}
              addCallback={printerCallback}
              printerTypes={printerTypes}
              onCancel={onClosePrinterForm}
              parent="salesChannel"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddSalesChannel}
          mode={mode}
          onCLoseForm={onCLoseSalesChannelForm}
          isSubmitting={isSubmitting}
          onSave={onSaveSalesChannel}
        />
      </DialogActions>
    </Dialog>
  );
};

SalesChannelForm.propTypes = {
  /**
   *
   */
};

SalesChannelForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default SalesChannelForm;
