// React
import React, { useState } from "react";

// Material
import {
  useTheme,
  Typography,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  Box,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import {
  displayenqueueSnackbar,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";
import { getReportDate, getDateDurations } from "Utils";

// Components
import CheckBox from "Components/FormFields/CheckBox";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services
import { useSendReportEmail } from "Services/ReportEmails/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components

/**
 * @email SendEmailsDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SendEmailsDialog = ({
  dateDuration,
  dateFrom,
  dateTo,
  reportEmails,
  open,
  setOpen,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Effect Hooks

  // Other Hooks
  const sendZReport = useSendReportEmail({});
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleSendEmails = () => {
    setIsSubmitting(true);
    const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
    sendZReport({
      from,
      to,
      emails: selectedEmails.map((s) => s.email),
    })
      .then((response) => {
        displayenqueueSnackbar(enqueueSnackbar, "sent");
        setIsSubmitting(false);
        handleClose();
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsSubmitting(false);
      });
  };

  const handleCheckBox = (e, checked, email) => {
    if (checked)
      setSelectedEmails((previousState) => [...previousState, email]);
    else
      setSelectedEmails(selectedEmails.filter((e) => e.email !== email.email));
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  console.log("reportEmails", reportEmails);

  // Component Render
  return (
    <Box>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <LocalizedMessage id="report.sendEmails" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {reportEmails.length ? (
              <LocalizedMessage id="report.chooseEmails" />
            ) : (
              <LocalizedMessage id="report.addEmails" />
            )}
          </DialogContentText>
          <form className={classes.form} noValidate>
            {reportEmails.map((email) => (
              <Box key={email.id} display="flex" alignItems="center">
                <CheckBox
                  key={email.email}
                  id={email.email}
                  checked={Boolean(
                    selectedEmails.find((e) => e.email === email.email)
                  )}
                  onChange={(e, checked) => handleCheckBox(e, checked, email)}
                  onClick={(e) => e.stopPropagation()}
                  label={<Typography variant="body2">{email.email}</Typography>}
                />
              </Box>
            ))}
          </form>
        </DialogContent>
        <DialogActions>
          <FormActions
            onCLoseForm={handleClose}
            isSubmitting={isSubmitting}
            action={<LocalizedMessage id="general.apply" />}
            onAction={handleSendEmails}
            disabled={!selectedEmails.length}
          />
          {/* <Button autoFocus onClick={handleClose} color="secondary">
            <LocalizedMessage id="general.cancel" />
          </Button>
          <Button
            color="primary"
            onClick={handleSendEmails}
            disabled={isSubmitting || !selectedEmails.length}
            autoFocus
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <LocalizedMessage id="general.apply" />
            )}
          </Button> */}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

SendEmailsDialog.propTypes = {
  /**
   *
   */
};

SendEmailsDialog.defaultProps = {
  /**
   *
   */
};

export default SendEmailsDialog;
