// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box, Typography, useTheme, CardHeader, makeStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Skeleton from "@material-ui/lab/Skeleton";

// Globals

// Helpers
import useSkeleton from "Helpers/useSkeleton";

// Components

// Factories

// Servicies

// Screens

// Assets

// Third Parties
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => ({
  subColor: {
    color: "#ffffff",
  },
}));
// Ad-Hoc Components

/**
 * @name SummaryCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SummaryCard = ({ isLoading, keyy = [], headerStyle, CardIcon }) => {
  // Theme & Style Hooks
  const Theme = useTheme();
  const classes = useStyles(Theme);

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks
  const intl = useIntl();
  const [inputSkeleton, inputRef] = useSkeleton("rect");

  // Event Handlers

  // Other
  // Component Render
  return (
    <Card style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      {isLoading && inputSkeleton ? (
        inputSkeleton
      ) : (
        <CardHeader
          ref={inputRef}
          style={{ ...headerStyle, flex: 1 }}
          subheader={
            <Typography className={classes.subColor}>
              {intl.formatMessage({
                id: `live.${keyy[0]}`,
              })}
            </Typography>
          }
        />
      )}
      <CardContent style={{ flex: 1 }}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {isLoading ? (
              <Skeleton width={70} height={40} style={{ marginTop: "auto" }} />
            ) : (
              <Box style={{ marginTop: "auto" }}>
                <Typography variant="h4">{keyy[1] ? keyy[1] : 0}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

SummaryCard.propTypes = {
  /**
   *
   */
};

export default SummaryCard;
