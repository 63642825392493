// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import useStateRef from "Helpers/useStateRef";

// Components
import { SelectField, TextField } from "Components/FormFields";
import SubscriptionAlert from "Components/SubscriptionAlert";
import SubscriptionDialog from "Components/SubscriptionDialog";
import FormActions from "Components/FormActions";

// Factories

// Screens
import PrinterForm from "Screens/Portal/Printers/PrinterForm";
import ServerChangedAlert from "./ServerChangedAlert";
// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";

// Services
import useGetAllPrinterTypes from "Services/PrinterTypes/useGetAllPrinterTypes";
import { useGetAllPrintersDropdowns } from "Services/Dropdowns/";
import { useGetAvailableSubscriptions } from "Services/Subscription/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components

/**
 * @name POSForm
 * @summary
 * @POS
 * @component
 * @description
 * >
 */
const POSForm = ({
  editFormData,
  onAdd,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  // Global State Hooks
  const [printers, setPrinters] = useGlobalState("printers.printers");
  const [salesSectionsTypes, setSalesSectionsTypes] = useGlobalState(
    "salesSectionsTypes.salesSectionsTypes"
  );
  const [branch, setBranch] = useGlobalState("userData.branch");

  // State Hooks
  const [openPrinterForm, setOpenPrinterForm] = useState(false);
  const [printerTypes, setPrinterTypes] = useState([]);
  const [subscriptions, setSubscriptions] = useStateRef([]);
  const [isFetchingPrinterTypes, setIsFetchingPrinterTypes] = useState(true);
  const [status, setStatus] = useState(0);
  const [openSubscriptionAlert, setOpenSubscriptionAlert] = useState(false);
  const [openServerChangedAlert, setOpenServerChangedAlert] = useState(false);

  const validationSchema = Joi.object({
    name: Joi.string().required().min(1).max(100),
    printerId: Joi.string().uuid().allow(null, ""),
    subscriptionId:
      mode === "edit"
        ? Joi.string().uuid().allow(null, "")
        : Joi.string().uuid().required().allow(null, ""),
    type: Joi.string().valid("Server", "Client").required(),
  });

  // Effect Hooks
  useEffect(() => {
    // get subscription
    if (open) {
      getAvailableSubscriptions({
        branchOrDevice: "device",
        branchId: branch.id,
      })
        .then((response) => {
          if (response.length !== 0) {
            setSubscriptions(
              response.map((subscription) => ({
                value: subscription.id,
                text: `${subscription.subscriptionId}/ ${subscription.subscriptionPlan}/ ${subscription.subscriptionExpiryDate}`,
              }))
            );
            const index = subscriptions
              .getState()
              .find((sub) => sub.value === editFormData.subscription?.id);
            if (editFormData.subscription && index === undefined) {
              setSubscriptions([
                ...subscriptions.getState(),
                {
                  value: editFormData.subscription?.id,
                  text: `${editFormData.subscription?.subscriptionId}/ ${editFormData.subscription?.subscriptionPlan}/ ${editFormData.subscription?.subscriptionExpiryDate}`,
                },
              ]);
            }
          }
        })
        .catch((errorResponse) => {
          setStatus(errorResponse.status);
        });
    }
  }, [open]);

  useEffect(() => {
    // Set form data if Edit mode
    if (open) {
      console.log("editFormData", editFormData);
      editFormData = {
        ...editFormData,
        subscriptionId: editFormData.subscription?.id,
      };

      const index = subscriptions
        .getState()
        .find((sub) => sub.value === editFormData.subscription?.id);
      if (editFormData.subscription && index === undefined) {
        setSubscriptions([
          ...subscriptions.getState(),
          {
            value: editFormData.subscription?.id,
            text: `${editFormData.subscription?.subscriptionId}/ ${editFormData.subscription?.subscriptionPlan}/ ${editFormData.subscription?.subscriptionExpiryDate}`,
          },
        ]);
      }
      if (mode === "edit") {
        setFormData(
          _.pick(editFormData, ["printerId", "subscriptionId", "type", "name"])
        );
      }
    }
    if (!open) setSubscriptions([]);
  }, [open]);

  useEffect(() => {
    if (!printers.isFetchingRows && !printers.data.length)
      getAllPrintersDropdowns()
        .then((res) => {
          setPrinters({ data: res, isFetchingRows: false });
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
        });
  }, [printers.isFetchingRows]);

  useEffect(() => {
    getAllPrinterTypes()
      .then((type) => {
        setPrinterTypes(() =>
          type.map((sc) => ({ value: sc.id, text: sc.id }))
        );
        setIsFetchingPrinterTypes(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const getAllPrinterTypes = useGetAllPrinterTypes();
  const getAllPrintersDropdowns = useGetAllPrintersDropdowns();
  const getAvailableSubscriptions = useGetAvailableSubscriptions({
    languageKey: "subscription",
  });

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "POS" });

  useEffect(() => {
    if (mode === "edit") {
      if (editFormData.type === "Server" && formData.type === "Client")
        setOpenServerChangedAlert(true);
    }
  }, [formData.type]);

  // Event Handlers

  const onOpenPrinterForm = () => {
    setOpenPrinterForm(true);
  };
  const onClosePrinterForm = () => {
    setOpenPrinterForm(false);
  };

  const openAddPOSForm = (parentPOS) => {
    setOpen(true);
  };

  const onCLosePOSForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddPOS = () => {
    onAdd(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLosePOSForm
    );
  };

  const onSavePOS = () => {
    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLosePOSForm
    );
  };

  const printerCallback = (response) => {
    const printer = response.data.data;
    const newPrinter = {
      value: printer.id,
      text: printer.name,
    };
    setPrinters({
      ...printers,
      data: [...printers.data, printer],
    });
    setIsSubmiting(false);
    setFormData({
      ...formData,
      printerId: newPrinter.value,
    });
  };

  const handleCloseServerChangedAlert = () => {
    setFormData({ ...formData, type: "Server" });
    setOpenServerChangedAlert(false);
  };
  // Other
  console.log(
    `%cPOSForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  const onOpenSubscriptionAlert = () => {
    setOpenSubscriptionAlert(true);
  };

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLosePOSForm}
      scroll={"paper"}
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="common.pos" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography component="span">
            <LocalizedMessage
              id={mode === "edit" ? "general.edit" : "general.add"}
            />
          </Typography>
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Device Name"}
                helpKey="POS.deviceName"
                autoFocus
                fullWidth
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <SelectField
                fullWidth
                name="printerId"
                tooltip={"printerId"}
                helpKey="Menu.POS.printerId"
                label={<LocalizedMessage id="POS.printerId" />}
                onChange={onFormFieldChange}
                value={formData.printerId}
                errors={formErrors.printerId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.printerId}
                options={printers.data
                  .map((p) => {
                    if (p.printerTypeId === "receipt")
                      return { value: p.id, text: p.name };
                  })
                  .filter((p) => p !== undefined)}
                openForm={onOpenPrinterForm}
                addPermission={
                  permissions.isAdmin ||
                  (permissions.printer && permissions.printer.includes("add"))
                }
              />
              <SelectField
                fullWidth
                required={mode === "add" ? true : false}
                name="subscriptionId"
                tooltip={"subscriptionId"}
                helpKey="Menu.POS.subscriptionId"
                label={<LocalizedMessage id="POS.subscriptionId" />}
                onChange={onFormFieldChange}
                value={formData.subscriptionId}
                errors={formErrors.subscriptionId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.subscriptionId}
                options={subscriptions.getState()}
                openForm={onOpenSubscriptionAlert}
                addPermission={
                  permissions.isAdmin &&
                  branch.subscription?.subscriptionPlan !== "Trial"
                }
              />
              <SelectField
                neglectNone
                required
                fullWidth
                name="type"
                tooltip={"Device value type"}
                helpKey="POS.type"
                label={<LocalizedMessage id="POS.type" />}
                onChange={onFormFieldChange}
                value={formData.type}
                errors={formErrors.type}
                isSubmitting={isSubmitting}
                isLoading={isLoading.type}
                options={[
                  {
                    value: "Server",
                    text: intl.formatMessage({
                      id: "common.server",
                    }),
                  },
                  {
                    value: "Client",
                    text: intl.formatMessage({
                      id: "common.client",
                    }),
                  },
                ]}
              />
            </form>
            <PrinterForm
              open={openPrinterForm}
              mode={"add"}
              addCallback={printerCallback}
              printerTypes={printerTypes}
              onCancel={onClosePrinterForm}
              parent="pos"
            />
            <SubscriptionAlert
              open={openSubscriptionAlert}
              onCloseDialog={() => setOpenSubscriptionAlert(false)}
              status={status}
              branchOrDevice="device"
            />
            <ServerChangedAlert
              open={openServerChangedAlert}
              handleContinueEditing={() => setOpenServerChangedAlert(false)}
              onCloseDialog={handleCloseServerChangedAlert}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddPOS}
          mode={mode}
          onCLoseForm={onCLosePOSForm}
          isSubmitting={isSubmitting}
          onSave={onSavePOS}
        />
      </DialogActions>
    </Dialog>
  );
};

POSForm.propTypes = {
  /**
   *
   */
};

POSForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default POSForm;
