import { useAxios } from "Helpers/Hooks"
import _ from "lodash"

const useSignIn = ({ languageKey }) => {
  const axios = useAxios({ api: false })

  const fieldMappings = {
    email: "email",
    password: "password",
  }

  const getAPIFieldName = (field) => {
    return fieldMappings[field]
  }

  const getReactFieldName = (field) => {
    return _.invert(fieldMappings)[field]
  }

  return ({ email, password }) =>
    new Promise(function (resolve, reject) {
      axios
        .post("/login", {
          [getAPIFieldName("email")]: email,
          [getAPIFieldName("password")]: password,
        })
        .then((response) => {
          resolve({ accessToken: response.data.message.accessToken })
        })
        .catch((error) => {
          let endErrors = []
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            const { status, data } = error.response
            if (status === 400) {
              const joi = Array.from(data.errors.joi)
              endErrors = joi.map((e) => ({
                field: getReactFieldName(e.context.key),
                message: e.message,
                languageKey: `validation.${getReactFieldName(
                  e.context.key
                )}.${e.type}`,
              }))
            } else if (status === 403) {
              endErrors = [
                {
                  message: "invalid email or password",
                  languageKey: `validation.any.invalidCredentials`,
                },
              ]
            } else if (status === 401) {
              endErrors.push({
                message: "This email is not confirmed",
                languageKey: "errors.server.confirmationError",
              })
            }
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            })
          } else {
            // Something happened in setting up the request that triggered an Error
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            })
          }

          reject(endErrors)
        })
    })
}

export default useSignIn
