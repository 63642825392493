// React
import React from "react";
import PropTypes from "prop-types";

// Material

// Globals

// Helpers
import useSkeleton from "Helpers/useSkeleton";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name Image
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Image = (props) => {
  // Props Destructing
  const {
    src,
    isLoading,
    estimatedHeight,
    estimatedWidth,
    ...otherProps
  } = props;

  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks
  const [imgSkeleton, imgRef] = useSkeleton(
    "rect",
    estimatedHeight,
    estimatedWidth
  );

  // Event Handlers

  // Other

  // Component Render
  return isLoading && imgSkeleton ? (
    imgSkeleton
  ) : (
    <img ref={imgRef} src={src} {...otherProps} />
  );
};

Image.propTypes = {
  /**
   *
   */
};

Image.defaultProps = {
  isLoading: false,
};

export default Image;
