// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Paper,
  useTheme,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";

// Components

// Factories

// Screens
import ItemList from "./ItemList";

// Assets

// Services
import { useGetTopSellingItems } from "Services/Dashboard/";

// Third Parties
import { useSnackbar } from "notistack";
import moment from "moment";
import { useIntl } from "react-intl";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 100,
  },
  {
    id: "ordered",
    label: <LocalizedMessage id="item.ordered" />,
    minWidth: 100,
  },
];

/**
 * @name TopSellingItemsList
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const TopSellingItemsList = ({ dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [topSellingItems, setTopSellingItems] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);

  // Effect Hooks
  useEffect(() => {
    getTopSellingItems(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    )
      .then((items) => {
        const newItems = items.map((note) => createData(note));
        setIsFetchingRows(false);
        setRows(newItems);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [dateFrom, dateTo]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getTopSellingItems = useGetTopSellingItems();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Other
  function createData(topSellingItem) {
    return {
      id: topSellingItem.id,
      name: topSellingItem.name,
      ordered: topSellingItem.ordered,
    };
  }
  const intl = useIntl();

  // Component Render
  return (
    <>
      <Typography variant="h6" component="h2" style={{ marginBottom: 4 }}>
        <LocalizedMessage id="dashboard.topSellingItems" />
      </Typography>
      <Paper>
        {isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .getState()
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </>

    // <ItemList
    //   perRow={4}
    //   title={intl.formatMessage({ id: "dashboard.topSellingItems" })}
    //   items={topSellingItems}
    // // items={[
    // //   {
    // //     title: "Angus Burger Meal",
    // //     image: AngusBurgerMeal,
    // //     price: 50,
    // //     ordered: 65565654546,
    // //   },
    // //   {
    // //     title: "Stone Oven Pizza",
    // //     image: StoneOvenPizza,
    // //     price: 70,
    // //     ordered: 5645406,
    // //   },
    // //   {
    // //     title: "Noddles With Red Sauce",
    // //     image: NoddlesWithRedSauce,
    // //     price: 20,
    // //     ordered: 745046,
    // //   },
    // //   {
    // //     title: "Salmon Fish Dish With Vegetables",
    // //     image: SalmonFishDish,
    // //     price: 35,
    // //     ordered: 9046,
    // //   },
    // // ]}
    // />
  );
};

TopSellingItemsList.propTypes = {
  /**
   *
   */
};

export default TopSellingItemsList;
