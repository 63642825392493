// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";

// Globals
import Theme from "Globals/Theme";

// Helpers
import { useGlobalState } from "Helpers/GlobalState";
import { enUS, arEG } from "Helpers/MaterialLocale";
import { LocalizationProvider } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties
import { create } from "jss";
import rtl from "jss-rtl";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// Styles

// Ad-Hoc Components

// Other
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

/**
 * @name Providers
 * @summary App Context Providers
 * @category Factories
 * @component
 * @description
 * > App Context Providers that inject:<br />
 * > Styles<br />
 * > Theme<br />
 * > Language
 */
function Providers(props) {
  // Theme & Style Hooks

  // Global State Hooks
  const [locale] = useGlobalState("global.locale");

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  // Construct theme object
  const theme = createMuiTheme(Theme({ locale }), locale == "ar" ? arEG : enUS);
  // Set app root node direction according to the theme
  document.getElementById("root").dir = theme.direction;

  // Component Render
  return (
    <>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider maxSnack={5}>{props.children}</SnackbarProvider>
            </MuiPickersUtilsProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

Providers.propTypes = {
  /**
   * Application main component
   */
  children: PropTypes.node,
};

export default Providers;
