// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Divider,
  Paper,
  Chip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Skeleton from "@material-ui/lab/Skeleton";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import { OrderDetailsDialog as OrderDetailsDialogComponent } from "Screens/Portal/Sales/";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral";
import { useIntl } from "react-intl";

// Services
import { useGetOrderDetails } from "Services/Sales/";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

// Ad-Hoc Components

/**
 * @name OrderDetailsDialog
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const OrderDetailsDialog = ({ customerDetails, open, setOpen }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [order, setRows] = useState({});
  const [isFetchingDetails, setIsFetchingDetails] = useState(true);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");

  // Effect Hooks

  // Other Hooks
  const intl = useIntl();

  // Event Handlers

  // Other
  const returnValue = (value) => {
    return value !== null
      ? typeof value === "boolean"
        ? value
          ? "Cancelled"
          : "Applied"
        : value
      : "";
  };
  // const returnString = (key, value, xs, md, style = {}) => {
  //   let val = returnValue(key);
  //   return (
  //     (val || val === 0) && (
  //       <Grid item xs={xs} md={md} style={{ display: "flex", alignItems: "center" }}>
  //         <Typography variant="subtitle2" style={{ margin: 4 }}>
  //           {value}
  //         </Typography>
  //         <Typography variant="body2" style={style}>
  //           {isNaN(val) || value.search("Amount") === -1 ? val : numeral(val).format("0.00a")}
  //         </Typography>
  //       </Grid>
  //     )
  //   );
  // };

  const returnString = (key, value, xs, md, style = {}) => {
    let val = returnValue(key);
    return (
      (val || val === 0) && (
        <Grid item xs={xs} md={md} style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" style={{ margin: 4 }}>
            <LocalizedMessage id={value} /> :
          </Typography>
          <Typography variant="body2" style={style}>
            {isNaN(val) || value.search("Amount") === -1 ? val : numeral(val).format("0.00a")}
          </Typography>
        </Grid>
      )
    );
  };

  const returnButton = (key, value, orderId, xs, md) => {
    return (
      <Grid item xs={xs} md={md} style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2" style={{ margin: 4 }}>
          {value}
        </Typography>
        <Button
          component="label"
          color="primary"
          size="small"
          onClick={() => {
            setSelectedOrderId(orderId);
            setOpenOrderDetailsDialog(true);
          }}
          style={{
            fontStyle: "italic",
          }}
        >
          {key}
        </Button>
      </Grid>
    );
  };

  const handleClose = () => {
    setOpen(false);
    setRows({});
    setIsFetchingDetails(true);
  };

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  let phoneNumbers = "";
  if (customerDetails.customerPhones) {
    customerDetails.customerPhones.map((phoneNumber) => {
      const value = phoneNumber.phoneNumber;
      phoneNumbers += `${value} - `;
    });
    phoneNumbers = phoneNumbers.slice(0, -2);
  }
  // Component Render
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle id="alert-dialog-title">
        <LocalizedMessage id="crm.customerDetails" />
      </DialogTitle>
      <Divider variant="middle" />

      {customerDetails.customerLocations === undefined ? (
        <Skeleton
          animation="wave"
          variant="rect"
          style={{ margin: "8px 24px", height: "40vh", width: "95%" }}
        />
      ) : (
        customerDetails.id && (
          <DialogContent
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              width: "100%",
            }}
          >
            {/* <Typography className={classes.heading}>
              <LocalizedMessage id="report.customerName" />: {customerDetails.name}
            </Typography>
            <Typography className={classes.heading}>
              <LocalizedMessage id="common.email" />: {customerDetails.email}
            </Typography>
            <Typography className={classes.heading}>
              <LocalizedMessage id="printer.printersDesign.phoneNumbers" />:
            </Typography>
            {customerDetails.phoneNumbers?.map((phoneNumber) => (
              <Typography className={classes.heading}>{customerDetails.phoneNumber}</Typography>
            ))} */}
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <LocalizedMessage id="crm.contactDetails" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: downSm ? "column" : "row",
                }}
              >
                <Grid container spacing={2}>
                  {returnString(customerDetails.name, "Name:", 12)}

                  {returnString(customerDetails.email, "Email:", 12)}

                  {customerDetails.customerPhones?.length && (
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
                      <Typography variant="subtitle2" style={{ margin: 4 }}>
                        <LocalizedMessage id="setup.printersDesign.phoneNumbers" /> :
                      </Typography>
                      <Typography variant="body2">{phoneNumbers}</Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <LocalizedMessage id="crm.customerLocations" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: downSm ? "column" : "row",
                }}
              >
                <Grid container spacing={2}>
                  {customerDetails.customerLocations.map((location, index) => {
                    return (
                      <Grid key={index} spacing={2} xs={12} md={6} lg={4} item>
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid item xs={12}>
                            {returnString(location["zone"]?.name, "report.zone", 12)}
                            {returnString(location["zone"]?.otherName, "common.otherName", 12)}
                            {returnString(location["zone"]?.city?.name, "common.city", 12)}
                            {returnString(
                              location["zone"]?.city?.otherName,
                              "common.otherName",
                              12
                            )}
                            {returnString(location["streetName"], "crm.streetName", 12)}
                            {returnString(location["buildingName"], "crm.buildingName", 12)}
                            {returnString(location["floorNumber"], "crm.floorNumber", 12)}
                            {returnString(location["addressTypeId"], "crm.addressType", 12)}
                            {returnString(location["nearestLandmark"], "crm.nearestLandmark", 12)}
                            {returnString(location["formattedAddress"], "crm.address", 12)}
                            {returnString(
                              location["longitude"],
                              "sales.orderDetailsDialog.customerLongitude",
                              12
                            )}
                            {returnString(
                              location["latitude"],
                              "sales.orderDetailsDialog.customerLatitude",
                              12
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <LocalizedMessage id="common.order" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  display: "flex",
                  flexDirection: downSm ? "column" : "row",
                }}
              >
                <Grid container spacing={2}>
                  {customerDetails.orders.map((order, index) => {
                    return (
                      <Grid key={index} spacing={2} xs={12} md={6} lg={3} item>
                        <Paper
                          elevation={2}
                          style={{
                            padding: "16px",
                            display: "flex",
                            width: "100%",
                            margin: "4px",
                          }}
                        >
                          <Grid item xs={12}>
                            {order["orderStatusId"] === "Open" ? (
                              <Chip
                                label={order["orderStatusId"]}
                                style={{
                                  backgroundColor: "#00c853",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : order["orderStatusId"] === "Closed" ? (
                              <Chip
                                label={order["orderStatusId"]}
                                style={{
                                  backgroundColor: "silver",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : order["orderStatusId"] === "Merged" ? (
                              <Chip
                                label={order["orderStatusId"]}
                                style={{
                                  backgroundColor: "#fbc02d",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : order["orderStatusId"] === "Void" ? (
                              <Chip
                                label={order["orderStatusId"]}
                                style={{
                                  backgroundColor: "#c62828",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : order["orderStatusId"] === "Returned" ? (
                              <Chip
                                label={order["orderStatusId"]}
                                style={{
                                  backgroundColor: "#f361b5",
                                  color: "white",
                                  margin: 8,
                                }}
                              />
                            ) : (
                              <Chip label={order.orderstatus} color="primary" />
                            )}
                            {order["isOnline"] ? (
                              <Chip
                                label={<LocalizedMessage id="general.online" />}
                                style={{
                                  backgroundColor: "#00c853",
                                  color: "white",
                                }}
                              />
                            ) : (
                              <Chip label={<LocalizedMessage id="crm.fromPOS" />} color="primary" />
                            )}
                            {order.id
                              ? returnButton(
                                  order["orderNumberFormatted"],
                                  intl.formatMessage({
                                    id: "setup.printersDesign.orderNumberFormatted",
                                  }) + " :",
                                  order.id,
                                  12
                                )
                              : null}

                            {returnString(order["orderDay"].value, "Order Day:", 12)}
                            {returnString(
                              order["salesSection"]?.name,
                              "setup.table.salesSectionName",
                              12
                            )}

                            {returnString(order["salesSection"]?.otherName, "common.otherName", 12)}
                            {returnString(
                              order["salesSectionTypeId"],
                              "setup.salesChannel.salesSectionType",
                              12
                            )}

                            {returnString(
                              order["grandTotalAmount"],
                              "live.orderGrandTotalAmount",
                              12
                            )}
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </DialogContent>
        )
      )}
      <DialogActions>
        <FormActions onCLoseForm={handleClose} isSubmitting={false} isClose />

        {/* <Button
          style={{
            backgroundColor: "#0582D2",
            color: "#fff",
            margin: "16px",
          }}
          variant="contained"
          autoFocus
          onClick={handleClose}
          //startIcon={<CloseIcon />}
        >
          <LocalizedMessage id="common.close" />
        </Button> */}
      </DialogActions>
      <OrderDetailsDialogComponent
        open={openOrderDetailsDialog}
        setOpen={setOpenOrderDetailsDialog}
        orderId={selectedOrderId}
      />
    </Dialog>
  );
};

OrderDetailsDialog.propTypes = {
  /**
   *
   */
};

OrderDetailsDialog.defaultProps = {
  /**
   *
   */
};

export default OrderDetailsDialog;
