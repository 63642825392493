export default function searchTree(element, parentId) {
  if (element.id === parentId) {
    return element;
  } else if (element.children != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], parentId);
    }
    return result;
  }
  return null;
}
