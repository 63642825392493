import { useAxios } from "Helpers/Hooks";
import _ from "lodash";

const useEditItem = ({ languageKey }) => {
  const axios = useAxios({ api: true });

  return ({
    id,
    name,
    otherName,
    description,
    barcode,
    categoryId,
    color,
    image,
    defaultPrice,
    salesSections,
    taxes,
    discounts,
    printerId,
    isActive,
    isByKilo,
    stockType,
    modifierGroups,
    defaultStock,
  }) =>
    new Promise(function (resolve, reject) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("otherName", otherName);
      formData.append("description", description);
      formData.append("barcode", barcode);
      formData.append("categoryId", categoryId);
      formData.append("color", color);
      formData.append("image", image);
      formData.append("defaultPrice", defaultPrice);
      if (salesSections)
        salesSections.forEach((salesSection, index) => {
          formData.append(
            `salesSections[${index}]`,
            JSON.stringify(salesSection)
          );
        });
      formData.append("isByKilo", isByKilo);
      formData.append("isActive", isActive);
      formData.append("printerId", printerId);
      if (stockType === "dailyStock")
        formData.append("defaultStock", defaultStock);
      formData.append("stockType", stockType);

      taxes.forEach((tax, index) => formData.append(`taxes[${index}]`, tax));
      discounts.forEach((discount, index) =>
        formData.append(`discounts[${index}]`, discount)
      );
      modifierGroups.forEach((modifierGroup, index) =>
        formData.append(
          `modifierGroups[${index}]`,
          JSON.stringify(modifierGroup)
        )
      );
      axios
        .put("/menuItems/" + id, formData)
        .then((response) => {
          const modifierGroups = response.data.data.modifierGroups.map(
            (modifierGroup) => {
              const modifierItems = modifierGroup.modifierItems.map(
                (modifierItem) => {
                  return {
                    id: modifierItem.id,
                    name: modifierItem.name,
                    otherName: modifierItem.otherName,
                    price: modifierItem.price,
                  };
                }
              );
              return {
                id: modifierGroup.id,
                name: modifierGroup.name,
                otherName: modifierGroup.otherName,
                maxOptions: modifierGroup.maxOptions,
                minOptions: modifierGroup.minOptions,
                allowMultipleQuantities: modifierGroup.allowMultipleQuantities,
                modifierItems,
              };
            }
          );
          const result = _.pick(response.data.data, [
            "id",
            "name",
            "otherName",
            "description",
            "barcode",
            "categoryId",
            "color",
            "image",
            "defaultPrice",
            "salesSections",
            "taxes",
            "discounts",
            "printerId",
            "stockType",
            "isActive",
            "isByKilo",
            "defaultStock",
          ]);
          resolve({ ...result, modifierGroups });
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status, data } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 400) {
              const joi = Array.from(data.errors.joi);
              endErrors = joi.map((e) => ({
                field: e.context.key,
                message: e.message,
                languageKey: `validation.${e.context.key}.${e.type}`,
              }));
            } else if (status === 415) {
              endErrors.push({
                message: "Unsupoorted media type",
                languageKey: "errors.unsupportedMedia",
              });
            } else if (status === 409) {
              const e = data.errors;
              endErrors.push({
                field: e.fieldName,
                message: e.message,
                languageKey: `validation.${e.fieldName}.unique`,
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

export default useEditItem;
