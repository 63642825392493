// React
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

// Material
import {
  Chip,
  Button,
  useTheme,
  Box,
  Paper,
  TableRow,
  IconButton,
  Tooltip,
  TablePagination,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  CircularProgress,
  Table,
  useMediaQuery,
  Typography,
  Divider,
} from "@material-ui/core";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import SearchIcon from "@material-ui/icons/Search";
import WebIcon from "@material-ui/icons/Web";

// Globals

// Helpers
import { useForm } from "Helpers/Hooks";
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { TextField } from "Components/FormFields";

// Factories

// Screens
import BranchForm from "./BranchSetupForm";
import PrintQRs from "./PrintQRs";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";

// Services
import {
  useActivateBranches,
  useGetAllBranchTables,
} from "Services/OnlineOrders";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "fullName",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "onlineDineInCode",
    label: <LocalizedMessage id="branch.onlineDineInCode" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "status",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 50,
  },
  { id: "actions", label: "", minWidth: 50, format: false },
];

const { REACT_APP_QRLINK } = process.env;

/**
 * @name SetupBranches
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SetupBranches = ({ branches, setBranches }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [accountState] = useGlobalState("userData.account");

  // State Hooks
  const [searchQuery, setSearchQuery] = useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [isGettingPdf, setIsGettingPdf] = useState(false);
  const [openBranchForm, setOpenBranchForm] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = useState(true);
  const [editId, setEditId] = useState("");
  const [isActivating, setIsActivating] = useStateRef(false);
  const [branchFormData, setBranchFormData] = useState({});
  const [qrData, setQrData] = useState({});
  const [openPrintQRs, setOpenPrintQRs] = useState(false);

  useEffect(() => {
    if (branches.length) {
      setRows(branches.map((branch) => createData({ ...branch })));
      setIsFetchingRows(false);
    }
  }, [branches]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const { enqueueSnackbar } = useSnackbar();
  const activateBranches = useActivateBranches({ languageKey: "branch" });
  const getAllBranchTables = useGetAllBranchTables();
  const intl = useIntl();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const handleDeactivateClick = (branch) => {
    setIsActivating(true);
    activateBranches({
      id: branch.id,
      isActiveInOnline: false,
      onlineDineInCode: undefined,
    })
      .then((response) => {
        displayenqueueSnackbar(enqueueSnackbar, "edited");
        const newRows = rows.getState();
        const index = newRows.findIndex((row) => row.id === branch.id);
        const br = branches.find((b) => b.id === branch.id);
        newRows.splice(
          index,
          1,
          createData({ ...br, isActiveInOnline: false })
        );
        setRows([...newRows]);

        setIsActivating(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsActivating(false);
      });
  };

  const handleEdit = (branch) => {
    setEditId(branch.id);
    setBranchFormData(branch);
    setOpenBranchForm(true);
  };

  const handleEditBranchCode = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseBranchForm
  ) => {
    const validForm = validateForm();
    console.log(validForm);
    if (validForm) {
      setIsSubmiting(true);
      const { id, onlineDineInCode } = formData;

      activateBranches({
        id,
        isActiveInOnline: onlineDineInCode ? true : false,
        onlineDineInCode: onlineDineInCode ? onlineDineInCode : undefined,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");
          const newRows = rows.getState();
          const index = newRows.findIndex((row) => row.id === id);
          const br = branches.find((b) => b.id === id);
          newRows.splice(
            index,
            1,
            createData({ ...br, isActiveInOnline: true, onlineDineInCode })
          );
          setRows([...newRows]);

          setIsSubmiting(false);
          onCLoseBranchForm();
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
          setIsSubmiting(false);
        });
    }
  };

  const handlePrintPdf = (branch) => {
    setOpenPrintQRs(true);
    setIsGettingPdf(true);
    getAllBranchTables(branch.id)
      .then((res) => {
        setQrData({ tablesData: res, branch });
        setIsGettingPdf(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsGettingPdf(false);
      });
  };

  // Other
  function createData(branch) {
    return {
      id: branch.id,
      fullName:
        branch.name +
        (branch.otherName && branch.otherName !== null
          ? ` "${branch.otherName}"`
          : ""),
      onlineDineInCode: branch.onlineDineInCode,
      status: branch.isActiveInOnline ? (
        <Chip
          label={<LocalizedMessage id="general.online" />}
          color="primary"
        />
      ) : (
        <Chip
          label={<LocalizedMessage id="general.notOnline" />}
          style={{ color: "white", backgroundColor: "#0582D2" }}
        />
      ),
      actions: (
        <Box>
          {branch.isActiveInOnline ? (
            <>
              <Tooltip title={<LocalizedMessage id="general.notOnline" />}>
                <IconButton
                  component={isActivating.getState() ? "div" : undefined}
                  onClick={() => handleDeactivateClick(branch)}
                  aria-label="notOnline"
                >
                  <NotInterestedIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={<LocalizedMessage id="general.editBranchCode" />}>
                <IconButton
                  component={isActivating.getState() ? "div" : undefined}
                  aria-label="editCode"
                  onClick={() => handleEdit(branch)}
                  disabled={!branch.isActiveInOnline}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={<LocalizedMessage id="onlineOrders.openOnlineWebSite" />}
              >
                <a
                  target="_blank"
                  href={`${REACT_APP_QRLINK}${accountState.brands[0].name.replace(
                    /\s/g,
                    ""
                  )}/getstarted/${jwt.sign(
                    { brandId: branch.brandId, branchId: branch.id },
                    "1234"
                  )}`}
                >
                  <IconButton
                    component={isActivating.getState() ? "div" : undefined}
                    aria-label="notOnline"
                  >
                    <WebIcon />
                  </IconButton>
                </a>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title={<LocalizedMessage id="general.online" />}>
                <IconButton
                  component={isActivating.getState() ? "div" : undefined}
                  onClick={() => handleEdit(branch)}
                  aria-label="online"
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={<LocalizedMessage id="general.editBranchCode" />}>
                <IconButton
                  component={isActivating.getState() ? "div" : undefined}
                  aria-label="editCode"
                  onClick={() => handleEdit(branch)}
                  disabled={!branch.isActiveInOnline}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={<LocalizedMessage id="onlineOrders.openOnlineWebSite" />}
              >
                <IconButton
                  component={isActivating.getState() ? "div" : undefined}
                  aria-label="notOnline"
                  disabled={!branch.isActiveInOnline}
                >
                  <WebIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Tooltip title={<LocalizedMessage id="report.downloadPDF" />}>
            <IconButton
              aria-label="pdf"
              disabled={!branch.isActiveInOnline}
              onClick={() => handlePrintPdf(branch)}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="branch.pageTitle" key="branch.pageTitle" />
        }
      />

      <Box
        dir={Theme.direction}
        style={{
          position: "relative",
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              <LocalizedMessage id="branch.pageTitle" />
            </Typography>

            {!openSearchField && downSm && (
              <IconButton onClick={() => setOpenSearchField(true)}>
                <SearchIcon color={"primary"} />
              </IconButton>
            )}
            {(openSearchField || !downSm) && (
              <TextField
                label={intl.formatMessage({ id: "general.search" })}
                size="small"
                value={searchQuery}
                onChange={handleSearchChange}
                variant="outlined"
              />
            )}
          </Box>

          <Paper className={classes.root}>
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows.getState().length
                        ? rows
                            .getState()
                            .filter(
                              (row) =>
                                row.fullName
                                  .toLowerCase()
                                  .indexOf(searchQuery.toLowerCase()) != -1 ||
                                searchQuery.toLowerCase() === ""
                            )
                            .slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage
                            )
                            .map((row) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={row.id}
                                >
                                  {columns.map((column) => {
                                    return (
                                      <TableCell
                                        key={column.id}
                                        align={column.align}
                                      >
                                        {row[column.id]}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              );
                            })
                        : null}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Box>
      <BranchForm
        open={openBranchForm}
        setOpen={setOpenBranchForm}
        mode="edit"
        editFormData={branchFormData}
        onSave={handleEditBranchCode}
      />
      <PrintQRs
        qrData={qrData}
        open={openPrintQRs}
        onCloseDialog={() => setOpenPrintQRs(false)}
        isGettingPdf={isGettingPdf}
      />
    </Box>
  );
};

SetupBranches.propTypes = {
  /**
   *
   */
};

SetupBranches.defaultProps = {
  /**
   *
   */
};

export default SetupBranches;
