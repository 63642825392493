// React
import React, { useState, useEffect } from "react";

// Material
import {
  Button,
  Divider,
  useTheme,
  Grid,
  Chip,
  CircularProgress,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import useSkeleton from "Helpers/useSkeleton";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import Box from "Components/Box";
import Typography from "Components/Typography";
import SubscriptionDialog from "Components/SubscriptionDialog";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import numeral from "numeral";
import moment from "moment";

// Services
import { useGetSubscriptionsDetails } from "Services/Subscription/";

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      flexGrow: 1,
    },
    formContainer: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flex: 3,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  };
});

// Ad-Hoc Components

/**
 * @name SubscriptionsDetails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SubscriptionsDetails = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [subscriptionsPlans] = useGlobalState(
    "subscriptionsPlans.subscriptionsPlans"
  );
  const [heading, setHeading] = useGlobalState("heading.heading");

  // State Hooks
  const [isLoading, setIsLoading] = useState(true);
  const [subscriptionsDetails, setSubscriptionsDetails] = useState([]);
  const [status, setStatus] = useState(0);
  const [openSubscribeNowDialog, setOpenSubscribeNowDialog] = useState(false);

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <SubscriptionsIcon
            style={{ margin: "0px 16px", width: 20, height: 20 }}
          />
          <LocalizedMessage
            id="subscriptionsDetails.pageTitle"
            key="subscriptionsDetails.pageTitle"
          />
        </Box>
      ),
    []
  );

  useEffect(() => {
    setIsLoading(true);
    getSubscriptionsDetails()
      .then((response) => {
        setSubscriptionsDetails(response);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        displaySnackbarErrors(error, enqueueSnackbar);
        setIsLoading(false);
      });
  }, []);

  // Other Hooks
  let history = useHistory();
  const getSubscriptionsDetails = useGetSubscriptionsDetails({
    languageKey: "subscription",
  });
  const { enqueueSnackbar } = useSnackbar();
  const [inputSkeleton, inputRef] = useSkeleton("rect");

  // Event Handlers
  const onCloseSubscriptionForm = () => {
    history.replace("/portal/dashboard");
  };

  const onSubscribeNowClick = () => {
    setOpenSubscribeNowDialog(true);
  };

  // Other
  const returnValue = (value) => {
    return value !== null
      ? typeof value === "boolean"
        ? value
          ? "Cancelled"
          : "Applied"
        : value
      : "";
  };

  const returnString = (key, value, xs, md, style = {}) => {
    let val = returnValue(key);
    return (
      (val || val === 0) && (
        <Grid
          item
          xs={xs}
          md={md}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Typography variant="subtitle2" style={{ margin: 4 }}>
            <LocalizedMessage id={value} /> :
          </Typography>
          <Typography variant="body2" style={style}>
            {isNaN(val) || value.search("Amount") === -1
              ? val
              : numeral(val).format("0.00a")}
          </Typography>
        </Grid>
      )
    );
  };

  console.log("inputSkeleton", inputSkeleton);

  // Component Render
  return (
    <>
      <Box className={classes.root}>
        <Title
          pageTitle={
            <LocalizedMessage
              id="subscriptionsDetails.pageTitle"
              key="subscriptionsDetails.pageTitle"
            />
          }
        />

        <Box className={classes.formContainer}>
          <Paper
            dir={theme.direction}
            style={{
              padding: theme.spacing(2),
              marginBottom: "20",
            }}
            elevation={2}
            square
            ref={inputRef}
          >
            <Typography variant="h6" component="h2" style={{ margin: 8 }}>
              <LocalizedMessage id="subscriptionsDetails.pageTitle" />
            </Typography>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            {isLoading && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Grid container spacing={2}>
                {subscriptionsDetails.map((subscription, index) => {
                  return (
                    <Grid key={index} xs={12} md={6} lg={4} item>
                      <Paper
                        elevation={2}
                        style={{
                          padding: "16px",
                          display: "flex",
                          width: "100%",
                          margin: "4px",
                        }}
                      >
                        <Grid item xs={12}>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                            }}
                          >
                            {subscription?.subscriptionStatus === "Active" ? (
                              <Chip
                                label={<LocalizedMessage id="branch.active" />}
                                style={{
                                  backgroundColor: "#00c853",
                                  color: "white",
                                }}
                              />
                            ) : subscription?.subscriptionStatus ===
                              "InGrace" ? (
                              <Chip
                                label={<LocalizedMessage id="branch.inGrace" />}
                                style={{
                                  backgroundColor: "#e48f2a",
                                  color: "white",
                                }}
                              />
                            ) : subscription?.subscriptionStatus ===
                              "Expired" ? (
                              <Chip
                                label={<LocalizedMessage id="branch.expired" />}
                                style={{
                                  backgroundColor: "#c62828",
                                  color: "white",
                                  marginRight: 8,
                                }}
                              />
                            ) : subscription?.subscriptionStatus ===
                              "InCountingDown" ? (
                              <Chip
                                label={
                                  <LocalizedMessage id="branch.inCountingDown" />
                                }
                                style={{
                                  backgroundColor: "#ff9800",
                                  color: "white",
                                  marginRight: 8,
                                }}
                              />
                            ) : null}
                            {subscription?.id ? (
                              subscription?.subscriptionAutoRenew ? (
                                <>
                                  <Typography
                                    style={{
                                      marginLeft: 8,
                                    }}
                                  >
                                    (
                                  </Typography>
                                  <AutorenewIcon />
                                  <Typography>
                                    <LocalizedMessage id="branch.autoRecurring" />
                                    )
                                  </Typography>
                                </>
                              ) : (
                                <>
                                  <Typography
                                    style={{
                                      marginLeft: 4,
                                    }}
                                  >
                                    (
                                  </Typography>
                                  <RadioButtonUncheckedIcon
                                    style={{
                                      margin: 4,
                                    }}
                                  />
                                  <Typography>
                                    <LocalizedMessage id="branch.nonRecurring" />
                                    )
                                  </Typography>
                                </>
                              )
                            ) : null}
                          </Box>
                          <Divider
                            style={{ marginTop: 10, marginBottom: 10 }}
                          />
                          {returnString(
                            subscription["productName"],
                            "subscriptionsDetails.productName",
                            12
                          )}
                          {returnString(
                            subscription["subscriptionId"],
                            "subscriptionsDetails.subscriptionId",
                            12
                          )}
                          {returnString(
                            subscription["type"],
                            "subscriptionsDetails.subscriptionType",
                            12
                          )}
                          {returnString(
                            subscription["subscriptionPlan"],
                            "subscriptionsDetails.subscriptionPlan",
                            12
                          )}
                          {returnString(
                            `${moment(subscription["serverCreatedAt"]).format(
                              "MMM DD, YYYY"
                            )}`,
                            "subscriptionsDetails.serverCreatedAt",
                            12
                          )}
                          {returnString(
                            `${moment(
                              subscription["subscriptionExpiryDate"]
                            ).format("MMM DD, YYYY")}`,
                            "subscriptionsDetails.subscriptionExpiryDate",
                            12
                          )}
                          {returnString(
                            subscription["quantity"],
                            subscription["type"] === "Device"
                              ? "subscriptionsDetails.devicesCount"
                              : "subscriptionsDetails.branchesCount",
                            12
                          )}
                          {subscription["type"] === "Device"
                            ? returnString(
                                subscription["quantity"] -
                                  subscription["devices"]?.length,
                                subscription["type"] === "Device"
                                  ? "subscriptionsDetails.availableDevicesCount"
                                  : "subscriptionsDetails.availableBranchesCount",
                                12
                              )
                            : returnString(
                                subscription["quantity"] -
                                  subscription["branches"]?.length,
                                subscription["type"] === "Device"
                                  ? "subscriptionsDetails.availableDevicesCount"
                                  : "subscriptionsDetails.availableBranchesCount",
                                12
                              )}
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            {subscription["subscriptionPlan"] === "Trial" && (
                              <Button
                                disabled={subscriptionsPlans?.isFetchingRows}
                                onClick={onSubscribeNowClick}
                                // style={{
                                //   color: "#cb4191",
                                //   marginRight: 4,
                                // }}
                                variant="outlined"
                                color="primary"
                              >
                                {subscriptionsPlans?.isFetchingRows && (
                                  <CircularProgress
                                    size={20}
                                    style={{ marginRight: 4 }}
                                  />
                                )}
                                <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                              </Button>
                            )}
                            {/* <Button
                              onClick={onCloseSubscriptionForm}
                              style={{
                                color: "#0582D2",
                                marginRight: 4,
                              }}
                              variant="outlined"
                            >
                              <LocalizedMessage id="general.cancel" />
                            </Button>
                            <Button
                              onClick={onCloseSubscriptionForm}
                              color="primary"
                              variant="outlined"
                            >
                              <LocalizedMessage id="subscriptionDialog.renewNow" />
                            </Button> */}
                          </Box>
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Box
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button onClick={onCloseSubscriptionForm} color="secondary">
                <LocalizedMessage id="general.cancel" />
              </Button>
            </Box>
            <SubscriptionDialog
              open={openSubscribeNowDialog}
              onCloseDialog={() => setOpenSubscribeNowDialog(false)}
              branchOrDevice="branch"
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

SubscriptionsDetails.propTypes = {
  /**
   *
   */
};

SubscriptionsDetails.defaultProps = {
  /**
   *
   */
};

export default SubscriptionsDetails;
