// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box, Button, Divider, Typography, Popover, useTheme, Tooltip } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MinimizeIcon from "@material-ui/icons/Minimize";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import pink from "@material-ui/core/colors/pink";
import purple from "@material-ui/core/colors/purple";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import lightGreen from "@material-ui/core/colors/lightGreen";
import orange from "@material-ui/core/colors/orange";
import blueGrey from "@material-ui/core/colors/blueGrey";

import grey from "@material-ui/core/colors/grey";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";

// Components

// Factories

// Screens
import ItemCard from "./ItemCard";

// Assets

// Third Parties
import { Pie } from "react-chartjs-2";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import numeral from "numeral";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components

/**
 * @name ItemList
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ItemList = ({ items, title, onAddToCart, onMinusFromCart, secondaryTextColor }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();
  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box>
      <Box
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: Theme.spacing(1),
        }}
      >
        <Typography variant="h6" component="span">
          {title}
        </Typography>
      </Box>
      {/* lg={3}  */}
      <Grid container justify="center" spacing={2}>
        {items.map((item, index) => (
          <Grid key={index} xs={12} md={6} lg={4} item style={{ overflow: "auto" }}>
            <ItemCard
              item={item}
              onAddToCart={onAddToCart}
              onMinusFromCart={onMinusFromCart}
              style={{ height: "100%" }}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

ItemList.propTypes = {
  /**
   *
   */
};

export default ItemList;
