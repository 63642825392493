// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  FormControl,
  useMediaQuery,
  useTheme,
  Divider,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import {
  displayenqueueSnackbar,
  displaySnackbarFormErrors,
} from "Utils/displaySnackbarErrors";
import payloads from "Helpers/Handlers/payloads";

// Components
import Title from "Components/Title";
import ImagePreloader from "Components/ImagePreloader";
import Box from "Components/Box";
import Typography from "Components/Typography";
import { TextField, ImageField } from "Components/FormFields";

// Factories

// Utils
import { throttle } from "Utils";

// Screens
import BrandSetupTour from "./BrandSetupTour";

// Assets
import { BrandingVector } from "Assets/Vectors";

// Third Parties
import Joi from "@hapi/joi";
import { useSnackbar } from "notistack";

// Services
import { useAddBrand, useGetBrand } from "Services/Wizard/Brands";
import { useAddStorage } from "Services/Storage";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    flexGrow: 1,
  },
  vectorContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  imageField: {
    flex: 1,
    [theme.breakpoints.down("sm")]: { padding: theme.spacing(3) },
    [theme.breakpoints.up("sm")]: { padding: theme.spacing(7) },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
      flexDirection: "row",
    },
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(7),
      flexDirection: "column",
    },
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {
    display: "flex",
  },
  formControl: {
    display: "flex",
    flex: 1,
  },
}));

// Ad-Hoc Components
// const JoiCustom = Joi.extend((joi) => {
//   return {
//     type: "strongPassword",
//     base: joi.string(),
//     messages: {
//       "strongPassword.base": '"{{#label}}" must be a strong password',
//     },
//     coerce(value, helpers) {},
//     validate(value, helpers) {
//       if (zxcvbn(value).score < 3) {
//         return { value, errors: helpers.error("strongPassword.base") };
//       }
//     },
//   };
// });

const validationSchema = Joi.object({
  name: Joi.string().min(1).max(100).required(),
  otherName: Joi.string().min(1).max(100).allow(null, ""),
  logo: Joi.alternatives(
    Joi.string().allow(null, ""),
    Joi.object().keys({
      type: Joi.any().valid(
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/svg+xml"
      ),
      name: Joi.string(),
      size: Joi.number().max(1024 * 1024),
    })
  ),
});

// Other

/**
 * @name BrandSetup
 * @summary BrandSetup Component
 * @category
 * @component
 * @description
 * >
 */
const BrandSetup = ({
  className,
  setDirty,
  setIsReady,
  setStepSubmittingCallback,
  ...otherProps
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false);
  const [isFetchingBrand, setIsFetchingBrand] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);

  // Other Hooks
  const getBrand = useGetBrand();
  const addBrand = useAddBrand({ languageKey: "wizard.brandSetup" });
  const addStorage = useAddStorage();

  const { enqueueSnackbar } = useSnackbar();
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
  } = useForm({ validationSchema, languageKey: "wizard.brandSetup" });

  // Event Handlers
  const addStorageHandler = (value) => {
    addStorage({ key: "wizard", value: value })
      .then((response) => console.log(response))
      .catch((errors) => {
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
      });
  };

  const onSubmitHandler = throttle(({ onCalled, onSuccess, onCancel }) => {
    const validForm = validateForm();

    if (validForm) {
      setIsSubmiting(true);
      onCalled();
      const payload = payloads["brandSetup"](formData);
      addBrand(payload)
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          addStorageHandler("account");
          setIsSubmiting(false);
          onSuccess();
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);

          setIsSubmiting(false);
          onCancel();
        });
    }
  }, 1000);

  // Effect Hooks
  useEffect(() => {
    setStepSubmittingCallback(() => onSubmitHandler);
  }, [formData]);

  useEffect(() => {
    if (!isImageLoading && !isFetchingBrand) {
      setIsScreenLoaded(true);
      setIsReady(true);
    }
  }, [isImageLoading, isFetchingBrand]);

  useEffect(() => {
    getBrand()
      .then((response) => {
        setFormData(response);
        setIsFetchingBrand(false);
      })
      .catch((errors) => {
        setIsFetchingBrand(false);
      });
  }, []);

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("xs"));

  // Component Render
  return (
    <>
      <Box
        className={[classes.root, className].join(" ")}
        style={{ flexDirection: downSm ? "column" : "row" }}
        {...otherProps}
      >
        <Title
          pageTitle={
            <LocalizedMessage
              id="wizard.brandSetup.pageTitle"
              key="wizard.brandSetup.pageTitle"
            />
          }
        />
        {!downSm && (
          <>
            <ImageField
              placeholderImage={BrandingVector}
              name="logo"
              label={<LocalizedMessage id="wizard.brandSetup.logo" />}
              tooltip="Accepted file types: jpeg, png, gif and svg"
              onChange={onFormFieldChange}
              value={formData.logo}
              errors={formErrors.logo}
              isSubmitting={isSubmitting}
              isLoading={!isScreenLoaded}
              className={classes.imageField}
            />
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {downSm && (
          <>
            <Box className={classes.formContainer}>
              <Typography
                component="h1"
                variant="h6"
                isLoading={!isScreenLoaded}
              >
                <LocalizedMessage id="wizard.brandSetup.getStarted" />
              </Typography>
            </Box>
            <Divider variant="middle" />
            <ImageField
              placeholderImage={BrandingVector}
              name="logo"
              label={<LocalizedMessage id="wizard.brandSetup.logo" />}
              tooltip="Accepted file types: jpeg, png, gif and svg"
              onChange={onFormFieldChange}
              value={formData.logo}
              errors={formErrors.logo}
              isSubmitting={isSubmitting}
              isLoading={!isScreenLoaded}
              className={classes.imageField}
            />
          </>
        )}
        <Box className={classes.formContainer}>
          {!downSm && (
            <Typography component="h1" variant="h6" isLoading={!isScreenLoaded}>
              <LocalizedMessage id="wizard.brandSetup.getStarted" />
            </Typography>
          )}
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoFocus
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                tooltip={"Your restaurant, cafe or bar name"}
                helpKey="common.name"
                //maxCharacters={50}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
              <TextField
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                onChange={onFormFieldChange}
                value={formData.otherName}
                errors={formErrors.otherName}
                isSubmitting={isSubmitting}
                isLoading={!isScreenLoaded}
                tooltip={"Your restaurant, cafe or bar other name"}
                helpKey="common.otherName"
                //maxCharacters={50}
                helpIconProps={{
                  "data-tour": "helpIcon",
                }}
              />
            </FormControl>
          </form>
        </Box>
      </Box>
      {/* <BrandSetupTour isScreenLoaded={isScreenLoaded} /> */}

      <ImagePreloader
        onLoaded={() => setIsImageLoading(false)}
        onTimeout={() => setIsImageLoading(false)}
        images={[BrandingVector]}
      />
    </>
  );
};

BrandSetup.propTypes = {
  /**
   *
   */
};

export default BrandSetup;
