// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Typography,
  Grid,
  Button,
  Divider,
  FormHelperText,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";
import { LocalizedMessage } from "Helpers/Localization";

// Components
import HelpIcon from "Components/HelpIcon";
import TextField from "Components/FormFields/TextField";

// Factories

// Screens

// Assets

// Servicies

// Help

// Third Parties

// Styles
const useStyles = makeStyles((theme) => ({}));

// Ad-Hoc Components

/**
 * @name ModifierItemField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ModifierItemField = ({
  name,
  tooltip,
  helpKey,
  label,
  onChange,
  value,
  isSubmitting,
  isLoading,
  errors,
  preHelperComponents,
  postHelperComponents,
  helperText,
  helpIconProps,
  size,
}) => {
  value = value === undefined || value === null ? [{ name: "", price: "" }] : value;
  const [modifierItems, setModifierItems] = useState(value);

  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect");

  // Event Handlers
  const handleModifierItemChange = (namee, valuee, index) => {
    if (namee === "name") {
      modifierItems.splice(index, 1, {
        id: modifierItems[index].id,
        name: valuee,
        otherName: modifierItems[index].otherName,
        price: modifierItems[index].price,
      });
      typeof onChange === "function" && onChange(name, modifierItems);
    }
    if (namee === "otherName") {
      modifierItems.splice(index, 1, {
        id: modifierItems[index].id,
        otherName: valuee,
        name: modifierItems[index].name,
        price: modifierItems[index].price,
      });
      typeof onChange === "function" && onChange(name, modifierItems);
    } else if (namee === "price") {
      modifierItems.splice(index, 1, {
        id: modifierItems[index].id,
        name: modifierItems[index].name,
        otherName: modifierItems[index].otherName,
        price: valuee,
      });
      typeof onChange === "function" && onChange(name, modifierItems);
    }
    setModifierItems(modifierItems);
  };

  const handleDelete = (key) => {
    modifierItems.splice(key, 1);
    setModifierItems([...modifierItems]);
    onChange(name, modifierItems);
  };
  // Other

  // Component Render

  return (
    <>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Typography variant="body1" component="h2" style={{ marginTop: theme.spacing(1) }}>
          <LocalizedMessage id="item.modifierItems" />
        </Typography>

        <Button
          size="small"
          onClick={() => setModifierItems([...modifierItems, { name: "", price: "" }])}
          startIcon={<AddIcon style={{ margin: 5 }} />}
        >
          <LocalizedMessage id="item.addModifierItem" />
        </Button>
      </Box>
      <Divider style={{ marginTop: 10, marginBottom: 10 }} />

      {/* <Grid
        container
        spacing={2}
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
        }}
      > */}
      {modifierItems.length ? (
        modifierItems.map((modifierItem, index) => (
          <Grid
            container
            key={index}
            spacing={2}
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <Grid item xs={6} md={5}>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Item Full Name"}
                helpKey="Menu.items.NameField"
                // maxCharacters={50}
                onChange={(name, value) => handleModifierItemChange(name, value, index)}
                value={modifierItem.name}
                // errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={6} md={5}>
              <TextField
                label={<LocalizedMessage id="common.otherName" />}
                name="otherName"
                autoComplete="otherName"
                tooltip={"Item Full otherName"}
                helpKey="Menu.items.otherNameField"
                // maxCharacters={50}
                onChange={(name, value) => handleModifierItemChange(name, value, index)}
                value={modifierItem.otherName}
                // errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <TextField
                label={<LocalizedMessage id="common.price" />}
                name="price"
                autoComplete="price"
                tooltip={"Items price"}
                helpKey="Menu.items.priceField"
                // maxCharacters={50}
                onChange={(name, value) => handleModifierItemChange(name, value, index)}
                value={modifierItem.price}
                // errors={formErrors.price}
                isSubmitting={isSubmitting}
                isLoading={isLoading}
              />
            </Grid>
            {/* <Grid item xs={12} md={2}>
              <Button
                fullWidth
                disabled={modifierItems.length === 1}
                variant="outlined"
                onClick={() => handleDelete(index)}
                color="secondary"
              >
                <LocalizedMessage id="general.dropdown.remove" />
              </Button>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography variant="body1" component="h2">
          <LocalizedMessage id="item.itemModifier.noModifierItems" />
        </Typography>
      )}
      {/* </Grid> */}

      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                    <span ref={helperTextRef}>{helperText}</span>
                  ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}

        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </>
  );
};

ModifierItemField.propTypes = {
  /**
   *
   */
};

ModifierItemField.defaultProps = {
  options: Array(),
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
};

export default ModifierItemField;
