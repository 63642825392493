// React
import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { Skeleton } from "@material-ui/lab";

// Globals

// Helpers

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name useSkeleton
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const useSkeleton = (variant, estimatedHeight, estimatedWidth, force) => {
  const objectRef = useRef();
  const [objectSkeletonDimensions, setObjectSkeletonDimensions] = useState({});

  useEffect(() => {
    if (objectRef.current) {
      if (objectRef.current.localName === "img") {
        setObjectSkeletonDimensions({
          height: estimatedHeight,
          width: estimatedWidth,
        });
        objectRef.current.onload = (e) => {
          const { width, height } = e.target;
          setObjectSkeletonDimensions({
            height: (estimatedHeight * height) / width,
            width: estimatedWidth,
          });
        };
      } else if (force) {
        setObjectSkeletonDimensions({
          height: estimatedHeight,
          width: estimatedWidth,
        });
      }
      else {
        const { offsetWidth, offsetHeight } = objectRef.current;

        setObjectSkeletonDimensions({
          height: offsetHeight,
          width: offsetWidth,
        });
      }
    }
  }, [objectRef]);

  return [
    objectSkeletonDimensions.height ? (
      <Skeleton
        animation="wave"
        variant={variant}
        style={objectSkeletonDimensions}
      />
    ) : undefined,
    objectRef,
  ];
};

export default useSkeleton;
