// React
import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// Material
import {
  Chip,
  Toolbar,
  useTheme,
  useMediaQuery,
  Button,
  CircularProgress,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import GetAppIcon from "@material-ui/icons/GetApp";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import StorageIcon from "@material-ui/icons/Storage";
import DoneIcon from "@material-ui/icons/Done";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";
import { getReportDate, getDateDurations } from "Utils";
import {
  displaySnackbarFormErrors,
  displaySnackbarErrors,
} from "Utils/displaySnackbarErrors";

// Components
import { DatePicker, SelectField } from "Components/FormFields";
import App from "../PDFViewer/App";
import CategoriesAndItemsDialog from "../CategoriesAndItemsDialog";
import Memo from "Components/Memo";
import ItemRow from "./ItemRow";
import Row from "../Row";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import _ from "lodash";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";
import moment from "moment";
import numeral from "numeral";

// Services
import { useGetSalesByItem, useGetSalesByItemPdf } from "Services/Reports/";
import {
  useGetAllCategoriesAndItemsDropdowns,
  useGetAllSalesSectionsDropdowns,
} from "Services/Dropdowns";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 660,
    scroll: "auto",
  },
}));

// Ad-Hoc Components
const columns = [
  // {
  //   id: "salessection",
  //   label: <LocalizedMessage id="report.salesChannel" />,
  //   minWidth: 200,
  //   format: false,
  // },
  // {
  //   id: "category",
  //   label: <LocalizedMessage id="common.category" />,
  //   minWidth: 200,
  //   format: false,
  // },
  {
    id: "item",
    label: <LocalizedMessage id="common.item" />,
    minWidth: 200,
    format: () => "",
  },
  {
    id: "price",
    label: "",
    minWidth: 200,
    format: false,
  },
  {
    id: "quantity",
    label: <LocalizedMessage id="common.quantity" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "totalSales",
    label: <LocalizedMessage id="report.totalsales" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "discount",
    label: <LocalizedMessage id="report.discount" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "tax",
    label: <LocalizedMessage id="common.tax" />,
    minWidth: 200,
    format: false,
  },

  {
    id: "netSales",
    label: <LocalizedMessage id="report.netsales" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "percentage",
    label: <LocalizedMessage id="common.percentage" />,
    minWidth: 200,
    format: false,
  },
];

const secondaryColumns = [
  // {
  //   id: "salessection",
  //   label: <LocalizedMessage id="report.salesChannel" />,
  //   minWidth: 200,
  //   format: false,
  // },
  // {
  //   id: "category",
  //   label: <LocalizedMessage id="common.category" />,
  //   minWidth: 200,
  //   format: false,
  // },
  {
    id: "item",
    label: <LocalizedMessage id="common.item" />,
    minWidth: 200,
    format: () => "",
  },
  {
    id: "price",
    label: <LocalizedMessage id="common.price" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "quantity",
    label: <LocalizedMessage id="common.quantity" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "totalSales",
    label: <LocalizedMessage id="report.totalsales" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "discount",
    label: <LocalizedMessage id="report.discount" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "tax",
    label: <LocalizedMessage id="common.tax" />,
    minWidth: 200,
    format: false,
  },

  {
    id: "netSales",
    label: <LocalizedMessage id="report.netsales" />,
    minWidth: 200,
    format: false,
  },
  {
    id: "percentage",
    label: <LocalizedMessage id="common.percentage" />,
    minWidth: 200,
    format: false,
  },
];

const validationSchema = Joi.object({
  dateDuration: Joi.string()
    .valid(
      "today",
      "yesterday",
      "thisWeek",
      "lastWeek",
      "thisMonth",
      "lastMonth",
      "thisYear",
      "lastYear",
      "custom"
    )
    .required(),
  dateFrom: Joi.date(),
  dateTo: Joi.date().min(Joi.ref("dateFrom")),
  categories: Joi.array().items(),
  items: Joi.array().items(),
  salesSections: Joi.array().items(Joi.string().uuid()),
});

/**
 * @name SalesByItem
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SalesByItem = ({ permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [salesSections, setSalesSections] = useState([]);
  const [isFetchingSalesSections, setIsFetchingSalesSections] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [rows, setRows] = useStateRef([]);
  const [footerRow, setFooterRow] = useStateRef([]);

  const [isFetchingRows, setIsFetchingRows] = React.useState(false);
  const [isFetchingPdf, setIsFetchingPdf] = React.useState(false);
  const [pdf, setPdf] = useState("");
  const [PDFViewerOpen, setPDFViewerOpen] = useState(false);
  const [categoriesAndItemsModalOpen, setCategoriesAndItemsModalOpen] =
    useState(false);
  const [categoriesAndItemsData, setCategoriesAndItemsData] = useState([]);
  const [isFetchingCategoriesAndItems, setIsFetchingCategoriesAndItems] =
    useState(true);
  const [hasPdfAccess, setHasPdfAccess] = useState(false);
  const [hasTableAccess, setHasTableAccess] = useState(false);
  const [spaningData, setSpaningData] = useState([]);

  // Effect Hooks
  useEffect(() => {
    getAllSalesSections()
      .then((data) => {
        setSalesSections(data);
        setIsFetchingSalesSections(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingSalesSections(false);
      });
  }, []);

  useEffect(() => {
    getData()
      .then((data) => {
        setCategoriesAndItemsData(data);
        setIsFetchingCategoriesAndItems(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingCategoriesAndItems(false);
      });
  }, []);

  // Other Hooks
  const intl = useIntl();
  const getSalesByItem = useGetSalesByItem();
  const getSalesByItemPdf = useGetSalesByItemPdf();
  const getAllSalesSections = useGetAllSalesSectionsDropdowns();
  const getData = useGetAllCategoriesAndItemsDropdowns();
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const { enqueueSnackbar } = useSnackbar();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isLoading,
  } = useForm({ validationSchema, languageKey: "report" });

  useEffect(() => {
    const { from, to } = getReportDate("today", "", "");
    setFormData({
      dateDuration: "today",
      dateFrom: from,
      dateTo: to,
    });
  }, []);

  useEffect(() => {
    if (formData.dateDuration === "today") handleSearch();
  }, [formData.dateDuration]);

  useEffect(() => {
    if (formData.dateDuration) handleSearch();
  }, [PDFViewerOpen]);
  useEffect(() => {
    if (permissions.isAdmin) {
      setHasPdfAccess(true);
      setHasTableAccess(true);
    } else {
      if (permissions.reportSalesByItem.includes("pdf")) setHasPdfAccess(true);
      if (permissions.reportSalesByItem.includes("get"))
        setHasTableAccess(true);
    }
  }, []);

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearch = () => {
    const validForm = validateForm();

    if (validForm) {
      setIsFetchingRows(true);
      setIsFetchingPdf(true);
      setPdf("");
      setRows([]);

      let { dateDuration, dateFrom, dateTo, salesSections, categories, items } =
        formData;

      const { from, to } = getReportDate(dateDuration, dateFrom, dateTo);
      setFormData({ ...formData, dateFrom: from, dateTo: to });

      if (hasTableAccess && !PDFViewerOpen)
        getSalesByItem({
          dateFrom: from,
          dateTo: to,
          salesSections,
          categories: categories?.map((category) => category.id),
          items: items?.map((item) => item.id),
        })
          .then((response) => {
            const groups = response.data.reduce(
              (groups, item) => ({
                ...groups,
                [item.category]: {
                  data: [...(groups[item.category]?.data || []), item],
                },
              }),
              {}
            );

            const round = (number, decimals = 3) => {
              if (number) return parseFloat(number.toFixed(decimals));
              else return number;
            };

            Object.entries(groups).forEach(([category, properties], index) => {
              columns.map((column) => {
                const columnsSum = properties.data.reduce(
                  (acc, item) => ({
                    ...acc,
                    [column.id]:
                      typeof item[column.id] === "number"
                        ? (acc[column.id] || 0) + item[column.id]
                        : undefined,
                  }),
                  {}
                );
                console.log("columnsSum[column.id]", columnsSum[column.id]);

                groups[category][column.id] = round(columnsSum[column.id]);
                groups[category]["price"] = " ";
                groups[category]["item"] = " ";
              });
            });

            let newSpaningData = {};
            if (response.data[0]) {
              const cols = Object.keys(response.data[0]);
              cols.map((column) => (newSpaningData[column] = 0));
            }

            response.data.map((key) =>
              Object.entries(key).map((k) => {
                newSpaningData[k[0]] = newSpaningData[k[0]] + k[1];
              })
            );
            delete newSpaningData.category;
            delete newSpaningData.item;
            delete newSpaningData.salessection;
            delete newSpaningData.percentage;

            setSpaningData(newSpaningData);
            setRows(groups);
            setFooterRow(response.footer);
            setIsFetchingRows(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else {
        setPDFViewerOpen(true);
        setIsFetchingRows(false);
      }

      if (hasPdfAccess && PDFViewerOpen)
        getSalesByItemPdf({
          dateFrom: from,
          dateTo: to,
          salesSections,
          categories: categories?.map((category) => category.id),
          items: items?.map((item) => item.id),
        })
          .then((response) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const objectUrl = window.URL.createObjectURL(blob);
            setPdf(objectUrl);
            setIsFetchingPdf(false);
          })
          .catch((errors) => {
            displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors);
            setIsFetchingRows(false);
            setIsFetchingPdf(false);
          });
      else setIsFetchingPdf(false);
    }
  };

  // Other
  const dateDurations = getDateDurations(intl);

  const salesSectionsFiltersTags = [];
  salesSections.forEach((ss) => {
    if (formData.salesSections)
      formData.salesSections.forEach((salesSectionId) => {
        if (ss.id === salesSectionId) salesSectionsFiltersTags.push(ss.name);
      });
  });

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant="h6"
            className={classes.heading}
            style={{ fontSize: "1rem" }}
          >
            <LocalizedMessage id="report.reportFilters" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box display="flex" flexDirection={downSm ? "column" : "row"}>
            <Box
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
              }}
            >
              <SelectField
                neglectNone
                fullWidth
                name="dateDuration"
                tooltip={"Date Duration"}
                helpKey="Menu.category.dateDuration"
                label={<LocalizedMessage id="report.dateDuration.title" />}
                onChange={onFormFieldChange}
                value={formData.dateDuration}
                errors={formErrors.dateDuration}
                isSubmitting={isFetchingRows || isFetchingPdf}
                isLoading={isLoading.dateDuration}
                options={dateDurations}
                defaultValue="today"
              />
              {formData.dateDuration === "custom" && (
                <>
                  <DatePicker
                    name="dateFrom"
                    tooltip={"Date from"}
                    helpKey="common.dateFrom"
                    label={intl.formatMessage({ id: "common.dateFrom" })}
                    onChange={onFormFieldChange}
                    value={formData.dateFrom}
                    errors={formErrors.dateFrom}
                    isSubmitting={isFetchingRows || isFetchingPdf}
                    isLoading={isLoading.dateFrom}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                  />
                  <DatePicker
                    name="dateTo"
                    tooltip={"Date from"}
                    helpKey="common.dateTo"
                    label={intl.formatMessage({ id: "common.dateTo" })}
                    onChange={onFormFieldChange}
                    value={formData.dateTo}
                    errors={formErrors.dateTo}
                    isSubmitting={isFetchingRows || isFetchingPdf}
                    isLoading={isLoading.dateTo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="dense"
                  />
                </>
              )}
            </Box>

            <Box
              style={{
                flex: 1,
                padding: downSm ? 0 : theme.spacing(0, 2),
                minWidth: 0,
              }}
            >
              <SelectField
                fullWidth
                name="salesSections"
                tooltip={"Sales Channels"}
                helpKey="common.salesSections"
                label={<LocalizedMessage id="setup.salesChannel.pageTitle" />}
                onChange={onFormFieldChange}
                value={formData.salesSections}
                errors={formErrors.salesSections}
                isSubmitting={isFetchingRows || isFetchingPdf}
                isLoading={isLoading.salesSections}
                options={salesSections.map((s) => ({
                  value: s.id,
                  text: s.name,
                }))}
                multiple
              />
              <CategoriesAndItemsDialog
                open={categoriesAndItemsModalOpen}
                setOpen={setCategoriesAndItemsModalOpen}
                formData={formData}
                setFormData={setFormData}
                categoriesAndItemsData={categoriesAndItemsData}
              />
              <Box margin={"16px 0px"}>
                <Button
                  variant="outlined"
                  disabled={
                    isFetchingCategoriesAndItems ||
                    isFetchingRows ||
                    isFetchingPdf
                  }
                  onClick={() => setCategoriesAndItemsModalOpen(true)}
                  style={{ width: "100%", padding: "14px" }}
                >
                  <LocalizedMessage id="export.chooseItems" />
                </Button>
              </Box>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end">
            <Button
              type="button"
              variant="contained"
              color="primary"
              disabled={isFetchingRows || isFetchingPdf}
              onClick={handleSearch}
              style={{ margin: "16px" }}
              startIcon={!isFetchingRows && !isFetchingPdf && <DoneIcon />}
            >
              {isFetchingRows || isFetchingPdf ? (
                <CircularProgress size={20} />
              ) : (
                <LocalizedMessage id="general.apply" />
              )}
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Memo deps={[pdf, rows.getState()]}>
        {() => (
          <Toolbar
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              marginTop: 16,
              overflow: "auto",
            }}
          >
            <Chip
              style={{
                margin: 8,
                padding: 8,
                height: "100%",
                color: "white",
                backgroundColor: "#0582D2",
              }}
              label={
                <Typography
                  component="span"
                  variant="body2"
                  style={{ whiteSpace: "normal" }}
                >
                  <LocalizedMessage id="report.dateDuration.title" />
                  {": " +
                    dateDurations.filter(
                      (dd) => formData.dateDuration === dd.value
                    )[0]?.text}
                </Typography>
              }
            />
            {formData.dateTo && (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    {formData.dateTo === formData.dateFrom ? (
                      <>
                        <LocalizedMessage id="report.day" />
                        {": " +
                          moment(formData.dateFrom).format("MMM DD, YYYY")}
                      </>
                    ) : (
                      <>
                        <LocalizedMessage id="common.dateFrom" />
                        {": " +
                          moment(formData.dateFrom).format("MMM DD, YYYY")}
                        <LocalizedMessage id="common.dateTo" />
                        {": " + moment(formData.dateTo).format("MMM DD, YYYY")}
                      </>
                    )}
                  </Typography>
                }
              />
            )}
            {/* {formData.categories?.length ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="common.category" />
                    {": " +
                      formData.categories
                        .map((category) => category.name)
                        .join(", ")}
                  </Typography>
                }
              />
            ) : null} */}
            {formData.items?.length ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="item.pageTitle" />
                    {": " + formData.items.map((item) => item.name).join(", ")}
                  </Typography>
                }
              />
            ) : null}
            {salesSectionsFiltersTags.length ? (
              <Chip
                style={{
                  margin: 8,
                  padding: 8,
                  height: "100%",
                  color: "white",
                  backgroundColor: "#0582D2",
                }}
                label={
                  <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                    <LocalizedMessage id="setup.salesChannel.pageTitle" />
                    {": " + salesSectionsFiltersTags.join(", ")}
                  </Typography>
                }
              />
            ) : null}
          </Toolbar>
        )}
      </Memo>

      {hasPdfAccess && (
        <Box display="flex" justifyContent="flex-end">
          <Tooltip
            title={
              PDFViewerOpen ? (
                <LocalizedMessage id="report.showTable" />
              ) : (
                <LocalizedMessage id="report.showPDFViewer" />
              )
            }
          >
            {downSm ? (
              <Button
                style={{ flex: 1 }}
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => setPDFViewerOpen((prevState) => !prevState)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {PDFViewerOpen ? (
                  <>
                    <StorageIcon style={{ marginRight: 8 }} />
                    <LocalizedMessage id="report.showTable" />
                  </>
                ) : (
                  <>
                    <PictureAsPdfIcon style={{ marginRight: 8 }} />
                    <LocalizedMessage id="report.showPDFViewer" />
                  </>
                )}
              </Button>
            ) : (
              <IconButton
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => setPDFViewerOpen((prevState) => !prevState)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {PDFViewerOpen ? <StorageIcon /> : <PictureAsPdfIcon />}
                <Typography variant="body2" component="h2"></Typography>
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title={<LocalizedMessage id="report.downloadPDF" />}>
            {downSm ? (
              <Button
                style={{ flex: 1 }}
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => window.open(pdf)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                <GetAppIcon style={{ marginRight: 8 }} />
                <LocalizedMessage id="report.downloadPDF" />
              </Button>
            ) : (
              <IconButton
                component={isFetchingRows || isFetchingPdf ? "div" : undefined}
                onClick={
                  isFetchingRows || isFetchingPdf
                    ? undefined
                    : () => window.open(pdf)
                }
                disabled={isFetchingRows || isFetchingPdf}
              >
                {<GetAppIcon />}
                <Typography variant="body2" component="h2"></Typography>
              </IconButton>
            )}
          </Tooltip>
        </Box>
      )}

      {(isFetchingRows || isFetchingPdf) && inputSkeleton ? (
        inputSkeleton
      ) : PDFViewerOpen && pdf ? (
        <Box>
          <App downSm={downSm} url={pdf} />
        </Box>
      ) : (
        <Paper
          style={{
            marginTop: theme.spacing(2),
          }}
        >
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.id !== "item" ? column.label : null}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {rows.getState() ? (
                  <TableBody>
                    {Object.entries(rows.getState()).map(
                      ([key, groupedRows]) => {
                        return (
                          <ItemRow
                            key={key}
                            header={key}
                            row={groupedRows}
                            columns={columns}
                            secondaryColumns={secondaryColumns}
                          />
                        );
                      }
                    )}
                    {Object.keys(rows.getState()).length ? (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={"footerRow"}
                      >
                        {footerRow.getState()
                          ? footerRow.getState().map((row, index) => (
                              <TableCell
                                key={index + "-footer"}
                                style={{ fontWeight: "bold" }}
                              >
                                {row}
                              </TableCell>
                            ))
                          : null}
                      </TableRow>
                    ) : null}
                  </TableBody>
                ) : null}
              </Table>
            </TableContainer>
          </Box>
        </Paper>
      )}
    </Box>
  );
};

SalesByItem.propTypes = {
  /**
   *
   */
};

SalesByItem.defaultProps = {
  /**
   *
   */
};

export default SalesByItem;
