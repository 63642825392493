/**
 * @module Help
 * @description
 * > Provides help markdown
 * @category Globals
 */
const axios = require("axios").default

/**
 * @name getHelp
 * @summary getHelp Function
 * @constant
 * @type {function}
 */
const getHelp = async (key, locale) => {
  let MarkdownUrl
  try {
    MarkdownUrl = (await import("./" + key.split(".").join("/") + "/" + locale))
      .Markdown
  } catch (e) {
    console.error("errorr", e)
  }
  if (MarkdownUrl) {
    const { data, status } = await axios.get(MarkdownUrl)
    return [data, status === 200]
  } else {
    return ["", false]
  }
}

export default getHelp
