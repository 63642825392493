// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { Tabs, useTheme, Tab, Paper, useMediaQuery } from "@material-ui/core";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ReceiptIcon from "@material-ui/icons/Receipt";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import Title from "Components/Title";
import { useGlobalState } from "Helpers/GlobalState";

// Components

// Factories

// Screens
import { Receipt } from "Screens/Portal/Receipt";
import { XReport } from "Screens/Portal/XReport";
import { ZReport } from "Screens/Portal/ZReport";

// Assets

// Third Parties
import SwipeableViews from "react-swipeable-views";
import { useSnackbar } from "notistack";

// Services

// Styles
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
}));
// Ad-Hoc Components

/**
 * @name PrintersDesign
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const PrintersDesign = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [value, setValue] = useState(
    permissions.isAdmin || permissions.receiptDesign
      ? 0
      : permissions.xReportDesign
      ? 1
      : 2
  );

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // Other
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <div
      style={{
        display: "flex",
        flexDirection: downSm ? "column" : "row",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.printersDesign.pageTitle"
            key="setup.printersDesign.pageTitle"
          />
        }
      />
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="action tabs example"
        variant="scrollable"
        scrollButtons="on"
        orientation={downSm ? "horizontal" : "vertical"}
      >
        <Tab
          label={
            <LocalizedMessage id={"setup.printersDesign.receipt.pageTitle"} />
          }
          disabled={!(permissions.isAdmin || permissions.receiptDesign)}
          icon={<ReceiptIcon />}
        />
        <Tab
          label={
            <LocalizedMessage id={"setup.printersDesign.xReport.pageTitle"} />
          }
          disabled={!(permissions.isAdmin || permissions.xReportDesign)}
          icon={<AssessmentIcon />}
        />
        <Tab
          label={
            <LocalizedMessage id={"setup.printersDesign.zReport.pageTitle"} />
          }
          disabled={!(permissions.isAdmin || permissions.zReportDesign)}
          icon={<AssignmentTurnedInIcon />}
        />
      </Tabs>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
        style={{
          flex: 1,
        }}
      >
        <Paper
          key={0}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.receiptDesign) && (
            <Receipt permissions={permissions} />
          )}
        </Paper>
        <Paper
          key={1}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.xReportDesign) && (
            <XReport permissions={permissions} />
          )}
        </Paper>
        <Paper
          key={2}
          dir={theme.direction}
          style={{
            padding: theme.spacing(2),
          }}
          elevation={0}
          square
        >
          {(permissions.isAdmin || permissions.zReportDesign) && (
            <ZReport permissions={permissions} />
          )}
        </Paper>
      </SwipeableViews>
    </div>
  );
};

PrintersDesign.propTypes = {
  /**
   *
   */
};

PrintersDesign.defaultProps = {
  /**
   *
   */
};

export default PrintersDesign;
