import { useAxios } from "Helpers/Hooks";
import _ from "lodash";

const useGetBranch = () => {
  const axios = useAxios({ api: true });

  return () =>
    new Promise(function (resolve, reject) {
      axios
        .get("/wizard/branch")
        .then((response) => {
          resolve({
            name: response.data.data.name,
            otherName: response.data.data.otherName,
            currencyId: response.data.data.currencyId,
            countryId: response.data.data.countryId,
            city: response.data.data.city,
            timezone: response.data.data.timezone,
            phoneNumbers: response.data.data.phoneNumbers,
            location: response.data.data.location,
          });
        })
        .catch((error) => {
          let endErrors = [];
          if (error.response) {
            const { status } = error.response;
            if (status === 403) {
              endErrors.push({
                message: "Authentication error",
                languageKey: "errors.authentication",
              });
            } else if (status === 401) {
              endErrors.push({
                message: "Authorization error",
                languageKey: "errors.authorization",
              });
            } else if (status === 404) {
              endErrors.push({
                message: "Data not found",
                languageKey: "errors.data.not.found",
              });
            } else if (status === 500) {
              endErrors.push({
                message: "Server error",
                languageKey: "errors.server.error",
              });
            }
          } else if (error.request) {
            endErrors.push({
              message: "Server did not respond",
              languageKey: "errors.server.no.response",
            });
          } else {
            endErrors.push({
              message: "Network or Browser error",
              languageKey: "errors.network.error",
            });
          }

          reject(endErrors);
        });
    });
};

export default useGetBranch;
