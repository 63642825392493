// React
import React, { useState, useEffect } from "react";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useStateRef from "Helpers/useStateRef";
import useSkeleton from "Helpers/useSkeleton";
import { useGlobalState } from "Helpers/GlobalState";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";

// Components
import { TextField } from "Components/FormFields";
import Title from "Components/Title";

// Factories

// Screens
import EmployeeForm from "./EmployeeForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";

// Services
import {
  useActivateEmployee,
  useDeactivateEmployee,
} from "Services/Employees/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "accessId",
    label: <LocalizedMessage id="setup.employee.accessId" />,
    minWidth: 50,
  },
  {
    id: "status",
    label: <LocalizedMessage id="common.status" />,
    minWidth: 100,
  },
  { id: "actions", label: "", minWidth: 200 },
];

/**
 * @name Employees
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Employees = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [employees, setEmployees] = useGlobalState("employees.employees");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [openSearchField, setOpenSearchField] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isActivating, setIsActivating] = useStateRef(false);
  const [editId, setEditId] = useState("");

  /* Employee Form */
  const [employeeFormOpen, setEmployeeFormOpen] = useState(false);
  const [employeeFormMode, setEmployeeFormMode] = useState("");
  const [employeeFormData, setEmployeeFormData] = useState({});

  // Effect Hooks
  useEffect(() => {
    setRows(employees.data.map((el) => createData(el)));
  }, [employees]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const activateEmployee = useActivateEmployee({
    languageKey: "setup.employee",
  });
  const deactivateEmployee = useDeactivateEmployee({
    languageKey: "setup.employee",
  });

  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddEmployeeForm = () => {
    setEmployeeFormData({});
    setEmployeeFormMode("add");
    setEmployeeFormOpen(true);
  };

  const onEditClick = (employee) => {
    setEditId(employee.id);
    setEmployeeFormData(employee);
    setEmployeeFormMode("edit");
    setEmployeeFormOpen(true);
  };

  const onActivateClick = (employee) => {
    setIsActivating(true);
    const index = employees.data.findIndex((row) => row.id === employee.id);
    employees.data.splice(index, 1, { ...employee, isActive: false });
    setEmployees({ ...employees });

    activateEmployee(employee.id)
      .then((response) => {
        enqueueSnackbar(
          <LocalizedMessage id="setup.employee.active.success" />,
          {
            variant: "success",
            autoHideDuration: 5000,
          }
        );
        const index = employees.data.findIndex((row) => row.id === employee.id);
        setIsActivating(false);
        employees.data.splice(index, 1, { ...employee, isActive: true });
        setEmployees({ ...employees });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const onDeactivateClick = (employee) => {
    setIsActivating(true);
    const index = employees.data.findIndex((row) => row.id === employee.id);
    employees.data.splice(index, 1, { ...employee, isActive: true });
    setEmployees({ ...employees });

    deactivateEmployee(employee.id)
      .then((response) => {
        enqueueSnackbar(
          <LocalizedMessage id="setup.employee.deactive.success" />,
          {
            variant: "success",
            autoHideDuration: 5000,
          }
        );
        const index = employees.data.findIndex((row) => row.id === employee.id);
        setIsActivating(false);
        employees.data.splice(index, 1, { ...employee, isActive: false });
        setEmployees({ ...employees });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };
  const addCallback = (response) => {
    setEmployees({
      ...employees,
      data: [...employees.data, response.data.data],
    });
  };

  const editCallback = (response) => {
    const index = employees.data.findIndex((row) => row.id === editId);
    employees.data.splice(index, 1, response.data.data);
    setEmployees({ ...employees });
  };

  const createData = (employee) => {
    return {
      id: employee.id,
      name: employee.name,
      accessId: employee.accessId,
      status: employee.isActive ? (
        <Chip
          label={<LocalizedMessage id="general.active" />}
          color="primary"
        />
      ) : (
        <Chip
          label={<LocalizedMessage id="general.inActive" />}
          style={{ color: "white", backgroundColor: "#0582D2" }}
        />
      ),
      actions: (
        <Box>
          {employee.isActive
            ? (permissions.isAdmin ||
                permissions.employee.includes("deactivate")) && (
                <Tooltip title={<LocalizedMessage id="common.deactivate" />}>
                  <IconButton
                    component={isActivating.getState() ? "div" : undefined}
                    onClick={
                      isActivating.getState()
                        ? undefined
                        : () => onDeactivateClick(employee)
                    }
                    disabled={isActivating.getState()}
                    aria-label="deactivate"
                    //onClick={() => onDeactivateClick(employee)}
                  >
                    <NotInterestedIcon />
                  </IconButton>
                </Tooltip>
              )
            : (permissions.isAdmin ||
                permissions.employee.includes("activate")) && (
                <Tooltip title={<LocalizedMessage id="common.activate" />}>
                  <IconButton
                    component={isActivating.getState() ? "div" : undefined}
                    onClick={
                      isActivating.getState()
                        ? undefined
                        : () => onActivateClick(employee)
                    }
                    disabled={isActivating.getState()}
                    aria-label="Activate"
                    //onClick={() => onActivateClick(employee)}
                  >
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
              )}
          {(permissions.isAdmin || permissions.employee.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton
                aria-label="edit"
                onClick={() => onEditClick(employee)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
    };
  };

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.employee.pageTitle"
            key="setup.employee.pageTitle"
          />
        }
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.employee.pageTitle" />
        </Typography>

        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.employee.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            disabled={employees.isFetchingRows}
            component={employees.isFetchingRows ? "div" : undefined}
            onClick={employees.isFetchingRows ? undefined : openAddEmployeeForm}
            color="primary"
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        <EmployeeForm
          open={employeeFormOpen}
          setOpen={setEmployeeFormOpen}
          mode={employeeFormMode}
          editFormData={employeeFormData}
          permissions={permissions}
          addCallback={addCallback}
          editCallback={editCallback}
          editId={editId}
          editCallback={editCallback}
          addCallback={addCallback}
        />
        {employees.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.accessId
                              .toLowerCase()
                              .indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === "" ||
                            row.name
                              .toLowerCase()
                              .indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                        >
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

Employees.propTypes = {
  /**
   *
   */
};

Employees.defaultProps = {
  /**
   *
   */
  setHeading: () => {},
};

export default Employees;
