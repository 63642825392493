// React
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Button,
  Divider,
  Typography,
  Popover,
  useTheme,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { fade } from "@material-ui/core/styles/colorManipulator";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import MinimizeIcon from "@material-ui/icons/Minimize";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import grey from "@material-ui/core/colors/grey";

// Globals

// Helpers
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { useIntl } from "react-intl";
import numeral from "numeral";
import randomcolor from "randomcolor";

// Services
import { useGetHourlySales } from "Services/Dashboard/";

// Styles

// Ad-Hoc Components

/**
 * @name HourlySalesBarChart
 * @summary
 * @category
 * @component
 * @description
 * >
 */

const HourlySalesBarChart = ({ style, dateFrom, dateTo }) => {
  // Theme & Style Hooks
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [ordersVsItemsData, setOrdersVsItemsData] = useState({
    orders: 0,
    itemsSold: 0,
    ordersTrend: 0,
    itemsTrend: 0,
  });
  const [chartDimensions, setChartDimensions] = useState({
    height: 0,
    width: 0,
  });
  const [HourlySalesBarChartData, setHourlySalesBarChartData] = useState([]);

  const chartContainer = useRef({ clientHeight: 0, clientWidth: 0 });
  // const chartContainerWidth = useRef(chartContainer.current.clientWidth)

  // Effect Hooks

  useEffect(() => {
    // new ResizeSensor(document.querySelector("#chartContainer"), function () {
    //   console.log("Resize", chartContainer.current.clientWidth);
    //   setChartDimensions((cd) => ({
    //     height: cd.height || chartContainer.current.clientHeight,
    //     width: chartContainer.current.clientWidth,
    //   }));
    // });
  }, []);

  useEffect(() => {
    if (chartContainer.current) {
      setChartDimensions((cd) => ({
        height: cd.height || chartContainer.current.clientHeight,
        width: cd.width || chartContainer.current.clientWidth,
      }));
    }
  }, [chartContainer]);

  useEffect(() => {
    getHourlySales(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    )
      .then((response) => {
        setHourlySalesBarChartData(response);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, [dateFrom, dateTo]);
  //   useEffect(() => {
  //     console.log(chartContainer.current);
  //     setChartDimensions((cd) => ({
  //       height: cd.height || chartContainer.current.clientHeight,
  //       width: chartContainer.current.clientWidth,
  //     }));
  //   }, [chartContainerWidth.current]);

  // Other Hooks
  const getHourlySales = useGetHourlySales();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  // Event Handlers

  // Other
  const data = (theme) => {
    return {
      labels: HourlySalesBarChartData.map((hour) => {
        return hour.hour;
      }),
      datasets: [
        {
          label: intl.formatMessage({ id: "report.netsales" }),
          backgroundColor: fade(theme.palette.primary.main, 0.5),
          borderColor: fade(theme.palette.primary.light, 0.7),
          data: HourlySalesBarChartData.map((hour) => {
            return hour.netsales;
          }),
          borderWidth: 1,
        },
        {
          label: intl.formatMessage({ id: "report.count" }),
          backgroundColor: fade(theme.palette.secondary.main, 0.5),
          borderColor: fade(theme.palette.secondary.light, 0.7),
          data: HourlySalesBarChartData.map((hour) => {
            return hour.count;
          }),
          borderWidth: 1,
        },
      ],
      // datasets: [
      //   {
      //     label: "Hour",
      //     data: [12, 19, 3, 5, 2, 3],
      //     backgroundColor: [
      //       "rgba(255, 99, 132, 0.2)",
      //       "rgba(54, 162, 235, 0.2)",
      //       "rgba(255, 206, 86, 0.2)",
      //       "rgba(75, 192, 192, 0.2)",
      //       "rgba(153, 102, 255, 0.2)",
      //       "rgba(255, 159, 64, 0.2)",
      //     ],
      //     borderColor: [
      //       "rgba(255, 99, 132, 1)",
      //       "rgba(54, 162, 235, 1)",
      //       "rgba(255, 206, 86, 1)",
      //       "rgba(75, 192, 192, 1)",
      //       "rgba(153, 102, 255, 1)",
      //       "rgba(255, 159, 64, 1)",
      //     ],
      //     borderWidth: 2,
      //   },
      // ],
    };
  };

  // const data = (theme) => ({
  //   labels: HourlySalesBarChartData.map((v, key) => {
  //     const d = moment(dateFrom).add(key, "days");
  //     return d.format("MMM DD, YYYY");
  //   }),
  //   datasets: [
  //     {
  //       label: intl.formatMessage({ id: "dashboard.orders" }),
  //       backgroundColor: fade(theme.palette.primary.main, 0.5),
  //       borderColor: fade(theme.palette.primary.light, 0.7),
  //       borderWidth: 1,
  //       data: HourlySalesBarChartData.map((v, key) => {
  //         return v.orderscount;
  //       }),
  //     },
  //     {
  //       label: intl.formatMessage({ id: "dashboard.itemsSold" }),
  //       backgroundColor: fade(theme.palette.secondary.main, 0.5),
  //       borderColor: fade(theme.palette.secondary.light, 0.7),
  //       borderWidth: 1,
  //       data: HourlySalesBarChartData.map((v, key) => {
  //         return v.itemscount;
  //       }),
  //     },
  //   ],
  // });
  // Component Render
  return (
    <Card variant="outlined" style={{ ...style, display: "flex" }}>
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          width: "100%",
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5" component="h2">
            <LocalizedMessage id="dashboard.hourlySales" />
          </Typography>
        </Box>

        <Divider />
        <Box
          style={{ flex: 1, position: "relative", width: "100%" }}
          ref={chartContainer}
          id="chartContainer"
        >
          {chartDimensions.height && (
            <Bar
              {...chartDimensions}
              data={data(Theme)}
              options={{
                maintainAspectRatio: false,
                responsive: true,
                onResize: (ci, ns) => console.log(ci, ns),
                title: {
                  display: false,
                  text: intl.formatMessage({
                    id: "dashboard.ordersVsItemsSold",
                  }),
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: "top",
                },
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

HourlySalesBarChart.propTypes = {
  /**
   *
   */
};

export default HourlySalesBarChart;
