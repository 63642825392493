// React
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// Material
import {
  Toolbar,
  List,
  ListItem,
  Collapse,
  Grow,
  Paper,
  Popper,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  ClickAwayListener,
  MenuList,
  Tooltip,
  withStyles,
  Button,
} from "@material-ui/core";
import DashboardIcon from "@material-ui/icons/Dashboard";
import LiveTvIcon from "@material-ui/icons/LiveTv";
import RestaurantMenuIcon from "@material-ui/icons/RestaurantMenu";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import SettingsIcon from "@material-ui/icons/Settings";
import TabletIcon from "@material-ui/icons/Tablet";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
// import AndroidIcon from "@material-ui/icons/Android"
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ShowChartIcon from "@material-ui/icons/ShowChart";
import BusinessIcon from "@material-ui/icons/Business";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CropFreeIcon from "@material-ui/icons/CropFree";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AppsIcon from "@material-ui/icons/Apps";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import PersonIcon from "@material-ui/icons/Person";
import TuneIcon from "@material-ui/icons/Tune";
import DriveEtaIcon from "@material-ui/icons/DriveEta";
import Alert from "@material-ui/lab/Alert";
import SubscriptionsIcon from "@material-ui/icons/Subscriptions";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import HomeIcon from "@material-ui/icons/Home";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import QRDialog from "Components/QRDialog";
import SubscriptionDialog from "Components/SubscriptionDialog";

// Factories

// Screens
import AppFooter from "./Footer";
import { GettingStarted } from "Screens/Portal/GettingStarted";
import { Dashboard } from "Screens/Portal/Dashboard";
import { Live } from "Screens/Portal/Live";
import { Menu } from "Screens/Portal/Menu";
import { Setup } from "Screens/Portal/Setup";
import { CardsSetup } from "Screens/Portal/CardsSetup";
import { Reports } from "Screens/Portal/Reports";
import { CardsReports } from "Screens/Portal/CardsReports";
import { Branches } from "Screens/Portal/Branches";
import { POS } from "Screens/Portal/POS";
import { DriverLogin } from "Screens/Portal/DriverLogin";
import { Accounts } from "Screens/Portal/Accounts";
import { CRM } from "Screens/Portal/CRM";
import { Sales } from "Screens/Portal/Sales";
import { Roles } from "Screens/Portal/Roles";
import { EditBrand } from "Screens/Portal/EditBrand";
import { EditProfile } from "Screens/Portal/EditProfile";
import { SubscriptionsDetails } from "Screens/Portal/SubscriptionsDetails";
import { DownloadApps } from "Screens/Portal/DownloadApps";
import { StockCapacity } from "Screens/Portal/StockCapacity";
import { Export } from "Screens/Portal/Export";
import { OnlineSetup } from "Screens/Portal/OnlineOrders";

// Assets
import { OnionLogoWithText } from "Assets/Images";

// Services

// Third Parties
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { useSnackbar } from "notistack";

import Layout, {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getCollapseBtn,
  getContent,
  getFooter,
} from "@mui-treasury/layout";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import _ from "lodash";

const drawerWidth = 240;

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);

const SubscriptionAlert = ({
  branch,
  alertOpen,
  setAlertOpen,
  onSubscribeNowClick,
}) => {
  return (
    <Box
      style={{
        position: "fixed",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "9999",
      }}
    >
      {branch.subscription?.subscriptionStatus === "Active" &&
      branch.subscription?.subscriptionPlan === "Trial" ? (
        <Collapse in={alertOpen}>
          <Alert
            variant="filled"
            severity="success"
            // onClick={() => setAlertOpen(false)}
            // onClose={() => {}}
            action={
              <>
                <Button
                  onClick={onSubscribeNowClick}
                  component="label"
                  color="inherit"
                  size="small"
                  style={{
                    marginLeft: "auto",
                  }}
                >
                  <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                </Button>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlertOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              </>
            }
          >
            <LocalizedMessage id="portal.trial" />
            <LocalizedMessage id="portal.active" />
          </Alert>
        </Collapse>
      ) : null}
      {branch.subscription?.subscriptionStatus === "Expired" ? (
        branch.subscription?.subscriptionPlan === "Trial" ? (
          <Collapse in={alertOpen}>
            <Alert
              variant="filled"
              severity="error"
              // onClick={() => setAlertOpen(false)}
              // onClose={() => {}}
              action={
                <>
                  <Button
                    onClick={onSubscribeNowClick}
                    component="label"
                    color="inherit"
                    size="small"
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                  </Button>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => setAlertOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <LocalizedMessage id="portal.trial" />
              <LocalizedMessage id="portal.expired" />
            </Alert>
          </Collapse>
        ) : (
          <Collapse in={alertOpen}>
            <Alert
              variant="filled"
              severity="error"
              onClick={() => setAlertOpen(false)}
              action={
                //   // <Button
                //   //   onClick={(e) => {
                //   //     e.preventDefault();
                //   //     window.open(CHECKOUT_BRANCH_MONTHLY); //to open renew link new page
                //   //   }}
                //   //   component="label"
                //   //   href="blank"
                //   //   color="inherit"
                //   //   size="small"
                //   //   style={{
                //   //     marginLeft: "auto",
                //   //
                //   //   }}
                //   // >
                //   //   Renew Now?
                //   // </Button>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlertOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <LocalizedMessage id="portal.expired" />
            </Alert>
          </Collapse>
        )
      ) : null}
      {branch.subscription?.subscriptionStatus === "InGrace" ? (
        branch.subscription?.subscriptionPlan === "Trial" ? (
          <Collapse in={alertOpen}>
            <Alert
              variant="filled"
              severity="error"
              // onClick={() => setAlertOpen(false)}
              // onClose={() => {}}
              action={
                <>
                  <Button
                    onClick={onSubscribeNowClick}
                    component="label"
                    color="inherit"
                    size="small"
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                  </Button>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => setAlertOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <LocalizedMessage id="portal.trial" />
              <LocalizedMessage id="portal.inGrace" />
            </Alert>
          </Collapse>
        ) : (
          <Collapse in={alertOpen}>
            <Alert
              variant="filled"
              severity="error"
              // onClick={() => setAlertOpen(false)}
              action={
                //   // <Button
                //   //   onClick={(e) => {
                //   //     e.preventDefault();
                //   //     window.open(CHECKOUT_BRANCH_MONTHLY); //to open renew link new page
                //   //   }}
                //   //   component="label"
                //   //   href="blank"
                //   //   color="inherit"
                //   //   size="small"
                //   //   style={{
                //   //     marginLeft: "auto",
                //   //
                //   //   }}
                //   // >
                //   //   Renew Now?
                //   // </Button>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlertOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <LocalizedMessage id="portal.inGrace" />
            </Alert>
          </Collapse>
        )
      ) : null}
      {branch.subscription?.subscriptionStatus === "InCountingDown" ? (
        branch.subscription?.subscriptionPlan === "Trial" ? (
          <Collapse in={alertOpen}>
            <Alert
              variant="filled"
              severity="warning"
              // onClick={() => setAlertOpen(false)}
              // onClose={() => {}}
              action={
                <>
                  <Button
                    onClick={onSubscribeNowClick}
                    component="label"
                    color="inherit"
                    size="small"
                    style={{
                      marginLeft: "auto",
                    }}
                  >
                    <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                  </Button>
                  <IconButton
                    color="inherit"
                    size="small"
                    onClick={() => setAlertOpen(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </>
              }
            >
              <LocalizedMessage id="portal.trial" />
              <LocalizedMessage id="portal.inCountingDown" />
            </Alert>
          </Collapse>
        ) : (
          <Collapse in={alertOpen}>
            <Alert
              variant="filled"
              severity="warning"
              // onClick={() => setAlertOpen(false)}
              action={
                //   // <Button
                //   //   onClick={(e) => {
                //   //     e.preventDefault();
                //   //     window.open(CHECKOUT_BRANCH_MONTHLY); //to open renew link new page
                //   //   }}
                //   //   component="label"
                //   //   href="blank"
                //   //   color="inherit"
                //   //   size="small"
                //   //   style={{
                //   //     marginLeft: "auto",
                //   //   }}
                //   // >
                //   //   Renew Now?
                //   // </Button>
                <IconButton
                  color="inherit"
                  size="small"
                  onClick={() => setAlertOpen(false)}
                >
                  <CloseIcon />
                </IconButton>
              }
            >
              <LocalizedMessage id="portal.inCountingDown" />
            </Alert>
          </Collapse>
        )
      ) : null}
    </Box>
  );
};

// Styles
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2, 0.7),
    "& img": {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      maxWidth: "100%",
      [theme.breakpoints.down("sm")]: { maxHeight: "100px" },
      [theme.breakpoints.up("sm")]: { maxHeight: "150px" },
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  footer: {
    position: "sticky",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    marginRight: theme.spacing(2),
  },
  selectedIcon: {
    color: theme.palette.primary.main,
  },
}));

const { CHECKOUT_BRANCH_MONTHLY } = process.env;

// Ad-Hoc Components

/**
 * @name Portal
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Portal = () => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [currentComponent, setCurrentComponent] = useGlobalState(
    "components.setupCurrentComponent"
  );
  const [currentReportComponent, setCurrentReportComponent] = useGlobalState(
    "components.reportsCurrentComponent"
  );

  const [accountState, setAccountState] = useGlobalState("userData.account");
  const [branch, setBranch] = useGlobalState("userData.branch");
  const [userAuth, setUserAuth] = useGlobalState("userData.auth");
  const [permissions] = useGlobalState("userData.permissions");
  const [dataToShowInPortal] = useGlobalState("userData.dataToShowInPortal");

  // State Hooks
  const [heading] = useGlobalState("heading.heading");
  const [branches, setBranches] = useState([]);
  const [openQrDialog, setOpenQRDialog] = useState(false);
  const [qrData, setQRData] = useState("");
  const [alertOpen, setAlertOpen] = useState(true);
  const [openSubscribeNowDialog, setOpenSubscribeNowDialog] = useState(false);

  // Effect Hooks
  useEffect(() => {
    let brs = accountState.isAdmin
      ? accountState.brands[0].branches
      : accountState.branchRoles.map((br) => br.branch);
    brs.sort((a, b) => a.name.localeCompare(b.name));
    setBranches(brs);
  }, []);

  // Other Hooks

  let history = useHistory();
  const routeMatch = useRouteMatch();
  const routerLocation = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const showSidebarTrigger = !useMediaQuery(Theme.breakpoints.up("md"));
  const showSidebarCollapseBtn = useMediaQuery(Theme.breakpoints.up("md"));

  // Event Handlers
  const handleSetCurrentBranch = (event, br) => {
    setBranch(br);
    if (br.id !== branch.id) history.go(0);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  }

  const handleLogout = () => {
    setBranch({});
    setUserAuth({
      loaded: false,
      token: null,
    });
    setAccountState({});
    localStorage.clear();
    history.go(0);
  };

  const updateBranches = (brs) => {
    brs.sort((a, b) => a.name.localeCompare(b.name));
    setBranches(brs);
  };

  const handleOpenQrDialog = (br) => {
    setOpenQRDialog(true);
    setQRData(br);
  };

  const onSubscribeNowClick = () => {
    setOpenSubscribeNowDialog(true);
  };

  // Other
  const scheme = Layout();

  scheme.configureHeader((builder) => {
    builder.create("appHeader").registerConfig("xs", {
      position: "sticky",
      clipped: false,
    });
  });

  scheme.configureEdgeSidebar((builder) => {
    builder
      .create("appSidebar", {
        anchor: "left",
      })
      .registerTemporaryConfig("xs", {
        //   anchor: "left",
        width: "auto", // 'auto' is only valid for temporary variant
      })
      .registerPermanentConfig("md", {
        width: drawerWidth, // px, (%, rem, em is compatible)
        collapsible: true,
        collapsedWidth: 64,
        headerMagnetEnabled: true,
      });
  });
  console.log("1111rerender");

  // Component Render
  return (
    <Root theme={Theme} scheme={scheme}>
      {({ state, setOpen }) => {
        const HeaderStyled = withStyles(
          (theme) => ({
            root: {
              width: "100%",
              [theme.breakpoints.up("xs")]: {
                position: "sticky",
              },
              [theme.breakpoints.up("md")]: {
                width: state.sidebar.appSidebar.collapsed
                  ? `calc(100% - 64px)`
                  : `calc(100% - ${drawerWidth}px)`,
              },
            },
          }),
          { index: 1 }
        )(Header);
        return (
          <>
            <CssBaseline />

            <HeaderStyled>
              <Toolbar>
                {showSidebarTrigger && (
                  <SidebarTrigger sidebarId="appSidebar" />
                )}
                <Typography variant="h6" color="primary" noWrap>
                  {heading}
                </Typography>
                <Box
                  display="flex"
                  flex="2"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <PopupState variant="popover" popupId="demo-popup-popover">
                    {(popupState) => (
                      <div>
                        <Box display="flex" alignItems="center">
                          <QRDialog
                            qrData={qrData}
                            open={openQrDialog}
                            onCloseDialog={() => setOpenQRDialog(false)}
                          />
                          <MenuItem
                            {...bindToggle(popupState)}
                            aria-controls={
                              popupState.isOpen ? "menu-list-grow" : undefined
                            }
                            aria-haspopup="true"
                          >
                            <ListItemIcon style={{ minWidth: 40 }}>
                              <AppsIcon
                                color={
                                  popupState.isOpen ? "primary" : undefined
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={branch.name}
                              primaryTypographyProps={{
                                color: popupState.isOpen
                                  ? "primary"
                                  : undefined,
                              }}
                            />
                          </MenuItem>
                        </Box>
                        <Popper
                          style={{ zIndex: 10000 }}
                          {...bindPopper(popupState)}
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={popupState.close}
                                >
                                  <MenuList
                                    autoFocusItem={popupState.isOpen}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    style={{
                                      maxHeight: "90vh",
                                      overflow: "auto",
                                    }}
                                  >
                                    {branches.map((br) => (
                                      <MenuItem
                                        key={br.id}
                                        selected={br.id === branch.id}
                                        onClick={(event) =>
                                          handleSetCurrentBranch(event, br)
                                        }
                                      >
                                        {br.name}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    )}
                  </PopupState>

                  <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                      <div>
                        <Box display="flex" alignItems="center">
                          <MenuItem
                            {...bindToggle(popupState)}
                            aria-controls={
                              popupState.isOpen ? "menu-list-grow" : undefined
                            }
                            aria-haspopup="true"
                          >
                            <ListItemIcon style={{ minWidth: 40 }}>
                              <AccountCircleIcon
                                fontSize="large"
                                color={
                                  popupState.isOpen ? "primary" : undefined
                                }
                              />
                            </ListItemIcon>
                            <ListItemText
                              primary={accountState.firstName}
                              primaryTypographyProps={{
                                color: popupState.isOpen
                                  ? "primary"
                                  : undefined,
                              }}
                            />

                            {/* {popupState.isOpen ? (
                              <ExpandLess color="primary" />
                            ) : (
                              <ExpandMore />
                            )} */}
                          </MenuItem>
                        </Box>
                        <Popper
                          style={{ zIndex: 105 }}
                          {...bindPopper(popupState)}
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={popupState.close}
                                >
                                  <MenuList
                                    autoFocusItem={popupState.isOpen}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    // style={{margin}}
                                  >
                                    <MenuItem
                                      style={{
                                        direction: Theme.direction,
                                        paddingTop: 10,
                                      }}
                                      onClick={() => {
                                        history.replace("/portal/editProfile");
                                      }}
                                    >
                                      <ListItemIcon>
                                        <AccountCircleIcon
                                          style={{
                                            margin: Theme.spacing(0, 1.5, 0, 0),
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <LocalizedMessage id="general.myAccount" />
                                        }
                                      />
                                    </MenuItem>

                                    {/* {accountState.isAdmin && (
                                      <MenuItem
                                        style={{ direction: Theme.direction }}
                                        onClick={() => {
                                          history.replace("/portal/editBrand");
                                        }}
                                      >
                                        <ListItemIcon>
                                          <BusinessIcon
                                            style={{
                                              margin: Theme.spacing(
                                                0,
                                                1.5,
                                                0,
                                                0
                                              ),
                                            }}
                                          />{" "}
                                        </ListItemIcon>
                                        <ListItemText
                                          primary={
                                            <LocalizedMessage id="general.brandSettings" />
                                          }
                                        />
                                      </MenuItem>
                                    )} */}

                                    <MenuItem
                                      style={{ direction: Theme.direction }}
                                      onClick={handleLogout}
                                    >
                                      <ListItemIcon>
                                        <ExitToAppIcon
                                          style={{
                                            margin: Theme.spacing(0, 1.5, 0, 0),
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <LocalizedMessage id="common.signOut" />
                                        }
                                      />
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    )}
                  </PopupState>
                </Box>
              </Toolbar>
              <SubscriptionDialog
                open={openSubscribeNowDialog}
                onCloseDialog={() => setOpenSubscribeNowDialog(false)}
                branchOrDevice="branch"
              />
            </HeaderStyled>

            <DrawerSidebar sidebarId={"appSidebar"}>
              <SidebarContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box className={classes.logoContainer}>
                  <img src={OnionLogoWithText} alt="Onion Colored Logo" />
                </Box>
                <Divider />

                <List>
                  <Link
                    key={"Home"}
                    to={`${routeMatch.path}/gettingStarted`}
                    component={React.forwardRef((props, ref) => {
                      return (
                        <ListItem
                          ref={ref}
                          button
                          key="Home"
                          onClick={(evt) => {
                            setOpen("appSidebar", false);
                            evt.preventDefault();
                            props.navigate();
                          }}
                          href={props.to}
                          selected={
                            routerLocation.pathname ===
                            `${routeMatch.path}/gettingStarted`
                          }
                        >
                          {props.children}
                        </ListItem>
                      );
                    })}
                  >
                    <ListItemIcon
                      className={
                        routerLocation.pathname ===
                        `${routeMatch.path}/gettingStarted`
                          ? classes.selectedIcon
                          : undefined
                      }
                    >
                      <HomeIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <LocalizedMessage id="gettingStarted.pageTitle" />
                      }
                      primaryTypographyProps={{
                        color:
                          routerLocation.pathname ===
                          `${routeMatch.path}/gettingStarted`
                            ? "primary"
                            : "inherit",
                        variant: "subtitle1",
                      }}
                    />
                  </Link>
                  <Link
                    key={"dashboard"}
                    to={`${routeMatch.path}/dashboard`}
                    component={React.forwardRef((props, ref) => {
                      return (
                        <ListItem
                          ref={ref}
                          button
                          key="Dashboard"
                          onClick={(evt) => {
                            setOpen("appSidebar", false);
                            evt.preventDefault();
                            props.navigate();
                          }}
                          href={props.to}
                          selected={
                            routerLocation.pathname ===
                            `${routeMatch.path}/dashboard`
                          }
                        >
                          {props.children}
                        </ListItem>
                      );
                    })}
                  >
                    <ListItemIcon
                      className={
                        routerLocation.pathname ===
                        `${routeMatch.path}/dashboard`
                          ? classes.selectedIcon
                          : undefined
                      }
                    >
                      <DashboardIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={<LocalizedMessage id="dashboard.pageTitle" />}
                      primaryTypographyProps={{
                        color:
                          routerLocation.pathname ===
                          `${routeMatch.path}/dashboard`
                            ? "primary"
                            : "inherit",
                        variant: "subtitle1",
                      }}
                    />
                  </Link>
                  <Link
                    key={"live"}
                    to={`${routeMatch.path}/live`}
                    component={React.forwardRef((props, ref) => {
                      return (
                        <ListItem
                          ref={ref}
                          button
                          key="Live"
                          onClick={(evt) => {
                            setOpen("appSidebar", false);
                            evt.preventDefault();
                            props.navigate();
                          }}
                          href={props.to}
                          selected={
                            routerLocation.pathname ===
                            `${routeMatch.path}/live`
                          }
                        >
                          {props.children}
                        </ListItem>
                      );
                    })}
                  >
                    <ListItemIcon
                      className={
                        routerLocation.pathname === `${routeMatch.path}/live`
                          ? classes.selectedIcon
                          : undefined
                      }
                    >
                      <LiveTvIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={<LocalizedMessage id="live.pageTitle" />}
                      primaryTypographyProps={{
                        color:
                          routerLocation.pathname === `${routeMatch.path}/live`
                            ? "primary"
                            : "inherit",
                        variant: "subtitle1",
                      }}
                    />
                  </Link>
                  {[
                    {
                      text: <LocalizedMessage id="setup.pageTitle" />,
                      permissionName: "setup",
                      icon: <SettingsIcon />,
                      link: `${routeMatch.path}/setup`,
                    },
                    // {
                    //   text: <LocalizedMessage id="setup.pageTitle" />,
                    //   permissionName: "setup",
                    //   icon: <SettingsIcon />,
                    //   link: `${routeMatch.path}/setup`,
                    // },
                    {
                      text: <LocalizedMessage id="menu.pageTitle" />,
                      permissionName: "menu",
                      icon: <RestaurantMenuIcon />,
                      link: `${routeMatch.path}/menu`,
                    },
                    {
                      text: <LocalizedMessage id="stockCapacity.pageTitle" />,
                      permissionName: "menuItemStock",
                      icon: <ListAltIcon />,
                      link: `${routeMatch.path}/editStock`,
                    },
                    {
                      text: <LocalizedMessage id="POS.pageTitle" />,
                      permissionName: "pos",
                      icon: <TabletIcon />,
                      link: `${routeMatch.path}/POS`,
                    },
                    // {
                    //   text: <LocalizedMessage id="driverLogin.pageTitle" />,
                    //   permissionName: "driverLogin",
                    //   icon: <DriveEtaIcon />,
                    //   link: `${routeMatch.path}/driverLogin`,
                    // },
                    {
                      text: <LocalizedMessage id="report.pageTitle" />,
                      permissionName: "report",
                      icon: <AssessmentIcon />,
                      link: `${routeMatch.path}/Reports`,
                    },
                    {
                      text: <LocalizedMessage id="sales.pageTitle" />,
                      permissionName: "sales",
                      icon: <ShowChartIcon />,
                      link: `${routeMatch.path}/sales`,
                    },
                    {
                      text: <LocalizedMessage id="crm.pageTitle" />,
                      permissionName: "customer",
                      icon: <PersonIcon />,
                      link: `${routeMatch.path}/crm`,
                    },
                  ].map((item, index) => {
                    if (
                      dataToShowInPortal.find(
                        (el) => el === item.permissionName
                      )
                    )
                      return (
                        <Link
                          key={item.permissionName}
                          to={item.link}
                          component={React.forwardRef((props, ref) => {
                            return (
                              // <a ref={ref}>💅 </a>
                              <ListItem
                                ref={ref}
                                button
                                key={item.text}
                                onClick={(evt) => {
                                  if (item.permissionName === "setup")
                                    setCurrentComponent("cards");
                                  if (item.permissionName === "report")
                                    setCurrentReportComponent("reportCards");
                                  setOpen("appSidebar", false);
                                  evt.preventDefault();
                                  props.navigate();
                                }}
                                href={props.to}
                                selected={routerLocation.pathname === item.link}
                              >
                                {props.children}
                              </ListItem>
                            );
                          })}
                        >
                          <ListItemIcon
                            className={
                              routerLocation.pathname === item.link
                                ? classes.selectedIcon
                                : undefined
                            }
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            primaryTypographyProps={{
                              color:
                                routerLocation.pathname === item.link
                                  ? "primary"
                                  : "inherit",
                              variant: "subtitle1",
                            }}
                          />
                        </Link>
                      );
                  })}
                </List>
                <Divider />
                <List>
                  {[
                    {
                      text: <LocalizedMessage id="branch.pageTitle" />,
                      permissionName: "branch",
                      icon: <LocationCityIcon />,
                      link: `${routeMatch.path}/branches`,
                    },
                    {
                      text: <LocalizedMessage id="role.pageTitle" />,
                      permissionName: "role",
                      icon: <AccountTreeIcon />,
                      link: `${routeMatch.path}/roles`,
                    },
                    {
                      text: <LocalizedMessage id="account.pageTitle" />,
                      permissionName: "account",
                      icon: <AccountBoxIcon />,
                      link: `${routeMatch.path}/accounts`,
                    },
                    {
                      text: (
                        <LocalizedMessage id="subscriptionDialog.pageTitle" />
                      ),
                      permissionName: "subscriptions",
                      icon: <AccountTreeIcon />,
                      link: `${routeMatch.path}/subscriptions`,
                    },
                    ...(accountState.isAdmin
                      ? [
                          {
                            text: (
                              <LocalizedMessage id="general.brandSettings" />
                            ),
                            permissionName: "brand",
                            icon: (
                              <BusinessIcon
                                style={{
                                  margin: Theme.spacing(0, 1.5, 0, 0),
                                }}
                              />
                            ),
                            link: `${routeMatch.path}/editBrand`,
                          },
                        ]
                      : []),
                    // {
                    //   text: <LocalizedMessage id={"onlineOrders.pageTitle"} />,
                    //   permissionName: "onlineOrdersSetup",
                    //   icon: <TuneIcon />,
                    //   link: `${routeMatch.path}/onlineOrdersSetup`,
                    // },
                    // {
                    //   text: <LocalizedMessage id="export.pannelName" />,
                    //   permissionName: "export",
                    //   icon: <ImportExportIcon />,
                    //   link: `${routeMatch.path}/export`,
                    // },
                  ].map((item, index) => {
                    if (
                      dataToShowInPortal.find(
                        (el) => el === item.permissionName
                      )
                    )
                      return (
                        <Link
                          key={item.permissionName}
                          to={item.link}
                          component={React.forwardRef((props, ref) => {
                            return (
                              <ListItem
                                ref={ref}
                                button
                                key={item.text}
                                onClick={(evt) => {
                                  setOpen("appSidebar", false);
                                  evt.preventDefault();
                                  props.navigate();
                                }}
                                href={props.to}
                                selected={routerLocation.pathname === item.link}
                              >
                                {props.children}
                              </ListItem>
                            );
                          })}
                        >
                          <ListItemIcon
                            className={
                              routerLocation.pathname === item.link
                                ? classes.selectedIcon
                                : undefined
                            }
                          >
                            {item.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            primaryTypographyProps={{
                              color:
                                routerLocation.pathname === item.link
                                  ? "primary"
                                  : "inherit",
                              variant: "subtitle1",
                            }}
                          />
                        </Link>
                      );
                  })}
                </List>
                <Divider />

                <Box marginTop="auto">
                  <Link
                    key={"downloadApps"}
                    to={`${routeMatch.path}/downloadApps`}
                    component={React.forwardRef((props, ref) => {
                      return (
                        <ListItem
                          ref={ref}
                          button
                          key="downloadApps"
                          onClick={(evt) => {
                            setOpen("appSidebar", false);
                            evt.preventDefault();
                            props.navigate();
                          }}
                          href={props.to}
                          selected={
                            routerLocation.pathname ===
                            `${routeMatch.path}/downloadApps`
                          }
                        >
                          {props.children}
                        </ListItem>
                      );
                    })}
                  >
                    <ListItemIcon
                      className={
                        routerLocation.pathname ===
                        `${routeMatch.path}/downloadApps`
                          ? classes.selectedIcon
                          : undefined
                      }
                    >
                      <AppsIcon />
                    </ListItemIcon>

                    <ListItemText
                      primary={<LocalizedMessage id="portal.downloadApps" />}
                      primaryTypographyProps={{
                        color:
                          routerLocation.pathname ===
                          `${routeMatch.path}/downloadApps`
                            ? "primary"
                            : "inherit",
                        variant: "subtitle1",
                      }}
                    />
                  </Link>
                </Box>
              </SidebarContent>

              {showSidebarCollapseBtn && <CollapseBtn />}
            </DrawerSidebar>
            <Content className={classes.content}>
              <Switch>
                <Route path={`${routeMatch.path}/gettingStarted`}>
                  {<GettingStarted />}
                </Route>
                <Route path={`${routeMatch.path}/dashboard`}>
                  {<Dashboard dataToShow={dataToShowInPortal} />}
                </Route>
                <Route path={`${routeMatch.path}/live`}>{<Live />}</Route>
                {dataToShowInPortal.includes("menu") && (
                  <Route path={`${routeMatch.path}/menu`}>
                    <Menu />
                  </Route>
                )}
                {dataToShowInPortal.includes("menuItemStock") && (
                  <Route path={`${routeMatch.path}/editStock`}>
                    <StockCapacity />
                  </Route>
                )}
                <Route path={`${routeMatch.path}/setup`}>
                  <CardsSetup />
                </Route>
                {dataToShowInPortal.includes("pos") && (
                  <Route path={`${routeMatch.path}/POS`}>
                    <POS />
                  </Route>
                )}
                {/* {dataToShowInPortal.includes("pos") && ( */}
                {/* <Route path={`${routeMatch.path}/driverLogin`}>
                  <DriverLogin  />
                </Route> */}
                {/* )} */}
                <Route path={`${routeMatch.path}/reports`}>
                  <CardsReports />
                </Route>
                {dataToShowInPortal.includes("sales") && (
                  <Route path={`${routeMatch.path}/sales`}>
                    <Sales />
                  </Route>
                )}
                {dataToShowInPortal.includes("customer") && (
                  <Route path={`${routeMatch.path}/crm`}>
                    <CRM />
                  </Route>
                )}
                <Route path={`${routeMatch.path}/EditProfile`}>
                  <EditProfile />
                </Route>
                <Route path={`${routeMatch.path}/subscriptions`}>
                  <SubscriptionsDetails />
                </Route>
                {dataToShowInPortal.includes("branch") && (
                  <Route path={`${routeMatch.path}/Branches`}>
                    <Branches
                      branches={branches}
                      setBranches={updateBranches}
                    />
                  </Route>
                )}
                {dataToShowInPortal.includes("account") && (
                  <Route path={`${routeMatch.path}/Accounts`}>
                    <Accounts
                      branches={branches}
                      setBranches={updateBranches}
                    />
                  </Route>
                )}
                {dataToShowInPortal.includes("role") && (
                  <Route path={`${routeMatch.path}/Roles`}>
                    <Roles />
                  </Route>
                )}
                {/* {dataToShowInPortal.includes("onlineOrdersSetup") && (
                  <Route path={`${routeMatch.path}/onlineOrdersSetup`}>
                    <OnlineSetup
                      branches={branches}
                      setBranches={updateBranches}
                    />
                  </Route>
                )} */}
                {permissions.isOwner && (
                  <Route path={`${routeMatch.path}/EditBrand`}>
                    <EditBrand />
                  </Route>
                )}
                {/* {dataToShowInPortal.includes("export") && (
                  <Route path={`${routeMatch.path}/export`}>
                    <Export />
                  </Route>
                )} */}
                <Route path={`${routeMatch.path}/downloadApps`}>
                  <DownloadApps />
                </Route>
                <Route path={routeMatch.path}>
                  <Redirect to={`${routeMatch.path}/gettingStarted`} />
                </Route>
              </Switch>
            </Content>
            <Footer className={classes.footer}>
              <AppFooter />
            </Footer>
            <SubscriptionAlert
              branch={branch}
              alertOpen={alertOpen}
              setAlertOpen={setAlertOpen}
              onSubscribeNowClick={onSubscribeNowClick}
            />
          </>
        );
      }}
    </Root>
  );
};

Portal.propTypes = {
  /**
   *
   */
};

export default Portal;
