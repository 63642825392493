// React
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Material
import { Box, FormHelperText } from '@material-ui/core'

// Globals

// Helpers
import { makeStyles } from 'Helpers/Styles'
import useSkeleton from 'Helpers/useSkeleton'

// Components
import HelpIcon from 'Components/HelpIcon'

// Factories

// Screens

// Assets

// Help

// Third Parties
import MUIChipField from 'material-ui-chip-input'
import uuid from 'uuid'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

// Ad-Hoc Components

/**
 * @name ChipField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ChipField = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  InputProps,
  size,
  helperText,
  autoComplete,
  defaultValue,
  value,
  onChange,
  id,
  isLoading,
  isSubmitting,
  label,
  name,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  const { style: inputStyle, ...otherInputProps } = InputProps
  const inputFieldID = id || uuid.v4()
  // const currentValue = value || defaultValue || []

  value = value || defaultValue || []
  // Theme & Style Hooks
  const classes = useStyles()

  // Global State Hooks

  // State Hooks

  // Effect Hooks
  // useEffect(() => {
  //   if (typeof onChange === "function") {
  //     const e = {};
  //     e.target = { name, value: value };
  //     e.persist = () => { };
  //     onChange(name, value);
  //   }
  //   console.log(
  //     `%cChip %cField Mount -> %cName: %c${name}`,
  //     'font-weight: bold; color: darkslategray',
  //     '',
  //     'font-weight: bold;',
  //     '',
  //   )
  // }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton('rect')
  const [helperTextSkeleton, helperTextRef] = useSkeleton('rect')

  // Event Handlers
  const onChangeHandler = (chips) => {
    if (typeof onChange === "function") {
      onChange(name, chips);
    }
  }

  // Other
  console.log(
    `%cChip %cRerender -> %cName: %c${name}`,
    'font-weight: bold; color: darkslategray',
    '',
    'font-weight: bold;',
    '',
  )

  // Component Render
  return (
    <Box className={className} ref={inputRef}>
      <Box className={classes.root} style={style}>
        {isLoading && inputSkeleton ? (
          inputSkeleton
        ) : (
            <MUIChipField
              {
              ...{
                ...otherProps,
                // ref: inputRef,
                name,
                label,
                onChange: onChangeHandler,
                // onDelete: onDeleteHandler,
                error: Boolean(errors && errors.length > 0),
                variant,
                margin,
                size,
                InputProps: {
                  id: inputFieldID,
                  autoComplete: "off",
                  style: { paddingTop: "18.5px", ...inputStyle },
                  disabled: isSubmitting || disabled,
                  ...InputProps,
                },
                fullWidth,
                defaultValue: value,
              }
              }
            />

          )}
        {/* <HelpIcon
          {...{
            tooltip,
            helpKey,
            size,
            isLoading,
            modalTitle: label,
            ...helpIconProps,
          }}
        /> */}
      </Box>
      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: 'flex',
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                      <span ref={helperTextRef}>{helperText}</span>
                    ))}
                {errors && (
                  <span style={{ display: 'flex', flexDirection: 'column' }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}
        <HelpIcon
          style={{ visibility: 'hidden', height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  )
}

ChipField.propTypes = {
  /**
   *
   */
}

ChipField.defaultProps = {
  variant: 'outlined',
  margin: 'normal',
  InputProps: {},
}

export default ChipField
