import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useGlobalState } from "Helpers/GlobalState";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Button,
  Box,
  Typography,
  Grid,
  CircularProgress,
} from "@material-ui/core";
import { LocalizedMessage } from "Helpers/Localization";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  title: {
    fontFamily: "Roboto,Changa",
    fontSize: "2.6rem",
    color: "#0582D2",
    fontWeight: 500,
    alignItems: "cneter",
  },
});

const SubscriptionPlansTable = () => {
  const classes = useStyles();
  const [subscriptionsPlans] = useGlobalState(
    "subscriptionsPlans.subscriptionsPlans"
  );
  const [rows, setRows] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState([]);

  const [billTypeFilter, setBillTypeFilter] = useState("monthly");

  useEffect(() => {
    const plansDescriptionsSet = new Set();
    const newRows = [];
    const newCols = [];
    const newPlans = subscriptionsPlans.data.filter(
      (subscription) => subscription.billedType === billTypeFilter
    );
    setFilteredPlans(newPlans);

    newPlans.forEach((plan) => {
      newCols.push(plan.name);
      plan.description.forEach((description) => {
        plansDescriptionsSet.add(description);
      });
    });
    plansDescriptionsSet.forEach((desc) => {
      let newRow = {
        description: desc,
      };
      newPlans.forEach((plan) => {
        newRow[plan.name] = plan.description.find((el) => el === desc) ? (
          <CheckBoxIcon color="primary" />
        ) : (
          <ClearIcon color="primary" />
        );
      });
      newRows.push(newRow);
    });
    setRows(newRows);

    console.log(plansDescriptionsSet);
  }, [subscriptionsPlans.success, billTypeFilter]);

  return (
    <Grid
      direction="column"
      alignItems="center"
      style={{ display: "flex", minWidth: "80%" }}
    >
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
          alignSelf: "center",
          margin: "20px",
          width: "100%",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color={billTypeFilter === "monthly" ? "primary" : "default"}
          onClick={() => setBillTypeFilter("monthly")}
          style={{
            ...(billTypeFilter === "monthly"
              ? {
                  color: "white",
                  backgroundColor: "#0582D2",
                }
              : {}),
            marginInlineEnd: "122px",
            padding: "20px 0",
            width: "230px",
          }}
        >
          <LocalizedMessage id="subscriptionDialog.billedMonthly" />
        </Button>
        <Button
          size="large"
          variant="contained"
          color={billTypeFilter === "yearly" ? "primary" : "default"}
          onClick={() => setBillTypeFilter("yearly")}
          style={{
            ...(billTypeFilter === "yearly"
              ? {
                  color: "white",
                  backgroundColor: "#0582D2",
                }
              : {}),
            padding: "20px 0",
            width: "230px",
          }}
        >
          <LocalizedMessage id="subscriptionDialog.billedYearly" />
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {filteredPlans.map((plan) => (
                <TableCell align="center">
                  <Typography variant="h6">{plan.name}</Typography>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      alignSelf: "center",
                    }}
                  >
                    <Typography className={classes.title}>
                      {`${plan.currency}${plan.monthlyPrice}`}
                    </Typography>
                    <Typography
                      style={{
                        color: "#0582D2",
                        alignSelf: "center",
                      }}
                    >
                      {`/`}
                      <LocalizedMessage id="subscriptionDialog.month" />
                    </Typography>
                  </Box>
                  {plan.billedType === "yearly" ? (
                    <Typography
                      style={{
                        alignSelf: "center",
                      }}
                    >
                      {"("}
                      <LocalizedMessage id="subscriptionDialog.billedYearly" />
                      {` ${plan.currency}${plan.yearlyPrice}/ `}
                      <LocalizedMessage id="subscriptionDialog.year" />
                      {")"}
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        alignSelf: "center",
                      }}
                    >
                      {`(`}
                      <LocalizedMessage id="subscriptionDialog.billedMonthly" />
                      {`)`}
                    </Typography>
                  )}
                  <Button
                    disabled={isSubmitting}
                    onClick={() => {
                      setIsSubmitting(true);
                      window.open(plan.link, "_self");
                    }}
                    size="medium"
                    style={{
                      margin: "20px",
                      color: "white",
                      backgroundColor: "#0582D2",
                    }}
                    variant="contained"
                  >
                    {isSubmitting ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : (
                      <LocalizedMessage id="subscriptionDialog.subscribeNow" />
                    )}
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  {row.description}
                </TableCell>
                {filteredPlans.map((plan) => (
                  <TableCell align="center">{row[plan.name]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

export default SubscriptionPlansTable;
