// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";
import Title from "Components/Title";
import SortableBody from "Components/Sortable";

// Factories

// Screens
import PaymentForm from "./PaymentForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import arrayMove from "array-move";

// Services
import { useGetAllPayments, useDeletePayment } from "Services/Payments/";
import { useGetAllPaymentTypes } from "Services/PaymentTypes";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "drag",
    label: <LocalizedMessage id="general.drag" />,
    minWidth: 20,
    align: "center",
  },
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 200,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 100,
  },
  {
    id: "typeId",
    label: <LocalizedMessage id="setup.payment.typeId" />,
    minWidth: 100,
  },
  { id: "actions", label: "", minWidth: 100 },
];

/**
 * @name Payments
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Payments = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();
  const intl = useIntl();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = React.useState(true);
  const [editId, setEditId] = React.useState("");

  const [paymentTypes, setPaymentTypes] = useState([]);
  const [isFetchingType, setIsFetchingType] = useState(true);

  /* Payment Form */
  const [PaymentFormOpen, setPaymentFormOpen] = useState(false);
  const [PaymentFormMode, setPaymentFormMode] = useState("");
  const [PaymentFormData, setPaymentFormData] = useState({});

  // Effect Hooks
  useEffect(() => {
    getAllPaymentTypes()
      .then((type) => {
        setPaymentTypes(() =>
          type.map((sc) => ({
            value: sc.id,
            text: intl.formatMessage({
              id: `lookUpTables.paymentTypes.${sc.id}`,
            }),
          }))
        );
        setIsFetchingType(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  useEffect(() => {
    getAllPayments()
      .then((payments) => {
        const newPayments = payments.map((payment) => createData(payment));
        setIsFetchingRows(false);
        setRows(newPayments);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllPayments = useGetAllPayments();
  const getAllPaymentTypes = useGetAllPaymentTypes();
  const deletePayment = useDeletePayment();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddPaymentForm = () => {
    setPaymentFormData({});
    setPaymentFormMode("add");
    setPaymentFormOpen(true);
  };

  const onEditClick = (payment) => {
    setEditId(payment.id);
    setPaymentFormData(payment);
    setPaymentFormMode("edit");
    setPaymentFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deletePayment(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setRows(rows.getState().filter((row) => row.id !== id));
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };
  const addCallback = (response) => {
    let newData = [...rows.getState(), createData(response.data.data)];
    setRows(newData);
  };

  const editCallback = (response) => {
    const newRows = rows.getState();
    const index = newRows.findIndex((row) => row.id === editId);
    newRows.splice(index, 1, createData(response.data.data));
    setRows(newRows);
  };

  // Other
  function createData(payment) {
    return {
      id: payment.id,
      name: payment.name,
      otherName: payment.otherName,
      typeId: <LocalizedMessage id={`lookUpTables.paymentTypes.${payment.paymentMethodType.id}`} />,
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.paymentMethod.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(payment)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin ||
            permissions.paymentMethod.includes("delete")) && (
            <AlertDialog id={payment.id} onAgree={onDeleteClick} />
          )} */}
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={<LocalizedMessage id="setup.payment.pageTitle" key="setup.payment.pageTitle" />}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between ",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.payment.pageTitle" />
        </Typography>
        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.paymentMethod.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={isFetchingRows}
            component={isFetchingRows ? "div" : undefined}
            onClick={isFetchingRows ? undefined : openAddPaymentForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        {/* Start Payment Form */}
        <PaymentForm
          open={PaymentFormOpen}
          setOpen={setPaymentFormOpen}
          mode={PaymentFormMode}
          editFormData={PaymentFormData}
          editId={editId}
          addCallback={addCallback}
          editCallback={editCallback}
          paymentTypes={paymentTypes}
        />
        {/* End Payment Form */}
        {isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <SortableBody
                  data={(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  columns={columns}
                  onSortEnd={({ oldIndex, newIndex }) => {
                    setRows((elements) => [...arrayMove(elements, oldIndex, newIndex)]);
                  }}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

Payments.propTypes = {
  /**
   *
   */
};

Payments.defaultProps = {
  /**
   *
   */
  setHeading: () => {},
};

export default Payments;
