// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";
import Title from "Components/Title";

// Factories

// Screens
import DiscountForm from "./DiscountForm";

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import moment from "moment";

// Services
import { useDeleteDiscount } from "Services/Discounts/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="common.name" />,
    minWidth: 100,
  },
  {
    id: "otherName",
    label: <LocalizedMessage id="common.otherName" />,
    minWidth: 100,
  },
  {
    id: "value",
    label: <LocalizedMessage id="common.value" />,
    minWidth: 100,
  },
  {
    id: "discountType",
    label: <LocalizedMessage id="setup.discount.discountType" />,
    minWidth: 100,
    format: (col) => <LocalizedMessage id={`common.${col}`} />,
  },
  {
    id: "dateFrom",
    label: <LocalizedMessage id="common.dateFrom" />,
    minWidth: 150,
  },
  {
    id: "dateTo",
    label: <LocalizedMessage id="common.dateTo" />,
    minWidth: 150,
  },
  { id: "actions", label: "", minWidth: 150 },
];

/**
 * @name Discounts
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Discounts = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  const [discounts, setDiscounts] = useGlobalState("discounts.discounts");
  const [branch, setBranch] = useGlobalState("userData.branch");
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [searchQuery, setSearchQuery] = useState("");
  const [openSearchField, setOpenSearchField] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [editId, setEditId] = useState("");

  /* Discount Form */
  const [discountFormOpen, setDiscountFormOpen] = useState(false);
  const [discountFormMode, setDiscountFormMode] = useState("");
  const [discountFormData, setDiscountFormData] = useState({});

  // Effect Hooks
  useEffect(() => {
    if (discounts.data) setRows(discounts.data.map((el) => createData(el)));
    else setRows([]);
  }, [discounts]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const deleteDiscount = useDeleteDiscount();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const openAddDiscountForm = () => {
    setDiscountFormData({});
    setDiscountFormMode("add");
    setDiscountFormOpen(true);
  };

  const onEditClick = (discount) => {
    setEditId(discount.id);
    setDiscountFormData(discount);
    setDiscountFormMode("edit");
    setDiscountFormOpen(true);
  };

  const onDeleteClick = (id) => {
    deleteDiscount(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setDiscounts({
          ...discounts,
          data: discounts.data.filter((row) => row.id !== id),
        });
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };
  const addCallback = (response) => {
    setDiscounts({
      ...discounts,
      data: [...discounts.data, response.data.data],
    });
  };
  const editCallback = (response) => {
    const index = discounts.data.findIndex((row) => row.id === editId);
    discounts.data.splice(index, 1, response.data.data);
    setDiscounts({ ...discounts });
  };

  // Other
  function createData(discount) {
    return {
      id: discount.id,
      name: discount.name,
      otherName: discount.otherName,
      employees: discount.employees,
      salesSections: discount.salesSections,
      value:
        discount.valueType === "PERCENTAGE"
          ? discount.value + "%"
          : discount.value + " " + branch.currencyId,
      discountType: discount.discountType,
      dateFrom: moment(discount.dateFrom).format("LLL"),
      dateTo: moment(discount.dateTo).format("LLL"),
      actions: (
        <Box>
          {(permissions.isAdmin || permissions.orderDiscount.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton aria-label="edit" onClick={() => onEditClick(discount)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.orderDiscount.includes("delete")) && (
            <AlertDialog id={discount.id} onAgree={onDeleteClick} />
          )} */}
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box
      style={{
        paddingBottom: Theme.spacing(10),
        position: "relative",
      }}
    >
      <Title
        pageTitle={
          <LocalizedMessage id="setup.discount.pageTitle" key="setup.discount.pageTitle" />
        }
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between ",
          paddingBottom: 8,
        }}
      >
        <Typography variant="h6" component="h2" style={{ margin: 8 }}>
          <LocalizedMessage id="setup.discount.pageTitle" />
        </Typography>
        {!openSearchField && downSm && (
          <IconButton onClick={() => setOpenSearchField(true)}>
            <SearchIcon color={"primary"} />
          </IconButton>
        )}
        {(openSearchField || !downSm) && (
          <TextField
            label={<LocalizedMessage id="general.search" />}
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            variant="outlined"
          />
        )}
      </Box>
      {(permissions.isAdmin || permissions.orderDiscount.includes("add")) && (
        <Tooltip title={<LocalizedMessage id="general.add" />}>
          <Fab
            color="primary"
            disabled={discounts.isFetchingRows}
            component={discounts.isFetchingRows ? "div" : undefined}
            onClick={discounts.isFetchingRows ? undefined : openAddDiscountForm}
            aria-label="add"
            style={{
              position: "absolute",
              bottom: Theme.spacing(2),
              right: Theme.direction === "ltr" ? Theme.spacing(2) : null,
              left: Theme.direction === "rtl" ? Theme.spacing(2) : null,
            }}
          >
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
      <Paper className={classes.root}>
        {/* Start Discount Form */}
        <DiscountForm
          open={discountFormOpen}
          setOpen={setDiscountFormOpen}
          mode={discountFormMode}
          editFormData={discountFormData}
          permissions={permissions}
          editCallback={editCallback}
          addCallback={addCallback}
          editId={editId}
          parent="discount"
        />
        {/* End Discount Form */}
        {discounts.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
          <Box ref={inputRef}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(searchQuery
                    ? rows
                        .getState()
                        .filter(
                          (row) =>
                            row.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1 ||
                            searchQuery.toLowerCase() === ""
                        )
                    : rows.getState()
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                          {columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {column.format ? column.format(value) : value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.getState().length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </Paper>
    </Box>
  );
};

Discounts.propTypes = {
  /**
   *
   */
};

Discounts.defaultProps = {
  /**
   *
   */
};

export default Discounts;
