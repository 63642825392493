// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
  Box,
} from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { useForm } from "Helpers/Hooks";
import { LocalizedMessage } from "Helpers/Localization";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import { handler } from "Helpers/Handlers";

// Components
import {
  TextField,
  SelectField,
  AutoCompleteField,
  CheckBox,
} from "Components/FormFields";
import CategoriesAndItemsDialog from "../Reports/CategoriesAndItemsDialog";
import FormActions from "Components/FormActions";

// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi";
import _ from "lodash";
import { useIntl } from "react-intl";
import { useSnackbar } from "notistack";

// Services
import { useGetAllCategoriesAndItemsDropdowns } from "Services/Dropdowns";
import { useAddPrinter, useEditPrinter } from "Services/Printers/";

// Styles
const useStyles = makeStyles(() => ({}));

// Ad-Hoc Components
const ipAddressRegex =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3})$/;

const ipAddressSchema = Joi.string()
  .required()
  .regex(ipAddressRegex)
  .message("Invalid ip Address");

const validationSchema = Joi.object({
  name: Joi.string().required().min(1).max(100),
  ipAddress: ipAddressSchema,
  printerTypeId: Joi.string().required(),
  codePage: Joi.number().required(),
  cutType: Joi.string().valid("part", "full").required(),
  isPrinterBeep: Joi.boolean().required(),
  textDensity: Joi.number().valid(0, 1, 2, 3, 4, 5, 6, 7, 8).required(),
  cashDrawerPin: Joi.number().valid(2, 5).required(),
  printingSize: Joi.number().valid(58, 76, 80).required(),
  receiptName: Joi.string().when("printerTypeId", {
    is: "itemSpecific",
    then: Joi.forbidden(),
    otherwise: Joi.string().required().min(1).max(100),
  }),
  copyName: Joi.string().when("printerTypeId", {
    is: "receipt",
    then: Joi.string().required().min(1).max(100),
    otherwise: Joi.forbidden(),
  }),
  isPrintOnSave: Joi.when("printerTypeId", {
    is: "receipt",
    then: Joi.boolean().required(),
    otherwise: Joi.boolean().valid(false),
  }),
});

/**
 * @name PrinterForm
 * @summary
 * @Printer
 * @component
 * @description
 * >
 */
const PrinterForm = ({
  editFormData,
  editId,
  onCancel,
  mode,
  open,
  setOpen,
  printerTypes,
  editCallback,
  addCallback,
  parent,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();
  const intl = useIntl();

  // Global State Hooks

  // State Hooks
  const [categoriesAndItemsModalOpen, setCategoriesAndItemsModalOpen] =
    useState(false);
  const [categoriesAndItemsData, setCategoriesAndItemsData] = useState([]);
  const [isFetchingCategoriesAndItems, setIsFetchingCategoriesAndItems] =
    useState(true);

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData);
    if (mode === "edit") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      editFormData = {
        ...editFormData,
        printerTypeId: editFormData.printerType.id,
        textDensity: editFormData.textDensity.toString(),
        cashDrawerPin: editFormData.cashDrawerPin.toString(),
        printingSize: editFormData.printingSize.toString(),
      };
      setFormData(
        _.pick(editFormData, [
          "name",
          "printerTypeId",
          "ipAddress",
          "codePage",
          "cutType",
          "isPrinterBeep",
          "textDensity",
          "cashDrawerPin",
          "printingSize",
          "receiptName",
          "isPrintOnSave",
          "copyName",
        ])
      );
    } else {
      if (open) setFormData({ codePage: 22 });
      else setFormData({});
    }
  }, [open]);

  useEffect(() => {
    getData()
      .then((data) => {
        setCategoriesAndItemsData(data);
        setIsFetchingCategoriesAndItems(false);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setIsFetchingCategoriesAndItems(false);
      });
  }, []);

  // Other Hooks
  const addPrinter = useAddPrinter({ languageKey: "common.printer" });
  const editPrinter = useEditPrinter({ languageKey: "common.printer" });
  const { enqueueSnackbar } = useSnackbar();
  const getData = useGetAllCategoriesAndItemsDropdowns();
  const printerForm = useForm({
    validationSchema,
    languageKey: "common.printer",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = printerForm;

  useEffect(() => {
    let receiptName =
      formData.printerTypeId === "salesSection"
        ? "Distributor Copy"
        : formData.printerTypeId === "receipt"
        ? "Customer Copy"
        : undefined;

    let copyName =
      formData.printerTypeId === "receipt" ? "Customer Copy" : undefined;

    let isPrintOnSave =
      formData.printerTypeId === "receipt"
        ? editFormData?.isPrintOnSave
          ? editFormData.isPrintOnSave
          : false
        : false;
    let codePage = 22;

    if (mode === "edit") {
      receiptName =
        editFormData.printerTypeId === formData.printerTypeId
          ? formData.printerTypeId === "itemSpecific"
            ? undefined
            : editFormData.receiptName
          : receiptName;

      copyName =
        editFormData.printerTypeId === formData.printerTypeId
          ? editFormData.copyName
            ? editFormData.copyName
            : undefined
          : copyName;

      codePage = editFormData.codePage;
    }

    setFormData((formData) => {
      return { ...formData, receiptName, copyName, isPrintOnSave, codePage };
    });
  }, [open, mode, formData.printerTypeId]);

  // Event Handlers
  const onCLosePrinterForm = () => {
    setOpen(false);
    setFormData({});
    setFormErrors({});
    onCancel();
  };

  const onAddPrinter = () => {
    handler({
      callback: addCallback,
      enqueueSnackbar,
      form: printerForm,
      formName: "printer",
      apiHandler: addPrinter,
      onClose: onCLosePrinterForm,
    });
  };

  const onSavePrinter = () => {
    handler({
      editId,
      callback: editCallback,
      enqueueSnackbar,
      form: printerForm,
      formName: "printer",
      apiHandler: editPrinter,
      onClose: onCLosePrinterForm,
    });
  };

  // Other
  console.log(
    `%cPrinterForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  );

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLosePrinterForm}
      scroll={"paper"}
      fullScreen
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="setup.printer.pageTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="general.add" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="general.edit" />: <b> {formData.name}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="common.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Printer Name"}
                helpKey="Menu.printer.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />
              <AutoCompleteField
                required
                fullWidth
                name="printerTypeId"
                tooltip={"Printer"}
                helpKey="Menu.printer.printerTypeId"
                label={<LocalizedMessage id="setup.printer.printerTypeId" />}
                onChange={onFormFieldChange}
                value={formData.printerTypeId}
                errors={formErrors.printerTypeId}
                isSubmitting={isSubmitting}
                isLoading={isLoading.printerTypeId}
                options={printerTypes.map((printerType) => {
                  return {
                    ...printerType,
                    text: intl.formatMessage({
                      id: `lookUpTables.printerTypes.${printerType.text}`,
                    }),
                  };
                })}
                getOptionDisabled={(option) =>
                  (option.value !== "receipt" && parent === "pos") ||
                  (parent === "salesChannel" &&
                    option.value !== "salesSection") ||
                  (parent === "item" && option.value !== "itemSpecific")
                }
              />
              {/* {formData.printerTypeId === "itemSpecific" && (
                <Box margin={"16px 0px"}>
                  <Button
                    variant="outlined"
                    disabled={isFetchingCategoriesAndItems}
                    onClick={() => setCategoriesAndItemsModalOpen(true)}
                    style={{ width: "100%", padding: "14px" }}
                  >
                    <LocalizedMessage id="general.chooseItems" />
                  </Button>
                  <CategoriesAndItemsDialog
                    open={categoriesAndItemsModalOpen}
                    setOpen={setCategoriesAndItemsModalOpen}
                    formData={formData}
                    setFormData={setFormData}
                    categoriesAndItemsData={categoriesAndItemsData}
                  />
                </Box>
              )} */}

              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="setup.printer.ipAddress" />}
                name="ipAddress"
                tooltip={"Printer IP Address"}
                helpKey="Menu.printer.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.ipAddress}
                errors={formErrors.ipAddress}
                isSubmitting={isSubmitting}
                isLoading={isLoading.ipAddress}
                postHelperComponents={
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ margin: theme.spacing(0, 1) }}
                  >
                    <LocalizedMessage id="setup.printer.ipAddressExample" />
                  </Typography>
                }
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="setup.printer.encodingCodePage" />}
                name="codePage"
                tooltip={"Printer Encoding Code Page"}
                helpKey="Menu.printer.NameField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.codePage}
                errors={formErrors.codePage}
                isSubmitting={isSubmitting}
                isLoading={isLoading.codePage}
                postHelperComponents={
                  <Typography
                    variant="body2"
                    color="textPrimary"
                    style={{ margin: theme.spacing(0, 1) }}
                  >
                    <LocalizedMessage id="setup.printer.encodingCodePageNote" />
                  </Typography>
                }
              />
              <SelectField
                required
                fullWidth
                name="cutType"
                tooltip={"Cut type"}
                helpKey="Menu.printer.cutType"
                label={<LocalizedMessage id="setup.printer.cutType" />}
                onChange={onFormFieldChange}
                value={formData.cutType}
                errors={formErrors.cutType}
                isSubmitting={isSubmitting}
                isLoading={isLoading.cutType}
                defaultValue={"full"}
                neglectNone
                options={[
                  {
                    value: "part",
                    text: intl.formatMessage({
                      id: "setup.printer.part",
                    }),
                  },
                  {
                    value: "full",
                    text: intl.formatMessage({
                      id: "setup.printer.full",
                    }),
                  },
                ]}
              />
              <SelectField
                fullWidth
                label={<LocalizedMessage id="setup.printer.textDensity" />}
                name="textDensity"
                tooltip={"Text Density"}
                helpKey="setup.printer.NameField"
                onChange={onFormFieldChange}
                value={formData.textDensity}
                errors={formErrors.textDensity}
                isSubmitting={isSubmitting}
                isLoading={isLoading.textDensity}
                defaultValue={"4"}
                neglectNone
                options={[
                  {
                    value: "0",
                    text: "0",
                  },
                  {
                    value: "1",
                    text: "1",
                  },
                  {
                    value: "2",
                    text: "2",
                  },
                  {
                    value: "3",
                    text: "3",
                  },
                  {
                    value: "4",
                    text: "4",
                  },
                  {
                    value: "5",
                    text: "5",
                  },
                  {
                    value: "6",
                    text: "6",
                  },
                  {
                    value: "7",
                    text: "7",
                  },
                  {
                    value: "8",
                    text: "8",
                  },
                ]}
              />
              <SelectField
                fullWidth
                name="cashDrawerPin"
                tooltip={"Cash drawer pin"}
                helpKey="Menu.printer.cashDrawerPin"
                label={<LocalizedMessage id="setup.printer.cashDrawerPin" />}
                onChange={onFormFieldChange}
                value={formData.cashDrawerPin}
                errors={formErrors.cashDrawerPin}
                isSubmitting={isSubmitting}
                isLoading={isLoading.cashDrawerPin}
                defaultValue={"2"}
                neglectNone
                options={[
                  {
                    value: "2",
                    text: "2",
                  },
                  {
                    value: "5",
                    text: "5",
                  },
                ]}
              />
              <SelectField
                fullWidth
                name="printingSize"
                tooltip={"Printing Size"}
                helpKey="Menu.printer.printingSize"
                label={<LocalizedMessage id="setup.printer.printingSize" />}
                onChange={onFormFieldChange}
                value={formData.printingSize}
                errors={formErrors.printingSize}
                isSubmitting={isSubmitting}
                isLoading={isLoading.printingSize}
                defaultValue={"80"}
                neglectNone
                options={[
                  {
                    value: "58",
                    text: "58mm",
                  },
                  {
                    value: "76",
                    text: "76mm",
                  },
                  {
                    value: "80",
                    text: "80mm",
                  },
                ]}
              />

              {formData.printerTypeId !== "itemSpecific" && (
                <TextField
                  required
                  label={<LocalizedMessage id="setup.printer.receiptName" />}
                  name="receiptName"
                  autoComplete="receiptName"
                  tooltip={"Printer Receipt Name"}
                  helpKey="Menu.printer.receiptNameField"
                  fullWidth
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.receiptName}
                  errors={formErrors.receiptName}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.receiptName}
                />
              )}

              {formData.printerTypeId === "receipt" && (
                <TextField
                  required
                  label={<LocalizedMessage id="setup.printer.copyName" />}
                  name="copyName"
                  autoComplete="copyName"
                  tooltip={"Printer Copy Name"}
                  helpKey="Menu.printer.copyNameField"
                  fullWidth
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.copyName}
                  errors={formErrors.copyName}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.copyName}
                />
              )}

              <CheckBox
                fullWidth
                name="isPrinterBeep"
                tooltip={"Active?"}
                helpKey="Menu.Categories.isPrinterBeep"
                label={<LocalizedMessage id="setup.printer.isPrinterBeep" />}
                onChange={onFormFieldChange}
                value={formData.isPrinterBeep}
                errors={formErrors.isPrinterBeep}
                isSubmitting={isSubmitting}
                isLoading={isLoading.isPrinterBeep}
              />
              {formData.printerTypeId === "receipt" && (
                <CheckBox
                  fullWidth
                  name="isPrintOnSave"
                  tooltip={"Active?"}
                  helpKey="Menu.Categories.isPrintOnSave"
                  label={<LocalizedMessage id="setup.printer.isPrintOnSave" />}
                  onChange={onFormFieldChange}
                  value={formData.isPrintOnSave}
                  errors={formErrors.isPrintOnSave}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.isPrintOnSave}
                />
              )}
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <FormActions
          onAdd={onAddPrinter}
          mode={mode}
          onCLoseForm={onCLosePrinterForm}
          isSubmitting={isSubmitting}
          onSave={onSavePrinter}
        />
      </DialogActions>
    </Dialog>
  );
};

PrinterForm.propTypes = {
  /**
   *
   */
};

PrinterForm.defaultProps = {
  onAdd: () => {},
  onSave: () => {},
  onCancel: () => {},
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => {},
};

export default PrinterForm;
