// React
import React from "react";

// Material
import CssBaseline from "@material-ui/core/CssBaseline";

// Globals
import InitialGlobalState from "Globals/State";

// Helpers
import GlobalStateProvider from "Helpers/GlobalState";

// Components

// Factories
import Providers from "./Factories/Providers";
import Subscribers from "./Factories/Subscribers";
import Initializer from "./Factories/Initializer";

// Screens
import AuthScreen from "./Screens/Auth";
import WizardScreen from "./Screens/Wizard";
import PortalScreen from "./Screens/Portal";

// Assets

// Third Parties
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

// Styles

// Ad-Hoc Components

/**
 * @name App
 * @summary App Wrapper Component
 * @category App Entry (src)
 * @component
 * @requires React
 * @requires CssBaseLine
 * @requires InitialGlobalState
 * @requires GlobalStateProvider
 * @requires Providers
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @see {@link https://material-ui.com/api/css-baseline/|Material UI CssBaseLine}
 * @see {@link module:State|Globals / State}
 * @see {@link module:GlobalState|Helpers / GlobalState}
 * @see {@link Providers|Factories / Providers}
 * @description
 * > Normalize the app styles using <CssBaseLine /> <br />
 * > Wrap the App components with the Provider<br />
 * > Render the App entry screen
 */
const App = () => {
  // Theme & Style Hooks
  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <GlobalStateProvider state={InitialGlobalState} decoupled>
      <CssBaseline />
      <Providers>
        <Router>
          <Initializer>
            <Subscribers>
              <Switch>
                <Route path="/auth">
                  <AuthScreen />
                </Route>
                <Route path="/wizard">
                  <WizardScreen />
                </Route>
                <Route path="/portal">
                  <PortalScreen />
                </Route>
              </Switch>
            </Subscribers>
          </Initializer>
        </Router>
      </Providers>
    </GlobalStateProvider>
  );
};
// App.whyDidYouRender = true;

export default App;
