// React
import React, { useRef, useState } from "react";

// Material
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  Button,
  Box,
  DialogContent,
  DialogActions,
  useMediaQuery,
  useTheme,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";

// Components
import SubscriptionDialog from "Components/SubscriptionDialog";
import FormActions from "Components/FormActions";

/**
 * @name SubscriptionAlert
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SubscriptionAlert = ({
  open,
  onCloseDialog,
  status,
  branchOrDevice,
  //onRenewNowClick,
}) => {
  // Theme & Style Hooks
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [openSubscribeNowDialog, setOpenSubscribeNowDialog] = useState(false);

  // Effect Hooks

  // Other Hooks
  const onSubscribeNowClick = () => {
    setOpenSubscribeNowDialog(true);
  };

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={false}
      maxWidth={"sm"}
      style={{ direction: theme.direction }}
    >
      <DialogTitle id="alert-dialog-title">
        {<LocalizedMessage id="subscriptionDialog.pageTitle" />}
      </DialogTitle>
      <Divider variant="middle" />

      <DialogContent
        style={{
          display: "flex",
          flex: 1,
          flexDirection: downSm ? "column" : "row",
          justifyContent: "space-around",
        }}
      >
        {status === 402 ? (
          <Box
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              <LocalizedMessage id="subscriptionDialog.subscribeNowInTrial" />
            </Typography>
          </Box>
        ) : status === 426 ? (
          <Box
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              <LocalizedMessage id="subscriptionDialog.addDevice" />
            </Typography>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography variant="body1">
              <LocalizedMessage id="subscriptionDialog.addSubscription" />
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <FormActions
          action={<LocalizedMessage id="subscriptionDialog.subscribeNow" />}
          onAction={onSubscribeNowClick}
          onCLoseForm={onCloseDialog}
          isSubmitting={false}
          isClose
        />
        {/* <Button color="secondary" autoFocus onClick={onCloseDialog}>
          <LocalizedMessage id="common.close" />
        </Button>
        {status === 402 ? (
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              onSubscribeNowClick();
              //onCloseDialog();
            }}
          >
            <LocalizedMessage id="subscriptionDialog.subscribeNow" />
          </Button>
        ) : status === 426 ? (
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              onSubscribeNowClick();
              //onCloseDialog();
            }}
          >
            <LocalizedMessage id="subscriptionDialog.subscribeNow" />
          </Button>
        ) : (
          <Button
            color="primary"
            autoFocus
            onClick={() => {
              onSubscribeNowClick();
              //onCloseDialog();
            }}
          >
            <LocalizedMessage id="subscriptionDialog.subscribeNow" />
          </Button>
        )} */}

        <SubscriptionDialog
          open={openSubscribeNowDialog}
          onCloseDialog={() => setOpenSubscribeNowDialog(false)}
          branchOrDevice={branchOrDevice}
        />
      </DialogActions>
    </Dialog>
  );
};

SubscriptionAlert.propTypes = {
  /**
   *
   */
};

SubscriptionAlert.defaultProps = {
  /**
   *
   */
};

export default SubscriptionAlert;
