// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Grid,
  Button,
  Divider,
  Typography,
  Popover,
  useTheme,
  Card,
  CardContent,
  Chip,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { DatePicker, SelectField } from "Components/FormFields";
import TrendingUpOutlinedIcon from "@material-ui/icons/TrendingUpOutlined";
import BarChartOutlinedIcon from "@material-ui/icons/BarChartOutlined";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import LocalMallOutlinedIcon from "@material-ui/icons/LocalMallOutlined";
import DashboardIcon from "@material-ui/icons/Dashboard";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";
import { getReportDate, getDateDurations } from "Utils";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors";
import useSkeleton from "Helpers/useSkeleton";
import { useGlobalState } from "Helpers/GlobalState";
import { makeStyles } from "Helpers/Styles";

// Components
import Title from "Components/Title";

// Factories

// Screens
import DownloadCard from "./DownloadPOSCard";
import OrdersSalesCard from "./OrdersSalesCard";
import HourlySalesBarChart from "./HourlySalesBarChart";
import IncomeCard from "./IncomeCard";
import OrdersCard from "./OrdersCard";
import DiscountsCard from "./DiscountsCard";
import CancelledOrdersCard from "./CancelledOrdersCard";
import SalesBySectionCard from "./SalesBySectionCard";
import SalesByEmployeeCard from "./SalesByEmployeeCard";
import SalesByCategoryCard from "./SalesByCategoryCard";
import TopSellingItemsList from "./TopSellingItemsList";
import LeastSellingItemsList from "./LeastSellingItemsList";

// Servicies
import { useGetTotalSales, useGetOrderDays } from "Services/Dashboard/";

// Assets

// Third Parties
import moment from "moment";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import numeral from "numeral";

// Styles
const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    justifyContent: "center",
  },
  iconBox: {
    display: "flex",
    backgroundColor: "white",
    margin: 8,
    alignItems: "center",
  },
  numberBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  cardContent: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
}));

// Ad-Hoc Components

/**
 * @name Dashboard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Dashboard = ({ dataToShow }) => {
  // Theme & Style Hooks
  const Theme = useTheme();
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const { from, to } = getReportDate("today", "", "");
  const [dateFrom, setDateFrom] = useState(from);
  const [dateTo, setDateTo] = useState(to);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dateDuration, setDateDuration] = useState("today");
  const [totalSales, setTotalSalesData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orderDay, setOrderDay] = useState("");
  const [errors, setErrors] = useState([]);
  const [orderDayProgress, setOrderDayProgress] = useState("");
  const [permissions] = useGlobalState("userData.permissions");
  const [heading, setHeading] = useGlobalState("heading.heading");

  // Effect Hooks
  useEffect(() => {
    getOrderDay()
      .then((response) => {
        if (!response) setOrderDay(null);
        else if (response.value) setOrderDay(response.value);
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
        setOrderDay(null);
      });
  }, []);

  useEffect(() => {
    if (orderDay) {
      if (moment(new Date()).format("YYYY-MM-DD") < orderDay) setOrderDayProgress("isAdvance");
      else if (moment(new Date()).format("YYYY-MM-DD") > orderDay) setOrderDayProgress("isBefore");
    }
  }, [orderDay]);

  useEffect(
    () =>
      setHeading(
        <Box display="flex" alignItems="center">
          <DashboardIcon style={{ margin: "0px 16px", width: 20, height: 20 }} />
          <LocalizedMessage id="dashboard.pageTitle" key="dashboard.pageTitle" />
        </Box>
      ),
    []
  );

  useEffect(() => {
    if (!errors.length) {
      setIsLoading(true);
      getTotalSales(moment(dateFrom).format("YYYY-MM-DD"), moment(dateTo).format("YYYY-MM-DD"))
        .then((response) => {
          setTotalSalesData(response[0]);
          setIsLoading(false);
        })
        .catch((errors) => {
          displaySnackbarErrors(errors, enqueueSnackbar);
          setIsLoading(false);
        });
    }
  }, [dateFrom, dateTo, errors]);

  // Other Hooks
  const intl = useIntl();
  const getTotalSales = useGetTotalSales();
  const getOrderDay = useGetOrderDays();
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnSelectDateDuration = (name, value) => {
    if (value && value !== dateDuration) {
      setDateDuration(value);
      const { from, to } = getReportDate(value, dateFrom, dateTo);
      setDateFrom(from);
      setDateTo(to);
      if (value !== "custom") handleClose();
    }
  };

  // Other
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const dateDurations = getDateDurations(intl);

  // Component Render
  return (
    <div>
      <Title pageTitle={<LocalizedMessage id="dashboard.pageTitle" key="dashboard.pageTitle" />} />

      {permissions.isAdmin || permissions.dashboard ? (
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} item>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              {isLoading ? (
                <Skeleton variant="text" height={40} width={100} />
              ) : (
                <Chip
                  style={{
                    margin: 8,
                    padding: 8,
                    height: "100%",
                    color: "white",
                    borderRadius: "8px",
                    backgroundColor:
                      orderDayProgress === "isAdvance"
                        ? "#D1A506"
                        : orderDayProgress === "isBefore"
                        ? "#ca121c"
                        : "#191970",
                  }}
                  label={
                    <Typography variant="body2" style={{ whiteSpace: "normal" }}>
                      <LocalizedMessage id="dashboard.openDay" />
                      {": "}
                      {orderDay ? orderDay : <LocalizedMessage id="dashboard.noOpenDay" />}
                    </Typography>
                  }
                />
              )}
              <Button aria-describedby={id} onClick={handleClick} color="primary">
                {`${moment(dateFrom).format("MMM DD, YYYY")} - ${moment(dateTo).format(
                  "MMM DD, YYYY"
                )}`}
              </Button>
            </Box>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Card variant="outlined">
                <CardContent>
                  <Box width="240.5px">
                    <SelectField
                      fullWidth
                      neglectNone
                      name="dateDuration"
                      label={<LocalizedMessage id="report.dateDuration.title" />}
                      onChange={handleOnSelectDateDuration}
                      value={dateDuration}
                      options={dateDurations}
                      variant="standard"
                      margin="none"
                      defaultValue={"today"}
                    />
                  </Box>
                  {dateDuration === "custom" && (
                    <Box marginTop={2}>
                      <Box style={{ marginBottom: Theme.spacing(2) }}>
                        <DatePicker
                          name="dateFrom"
                          tooltip={"Date from"}
                          helpKey="common.dateFrom"
                          label={intl.formatMessage({ id: "common.dateFrom" })}
                          onChange={(name, value) => {
                            if (
                              moment(value).format("YYYY-MM-DD") >
                              moment(dateTo).format("YYYY-MM-DD")
                            )
                              setErrors([<LocalizedMessage id="validation.dateTo.date.min" />]);
                            else {
                              setErrors([]);
                              setDateFrom(value);
                            }
                            // if (dateFrom !== value) handleClose()
                          }}
                          value={dateFrom}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="dense"
                          disabled={dateDuration !== "custom"}
                          errors={errors.length ? [""] : []}
                        />
                      </Box>
                      <Box>
                        <DatePicker
                          name="dateTo"
                          tooltip={"Date from"}
                          helpKey="common.dateTo"
                          label={intl.formatMessage({ id: "common.dateTo" })}
                          onChange={(name, value) => {
                            if (
                              moment(value).format("YYYY-MM-DD") <
                              moment(dateFrom).format("YYYY-MM-DD")
                            )
                              setErrors([<LocalizedMessage id="validation.dateTo.date.min" />]);
                            else {
                              if (dateTo !== value) handleClose();
                              setErrors([]);
                              setDateTo(value);
                            }
                          }}
                          value={dateTo}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="dense"
                          disabled={dateDuration !== "custom"}
                          errors={errors}
                        />
                      </Box>
                    </Box>
                  )}
                </CardContent>
                {/* <CardActions>
                <Button
                  size="small"
                  color="primary"
                  onClick={handleClose}
                  style={{ display: "flex", flex: 1 }}
                >
                  Change Reporting Period
                  {/* <LocalizedMessage id="general.edit" />
                </Button>
              </CardActions> */}
              </Card>
            </Popover>
          </Grid>
          <Grid xs={12} md={12} item>
            <Grid container justify="center" spacing={2}>
              <Grid xs={12} sm={6} md={3} item>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {isLoading ? (
                      <Skeleton variant="rect" width={70} height={70} style={{ margin: 6 }} />
                    ) : (
                      <Box className={classes.iconBox}>
                        <TrendingUpOutlinedIcon style={{ color: "green", fontSize: "xxx-large" }} />
                      </Box>
                    )}
                    <Box className={classes.numberBox}>
                      {isLoading ? (
                        <Skeleton width={100} height={35} />
                      ) : (
                        <Typography variant="subtitle1" align="center">
                          <LocalizedMessage id="report.totalsales" />
                        </Typography>
                      )}
                      {isLoading ? (
                        <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                      ) : (
                        <Box style={{ marginTop: "auto" }}>
                          <Typography variant="h4">
                            {totalSales.total_sales ? totalSales.total_sales : 0}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {isLoading ? (
                      <Skeleton variant="rect" width={70} height={70} style={{ margin: 6 }} />
                    ) : (
                      <Box className={classes.iconBox}>
                        <BarChartOutlinedIcon
                          style={{ color: "MidnightBlue", fontSize: "xxx-large" }}
                        />
                      </Box>
                    )}
                    <Box className={classes.numberBox}>
                      {isLoading ? (
                        <Skeleton width={100} height={35} />
                      ) : (
                        <Typography variant="subtitle1" align="center">
                          <LocalizedMessage id="report.netsales" />
                        </Typography>
                      )}
                      {isLoading ? (
                        <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                      ) : (
                        <Box style={{ marginTop: "auto" }}>
                          <Typography variant="h4">
                            {totalSales.net_sales ? totalSales.net_sales : 0}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {isLoading ? (
                      <Skeleton variant="rect" width={70} height={70} style={{ margin: 6 }} />
                    ) : (
                      <Box className={classes.iconBox}>
                        <LocalMallOutlinedIcon
                          style={{ color: "GoldenRod", fontSize: "xxx-large" }}
                        />
                      </Box>
                    )}
                    <Box className={classes.numberBox}>
                      {isLoading ? (
                        <Skeleton width={100} height={35} />
                      ) : (
                        <Typography variant="subtitle1" align="center">
                          <LocalizedMessage id="report.orderscount" />
                        </Typography>
                      )}
                      {isLoading ? (
                        <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                      ) : (
                        <Box style={{ marginTop: "auto" }}>
                          <Typography variant="h4">
                            {totalSales.orders_count ? totalSales.orders_count : 0}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={12} sm={6} md={3} item>
                <Card className={classes.card}>
                  <CardContent className={classes.cardContent}>
                    {isLoading ? (
                      <Skeleton variant="rect" width={70} height={70} style={{ margin: 6 }} />
                    ) : (
                      <Box className={classes.iconBox}>
                        <ShoppingCartOutlinedIcon style={{ color: "red", fontSize: "xxx-large" }} />
                      </Box>
                    )}
                    <Box className={classes.numberBox}>
                      {isLoading ? (
                        <Skeleton width={100} height={35} />
                      ) : (
                        <Typography variant="subtitle1" align="center">
                          <LocalizedMessage id="report.itemscount" />
                        </Typography>
                      )}
                      {isLoading ? (
                        <Skeleton height={40} width={60} style={{ marginTop: "auto" }} />
                      ) : (
                        <Box style={{ marginTop: "auto" }}>
                          <Typography variant="h4">
                            {totalSales.items_count ? totalSales.items_count : 0}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={6} item>
            <OrdersSalesCard style={{ height: "580px" }} dateFrom={dateFrom} dateTo={dateTo} />
          </Grid>
          <Grid xs={12} md={6} item>
            <HourlySalesBarChart style={{ height: "580px" }} dateFrom={dateFrom} dateTo={dateTo} />
          </Grid>
          {/* <Grid xs={12} md={6} item>
            <Grid container justify="center" spacing={2}>
              <Grid xs={6} item>
                <IncomeCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
              <Grid xs={6} item>
                <OrdersCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
              <Grid xs={6} item>
                <DiscountsCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
              <Grid xs={6} item>
                <CancelledOrdersCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid xs={12} item>
            <Grid container justify="center" spacing={2}>
              <Grid xs={12} md={6} lg={4} item>
                <SalesBySectionCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
              <Grid xs={12} md={6} lg={4} item>
                <SalesByEmployeeCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
              <Grid xs={12} md={6} lg={4} item>
                <SalesByCategoryCard dateFrom={dateFrom} dateTo={dateTo} />
              </Grid>
            </Grid>
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <TopSellingItemsList dateFrom={dateFrom} dateTo={dateTo} />
          </Grid>
          <Grid xs={12} md={6} lg={6} item>
            <LeastSellingItemsList dateFrom={dateFrom} dateTo={dateTo} />
          </Grid>
        </Grid>
      ) : (
        <Grid container justify="center" spacing={2}>
          <Grid xs={12} sm={9} item>
            <Card
              style={{
                minHeight: "70vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                textAlign: "center",
                padding: 20,
              }}
            >
              <Box>
                <Typography
                  justify="center"
                  variant="h2"
                  component="h2"
                  style={{
                    flex: 1,
                    color: "#2E56A5",
                  }}
                >
                  {<LocalizedMessage id={"dashboard.welcome"} />}
                </Typography>

                <Divider variant="middle" style={{ marginTop: 20 }} />
              </Box>
              <Box>
                <Typography
                  style={{
                    flex: 1,
                    marginBottom: 20,
                  }}
                  variant="h4"
                  color="textSecondary"
                >
                  {<LocalizedMessage id={"dashboard.navigate"} />}
                </Typography>

                <Box
                  style={{
                    flex: 1,
                    // marginTop: 40,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {(permissions.isAdmin || dataToShow.includes("menu")) && (
                    <Button component={Link} to="Menu" color="primary" style={{ marginRight: 10 }}>
                      {<LocalizedMessage id={"dashboard.menu"} />}
                    </Button>
                  )}
                  {(permissions.isAdmin || dataToShow.includes("pos")) && (
                    <Button component={Link} to="POS" color="primary" style={{ marginRight: 10 }}>
                      {<LocalizedMessage id={"common.pos"} />}
                    </Button>
                  )}
                  {(permissions.isAdmin || dataToShow.includes("setup")) && (
                    <Button component={Link} to="Setup" color="primary" style={{ marginRight: 10 }}>
                      {<LocalizedMessage id={"common.setup"} />}
                    </Button>
                  )}
                  {dataToShow.includes("report") && (
                    <Button
                      component={Link}
                      to="Reports"
                      color="primary"
                      style={{ marginRight: 20 }}
                    >
                      <LocalizedMessage id={"common.reports"} />
                    </Button>
                  )}
                </Box>
                {permissions.isOwner && (
                  <Box
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      component={Link}
                      to="Branches"
                      color="primary"
                      style={{ marginRight: 10 }}
                    >
                      {<LocalizedMessage id={"dashboard.branches"} />}
                    </Button>
                    <Button
                      component={Link}
                      to="Accounts"
                      color="primary"
                      style={{ marginRight: 20 }}
                    >
                      {<LocalizedMessage id={"dashboard.accounts"} />}
                    </Button>
                    <Button component={Link} to="Roles" color="primary" style={{ marginRight: 20 }}>
                      {<LocalizedMessage id={"dashboard.roles"} />}
                    </Button>
                    <Button
                      component={Link}
                      to="Reports"
                      color="primary"
                      style={{ marginRight: 20 }}
                    >
                      {<LocalizedMessage id={"common.reports"} />}
                    </Button>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
          <Grid xs={12} sm={3} item>
            <DownloadCard />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

Dashboard.propTypes = {
  /**
   *
   */
};

export default Dashboard;
