// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Typography, Grid, Fab, Badge, useTheme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { withStyles } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Logo from "./pepsi.png";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useStateRef from "Helpers/useStateRef";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";

// Factories
import items from "./i";

// Screens
import ItemList from "./ItemList";

// Assets

// Third Parties

// Services

// Styles
const StyledTabs = withStyles(
  (theme) => ({
    root: {
      display: "flex",
      minHeight: "100vh",
      position: "sticky",
      top: "0",
    },
    indicator: {
      backgroundColor: "white",
    },
    flexContainerVertical: {
      height: "90vh",
      display: "flex",
    },
    scroller: {},
  }),
  { index: 1 }
)(Tabs);

const StyledTab = withStyles(
  (theme) => ({
    root: {
      padding: 0,
      minWidth: 0,
    },
    wrapper: {
      transform: "rotate(-90deg)",
      margin: theme.spacing(5),
      overflow: "visible",
      // color: "#FFFFFF",
    },
  }),
  { index: 1 }
)(Tab);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    // height: "53vh",
  },
  tabs: {
    display: "flex",
    minHeight: "100vh",
    position: "sticky",
    top: "0",
    // marginTop: theme.spacing(3)
    margin: 20,
  },
  tab: {
    // marginBottom: 30,
    // minHeight: "120px",
    transform: "rotate(-90deg)",
    color: "#FFFFFF",
    overflow: "visible",

    // -ms-overflow-style: none, /* Internet Explorer 10+ */
    // scrollbarWidth: "none" /* Firefox */,

    // "&::-webkit-scrollbar": {
    //   display: "none" /* Safari and Chrome */,
    // },
  },
  logo: {
    maxheight: "50px",
    marginRight: "5px",
  },
  fab: {
    position: "fixed",
    bottom: 100,
    right: 92,
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2, 0.7),
    "& img": {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      maxWidth: "100%",
      maxHeight: "150px",
    },
  },
  gridContainer: {
    display: "flex",
    flexWrap: "unset",
  },
}));

// Ad-Hoc Components
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

/**
 * @title BuilderTemplate
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const BuilderTemplate = ({
  primaryBackgroundColor,
  primaryTextColor,
  backgroundColor,
  secondaryBackgroundColor,
  secondaryTextColor,
}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [account, setAccount] = useGlobalState("userData.account");
  // const [items, setItems] = useGlobalState("userData.items");

  // State Hooks
  const [cart, setCart] = useStateRef([]);
  const [totalPrice, setTotalPrice] = useStateRef(0);
  const [categoriesValue, setCategoriesValue] = useState(0);
  const [subCategoriesValue, setSubCategoriesValue] = useState(0);
  const [subCategories, setSubCategories] = useState([]);
  const [parentCategories, setParentCategories] = useState([]);
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [openModifierForm, setOpenModifierForm] = useState(false);

  // Effect Hooks
  useEffect(() => {
    if (items.length) setSubCategories(items[0].children);
  }, [items]);

  // Other Hooks

  // Event Handlers
  const handleCategoriesChange = (event, newValue) => {
    setSubCategoriesValue(0);
    setCategoriesValue(newValue);
    setSubCategories(items[newValue].children);
    setParentCategories([]);
  };

  const handleSubCategoriesChange = (event, newValue) => {
    if (subCategories[newValue].children.length !== 0) {
      setSubCategoriesValue(0);
      if (parentCategories.length) setParentCategories([...parentCategories, subCategories]);
      else setParentCategories([subCategories]);

      setSubCategories(subCategories[newValue].children);
      setDisabled(false);
    } else setSubCategoriesValue(newValue);
  };

  const handleSubCategoriesChildrenChange = (event, newValue) => {
    if (parentCategories.length) {
      const parent = parentCategories.pop();
      if (!parentCategories.length) setDisabled(true);
      setParentCategories(parentCategories);
      setSubCategories(parent);
    }
  };

  const handleAddToCart = (item) => {};

  const handleMinusFromCart = (item) => {};

  // Other

  // Component Render
  return (
    <Box className={classes.root} style={{ backgroundColor }}>
      <Title
        pageTitle={
          <LocalizedMessage id="setup.onlineOrders.pageTitle" key="setup.onlineOrders.pageTitle" />
        }
      />
      <Grid container className={classes.gridContainer}>
        <Grid item xs={2} sm={2} style={{ backgroundColor: primaryBackgroundColor }}>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={categoriesValue}
            onChange={handleCategoriesChange}
            aria-label="Vertical tabs example"
            TabIndicatorProps={{ style: { background: primaryTextColor } }}
          >
            {items.map((category, index) => (
              <StyledTab
                key={index}
                // className={classes.tab}
                style={{ color: primaryTextColor }}
                label={category.name}
                wrapped={true}
                {...a11yProps(index)}
              />
            ))}
          </StyledTabs>
        </Grid>
        <Grid item xs={10} sm={10}>
          <Box>
            <Box display="flex" alignItems="center" m={1}>
              <img src={account.brands[0].logoPath} style={{ height: 80 }} alt="Brand Image" />
              <Typography
                component="span"
                style={{ margin: theme.spacing(2), color: "#292929" }}
                variant="h5"
              >
                {account.brands[0].name}
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Tabs
                orientation="horizontal"
                // value={subCategoriesValue}
                onChange={handleSubCategoriesChildrenChange}
                style={{
                  margin: theme.spacing(1),
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Tab
                  icon={<ArrowBackIcon />}
                  style={{
                    backgroundColor: secondaryBackgroundColor,
                    minWidth: "60px",
                    color: secondaryTextColor,
                  }}
                  disabled={disabled}
                />
              </Tabs>
              <Tabs
                scrollButtons="auto"
                orientation="horizontal"
                variant="scrollable"
                value={subCategoriesValue}
                onChange={handleSubCategoriesChange}
                aria-label="Vertical tabs example"
                style={{
                  // margin: theme.spacing(2),
                  display: "flex",
                  alignItems: "center",
                  position: "relative",
                  //width: "10vh",
                  // minHeight: "10vh",
                }}
              >
                {subCategories.map((subCategory, key) => (
                  <Tab
                    key={key}
                    //className={classes.tab}
                    label={subCategory.name}
                    wrapped={true}
                    style={{
                      backgroundColor: secondaryBackgroundColor,
                      color: secondaryTextColor,
                    }}
                    {...a11yProps(key)}
                  />
                ))}
              </Tabs>
            </Box>
          </Box>
          {subCategoriesValue === 0 ? (
            <TabPanel value={subCategoriesValue} index={subCategoriesValue}>
              <ItemList
                title={items[categoriesValue].name}
                items={items[categoriesValue].items}
                onAddToCart={handleAddToCart}
                onMinusFromCart={handleMinusFromCart}
                secondaryTextColor={secondaryTextColor}
              />
            </TabPanel>
          ) : (
            subCategories.map((subCategory, index) => (
              <TabPanel value={subCategoriesValue} index={index}>
                <ItemList
                  title={subCategory.name}
                  items={subCategory.items}
                  onAddToCart={handleAddToCart}
                  onMinusFromCart={handleMinusFromCart}
                  secondaryTextColor={secondaryTextColor}
                />
              </TabPanel>
            ))
          )}
        </Grid>
      </Grid>
      {/* <Fab
        aria-label="Add"
        className={classes.fab}
        size="large"
        style={{ width: 70, height: 70, color: "#1aa6b7" }}
        onClick={() => setOpen(true)}
      >
        <Badge
          badgeContent={cart.getState().length}
          color="secondary"
          showZero
        //style={{ marginRight: theme.spacing(4) }}
        >
          <ShoppingCartIcon style={{ width: 40, height: 40 }} />
        </Badge>
      </Fab> */}
    </Box>
  );
};

BuilderTemplate.propTypes = {
  /**
   *
   */
};

BuilderTemplate.defaultProps = {
  /**
   *
   */
};

export default BuilderTemplate;
