// React
import React, { useState, useEffect } from "react";

// Material
import {
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  IconButton,
  TableRow,
  Tooltip,
  Button,
  Fab,
  Divider,
  useTheme,
  CircularProgress,
  useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { TextField } from "Components/FormFields";
import AlertDialog from "Components/AlertDialog";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";

// Services
import {
  useGetAllReportEmails,
  useDeleteReportEmail,
  useAddReportEmail,
  useEditReportEmail,
} from "Services/ReportEmails/";

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components
const columns = [
  {
    id: "email",
    label: <LocalizedMessage id="common.email" />,
    minWidth: 300,
  },
  { id: "actions", label: "", minWidth: 200 },
];

/**
 * @email ReportEmails
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ReportEmails = ({}) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks
  const [openSearchField, setOpenSearchField] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRows] = useStateRef([]);
  const [isFetchingRows, setIsFetchingRows] = useState(true);
  const [selectedReportEmail, setSelectedReportEmail] = useState({});

  // Effect Hooks
  useEffect(() => {
    getAllReportEmails()
      .then((emails) => {
        setRows(emails.map((email) => createData(email)));
        setIsFetchingRows(false);
      })
      .catch((errors) => {
        errors.forEach((e) => {
          enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
            variant: "error",
            autoHideDuration: 3000,
          });
        });
      });
  }, []);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const getAllReportEmails = useGetAllReportEmails();
  const deleteReportEmail = useDeleteReportEmail();
  const addReportEmail = useAddReportEmail({
    languageKey: "setup.reportEmail",
  });
  const editReportEmail = useEditReportEmail({
    languageKey: "setup.reportEmail",
  });
  const { enqueueSnackbar } = useSnackbar();

  const validationSchema = Joi.object({
    email: Joi.string().email({ tlds: false }).required(),
  });

  const {
    formData: addFormData,
    setFormData: setAddFormData,
    formErrors: addFormErrors,
    setFormErrors: setAddFormErrors,
    validateForm: validateAddForm,
    onFormFieldChange: onAddFormFieldChange,
    isSubmitting: isSubmittingAdd,
    setIsSubmiting: setIsSubmitingAdd,
    isLoading: isLoadingAdd,
  } = useForm({ validationSchema, languageKey: "setup.reportEmail" });

  const {
    formData: editFormData,
    setFormData: setEditFormData,
    formErrors: editFormErrors,
    setFormErrors: setEditFormErrors,
    validateForm: validateEditForm,
    onFormFieldChange: onEditFormFieldChange,
    isSubmitting: isSubmittingEdit,
    setIsSubmiting: setIsSubmitingEdit,
    isLoading: isLoadingEdit,
  } = useForm({ validationSchema, languageKey: "setup.reportEmail" });

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (target, value) => {
    setSearchQuery(value);
    setPage(0);
  };

  const onEditClick = (reportEmail) => {
    setSelectedReportEmail(reportEmail);
    setEditFormData({ email: reportEmail.email });
  };

  const onDeleteClick = (id) => {
    deleteReportEmail(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted");

        setRows(rows.getState().filter((row) => row.id !== id));
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar);
      });
  };

  const handleAddReportEmail = () => {
    const validForm = validateAddForm();
    console.log(validForm);

    if (validForm) {
      setIsSubmitingAdd(true);
      const { email } = addFormData;
      addReportEmail({
        email,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added");

          setRows([...rows.getState(), createData(response.data.data)]);
          setIsSubmitingAdd(false);
          setAddFormData({});
        })
        .catch((errors) => {
          console.log(errors);
          errors.forEach((e) => {
            if (e.field) {
              setAddFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }));
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
          setIsSubmitingAdd(false);
        });
    }
  };

  const handleEditReportEmail = () => {
    console.log("editFromData", editFormData);

    const validForm = validateEditForm();
    console.log(validForm);

    if (validForm) {
      setIsSubmitingEdit(true);
      const { email } = editFormData;
      editReportEmail({
        id: selectedReportEmail.id,
        email,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited");

          const newRows = rows.getState();
          const index = newRows.findIndex(
            (row) => row.id === selectedReportEmail.id
          );
          newRows.splice(index, 1, createData(response.data.data));
          setRows(newRows);
          setIsSubmitingEdit(false);
          setSelectedReportEmail({});
        })
        .catch((errors) => {
          console.log(errors);
          errors.forEach((e) => {
            if (e.field) {
              setEditFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }));
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
          setIsSubmitingEdit(false);
        });
    }
  };

  // Other
  function createData(reportEmail) {
    return {
      id: reportEmail.id,
      email: reportEmail.email,
      actions: (
        <Box>
          {(permissions.isAdmin ||
            permissions.reportEmail.includes("edit")) && (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton
                aria-label="edit"
                onClick={() => onEditClick(reportEmail)}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
          {/* {(permissions.isAdmin || permissions.reportEmail.includes("delete")) && (
            <AlertDialog id={reportEmail.id} onAgree={onDeleteClick} deleteText="" />
          )} */}
        </Box>
      ),
    };
  }

  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage
            id="setup.reportEmail.pageTitle"
            key="setup.reportEmail.pageTitle"
          />
        }
      />

      <Box
        style={{
          position: "relative",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between ",
          }}
        >
          <Typography variant="h6" component="h2" style={{ margin: 8 }}>
            <LocalizedMessage id="setup.reportEmail.pageTitle" />
          </Typography>
          {!openSearchField && downSm && (
            <IconButton onClick={() => setOpenSearchField(true)}>
              <SearchIcon color={"primary"} />
            </IconButton>
          )}
          {(openSearchField || !downSm) && (
            <TextField
              label={<LocalizedMessage id="general.search" />}
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
            />
          )}
        </Box>
        <Divider style={{ margin: 10 }} />

        <Box
          display="flex"
          flexDirection="row"
          alignItems="baseline"
          alignContent="center"
          justifyContent="start"
          // justifyContent="space-between"
        >
          <Box
            // flex={3}
            position="relative"
            top="-3px"
            minWidth="250px"
          >
            <TextField
              size="small"
              required
              label={<LocalizedMessage id="common.email" />}
              name="email"
              tooltip={"Report Email"}
              helpKey="Menu.reportEmail.emailField"
              onChange={onAddFormFieldChange}
              value={addFormData.email}
              errors={addFormErrors.email}
              isSubmitting={isSubmittingAdd}
              isLoading={isLoadingAdd.email}
              fullWidth
            />
          </Box>
          <Button
            type="button"
            size="small"
            color="primary"
            disabled={isSubmittingAdd}
            onClick={handleAddReportEmail}
            style={{
              // flex: 1,
              justifyItems: "center",
              padding: "7px",
              marginInlineStart: "16px",
            }}
            variant="contained"
            startIcon={!isSubmittingAdd && <AddIcon />}
          >
            {isSubmittingAdd ? (
              <CircularProgress size={20} />
            ) : (
              <LocalizedMessage id="general.add" />
            )}
          </Button>
        </Box>

        <Paper className={classes.root}>
          {isFetchingRows && inputSkeleton ? (
            inputSkeleton
          ) : (
            <Box ref={inputRef}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(searchQuery
                      ? rows
                          .getState()
                          .filter(
                            (row) =>
                              row.email
                                .toLowerCase()
                                .indexOf(searchQuery.toLowerCase()) != -1 ||
                              searchQuery.toLowerCase() === ""
                          )
                      : rows.getState()
                    )
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            {columns.map((column) => {
                              const value = row[column.id];
                              return (
                                <TableCell key={column.id} align={column.align}>
                                  {column.id === "email" &&
                                  selectedReportEmail.id === row.id ? (
                                    <Box
                                      display="flex"
                                      flexDirection="row"
                                      alignItems="baseline"
                                    >
                                      <Box
                                        flex={0.5}
                                        position="relative"
                                        top="-3px"
                                      >
                                        <TextField
                                          variant="standard"
                                          required
                                          label={
                                            <LocalizedMessage id="common.email" />
                                          }
                                          name="email"
                                          size="small"
                                          tooltip={"Report Email"}
                                          helpKey="Menu.reportEmail.emailField"
                                          onChange={onEditFormFieldChange}
                                          value={editFormData.email}
                                          errors={editFormErrors.email}
                                          isSubmitting={isSubmittingEdit}
                                          isLoading={isLoadingEdit.email}
                                        />
                                      </Box>
                                      <Box
                                        style={{ marginInlineStart: "16px" }}
                                      >
                                        <Button
                                          onClick={() =>
                                            setSelectedReportEmail({})
                                          }
                                          color="secondary"
                                          startIcon={<ClearIcon />}
                                          style={{
                                            marginInlineStart: "8px",
                                            padding: "8px",
                                          }}
                                        >
                                          <LocalizedMessage id="general.cancel" />
                                        </Button>
                                        <Button
                                          type="button"
                                          color="primary"
                                          disabled={isSubmittingEdit}
                                          onClick={handleEditReportEmail}
                                          startIcon={
                                            !isSubmittingEdit && <DoneIcon />
                                          }
                                          style={{
                                            marginInlineStart: "8px",
                                            padding: "8px",
                                          }}
                                        >
                                          {isSubmittingEdit ? (
                                            <CircularProgress size={20} />
                                          ) : (
                                            <LocalizedMessage id="general.edit" />
                                          )}
                                        </Button>
                                      </Box>
                                    </Box>
                                  ) : column.id === "actions" &&
                                    selectedReportEmail.id === row.id ? null : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.getState().length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

ReportEmails.propTypes = {
  /**
   *
   */
};

ReportEmails.defaultProps = {
  /**
   *
   */
};

export default ReportEmails;
